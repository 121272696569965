import { FC, useEffect, useMemo, useState } from 'react'
import {
  Autocomplete,
  Body1,
  Box,
  Button,
  CircularProgress,
  DatePicker,
  Divider,
  findBy,
  FormControl,
  FormHelperText,
  H5,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@perk-ui/core'
import { format } from 'date-fns'
import { useFormik } from 'formik'
import { matchSorter } from 'match-sorter'
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router'

import AppLink from '../../../components/AppLink'
import { buildTreatmentDetailSlug } from '../../../components/buildTreatmentDetailSlug'
import { useSitesContext } from '../../../components/SitesProvider'
import TreatmentDetail from '../../../components/TreatmentDetail'
import useBodySites from '../../../features/query-hooks/useBodySites'
import useCreateTreatment from '../../../features/query-hooks/useCreateTreatment'
import useDeleteTreatment from '../../../features/query-hooks/useDeleteTreatment'
import useMedicalTreatments from '../../../features/query-hooks/useMedicalTreatments'
import usePatientCases from '../../../features/query-hooks/usePatientCases'
import { PatientTreatment } from '../../../features/query-hooks/usePatientTreatment'
import useProviders from '../../../features/query-hooks/useProviders'
import useSurveyResponseSearch from '../../../features/query-hooks/useSurveyResponseSearch'
import useTreatmentProducts from '../../../features/query-hooks/useTreatmentProducts'
import useUpdateTreatment from '../../../features/query-hooks/useUpdateTreatment'
import useDates from '../../../hooks/useDates'
import { buildName } from '../../../utils/buildName'
import { parseYMD } from '../../../utils/dates'
import PatientFlowUrls, { PatientFlowLocationParams } from '../PatientFlowUrls'
import { TreatmentTypeNameCustomizations } from './Treatment'
import TreatmentDetailsForm from './TreatmentDetailsForm'
import useTreatmentSession from './TreatmentSession.hooks'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  form: {
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '& > .MuiDivider-root': {
      marginBottom: theme.spacing(3),
    },
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
  },
  divider: {
    marginTop: theme.spacing(3),
  },
}))

const treatmentHasChanged = (
  treatment1: TreatmentSessionFormValues,
  treatment2: PatientTreatment,
) => {
  if (treatment1.providerId !== treatment2.providerId) return true
  if (treatment1.medicalTreatmentId !== treatment2.medicalTreatmentId)
    return true
  if (treatment1.treatmentDate !== treatment2.treatmentDate) return true
  if (treatment1.treatmentProductId !== treatment2.treatmentProductId)
    return true

  return false
}

export interface TreatmentSessionFormValues {
  siteLocationId?: string
  providerId: string
  parentId?: string
  medicalTreatmentId: string
  treatmentProductId?: string
  treatmentDate: string
}

export interface TreatmentProps {}

/**
 * TODO: Refactor this component to use Routes to handle its internal `pageState`
 */
const Treatment: FC<TreatmentProps> = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const sessionMatch = useRouteMatch<PatientFlowLocationParams>()
  const [pageState, setPageState] = useState<
    'page1-edit' | 'page2-edit' | 'readonly'
  >('page1-edit')
  const {
    patientId = undefined,
    caseId = undefined,
    treatmentId = undefined,
    sessionId = undefined,
  } = sessionMatch ? sessionMatch.params : {}

  const createTreatmentSession = useCreateTreatment()
  const updateTreatmentSession = useUpdateTreatment()
  const deleteTreatmentSession = useDeleteTreatment()
  const { data: bodySites = [] } = useBodySites()
  const { data: providers = [] } = useProviders()
  const { currentSite } = useSitesContext()
  const { localizedFormatDate, localizedParseDate } = useDates()

  const { data: patientCases = [], isLoading: isLoadingCases } =
    usePatientCases(patientId)
  const {
    data: medicalTreatments = [],
    isLoading: isLoadingMedicalTreatments,
  } = useMedicalTreatments()
  const location = useLocation<{ from?: string }>()

  const patientCase = findBy('id', caseId, patientCases)
  const treatment = findBy(
    'id',
    treatmentId,
    patientCase?.patientTreatments || [],
  )
  const treatmentSessions = (patientCase?.patientTreatments || [])
    .filter((pt) => {
      return pt.parentId == treatmentId
    })
    .sort((a, b) => {
      const date1 = parseYMD(a.treatmentDate)
      const date2 = parseYMD(b.treatmentDate)
      return Number(date1) - Number(date2)
    })
  const existingSession = findBy('id', sessionId, treatmentSessions)
  const existingSessionIndex = existingSession
    ? treatmentSessions.indexOf(existingSession)
    : null
  const bodySiteName = findBy('id', patientCase?.bodySiteId, bodySites)?.name
  const medicalTreatment =
    existingSession &&
    findBy('id', existingSession.medicalTreatmentId, medicalTreatments)
  const medicalTreatmentSlug = medicalTreatment
    ? buildTreatmentDetailSlug(medicalTreatment.slug)
    : ''

  const { data: existingSrs, isLoading: isLoadingSrs } =
    useSurveyResponseSearch({
      ownerIds: [existingSession?.id || ''],
      ownerType: 'PatientTreatment',
      surveySlugs: [medicalTreatmentSlug],
    })

  const isLoading = isLoadingCases || isLoadingMedicalTreatments || isLoadingSrs
  useEffect(() => {
    const srForThisTreatment = (existingSrs?.surveyResponses || []).filter(
      (sr) => sr.ownerId === existingSession?.id,
    )

    if (srForThisTreatment.length) {
      setPageState('readonly')
    } else if (existingSession) {
      setPageState('page2-edit')
    } else {
      setPageState('page1-edit')
    }
  }, [existingSession, existingSrs])

  const handleDeleteTreatment = () => {
    if (!patientId || !caseId || !existingSession?.id) return

    return deleteTreatmentSession.mutateAsync(existingSession.id).then(() => {
      setPageState('page1-edit')
      const otherTreatment = (patientCase?.patientTreatments || []).find(
        (t) => t.id !== treatmentId,
      )
      const destination = otherTreatment
        ? PatientFlowUrls.treatment.create(patientId, caseId, otherTreatment.id)
        : PatientFlowUrls.addTreatment.create(patientId, caseId)

      push(destination)
    })
  }

  const country = currentSite?.country
  const isCanada = country === 'canada'

  const sessionMinDate = existingSessionIndex
    ? existingSessionIndex > 0
      ? localizedFormatDate(
          parseYMD(
            treatmentSessions[existingSessionIndex - 1].treatmentDate ||
              new Date(),
          ),
        )
      : localizedFormatDate(parseYMD(treatment?.treatmentDate || new Date()))
    : treatmentSessions.length > 0
    ? localizedFormatDate(
        parseYMD(
          treatmentSessions[treatmentSessions.length - 1].treatmentDate ||
            new Date(),
        ),
      )
    : localizedFormatDate(parseYMD(treatment?.treatmentDate || new Date()))
  const sessionMaxDate = existingSessionIndex
    ? existingSessionIndex + 1 == treatmentSessions.length
      ? undefined
      : localizedFormatDate(
          parseYMD(treatmentSessions[existingSessionIndex + 1].treatmentDate),
        )
    : undefined

  const { createTreatmentSessionValidations } = useTreatmentSession(
    sessionMinDate,
    sessionMaxDate,
  )
  const validationSchema = useMemo(
    () => createTreatmentSessionValidations(existingSession),
    [existingSession, createTreatmentSessionValidations],
  )

  const {
    values,
    errors,
    touched,
    isSubmitting,
    isValid,
    handleBlur,
    handleSubmit,
    setFieldValue,
  } = useFormik<TreatmentSessionFormValues>({
    enableReinitialize: true,
    validationSchema,
    initialValues: existingSession
      ? {
          ...existingSession,
          treatmentDate: localizedFormatDate(
            parseYMD(existingSession.treatmentDate),
          ),
        }
      : {
          siteLocationId: treatment?.siteLocationId || '',
          providerId: treatment?.providerId || '',
          parentId: treatment?.id || '',
          medicalTreatmentId: treatment?.medicalTreatmentId || '',
          treatmentProductId: treatment?.treatmentProductId || '',
          treatmentDate: '',
        },
    onSubmit: async (values) => {
      if (!patientId || !caseId) return

      const mdyTreatmentDate = localizedParseDate(values.treatmentDate)
      if (!mdyTreatmentDate) return
      const formattedTreatmentDate = format(mdyTreatmentDate, 'yyyy-MM-dd')

      if (currentSite?.siteLocations.length === 1) {
        values.siteLocationId = currentSite.siteLocations[0].id
      }

      if (existingSession && treatmentHasChanged(values, existingSession)) {
        // Updating a Treatment
        await updateTreatmentSession.mutateAsync({
          id: existingSession.id,
          patientCaseId: caseId,
          parentId: treatment?.id,
          ...values,
          treatmentDate: formattedTreatmentDate,
        })
      } else if (!existingSession) {
        // Creating a new Session
        await createTreatmentSession
          .mutateAsync({
            patientCaseId: caseId,
            parentId: treatment?.id,
            ...values,
            treatmentDate: formattedTreatmentDate,
          })
          .then((session) => {
            push(
              PatientFlowUrls.session.create(
                patientId,
                caseId,
                session.parentId || '',
                session.id,
              ),
            )
          })
      } else {
        // Just continue to the next apge
      }

      setPageState('page2-edit')
    },
  })

  const { data: treatmentProducts = [] } = useTreatmentProducts(
    values.medicalTreatmentId,
  )

  const availableMedicalTreatmentOptions = medicalTreatments.filter(
    (treatment) => {
      if (patientCase?.study && patientCase.patientTreatments?.length == 0) {
        return patientCase?.study?.medicalTreatments
          .map((mt) => mt.id)
          .includes(treatment.id)
      }
      return treatment.onlyForStudy == false
    },
  )

  const availableTreatmentProductOptions = treatmentProducts.filter(
    (product) => {
      if (patientCase?.study && patientCase.patientTreatments?.length == 0) {
        return patientCase?.study?.treatmentProducts
          .map((tp) => tp.id)
          .includes(product.id)
      }
      return product.onlyForStudy == false
    },
  )
  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    )
  }

  if (
    patientCase &&
    patientCase.patientDiagnoses &&
    patientCase?.patientDiagnoses.length === 0 &&
    !location.state?.from?.includes('/diagnosis')
  ) {
    return (
      <Redirect
        to={PatientFlowUrls.diagnosis.create(
          patientCase.patientId,
          patientCase.id,
        )}
      />
    )
  }

  return (
    <>
      <div className={classes.header}>
        <H5 paragraph>{bodySiteName} (Additional Session)</H5>
        {existingSession && (
          <AppLink
            underline="hover"
            to={PatientFlowUrls.addSession.create(
              patientId || '',
              caseId || '',
              treatmentId || '',
            )}
          >
            <Body1>Add New Session</Body1>
          </AppLink>
        )}
      </div>
      <Divider />

      {pageState === 'page1-edit' ? (
        <form className={classes.form} onSubmit={handleSubmit}>
          {currentSite && currentSite.siteLocations.length > 1 && (
            <FormControl style={{ width: '100%' }} error={!!errors.providerId}>
              <InputLabel id="site-location-label" required>
                Clinic Location
              </InputLabel>
              <Select
                labelId="site-location-label"
                placeholder="Select"
                value={values.siteLocationId}
                fullWidth
                required
                onChange={(event) => {
                  setFieldValue('siteLocationId', event.target.value)
                }}
              >
                {currentSite?.siteLocations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.siteLocationId && (
                <FormHelperText error>{errors.siteLocationId}</FormHelperText>
              )}
            </FormControl>
          )}
          <FormControl style={{ width: '100%' }} error={!!errors.providerId}>
            <InputLabel id="provider-label" required>
              Provider
            </InputLabel>
            <Select
              labelId="provider-label"
              placeholder="Select"
              value={values.providerId}
              fullWidth
              required
              onChange={(event) => {
                setFieldValue('providerId', event.target.value)
              }}
            >
              {providers.map((prov) => (
                <MenuItem key={prov.id} value={prov.id}>
                  {buildName(prov)}
                </MenuItem>
              ))}
            </Select>
            {errors.providerId && (
              <FormHelperText error>{errors.providerId}</FormHelperText>
            )}
          </FormControl>
          <Autocomplete
            options={availableMedicalTreatmentOptions}
            getOptionLabel={(opt) =>
              opt.name in TreatmentTypeNameCustomizations
                ? TreatmentTypeNameCustomizations[opt.name]
                : opt.name
            }
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            filterOptions={(options, { inputValue }) =>
              inputValue
                ? matchSorter(options, inputValue, { keys: ['name'] })
                : options
            }
            value={
              availableMedicalTreatmentOptions.find(
                (treatment) => treatment.id === values.medicalTreatmentId,
              ) ?? null
            }
            onChange={(_event, treatment) => {
              setFieldValue('medicalTreatmentId', treatment?.id || '')
            }}
            renderInput={(params) => {
              // @ts-expect-error Mui's autocomplete types here are incomplete
              params.InputLabelProps.shrink = true
              return (
                <TextField
                  {...params}
                  label="Treatment Type"
                  name="medicalTreatmentId"
                  required
                  value={values.medicalTreatmentId}
                  onBlur={handleBlur}
                  error={
                    touched.medicalTreatmentId &&
                    Boolean(errors.medicalTreatmentId)
                  }
                  helperText={
                    touched.medicalTreatmentId && errors.medicalTreatmentId
                  }
                />
              )
            }}
          />
          <Autocomplete
            options={availableTreatmentProductOptions}
            getOptionLabel={(opt) => opt.name}
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            filterOptions={(options, { inputValue }) =>
              inputValue
                ? matchSorter(options, inputValue, { keys: ['name'] })
                : options
            }
            value={
              availableTreatmentProductOptions.find(
                (prod) => prod.id === values.treatmentProductId,
              ) ?? null
            }
            onChange={(_event, prov) => {
              setFieldValue('treatmentProductId', prov?.id || '')
            }}
            renderInput={(params) => {
              // @ts-expect-error Mui's autocomplete types here are incomplete
              params.InputLabelProps.shrink = true
              return (
                <TextField
                  {...params}
                  name="treatmentProductId"
                  label="Product Name"
                  onBlur={handleBlur}
                  required
                  value={values.treatmentProductId}
                  error={
                    touched.treatmentProductId &&
                    Boolean(errors.treatmentProductId)
                  }
                  helperText={
                    touched.treatmentProductId && errors.treatmentProductId
                  }
                />
              )
            }}
          />
          <DatePicker
            label="Treatment Date"
            name="treatmentDate"
            // defaultDate={localizedFormatDate(aWeekFromNow)}
            inputFormat={isCanada ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
            required
            fullWidth
            minDate={
              sessionMinDate ? new Date(sessionMinDate || '') : undefined
            }
            maxDate={
              sessionMaxDate ? new Date(sessionMaxDate || '') : undefined
            }
            isEditing={values.treatmentDate ? values.treatmentDate : undefined}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            error={touched.treatmentDate && Boolean(errors.treatmentDate)}
            helperText={touched.treatmentDate && errors.treatmentDate}
          />
          <Button
            type="submit"
            fullWidth
            loading={isSubmitting}
            disabled={!isValid}
          >
            Next
          </Button>
        </form>
      ) : (
        existingSession &&
        medicalTreatment && (
          <Box pb={3} pt={1}>
            <TreatmentDetail
              treatment={existingSession}
              onEditClick={() => setPageState('page1-edit')}
              onDeleteClick={() => handleDeleteTreatment()}
              withDetails={pageState === 'readonly'}
            />
            {pageState === 'page2-edit' && (
              <Divider className={classes.divider} />
            )}
          </Box>
        )
      )}

      {existingSession && patientCase && pageState === 'page2-edit' && (
        <TreatmentDetailsForm
          treatment={existingSession}
          patientCase={patientCase}
          medicalTreatmentSlug={medicalTreatment?.slug || ''}
          onSubmit={() => setPageState('readonly')}
        />
      )}
    </>
  )
}

export default Treatment
