import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { BodySiteKeyCamelCase } from './useBodySites'

interface PatientDiagnosisStatsResponse
  extends Partial<Record<BodySiteKeyCamelCase, number>> {}

const usePatientDiagnosisStats = (
  extraOptions: UseQueryOptions<PatientDiagnosisStatsResponse> = {},
) => {
  const currentSiteId = useCurrentSite()?.id || ''
  const key = QueryKeys.patientDiagnosisStats(currentSiteId)
  const url = `/sites/patient_diagnoses/stats?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () => request.get<PatientDiagnosisStatsResponse>(url).then((r) => r.data),
    {
      enabled: Boolean(currentSiteId),
      ...extraOptions,
    },
  )
}

export default usePatientDiagnosisStats
