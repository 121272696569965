import { useQuery, UseQueryOptions } from 'react-query'

import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Study } from './useStudies'

export interface Site {
  id: string
  name: string
  organizationId: string
  country: 'united_states_of_america' | 'canada'
  timeZone: string
  studies: Study[]
  specialty: string[]
  siteLocations: SiteLocation[]
  organization: Organization
}

export interface SiteLocation {
  id: string
  name: string
  siteId: string
  isPrimary: boolean
  street: string
  unitApartmentSuite: string
  city: string
  stateProvince: string
  zipCode: string
  phone: string
}

export interface Organization {
  id: string
  name: string
}
interface SitesAvailableResponse {
  sites: Site[]
}

const useSitesAvailable = (extraOptions: UseQueryOptions<Site[]> = {}) => {
  const key = QueryKeys.sitesAvailable()
  const url = `/sites/available`

  return useQuery(
    key,
    () => request.get<SitesAvailableResponse>(url).then((r) => r.data.sites),
    {
      // Products are static - don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useSitesAvailable
