var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import MuiSwitch from '@mui/material/Switch';
import clsx from 'clsx';
import { Body2, makeStyles } from '../';
const useStyles = makeStyles((theme) => ({
    labelRoot: {
        marginLeft: 'unset',
    },
    text: {
        // This prevents the component from changing width when the On/Off text changes
        display: 'inline-block',
        minWidth: '3.25ex',
    },
    textChecked: {
        color: theme.palette.success.main,
    },
}), { name: 'Switch' });
/** Wraps the Material UI Switch with an "On" / "Off" label. */
const Switch = (_a) => {
    var { checked: checkedProp, defaultChecked, offLabel = 'Off', onLabel = 'On', onChange: onChangeProp } = _a, rest = __rest(_a, ["checked", "defaultChecked", "offLabel", "onLabel", "onChange"]);
    const classes = useStyles();
    // Keep track of the checked state so we can set the On/Off label.
    const [checked, setChecked] = useState(checkedProp !== undefined ? checkedProp : defaultChecked);
    const handleInputChange = (event) => {
        const newChecked = event.target.checked;
        setChecked(newChecked);
        if (onChangeProp) {
            onChangeProp(event, newChecked);
        }
    };
    return (_jsx(FormControlLabel, Object.assign({ classes: { root: classes.labelRoot }, checked: checked, control: _jsx(MuiSwitch, {}, void 0), label: _jsx(Body2, Object.assign({ classes: {
                root: clsx(classes.text, {
                    [classes.textChecked]: checked && !rest.disabled,
                }),
            }, component: "span" }, { children: checked ? onLabel : offLabel }), void 0), labelPlacement: "start", onChange: handleInputChange }, rest), void 0));
};
export default Switch;
