import { QueryClient } from 'react-query'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // By default, cached data is immediately considered stale.
      // This causes queries to refetch very frequently, so let's
      // set a simple default to avoid continuous re-fetchings
      staleTime: 60 * 1000,

      retry: false,
    },
  },
})

export default queryClient
