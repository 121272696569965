import { FC, useEffect, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  DatePicker,
  Divider,
  H5,
  makeStyles,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@perk-ui/core'
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInYears,
  format,
  isFuture,
  isToday,
} from 'date-fns'
import { useFormik } from 'formik'
import { matchSorter } from 'match-sorter'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import { useSitesContext } from '../../../components/SitesProvider'
import useAnatomicalLocations, {
  AnatomicalLocation,
} from '../../../features/query-hooks/useAnatomicalLocations'
import useCreateCase from '../../../features/query-hooks/useCreateCase'
import useCreateDiagnosis, {
  PatientDiagnosisSeverity,
} from '../../../features/query-hooks/useCreateDiagnosis'
import useCreateTreatment from '../../../features/query-hooks/useCreateTreatment'
import usePatient from '../../../features/query-hooks/usePatient'
import usePatientCases from '../../../features/query-hooks/usePatientCases'
import useProtocols, {
  Protocol,
} from '../../../features/query-hooks/useProtocols'
import useSpecialtyBodySites, {
  BodySite,
} from '../../../features/query-hooks/useSpecialtyBodySites'
import useStudies, { Study } from '../../../features/query-hooks/useStudies'
import useDates from '../../../hooks/useDates'
import { useHistory } from '../../../utils/useHistory'
import PatientFlowUrls, { PatientFlowLocationParams } from '../PatientFlowUrls'

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
  },
}))

interface AddCaseFormValues {
  bodySiteId: string
  protocol?: Protocol
  anticipatedTreatmentDate: string
  studyId: string
  // patientStudyNumber: string
  enrollment: string
}

export interface AddCaseProps {}

const AddCase: FC<AddCaseProps> = () => {
  const classes = useStyles()
  const createCase = useCreateCase()
  const { push } = useHistory()
  const [availableBodySitesOptions, setAvailableBodySitesOptions] = useState<
    BodySite[]
  >([])
  const patientIdMatch = useRouteMatch<PatientFlowLocationParams>(
    PatientFlowUrls.hasPatient.match,
  )
  const patientId = patientIdMatch?.params.patientId
  const { currentSite } = useSitesContext()
  const { data: bodySites = [] } = useSpecialtyBodySites()
  const { data: studies = [] } = useStudies()
  const { data: patient } = usePatient(patientId || '', {
    enabled: Boolean(patientId),
  })

  const { data: patientCases = [] } = usePatientCases(patientId)
  const { localizedParseDate, localizedDatePattern } = useDates()
  const { data: protocols } = useProtocols('FullProtocol')
  const noneStudy = studies.find((s) => s.slug === 'none')
  const createDiagnosis = useCreateDiagnosis()
  const createTreatment = useCreateTreatment()
  const [protocolAnatomicalLocations, setProtocolAnatomicalLocations] =
    useState<AnatomicalLocation[]>([])

  const country = currentSite?.country
  const isCanada = country === 'canada'

  const validationSchema = yup.object({
    bodySiteId: yup.string().defined('Body Part must be selected'),
    anticipatedTreatmentDate: yup
      .string()
      .defined('Treatment date is required for scheduling assessments')
      .test(
        'is-valid-date',
        `Treatment must be in format ${localizedDatePattern.toLowerCase()} and a valid date`,
        (value = '') => !!localizedParseDate(value),
      )
      .test(
        'is-after-yesterday',
        'Treatment date must be today or later',
        (value = '') => {
          const asDate = localizedParseDate(value)
          return asDate && (isToday(asDate) || isFuture(asDate))
        },
      )
      .test(
        'is-before-a-year',
        'Treatment cannot be after 1 year from today',
        (value = '') => {
          const asDate = localizedParseDate(value)
          return asDate && differenceInYears(asDate, new Date()) < 1
        },
      ),
    studyId: yup.string(),
    // patientStudyNumber: yup.string(),
  })

  const usedBodySites = patientCases
    .filter((pc) => pc.status === 'active')
    .map((pc) => pc.bodySiteId)
  const availableBodySites = bodySites.filter(
    (site) => !usedBodySites.includes(site.id),
  )
  useEffect(() => {
    const filteredBodySites = availableBodySites.filter(
      (bs) => bs.onlyForStudy == false,
    )
    setAvailableBodySitesOptions(filteredBodySites)
  }, [bodySites])

  const {
    errors,
    touched,
    values,
    handleBlur,
    // handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
    setFieldError,
  } = useFormik<AddCaseFormValues>({
    validationSchema,
    initialValues: {
      bodySiteId: '',
      anticipatedTreatmentDate: '',
      studyId: noneStudy?.id || '',
      // patientStudyNumber: '',
      enrollment: 'standard',
    },
    onSubmit(values) {
      if (!patientId) return

      const asDate = localizedParseDate(values.anticipatedTreatmentDate)
      if (!asDate) return
      values.anticipatedTreatmentDate = format(asDate, 'yyyy-MM-dd')

      return createCase
        .mutateAsync({
          patientId: patientId,
          protocolId: values.protocol?.id,
          ...values,
        })
        .then(async (pCase) => {
          if (values.protocol) {
            await createDiagnosis.mutateAsync({
              patientCaseId: pCase.id,
              protocolId: values.protocol?.id,
              medicalConditionId:
                (values.protocol?.protocolDiagnoses || [])[0]
                  .medicalConditionId || '',
              severity: (values.protocol?.protocolDiagnoses || [])[0]
                .severity as PatientDiagnosisSeverity,
              isPrimary: (values.protocol?.protocolDiagnoses || [])[0]
                .isPrimary,
              locations: protocolAnatomicalLocations,
              patientId: patientId,
            })

            const protocolPrimaryTreatments =
              values.protocol?.protocolTreatments || []
            if (protocolPrimaryTreatments) {
              protocolPrimaryTreatments?.sort().map(async (treatment) => {
                let unformattedTreatmentDate
                switch (treatment.periodType) {
                  case 'days':
                    unformattedTreatmentDate = addDays(
                      new Date(values.anticipatedTreatmentDate),
                      treatment.periodAmount || 0,
                    )
                    break
                  case 'weeks':
                    unformattedTreatmentDate = addWeeks(
                      new Date(values.anticipatedTreatmentDate),
                      treatment.periodAmount || 0,
                    )
                    break
                  case 'months':
                    unformattedTreatmentDate = addMonths(
                      new Date(values.anticipatedTreatmentDate),
                      treatment.periodAmount || 0,
                    )
                    break
                  case 'years':
                    unformattedTreatmentDate = addYears(
                      new Date(values.anticipatedTreatmentDate),
                      treatment.periodAmount || 0,
                    )
                    break

                  default:
                    unformattedTreatmentDate = addWeeks(
                      new Date(values.anticipatedTreatmentDate),
                      0,
                    )
                    break
                }
                await createTreatment.mutateAsync({
                  patientCaseId: pCase.id,
                  siteLocationId: treatment.siteLocationId || '',
                  providerId: treatment.staffId || '',
                  medicalTreatmentId: treatment.medicalTreatmentId,
                  treatmentProductId: treatment.treatmentProductId,
                  protocolId: values.protocol?.id,
                  treatmentDate: format(
                    unformattedTreatmentDate as Date,
                    'yyyy-MM-dd',
                  ),
                })
              })
            }
          }
          push(PatientFlowUrls.diagnosis.create(patientId, pCase.id))
        })
    },
  })

  const updateBodySitesOptions = (study: Study | null) => {
    if (study && study.slug != 'none') {
      const selectedStudy = studies.find((s) => s.id === study.id)
      if (selectedStudy) {
        const filteredAvailableBodySites = availableBodySites.filter(
          (site: BodySite) =>
            selectedStudy.bodySites.map((bs) => bs.slug).includes(site.slug),
        )
        setAvailableBodySitesOptions(filteredAvailableBodySites)
        if (selectedStudy.bodySites.length === 1) {
          setFieldValue('bodySiteId', selectedStudy.bodySites[0].id)
        }
      }
    } else {
      const filteredBodySites = bodySites.filter(
        (bs) => bs.onlyForStudy == false,
      )
      setAvailableBodySitesOptions(filteredBodySites)
    }
  }

  const { data: anatomicalLocations = [] } = useAnatomicalLocations(
    (values.protocol?.protocolDiagnoses || [])[0]?.medicalConditionId || '',
  )

  useEffect(() => {
    if (values.protocol && anatomicalLocations) {
      const pLocations = anatomicalLocations.filter((al) =>
        (
          (values.protocol?.protocolDiagnoses || [])[0]?.locations || []
        ).includes(al.name),
      )
      setProtocolAnatomicalLocations(pLocations || [])
    }
  }, [values.protocol])

  // const isQckStudy = studies && studies.find(
  //       (study) =>
  //         study.id === values.studyId &&
  //         study.slug ===
  //           'platelet-rich-plasma-and-the-effects-of-nsaids-on-pain-and-functional-scores-in-knee-osteoarthritis',
  //     ) !== undefined

  return (
    <>
      <H5 paragraph>Add Body Part</H5>
      <Divider />
      <form
        className={classes.form}
        onSubmit={handleSubmit}
        id="patient-flow-add-case-form"
      >
        <ToggleButtonGroup
          id="patient-flow-add-case-enrollment-toggle"
          color="primary"
          value={values.enrollment}
          exclusive
          fullWidth
          onChange={(_event, s) => {
            setFieldValue('enrollment', s)
            if (s === 'standard') {
              const noneStudy = studies.find((s) => s.slug === 'none')
              setFieldValue('studyId', noneStudy?.id || '')
              setFieldValue('bodySiteId', '')
              updateBodySitesOptions(noneStudy || null)
            } else {
              setFieldValue('studyId', '')
              setFieldValue('bodySiteId', '')
              setFieldValue('protocol', null)
              updateBodySitesOptions(null)
            }
          }}
          aria-label="Platform"
        >
          <ToggleButton
            id="patient-flow-add-case-enrollment-standard"
            value="standard"
          >
            <Box>
              Standard <br />
              <span>General Care</span>
            </Box>
          </ToggleButton>
          <ToggleButton
            id="patient-flow-add-case-enrollment-study"
            value="study"
          >
            <Box>
              Study
              <br />
              <span>Research Participant</span>
            </Box>
          </ToggleButton>
        </ToggleButtonGroup>
        {protocols && protocols.length > 0 && values.enrollment !== 'study' && (
          <Autocomplete
            id="patient-flow-treatment-protocol"
            options={protocols || []}
            getOptionLabel={(opt) => opt.name}
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            filterOptions={(options, { inputValue }) =>
              inputValue
                ? matchSorter(options, inputValue, { keys: ['name'] })
                : options
            }
            value={values.protocol}
            fullWidth
            onChange={(_event, data) => {
              setFieldValue('protocol', data)
              const protocolBodySite = availableBodySitesOptions.find(
                (bs) => bs.id === data?.bodySite?.id,
              )
              if (protocolBodySite) {
                setFieldValue('bodySiteId', protocolBodySite.id)
              } else {
                setFieldValue('bodySiteId', '')
              }
              if (!protocolBodySite) {
                setFieldError(
                  'protocol',
                  'Body site of this protocol not available',
                )
              }
            }}
            renderInput={(params) => {
              // @ts-expect-error Mui's autocomplete types here are incomplete
              params.InputLabelProps.shrink = true
              return (
                <TextField
                  {...params}
                  label="Protocol"
                  error={touched.protocol && Boolean(errors.protocol)}
                  helperText={touched.protocol && errors.protocol}
                />
              )
            }}
          />
        )}
        {currentSite?.studies &&
          currentSite?.studies.length > 0 &&
          values.enrollment === 'study' && (
            <Autocomplete
              id="patient-flow-add-case-study-autocomplete"
              options={studies}
              getOptionLabel={(opt) => opt.shortName}
              disabled={
                !patient?.email &&
                studies.length ===
                  studies.filter((study) => study.hasEconsent === true).length
              }
              getOptionDisabled={(option) =>
                option.hasEconsent === true && !patient?.email
              }
              onChange={(_event, study) => {
                setFieldValue('studyId', study?.id || '')
                setFieldValue('bodySiteId', '')
                updateBodySitesOptions(study)
              }}
              filterOptions={(options, { inputValue }) =>
                inputValue
                  ? matchSorter(options, inputValue, { keys: ['shortName'] })
                  : options
              }
              renderInput={(params) => {
                // @ts-expect-error Mui's autocomplete types here are incomplete
                params.InputLabelProps.shrink = true
                return (
                  <TextField
                    {...params}
                    name="studyId"
                    label="Study Enrollment"
                    onBlur={handleBlur}
                    disabled={
                      !patient?.email &&
                      studies.length ===
                        studies.filter((study) => study.hasEconsent === true)
                          .length
                    }
                    value={values.studyId}
                    helperText={
                      Boolean(!patient?.email) &&
                      (studies.length ===
                      studies.filter((study) => study.hasEconsent === true)
                        .length
                        ? "Patient's email is required for study enrollment"
                        : studies.length ===
                          studies.filter((study) => study.hasEconsent === false)
                            .length
                        ? ''
                        : "Patient's email is required for some studies")
                    }
                  />
                )
              }}
            />
          )}
        <Autocomplete
          id="patient-flow-add-case-body-site-autocomplete"
          options={availableBodySitesOptions}
          getOptionLabel={(opt) => opt.name}
          noOptionsText="No other body parts available"
          onChange={(_event, site) => {
            setFieldValue('bodySiteId', site?.id || '')
          }}
          filterOptions={(options, { inputValue }) =>
            inputValue
              ? matchSorter(options, inputValue, { keys: ['name'] })
              : options
          }
          disabled={values.protocol ? true : false}
          value={
            availableBodySites.find((site) => site.id === values.bodySiteId) ??
            null
          }
          renderInput={(params) => {
            // @ts-expect-error Mui's autocomplete types here are incomplete
            params.InputLabelProps.shrink = true
            return (
              <TextField
                {...params}
                name="bodySiteId"
                label="Body Part"
                onBlur={handleBlur}
                required
                InputProps={{
                  ...params.InputProps,
                }}
                error={touched.bodySiteId && Boolean(errors.bodySiteId)}
                helperText={touched.bodySiteId && errors.bodySiteId}
              />
            )
          }}
        />
        <DatePicker
          id="patient-flow-add-case-treatment-date"
          label="Treatment Date"
          name="anticipatedTreatmentDate"
          // defaultDate={localizedFormatDate(aWeekFromNow)}
          inputFormat={isCanada ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
          required
          fullWidth
          minDate={new Date()}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
          error={
            touched.anticipatedTreatmentDate &&
            Boolean(errors.anticipatedTreatmentDate)
          }
          helperText={
            touched.anticipatedTreatmentDate && errors.anticipatedTreatmentDate
          }
        />
        {/* {isQckStudy && (
          <TextField
            label="Research Study Number"
            name="patientStudyNumber"
            fullWidth
            value={values.patientStudyNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.patientStudyNumber && Boolean(errors.patientStudyNumber)
            }
            helperText={
              touched.patientStudyNumber && errors.patientStudyNumber
            }
          />
        )} */}
        <Button
          id="patient-flow-add-case-submit-button"
          type="submit"
          fullWidth
          loading={isSubmitting}
        >
          Add
        </Button>
      </form>
    </>
  )
}

export default AddCase
