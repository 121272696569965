import { FC, useEffect, useRef, useState } from 'react'
import {
  Body1,
  Box,
  Button,
  Chip,
  DatePicker,
  Dialog,
  DialogTrigger,
  Divider,
  findBy,
  FormControl,
  FormHelperText,
  H2,
  H3,
  Icon,
  IconButton,
  InputLabel,
  Link as ELink,
  makeStyles,
  MenuItem,
  Popover,
  Select,
  TextField,
  Tooltip,
} from '@perk-ui/core'
import clsx from 'clsx'
import { endOfToday, format, getYear, isPast } from 'date-fns'
import { useFormik } from 'formik'
import { AlertTriangle, ArrowLeft, Check, ChevronRight, X } from 'react-feather'
import { Link, useLocation } from 'react-router-dom'
import * as yup from 'yup'

import { useSitesContext } from '../../../components/SitesProvider'
import TreatmentDetail from '../../../components/TreatmentDetail'
import {
  adverseEventsIssues,
  adverseEventsSeekingMedicalAttention,
} from '../../../config/constants'
import { useUser } from '../../../features/auth/AuthContext'
import {
  AdverseEvent,
  ExpectednessLabelMap,
  RelatednessLabelMap,
  SeriousnessLabelMap,
} from '../../../features/query-hooks/useAdverseEvents'
import useBodySites from '../../../features/query-hooks/useBodySites'
import useCreateAdverseEvent from '../../../features/query-hooks/useCreateAdverseEvent'
import {
  PatientCase,
  StudyConsent,
} from '../../../features/query-hooks/usePatientCases'
import useSurveyResponseSearch from '../../../features/query-hooks/useSurveyResponseSearch'
import useDates from '../../../hooks/useDates'
import DiagnosisDetail from './DiagnosisDetail'

export interface CaseDetailsProps {
  // Would love to name this just `case`, but that is a reserved keyword in Javascript.
  patientCase: PatientCase
  index: number
}

interface AdverseEventFormValues {
  dateOfProblem?: string
  seekingMedicalAtention: AdverseEvent['seekingMedicalTreatment']
  issue: string
  otherIssue: string
  patientNotes: string
  seriousness: AdverseEvent['seriousness']
  expectedness: AdverseEvent['expectedness']
  relatedness: AdverseEvent['relatedness']
  providerNotes: string
}

const useStyles = makeStyles((theme) => ({
  titleDivider: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  archiveText: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
  },
  patientCaseTitle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p': {
      marginBottom: 0,
    },
    marginBottom: theme.spacing(1),
  },
  studyId: {
    height: '20px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& p': {
      marginBottom: 0,
      marginTop: 0,
      fontSize: '18px',
    },
    marginBottom: theme.spacing(1),
  },
  disabledStudyChip: {},
  noConsentStudyChip: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  enabledStudyChip: {
    backgroundColor: '#00A800',
    color: theme.palette.common.white,
    '&:hover': {
      backgroundColor: '#8AD78A',
      color: theme.palette.common.white,
    },
  },
  textContainer: {
    marginBottom: '22px',
    '& span': {
      fontSize: '14px',
      '& b': {
        color: '#33B933',
      },
    },
  },
  popoverContainer: {},
  download: {
    color: theme.palette.common.white,
    backgroundColor: theme.palette.primary.main,
    fontWeight: 600,
    fontSize: '16px',
    width: '100%',
    display: 'block',
    textAlign: 'center',
    borderRadius: '4px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '&:hover': {
      textDecoration: 'none',
      background: theme.palette.primary.dark,
    },
    '&:visited': {
      color: theme.palette.common.white,
    },
    '&:link': {
      color: theme.palette.common.white,
    },
    cursor: 'pointer',
  },
  studyTagTooltip: {
    '& .MuiTooltip-tooltip': {
      maxWidth: 'none',
    },
  },
  dialog: {
    width: '100%',
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '457px',
        padding: theme.spacing(1),
      },
    },
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  consentRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '10px',
  },
  completedDate: {
    color: '#00A800',
    fontWeight: 'bold',
  },
  viewLink: {
    color: '#2396CF',
    size: '16px',
    fontWeight: 'bold',
    cursor: 'pointer',
  },
  aeTitleSection: {
    padding: theme.spacing(2, 0, 4, 0),
    '& h3': {
      marginBottom: theme.spacing(1),
    },
  },
  stepperContainer: {
    flex: '0 1 10px',
    display: 'flex',
    flexDirection: 'row',
    gridGap: theme.spacing(1),
    padding: theme.spacing(1, 0),
  },
  step: {
    borderRadius: theme.spacing(1),
    width: '50%',
    height: '10px',
    background: '#F1F1F1',
  },
  activeStep: {
    background: 'var(--brand-gradient,linear-gradient(118deg,#2badee,#526ceb))',
  },
  aeContainer: {
    padding: theme.spacing(4),
  },
  aeHeaderContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
  aeHeaderContainerAlt: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  aeSubmitButton: {
    borderRadius: '20px',
  },
  adverseEventDialogContainer: {
    display: 'flex',
    justifyContent: 'right',
  },
  dialogPaper: {
    maxHeight: 1000,
    maxWidth: 550,
  },
  dialogPaperConfirmation: {
    maxHeight: 1000,
    maxWidth: 550,
  },
  aeConfirmation: {
    padding: theme.spacing(2),
    textAlign: 'center',
    '& svg': {
      display: 'block',
      margin: 'auto',
      padding: theme.spacing(1),
      borderRadius: '45px',
      backgroundColor: '#4DF4B8',
    },
    '& h3': {
      padding: theme.spacing(2, 0, 4, 0),
    },
  },
  aeConfirmationButton: {
    border: '1px solid #80CEF5',
    '&:hover': {
      border: '1px solid #80CEF5',
      opacity: 0.7,
    },
  },
}))

const CaseDetails: FC<CaseDetailsProps> = ({ patientCase, index }) => {
  const classes = useStyles()
  const { data: bodySites = [] } = useBodySites()
  const { currentSite } = useSitesContext()
  const currentUser = useUser()
  const isProvider = currentUser?.staff?.type === 'Provider'
  const { localizedFormatDate: localizedFormatDateWithTime } = useDates(true)
  const addAdverseEvent = useCreateAdverseEvent()
  const { pathname } = useLocation()

  const partName = findBy('id', patientCase.bodySiteId, bodySites)
  const diagnoses = patientCase.patientDiagnoses || []
  const treatments = patientCase.patientTreatments || []
  const sortedDiagnoses = diagnoses.sort((a) => (a.isPrimary ? -1 : 1))
  const { data: allSurveys } = useSurveyResponseSearch({
    ownerIds: [patientCase.id],
    ownerType: 'PatientCase',
    includes: ['survey_response_scores'],
    perPage: '300',
  })
  const patientCaseScoreLink = `/patients/${patientCase.patientId}/patient-case-surveys/${patientCase.id}`
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [latestConsent, setLatestConsent] = useState<StudyConsent | undefined>(
    undefined,
  )
  const [openPastConsents, setOpenPastConsents] = useState<boolean>(false)
  const [popoverOrigin, setPopoverOrigin] = useState<'top' | 'bottom'>('bottom')
  const open = Boolean(anchorEl)
  const myRef = useRef<HTMLDivElement>(null)

  const country = currentSite?.country
  const isCanada = country === 'canada'
  const { localizedParseDate, localizedDatePattern } = useDates()
  const [currentAEStep, setCurrentAEStep] = useState<number>(1)
  const isDyspareunia =
    patientCase.study?.slug ==
    'randomized-controlled-trial-for-use-of-low-intensity-shockwave-for-patients-with-dyspareunia'
  const adverseEventSteps = [
    {
      step: 1,
      key: 'adverse-event',
    },
    {
      step: 2,
      key: 'adjudication',
    },
  ]
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const chipOriginTop = myRef?.current?.getBoundingClientRect().top || 0
    const windowHeight = window.innerHeight
    if (chipOriginTop > windowHeight / 2) {
      if (popoverOrigin !== 'top') setPopoverOrigin('top')
    } else {
      if (popoverOrigin !== 'bottom') setPopoverOrigin('bottom')
    }
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  useEffect(() => {
    setLatestConsent(
      patientCase.studyConsents[patientCase.studyConsents.length - 1],
    )
  }, [patientCase])

  const adverseEventValidations = yup.object({
    dateOfProblem: yup
      .string()
      .defined('Problem date is required')
      .test(
        'is-valid-date',
        `Problem date must be in format ${localizedDatePattern.toLowerCase()} and a valid date`,
        (value = '') => !!localizedParseDate(value),
      )
      .test(
        'is-before-now',
        'Problem date must be before today',
        (value = '') => {
          const asDate = localizedParseDate(value)
          return asDate && isPast(asDate)
        },
      )
      .test('is-after-year-1900', 'Year must be after 1900', (value = '') => {
        const asDate = localizedParseDate(value)
        return asDate && getYear(asDate) >= 1900
      }),
    seekingMedicalAtention: yup.string().defined('Field is required'),
    issue: yup.string().defined('Issue is required'),
    otherIssue: yup.string(),
    patientNotes: yup.string(),
    seriousness: yup.string().oneOf(Object.keys(SeriousnessLabelMap)),
    expectedness: yup.string().oneOf(Object.keys(ExpectednessLabelMap)),
    relatedness: yup.string().oneOf(Object.keys(RelatednessLabelMap)),
  })
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    resetForm,
    isSubmitting,
    isValid,
    validateForm,
    touched,
  } = useFormik<AdverseEventFormValues>({
    enableReinitialize: true,
    initialValues: {
      dateOfProblem: '' as AdverseEvent['dateOfProblem'],
      seekingMedicalAtention: '' as AdverseEvent['seekingMedicalTreatment'],
      issue: '' as AdverseEvent['issue'],
      otherIssue: '' as AdverseEvent['issue'],
      patientNotes: '',
      seriousness: '' as AdverseEvent['seriousness'],
      expectedness: '' as AdverseEvent['expectedness'],
      relatedness: '' as AdverseEvent['relatedness'],
      providerNotes: '',
    },
    validationSchema: adverseEventValidations,
    onSubmit: (values) => {
      // Trim whitespace from all values
      Object.keys(values).forEach((key) => {
        const k = key as keyof AdverseEventFormValues
        if (typeof values[k] === 'string') {
          ;(values[k] as string).trim()
        }
      })
      // Format to mm/dd/yyyy
      const dop = localizedParseDate(values.dateOfProblem || '')

      if (!dop) return

      const aeIssue =
        values.issue === 'other' ? values.otherIssue : values.issue
      return addAdverseEvent
        .mutateAsync({
          patientCaseId: patientCase.id,
          seekingMedicalTreatment: values.seekingMedicalAtention,
          bodySiteId: patientCase.bodySiteId,
          patientId: patientCase.patientId,
          providerId: treatments[0].providerId || '',
          siteId: patientCase.siteId,
          seriousness: values.seriousness,
          expectedness: values.expectedness,
          relatedness: values.relatedness,
          patientNotes: values.patientNotes,
          providerNotes: values.providerNotes,
          dateOfProblem: format(dop, 'yyyy-MM-dd'),
          issue: aeIssue,
        })
        .then((res) => {
          if (res) {
            setCurrentAEStep(3)
          }
        })
    },
  })

  // const isQckStudy = patientCase.study?.slug === 'platelet-rich-plasma-and-the-effects-of-nsaids-on-pain-and-functional-scores-in-knee-osteoarthritis'
  return (
    <>
      <div
        className={classes.patientCaseTitle}
        id={'patient-case-details' + index}
      >
        <H3 paragraph>{partName?.name || ''}</H3>
        {patientCase.study && (
          <Tooltip
            classes={{ popper: classes.studyTagTooltip }}
            arrow
            placement="bottom"
            title={
              patientCase.signedConsent
                ? ''
                : patientCase.study.hasEconsent
                ? 'eConsent not completed'
                : ''
            }
          >
            <Chip
              ref={myRef}
              size="medium"
              label={patientCase.study ? patientCase.study.shortName : ''}
              variant="filled"
              className={
                patientCase.signedConsent
                  ? classes.enabledStudyChip
                  : patientCase.study.hasEconsent
                  ? classes.disabledStudyChip
                  : classes.noConsentStudyChip
              }
              onClick={
                patientCase.studyConsents.length >= 1 ? handleClick : undefined
              }
            />
          </Tooltip>
        )}
      </div>
      {patientCase.siteNumber && patientCase.patientStudyNumber && (
        <div className={classes.studyId}>
          <p>
            ID: {patientCase.siteNumber?.toString().padStart(3, '0')}-
            {patientCase.patientStudyNumber?.toString().padStart(3, '0')}
          </p>
        </div>
      )}
      {/* {isQckStudy && patientCase.patientStudyNumber && (
        <div className={classes.studyId}>
          <p>
            Research Study Number: {patientCase.patientStudyNumber}
          </p>
        </div>
      )} */}
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popoverContainer}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          sx: {
            minWidth: '268px',
            p: 2,
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
          },
        }}
      >
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: '#767676',
          }}
        >
          <X size={16} />
        </IconButton>
        <H3 paragraph style={{ fontSize: 20 }}>
          {' '}
          eConsent
        </H3>
        <Divider className={classes.titleDivider} />
        <div className={classes.textContainer}>
          {latestConsent && (
            <span key={latestConsent.id}>
              Last Completed:{' '}
              <b>
                {localizedFormatDateWithTime(new Date(latestConsent.createdAt))}
              </b>
            </span>
          )}
        </div>
        <div>
          <ELink
            className={classes.download}
            href={latestConsent?.link}
            target={'_blank'}
          >
            View eConsent
          </ELink>
        </div>
        {patientCase.studyConsents.length > 1 && (
          <Button variant="text" onClick={() => setOpenPastConsents(true)}>
            Browse past versions
          </Button>
        )}
      </Popover>
      {patientCase.status !== 'active' && (
        <Body1 className={classes.archiveText}>{`Archived: ${
          patientCase.archivalOccurrence?.archivalReason.description || ''
        }`}</Body1>
      )}
      {diagnoses.length > 0 ? (
        <Box mt={4}>
          <H3 paragraph style={{ fontSize: 20 }}>
            {diagnoses.length > 1 ? 'Diagnoses' : 'Diagnosis'}
          </H3>
          <Divider className={classes.titleDivider} />
          {sortedDiagnoses.map((diag, idx) => (
            <div key={diag.id}>
              <DiagnosisDetail
                bodySiteId={patientCase.bodySiteId}
                diagnosis={diag}
                isSecondary={idx > 0}
              />
              <Divider />
            </div>
          ))}
        </Box>
      ) : null}
      {treatments.length > 0 ? (
        <Box mt={4}>
          <H3 style={{ fontSize: 20 }}>
            {treatments.length > 1 ? 'Treatments' : 'Treatment'}
          </H3>
          <Divider className={classes.titleDivider} />
          {treatments.map((treatment) => {
            if (treatment.parentId) return
            return (
              <div key={treatment.id}>
                <TreatmentDetail
                  treatment={treatment}
                  withDetails
                  sessions={treatments.filter(
                    (pt) => pt.parentId == treatment.id,
                  )}
                  patientId={patientCase.patientId}
                />
                <Divider />
              </div>
            )
          })}
        </Box>
      ) : null}
      {allSurveys && allSurveys?.surveyResponses?.length > 0 && (
        <Link
          id={'patient-case-details' + index + '-survey-score-link'}
          to={patientCaseScoreLink}
          style={{ textDecoration: 'none', color: 'black' }}
        >
          <Box
            sx={{
              mt: 2,
              pr: 2,
              display: 'flex',
              justifyContent: 'space-between',
              flexDirection: 'row',
              pt: 2,
              pb: 1,
              cursor: 'pointer',
            }}
          >
            <H3 paragraph style={{ fontSize: 20 }}>
              Survey Scores & Schedule
            </H3>
            <Icon>
              <ChevronRight />
            </Icon>
          </Box>
        </Link>
      )}
      {isDyspareunia && pathname.includes('/patients/') && (
        <>
          <Divider />
          <Box className={classes.adverseEventDialogContainer}>
            <DialogTrigger
              action={
                <Button variant="text" startIcon={<AlertTriangle />}>
                  Report Adverse Event
                </Button>
              }
              onOpen={() => {
                setCurrentAEStep(1)
                resetForm()
              }}
              content={(toggle) => (
                <Box p={3} className={classes.aeContainer}>
                  {currentAEStep < 3 && (
                    <>
                      <Box
                        className={clsx({
                          [classes.aeHeaderContainer]: currentAEStep === 1,
                          [classes.aeHeaderContainerAlt]: currentAEStep === 2,
                        })}
                      >
                        {currentAEStep === 2 && (
                          <Button
                            variant="text"
                            startIcon={<ArrowLeft />}
                            size="small"
                            onClick={() => {
                              if (currentAEStep > 1)
                                setCurrentAEStep(currentAEStep - 1)
                            }}
                          >
                            Back
                          </Button>
                        )}
                        <IconButton
                          aria-label="close"
                          onClick={() => {
                            toggle()
                            resetForm()
                          }}
                        >
                          <X size={16} />
                        </IconButton>
                      </Box>
                      {isProvider && (
                        <Box className={classes.stepperContainer}>
                          {adverseEventSteps.map((step) => (
                            <div
                              key={step.key}
                              className={clsx(classes.step, {
                                [classes.activeStep]:
                                  currentAEStep >= step.step,
                              })}
                            />
                          ))}
                        </Box>
                      )}
                      <Box className={classes.aeTitleSection}>
                        <H3>
                          {currentAEStep === 1
                            ? 'Report Adverse Event'
                            : 'Adjudicate the report (optional)'}
                        </H3>
                        <Body1>
                          {currentAEStep === 1
                            ? 'Fill the following fields with the details of the Adverse Event'
                            : 'Fill the following adjucation details for the adverse event'}
                        </Body1>
                      </Box>
                      <form className={classes.form}>
                        {currentAEStep === 1 && (
                          <>
                            <DatePicker
                              label="When did the patient's problems or symptoms first begin?"
                              name="dateOfProblem"
                              // defaultDate={isCanada ? '31/01/1990' : '01/31/1990'}
                              inputFormat={
                                isCanada ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
                              }
                              required
                              fullWidth
                              maxDate={endOfToday()}
                              isEditing={
                                values.dateOfProblem
                                  ? values.dateOfProblem
                                  : undefined
                              }
                              setFieldValue={setFieldValue}
                              onBlur={handleBlur}
                              error={
                                touched.dateOfProblem &&
                                Boolean(errors.dateOfProblem)
                              }
                              helperText={
                                touched.dateOfProblem && errors.dateOfProblem
                              }
                            />
                            <FormControl
                              style={{ width: '100%' }}
                              error={!!errors.seekingMedicalAtention}
                            >
                              <InputLabel id="sma-label" required>
                                Did the patient seek or is seeking medical
                                attention?
                              </InputLabel>
                              <Select
                                labelId="sma-label"
                                placeholder="Select"
                                value={values.seekingMedicalAtention}
                                fullWidth
                                required
                                onChange={(event) => {
                                  setFieldValue(
                                    'seekingMedicalAtention',
                                    event.target.value,
                                  )
                                }}
                              >
                                {adverseEventsSeekingMedicalAttention.map(
                                  (sma) => (
                                    <MenuItem key={sma.value} value={sma.value}>
                                      {sma.slug}
                                    </MenuItem>
                                  ),
                                )}
                              </Select>
                              {errors.seekingMedicalAtention && (
                                <FormHelperText error>
                                  {errors.seekingMedicalAtention}
                                </FormHelperText>
                              )}
                            </FormControl>
                            <FormControl
                              style={{ width: '100%' }}
                              error={!!errors.issue}
                            >
                              <InputLabel id="ae-issue-label" required>
                                Please specify the issue
                              </InputLabel>
                              <Select
                                labelId="ae-issue-label"
                                placeholder="Select"
                                value={values.issue}
                                fullWidth
                                required
                                onChange={(event) => {
                                  setFieldValue('issue', event.target.value)
                                }}
                              >
                                {adverseEventsIssues.map((aeIssue) => (
                                  <MenuItem
                                    key={aeIssue.value}
                                    value={aeIssue.value}
                                  >
                                    {aeIssue.slug}
                                  </MenuItem>
                                ))}
                              </Select>
                              {errors.issue && (
                                <FormHelperText error>
                                  {errors.issue}
                                </FormHelperText>
                              )}
                            </FormControl>
                            {values.issue === 'other' && (
                              <TextField
                                label="Please specify 'Other'"
                                name="otherIssue"
                                placeholder="Please specify"
                                fullWidth
                                value={values.otherIssue}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                error={
                                  touched.otherIssue &&
                                  Boolean(errors.otherIssue)
                                }
                                helperText={
                                  touched.otherIssue && errors.otherIssue
                                }
                              />
                            )}
                            <TextField
                              label="Details (optional)"
                              name="patientNotes"
                              placeholder="Additional notes"
                              fullWidth
                              value={values.patientNotes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.patientNotes &&
                                Boolean(errors.patientNotes)
                              }
                              helperText={
                                touched.patientNotes && errors.patientNotes
                              }
                            />
                          </>
                        )}
                        {currentAEStep === 2 && isProvider && (
                          <>
                            <InputLabel id="seriousness">
                              Seriousness
                            </InputLabel>
                            <Select
                              labelId="seriousness"
                              required
                              value={values.seriousness}
                              onChange={(event) => {
                                const val = event.target.value
                                setFieldValue('seriousness', val)
                              }}
                            >
                              {Object.entries(SeriousnessLabelMap).map(
                                ([key, val]) => (
                                  <MenuItem key={key} value={key}>
                                    {val}
                                  </MenuItem>
                                ),
                              )}
                            </Select>

                            <InputLabel id="expectedness">
                              Expectedness
                            </InputLabel>
                            <Select
                              labelId="expectedness"
                              required
                              value={values.expectedness}
                              onChange={(event) => {
                                const val = event.target.value
                                setFieldValue('expectedness', val)
                              }}
                            >
                              {Object.entries(ExpectednessLabelMap).map(
                                ([key, val]) => (
                                  <MenuItem key={key} value={key}>
                                    {val}
                                  </MenuItem>
                                ),
                              )}
                            </Select>

                            <InputLabel id="relatedness">
                              Relatedness
                            </InputLabel>
                            <Select
                              labelId="relatedness"
                              required
                              value={values.relatedness}
                              onChange={(event) => {
                                const val = event.target.value
                                setFieldValue('relatedness', val)
                              }}
                            >
                              {Object.entries(RelatednessLabelMap).map(
                                ([key, val]) => (
                                  <MenuItem key={key} value={key}>
                                    {val}
                                  </MenuItem>
                                ),
                              )}
                            </Select>
                            <TextField
                              label="Notes"
                              name="providerNotes"
                              placeholder="Additional notes"
                              fullWidth
                              value={values.providerNotes}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              error={
                                touched.providerNotes &&
                                Boolean(errors.providerNotes)
                              }
                              helperText={
                                touched.providerNotes && errors.providerNotes
                              }
                            />
                          </>
                        )}
                        <Divider />
                        <Button
                          className={classes.aeSubmitButton}
                          fullWidth
                          loading={isSubmitting}
                          disabled={!isValid}
                          onClick={() => {
                            validateForm().then((errs) => {
                              if (Object.keys(errs).length === 0) {
                                if (isProvider) {
                                  if (currentAEStep === 1) {
                                    setCurrentAEStep(currentAEStep + 1)
                                  } else if (currentAEStep === 2) {
                                    handleSubmit()
                                  }
                                } else {
                                  handleSubmit()
                                }
                              }
                            })
                          }}
                        >
                          {currentAEStep === 2 || !isProvider
                            ? 'Report Adverse Event'
                            : 'Next'}
                        </Button>
                      </form>
                    </>
                  )}
                  {currentAEStep === 3 && (
                    <div>
                      <Box className={classes.aeHeaderContainer}>
                        <IconButton
                          aria-label="close"
                          onClick={() => {
                            toggle()
                            resetForm()
                          }}
                        >
                          <X size={16} />
                        </IconButton>
                      </Box>
                      <div className={classes.aeConfirmation}>
                        <Check size={90} />
                        <H3>Adverse Event Reported</H3>
                      </div>
                      <Button
                        fullWidth
                        variant="outlined"
                        className={classes.aeConfirmationButton}
                        onClick={() => {
                          toggle()
                          resetForm()
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  )}
                </Box>
              )}
              dialogProps={{
                PaperProps: {
                  classes: {
                    root:
                      currentAEStep === 3
                        ? classes.dialogPaperConfirmation
                        : classes.dialogPaper,
                  },
                },
              }}
            />
          </Box>
        </>
      )}

      <Dialog open={openPastConsents} className={classes.dialog}>
        <Box p={2}>
          <div className={classes.dialogTitle}>
            <H2>eConsents</H2>
            <Button
              size="small"
              variant="text"
              onClick={() => setOpenPastConsents(false)}
            >
              <Icon size="medium" color="secondaryText">
                <X />
              </Icon>
            </Button>
          </div>
          <Divider style={{ marginBottom: '15px', marginTop: '15px' }} />
          {patientCase.studyConsents
            .slice(0)
            .reverse()
            .map((consent) => {
              return (
                <div key={consent.id} className={classes.consentRow}>
                  <span>
                    Completed:{' '}
                    <span className={classes.completedDate}>
                      {localizedFormatDateWithTime(new Date(consent.createdAt))}
                    </span>
                  </span>
                  <ELink
                    className={classes.viewLink}
                    href={consent.link}
                    target={'_blank'}
                    underline="none"
                  >
                    View
                  </ELink>
                </div>
              )
            })}
        </Box>
      </Dialog>
    </>
  )
}

export default CaseDetails
