import { FC, ReactNode } from 'react'
import { Body1, Box, BoxProps, H5, Icon, makeStyles } from '@perk-ui/core'

import { LINEAR_GRADIENT_PRIMARY } from '../config/constants'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(3),

    background: LINEAR_GRADIENT_PRIMARY,
    borderRadius: theme.perk.borderRadius,
    boxShadow: theme.perk.boxShadow.card,
    color: theme.palette.common.white,
  },
  label: {
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.common.black,
    '& > svg': {
      marginRight: theme.spacing(1),
    },
  },
  icon: {
    color: theme.palette.common.white,
  },
  title: {
    color: theme.palette.common.white,
  },
}))
export interface MetricItem {
  legend?: string | ReactNode
  value: string | ReactNode
}

export interface MetricCardProps extends BoxProps {
  cardTitle: string | ReactNode
  icon?: ReactNode
  metrics: MetricItem[]
}

const MetricCard: FC<MetricCardProps> = ({
  metrics,
  cardTitle,
  icon,
  ...rest
}) => {
  const classes = useStyles()
  return (
    <Box className={classes.root} {...rest}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {icon && (
          <Box sx={{ mr: 1 }} className={classes.icon}>
            <Icon size="large">{icon}</Icon>
          </Box>
        )}
        {typeof cardTitle === 'string' ? (
          <H5 component="span" className={classes.title} fontWeight={700}>
            {cardTitle}
          </H5>
        ) : (
          cardTitle
        )}
      </Box>
      <Box sx={{ display: 'flex' }}>
        {metrics.map((metric, index) => (
          <Box
            key={`metric-card-item-${index}`}
            sx={{ flex: 'auto', ml: index === 0 ? 0 : 3 }}
          >
            <Body1 sx={{ fontSize: 56, fontWeight: 700 }}>{metric.value}</Body1>
            <Body1 fontWeight={700} fontSize={18}>
              {metric.legend}
            </Body1>
          </Box>
        ))}
      </Box>
    </Box>
  )
}

export default MetricCard
