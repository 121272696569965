import { FC, useState } from 'react'
import { Alert, Box, Button, H1, PageTitle } from '@perk-ui/core'
import { useFormik } from 'formik'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import AppLink from '../../../components/AppLink'
import SideBannerContainer from '../../../components/SideBannerContainer'
import AuthenticationBase from '../AuthenticationBase'
// import { useAuth } from '../../../features/auth/AuthContext'
import AuthenticationBanner from '../components/AuthenticationBanner'

interface AccountVerificationProps {}

export interface AccountVerificationLocationParams {
  token: string
}

interface AccountVerificationFormValues {
  token?: string
}

const COUNTDOWN_SECONDS = 3

const AccountVerification: FC<AccountVerificationProps> = () => {
  // const { signIn } = useAuth()
  const match = useRouteMatch<AccountVerificationLocationParams>()
  const token = match.params.token

  const [ticker, setTicker] = useState<number>(COUNTDOWN_SECONDS)
  const [didReset, setDidReset] = useState(false)

  const startTicking = () => {
    const tId = window.setInterval(() => {
      setTicker((tick) => {
        if (tick <= 1) {
          window.clearInterval(tId)
        }

        return tick - 1
      })
    }, 1000)
  }

  const loginValidations = yup.object({})

  const {
    // values,
    errors,
    // handleBlur,
    // handleChange,
    handleSubmit,
    // setFieldError,
    isSubmitting,
    isValid,
    // touched,
  } = useFormik<AccountVerificationFormValues>({
    enableReinitialize: true,
    initialValues: {},
    validationSchema: loginValidations,
    onSubmit: () => {
      if (token === 'testtoken123') {
        setDidReset(true)
        startTicking()

        // setTimeout(() => {
        //   push(config.authenticatedRootUrl)
        // }, COUNTDOWN_SECONDS * 1000)
      }
    },
  })

  return (
    <SideBannerContainer
      bannerWidth={5}
      bannerSide="left"
      banner={<AuthenticationBanner />}
    >
      <PageTitle title="Activate Account" />
      <AuthenticationBase>
        <Box className="form-header">
          <H1>Account Verification</H1>
        </Box>
        <form className="form-content" onSubmit={handleSubmit}>
          {didReset && (
            <Alert severity="success" fade grow>
              Account Verified!
              <br />
              Redirecting you in {ticker} seconds.
            </Alert>
          )}
          {errors.token && (
            <Alert severity="error" fade grow>
              {errors.token}
              <br />
              <br />
              <AppLink to="/resend-activation-link-new">
                Request a new link
              </AppLink>
            </Alert>
          )}
          <Button
            type="submit"
            fullWidth
            loading={isSubmitting}
            disabled={!isValid}
          >
            Verify Account
          </Button>
        </form>
      </AuthenticationBase>
    </SideBannerContainer>
  )
}

export default AccountVerification
