import { useEffect } from 'react'

export default function useExternalScripts(url: string, type: string) {
  useEffect(() => {
    const head = document.querySelector('head')
    const script = document.createElement('script')

    if (head) {
      script.setAttribute('src', url)
      script.setAttribute('type', type)
      head.appendChild(script)

      return () => {
        head.removeChild(script)
      }
    }
    return
  }, [url])
}
