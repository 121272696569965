import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { ProtocolDiagnosis } from './useProtocols'

interface ProtocolDiagnosesResponse {
  protocolDiagnosis: ProtocolDiagnosis
}

const useProtocolDiagnosis = (
  protocolDiagnosisId: string,
  extraOptions: UseQueryOptions<ProtocolDiagnosis> = {},
) => {
  const currentSiteId = useCurrentSite().id
  const key = QueryKeys.protocol(protocolDiagnosisId)
  const url = `/sites/protocol_diagnoses/${protocolDiagnosisId}?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () =>
      request
        .get<ProtocolDiagnosesResponse>(url)
        .then((r) => r.data.protocolDiagnosis),
    extraOptions,
  )
}

export default useProtocolDiagnosis
