import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Subscription } from './useSubscription'

interface UpdatesubscriptionIdResponse {
  subscription: Subscription
}

export interface updateSubscriptionVars {
  subscriptionId: string
  unauthSiteId?: string
}

const useUpdateSubscription = () => {
  const queryClient = useQueryClient()
  const currentSiteId = useCurrentSite()?.id

  return useMutation(
    ({ subscriptionId, unauthSiteId }: updateSubscriptionVars) => {
      const url = `/sites/subscriptions/${subscriptionId}`
      const siteId = currentSiteId || unauthSiteId

      return request
        .patch<UpdatesubscriptionIdResponse>(url, {
          siteIds: [siteId],
          subscriptionId,
        })
        .then((r) => {
          queryClient.invalidateQueries(QueryKeys.onboardingStep(siteId || ''))
          queryClient.invalidateQueries(QueryKeys.subscription())
          queryClient.invalidateQueries(QueryKeys.unauthenticatedSubscription())
          return r.data.subscription
        })
    },
  )
}

export default useUpdateSubscription
