import { FC } from 'react'
import { Link, makeStyles, SvgIcon, SvgIconProps, Tooltip } from '@perk-ui/core'

const useStyles = makeStyles((theme) => ({
  knowledgeBaseLink: {
    backgroundColor: '#F5E9FC',
    display: 'flex',
    borderRadius: '25px',
    marginRight: theme.spacing(2),
    padding: theme.spacing(1),
    '& svg': {
      color: 'transparent',
      stroke: '#9D27E1',
    },
  },
}))

export interface KnowledgeBaseButtonProps {}

const KnowledgeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props} width="24" height="24" viewBox="0 0 24 24" fill="none">
      <path
        d="M6 12V17C9 20 15 20 18 17V12"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22 10V16V10ZM2 10L12 5L22 10L12 15L2 10Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  )
}

const KnowledgeBaseButton: FC<KnowledgeBaseButtonProps> = () => {
  const classes = useStyles()

  return (
    <Tooltip arrow placement="bottom" title="Knowledge Base">
      <Link
        className={classes.knowledgeBaseLink}
        href={'https://knowledgebase.databiologics.com'}
        target={'_blank'}
      >
        <KnowledgeIcon />
      </Link>
    </Tooltip>
  )
}

export default KnowledgeBaseButton
