import { useQuery, UseQueryOptions } from 'react-query'

// import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export interface Product {
  id: string
  name: string
  description: string
  defaultPrice: ProductPrice
  metadata: ProductMetadata
}

export interface ProductPrice {
  id: string
  amount: number
  currency: string
  interval: string
  tiers: ProductTier[]
}

export interface ProductMetadata {
  mainColor: string
  backgroundColor: string
  order: number
  systemValue: 'essentials' | 'performance' | 'demo' | 'starter'
}
export interface ProductTier {
  flatAmount: number
  flatAmountDecimal: string
  unitAmount: number
  unitAmountDecimal: string
  upTo: number | null
}

interface ProductResponse {
  products: Product[]
}
// Subscription Products (Stripe)
const useProducts = (extraOptions: UseQueryOptions<Product[]> = {}) => {
  // const siteId = useCurrentSite().id
  const key = QueryKeys.products()
  const url = `/sites/products`

  return useQuery(
    key,
    () => request.get<ProductResponse>(url).then((r) => r.data.products),
    {
      // Products are static - don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useProducts
