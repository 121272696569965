var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import MuiListItem from '@mui/material/ListItem';
import clsx from 'clsx';
import { makeStyles } from '../';
const useStyles = makeStyles((theme) => ({
    root: {
        // The :after pseudo element replaces the border as the bottom divider
        // This allows us to horizontally offset the divider
        borderBottomColor: 'transparent',
        '&:after': {
            content: '""',
            display: 'block',
            height: 1,
            position: 'absolute',
            left: theme.spacing(3),
            right: 0,
            bottom: -1,
        },
    },
    divider: {
        '&:after': {
            backgroundColor: theme.palette.divider,
            left: theme.spacing(3),
        },
    },
    dividerInset: {
        '&:after': {
            // 3 for spacing from left side, 6 for spacing of the inset element, 1.5 for padding between inset and content
            left: theme.spacing(3 + 6 + 1.5),
        },
    },
    dividerFullWidth: {
        '&:after': {
            left: 0,
        },
    },
}), { name: 'ListItem' });
/**
 * Extends the MUI ListItem with additional style options.
 * Caution: this component does not currently expose the types for ListItem.
 */
const ListItem = (_a) => {
    var { leftSpacing = 'default' } = _a, rest = __rest(_a, ["leftSpacing"]);
    const classes = useStyles();
    const inset = leftSpacing === 'inset';
    const fullWidth = leftSpacing === 'full-width';
    return (_jsx(MuiListItem, Object.assign({ classes: {
            // When MuiListItem has a `secondaryAction`, it wraps the component
            // with another element, the `container`, but does not apply the
            // MuiListItem class. This breaks CSS selectors that rely on a consistent
            // sequence of element classes, like `:first-child`.
            // We create a container and root class here so we have a consistent hook
            // @see https://github.com/mui-org/material-ui/issues/13656
            container: 'ListItem-base',
            root: clsx('ListItem-base', classes.root),
            divider: clsx(classes.divider, {
                [classes.dividerInset]: inset,
                [classes.dividerFullWidth]: fullWidth,
            }),
        } }, rest), void 0));
};
export default ListItem;
