import { Fragment } from 'react'
import {
  Body1,
  Box,
  Button,
  DialogTrigger,
  Divider,
  IconButton,
  Link,
  List,
  makeStyles,
  Tooltip,
} from '@perk-ui/core'
import { X } from 'react-feather'
import { useQueryClient } from 'react-query'

import QueryKeys from '../features/query-hooks/QueryKeys'
import { Patient } from '../features/query-hooks/usePatient'
import useResendSurveyNotification from '../features/query-hooks/useResendSurveyNotification'
import { SurveyResponseGroup } from '../features/query-hooks/useSurveyResponseGroups'
import { formatPhoneNumber } from './PatientInfo'
import { useCurrentSite } from './SitesProvider'

export interface PatientCDOpenSurveysProps {
  openSurveys: SurveyResponseGroup[]
  patient?: Patient
  parentToggle?: () => void
}

const useStyles = makeStyles((theme) => ({
  listContainer: {
    maxHeight: '85px',
    // overflow: 'auto',
  },
  cardTitle: {
    marginBottom: theme.spacing(2),
  },
  alertCircle: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  listItem: {
    paddingLeft: 0,
    minHeight: 'auto',
    '&&': {
      color: theme.palette.text.primary,
    },
    '&:last-child': {
      // To not impact the containing Card's spacing
      marginBottom: -8,
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
  completeManuallyContainer: {
    padding: theme.spacing(2, 0, 1, 0),
  },
  resendLink: {
    cursor: 'pointer',
    fontWeight: 600,
  },
  completeManually: {
    width: '100%',
    borderRadius: '4px',
    marginBottom: '4px',
  },
  dialogContent: {
    '& > p': {
      textAlign: 'center',
    },
  },
  dialogButtons: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'center',
    '& button+button': {
      marginLeft: theme.spacing(5),
    },
  },
  dialogPaper: {
    height: '90%',
    // These height/width are semi-arbitrary, found by playing with different values
    // The patient app fills vertical space first, so the maxHeight is the primary
    // constraint here and sets the aspect-ratio of the iframe'd app. The aspect-ratio
    // is maybe the most important aesthetic aspect as long as the app is easy to read.
    // iPhoneX has a width/height of 375px/812px, so this can grow to be a bit larger than that.
    maxHeight: 1000,
    maxWidth: 500,
  },
  manualCompletionDialog: {
    height: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  iFrame: {
    height: '100%',
    width: '100%',
  },
  iframeXButton: {
    position: 'absolute',
    right: 0,
  },
  disabledLink: {
    pointerEvents: 'none',
    color: '#919191',
  },
}))

const PatientCDOpenSurveys: React.FC<PatientCDOpenSurveysProps> = ({
  openSurveys,
  patient,
  parentToggle,
}) => {
  const classes = useStyles()
  const resendSurveyNotification = useResendSurveyNotification()
  const currentSiteId = useCurrentSite().id
  const patientPhoneType = patient?.phoneData?.phoneType
    ? `[${patient.phoneData.phoneType}]`
    : ''

  const handleSmsClick = (surveyResponseGroupId: string) => {
    return resendSurveyNotification.mutateAsync({
      surveyResponseGroupId: surveyResponseGroupId,
      medium: 'sms',
    })
  }
  const handleEmailClick = (surveyResponseGroupId: string) => {
    return resendSurveyNotification.mutateAsync({
      surveyResponseGroupId: surveyResponseGroupId,
      medium: 'email',
    })
  }
  const handleCompleteManuallyClick = (ownerId: string) => {
    return queryClient.invalidateQueries(
      QueryKeys.surveyResponseGroups({
        currentSiteId: currentSiteId,
        patientId: ownerId,
      }),
    )
  }
  const queryClient = useQueryClient()

  let smsLinkClass = `${classes.resendLink}`
  let isSmsLinkDisabled = false
  let smsDisabledReason = ''
  let emailLinkClass = `${classes.resendLink}`
  let isEmailLinkDisabled = false
  let emailDisabledReason = ''
  if (patient) {
    if (patient.isOptedOutPhone || !patient.isValidPhone) {
      smsLinkClass += ` ${classes.disabledLink}`
      smsLinkClass += ` ${classes.resendLink}`
      isSmsLinkDisabled = true
      if (patient.isOptedOutPhone && !patient.isValidPhone) {
        smsDisabledReason =
          "Phone number can't receive SMS messages and has also opted-out"
      } else if (!patient.isValidPhone) {
        smsDisabledReason = "Phone number can't receive SMS messages"
      } else if (patient.isOptedOutPhone) {
        smsDisabledReason = 'Phone number has opted-out for SMS messages'
      }
    }
    if (!patient.email) {
      emailLinkClass += ` ${classes.disabledLink}`
      isEmailLinkDisabled = true
      emailDisabledReason = 'No email entered.'
    }
  }
  return (
    <>
      <List className={classes.listContainer}>
        {openSurveys.map((openSurvey, index) => {
          let completeManuallyLinkClass = `${classes.completeManually}`
          let iscompleteManuallyLinkDisabled = false
          let completeManuallyDisabledReason = ''
          if (!openSurvey.shortLink) {
            completeManuallyLinkClass += ` ${classes.disabledLink}`
            iscompleteManuallyLinkDisabled = true
            completeManuallyDisabledReason = 'Survey link not available'
          }
          return (
            <Fragment key={openSurvey.id}>
              <Box className={classes.row}>
                <DialogTrigger
                  disabled={isSmsLinkDisabled}
                  action={
                    <Tooltip arrow placement="top" title={smsDisabledReason}>
                      <span>
                        <Link
                          component={Body1}
                          className={smsLinkClass}
                          underline="hover"
                        >
                          Resend via SMS
                        </Link>
                      </span>
                    </Tooltip>
                  }
                  onOpen={parentToggle}
                  content={(toggle) => (
                    <Box p={3} className={classes.dialogContent}>
                      <Body1 mb={2}>
                        Are you sure you want to resend this Survey link?
                      </Body1>
                      <Body1>
                        {patient?.phoneData?.phoneType !== 'MOBILE'
                          ? `${formatPhoneNumber(
                              patient?.cellPhoneLocal || '',
                            )} ${patientPhoneType} may not be able to receive SMS messages. Click Cancel, or click Confirm to send anyway.`
                          : ''}
                      </Body1>
                      <div className={classes.dialogButtons}>
                        <Button
                          size="medium"
                          variant="outlined"
                          onClick={() => toggle()}
                        >
                          Cancel
                        </Button>

                        <Button
                          size="medium"
                          onClick={() =>
                            handleSmsClick(openSurvey.id).then(() => toggle())
                          }
                          disabled={resendSurveyNotification.isLoading}
                        >
                          Confirm
                        </Button>
                      </div>
                    </Box>
                  )}
                />
                <DialogTrigger
                  disabled={isEmailLinkDisabled}
                  action={
                    <Tooltip arrow placement="top" title={emailDisabledReason}>
                      <span>
                        <Link
                          component={Body1}
                          className={emailLinkClass}
                          underline="hover"
                        >
                          Resend via Email
                        </Link>
                      </span>
                    </Tooltip>
                  }
                  onOpen={parentToggle}
                  content={(toggle) => (
                    <Box p={3} className={classes.dialogContent}>
                      <Body1>
                        Are you sure you want to resend this Survey link?
                      </Body1>
                      <div className={classes.dialogButtons}>
                        <Button
                          size="medium"
                          variant="outlined"
                          onClick={() => toggle()}
                        >
                          Cancel
                        </Button>

                        <Button
                          size="medium"
                          onClick={() =>
                            handleEmailClick(openSurvey.id).then(() => toggle())
                          }
                          disabled={resendSurveyNotification.isLoading}
                        >
                          Confirm
                        </Button>
                      </div>
                    </Box>
                  )}
                />
              </Box>
              <Box className={classes.completeManuallyContainer}>
                <DialogTrigger
                  disabled={iscompleteManuallyLinkDisabled}
                  action={
                    <Tooltip
                      arrow
                      placement="top"
                      title={completeManuallyDisabledReason}
                    >
                      <span>
                        <Button
                          disabled={iscompleteManuallyLinkDisabled}
                          className={completeManuallyLinkClass}
                          size={'small'}
                        >
                          Complete Manually
                        </Button>
                      </span>
                    </Tooltip>
                  }
                  onOpen={parentToggle}
                  content={(toggle) => (
                    <>
                      <Box
                        className={classes.iframeXButton}
                        pr={4}
                        // .5 smidge to match the back arrow
                        pt={3.5}
                      >
                        <IconButton
                          onClick={() =>
                            handleCompleteManuallyClick(
                              openSurvey.ownerId,
                            ).then(() => toggle())
                          }
                          size="large"
                        >
                          <X />
                        </IconButton>
                      </Box>
                      <Box p={3} className={classes.manualCompletionDialog}>
                        <iframe
                          className={classes.iFrame}
                          src={openSurvey.shortLink}
                          frameBorder="0"
                          allowFullScreen
                          title={'Survey'}
                        ></iframe>
                      </Box>
                    </>
                  )}
                  dialogProps={{
                    PaperProps: {
                      classes: { root: classes.dialogPaper },
                    },
                  }}
                />
                {openSurveys.length != index + 1 && <Divider />}
              </Box>
              {/* <ListItem className={classes.listItem}>
                <AlertCircle className={classes.alertCircle} />
                <ListItemText body={renderTitle(openSurvey)} />
              </ListItem> */}
            </Fragment>
          )
        })}
      </List>
    </>
  )
}

export default PatientCDOpenSurveys
