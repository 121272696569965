import { capitalize } from '@perk-ui/core'
import { useQuery, UseQueryOptions } from 'react-query'

import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export type BodySiteKey =
  | 'brain'
  | 'cervical-spine'
  | 'thoracic-spine'
  | 'lumbar-spine'
  | 'shoulder'
  | 'elbow'
  | 'wrist-hand'
  | 'hip'
  | 'pelvis'
  | 'penis'
  | 'knee'
  | 'ankle'
  | 'ankle-achilles'
  | 'foot-toes'
  | 'abdomen-groin'

export type BodySiteKeyCamelCase =
  | 'brain'
  | 'cervicalSpine'
  | 'thoracicSpine'
  | 'lumbarSpine'
  | 'shoulder'
  | 'elbow'
  | 'wristHand'
  | 'hip'
  | 'pelvis'
  | 'penis'
  | 'knee'
  | 'ankle'
  | 'ankleAchilles'
  | 'footToes'
  | 'abdomenGroin'

export const humanizeBodySite = (str: string) => {
  if (str === 'foot-toes') return 'Foot / Toe'
  if (str === 'wrist-hand') return 'Wrist / Hand'
  if (str === 'abdomen-groin') return 'Abdomen / Groin'

  return str.toLowerCase().split('-').map(capitalize).join(' ')
}

export interface BodySite {
  bodySiteKey: BodySiteKey
  id: string
  name: string // 'Knee (Left)'
  slug: string // bodySiteKey + left/right, i.e. 'knee-left'
  onlyForStudy: boolean
}

interface BodySiteResponse {
  bodySites: BodySite[]
}

const useBodySites = (extraOptions: UseQueryOptions<BodySite[]> = {}) => {
  const key = QueryKeys.bodySites()
  const url = '/sites/body_sites'

  return useQuery(
    key,
    () => request.get<BodySiteResponse>(url).then((r) => r.data.bodySites),
    {
      // BodySites are static - don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useBodySites
