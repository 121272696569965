import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { BodySiteKey } from './useBodySites'
import {
  SurveyStatusObject,
  SurveyStatusPeriod,
} from './useSurveyCompletionCDStatus'

export interface BodyPartSurveyCompletionStatus {
  name: BodySiteKey
  patientCase: string
  surveys: Record<SurveyStatusPeriod, SurveyStatusObject>
}

const useBodyPartsSurveyCompletionStatus = (
  patientId?: string,
  extraOptions: UseQueryOptions<BodyPartSurveyCompletionStatus[]> = {},
) => {
  const currentSiteId = useCurrentSite()?.id
  const siteIds = currentSiteId

  const key = patientId
    ? QueryKeys.bodyPartsSurveyCompletionStatus({ currentSiteId, patientId })
    : ''
  const url = `/sites/patients/${patientId}/survey_status_overview?site_ids[]=${siteIds}`

  return useQuery(
    key,
    () =>
      request.get<BodyPartSurveyCompletionStatus[]>(url).then((r) => r.data),
    {
      enabled: Boolean(patientId) && Boolean(currentSiteId),
      ...extraOptions,
    },
  )
}

export default useBodyPartsSurveyCompletionStatus
