import { FC, useState } from 'react'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  H1,
  H3,
  makeStyles,
  PageTitle,
  PasswordField,
  TextField,
  Tooltip,
} from '@perk-ui/core'
import { useFormik } from 'formik'
import { matchSorter } from 'match-sorter'
import { HelpCircle } from 'react-feather'
import * as yup from 'yup'

import AppLink from '../../../components/AppLink'
import SideBannerContainer from '../../../components/SideBannerContainer'
import { staffRoles } from '../../../config/constants'
import { useAuth } from '../../../features/auth/AuthContext'
import { StaffRole } from '../../../features/query-hooks/types'
import { humanizeBodySite } from '../../../features/query-hooks/useBodySites'
import useCreateAccount from '../../../features/query-hooks/useCreateAccount'
import useNpiValidation from '../../../features/query-hooks/useNpiValidation'
// import { useHistory } from '../../../utils/useHistory'
import { keyToLabel } from '../../Home/components/BodyPartGraph'
import AuthenticationBase from '../AuthenticationBase'
import AuthenticationBanner from '../components/AuthenticationBanner'

interface RegisterProps {}

interface RegisterFormValues {
  firstName: string
  lastName: string
  email: string
  password: string
  passwordConfirmation: string
  type: StaffRole
  keratin?: string
  npi?: string
}
const useStyles = makeStyles((theme) => ({
  helperTooltip: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing(1),
      width: '18px',
      height: '18px',
      color: theme.palette.primary.main,
    },
  },
}))

const Register: FC<RegisterProps> = () => {
  const { signIn } = useAuth()
  const classes = useStyles()
  // const { push } = useHistory()
  const addAccount = useCreateAccount()
  const validateNpi = useNpiValidation()
  const [previousNpiValidated, setPreviousNpiValidated] = useState('')
  const [isPreviousNpiValid, setIsPreviousNpiValid] = useState(false)

  const registerValidations = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup
      .string()
      .required('Email is required')
      .email('Ensure the email is formatted correctly'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/(?=.*[0-9])/, 'Password must have a number')
      .matches(/(?=.*[^A-Za-z0-9])/, 'Password must have a symbol'),
    passwordConfirmation: yup
      .string()
      .required('Password confirmation is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
    type: yup
      // .oneOf([staffRoles])
      .string()
      .required('Role is required'),
    npi: yup
      .string()
      .when('type', {
        is: (type: string) => type === 'Provider',
        then: yup
          .string()
          .min(10, 'NPI must be 10 digits')
          .max(10, 'NPI must be 10 digits')
          .required('NPI is required.')
          .test('valid-npi', 'Invalid NPI Number', (value = '') =>
            value && value !== previousNpiValidated
              ? NpiVerification(value)
              : isPreviousNpiValid,
          ),
      })
      .nullable(),
  })

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldError,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isValid,
    touched,
  } = useFormik<RegisterFormValues>({
    enableReinitialize: true,
    initialValues: {
      firstName: '',
      npi: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      type: '' as StaffRole,
    },
    validationSchema: registerValidations,
    onSubmit: (values) => {
      // Trim whitespace from all values
      Object.keys(values).forEach((key) => {
        const k = key as keyof RegisterFormValues
        if (typeof values[k] === 'string') {
          ;(values[k] as string).trim()
        }
      })

      return addAccount
        .mutateAsync({
          firstName: values.firstName,
          lastName: values.lastName,
          npi: values.npi,
          email: values.email,
          password: values.password,
          type: values.type,
        })
        .catch((err) => {
          if (err.response.data.keratin) {
            setFieldError('keratin', err.response.data.keratin.message)
            throw err
          }
          if (
            err.response.data.errors.npi &&
            err.response.data.errors.npi[0] == 'has already been taken'
          ) {
            setFieldError('npi', 'NPI number already taken.')
            throw err
          }
        })
        .then(() => {
          return signIn(values.email, values.password)
        })
    },
  })

  const NpiVerification = (npi: string) => {
    if (npi.length !== 10) return false
    return validateNpi
      .mutateAsync({
        npi: npi,
      })
      .then((res) => {
        setPreviousNpiValidated(npi)
        if (res.resultCount > 0) {
          const responseProvider = res.results[0].basic
          setFieldValue(
            'firstName',
            humanizeBodySite(responseProvider.firstName),
          )
          setFieldValue('lastName', humanizeBodySite(responseProvider.lastName))
          setIsPreviousNpiValid(true)
          return true
        } else {
          setFieldValue('firstName', '')
          setFieldValue('lastName', '')
          setIsPreviousNpiValid(false)
          return false
        }
      })
      .catch(() => false)
  }

  return (
    <SideBannerContainer
      bannerWidth={5}
      bannerSide="left"
      banner={<AuthenticationBanner />}
    >
      <PageTitle title="Register" />
      <AuthenticationBase>
        <Box className="form-header">
          <H1>Create your account</H1>
          <H3>{"Let's get started creating your account"}</H3>
        </Box>
        <form className="form-content" onSubmit={handleSubmit}>
          {errors.keratin && (
            <Alert severity="error" fade grow>
              {errors.keratin}
            </Alert>
          )}

          <Autocomplete
            options={staffRoles}
            getOptionLabel={(opt) => keyToLabel(opt)}
            filterOptions={(options, { inputValue }) =>
              inputValue ? matchSorter(options, inputValue, {}) : options
            }
            onChange={(_event, type) => {
              setFieldValue('type', type)
              setFieldValue('firstName', '')
              setFieldValue('lastName', '')
              setFieldValue('npi', '')
            }}
            onBlur={() => {
              setFieldTouched('type')
            }}
            value={staffRoles.find((type) => type === values.type) || null}
            renderInput={(params) => {
              // @ts-expect-error Mui's autocomplete types here are incomplete
              params.InputLabelProps.shrink = true
              return (
                <TextField
                  {...params}
                  label="Role"
                  placeholder="Select Role"
                  error={touched.type && Boolean(errors.type)}
                  helperText={touched.type && errors.type}
                />
              )
            }}
          />

          {values.type === 'Provider' && (
            <TextField
              label="NPI"
              name="npi"
              placeholder="Enter NPI"
              type={'number'}
              fullWidth
              value={values.npi}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.npi && Boolean(errors.npi)}
              helperText={touched.npi && errors.npi}
              inputProps={{ size: 10 }}
            />
          )}
          <Grid container spacing={3}>
            <Grid item xs sm={6}>
              <TextField
                label="First Name"
                name="firstName"
                placeholder="Enter first name"
                fullWidth
                value={values.firstName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
                InputProps={{
                  readOnly: values.type === 'Provider',
                }}
              />
            </Grid>
            <Grid item xs sm={6}>
              <TextField
                label="Last Name"
                name="lastName"
                placeholder="Enter last name"
                fullWidth
                value={values.lastName}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
                InputProps={{
                  readOnly: values.type === 'Provider',
                }}
              />
            </Grid>
          </Grid>
          <TextField
            label="Email"
            name="email"
            placeholder="Enter email"
            fullWidth
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
          <Grid container spacing={3}>
            <Grid item xs sm={6}>
              <PasswordField
                label={
                  <span className={classes.helperTooltip}>
                    Password{' '}
                    <Tooltip title="Password must be at least 8 characters and include a symbol and a number">
                      <HelpCircle />
                    </Tooltip>
                  </span>
                }
                name="password"
                placeholder="Enter password"
                fullWidth
                value={values.password}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.password && Boolean(errors.password)}
                helperText={touched.password && errors.password}
              />
            </Grid>
            <Grid item xs sm={6}>
              <PasswordField
                label="Confirm Password"
                name="passwordConfirmation"
                placeholder="Confirm password"
                fullWidth
                value={values.passwordConfirmation}
                onChange={handleChange}
                onBlur={handleBlur}
                error={
                  touched.passwordConfirmation &&
                  Boolean(errors.passwordConfirmation)
                }
                helperText={
                  touched.passwordConfirmation && errors.passwordConfirmation
                }
              />
            </Grid>
          </Grid>
          <Button
            type="submit"
            fullWidth
            loading={isSubmitting}
            disabled={!isValid}
          >
            Create Account
          </Button>
          <Box textAlign={'center'}>
            <span>
              {'Already have an account? '}
              <AppLink to="/login-new">Login</AppLink>
            </span>
          </Box>
        </form>
      </AuthenticationBase>
    </SideBannerContainer>
  )
}

export default Register
