import { useReducer } from 'react';
const toggler = (value, nextValue) => nextValue === undefined ? !value : Boolean(nextValue);
/**
 * A hook that tracks and changes the value of a boolean.
 *
 * @example
 * const [isOpen, toggle] = useBool(false)
 * toggle() // isOpen === true
 * toggle() // isOpen === false
 * toggle(false) // isOpen === false
 *
 * @param initialValue The initial state
 * @returns a [value, setter] tuple. The setter may be called with an arg to force
 * the next value, or with no argument.
 */
const useBool = (initialValue) => useReducer(toggler, initialValue);
export default useBool;
