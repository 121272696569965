import developmentConfig from './environments/development'
import productionConfig from './environments/production'
import stagingConfig from './environments/staging'

export interface AppConfig {
  /** The base path used for most requests */
  baseApiPath: string
  /** Where auth-related requests are sent */
  authHost: string
  /** Root url for an authenticated user. i.e. /home */
  authenticatedRootUrl: string
  /** Root url for an unauthenticated user. i.e. /login */
  unAuthenticatedRootUrl: string
  /** Where analytics requests are sent */
  apmUrl?: string
  /** Tableau Production Base views */
  tableauProductionBaseUrl?: string
  /** Tableau Production API for embbeded views */
  tableauProductionEmbeddedApiUrl?: string
  /** Tableau Staging Base views */
  tableauStagingBaseUrl?: string
  /** Tableau Staging API for embbeded views */
  tableauStagingEmbeddedApiUrl?: string
  /**  Google Maps API Key*/
  googleMapsApiKey?: string
  /**  Stripe Public Key*/
  stripePublictKey?: string
  /**New Relic Config */
  newRelicAccountID: string
  newRelicTrustKey: string
  newRelicAgentID: string
  newRelicLicenseKey: string
  newRelicApplicationID: string
  sentryDSN?: string
}
export const e2eTarget = process.env['E2E_TARGET'] || 'http://localhost:3000'

// If a field in the AppConfig requires customization on a level beyond what
// deployment stage it is in, use an env variable or js.
const config: AppConfig = {
  dev: developmentConfig,
  staging: stagingConfig,
  production: productionConfig,
  test: developmentConfig,
}[process.env.REACT_APP_STAGE]

if (!config) {
  throw new Error(
    'Failed to load AppConfig. Likely an unknown REACT_APP_STAGE env variable.',
  )
}

export default config
