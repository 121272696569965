import { useMutation } from 'react-query'

import request from '../../config/axios'

interface NpiValidationResponse {
  resultCount: number
  results: Array<NpiRecord>
}

interface Taxonomy {
  code: string
  desc: string
  primary: boolean
}

interface NpiRecord {
  taxonomies: Array<Taxonomy>
  basic: {
    firstName: string
    lastName: string
    credential: string
  }
}
interface NpiParams {
  npi?: string
}

const useNpiValidation = () => {
  return useMutation((vars: NpiParams) => {
    const url = `/sites/npi_validation?npi=${vars.npi}`
    return request.get<NpiValidationResponse>(url).then((response) => {
      return response.data
    })
  })
}

export default useNpiValidation
