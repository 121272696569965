import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Staff } from './useCurrentUser'

interface UpdateStafResponse {
  staff: Staff
}

export interface UpdateStaffVars extends Staff {}

const useUpdateStaff = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite()?.id || ''

  return useMutation(({ ...vars }: UpdateStaffVars) => {
    const url = `/sites/staffs/${vars.id}`

    return request
      .patch<UpdateStafResponse>(url, {
        siteIds: [siteId],
        staff: {
          ...vars,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.staffs(siteId || ''))
        queryClient.invalidateQueries(QueryKeys.productsAvailable())
        return r.data.staff
      })
  })
}

export default useUpdateStaff
