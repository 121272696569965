import { useMutation, useQueryClient } from 'react-query'

import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { ClinicContract } from './useUnauthenticatedClinicContracts'

interface SignContractResponse extends Array<ClinicContract> {}

export interface SignContractVars {
  signature: string
  contractId: string
}

const useSignContract = () => {
  const queryClient = useQueryClient()

  return useMutation((vars: SignContractVars) => {
    const url = `/sites/contracts/${vars.contractId}/sign`

    return request
      .post<SignContractResponse>(url, {
        sign: {
          signature: vars.signature,
        },
      })
      .then((res) => {
        queryClient.invalidateQueries(QueryKeys.clinicContract())
        queryClient.invalidateQueries(QueryKeys.unauthenticatedClinicContract())
        return res.data
      })
  })
}

export default useSignContract
