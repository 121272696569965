import { FC, MouseEvent } from 'react'
import {
  Box,
  Card,
  H2,
  makeStyles,
  PageTitle,
  TablePagination,
} from '@perk-ui/core'
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'

import usePatientSearch from '../../features/query-hooks/usePatientSearch'
import PatientTable from './components/PatientTable'
import PatientTableSearch from './components/PatientTableSearch'

export interface PatientsProps {}

const useStyles = makeStyles((theme) => ({
  title: {
    marginBottom: theme.spacing(2),
  },
  root: {
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
}))

const perPage = 10

const Patients: FC<PatientsProps> = () => {
  const classes = useStyles()

  const [searchValue, setSearchValue] = useQueryParam(
    'q',
    withDefault(StringParam, ''),
  )
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0))

  const { data: searchResult } = usePatientSearch({
    search: searchValue,
    // `page` as used by MUI table components is 0-indexed while our backend
    // pagination is 1-indexed.
    // ¯\_(ツ)_/¯
    page: page + 1,
    perPage,
  })
  const count = searchResult?.meta.count || 0
  const tableData = searchResult?.patients || []

  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleSetSearchValue = (val: string) => {
    setSearchValue(val)
    setPage(0)
  }

  return (
    <Box p={3}>
      <PageTitle title="Patients" />
      <H2 className={classes.title}>All Patients</H2>
      <Card className={classes.root}>
        <PatientTableSearch
          setSearchValue={handleSetSearchValue}
          searchValue={searchValue}
        />
        <PatientTable
          data={tableData}
          pagination={
            tableData.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={count}
                rowsPerPage={perPage}
                page={page}
                onPageChange={handleChangePage}
              />
            )
          }
        />
      </Card>
    </Box>
  )
}

export default Patients
