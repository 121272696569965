import { tableCellClasses } from '@mui/material/TableCell'
import {
  Box,
  Chip,
  findBy,
  Grid,
  parseYMD,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@perk-ui/core'
import { startCase } from 'lodash'

import { patientReportSurveySlugs } from '../../../config/constants'
import { BodyPartSurveyCompletionStatus } from '../../../features/query-hooks/useBodyPartsSurveyCompletionStatus'
import { PatientCase } from '../../../features/query-hooks/usePatientCases'
import { SurveyResponse } from '../../../features/query-hooks/useSurveyResponse'

export interface SurveyDetailsTableProps {
  patientCase: PatientCase
  surveyStatus: BodyPartSurveyCompletionStatus
  currentSurveys?: SurveyResponse[]
  addElementToPdf: (element: JSX.Element) => void
  localizeDate: (date: number | Date) => string
}

const classes = {
  bodyPartName: {
    // '& .MuiDivider-root': {
    //   marginBottom: '24px',
    //   '&::before': {
    //     width: 0,
    //   },
    //   '& .MuiDivider-wrapper': {
    //     display: 'flex',
    //     fontSize: '16px',
    //     fontWeight: 700,
    //     alignItems: 'center',
    //     marginRight: '8px',
    //     paddingLeft: 0,
    '& span.partSide': {
      backgroundColor: '#F1F1F1',
      padding: '8px 12px',
      marginLeft: '8px',
      borderRadius: '16px',
      fontWeight: 400,
      fontSize: '12px',
    },
    divider: {
      height: '1px',
      borderBottom: '1px solid #F1F1F1',
      marginLeft: '16px',
      marginTop: '12px',
    },
    //   },
    // },
    marginBottom: '24px',
  },
  dataPair: {
    display: 'inline-block',
    marginRight: '8px',
    paddingRight: '8px',
    '& b.label': {
      fontSize: '10px',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#919191',
      marginBottom: '8px',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      '& b': {
        color: '#919191',
        marginLeft: '8px',
        fontWeight: 400,
      },
    },
  },
  dataPairAlt: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '8px',
    '& b.label': {
      fontSize: '12px',
      fontWeight: 400,
      color: '#767676',
      marginRight: '8px',
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      '& b': {
        color: '#919191',
        marginLeft: '8px',
        fontWeight: 400,
      },
    },
  },
  capitalizeText: {
    textTransform: 'capitalize',
  },
  sectionName: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#000',
  },
  diagnosesContainer: {
    padding: '16px 0',
  },
  diagnosisBox: {
    backgroundColor: '#FAFAFB',
    border: '1px solid #F1F1F1',
    borderRadius: '8px',
    padding: '16px',
  },
  diagnosisHeader: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '8px',
  },
  diagnosisName: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#000',
  },
  diagnosisType: {
    backgroundColor: '#EAF2F5',
    fontSize: '10px',
    padding: '4px 12px',
    color: '#2E8099',
  },
  severityChip: {
    backgroundColor: 'white',
    '& .MuiChip-label': {
      fontSize: '10px',
    },
  },
  severityMild: {
    borderColor: '#27ADE1',
    '& .MuiChip-label': {
      color: '#27ADE1',
    },
  },
  severityModerate: {
    borderColor: '#F79A48',
    '& .MuiChip-label': {
      color: '#F79A48',
    },
  },
  severitySevere: {
    borderColor: '#C00202',
    '& .MuiChip-label': {
      color: '#C00202',
    },
  },
  emptyStateMessage: {
    color: '#919191',
    fontSize: '10px',
    fontWeight: 500,
  },
  treatmentsContainer: {
    padding: '16px 0',
  },
  treatmentBox: {
    backgroundColor: '#FAFAFB',
    border: '1px solid #F1F1F1',
    borderRadius: '8px',
    padding: '16px',
  },
  treatmentHeader: {
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    marginBottom: '8px',
  },
  treatmentName: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#000',
  },
  treatmentType: {
    // color: '#2E8099',
    // backgroundColor: '#EAF2F5',
    color: 'blue',
    backgroundColor: 'transparent',
    fontSize: '10px',
    padding: '4px 12px',
  },
  treatmentDate: {
    color: '#767676',
    backgroundColor: '#F1F1F1',
    marginLeft: '8px',
  },
  cellContent: {
    background: '#FAFAFB',
    border: '1px solid #F1F1F1',
    borderRadius: '6px',
    padding: '6px',
    height: '100%',
  },
  cellRight: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
  },
  cellTitle: {
    fontSize: '12px',
    fontWeight: 700,
    lineHeight: '20px',
  },
  table: {
    '& .MuiTableCell-root': {
      padding: 0,
      border: 'none',
    },
  },
}

const durationOfSymptomsMap = [
  '< 1 month',
  '1-3 months',
  '3-6 months',
  '6-12 months',
  'More than a year',
]

const pastTreatmentsMap = [
  'Physical Therapy',
  'Chiropractic',
  'Acupuncture',
  'Massage',
  'Injections',
  'Surgery',
  'Medication',
  'Other',
]

const intervals = [
  'demographics',
  'baseline',
  '2 weeks',
  '1 month',
  '6 weeks',
  '2 months',
  '3 months',
  '6 months',
  '9 months',
  '12 months',
  '18 months',
  '24 months',
  '36 months',
]

const getSurveyScore = (survey: SurveyResponse): number => {
  let score: number
  // if (survey.survey.slug === 'AE-PATIENT') {
  //   const aeScore = survey.surveyResponseAnswers[0].values[0]
  //   score = aeScore === '1.0' ? 'Yes' : 'No'
  // } else if (survey.survey.slug === 'SATISFACTION-SCORE') {
  //   const satisfactionScore = SATISFACTION_SCORE.find(
  //     (ss) => ss.value === survey.surveyResponseAnswers[0].values[0],
  //   )
  //   score = satisfactionScore
  //     ? satisfactionScore.slug
  //     : survey.surveyResponseAnswers[0].values[0]
  // } else if (survey.survey.slug === 'REVIEW-ROUTING') {
  //   const satisfactionAnswer = survey.surveyResponseAnswers.find(
  //     (a) => a.surveyQuestion.slug === 'REVIEW-ROUTING-SATISFACTION-Q',
  //   )
  //   const answerValue = satisfactionAnswer
  //     ? satisfactionAnswer.values[0]
  //     : survey.surveyResponseAnswers[survey.surveyResponseAnswers.length - 1]
  //       .values[0]

  //   const satisfactionScore = SATISFACTION_SCORE.find(
  //     (ss) => ss.value === answerValue,
  //   )
  //   score = satisfactionScore
  //     ? satisfactionScore.slug
  //     : survey.surveyResponseAnswers[0].values[0]
  // } else if (survey.survey.slug === 'SATISFACTION-PSC') {
  //   const satisfactionScore = SATISFACTION_PSC.find(
  //     (ss) => ss.value === survey.surveyResponseAnswers[0].values[0],
  //   )
  //   score = satisfactionScore
  //     ? satisfactionScore.slug
  //     : survey.surveyResponseAnswers[0].values[0]
  // } else if (survey.survey.slug === 'PGI-I') {
  //   const pgiiScore = PGII_SCORE.find(
  //     (pgii) => pgii.value === survey.surveyResponseAnswers[0].values[0],
  //   )
  //   score = pgiiScore
  //     ? pgiiScore.slug
  //     : survey.surveyResponseAnswers[0].values[0]
  // } else if (survey.survey.slug === 'VIVEX-ADDITIONAL-TREATMENT') {
  //   const vivexATScore = survey.surveyResponseAnswers[0].values[0]
  //   score =
  //     vivexATScore === '1.0'
  //       ? 'Taking Additional Medication'
  //       : 'Not Taking Additional Medication'
  // } else if (survey.survey.slug === 'VIVEX-BACK-PAIN-MEDICATION') {
  //   const vivexBPMScore = survey.surveyResponseAnswers[0].values[0]
  //   score =
  //     vivexBPMScore === '1.0'
  //       ? 'Taking Pain Medications'
  //       : 'Not Taking Pain Medications'
  // } else if (survey.survey.slug === 'BDI') {
  //   const bdiScore = +Number(
  //     (findBy('isPrimary', true, survey?.surveyResponseScores) || {}).value,
  //   ).toFixed(1)
  //   score = `${bdiScore} (${bdiScoreLabel(parseInt(bdiScore.toString()))})`
  // } else if (survey.survey.slug === 'PHYSICAL-THERAPY') {
  //   const aeScore = survey.surveyResponseAnswers[0].values[0]
  //   score = aeScore === '1.0' ? 'Yes' : 'No'
  // } else
  {
    score = +Number(
      (findBy('isPrimary', true, survey?.surveyResponseScores) || {}).value,
    ).toFixed(1)
  }
  return score
}

const SurveyDetailsTable = ({
  patientCase,
  surveyStatus,
  currentSurveys,
  addElementToPdf,
  localizeDate,
}: SurveyDetailsTableProps) => {
  const surveysGroupedBySurvey: { [key: string]: SurveyResponse[] } =
    currentSurveys?.reduce((acc, item) => {
      if (Object.keys(item?.metadata).length === 0) return acc

      if (patientReportSurveySlugs.includes(item?.survey?.slug)) {
        acc[item?.survey?.slug] = acc[item?.survey?.slug] || []
        acc[item?.survey?.slug].push(item)
      }
      return acc
    }, Object.create(null))

  const treatmentHistory = currentSurveys?.find(
    (survey) => survey?.survey?.slug === 'TREATMENT-HISTORY',
  )
  const durationOfSymptoms = treatmentHistory?.surveyResponseAnswers?.find(
    (answer) => answer.surveyQuestion.slug === 'TREATMENTS-SYMPTOMS',
  )?.values
  const previousTreatments = treatmentHistory?.surveyResponseAnswers?.find(
    (answer) => answer.surveyQuestion.slug === 'TREATMENTS-PAST',
  )?.values

  const bodyPartSide = surveyStatus.name.includes('Left') ? (
    <span className="partSide">L</span>
  ) : surveyStatus.name.includes('Right') ? (
    <span className="partSide">R</span>
  ) : (
    ''
  )

  function groupElements(array: JSX.Element[]) {
    const grouped = []
    for (let i = 0; i < array.length; i += 2) {
      if (i + 1 < array.length) {
        grouped.push(array.slice(i, i + 2))
      } else {
        grouped.push([array[i]])
      }
    }
    return grouped
  }

  // Build section per parts
  addElementToPdf(
    <Box display="flex">
      <Box sx={classes.bodyPartName}>
        <span style={{ fontSize: '16px', fontWeight: 700 }}>
          {surveyStatus.name.replaceAll('(Left)', '').replaceAll('(Right)', '')}
        </span>
        {bodyPartSide}
      </Box>
      <Box sx={classes.bodyPartName.divider} style={{ flexGrow: 1 }} />
      {/* <Box sx={classes.bodyPartName}>
      <Divider textAlign='left'>
        {surveyStatus.name.replaceAll('(Left)', '').replaceAll('(Right)', '')}
        {bodyPartSide}
      </Divider>
    </Box> */}
    </Box>,
  )

  addElementToPdf(
    <Box>
      <Box sx={classes.dataPair}>
        <b className={'label'}>Duration of Symptoms</b>
        <span>
          {(durationOfSymptoms &&
            durationOfSymptoms.length &&
            durationOfSymptomsMap[Number(durationOfSymptoms[0])]) ||
            '-'}
        </span>
      </Box>

      <Box sx={classes.dataPair}>
        <b className={'label'}>Previous Treatments</b>
        <span>
          {(previousTreatments &&
            previousTreatments
              .map((x) => pastTreatmentsMap[Number(x)])
              .join(', ')) ||
            '-'}
        </span>
      </Box>
    </Box>,
  )

  addElementToPdf(
    <Box pt={'10px'}>
      <Grid item xs={12} sx={classes.sectionName}>
        Diagnoses
      </Grid>
    </Box>,
  )

  const diagnoses = []

  patientCase.patientDiagnoses && patientCase.patientDiagnoses.length > 0
    ? patientCase.patientDiagnoses
        ?.filter((diag) => diag.patientCaseId === patientCase.id)
        .map((diagnosis) => {
          diagnoses.push(
            <Grid item xs={6} key={diagnosis.id}>
              <Box sx={classes.diagnosisBox}>
                <Box sx={classes.diagnosisHeader}>
                  <span style={classes.diagnosisName}>
                    {diagnosis.medicalCondition?.name}
                  </span>
                  <Chip
                    sx={classes.diagnosisType}
                    variant="filled"
                    size="small"
                    label={diagnosis.isPrimary ? 'Primary' : 'Secondary'}
                  />
                </Box>

                <Box sx={classes.dataPairAlt}>
                  <b className={'label'}>Severity:</b>
                  <span>
                    <Chip
                      label={startCase(diagnosis.severity)}
                      variant="outlined"
                      size="small"
                      sx={{
                        ...classes.severityChip,
                        ...(diagnosis.severity == 'mild' &&
                          classes.severityMild),
                        ...(diagnosis.severity == 'moderate' &&
                          classes.severityModerate),
                        ...(diagnosis.severity == 'severe' &&
                          classes.severitySevere),
                      }}
                    />
                  </span>
                </Box>
                <Box sx={classes.dataPairAlt}>
                  <b className={'label'}>Location Detail:</b>
                  <span>
                    {diagnosis.locations
                      .map((location) => location.name)
                      .toString()
                      .replaceAll(',', ', ')}
                  </span>
                </Box>
              </Box>
            </Grid>,
          )
        })
    : diagnoses.push(
        <Grid item xs={12} sx={classes.emptyStateMessage}>
          {' '}
          No Diagnoses
        </Grid>,
      )

  groupElements(diagnoses).map((group) => {
    addElementToPdf(
      <Grid container spacing={2} sx={classes.diagnosesContainer}>
        {group}
      </Grid>,
    )
  })

  addElementToPdf(
    <Box pt={'10px'}>
      <Grid item xs={12} sx={classes.sectionName}>
        Treatments
      </Grid>
    </Box>,
  )

  const treatments: JSX.Element[] = []
  patientCase.patientTreatments && patientCase.patientTreatments.length > 0
    ? patientCase.patientTreatments
        ?.filter((treatment) => treatment.patientCaseId === patientCase.id)
        .map((treatment) => {
          treatments.push(
            <Grid item xs={6} key={treatment.id}>
              <Box sx={classes.treatmentBox}>
                <Box sx={classes.treatmentHeader}>
                  <span style={classes.treatmentName}>
                    {treatment.medicalTreatment?.name}
                  </span>
                  <Chip
                    sx={classes.treatmentDate}
                    size="small"
                    label={localizeDate(parseYMD(treatment.treatmentDate))}
                  />
                </Box>

                <Box sx={classes.dataPairAlt}>
                  <b className={'label'}>Provider:</b>
                  <span>{`${treatment.provider?.firstName} ${treatment.provider?.lastName}`}</span>
                </Box>
                <Box sx={classes.dataPairAlt}>
                  <b className={'label'}>Treatment Product:</b>
                  <span>{treatment.treatmentProduct?.name}</span>
                </Box>
              </Box>
            </Grid>,
          )
        })
    : treatments.push(
        <Grid item xs={12} sx={classes.emptyStateMessage}>
          {' '}
          No Treatments
        </Grid>,
      )

  groupElements(treatments).map((group) => {
    addElementToPdf(
      <Grid container spacing={2} sx={classes.treatmentsContainer}>
        {group}
      </Grid>,
    )
  })

  Object.values(surveysGroupedBySurvey).forEach((surveyResponse) => {
    surveyResponse.sort((a, b) => {
      const aValue = a.metadata.find((x) => x.type === 'schedule')
        ?.value as string
      const bValue = b.metadata.find((x) => x.type === 'schedule')
        ?.value as string

      const aIndex = intervals.indexOf(aValue)
      const bIndex = intervals.indexOf(bValue)

      // Handle cases where aValue or bValue is not found in intervals
      // If not found, indexOf returns -1
      return (
        (aIndex === -1 ? Infinity : aIndex) -
        (bIndex === -1 ? Infinity : bIndex)
      )
    })

    const baselineSurvey = surveyResponse.find(
      (x) => x.metadata.find((x) => x.type == 'schedule')?.value == 'baseline',
    )
    let baselineScore: number
    if (baselineSurvey) {
      baselineScore = getSurveyScore(baselineSurvey)
    }

    const baseTable = (
      <>
        <Box pt={'10px'} mb={'10px'}>
          <Grid item xs={12} sx={classes.sectionName}>
            {surveyResponse[0].survey.name}
          </Grid>
        </Box>
        <TableContainer>
          <Table
            sx={{
              [`& .${tableCellClasses.root}`]: {
                borderBottom: 'none',
                padding: 0,
              },
              height: '1px',
            }}
          >
            <TableHead>
              <TableRow>
                <TableCell style={{ padding: '2px' }}>
                  <Box style={classes.cellContent}>&nbsp;</Box>
                </TableCell>
                {surveyResponse.map((s) => (
                  <TableCell key={s.id} style={{ padding: '2px' }}>
                    <Box style={classes.cellContent}>
                      {startCase(
                        s.metadata.find((x) => x.type == 'schedule')
                          ?.value as string,
                      )}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell style={{ height: 'inherit', padding: '2px' }}>
                  <Box style={{ ...classes.cellContent, ...classes.cellTitle }}>
                    Survey
                  </Box>
                </TableCell>
                {surveyResponse.map((s) => (
                  <TableCell
                    key={s.id}
                    style={{ height: 'inherit', padding: '2px' }}
                  >
                    <Box
                      style={{ ...classes.cellContent, ...classes.cellRight }}
                    >
                      {s.surveyResponseScores.length ? getSurveyScore(s) : '-'}
                    </Box>
                  </TableCell>
                ))}
              </TableRow>
              <TableRow style={{}}>
                <TableCell
                  style={{
                    maxWidth: '100px',
                    height: 'inherit',
                    padding: '2px',
                  }}
                >
                  <Box style={{ ...classes.cellContent, ...classes.cellTitle }}>
                    Change from baseline
                  </Box>
                </TableCell>
                <TableCell style={{ height: 'inherit', padding: '2px' }}>
                  <Box style={{ ...classes.cellContent, ...classes.cellRight }}>
                    -
                  </Box>
                </TableCell>
                {surveyResponse
                  .filter(
                    (x) =>
                      x.metadata.find((x) => x.type == 'schedule')?.value !=
                      'baseline',
                  )
                  .map((s) => (
                    <TableCell
                      key={s.id}
                      style={{ height: 'inherit', padding: '2px' }}
                    >
                      <Box
                        style={{ ...classes.cellContent, ...classes.cellRight }}
                      >
                        {s.surveyResponseScores.length
                          ? getSurveyScore(s) - baselineScore
                          : '-'}
                      </Box>
                    </TableCell>
                  ))}
              </TableRow>
              <TableRow style={{}}>
                <TableCell
                  style={{
                    maxWidth: '100px',
                    height: 'inherit',
                    padding: '2px',
                  }}
                >
                  <Box style={{ ...classes.cellContent, ...classes.cellTitle }}>
                    Percent change from baseline
                  </Box>
                </TableCell>
                <TableCell style={{ height: 'inherit', padding: '2px' }}>
                  <Box style={{ ...classes.cellContent, ...classes.cellRight }}>
                    -
                  </Box>
                </TableCell>
                {surveyResponse
                  .filter(
                    (x) =>
                      x.metadata.find((x) => x.type == 'schedule')?.value !=
                      'baseline',
                  )
                  .map((s) => (
                    <TableCell
                      key={s.id}
                      style={{ height: 'inherit', padding: '2px' }}
                    >
                      <Box
                        style={{ ...classes.cellContent, ...classes.cellRight }}
                      >
                        {s.surveyResponseScores.length
                          ? `${
                              ((getSurveyScore(s) - baselineScore) /
                                getSurveyScore(s)) *
                              100.0
                            }%`
                          : '-'}
                      </Box>
                    </TableCell>
                  ))}
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </>
    )

    addElementToPdf(baseTable)
  })
}

export default SurveyDetailsTable
