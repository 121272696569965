import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export interface ArchivalReason {
  id: string
  action: 'archive' | 'hide'
  description: string
  slug: string
  type: ArchivalReasonType
}

export type ArchivalReasonType = 'Patient' | 'PatientCase'

interface ArchivalReasonResponse {
  archivalReasons: ArchivalReason[]
}

const useArchivalReasons = (
  type: ArchivalReasonType,
  extraOptions: UseQueryOptions<ArchivalReason[]> = {},
) => {
  const siteId = useCurrentSite().id
  const key = QueryKeys.archivalReasons(type)
  const url = `sites/archival_reasons?site_ids[]=${siteId}&type=${type}`

  return useQuery(
    key,
    () =>
      request
        .get<ArchivalReasonResponse>(url)
        .then((r) => r.data.archivalReasons),
    {
      // ArchivalReasons are static. Don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useArchivalReasons
