import { FC } from 'react'
import { Box, makeStyles } from '@perk-ui/core'
import { useLocation } from 'react-router'

import SideBannerContainer from '../../../components/SideBannerContainer'
import useUnauthenticatedClinicContracts from '../../../features/query-hooks/useUnauthenticatedClinicContracts'
import OnboardingBanner from '../components/OnboardingBanner'
import UnauthenticatedSigncontractDone from '../UnauthenticatedSignContractDone.tsx'
import ContractSignatureStep from './ContractSignatureStep'

interface UnauthenticatedContractSignatureProps {}
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(3),
    maxWidth: '80%',
    // height: '100vh',
    margin: '0 auto',
    '& .form-content': {
      display: 'flex',
      overflow: 'hidden',
      maxHeight: '90%',
      '& form': {
        overflow: 'auto',
        height: '100%',
        '& .MuiButton-root': {
          marginBottom: 0,
        },
        '& .MuiFormControl-root': {
          marginBottom: 0,
        },
      },
      '&.full-height': {
        maxHeight: '100%',
      },
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  stepContainer: {
    flex: '1 1 auto',
    padding: theme.spacing(0, 2),
    overflow: 'hidden',
    '& .step-header': {
      marginBottom: theme.spacing(4),
      '& h1': {
        fontSize: '32px',
        fontWeight: '700',
      },
      '& h3': {
        fontSize: '16px',
        fontWeight: '500',
      },
    },
    '& .step-content': {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      '& form': {
        height: '100%',
        overflow: 'auto',
        '& .MuiFormControl-root': {
          marginBottom: theme.spacing(0),
        },
        '& .MuiButton-root': {
          marginBottom: theme.spacing(0),
        },
      },
    },
  },
}))

const UnauthenticatedContractSignature: FC<
  UnauthenticatedContractSignatureProps
> = () => {
  const classes = useStyles()
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const token = searchParams.get('token') || ''
  const {
    data: contracts = [],
    isLoading,
    isFetching,
  } = useUnauthenticatedClinicContracts(token)
  const hasContractToSign =
    contracts.filter((contract) => contract.status == 'pending').length > 0

  if (isLoading || isFetching) return <></>
  if (!hasContractToSign && !isLoading && !isFetching)
    return <UnauthenticatedSigncontractDone type="no-contract" />
  return (
    <SideBannerContainer
      bannerWidth={4}
      bannerSide="left"
      banner={<OnboardingBanner />}
    >
      <Box className={classes.root}>
        <Box className={classes.stepContainer}>
          <ContractSignatureStep contracts={contracts} type="unauthenticated" />
        </Box>
      </Box>
    </SideBannerContainer>
  )
}

export default UnauthenticatedContractSignature
