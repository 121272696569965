import { useQuery, UseQueryOptions } from 'react-query'

import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { BodySiteKey } from './useBodySites'

export interface BodySite {
  bodySiteKey: BodySiteKey
  id: string
  name: string // 'Knee (Left)'
  slug: string // bodySiteKey + left/right, i.e. 'knee-left'
  onlyForStudy: boolean
}

interface BodySiteResponse {
  bodySites: BodySite[]
}

const useBodySites = (extraOptions: UseQueryOptions<BodySite[]> = {}) => {
  const key = QueryKeys.specialtyBodySites()
  const url = '/sites/body_sites?filtered=true'

  return useQuery(
    key,
    () => request.get<BodySiteResponse>(url).then((r) => r.data.bodySites),
    {
      // BodySites are static - don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useBodySites
