import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { MedicalTreatment } from './useMedicalTreatments'
import { Protocol } from './useProtocols'
import { Provider } from './useProviders'
import { SiteLocation } from './useSitesAvailable'
import { TreatmentProduct } from './useTreatmentProducts'

export interface PatientTreatment {
  id: string
  patientCaseId: string
  parentId?: string
  providerId: string
  siteLocationId?: string
  medicalTreatmentId: string
  treatmentProductId?: string
  treatmentDate: string
  medicalTreatment?: MedicalTreatment
  treatmentProduct?: TreatmentProduct
  provider?: Provider
  siteLocation?: SiteLocation
  researchStudyNumber?: string
  protocolSecondaryTreatments?: string
  protocol?: Protocol
  protocolId?: string
}

interface PatientTreatmentResponse {
  patientTreatment: PatientTreatment
}

const usePatientTreatment = (
  treatmentId?: string,
  extraOptions: UseQueryOptions<PatientTreatment> = {},
) => {
  const currentSiteId = useCurrentSite().id

  const key = treatmentId ? QueryKeys.patientTreatment(treatmentId) : []
  const url = `/sites/patient_treatments/${treatmentId}?site_ids[]=${currentSiteId}&includes[]=medical_treatment&includes[]=treatment_product`

  return useQuery(
    key,
    () =>
      request
        .get<PatientTreatmentResponse>(url)
        .then((r) => r.data.patientTreatment),
    {
      enabled: Boolean(treatmentId),
      ...extraOptions,
    },
  )
}

export default usePatientTreatment
