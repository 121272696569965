import { FC } from 'react'
import { Box, H3, H5, Link, makeStyles } from '@perk-ui/core'
import parse from 'html-react-parser'
import { Check, Download, X } from 'react-feather'
import { useLocation } from 'react-router'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
    padding: theme.spacing(2, 0.5),
    width: '100%',
    maxWidth: '678px',
  },
  container: {
    padding: theme.spacing(5),
    textAlign: 'center',
    backgroundColor: '#FAFAFB',
    height: '100%',
    borderRadius: '40px',
    '& h3': {
      padding: theme.spacing(5, 0, 2, 0),
      fontSize: '32px',
      fontWeight: 700,
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 500,
    },
    '& a': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
      border: `1px solid ${theme.palette.primary.main}`,
      textDecoration: 'unset',
      padding: theme.spacing(2, 2),
      marginTop: theme.spacing(5),
      borderRadius: '16px',
      fontWeight: 700,
      fontSize: '16px',
      display: 'inline-flex',
      alignItems: 'center',
      '&:hover': {
        color: theme.palette.primary.dark,
        borderColor: theme.palette.primary.dark,
      },
      '& svg': {
        marginRight: theme.spacing(1.5),
      },
    },
  },
  iconContainer: {
    display: 'inline-flex',
    backgroundColor: '#6DF5C4',
    padding: theme.spacing(3),
    borderRadius: theme.spacing(5),
  },
}))

interface ThankYouContainerProps {
  type:
    | 'no-contract'
    | 'thank-you'
    | 'payment-done'
    | 'no-subscription'
    | 'payment-incomplete'
  title: string
  body: string
}

const ThankYouContainer: FC<ThankYouContainerProps> = ({
  type,
  title,
  body,
}) => {
  const classes = useStyles()
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const link = searchParams.get('link') || undefined

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.iconContainer}>
          {type == 'no-subscription' || type == 'payment-incomplete' ? (
            <X />
          ) : (
            <Check />
          )}
        </Box>
        <H3>{title}</H3>
        <H5>{parse(body)}</H5>
        {link && type == 'thank-you' && (
          <Link href={link} target="_blank">
            <Download /> Donwload
          </Link>
        )}
      </Box>
    </Box>
  )
}

export default ThankYouContainer
