import { FC, Fragment } from 'react'
import {
  Body1,
  Box,
  Button,
  DialogTrigger,
  Divider,
  H5,
  Link,
  makeStyles,
} from '@perk-ui/core'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import useBodySites from '../../../features/query-hooks/useBodySites'
import useCreateDiagnosis, {
  PatientDiagnosis,
  PatientDiagnosisSeverity,
} from '../../../features/query-hooks/useCreateDiagnosis'
import useDeleteDiagnosis from '../../../features/query-hooks/useDeleteDiagnosis'
import usePatientCases from '../../../features/query-hooks/usePatientCases'
import useUpdateDiagnosis from '../../../features/query-hooks/useUpdateDiagnosis'
import { useHistory } from '../../../utils/useHistory'
import ArchiveModal from '../components/ArchiveModal'
import PatientFlowUrls, { PatientFlowLocationParams } from '../PatientFlowUrls'
import DiagnosisForm, { DiagnosisFormValues } from './components/DiagnosisForm'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  archiveButton: {
    cursor: 'pointer',
  },
  dialogContent: {
    '& > p': {
      textAlign: 'start',
    },
  },
  dialogButtons: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'center',
    '& button+button': {
      marginLeft: theme.spacing(5),
    },
  },
  form: {
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '& > .MuiDivider-root': {
      marginBottom: theme.spacing(3),
    },
    '& .MuiAutocomplete-input': {
      maxWidth: '100%',
    },
  },
}))

interface DiagnosesFormValues {
  diagnoses: DiagnosisFormValues[]
}

const diagnosisHasChanged = (
  diag1?: DiagnosisFormValues & Pick<PatientDiagnosis, 'isPrimary'>,
  diag2?: PatientDiagnosis,
) => {
  if (!diag1 || !diag2) return true
  if (diag1.isPrimary !== diag2.isPrimary) return true
  if (diag1.severity !== diag2.severity) return true
  if (diag1.medicalConditionId !== diag2.medicalConditionId) return true
  if (diag1.radiographicClassification !== diag2.radiographicClassification)
    return true
  if (
    diag1.locations.map((l) => l.id).join() !==
    diag2.locations.map((l) => l.id).join()
  )
    return true

  return false
}

const validationSchema = yup.object({
  diagnoses: yup.array().of(
    yup.object({
      medicalConditionId: yup
        .string()
        .defined('A Primary Diagnosis must be selected'),
      severity: yup
        .string()
        .oneOf(['mild', 'moderate', 'severe'])
        .defined('Please select a severity'),
      locations: yup.array().of(
        yup.object({
          id: yup.string(),
        }),
      ),
      radiographicClassification: yup.string().nullable(),
    }),
  ),
})

export interface DiagnosisProps {}

const Diagnosis: FC<DiagnosisProps> = () => {
  const { push } = useHistory()
  const classes = useStyles()
  const diagnosisMatch = useRouteMatch<PatientFlowLocationParams>(
    PatientFlowUrls.diagnosis.match,
  )
  const { patientId = undefined, caseId = undefined } = diagnosisMatch
    ? diagnosisMatch.params
    : {}
  const createDiagnosis = useCreateDiagnosis()
  const updateDiagnosis = useUpdateDiagnosis()
  const deleteDiagnosis = useDeleteDiagnosis()
  const { data: patientCases = [] } = usePatientCases(patientId)
  const patientCase = patientCases.find((pCase) => pCase.id === caseId)
  const existingDiagnoses = patientCase?.patientDiagnoses ?? []

  const { data: bodySites = [] } = useBodySites()
  const bodySiteName = bodySites.find(
    (site) => site.id === patientCase?.bodySiteId,
  )?.name

  const formik = useFormik<DiagnosesFormValues>({
    validationSchema,
    enableReinitialize: true,
    initialValues: {
      diagnoses:
        existingDiagnoses.length > 0
          ? existingDiagnoses
          : [
              {
                medicalConditionId: '',
                severity: '',
                locations: [],
              },
            ],
    },
    onSubmit(values) {
      if (!patientId || !caseId) return

      const createsAndUpdates = values.diagnoses.map((diagnosis, idx) => {
        if (diagnosis.id) {
          const shouldUpdate = diagnosisHasChanged(
            {
              ...diagnosis,
              isPrimary: idx === 0,
            },
            existingDiagnoses[idx],
          )

          if (shouldUpdate) {
            return updateDiagnosis.mutateAsync({
              ...diagnosis,
              id: diagnosis.id,
              patientCaseId: caseId,
              severity: diagnosis.severity as PatientDiagnosisSeverity,
              isPrimary: idx === 0,
              patientId,
            })
          }
        } else {
          return createDiagnosis.mutateAsync({
            ...diagnosis,
            patientCaseId: caseId,
            severity: diagnosis.severity as PatientDiagnosisSeverity,
            isPrimary: idx === 0,
            patientId,
          })
        }
      })

      return Promise.all(createsAndUpdates).then(() => {
        const treatments = patientCase?.patientTreatments || []
        const destination =
          treatments.length > 0
            ? PatientFlowUrls.treatment.create(
                patientId,
                caseId,
                treatments[0].id,
              )
            : PatientFlowUrls.addTreatment.create(patientId, caseId)

        push({
          pathname: destination,
          state: { from: location.pathname },
        })
      })
    },
  })
  const {
    errors,
    touched,
    values,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik

  return (
    <>
      <div className={classes.header}>
        <H5 paragraph>{bodySiteName}</H5>
        <DialogTrigger
          action={
            <Link
              component={Body1}
              className={classes.archiveButton}
              underline="hover"
            >
              Archive Body Part
            </Link>
          }
          content={(toggle) => (
            <Box p={3} className={classes.dialogContent}>
              <ArchiveModal
                bodySiteName={bodySiteName}
                patientId={patientId}
                patientCaseId={patientCase?.id}
                archiveType={'PatientCase'}
                toggle={toggle}
              />
            </Box>
          )}
        />
      </div>
      <Divider />

      <form className={classes.form} onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <FieldArray name="diagnoses" validateOnChange={false}>
            {({ remove, push }) => {
              return (
                <>
                  {values.diagnoses.map((diagnosis, idx) => {
                    return (
                      <Fragment key={idx}>
                        <DiagnosisForm
                          patientCase={patientCase ?? null}
                          diagnosis={diagnosis}
                          bodySiteId={patientCase?.bodySiteId || ''}
                          bodySites={bodySites}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched.diagnoses?.[idx] ?? null}
                          errors={errors.diagnoses?.[idx] ?? null}
                          index={idx}
                          onRemove={async () => {
                            if (diagnosis.id) {
                              await deleteDiagnosis.mutateAsync(diagnosis.id)
                            }
                            remove(idx)
                          }}
                        />
                        <Divider />
                      </Fragment>
                    )
                  })}
                  <Link
                    id={'patient-flow-diagnosis-add-secondary-diagnosis-button'}
                    component="button"
                    type="button"
                    underline="hover"
                    onClick={() =>
                      push({
                        medicalConditionId: '',
                        severity: '',
                        locations: [],
                      })
                    }
                  >
                    <Body1>Add Secondary Diagnosis</Body1>
                  </Link>
                </>
              )
            }}
          </FieldArray>
          <Button type="submit" fullWidth loading={isSubmitting}>
            Save
          </Button>
        </FormikProvider>
      </form>
    </>
  )
}

export default Diagnosis
