import { FC, ReactChild, ReactNode } from 'react'
import {
  Body1,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@perk-ui/core'
import { useLocation } from 'react-router'

import AppLink from '../../../components/AppLink'
import { Patient } from '../../../features/query-hooks/usePatient'
import useDates from '../../../hooks/useDates'
import { parseYMD } from '../../../utils/dates'

export interface PatientTableProps {
  pagination: ReactNode
  data: Patient[]
}

interface Column {
  id: 'name' | 'medicalRecordNumber' | 'dateOfBirth' | 'id' | 'createdAt'
  label: string
  minWidth?: number
  align?: 'right'
  icon?: ReactChild
  format?: (value?: string | number | Date) => string | ReactChild
}

const useStyles = makeStyles((theme) => ({
  container: {},
  tableHeader: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(3),
  },
  tableHeaderIcon: {
    display: 'flex',
    fontWeight: 'bold',
    backgroundColor: theme.palette.common.white,
    justifyContent: 'flex-start',
  },
  contentRow: {
    cursor: 'pointer',
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  contentCell: {
    paddingLeft: theme.spacing(3),
  },
  archivedContentCell: {
    color: theme.palette.error.main,
    paddingLeft: theme.spacing(3),
  },
}))

const PatientTable: FC<PatientTableProps> = ({ pagination, data }) => {
  const classes = useStyles()
  const location = useLocation()
  const { localizedFormatDate } = useDates()

  const url = `${location.pathname}${location.search}`

  const columns: Column[] = [
    { id: 'name', label: 'Name' },
    {
      id: 'dateOfBirth',
      label: 'Birth Date',
      format: (value) =>
        value ? localizedFormatDate(parseYMD(value as string)) : '',
    },
    {
      id: 'createdAt',
      label: 'Date Added',
      format: (value) => (value ? localizedFormatDate(new Date(value)) : ''),
    },
    {
      id: 'medicalRecordNumber',
      label: 'MRN',
      format: (value) => (value ? `#${value}` : ''),
    },
  ]

  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column: Column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={
                    column.icon ? classes.tableHeaderIcon : classes.tableHeader
                  }
                >
                  {column.label}
                  {column.icon}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              <>
                {data.map((row: Patient) => {
                  return (
                    <TableRow
                      hover
                      key={row.id}
                      className={classes.contentRow}
                      component={AppLink}
                      to={{
                        pathname: `/patients/${row.id}`,
                        state: {
                          from: url,
                        },
                      }}
                      underline="none"
                    >
                      {columns.map((column) => {
                        const value =
                          column.id === 'name'
                            ? `${row.firstName} ${row.lastName} ${
                                row.status !== 'active' ? '(Archived)' : ''
                              }`
                            : row[column.id]

                        return (
                          <TableCell
                            key={column.id}
                            align={column.align}
                            className={
                              row.status === 'active'
                                ? classes.contentCell
                                : classes.archivedContentCell
                            }
                          >
                            {column.format ? column.format(value) : value}
                          </TableCell>
                        )
                      })}
                    </TableRow>
                  )
                })}{' '}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Box p={3} width="100%" textAlign="center">
                    <Body1>No patient records</Body1>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination}
    </>
  )
}

export default PatientTable
