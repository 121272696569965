import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { AnatomicalLocation } from './useAnatomicalLocations'
import { MedicalCondition } from './useMedicalConditions'
import { Protocol } from './useProtocols'

interface CreateDiagnosisResponse {
  patientDiagnosis: PatientDiagnosis
}

export type PatientDiagnosisSeverity = 'mild' | 'moderate' | 'severe'
export type PatientDiagnosisRadiographicClassification =
  | 'kl0'
  | 'kl1'
  | 'kl2'
  | 'kl3'
  | 'kl4'

export interface PatientDiagnosis {
  id: string
  medicalConditionId: string
  patientCaseId: string
  severity: PatientDiagnosisSeverity
  locations: AnatomicalLocation[]
  isPrimary: boolean
  medicalCondition?: MedicalCondition
  radiographicClassification?: PatientDiagnosisRadiographicClassification
  protocol?: Protocol
  protocolId?: string
}

export interface CreateDiagnosisVars extends Omit<PatientDiagnosis, 'id'> {
  patientId: string
}

const useCreateDiagnosis = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation(({ patientId, ...vars }: CreateDiagnosisVars) => {
    const url = `/sites/patient_diagnoses`

    return request
      .post<CreateDiagnosisResponse>(url, {
        siteIds: [siteId],
        patientDiagnosis: {
          siteId: siteId,
          ...vars,
          radiographicClassifications: vars.radiographicClassification
            ? vars.radiographicClassification
            : undefined,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.patientCases({ patientId }))
        queryClient.invalidateQueries(QueryKeys.upcomingDashboard(siteId))

        return r.data.patientDiagnosis
      })
  })
}

export default useCreateDiagnosis
