import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

interface SubscriptionResponse {
  subscription: Subscription
}

export interface Subscription {
  id: string
  lastInvoicePi: string
  nextInvoiceDate: string
  siteId: string
  startedDate: string
  status:
    | 'incomplete'
    | 'incomplete_expired'
    | 'trialing'
    | 'active'
    | 'past_due'
    | 'canceled'
    | 'unpaid'
    | 'free'
  stripeSubscriptionId: string
  paymentLink: string
  createdAt: string
  updatedAt: string
  subscriptionProducts: Array<SubscriptionProduct>
  clientSecret: string
  stripeSubscription?: StripeSubscription
}

export interface StripeSubscription {
  id: string
  discount: Discount
  latestInvoice: Invoice
}

export interface Invoice {
  id: string
  hostedInvoiceUrl: string
  invoicePdf: string
  subtotal: number
  total: number
  totalDiscountAmounts: Array<DiscountAmount>
  discount: Discount
}

export interface DiscountAmount {
  amount: number
  discount: string
}
export interface Discount {
  coupon: Coupon
}

export interface Coupon {
  id: string
  name: string
  amountOff: number
  percentOff: number
}

export interface SubscriptionProduct {
  id: string
  amount: number
  quantity: number
  usedQuantity: number
  stripeProductId: string
  subscriptionId: string
  stripePriceId: string
  paymentLink: string
  createdAt: string
  updatedAt: string
}

const useSubscription = (extraOptions: UseQueryOptions<Subscription> = {}) => {
  const currentSiteId = useCurrentSite()?.id || ''
  const key = QueryKeys.subscription()
  const url = `/sites/subscriptions?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () =>
      request.get<SubscriptionResponse>(url).then((r) => r.data.subscription),
    {
      // subscription is static - don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useSubscription
