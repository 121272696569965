import { FC } from 'react'
import { Box, H2, makeStyles } from '@perk-ui/core'

import { ReactComponent as AuthenticationBannerImage } from '../../../assets/OnboardingGraph.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    height: '100%',
  },
  container: {
    background:
      'radial-gradient(farthest-corner at 100% 0,#99EDF5 0%, #56AAEB 40%, #56AAEB 100%)',
    // background: 'linear-gradient(66deg, #2badee 0%, #99edf5 100%)',
    height: '100%',
    justifyContent: 'center',
    borderRadius: '40px',
  },
  content: {
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  title: {
    flex: '0 1 auto',
    color: theme.palette.common.white,
    padding: theme.spacing('12%', '12%', '4%', '12%'),
    fontSize: '4vh',
    fontWeight: 500,
    maxWidth: '80%',
    [theme.breakpoints.down('lg')]: {
      fontSize: '2rem',
    },
  },
  imageContainer: {
    flex: '1 1 auto',
    overflow: 'hidden',
    '& svg': {
      height: '100%',
      width: '100%',
    },
  },
}))

interface AuthenticationBannerProps {}

const AuthenticationBanner: FC<AuthenticationBannerProps> = () => {
  const classes = useStyles()
  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <H2 className={classes.title}>
            Welcome to the future of optimizing orthobiologics care
          </H2>
          <Box className={classes.imageContainer}>
            <AuthenticationBannerImage />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default AuthenticationBanner
