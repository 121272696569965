import { FC, useState } from 'react'
import {
  Body1,
  Box,
  Button,
  Collapse,
  DialogTrigger,
  findBy,
  H5,
  Icon,
  ISurveyQuestionItem,
  ISurveyResponseAnswer,
  Link,
  makeStyles,
  QuestionVariants,
  styled,
  tail,
} from '@perk-ui/core'
import { ChevronDown } from 'react-feather'

import usePatientTreatment, {
  PatientTreatment,
} from '../features/query-hooks/usePatientTreatment'
import useProviders from '../features/query-hooks/useProviders'
import useSurveyResponse from '../features/query-hooks/useSurveyResponse'
import useSurveyResponseSearch from '../features/query-hooks/useSurveyResponseSearch'
import useDates from '../hooks/useDates'
import { buildName } from '../utils/buildName'
import { parseYMD } from '../utils/dates'
import { buildTreatmentDetailSlug } from './buildTreatmentDetailSlug'
import DataPair from './DataPair'
import SessionDetail from './SessionDetail'
import { useSitesContext } from './SitesProvider'

type IconWrapperProps = {
  isCollapsed?: boolean
}

const IconWrapper = styled(Box)<IconWrapperProps>`
  transition: all 0.3s ease-in-out;
  transform: rotate(${({ isCollapsed }) => (isCollapsed ? 0 : 180)}deg);
`

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  treatmentDate: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  editButton: {
    marginLeft: theme.spacing(3),
  },
  dialogTrigger: {
    display: 'inline',
  },
  deleteButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginLeft: theme.spacing(3),
  },
  dialogContent: {
    '& > p': {
      textAlign: 'center',
    },
  },
  dialogButtons: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'center',
    '& button+button': {
      marginLeft: theme.spacing(5),
    },
  },
  accordion: {
    // Ovverrides of MUI styles
    '&&': {
      boxShadow: 'none',
      margin: 0,
      '&:before': {
        content: 'none',
      },
    },
  },
  accordionSummary: {
    // Overrides of MUI styles
    '&&': {
      paddingLeft: 0,
      minHeight: 0,
      '& > *': {
        margin: 0,
      },
    },
  },
  accordionTitle: {
    fontWeight: 500,
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',

    '&&': {
      padding: 0,
      paddingTop: theme.spacing(1),
    },
  },
}))

export interface TreatmentDetailProps {
  treatment: PatientTreatment
  onEditClick?: () => void
  onDeleteClick?: () => void
  withDetails?: boolean
  patientId?: string
  sessions?: Array<PatientTreatment>
}

const TreatmentDetail: FC<TreatmentDetailProps> = ({
  treatment,
  withDetails = false,
  onEditClick,
  onDeleteClick,
  sessions,
}) => {
  const classes = useStyles()
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { data: treatmentDetails } = usePatientTreatment(treatment.id)
  const { data: providers = [] } = useProviders()
  const { data: treatmentDetail } = usePatientTreatment(treatment.id)
  const { localizedFormatDate } = useDates()
  const { currentSite } = useSitesContext()

  const treatmentProvider = findBy(
    'id',
    treatmentDetails?.providerId,
    providers,
  )
  const treatmentLocation = findBy(
    'id',
    treatmentDetail?.siteLocationId,
    currentSite?.siteLocations || [],
  )

  const medicalTreatmentSlug = treatmentDetail?.medicalTreatment?.slug
  const surveySlug = medicalTreatmentSlug
    ? buildTreatmentDetailSlug(medicalTreatmentSlug)
    : ''

  const { data: existingResponses } = useSurveyResponseSearch({
    ownerIds: [treatment.id],
    ownerType: 'PatientTreatment',
    surveySlugs: [surveySlug],
  })
  const { data: existingSrWithAnswers } = useSurveyResponse({
    id: tail(existingResponses?.surveyResponses || [])?.id,
    ownerType: 'PatientTreatment',
  })

  if (!treatmentDetails) return null

  return (
    <>
      <Box
        pr={2}
        display="flex"
        justifyContent={
          !onEditClick && !onDeleteClick ? 'space-between' : 'unset'
        }
        flexDirection="row"
        pt={2}
        pb={1}
        onClick={() => setIsCollapsed(!isCollapsed)}
        sx={!onEditClick && !onDeleteClick ? { cursor: 'pointer' } : null}
      >
        <H5 sx={{ pointerEvents: 'none' }}>
          {treatmentDetails?.medicalTreatment?.name} &nbsp; (
          {localizedFormatDate(parseYMD(treatment.treatmentDate))})
        </H5>
        {onEditClick && (
          <Link
            className={classes.editButton}
            component="button"
            underline="hover"
            onClick={onEditClick}
          >
            <Body1>Edit</Body1>
          </Link>
        )}
        {onDeleteClick && (
          <DialogTrigger
            action={
              <Link
                component={Body1}
                className={classes.deleteButton}
                underline="hover"
              >
                Delete
              </Link>
            }
            content={(toggle) => (
              <Box p={3} className={classes.dialogContent}>
                <Body1>Are you sure you want to delete this treatment?</Body1>
                <div className={classes.dialogButtons}>
                  <Button
                    size="medium"
                    variant="outlined"
                    onClick={onDeleteClick}
                  >
                    Confirm
                  </Button>
                  <Button size="medium" onClick={() => toggle()}>
                    Cancel
                  </Button>
                </div>
              </Box>
            )}
          />
        )}
        {!onEditClick && !onDeleteClick ? (
          <IconWrapper isCollapsed={isCollapsed}>
            <Icon>
              <ChevronDown />
            </Icon>
          </IconWrapper>
        ) : null}
      </Box>
      <Collapse in={!!onEditClick || !!onDeleteClick || !isCollapsed}>
        {treatmentProvider && (
          <DataPair label="Provider" value={buildName(treatmentProvider)} />
        )}
        {treatmentDetails.treatmentProduct && (
          <DataPair
            label="Treatment Product"
            value={treatmentDetails.treatmentProduct.name}
          />
        )}
        {treatmentLocation && treatmentLocation.name && (
          <DataPair label="Clinic Location" value={treatmentLocation.name} />
        )}

        {treatmentDetails.researchStudyNumber && (
          <DataPair
            label="Research Study Number"
            value={treatmentDetails.researchStudyNumber}
          />
        )}
        {withDetails && existingSrWithAnswers && (
          <Box my={3}>
            <Box mt={3} mb={2}>
              <H5 sx={{ pointerEvents: 'none' }}>
                {treatmentDetails.parentId
                  ? 'Session details'
                  : 'Treatment details'}
              </H5>
            </Box>
            {existingSrWithAnswers.surveyResponseAnswers.map((sra) => {
              if (!sra.surveyQuestion) return
              return (
                <DataPair
                  key={sra.id}
                  label={
                    sra.surveyQuestion.data.prompt
                      ? sra.surveyQuestion.data.prompt.trim().endsWith('*')
                        ? sra.surveyQuestion.data.prompt
                            .trim()
                            .replace(/.$/, '')
                        : sra.surveyQuestion.data.prompt.trim()
                      : 'Unknown'
                  }
                  value={
                    getAnswerValueLabels(sra.surveyQuestion, sra.values) ||
                    'None'
                  }
                />
              )
            })}
          </Box>
        )}
        {sessions && sessions.length > 0 && <H5>Additional Sessions</H5>}
        {sessions &&
          sessions.length > 0 &&
          sessions.map((session) => {
            return (
              <SessionDetail
                key={session.id}
                session={session}
                withDetails
              ></SessionDetail>
            )
          })}
      </Collapse>
    </>
  )
}

const getAnswerValueLabels = (
  item: ISurveyQuestionItem,
  values: ISurveyResponseAnswer['values'],
) => {
  switch (item.variant) {
    case QuestionVariants.inputInteger:
    case QuestionVariants.inputString:
    case QuestionVariants.inputText:
    case QuestionVariants.inputDecimal:
    case QuestionVariants.postalCode:
    case QuestionVariants.slider:
    case QuestionVariants.sliderHorizontal:
      return values.join(', ')

    case QuestionVariants.choiceDropdown:
    case QuestionVariants.choiceLong:
    case QuestionVariants.choiceStandard: {
      const options = item.data.answerOptions
      return options
        .filter((opt) => values.includes(opt.value))
        .map((opt) => opt.label)
        .join(', ')
    }

    default:
  }
}

export default TreatmentDetail
