import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Subscription } from './useSubscription'

interface CreateSubscriptionResponse {
  subscription: Subscription
}

interface CreateSubscriptionVars {
  items: CartItem[]
  zeroSubscription?: boolean
}

interface CartItem {
  price: string
  amount: number
  quantity: number
}

const useCreateSubscription = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite()?.id || ''

  return useMutation((vars: CreateSubscriptionVars) => {
    const url = `/sites/subscriptions`
    const items = vars.items.filter((item) => item.quantity > 0)
    return request
      .post<CreateSubscriptionResponse>(url, {
        siteIds: [siteId],
        items: items,
        zeroSubscription: vars.zeroSubscription || undefined,
      })
      .then((response) => {
        queryClient.invalidateQueries(QueryKeys.subscription())
        queryClient.invalidateQueries(QueryKeys.productsAvailable())
        return response.data.subscription
      })
  })
}

export default useCreateSubscription
