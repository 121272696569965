import { FC, useState } from 'react'
import {
  // Alert,
  Autocomplete,
  Box,
  // Button,
  Grid,
  makeStyles,
  TextField,
} from '@perk-ui/core'
import { useFormik } from 'formik'
import { matchSorter } from 'match-sorter'
import * as yup from 'yup'

import { staffRoles } from '../config/constants'
import { useUser } from '../features/auth/AuthContext'
// import { FileText, Lock, User as UserICon, X } from 'react-feather'
import { StaffRole } from '../features/query-hooks/types'
import { humanizeBodySite } from '../features/query-hooks/useBodySites'
import useNpiValidation from '../features/query-hooks/useNpiValidation'
import { keyToLabel } from '../pages/Home/components/BodyPartGraph'

interface UserProfileProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'calc(100% - 55px)',
    overflow: 'scroll',
    padding: theme.spacing(0, 2, 0, 0),
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    },
  },
  alertSuccess: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 2),
  },
}))

interface UserProfileValues {
  firstName: string
  lastName: string
  email: string
  type: StaffRole
  keratin?: string
  npi?: string
}

const UserProfile: FC<UserProfileProps> = () => {
  const classes = useStyles()
  const validateNpi = useNpiValidation()
  const user = useUser()
  const [previousNpiValidated, setPreviousNpiValidated] = useState('')
  const [isPreviousNpiValid, setIsPreviousNpiValid] = useState(false)

  const userSettingsValidations = yup.object({
    firstName: yup.string().required('First name is required'),
    lastName: yup.string().required('Last name is required'),
    email: yup
      .string()
      .required('Email is required')
      .email('Ensure the email is formatted correctly'),
    type: yup
      // .oneOf([staffRoles])
      .string()
      .required('Role is required'),
    npi: yup
      .string()
      .when('type', {
        is: (type: string) => type === 'Provider',
        then: yup
          .string()
          .min(10, 'NPI must be 10 digits')
          .max(10, 'NPI must be 10 digits')
          .required('NPI is required.')
          .test('valid-npi', 'Invalid NPI Number', (value = '') =>
            value && value !== previousNpiValidated
              ? NpiVerification(value)
              : isPreviousNpiValid,
          ),
      })
      .nullable(),
  })

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    // setFieldError,
    setFieldValue,
    setFieldTouched,
    // isSubmitting,
    // isValid,
    touched,
  } = useFormik<UserProfileValues>({
    enableReinitialize: true,
    initialValues: {
      firstName: user?.staff?.firstName || '',
      npi: user?.staff?.npi || '',
      lastName: user?.staff?.lastName || '',
      email: user?.email || '',
      type: user?.staff?.type || ('' as StaffRole),
    },
    validationSchema: userSettingsValidations,
    onSubmit: () => {},
  })

  const NpiVerification = (npi: string) => {
    if (npi.length !== 10) return false
    return validateNpi
      .mutateAsync({
        npi: npi,
      })
      .then((res) => {
        setPreviousNpiValidated(npi)
        if (res.resultCount > 0) {
          const responseProvider = res.results[0].basic
          setFieldValue(
            'firstName',
            humanizeBodySite(responseProvider.firstName),
          )
          setFieldValue('lastName', humanizeBodySite(responseProvider.lastName))
          setIsPreviousNpiValid(true)
          return true
        } else {
          setFieldValue('firstName', '')
          setFieldValue('lastName', '')
          setIsPreviousNpiValid(false)
          return false
        }
      })
      .catch(() => false)
  }

  return (
    <Box className={classes.root}>
      <form className="form-content" onSubmit={handleSubmit}>
        <Autocomplete
          options={staffRoles}
          getOptionLabel={(opt) => keyToLabel(opt)}
          filterOptions={(options, { inputValue }) =>
            inputValue ? matchSorter(options, inputValue, {}) : options
          }
          onChange={(_event, type) => {
            setFieldValue('type', type)
            setFieldValue('firstName', '')
            setFieldValue('lastName', '')
            setFieldValue('npi', '')
          }}
          onBlur={() => {
            setFieldTouched('type')
          }}
          value={staffRoles.find((type) => type === values.type) || null}
          disabled
          renderInput={(params) => {
            // @ts-expect-error Mui's autocomplete types here are incomplete
            params.InputLabelProps.shrink = true
            return (
              <TextField
                {...params}
                label="Role"
                placeholder="Select Role"
                disabled
                error={touched.type && Boolean(errors.type)}
                helperText={touched.type && errors.type}
              />
            )
          }}
        />

        {values.type === 'Provider' && (
          <TextField
            label="NPI"
            name="npi"
            placeholder="Enter NPI"
            type={'number'}
            fullWidth
            disabled
            value={values.npi}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.npi && Boolean(errors.npi)}
            helperText={touched.npi && errors.npi}
            inputProps={{ size: 10 }}
          />
        )}
        <Grid container spacing={3}>
          <Grid item xs sm={6}>
            <TextField
              label="First Name"
              name="firstName"
              placeholder="Enter first name"
              fullWidth
              disabled
              value={values.firstName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.firstName && Boolean(errors.firstName)}
              helperText={touched.firstName && errors.firstName}
              InputProps={{
                readOnly: values.type === 'Provider',
              }}
            />
          </Grid>
          <Grid item xs sm={6}>
            <TextField
              label="Last Name"
              name="lastName"
              placeholder="Enter last name"
              fullWidth
              disabled
              value={values.lastName}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.lastName && Boolean(errors.lastName)}
              helperText={touched.lastName && errors.lastName}
              InputProps={{
                readOnly: values.type === 'Provider',
              }}
            />
          </Grid>
        </Grid>
        <TextField
          label="Email"
          name="email"
          placeholder="Enter email"
          fullWidth
          disabled
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />
        {/* <Button
          type="submit"
          fullWidth
          loading={isSubmitting}
          disabled={!isValid}
        >
          Create Account
        </Button> */}
      </form>
    </Box>
  )
}

export default UserProfile
