import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { BodySiteKey } from './useBodySites'

export interface BodySiteFilter {
  diagnosis: string[]
  providerName: string[]
  treatment: string[]
  age: {
    maximum: number
    minimum: number
  }
  bmi: {
    maximum: number
    minimum: number
  }
}

export type BodySitesFilters = Record<BodySiteKey, BodySiteFilter>[]

export interface AggregateOutcomesFilters {
  all: {
    bodySiteKey: BodySiteKey[]
    // An array of string with diagnosis names
    diagnosis: string[]
    providerName: string[]
    treatment: string[]
    age: {
      maximum: number
      minimum: number
    }
    bmi: {
      maximum: number
      minimum: number
    }
  }
  bodySites: BodySitesFilters
}

export interface AggregateOutcomesFiltersResponse {
  filters: AggregateOutcomesFilters
}

const useAggregateOutcomesFilters = (
  extraOptions: UseQueryOptions<AggregateOutcomesFiltersResponse> = {},
) => {
  const currentSiteId = useCurrentSite().id
  const siteIds = currentSiteId

  const key = QueryKeys.aggregateOutcomesFilters()
  const url = `/sites/aggregate_outcomes/filter_options?site_ids[]=${siteIds}`

  return useQuery(
    key,
    () =>
      request.get<AggregateOutcomesFiltersResponse>(url).then((r) => r.data),
    {
      ...extraOptions,
    },
  )
}

export default useAggregateOutcomesFilters
