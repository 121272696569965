import { useQuery, UseQueryOptions } from 'react-query'

import { ISurvey } from '../../../../core/dist'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export interface Patient {}

interface SurveyResponse extends ISurvey {}

interface SurveyQueryParams {
  slug?: string
}

const useSurvey = (
  { slug }: SurveyQueryParams,
  extraOptions: UseQueryOptions<ISurvey> = {},
) => {
  const key = slug ? QueryKeys.survey(slug) : []
  const url = `/surveys/${slug}`

  return useQuery(
    key,
    () => request.get<SurveyResponse>(url).then((r) => r.data),
    {
      enabled: Boolean(slug),
      ...extraOptions,
    },
  )
}

export default useSurvey
