var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { cloneElement, isValidElement } from 'react';
import clsx from 'clsx';
import { AppBar, H5, makeStyles, Toolbar } from '../';
const useStyles = makeStyles((theme) => ({
    appBar: {
        backgroundColor: theme.palette.common.white,
    },
    boxShadow: {
        boxShadow: theme.perk.boxShadow.card,
    },
    toolbar: {
        padding: theme.spacing(0, 4),
        width: '100%',
    },
    toolbarUpSmPadding: {
        [theme.breakpoints.up('sm')]: {
            // 72px left and right spacing for tablet+ screens
            padding: theme.spacing(0, 9),
        },
    },
    leftColumn: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
    },
    centerColumn: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        justifyContent: 'center',
    },
    rightColumn: {
        alignItems: 'center',
        display: 'flex',
        width: '100%',
        justifyContent: 'flex-end',
    },
    title: {
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
}), { name: 'HeaderBar' });
/**
 * A three-column (or two-column) navigation bar for the top of a page.
 */
const HeaderBar = (_a) => {
    var { left: leftProp, right: rightProp, titlePosition = 'center', titleContent, toolbarProps = {}, 
    // prop defaults for the base Mui AppBar
    color = 'default', position = 'sticky', component = 'header', className, elevation } = _a, rest = __rest(_a, ["left", "right", "titlePosition", "titleContent", "toolbarProps", "color", "position", "component", "className", "elevation"]);
    const classes = useStyles(elevation);
    // const component = componentProp || 'header'
    const { className: toolbarClassName } = toolbarProps, toolbarRest = __rest(toolbarProps, ["className"]);
    const titlePositionedLeft = titlePosition === 'left';
    let left = leftProp;
    if (isValidElement(left) && left.props.edge === undefined) {
        left = cloneElement(left, { edge: 'start' });
    }
    let right = rightProp;
    if (isValidElement(right) && right.props.edge === undefined) {
        right = cloneElement(right, { edge: 'end' });
    }
    const title = typeof titleContent === 'string' ? (_jsx(H5, Object.assign({ align: titlePosition, classes: { root: classes.title } }, { children: titleContent }), void 0)) : (titleContent);
    return (_jsx(AppBar, Object.assign({ className: clsx(className, classes.appBar, elevation !== 0 && classes.boxShadow), position: position, component: component, color: color, elevation: elevation }, rest, { children: _jsxs(Toolbar, Object.assign({ className: clsx(classes.toolbar, toolbarClassName), disableGutters: true }, toolbarRest, { children: [_jsxs("div", Object.assign({ className: classes.leftColumn }, { children: [titlePositionedLeft && title, !titlePositionedLeft && left] }), void 0), _jsx("div", Object.assign({ className: classes.centerColumn }, { children: titlePositionedLeft ? null : title }), void 0), _jsx("div", Object.assign({ className: classes.rightColumn }, { children: right }), void 0)] }), void 0) }), void 0));
};
export default HeaderBar;
