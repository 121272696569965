import { FC, ReactNode } from 'react'
import { Body1, makeStyles } from '@perk-ui/core'

export interface DataPairProps {
  label: string
  value: ReactNode
}

const useStyles = makeStyles(() => ({
  label: {
    // Just a smidge lighter than 'bold'
    fontWeight: 500,
  },
}))

const DataPair: FC<DataPairProps> = ({ label, value }) => {
  const classes = useStyles()
  return (
    <Body1 gutterBottom>
      <span className={classes.label}>{label}:</span> {value}
    </Body1>
  )
}

export default DataPair
