var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { forwardRef } from 'react';
import MuiTypography from '@mui/material/Typography';
import clsx from 'clsx';
import { makeStyles } from '../';
const useStyles = makeStyles((theme) => ({
    body2: {
        fontWeight: theme.typography.fontWeightBold,
    },
}), { name: 'Typography' });
/**
 * Provides a subset of Material-UI typography styles.
 * Sorry for the complex type, it could probably be simpler. Extending
 * MUI types sometimes feels obstreperous.
 */
const Typography = forwardRef(function Typography(_a, ref) {
    var { classes: classesProp, component } = _a, props = __rest(_a, ["classes", "component"]);
    const classes = useStyles();
    return (_jsx(MuiTypography, Object.assign({ ref: ref, classes: Object.assign({ body2: clsx(classes.body2, classesProp && classesProp.body2) }, classesProp), color: props.variant === 'body2' ? 'textSecondary' : undefined, component: component }, props), void 0));
});
export default Typography;
/** <Typography variant="h1" .. />  */
export const H1 = forwardRef((props, ref) => (_jsx(Typography, Object.assign({ ref: ref, variant: "h1" }, props), void 0)));
/** <Typography variant="h2" .. />  */
export const H2 = forwardRef((props, ref) => (_jsx(Typography, Object.assign({ ref: ref, variant: "h2" }, props), void 0)));
/** <Typography variant="h3" .. />  */
export const H3 = forwardRef((props, ref) => (_jsx(Typography, Object.assign({ ref: ref, variant: "h3" }, props), void 0)));
/** <Typography variant="h4" .. />  */
export const H5 = forwardRef((props, ref) => (_jsx(Typography, Object.assign({ ref: ref, variant: "h5" }, props), void 0)));
/** <Typography variant="body1" .. /> */
export const Body1 = forwardRef((props, ref) => (_jsx(Typography, Object.assign({ ref: ref, variant: "body1" }, props), void 0)));
/** <Typography variant="body2" .. /> */
export const Body2 = forwardRef((props, ref) => (_jsx(Typography, Object.assign({ ref: ref, variant: "body2" }, props), void 0)));
