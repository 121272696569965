import { Dispatch, FC, SetStateAction, useState } from 'react'
import {
  Box,
  Button,
  Dialog,
  H2,
  H5,
  makeStyles,
  Tab,
  Tabs,
  TextField,
} from '@perk-ui/core'
import { useFormik } from 'formik'
import {
  // Camera,
  // Edit3,
  Type,
} from 'react-feather'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'

import useSignContract from '../../../features/query-hooks/useSignContract'
import { ClinicContract } from '../../../features/query-hooks/useUnauthenticatedClinicContracts'
import useUpdateOnboardingStep from '../../../features/query-hooks/useUpdateOnboardingStep'
import { onboardingUrls } from '../Onboarding'

interface SignContractDialogProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  contracts: Array<ClinicContract>
  setCurrentContractId: Dispatch<SetStateAction<string | null>>
  currentContractId: string | null
  type: 'authenticated' | 'unauthenticated'
}

interface SignContractDialogFormValues {
  signature: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiDialog-container': {
      '& .MuiPaper-root': {
        width: '100%',
        maxWidth: '648px',
        padding: theme.spacing(4),
      },
    },
  },
  form: {
    '& .MuiFormHelperText-root': {
      textAlign: 'center',
    },
  },
  signatureField: {
    marginBottom: theme.spacing(3),
    '& .MuiInput-root': {
      padding: theme.spacing(11, 1, 1, 1),
      '&:hover:not(.Mui-disabled)': {
        borderColor: 'transparent',
      },
      '&:focus-visible': {
        borderColor: 'transparent',
      },
      '&.Mui-focused': {
        backgroundColor: '#f5f5f8',
      },
      '& input': {
        borderBottom: `1px solid ${theme.palette.common.black}`,
        borderRadius: 0,
        fontSize: '70px',
        fontFamily: 'Caveat',
        fontWeight: 'bold',
        fontStyle: 'oblique',
        textAlign: 'center',
      },
    },
  },
  dialogHeader: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    '& h2': {
      fontSize: '32px',
      fontWeight: 700,
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  signatureTypeTabs: {
    border: '1px solid #f1f1f1',
    borderRadius: '16px',
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
    '& .MuiTabs-flexContainer': {
      '& button.Mui-selected': {
        borderRadius: '8px',
        backgroundColor: '#E9F7FC', // primary.light?
      },
      '& button': {
        minHeight: '24px',
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  submitButton: {},
  closeButton: {
    color: theme.palette.common.black,
    minHeight: '20px',
    padding: 0,
    marginTop: theme.spacing(2),
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.dark,
    },
    '&:not(:disabled):active': {
      backgroundColor: 'unset',
    },
  },
  footerNote: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#959595',
    marginBottom: theme.spacing(2),
  },
  dialogFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
}))

const SignContractDialog: FC<SignContractDialogProps> = ({
  open,
  setOpen,
  currentContractId,
  contracts,
  setCurrentContractId,
  type,
}) => {
  // const { signIn } = useAuth()
  const classes = useStyles()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const currentStepIndex = onboardingUrls.indexOf(pathname)
  const [currentSignatureType, setCurrentSignatureType] = useState('type')

  const sendEmailContractValidation = yup.object({
    signature: yup.string().required('Signature is required'),
  })

  const { mutateAsync: sign } = useSignContract()
  const updateOnboardingStep = useUpdateOnboardingStep()

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldError,
    // setFieldValue,
    // setSubmitting,
    resetForm,
    isSubmitting,
    isValid,
    touched,
  } = useFormik<SignContractDialogFormValues>({
    // enableReinitialize: true,
    initialValues: {
      signature: '',
    },
    validationSchema: sendEmailContractValidation,
    onSubmit: async (values) => {
      return sign({
        contractId: currentContractId || '',
        signature: values.signature,
      })
        .then(() => {
          const nextContract = contracts.find(
            (contract) =>
              contract.id !== currentContractId && contract.status == 'pending',
          )

          // if there's pending contracts to sign change currentContractId if not, move to next step
          if (nextContract) {
            setCurrentContractId(nextContract.id)
            onDialogClose()
          } else {
            if (type == 'authenticated') {
              updateOnboardingStep
                .mutateAsync({
                  onboardingStep: 'payment-information',
                })
                .then(() => {
                  setTimeout(() => {
                    push(onboardingUrls[currentStepIndex + 1])
                  }, 2000)
                })
            } else {
              const currentContractLink =
                contracts.find((contract) => contract.id == currentContractId)
                  ?.url || ''
              push('sign-contract/thank-you?link=' + currentContractLink)
            }
          }
        })
        .catch(() => {
          setFieldError(
            'signature',
            'There was a problem signing this contract.',
          )
        })
    },
  })

  const signatureType = [
    // {
    //   key: 'canvas',
    //   label: 'Draw',
    //   icon: <Edit3 />,
    //   disabled: true,
    // },
    {
      key: 'type',
      label: 'Type',
      icon: <Type />,
      disabled: false,
    },
    // {
    //   key: 'upload',
    //   label: 'Upload',
    //   icon: <Camera />,
    //   disabled: true,
    // },
  ]

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentSignatureType(newValue)
  }

  const signatureContent = () => {
    switch (currentSignatureType) {
      case 'draw':
        return <></>
      case 'type':
        return (
          <form className={classes.form} onSubmit={handleSubmit}>
            <TextField
              label=""
              name="signature"
              placeholder=""
              className={classes.signatureField}
              fullWidth
              value={values.signature}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.signature && Boolean(errors.signature)}
              helperText={touched.signature && errors.signature}
            />
            <Box className={classes.footerNote}>
              By signing this document with an electronic signature I agree that
              such signature will be as valid as handwritten signatures to the
              extent allowed by law.
            </Box>
            <Box className={classes.dialogFooter}>
              <Button
                variant="text"
                onClick={onDialogClose}
                className={classes.closeButton}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={!isValid}
                className={classes.submitButton}
              >
                Accept and Sign
              </Button>
            </Box>
          </form>
        )
      case 'upload':
        return <></>
      default:
        return <></>
    }
  }

  const onDialogClose = () => {
    resetForm()
    setOpen(false)
  }

  return (
    <Dialog onClose={onDialogClose} open={open} className={classes.root}>
      <Box className={classes.dialogHeader}>
        <H2>Add your signature</H2>
        <H5>Add your signature to sign agreement</H5>
      </Box>
      <Box className={classes.tabsContainer}>
        <Tabs
          className={classes.signatureTypeTabs}
          value={currentSignatureType}
          onChange={handleTabChange}
        >
          {signatureType.map((type) => {
            return (
              <Tab
                key={type.key}
                value={type.key}
                label={type.label}
                icon={type.icon}
                iconPosition="start"
                disabled={type.disabled}
              />
            )
          })}
        </Tabs>
      </Box>
      {signatureContent()}
    </Dialog>
  )
}

export default SignContractDialog
