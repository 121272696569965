import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

const useDeleteProtocolTreatment = () => {
  const queryClient = useQueryClient()
  const currentSiteId = useCurrentSite().id

  return useMutation((treatmentId: string) => {
    const url = `/sites/protocol_treatments/${treatmentId}?site_ids[]=${currentSiteId}`

    return request.delete(url).then((r) => {
      queryClient.invalidateQueries(
        QueryKeys.protocols({ clinicId: currentSiteId }),
      )
      return r.data
    })
  })
}

export default useDeleteProtocolTreatment
