import { useMutation } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'

export interface ResendSurveyNotificationVars {
  surveyResponseGroupId: string
  medium: string
}

const useResendSurveyNotification = () => {
  const siteId = useCurrentSite().id

  return useMutation((vars: ResendSurveyNotificationVars) => {
    const url = `/sites/survey_response_groups/${vars.surveyResponseGroupId}/resend`

    return request.post<[]>(url, {
      siteIds: [siteId],
      medium: vars.medium,
    })
  })
}

export default useResendSurveyNotification
