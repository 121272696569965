import { FC, useEffect, useState } from 'react'
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  H1,
  H3,
  makeStyles,
  PhoneField,
  TextField,
} from '@perk-ui/core'
import { useFormik } from 'formik'
import { matchSorter } from 'match-sorter'
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService'
// import { useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router-dom'
import * as yup from 'yup'

import {
  useCurrentSite,
  useSitesContext,
} from '../../../components/SitesProvider'
import config from '../../../config/app-config'
import {
  canadaTimeZones,
  countryList,
  siteSpecialties,
  unitedStatesTimeZones,
} from '../../../config/constants'
// import QueryKeys from '../../../features/query-hooks/QueryKeys'
import useCreateClinicSite from '../../../features/query-hooks/useCreateClinicSite'
// import useOnboardingStep from '../../../features/query-hooks/useOnboardingStep'
import { onboardingUrls } from '../Onboarding'

interface ClinicSetupStepProps {}

interface AutocompleteItem {
  long_name: string
  short_name: string
  types: Array<string>
}

interface ClinicSetupStepFormValues {
  name: string
  country: string
  timeZone: string
  specialty: string[]
  address: ClinicAddresFormValues
  createSite?: string
}

interface ClinicAddresFormValues {
  unitApartmentSuite: string
  street: string
  zipCode: string
  city: string
  stateProvince: string
  phone: string
}

const googleMapsApiKey = config.googleMapsApiKey

const useStyles = makeStyles((theme) => ({
  helperLabel: {
    '& .helper-span': {
      fontSize: '12px',
      fontWeight: 500,
      '& .helper-link': {
        color: theme.palette.primary.main,
        '&:hover': {
          cursor: 'pointer',
          color: theme.palette.primary.dark,
          textDecoration: 'underline',
        },
      },
    },
  },
}))

const ClinicSetupStep: FC<ClinicSetupStepProps> = () => {
  const classes = useStyles()
  // const queryClient = useQueryClient()
  const addClinicSite = useCreateClinicSite()
  const { setCurrentSite } = useSitesContext()
  const currentSite = useCurrentSite()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const currentSiteLocation = currentSite?.siteLocations.find(
    (site) => site.isPrimary === true,
  )
  const name = (label: string) => `address.${label}`
  const [timeZones, setTimeZones] = useState<string[]>([])
  const [completeManually, setCompleteManually] = useState(false)
  const [placeSelected, setPlaceSelected] = useState(false)
  const [succeeded, setSucceeded] = useState(false)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [googlePlace, setGooglePlace] = useState<any>()

  const {
    placesService,
    placePredictions,
    getPlacePredictions,
    // isPlacePredictionsLoading,
  } = usePlacesService({
    apiKey: googleMapsApiKey,
    options: {
      componentRestrictions: {
        country: completeManually ? [] : ['ca', 'us'],
      },
      fields: ['address_components'],
      types: ['address'],
    },
  })
  const clinicSetupValidations = yup.object({
    name: yup.string().defined('Clinic name is required'),
    // shortName: yup.string().defined('Short name is required'),
    country: yup
      .string()
      .defined('Country is required')
      .required('Country is required'),
    timeZone: yup.string().defined('Time Zone is required'),
    specialty: yup
      .array()
      .min(1, 'At least 1 specialty is required')
      .required('Specialty is required.'),
    address: yup.object({
      street: yup.string().defined('Street name is required'),
      // unitApartmentSuite: yup
      //   .string()
      //   .defined('Unit/Apartment/Suite is required'),
      city: yup.string().defined('City name is required'),
      stateProvince: yup.string().defined('State/Province is required'),
      zipCode: yup
        .string() // strip space and dash
        .when([], {
          is: () => values.country === 'canada',
          then: yup
            .string()
            .length(6, 'Postal code should be 6 characters')
            .defined('Postal code is required'),
          otherwise: yup
            .string()
            .length(5, 'Zip code should be 5 characters')
            .defined('Zip code is required'),
        }),
      phone: yup
        .string()
        .transform((value) => {
          let transformValue = value.replace(/\D/g, '')
          if (transformValue.length === 11)
            transformValue = transformValue.substring(1)
          return transformValue
        }) // strip parenthesis, space, and dash
        .length(10, 'Phone should be 10 numbers')
        .matches(/^[0-9]{10}$/, 'Please check the phone')
        .defined('Phone is required to send assessments'),
    }),
  })

  const currentStepIndex = onboardingUrls.indexOf(pathname)

  useEffect(() => {
    if (succeeded && currentSite) {
      setTimeout(() => {
        push(onboardingUrls[currentStepIndex + 1])
      }, 2000)
    }
  }, [currentSite, succeeded])

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldError,
    setFieldValue,
    setFieldTouched,
    isSubmitting,
    isValid,
    touched,
  } = useFormik<ClinicSetupStepFormValues>({
    enableReinitialize: true,
    initialValues: {
      name: currentSite?.name ? currentSite.name : '',
      country: currentSite?.country ? currentSite.country : '',
      timeZone: currentSite?.timeZone ? currentSite.timeZone : '',
      specialty: currentSite?.specialty ? currentSite.specialty : [],
      address: {
        unitApartmentSuite: currentSiteLocation?.unitApartmentSuite
          ? currentSiteLocation.unitApartmentSuite
          : '',
        street: currentSiteLocation?.street ? currentSiteLocation.street : '',
        zipCode: currentSiteLocation?.zipCode
          ? currentSiteLocation.zipCode
          : '',
        city: currentSiteLocation?.city ? currentSiteLocation.city : '',
        stateProvince: currentSiteLocation?.stateProvince
          ? currentSiteLocation.stateProvince
          : '',
        phone: currentSiteLocation?.phone ? currentSiteLocation.phone : '',
      },
    },
    validationSchema: clinicSetupValidations,
    onSubmit: (values) => {
      // Trim whitespace from all values
      Object.keys(values).forEach((key) => {
        const k = key as keyof ClinicSetupStepFormValues
        if (typeof values[k] === 'string') {
          ;(values[k] as string).trim()
        }
      })
      if (currentSite) return push(onboardingUrls[currentStepIndex + 1])
      return addClinicSite
        .mutateAsync({
          name: values.name,
          country: values.country,
          timeZone: values.timeZone,
          specialty: values.specialty,
          street: values.address.street,
          unitApartmentSuite: values.address.unitApartmentSuite,
          zipCode: values.address.zipCode,
          city: values.address.city,
          stateProvince: values.address.stateProvince,
          phone: values.address.phone,
        })
        .catch((err) => {
          setFieldError('createSite', err.response.data.errors)
          throw err
        })
        .then((res) => {
          if (res.site) {
            setCurrentSite(res.site)
            setSucceeded(true)
          }
        })
    },
  })

  useEffect(() => {
    if (googlePlace) {
      const result = googlePlace?.address_components
      const country =
        result.find((autocomplete: AutocompleteItem) =>
          autocomplete.types.includes('country'),
        )?.short_name || ''
      const street_number =
        result.find((autocomplete: AutocompleteItem) =>
          autocomplete.types.includes('street_number'),
        )?.short_name || ''
      const route =
        result.find((autocomplete: AutocompleteItem) =>
          autocomplete.types.includes('route'),
        )?.short_name || ''
      const postal_code =
        result
          .find((autocomplete: AutocompleteItem) =>
            autocomplete.types.includes('postal_code'),
          )
          ?.short_name.replace(/[-\s]/g, '') || ''
      const locality =
        result.find((autocomplete: AutocompleteItem) =>
          autocomplete.types.includes('locality'),
        )?.short_name || ''
      const stateProvince =
        result.find((autocomplete: AutocompleteItem) =>
          autocomplete.types.includes('administrative_area_level_1'),
        )?.long_name || ''
      setFieldValue(name('street'), street_number + ' ' + route)
      if (!completeManually) {
        setFieldValue(name('zipCode'), postal_code)
        setFieldValue(name('city'), locality)
        setFieldValue(name('stateProvince'), stateProvince)
        setFieldValue(
          'country',
          country === 'CA' ? 'canada' : 'united_states_of_america',
        )
        if (country) {
          if (country === 'CA') {
            setTimeZones(canadaTimeZones)
          } else if (country === 'US') {
            setTimeZones(unitedStatesTimeZones)
          }
        }
      }
    }
  }, [googlePlace])

  return (
    <Box className="step-content">
      <Box className="step-header">
        <H1>Set up your clinic</H1>
        <H3>{"Let's set up your clinic's account"}</H3>
      </Box>

      <form className="clinic-setup-form" onSubmit={handleSubmit}>
        {errors.createSite && (
          <Alert severity="error" fade grow>
            Account creation failed.
            <br />
            <br />
            {errors.createSite}
          </Alert>
        )}
        <Grid container spacing={2} rowSpacing={0}>
          <Grid item xs={12} sm={6}>
            <TextField
              label="Clinic Site Name"
              name="name"
              placeholder="Enter clinic name"
              fullWidth
              required
              value={values.name}
              onChange={handleChange}
              onBlur={handleBlur}
              disabled={currentSite ? true : false}
              error={touched.name && Boolean(errors.name)}
              helperText={touched.name && errors.name}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              options={siteSpecialties}
              getOptionLabel={(opt) => opt}
              isOptionEqualToValue={(opt, val) => opt === val}
              filterOptions={(options, { inputValue }) =>
                inputValue ? matchSorter(options, inputValue, {}) : options
              }
              onBlur={() => {
                setFieldTouched('specialty')
              }}
              onChange={(_event, data) => {
                setFieldValue('specialty', data)
              }}
              value={values.specialty || []}
              disabled={currentSite ? true : false}
              renderInput={(params) => {
                // @ts-expect-error Mui's autocomplete types here are incomplete
                params.InputLabelProps.shrink = true
                return (
                  <TextField
                    {...params}
                    label="Specialty"
                    required
                    value={values.specialty}
                    inputProps={{
                      ...params.inputProps,
                      required: values.specialty.length === 0,
                    }}
                    placeholder={
                      values.specialty.length > 0 ? '' : 'Select specialty'
                    }
                    error={touched.specialty && Boolean(errors.specialty)}
                    helperText={touched.specialty && errors.specialty}
                  />
                )
              }}
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Autocomplete
              disableClearable
              freeSolo
              options={completeManually ? [] : placePredictions}
              getOptionLabel={(opt) => opt.description || ''}
              onBlur={() => {
                setFieldTouched(name('street'))
              }}
              onChange={(_event, place) => {
                if (completeManually) {
                  setFieldValue(name('street'), place)
                } else {
                  placesService?.getDetails(
                    {
                      placeId: place.place_id,
                      fields: ['address_components'],
                    },
                    // eslint-disable-next-line @typescript-eslint/no-explicit-any
                    (placeDetails: any) => {
                      setGooglePlace(placeDetails)
                      setPlaceSelected(true)
                    },
                  )
                }
              }}
              onInputChange={(_event, street) => {
                if (_event && _event.type == 'change')
                  setFieldValue(name('street'), street)
                if (completeManually) {
                  setFieldValue(name('street'), street)
                } else {
                  getPlacePredictions({
                    input: street,
                    componentRestrictions: {
                      country: ['ca', 'us'],
                    },
                    types: ['address'],
                  })
                }
              }}
              inputValue={values.address.street}
              disabled={currentSiteLocation ? true : false}
              renderInput={(params) => {
                // @ts-expect-error Mui's autocomplete types here are incomplete
                params.InputLabelProps.shrink = true
                return (
                  <TextField
                    {...params}
                    label={
                      completeManually ? (
                        <span className={classes.helperLabel}>
                          Street{' '}
                          {!currentSite && (
                            <span className="helper-span">
                              (
                              <span
                                className="helper-link"
                                onClick={() =>
                                  setCompleteManually(!completeManually)
                                }
                              >
                                Find Address
                              </span>
                              )
                            </span>
                          )}
                        </span>
                      ) : (
                        <span className={classes.helperLabel}>
                          Primary Address{' '}
                          {!currentSite && (
                            <span className="helper-span">
                              (Start typing your address or{' '}
                              <span
                                className="helper-link"
                                onClick={() =>
                                  setCompleteManually(!completeManually)
                                }
                              >
                                enter it manually
                              </span>
                              )
                            </span>
                          )}
                        </span>
                      )
                    }
                    placeholder="Start typing in your address"
                    required
                    error={
                      touched?.address?.street &&
                      typeof errors !== 'string' &&
                      Boolean(errors?.address?.street)
                    }
                    helperText={
                      touched?.address?.street &&
                      typeof errors !== 'string' &&
                      errors?.address?.street
                    }
                  />
                )
              }}
            />
          </Grid>
          {(completeManually || placeSelected || currentSite) && (
            <Grid item xs={12} sm={12}>
              <TextField
                label="Unit/Apartment/Suite"
                name="unitApartmentSuite"
                placeholder="Enter unit, apartment or suite"
                fullWidth
                value={values.address.unitApartmentSuite}
                onBlur={() => {
                  setFieldTouched(name('unitApartmentSuite'))
                }}
                onChange={(_event) => {
                  setFieldValue(name('unitApartmentSuite'), _event.target.value)
                }}
                disabled={currentSiteLocation ? true : false}
                error={
                  touched?.address?.unitApartmentSuite &&
                  typeof errors !== 'string' &&
                  Boolean(errors?.address?.unitApartmentSuite)
                }
                helperText={
                  touched?.address?.unitApartmentSuite &&
                  typeof errors !== 'string' &&
                  errors?.address?.unitApartmentSuite
                }
              />
            </Grid>
          )}
          {(completeManually || placeSelected || currentSite) && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                // disableClearable
                options={countryList}
                getOptionLabel={(opt) => opt.label}
                filterOptions={(options, { inputValue }) =>
                  inputValue
                    ? matchSorter(options, inputValue, { keys: ['label'] })
                    : options
                }
                onBlur={() => {
                  setFieldTouched('country')
                }}
                onChange={(_event, country) => {
                  setFieldValue('timeZone', '')
                  // setFieldValue(name('street'), '')
                  setFieldValue(name('zipCode'), '')
                  setFieldValue(name('city'), '')
                  setFieldValue(name('stateProvince'), '')
                  setFieldValue(name('phone'), '')
                  if (country) {
                    setFieldValue('country', country.value)
                    if (country.value === 'canada') {
                      setTimeZones(canadaTimeZones)
                    } else if (country.value === 'united_states_of_america') {
                      setTimeZones(unitedStatesTimeZones)
                    }
                  } else {
                    setFieldValue('country', '')
                  }
                }}
                value={
                  countryList.find(
                    (country) => country.value === values.country,
                  ) || null
                }
                disabled={currentSite ? true : false}
                renderInput={(params) => {
                  // @ts-expect-error Mui's autocomplete types here are incomplete
                  params.InputLabelProps.shrink = true
                  return (
                    <TextField
                      {...params}
                      label="Country"
                      placeholder="Select country"
                      required
                      error={
                        touched?.country &&
                        typeof errors !== 'string' &&
                        Boolean(errors?.country)
                      }
                      helperText={
                        touched?.country &&
                        typeof errors !== 'string' &&
                        errors?.country
                      }
                    />
                  )
                }}
              />
            </Grid>
          )}
          {(completeManually || placeSelected || currentSite) && (
            <Grid item xs={12} sm={6}>
              <Autocomplete
                // disableClearable
                options={timeZones}
                getOptionLabel={(opt) => opt}
                filterOptions={(options, { inputValue }) =>
                  inputValue ? matchSorter(options, inputValue, {}) : options
                }
                onBlur={() => {
                  setFieldTouched('timeZone')
                }}
                onChange={(_event, timeZone) => {
                  setFieldValue('timeZone', timeZone)
                }}
                value={
                  timeZones.find((timeZone) => timeZone === values.timeZone) ||
                  null
                }
                disabled={currentSite ? true : false}
                renderInput={(params) => {
                  // @ts-expect-error Mui's autocomplete types here are incomplete
                  params.InputLabelProps.shrink = true
                  return (
                    <TextField
                      {...params}
                      label="Time Zone"
                      placeholder="Select time zone"
                      required
                      error={
                        touched?.timeZone &&
                        typeof errors !== 'string' &&
                        Boolean(errors?.timeZone)
                      }
                      helperText={
                        touched?.timeZone &&
                        typeof errors !== 'string' &&
                        errors?.timeZone
                      }
                    />
                  )
                }}
              />
            </Grid>
          )}
          {(completeManually || placeSelected || currentSite) && (
            <Grid item xs={12} sm={6}>
              <TextField
                label={values.country === 'canada' ? 'Postal Code' : 'Zip Code'}
                name="zipCode"
                placeholder={
                  values.country === 'canada'
                    ? 'Enter postal code'
                    : 'Enter zip code'
                }
                fullWidth
                required
                value={values.address?.zipCode}
                onBlur={() => {
                  setFieldTouched(name('zipCode'))
                }}
                onChange={(_event) => {
                  setFieldValue(name('zipCode'), _event.target.value)
                }}
                disabled={currentSiteLocation ? true : false}
                error={
                  touched?.address?.zipCode &&
                  typeof errors !== 'string' &&
                  Boolean(errors?.address?.zipCode)
                }
                helperText={
                  touched?.address?.zipCode &&
                  typeof errors !== 'string' &&
                  errors?.address?.zipCode
                }
              />
            </Grid>
          )}
          {(completeManually || placeSelected || currentSite) && (
            <Grid item xs={12} sm={6}>
              <TextField
                label="City"
                name="city"
                placeholder="Enter city"
                fullWidth
                required
                value={values.address?.city}
                onBlur={() => {
                  setFieldTouched(name('city'))
                }}
                onChange={(_event) => {
                  setFieldValue(name('city'), _event.target.value)
                }}
                disabled={currentSiteLocation ? true : false}
                error={
                  touched?.address?.city &&
                  typeof errors !== 'string' &&
                  Boolean(errors?.address?.city)
                }
                helperText={
                  touched?.address?.city &&
                  typeof errors !== 'string' &&
                  errors?.address?.city
                }
              />
            </Grid>
          )}
          {(completeManually || placeSelected || currentSite) && (
            <Grid item xs={12} sm={6}>
              <TextField
                label={values.country === 'canada' ? 'Province' : 'State'}
                name="stateProvince"
                placeholder={
                  values.country === 'canada' ? 'Enter province' : 'Enter state'
                }
                fullWidth
                required
                value={values.address?.stateProvince}
                onBlur={() => {
                  setFieldTouched(name('stateProvince'))
                }}
                onChange={(_event) => {
                  setFieldValue(name('stateProvince'), _event.target.value)
                }}
                disabled={currentSiteLocation ? true : false}
                error={
                  touched?.address?.stateProvince &&
                  typeof errors !== 'string' &&
                  Boolean(errors?.address?.stateProvince)
                }
                helperText={
                  touched?.address?.stateProvince &&
                  typeof errors !== 'string' &&
                  errors?.address?.stateProvince
                }
              />
            </Grid>
          )}
          <Grid item xs={12} sm={6}>
            <PhoneField
              label="Primary location phone"
              name="phone"
              placeholder="Enter number"
              fullWidth
              required
              value={values.address?.phone}
              onBlur={() => {
                setFieldTouched(name('phone'))
              }}
              onChange={(_event) => {
                setFieldValue(name('phone'), _event.target.value)
              }}
              disabled={currentSiteLocation ? true : false}
              error={
                touched?.address?.phone &&
                typeof errors !== 'string' &&
                Boolean(errors?.address?.phone)
              }
              helperText={
                touched?.address?.phone &&
                typeof errors !== 'string' &&
                errors?.address?.phone
              }
            />
          </Grid>
          <Grid item xs={12} sm={12}>
            <Button type="submit" loading={isSubmitting} disabled={!isValid}>
              Continue
            </Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default ClinicSetupStep
