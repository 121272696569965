import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import clsx from 'clsx';
import { getQuestions, groupBy, isItemEnabled, makeStyles, QuestionVariants, SurveyItemDisplay, SurveyItemQuestion, SurveyItemTypes, } from '../';
// TODO Further reduce these classes
const useStyles = makeStyles((theme) => {
    return {
        root: {
        // '& .MuiFormLabel-asterisk, & .MuiInputLabel-asterisk': {
        //   display: 'none',
        // }
        },
        // Mobile screens
        [theme.breakpoints.down('md')]: {
            itemsContainer: {
                display: 'grid',
                gridTemplateRows: 'auto 1fr',
                height: '100%',
                '& [class*=SurveyItemQuestion-root]:not(.SurveySlider-root)': {
                    width: '100%',
                },
                '& input': {
                    textAlign: 'center',
                },
            },
            pageAutoHeight: {
                '&&': {
                    gridTemplateRows: '1fr',
                },
            },
            sliderRows: {
                '&&': {
                    rowGap: theme.spacing(5),
                },
            },
            centerAlignAnswers: {
                '& .SurveyItem-displayQuestion': {
                    alignSelf: 'center',
                },
            },
            bottomAlignAnswers: {
                '& .SurveyItem-displayQuestion': {
                    alignSelf: 'flex-end',
                },
            },
            displayContainer: {
                width: '100%',
                maxHeight: '35vh',
            },
            displayIntro: {},
            displayQuestion: {
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                width: '100%',
                overflow: 'auto',
                textAlign: 'center',
                zIndex: 1,
                '& [class*=MuiTextField-root]': {
                    width: '50%',
                    textAlign: 'center',
                    margin: 'auto',
                },
            },
            displaySliderQuestion: {
                marginBottom: theme.spacing(3),
                overflow: 'visible',
                marginLeft: '44%',
                // iOS customization.
                '.plt-ios &': {
                    marginLeft: '43%',
                    // 108px is the height of the Next button bar
                    // 32px is some extra spacing
                    // TODO. Really, I think we should us JS for height adjustment
                    height: 'calc(100% - 108px - 32px)',
                },
            },
            choiceStandard: {
                // These margin, padding, width, and overflow adjustments are to ensure
                // that the ControlledButtonGroup's box shadow has space to render
                // and our containing div does not encroach on it.
                // marginBottom: theme.spacing(-1),
                paddingLeft: theme.spacing(1),
                paddingRight: theme.spacing(1),
                marginLeft: theme.spacing(-1),
                width: '105%',
                overflow: 'hidden',
            },
            displayChecklistQuestion: {},
        },
        // Non-mobile screens
        [theme.breakpoints.up('md')]: {
            itemsContainer: {
                display: 'grid',
                gridTemplateColumns: '1fr 1fr',
                gap: theme.spacing(3),
                '& .SurveyItemQuestion-select': {
                    width: '100%',
                },
            },
            oneColumn: {
                '&&': {
                    gridTemplateColumns: '1fr',
                },
            },
            displayIntro: {
                minWidth: '50%',
            },
            displayChecklistQuestion: {
                minWidth: '50%',
            },
            displayContainer: {
                minWidth: '50%',
            },
            displayQuestion: {
                alignSelf: 'center',
                minWidth: '50%',
            },
            displaySliderQuestion: {
                justifySelf: 'center',
                height: '50vh',
                marginTop: '2vh',
            },
        },
        manyQuestionsOnePage: {
            width: '100%',
            '& input': {
                textAlign: 'left',
            },
            '& .SurveyItemQuestion-root': {
                width: '100%',
                marginBottom: theme.spacing(2),
            },
        },
    };
}, { name: 'SurveyItem' });
const SurveyItem = ({ item, answers, onAnswer, studySlug, bodySiteKey, defaultIcon, checkedIcon, }) => {
    var _a;
    const classes = useStyles();
    const isIntro = item.type === SurveyItemTypes.group &&
        !item.items.some((item) => item.type === SurveyItemTypes.question);
    switch (item.type) {
        case SurveyItemTypes.group: {
            const displayItems = [];
            const introItems = [];
            const questionItems = [];
            const sliderItems = [];
            const checklistItems = [];
            const manyQuestionsSamePage = [];
            const hasManyQuestions = getQuestions(item).length > 1;
            const groups = groupBy('variant', item.items);
            const hasChoiceStandard = groups[QuestionVariants.choiceStandard];
            const _hasChoiceLong = groups[QuestionVariants.choiceLong];
            const hasInputs = groups[QuestionVariants.inputInteger] ||
                groups[QuestionVariants.inputString] ||
                groups[QuestionVariants.inputText] ||
                groups[QuestionVariants.choiceDropdown];
            item.items.forEach((subItem) => {
                const ItemComponent = (_jsx(SurveyItem, { item: subItem, answers: answers, onAnswer: onAnswer, studySlug: studySlug, bodySiteKey: bodySiteKey, defaultIcon: defaultIcon, checkedIcon: checkedIcon }, subItem.id));
                if (hasManyQuestions) {
                    manyQuestionsSamePage.push(ItemComponent);
                }
                else if (subItem.type === SurveyItemTypes.display) {
                    if (isIntro) {
                        introItems.push(ItemComponent);
                    }
                    else {
                        displayItems.push(ItemComponent);
                    }
                }
                else {
                    if (subItem.type === SurveyItemTypes.question &&
                        (subItem.variant === QuestionVariants.slider ||
                            subItem.variant === QuestionVariants.sliderHorizontal)) {
                        sliderItems.push(ItemComponent);
                    }
                    else if (subItem.type === SurveyItemTypes.question &&
                        subItem.variant === QuestionVariants.choiceLong) {
                        checklistItems.push(ItemComponent);
                    }
                    else {
                        const variantsWithAutofocus = [
                            QuestionVariants.inputInteger,
                            QuestionVariants.inputString,
                            QuestionVariants.inputText,
                            QuestionVariants.zipCode,
                            QuestionVariants.postalCode,
                        ];
                        if (subItem.type === SurveyItemTypes.question &&
                            variantsWithAutofocus.includes(subItem.variant)) {
                            // It may not specifically be an InputStringQuestion, but TS
                            // can't tell that it is one of the `variantsWithAutoFocus` above
                            const itemMask = subItem;
                            itemMask.custom = Object.assign(Object.assign({}, itemMask.custom), { autoFocus: true });
                        }
                        questionItems.push(ItemComponent);
                    }
                }
            });
            // TODO Condense the inner children below to fewer code paths
            return (_jsxs("div", Object.assign({ className: clsx(classes.itemsContainer, {
                    [classes.oneColumn]: isIntro || hasManyQuestions,
                    [classes.pageAutoHeight]: hasManyQuestions,
                    [classes.sliderRows]: sliderItems.length > 0,
                    [classes.centerAlignAnswers]: !hasManyQuestions && hasInputs,
                    [classes.bottomAlignAnswers]: !hasManyQuestions && hasChoiceStandard,
                }) }, { children: [introItems.length > 0 && (_jsxs("div", Object.assign({ className: classes.displayIntro }, { children: [displayItems, introItems] }), void 0)), questionItems.length > 0 && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: classes.displayContainer }, { children: displayItems }), void 0), _jsx("div", Object.assign({ className: clsx('SurveyItem-displayQuestion', classes.displayQuestion, {
                                    [classes.choiceStandard]: hasChoiceStandard,
                                }) }, { children: questionItems }), void 0)] }, void 0)), sliderItems.length > 0 && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: classes.displayContainer }, { children: displayItems }), void 0), _jsx("div", Object.assign({ className: classes.displaySliderQuestion }, { children: sliderItems }), void 0)] }, void 0)), checklistItems.length > 0 && (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ className: classes.displayContainer }, { children: displayItems }), void 0), _jsx("div", Object.assign({ className: classes.displayChecklistQuestion }, { children: checklistItems }), void 0)] }, void 0)), manyQuestionsSamePage.length > 0 && (_jsx("div", Object.assign({ className: classes.manyQuestionsOnePage }, { children: manyQuestionsSamePage }), void 0))] }), void 0));
        }
        case SurveyItemTypes.display: {
            if (!isItemEnabled(answers, item, studySlug, bodySiteKey)) {
                return null;
            }
            return _jsx(SurveyItemDisplay, { item: item }, void 0);
        }
        case SurveyItemTypes.question: {
            if (!isItemEnabled(answers, item, studySlug, bodySiteKey)) {
                return null;
            }
            const questionAnswer = (_a = answers.find((a) => a.surveyQuestionId === item.id)) === null || _a === void 0 ? void 0 : _a.values;
            return (_jsx(SurveyItemQuestion, { item: item, answer: questionAnswer, onAnswer: onAnswer, defaultIcon: defaultIcon, checkedIcon: checkedIcon }, void 0));
        }
        default:
            throw new Error(
            // The item is now known to be unknown. Allow the `any`
            // eslint-disable-next-line
            `Unknown SurveyItem type ${item.type}.`);
    }
};
export default SurveyItem;
