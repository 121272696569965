import { useEffect } from 'react'
import { usePresence } from 'framer-motion'

/**
 * When a component redirects before it has been "registered" by
 * AnimatePresence, it's unmounting is then never seen by AnimatePresence,
 * causing the exiting animation to hang. We're pretty sure this is a bug.
 *
 * This happens when you navigate programmatically, via `useHistory`
 * or `<Redirect .. />` components.
 *
 * Issue: @see https://github.com/framer/motion/issues/466
 * Potential fix: @see https://github.com/framer/motion/pull/826
 */
const useRedirectCleanup = () => {
  const [isPresent, safeToRemove] = usePresence()
  useEffect(() => {
    !isPresent && safeToRemove && setTimeout(safeToRemove, 0)
  }, [isPresent, safeToRemove])
}

export default useRedirectCleanup
