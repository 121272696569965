import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export interface MedicalTreatment {
  id: string
  name: string // 'Tendinosis'
  slug: string
  onlyForStudy: boolean
}

interface MedicalTreatmentResponse {
  medicalTreatments: MedicalTreatment[]
}

const useMedicalTreatments = (
  extraOptions: UseQueryOptions<MedicalTreatment[]> = {},
) => {
  const siteId = useCurrentSite().id
  const key = QueryKeys.medicalTreatments()
  const url = `/sites/medical_treatments?site_ids[]=${siteId}`

  return useQuery(
    key,
    () =>
      request
        .get<MedicalTreatmentResponse>(url)
        .then((r) => r.data.medicalTreatments),
    {
      // MedicalTreatments are static. Don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useMedicalTreatments
