import { FC, useState } from 'react'
import {
  Box,
  Card,
  CardHeader,
  Chip,
  Grid,
  H3,
  makeStyles,
  PageTitle,
  Tab,
  // TablePagination,
  Tabs,
  Tooltip,
} from '@perk-ui/core'
import clsx from 'clsx'
import { BarChart2, CheckSquare, Info, Users } from 'react-feather'

// import {
//   NumberParam,
//   StringParam,
//   useQueryParam,
//   withDefault,
// } from 'use-query-params'
// import RemovableKnowledgeBaseBanner from '../../components/RemovableKnowledgeBaseBanner'
import { useSitesContext } from '../../components/SitesProvider'
// import TableHeaderAutocomplete from '../../components/TableHeaderAutocomplete'
import useHomeMetricStats from '../../features/query-hooks/useHomeMetricStats'
// import usePatientCDSearch from '../../features/query-hooks/usePatientCDSearch'
import useUpcomingDashboard from '../../features/query-hooks/useUpcomingDashboard'
// import PatientTableSearch from '../PatientsCD/components/PatientTableSearch'
import BodyPartPieChart from './components/BodyPartPieChart'
// import DashboardTable from './components/DashboardTable'
import TreatmentPieChart from './components/TreatmentPieChart'
import UpcomingTreatmentTable from './components/UpcomingTreatmentTable'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    [theme.breakpoints.down('md')]: {
      display: 'block',
    },
  },
  treatmentContainer: {
    width: '65%',
    minWidth: '65%',
    marginRight: theme.spacing(1.5),
    [theme.breakpoints.down('md')]: {
      width: 'auto',
      minWidth: 'auto',
    },
  },
  content: {
    width: '100%',
  },
  bodyPartGraphContainer: {
    padding: theme.spacing(2),
    // gridColumn: '1/3',
    // gridRow: '2/4',
  },

  metricContainer: {
    // backgroundColor: '#FFFFFF',
    // borderRadius: '16px',
    display: 'flex',
    height: '100%',
    padding: theme.spacing(2),
    justifyContent: 'space-between',
    border: '1px solid transparent',
    borderRadius: '16px',
  },
  graphMetricContainer: {
    borderColor: '#DEDEDE99',
  },
  dataContainer: {
    padding: theme.spacing(2),
    borderRadius: '16px',
    '& h3': {
      paddingBottom: theme.spacing(2),
    },
  },
  metrixBox: {
    '& .MuiCardHeader-avatar': {
      '& svg': {
        color: '#2396CF',
      },
    },
    '& .MuiCardHeader-title': {
      color: '#545454',
      fontSize: '12px',
      fontWeight: 400,
      lineHeight: '20px',
    },
    '& .MuiCardHeader-subheader': {
      color: '#000000',
      fontSize: '24px',
      fontWeight: 700,
      lineHeight: '20px',
      display: 'flex',
      alignItems: 'center',
      paddingTop: theme.spacing(0.5),
    },
  },

  tabContainer: {
    paddingBottom: theme.spacing(2),
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  tabItem: {
    fontSize: '16px',
    fontWeight: 400,
    borderRadius: '8px',
    padding: theme.spacing(1, 2),
    minHeight: '40px',
    marginRight: theme.spacing(1.5),
    '&.Mui-selected': {
      backgroundColor: '#EAF2F5',
      '& .MuiChip-root': {
        backgroundColor: '#2E8099',
        color: '#FFFFFF',
      },
    },
    '& .MuiChip-root': {
      marginLeft: theme.spacing(0.5),
    },
  },
  metricValueSpan: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing(0.5),
    },
  },
  leftBorder: {
    borderLeft: '1px solid #F1F1F1',
    paddingLeft: theme.spacing(4),
  },
  tabContentData: {},
  filterContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  patientSearchField: {
    paddingLeft: 0,
    width: theme.spacing(30),
    '& .MuiInput-input': {
      padding: theme.spacing(0.5, 3),
      minHeight: '40px',
    },
  },
}))

export interface HomeProps {}

const Home: FC<HomeProps> = () => {
  const classes = useStyles()
  const sitesContext = useSitesContext()
  const { data: homeMetricStats } = useHomeMetricStats()
  const {
    data: upcomingPatientCases = [],
    isLoading: isUpcomingDashboardLoading,
  } = useUpcomingDashboard()
  // const perPage = 10
  // const [searchValue, setSearchValue] = useQueryParam(
  //   'q',
  //   withDefault(StringParam, ''),
  // )
  // const [surveyStatuses, setSurveyStatuses] = useQueryParam(
  //   'surveyStatuses',
  //   withDefault(StringParam, ''),
  // )
  // const [surveyPeriods, setSurveyPeriods] = useQueryParam(
  //   'surveyPeriods',
  //   withDefault(StringParam, ''),
  // )
  // const [showFilterToggle, setShowFilterToggle] = useQueryParam(
  //   'filterToggle',
  //   withDefault(StringParam, ''),
  // )
  // const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0))

  // const { data: searchResult, isLoading, isFetching } = usePatientCDSearch({
  //   search: searchValue,
  //   surveyStatuses: surveyStatuses,
  //   surveyPeriods: surveyPeriods,
  //   filterToggle: showFilterToggle,
  //   dashboardToggle: true,
  //   page: page + 1,
  //   perPage,
  // })
  // const count = searchResult?.meta.count || 0
  // const tableData = searchResult?.patients || []
  // const surveyPeriodsOptions = searchResult?.meta.surveyPeriods || []
  // const missingTreatmentsCount = searchResult?.meta.missingTreatmentsCount || 0
  // const missingBaselineSurveysCount =
  //   searchResult?.meta.missingBaselineSurveysCount || 0
  // const missingFollowupSurveysCount =
  //   searchResult?.meta.missingFollowupSurveysCount || 0
  const upcomingTreatmentsCount = upcomingPatientCases.length || 0

  // const handleChangePage = (
  //   _event: MouseEvent<HTMLButtonElement> | null,
  //   newPage: number,
  // ) => {
  //   setPage(newPage)
  // }

  // const handleSetSearchValue = (val: string) => {
  //   setSearchValue(val)
  //   setPage(0)
  // }

  // const handleSetSurveyPeriodsValue = (val: string) => {
  //   setSurveyPeriods(val)
  //   setPage(0)
  // }
  const site = sitesContext.currentSite
  // const tabContent = (value: number) => {
  //   switch (value) {
  //     case 0:
  //       if (surveyStatuses != 'expiring_soon')
  //         setSurveyStatuses('expiring_soon')
  //       if (surveyPeriods != '') setSurveyPeriods('')
  //       if (searchValue != '') setSearchValue('')
  //       if (showFilterToggle != 'baselinesurveys')
  //         setShowFilterToggle('baselinesurveys')
  //       return (
  //         <Box>
  //           <DashboardTable
  //             data={tableData}
  //             type={'baseline'}
  //             isLoading={isFetching || isLoading}
  //             pagination={
  //               tableData.length > 0 && (
  //                 <TablePagination
  //                   rowsPerPageOptions={[]}
  //                   component="div"
  //                   count={count}
  //                   rowsPerPage={perPage}
  //                   page={page}
  //                   onPageChange={handleChangePage}
  //                 />
  //               )
  //             }
  //           />
  //         </Box>
  //       )
  //     case 1:
  //       if (surveyStatuses != 'expiring_soon')
  //         setSurveyStatuses('expiring_soon')
  //       if (showFilterToggle != 'followup') setShowFilterToggle('followup')
  //       return (
  //         <Box>
  //           {tableData.length > 0 && (
  //             <Box className={classes.filterContainer}>
  //               <PatientTableSearch
  //                 setSearchValue={handleSetSearchValue}
  //                 searchValue={searchValue}
  //                 customClassName={classes.patientSearchField}
  //                 customPlaceholder="Search Follow Ups"
  //               />
  //               <Box>
  //                 <TableHeaderAutocomplete
  //                   options={surveyPeriodsOptions}
  //                   onChange={(data) => {
  //                     const filterResult = data.map(
  //                       (study: { slug: string }) => study.slug,
  //                     )
  //                     handleSetSurveyPeriodsValue(filterResult.toString())
  //                   }}
  //                   value={
  //                     surveyPeriodsOptions?.filter((status) =>
  //                       surveyPeriods
  //                         .split(',')
  //                         ?.some((stat) => status.slug === stat),
  //                     ) ?? undefined
  //                   }
  //                   placeholder="Follow Up Type"
  //                   styleProps={{
  //                     width: '220px',
  //                   }}
  //                 />
  //               </Box>
  //             </Box>
  //           )}
  //           <DashboardTable
  //             data={tableData}
  //             type={'followup'}
  //             isLoading={isFetching || isLoading}
  //             pagination={
  //               tableData.length > 0 && (
  //                 <TablePagination
  //                   rowsPerPageOptions={[]}
  //                   component="div"
  //                   count={count}
  //                   rowsPerPage={perPage}
  //                   page={page}
  //                   onPageChange={handleChangePage}
  //                 />
  //               )
  //             }
  //           />
  //         </Box>
  //       )
  //     case 2:
  //       if (surveyStatuses != '') setSurveyStatuses('')
  //       if (surveyPeriods != '') setSurveyPeriods('')
  //       if (searchValue != '') setSearchValue('')
  //       if (showFilterToggle != 'treatments') setShowFilterToggle('treatments')
  //       return (
  //         <Box>
  //           <DashboardTable
  //             data={tableData}
  //             type={'treatment'}
  //             isLoading={isFetching || isLoading}
  //             pagination={
  //               tableData.length > 0 && (
  //                 <TablePagination
  //                   rowsPerPageOptions={[]}
  //                   component="div"
  //                   count={count}
  //                   rowsPerPage={perPage}
  //                   page={page}
  //                   onPageChange={handleChangePage}
  //                 />
  //               )
  //             }
  //           />
  //         </Box>
  //       )
  //     case 3:
  //       return (
  //         <UpcomingTreatmentTable
  //           isLoading={isUpcomingDashboardLoading}
  //           patientCases={upcomingPatientCases}
  //         />
  //       )
  //     default:
  //       return <></>
  //   }
  // }

  const numericMetricsArray = [
    {
      key: 1,
      label: 'Patients',
      value: homeMetricStats?.patients || 0,
      icon: <Users size={24} />,
    },
    {
      key: 2,
      label: 'Surveys',
      value: homeMetricStats?.surveys || 0,
      icon: <CheckSquare size={24} />,
    },
    {
      key: 3,
      label: 'Satisfaction',
      value: homeMetricStats?.satisfactionPercent,
      icon: <BarChart2 size={24} />,
      isPercent: true,
      tooltip:
        'Percent of patients who responded Satisfied, Very Satisfied or Extremely Satisfied to the Satisfaction Survey (at 6, 12 and 24 months)',
    },
    {
      key: 4,
      label: 'Baseline Completion',
      value: homeMetricStats?.baselinePercent,
      isPercent: true,
      tooltip: 'Percent of baseline surveys completed in your clinic',
      // icon: <Users/>,
    },
    {
      key: 5,
      label: 'Follow Up Completion',
      value: homeMetricStats?.followupPercent,
      isPercent: true,
      tooltip:
        'Percent of follow-up surveys completed in your clinic (excluding baseline surveys and archived cases)',
      // icon: <Users/>,
    },
  ]

  const [value, setValue] = useState(0)

  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue)
  }

  const HomeContent = () => {
    if (!site) return null

    return (
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Card className={clsx(classes.metricContainer)}>
            {numericMetricsArray.map((metric) => {
              return (
                <CardHeader
                  key={metric.key}
                  className={clsx(classes.metrixBox, {
                    [classes.leftBorder]: metric.key === 4,
                  })}
                  avatar={metric.icon}
                  title={metric.label}
                  subheader={
                    <span className={classes.metricValueSpan}>
                      <Tooltip
                        arrow
                        title={
                          metric.key === 3 && !metric.value
                            ? "You still don't have enough satisfaction surveys completed to show this metric"
                            : ''
                        }
                      >
                        <span>
                          {metric.value === null
                            ? '---'
                            : metric.value?.toString()}
                          {metric.isPercent && metric.value !== null ? '%' : ''}
                        </span>
                      </Tooltip>
                      {metric.tooltip && (
                        <Tooltip arrow title={metric.tooltip}>
                          <Info size={18} />
                        </Tooltip>
                      )}
                    </span>
                  }
                />
              )
            })}
          </Card>
        </Grid>
        <Grid item xs={12} md={4}>
          <Card
            className={clsx(
              classes.metricContainer,
              classes.graphMetricContainer,
            )}
          >
            <Grid container alignItems={'center'}>
              <Grid item xs={6}>
                <CardHeader
                  className={classes.metrixBox}
                  title={'Total Body Parts'}
                  subheader={<BodyPartPieChart />}
                />
              </Grid>
              <Grid item xs={6}>
                <CardHeader
                  className={classes.metrixBox}
                  title={'Total Treatments'}
                  subheader={<TreatmentPieChart />}
                />
              </Grid>
            </Grid>
          </Card>
        </Grid>
        <Grid item xs={12}>
          <Card className={classes.dataContainer}>
            <H3>Scheduled Activity</H3>
            <Box>
              <Tabs
                className={classes.tabContainer}
                value={value}
                onChange={handleChange}
              >
                {/* <Tab
                  className={classes.tabItem}
                  label={
                    <span>
                      Expiring Baseline Surveys{' '}
                      <Chip label={missingBaselineSurveysCount} size="small" />
                    </span>
                  }
                />
                <Tab
                  className={classes.tabItem}
                  label={
                    <span>
                      Expiring Follow Up Surveys{' '}
                      <Chip label={missingFollowupSurveysCount} size="small" />
                    </span>
                  }
                />
                <Tab
                  className={classes.tabItem}
                  label={
                    <span>
                      Incomplete Info{' '}
                      <Chip label={missingTreatmentsCount} size="small" />
                    </span>
                  }
                /> */}
                <Tab
                  className={classes.tabItem}
                  label={
                    <span>
                      Upcoming Treatments{' '}
                      <Chip label={upcomingTreatmentsCount} size="small" />
                    </span>
                  }
                />
              </Tabs>
            </Box>
            <Box className={classes.tabContentData}>
              <UpcomingTreatmentTable
                isLoading={isUpcomingDashboardLoading}
                patientCases={upcomingPatientCases}
              />
            </Box>
          </Card>
        </Grid>
      </Grid>
    )
  }

  return (
    <Box p={3}>
      <PageTitle title="Home" />
      {/* <H1>{site ? site.name : ' '}</H1> */}
      {/* <RemovableKnowledgeBaseBanner /> */}
      {sitesContext.currentSite && <HomeContent />}
    </Box>
  )
}

export default Home
