import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { ProtocolDiagnosis } from './useProtocols'

interface UpdateDiagnosisResponse {
  protocolDiagnosis: ProtocolDiagnosis
}

export interface UpdateDiagnosisVars extends ProtocolDiagnosis {
  protocolId: string
}

const useUpdateProtocolDiagnosis = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation(({ protocolId, ...vars }: UpdateDiagnosisVars) => {
    const url = `/sites/protocol_diagnoses/${vars.id}`

    return request
      .patch<UpdateDiagnosisResponse>(url, {
        siteIds: [siteId],
        protocolDiagnosis: {
          protocolId: protocolId,
          ...vars,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.protocols({ clinicId: siteId }))
        queryClient.invalidateQueries(QueryKeys.protocol(protocolId || ''))

        return r.data.protocolDiagnosis
      })
  })
}

export default useUpdateProtocolDiagnosis
