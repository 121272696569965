import { FC } from 'react'
import { Link as CoreLink, LinkProps as CoreLinkProps } from '@perk-ui/core'
import { Link as RouterLink, LinkProps } from 'react-router-dom'

// related but not necessary: https://github.com/mui-org/material-ui/issues/16846
export type AppLinkProps = Omit<CoreLinkProps, 'component'> & LinkProps

/**
 * A Link integrated with ReactRouter behavior for in-app navigation.
 */
const AppLink: FC<AppLinkProps> = ({ children, to, ...rest }) => (
  <CoreLink component={RouterLink} to={to} {...rest}>
    {children}
  </CoreLink>
)

export default AppLink
