var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Alert as MuiAlert } from '@mui/material';
import clsx from 'clsx';
import { makeStyles } from '../';
const useStyles = makeStyles((theme) => ({
    root: {
        minWidth: theme.spacing(3),
    },
    fade: {
        animation: 'alertFadeIn 350ms',
    },
    grow: {
        animation: 'alertGrow 350ms',
    },
    fadeAndGrow: {
        animation: 'alertFadeIn 350ms, alertGrow 350ms',
    },
    '@keyframes alertFadeIn': {
        '0%': { opacity: 0 },
        '100%': { opacity: 1 },
    },
    '@keyframes alertGrow': {
        '0%': { transform: 'scaleY(0%)' },
        '100%': { transform: 'scaleY(100%)' },
    },
}));
const Alert = (_a) => {
    var { fade = false, grow = false, className: classNameProp, children } = _a, rest = __rest(_a, ["fade", "grow", "className", "children"]);
    const classes = useStyles();
    const className = clsx(classNameProp, classes.root, {
        [classes.fade]: fade,
        [classes.grow]: grow,
        [classes.fadeAndGrow]: fade && grow,
    });
    return (_jsx(MuiAlert, Object.assign({ className: className }, rest, { children: children }), void 0));
};
export default Alert;
