import { FC, useRef, useState } from 'react'
import { Chip, makeStyles, Popover, Tooltip } from '@perk-ui/core'
import {
  AlertCircle,
  Archive,
  ChevronRight,
  Clock,
  ThumbsUp,
} from 'react-feather'

import { Patient } from '../../../features/query-hooks/usePatient'
import SurveyCompletionCD from './SurveyCompletionCD'

export interface SurveyStatusChip {
  status: string | undefined
  patientId: string
  patientName: string
  patientCaseId: string
  bodyPart?: string
  tooltip?: string
  patient: Patient
}

const useStyles = makeStyles((theme) => ({
  popoverContainer: {
    backgroundColor: '#32323233',
  },
  surveyStatusChip: {
    color: '#000',
    height: '28px',
    padding: theme.spacing(1),
    borderColor: 'transparent',
    backgroundColor: 'transparent',
    paddingLeft: 0,
    '& .MuiChip-icon': {
      color: theme.palette.common.white,
      borderRadius: '25px',
      display: 'flex',
      margin: 0,
      padding: '7px',
    },
    '&.archived': {
      color: '#6B6B6B',
      // backgroundColor: '#F1F1F180',
      '&:hover': {
        backgroundColor: '#F1F1F180',
      },
      '& .MuiChip-icon': {
        color: '#A3A3A3',
        backgroundColor: '#F1F1F180',
      },
      '& .MuiChip-deleteIcon': {
        color: '#A3A3A3',
      },
    },
    '&.open': {
      '&.MuiChip-clickable': {
        // backgroundColor: '#FEF5ED',
        '&:hover': {
          backgroundColor: '#FEF5ED',
          borderColor: '#F79A48',
        },
        '&.active': {
          backgroundColor: '#FEF5ED',
          borderColor: '#F79A48',
        },
      },
      '& .MuiChip-icon': {
        color: '#F79A48',
        backgroundColor: '#FEF5ED',
      },
      '& .MuiChip-deleteIcon': {
        color: '#F79A48',
      },
    },
    '&.expiring-soon': {
      '&.MuiChip-clickable': {
        color: '#C00202',
        '&:hover': {
          backgroundColor: '#F9E6E6',
          borderColor: '#C00202',
        },
        '&.active': {
          backgroundColor: '#F9E6E6',
          borderColor: '#C00202',
        },
      },
      '& .MuiChip-icon': {
        color: '#C00202',
        backgroundColor: '#F9E6E6',
      },
      '& .MuiChip-deleteIcon': {
        color: '#C00202',
      },
    },
    '&.none-pending': {
      '&.MuiChip-clickable': {
        // backgroundColor: '#EAF2F5',
        '&:hover': {
          backgroundColor: '#EAF2F5',
          borderColor: theme.palette.primary.main,
        },
        '&.active': {
          backgroundColor: '#EAF2F5',
          borderColor: theme.palette.primary.main,
        },
      },
      '& .MuiChip-icon': {
        color: theme.palette.primary.main,
        backgroundColor: '#EAF2F5',
      },
      '& .MuiChip-deleteIcon': {
        color: theme.palette.primary.main,
      },
    },
  },
}))

const SurveyStatusChip: FC<SurveyStatusChip> = ({
  status,
  patientId,
  patientName,
  patientCaseId,
  bodyPart,
  tooltip,
  patient,
}) => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [popoverOrigin, setPopoverOrigin] = useState<'top' | 'bottom'>('bottom')
  const open = Boolean(anchorEl)
  const id = open ? `${[patientCaseId]}-popover` : undefined
  let icon = undefined
  let text = ''
  let colorClassName = ''

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const chipOriginTop = myRef?.current?.getBoundingClientRect().top || 0
    const windowHeight = window.innerHeight
    if (chipOriginTop > windowHeight / 2) {
      if (popoverOrigin !== 'top') setPopoverOrigin('top')
    } else {
      if (popoverOrigin !== 'bottom') setPopoverOrigin('bottom')
    }
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }
  const myRef = useRef<HTMLDivElement>(null)

  switch (status) {
    case 'archived':
      icon = (
        <span>
          <Archive size={12} />
        </span>
      )
      text = 'Archived'
      colorClassName = 'archived'
      break
    case 'expiring_soon':
      icon = (
        <span>
          <AlertCircle size={12} />
        </span>
      )
      text = 'Expiring Soon'
      colorClassName = 'expiring-soon'
      break
    case 'open':
      icon = (
        <span>
          <Clock size={12} />
        </span>
      )
      text = 'Survey Open'
      colorClassName = 'open'
      break
    case 'none_pending':
      icon = (
        <span>
          <ThumbsUp size={12} />
        </span>
      )
      text = 'None Pending'
      colorClassName = 'none-pending'
      break
    default:
      break
  }

  let chipClassName = `${classes.surveyStatusChip} ${colorClassName}`
  if (open) {
    chipClassName += ' active'
  }

  return (
    <>
      <Tooltip title={tooltip ? tooltip : ''} placement="top" arrow>
        <Chip
          ref={myRef}
          size="small"
          icon={icon}
          label={text}
          variant="outlined"
          className={chipClassName}
          onDelete={() => {}}
          deleteIcon={<ChevronRight size={12} />}
          onClick={status === 'archived' ? undefined : handleClick}
        />
      </Tooltip>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        className={classes.popoverContainer}
        transformOrigin={{
          horizontal: 'right',
          vertical: 'center',
        }}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'center',
        }}
        PaperProps={{
          // elevation: 0,
          sx: {
            minHeight: status === 'none_pending' ? '300px' : '350px',
            // overflow: 'visible',
            // filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            // mt: 1.5,
            // '& .MuiAvatar-root': {
            //   width: 32,
            //   height: 32,
            //   ml: -0.5,
            //   mr: 1,
            // },
            // '&:before': {
            //   content: '""',
            //   display: 'block',
            //   position: 'absolute',
            //   top: '50%',
            //   right: '-5px',
            //   width: 10,
            //   height: 10,
            //   bgcolor: 'background.paper',
            //   transform: 'translateY(-50%) rotate(45deg)',
            //   zIndex: 0,
            // },
          },
        }}
      >
        <SurveyCompletionCD
          patientId={patientId}
          patientName={patientName}
          patient={patient}
          patientCaseId={patientCaseId}
          currentStatus={status}
          bodyPart={bodyPart}
          onClose={handleClose}
        />
      </Popover>
    </>
  )
}

export default SurveyStatusChip
