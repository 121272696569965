import { FC, useState } from 'react'
import {
  Alert,
  Body1,
  Button,
  H3,
  InputLabel,
  Link,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@perk-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useUser } from '../../../../features/auth/AuthContext'
import {
  AdverseEvent,
  ExpectednessLabelMap,
  RelatednessLabelMap,
  SeriousnessLabelMap,
} from '../../../../features/query-hooks/useAdverseEvents'
import useUpdateAdverseEvent from '../../../../features/query-hooks/useUpdateAdverseEvent'
import AdverseEventDetails from './AdverseEventDetails'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: theme.spacing(2),
    paddingRight: theme.spacing(3),

    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
  },
  editButton: {
    paddingRight: theme.spacing(3),
  },
  ClinicStaffAlert: {
    marginRight: theme.spacing(3),
  },
}))

interface AdjudicationFormValues {
  seriousness: AdverseEvent['seriousness']
  expectedness: AdverseEvent['expectedness']
  relatedness: AdverseEvent['relatedness']
  providerNotes: string
}

const validationSchema = yup.object({
  seriousness: yup
    .string()
    .oneOf(Object.keys(SeriousnessLabelMap))
    .defined('Seriousness must be selected'),
  expectedness: yup
    .string()
    .oneOf(Object.keys(ExpectednessLabelMap))
    .defined('Expectedness must be selected'),
  relatedness: yup
    .string()
    .oneOf(Object.keys(RelatednessLabelMap))
    .defined('Relatedness must be selected'),
  providerNotes: yup.string(),
})

export interface AdjudicationCardProps {
  adverseEvent: AdverseEvent
}

const AdjudicationCard: FC<AdjudicationCardProps> = ({ adverseEvent }) => {
  const classes = useStyles()
  const user = useUser()
  const [isEditing, setIsEditing] = useState(
    adverseEvent.status !== 'adjudicated',
  )
  const updateAdverseEvent = useUpdateAdverseEvent()
  const isClinicStaff = user.staff.type === 'ClinicStaff'
  const canEdit = adverseEvent.status === 'adjudicated'

  const {
    errors,
    touched,
    values,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = useFormik<AdjudicationFormValues>({
    validationSchema,
    initialValues: {
      seriousness: '' as AdverseEvent['seriousness'],
      expectedness: '' as AdverseEvent['expectedness'],
      relatedness: '' as AdverseEvent['relatedness'],
      providerNotes: '',
    },
    onSubmit(values) {
      return updateAdverseEvent
        .mutateAsync({
          id: adverseEvent.id,
          status: 'adjudicated',
          ...values,
        })
        .then(() => {
          setIsEditing(false)
        })
    },
  })

  return (
    <>
      <div className={classes.header}>
        <H3>Adverse Event</H3>
        {canEdit && (
          <Link
            className={classes.editButton}
            underline="hover"
            component="button"
            onClick={() => setIsEditing(!isEditing)}
          >
            <Body1>{isEditing ? 'Cancel' : 'Edit'}</Body1>
          </Link>
        )}
      </div>
      <AdverseEventDetails
        adverseEvent={adverseEvent}
        withAdjudication={!isEditing}
      />
      {isEditing ? (
        isClinicStaff ? (
          <Alert className={classes.ClinicStaffAlert} severity="info">
            You must be a Provider to adjudicate Adverse Events
          </Alert>
        ) : (
          <form className={classes.form} onSubmit={handleSubmit}>
            <InputLabel id="seriousness">Seriousness</InputLabel>
            <Select
              labelId="seriousness"
              required
              value={values.seriousness}
              onChange={(event) => {
                const val = event.target.value
                setFieldValue('seriousness', val)
              }}
            >
              {Object.entries(SeriousnessLabelMap).map(([key, val]) => (
                <MenuItem key={key} value={key}>
                  {val}
                </MenuItem>
              ))}
            </Select>

            <InputLabel id="expectedness">Expectedness</InputLabel>
            <Select
              labelId="expectedness"
              required
              value={values.expectedness}
              onChange={(event) => {
                const val = event.target.value
                setFieldValue('expectedness', val)
              }}
            >
              {Object.entries(ExpectednessLabelMap).map(([key, val]) => (
                <MenuItem key={key} value={key}>
                  {val}
                </MenuItem>
              ))}
            </Select>

            <InputLabel id="relatedness">Relatedness</InputLabel>
            <Select
              labelId="relatedness"
              required
              value={values.relatedness}
              onChange={(event) => {
                const val = event.target.value
                setFieldValue('relatedness', val)
              }}
            >
              {Object.entries(RelatednessLabelMap).map(([key, val]) => (
                <MenuItem key={key} value={key}>
                  {val}
                </MenuItem>
              ))}
            </Select>
            <TextField
              label="Notes"
              name="providerNotes"
              placeholder="Additional notes"
              fullWidth
              value={values.providerNotes}
              onChange={handleChange}
              onBlur={handleBlur}
              error={touched.providerNotes && Boolean(errors.providerNotes)}
              helperText={touched.providerNotes && errors.providerNotes}
            />
            <Button type="submit" fullWidth loading={isSubmitting}>
              Done
            </Button>
          </form>
        )
      ) : null}
    </>
  )
}

export default AdjudicationCard
