import { FC } from 'react'
import { ApmRoute } from '@elastic/apm-rum-react'
import { RouteProps, useLocation } from 'react-router-dom'

import config from '../config/app-config'
import AnimatedRedirect from '../features/animation/AnimatedRedirect'
import { useAuth } from '../features/auth/AuthContext'

export type PrivateRouteProps = RouteProps

/**
 * A Route which requires the user to be authenticated before mounting the Route's children.
 *
 * If the user is not authenticated, redirects back to `/login` with info about what the user
 * was trying to access.
 */
const PrivateRoute: FC<PrivateRouteProps> = (props) => {
  const { pathname, search } = useLocation()
  const { isAuthenticated, isLoading } = useAuth()

  if (isLoading) return null

  if (isAuthenticated && search.includes('redirectTo')) {
    const params = new URLSearchParams(search)
    return <AnimatedRedirect to={params.get('redirectTo') || ''} />
  }

  return !isAuthenticated ? (
    <AnimatedRedirect
      to={{
        pathname: config.unAuthenticatedRootUrl,
        state: { from: pathname, isAuthRedirect: true },
        search,
      }}
    />
  ) : (
    <ApmRoute {...props} />
  )
}

export default PrivateRoute
