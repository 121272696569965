import { tail } from '@perk-ui/core'
import { isValid, parse } from 'date-fns'

/**
 * Parses a YYYY-MM-DD date like ones that Rails generates.
 * Returns a Date object at the 0th minute of the day, in the
 * users local timezone.
 *
 * @explanation
 * Dates in this format do not have a timezone so `new Date(x)`
 * parses it as having a GMT-timezone. This causes problems as JS
 * then turns that date into a local timezone, potentially displaying the
 * day incorrectly.
 * i.e. 2021-06-08 is parsed as 2021-06-08T00:00:00.000Z
 * Which, when rendered locally before 8PM EST, is shown as June 7th (not 8th).
 */
export const parseYMD = (date: string | Date) =>
  typeof date === 'string' ? parse(date, 'yyyy-MM-dd', new Date()) : date

export const parseMDY = (value: string) => {
  const asDate = parse(value, 'mm/dd/yyyy', new Date())
  const has4LetterYear = tail(value.split('/')).length === 4
  return isValid(asDate) && has4LetterYear && asDate
}

export const parseDMY = (value: string) => {
  const asDate = parse(value, 'dd/mm/yyyy', new Date())
  const has4LetterYear = tail(value.split('/')).length === 4
  return isValid(asDate) && has4LetterYear && asDate
}
