/* eslint-disable @typescript-eslint/no-explicit-any */
import { FC, useEffect, useState } from 'react'
import {
  Box,
  Button,
  Checkbox,
  InputAdornment,
  makeStyles,
  Menu,
  MenuItem,
  TextField,
} from '@perk-ui/core'
import { matchSorter } from 'match-sorter'
import { ChevronDown, ChevronUp, Search } from 'react-feather'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  filterButton: (props: StyleProps) => ({
    width: props.width ? props.width : 'auto',
    justifyContent: 'space-between',
    borderRadius: '4px',
    color: '#000',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: '400',
    padding: '8px 14px',
    border: '1px solid #C0C0C0',
    boxShadow: 'none',
    '&:hover': {
      border: '1px solid #323232',
    },
  }),
  activefilterButton: {
    border: '1px solid #56AAEB',
  },
  menuContainer: (props: StyleProps) => ({
    '& .MuiPaper-root.MuiMenu-paper': {
      borderRadius: '4px',
      boxShadow: '0 4px 8px 0 #0000001A',
    },
    '& ul': {
      width: props.width ? props.width : 'auto',
    },
  }),
  filterHeaderContainer: {
    padding: theme.spacing(2, 2, 0, 2),
    display: 'inline-flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  filterHeaderText: {
    fontSize: '16px',
    alignSelf: 'center',
  },
  clearFilterButton: {
    fontSize: '14px',
    fontWeight: '400',
    lineHeight: '16px',
    minHeight: '20px',
    padding: '4px 5px',
    borderRadius: '4px',
  },
  searchField: {
    width: '100%',
    padding: theme.spacing(1, 2),
    '& .MuiInput-root': {
      borderRadius: '4px',
      border: '1px solid #F1F1F1',
      '&:hover': {
        border: '1px solid #323232',
      },
    },
    '& input': {
      padding: theme.spacing(1, 1, 1, 0),
      fontSize: '14px',
    },
    '& .MuiInputBase-sizeSmall': {
      height: '40px',
    },
  },
  searchIcon: {
    marginLeft: theme.spacing(1),
  },
  listContainer: {
    maxHeight: '150px',
    overflow: 'auto',
  },
  menuItem: {
    fontSize: '16px',
    padding: '6px 16px',
    lineHeight: '24px',
    whiteSpace: 'break-spaces',
  },
  actionContainer: {
    padding: theme.spacing(1, 2),
    '& button': {
      width: 'calc(50% - 8px)',
      borderRadius: '4px',
      border: '1px solid transparent',
      boxShadow: 'none',
      margin: '4px',
      padding: theme.spacing(0.5, 1),
      fontSize: '12px',
      '&.cancel-action': {
        borderColor: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: '#73AABB26',
          border: `1px solid ${theme.palette.primary.main}`,
        },
      },
      '&.apply-action': {
        borderColor: theme.palette.primary.main,
      },
    },
  },
}))

export interface TableHeaderAutocompleteProps {
  options: any[]
  placeholder: string
  value: any[]
  onChange: (data: any) => void
  optionsType?: string
  styleProps: StyleProps
}

interface StyleProps {
  width?: number | string
}

const TableHeaderAutocomplete: FC<TableHeaderAutocompleteProps> = ({
  options,
  placeholder,
  value,
  optionsType,
  onChange,
  styleProps,
}) => {
  const classes = useStyles(styleProps)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [selectedOptions, setSelectedOptions] = useState<Array<any>>(value)
  const [selectOptions, setSelectOptions] = useState<Array<any>>(options)
  const [filterText, setFilterText] = useState<string>('')

  useEffect(() => {
    setSelectedOptions(value)
  }, [value])

  useEffect(() => {
    if (filterText == '') {
      setSelectOptions(options)
    } else {
      const filteredOptions = matchSorter(options, filterText, {
        keys:
          optionsType === 'string'
            ? undefined
            : optionsType === 'alt'
            ? [{ threshold: matchSorter.rankings.CONTAINS, key: 'first_name' }]
            : [{ threshold: matchSorter.rankings.CONTAINS, key: 'name' }],
      })
      setSelectOptions(filteredOptions)
    }
  }, [options, filterText])

  const openFilter = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
    setFilterText('')
  }
  const filterOptionsHandler = (
    event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
  ) => {
    const inputValue = event.target.value
    setFilterText(inputValue)
  }

  const handleMenuItemClick = (
    event: React.MouseEvent<HTMLElement>,
    id: string,
  ) => {
    const isSelected = selectedOptions.find((opt: any) =>
      optionsType === 'string' ? opt === id : opt.id === id,
    )
    if (isSelected) {
      setSelectedOptions((current) =>
        current.filter((opt) =>
          optionsType === 'string' ? opt !== id : opt.id !== id,
        ),
      )
    } else {
      setSelectedOptions((oldArray) => [
        ...oldArray,
        options.find((opt) =>
          optionsType === 'string' ? opt === id : opt.id === id,
        ),
      ])
    }
  }

  const cancelHandler = () => {
    setSelectedOptions(value)
    setFilterText('')
  }

  const applyHandler = () => {
    onChange(selectedOptions)
  }

  const handleResetFilter = () => {
    setFilterText('')
    // setSelectedOptions([])
    onChange([])
  }

  return (
    <Box className={classes.root}>
      <div>
        <Button
          className={
            openFilter
              ? `${classes.filterButton} ${classes.activefilterButton}`
              : classes.filterButton
          }
          onClick={handleClick}
          endIcon={
            openFilter ? <ChevronUp size={14} /> : <ChevronDown size={14} />
          }
          size={'small'}
          variant={'outlined'}
        >
          {placeholder}
        </Button>
        <Menu
          id="basic-menu"
          anchorEl={anchorEl}
          open={openFilter}
          onClose={handleClose}
          MenuListProps={{
            'aria-labelledby': 'fade-button',
          }}
          className={classes.menuContainer}
        >
          {/* This element is to avoid focus on "Filter" text when typing f in searchbar  */}
          <div></div>
          <div className={classes.filterHeaderContainer}>
            <div className={classes.filterHeaderText}>Filter</div>
            <Button
              variant={'text'}
              onClick={handleResetFilter}
              className={classes.clearFilterButton}
            >
              Clear Filters
            </Button>
          </div>
          <TextField
            className={classes.searchField}
            autoFocus
            size="small"
            placeholder="Search"
            onChange={filterOptionsHandler}
            value={filterText}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={classes.searchIcon} size={16} />
                </InputAdornment>
              ),
            }}
          />
          <Box className={classes.listContainer}>
            {selectOptions.length > 1 && (
              <MenuItem
                onClick={() => {
                  if (selectedOptions.length == selectOptions.length) {
                    setSelectedOptions([])
                  } else {
                    setSelectedOptions(selectOptions)
                  }
                }}
                selected={selectedOptions.length == selectOptions.length}
                className={classes.menuItem}
              >
                <Checkbox
                  inputProps={{ 'aria-label': 'controlled' }}
                  // icon={<Square size={16} />}
                  size="small"
                  // checkedIcon={<CheckSquare size={16} />}
                  style={{ margin: 8 }}
                  checked={selectedOptions.length == selectOptions.length}
                />
                All
              </MenuItem>
            )}
            {selectOptions.length > 0 &&
              selectOptions.map((option) => {
                return (
                  <MenuItem
                    key={option.id}
                    onClick={(event) =>
                      handleMenuItemClick(
                        event,
                        optionsType === 'string' ? option : option.id,
                      )
                    }
                    selected={
                      selectedOptions.find((selOpt) =>
                        optionsType === 'string'
                          ? selOpt === option
                          : optionsType === 'alt'
                          ? selOpt.id == option.id
                          : selOpt.slug == option.slug,
                      )
                        ? true
                        : false
                    }
                    className={classes.menuItem}
                  >
                    <Checkbox
                      inputProps={{ 'aria-label': 'controlled' }}
                      // icon={<Square size={16} />}
                      size="small"
                      // checkedIcon={<CheckSquare size={16} />}
                      style={{ margin: 8 }}
                      checked={
                        selectedOptions.find((selOpt) =>
                          optionsType === 'string'
                            ? selOpt === option
                            : optionsType === 'alt'
                            ? selOpt.id == option.id
                            : selOpt.slug == option.slug,
                        )
                          ? true
                          : false
                      }
                    />
                    {optionsType === 'string'
                      ? option
                      : optionsType === 'alt'
                      ? `${option.firstName} ${option.lastName}`
                      : option.shortName
                      ? option.shortName
                      : option.name}
                  </MenuItem>
                )
              })}
          </Box>
          <div className={classes.actionContainer}>
            <Button
              className="cancel-action"
              variant="outlined"
              size={'small'}
              onClick={cancelHandler}
            >
              Cancel
            </Button>
            <Button
              className="apply-action"
              size={'small'}
              onClick={applyHandler}
            >
              Apply Filter
            </Button>
          </div>
        </Menu>
      </div>
    </Box>
  )
}

export default TableHeaderAutocomplete
