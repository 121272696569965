import { FC, useState } from 'react'
import {
  Alert,
  Button,
  EmailField,
  EmailRegex,
  ForgotPasswordContainer,
  makeStyles,
  PageTitle,
} from '@perk-ui/core'

import AuthBackground from '../../components/AuthBackground'
import { useAuth } from '../../features/auth/AuthContext'

const useStyles = makeStyles((theme) => ({
  password: {
    marginTop: theme.spacing(3),
  },
  loginButton: {
    marginTop: theme.spacing(3),
  },
  alert: {
    color: theme.palette.success.dark,
  },
}))

interface ErrorState {
  email?: string
}

interface ResendActivationEmailProps {}

const ResendActivationLink: FC<ResendActivationEmailProps> = () => {
  const classes = useStyles()
  const { requestPasswordReset } = useAuth()
  const [username, setUsername] = useState<string>('')
  const [emailSent, setEmailSent] = useState(false)
  const [errors, setErrors] = useState({} as ErrorState)
  const [loading, setLoading] = useState(false)

  const handleResendEmailClick = () => {
    if (!EmailRegex.test(username)) {
      setErrors({
        email: 'Invalid email format',
      })
      return
    }

    setLoading(true)
    return requestPasswordReset(username)
      .then(() => {
        setEmailSent(true)
      })
      .catch(() => {
        setErrors({
          email: 'Sorry - we were unable to send the verification email',
        })
      })
      .finally(() => {
        setLoading(false)
        setErrors({} as ErrorState)
      })
  }

  return (
    <AuthBackground>
      <PageTitle title="Resend Activation Link" />
      <ForgotPasswordContainer
        title={'Verification Email'}
        MessagesComponent={
          emailSent && (
            <Alert className={classes.alert} severity="success" fade grow>
              Please check your email for the next step.
            </Alert>
          )
        }
        UsernameComponent={
          <EmailField
            value={username}
            onChange={(ev) => setUsername(ev.target.value)}
            helperText={errors?.email}
            error={Boolean(errors.email)}
          />
        }
        ButtonComponent={
          <Button
            className={classes.loginButton}
            loading={loading}
            onClick={handleResendEmailClick}
          >
            Resend activation email
          </Button>
        }
      />
    </AuthBackground>
  )
}

export default ResendActivationLink
