import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { BodySiteKey } from './useBodySites'

export type GenderOptions = 'male' | 'female' | 'other'
export type TreatmentPercentages = Record<string, number>
export type BodyPartPercentages = Record<BodySiteKey, number>
export interface AveragePatientAge {
  years: number
  months: number
  weeks: number
  hours: number
  minutes: number
  seconds: number
}

export interface ClinicOverviewResponse {
  name: BodySiteKey
  averagePatientAge: AveragePatientAge
  // Object where key is the treatment name and value is
  // an integer representing the percentage:
  treatmentPercentages: TreatmentPercentages
  bodyPartsPercentages: BodyPartPercentages
  patientSexPercentages: Record<GenderOptions, number>
  averagePatientBmiScore: number
}

const useClinicOverview = (
  extraOptions: UseQueryOptions<ClinicOverviewResponse> = {},
) => {
  const currentSiteId = useCurrentSite().id
  const siteIds = currentSiteId

  const key = QueryKeys.clinicOverview({ currentSiteId })
  const url = `/sites/${currentSiteId}/overview?site_ids[]=${siteIds}`

  return useQuery(
    key,
    () => request.get<ClinicOverviewResponse>(url).then((r) => r.data),
    {
      ...extraOptions,
    },
  )
}

export default useClinicOverview
