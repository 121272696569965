import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export interface OnboardingStep {}

interface OnboardingStepResponse {
  onboardingStep: string
}

const useOnboardingStep = (
  extraOptions: UseQueryOptions<OnboardingStep> = {},
) => {
  const siteId = useCurrentSite()?.id || ''
  const key = QueryKeys.onboardingStep(siteId)
  const url = `/sites/onboarding_step?site_ids[]=${siteId}`

  return useQuery(
    key,
    () =>
      request
        .get<OnboardingStepResponse>(url)
        .then((r) => r.data.onboardingStep),
    {
      // Site onboarding step is static - don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useOnboardingStep
