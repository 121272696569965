import { useMemo } from 'react'
import { differenceInYears } from 'date-fns'
import * as yup from 'yup'

import useDates from '../../../hooks/useDates'
import { TreatmentFormValues } from './Treatment'

const createTreatmentValidations = (
  datePattern: string,
  parseDateFn: (date: string) => false | Date,
  initialValues?: TreatmentFormValues,
  isBaselineCompleted?: boolean,
  isPrimaryTreatment?: boolean,
  isRsnRequired?: boolean,
) =>
  yup.object({
    providerId: yup.string().defined('A provider must be selected'),
    medicalTreatmentId: yup
      .string()
      .defined('A treatment type must be selected'),
    treatmentProductId: yup
      .string()
      .defined('A treatment product must be selected'),
    treatmentDate: yup
      .string()
      .defined('Treatment date is required for scheduling assessments')
      .test(
        'is-valid-date',
        `Treatment must be in format ${datePattern.toLocaleLowerCase()} and a valid date`,
        (value = '') => !!parseDateFn(value),
      )
      // .test(
      //   'is-after-yesterday',
      //   'Treatment date must be today or later',
      //   (value) => {
      //     const asDate = parseDateFn(value || '')
      //     if (
      //       value !== '' &&
      //       (!initialValues?.treatmentDate ||
      //         // If we have an initialValue (aka we're operating on an existing treatment)
      //         // Treatment dates can only be the same as they were initially or for dates after
      //         // yesterday
      //         format(asDate || 0, 'yyyy-MM-dd') !== initialValues.treatmentDate)
      //     ) {
      //       if (isBaselineCompleted || isPrimaryTreatment) return true
      //       return asDate && (isToday(asDate) || isFuture(asDate))
      //     }
      //     return true
      //   },
      // )
      .test(
        'is-before-a-year',
        'Treatment cannot be after 1 year from today',
        (value = '') => {
          const asDate = parseDateFn(value)
          return asDate && differenceInYears(asDate, new Date()) < 1
        },
      ),
    researchStudyNumber: yup
      .string()
      .nullable()
      .test(
        'is-required-for-primary-treatment',
        'Research study number is required',
        (value = '') => (isRsnRequired ? value !== '' && isRsnRequired : true),
      ),
  })

const useTreatment = () => {
  const { localizedDatePattern, localizedParseDate } = useDates()

  return useMemo(
    () => ({
      createTreatmentValidations: (
        initialValues?: TreatmentFormValues,
        isBaselineCompleted?: boolean,
        isPrimaryTreatment?: boolean,
        isRsnRequired?: boolean,
      ) =>
        createTreatmentValidations(
          localizedDatePattern,
          localizedParseDate,
          initialValues,
          isBaselineCompleted,
          isPrimaryTreatment,
          isRsnRequired,
        ),
    }),
    [localizedDatePattern, localizedParseDate],
  )
}

export default useTreatment
