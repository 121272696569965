import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { BodySite } from './useBodySites'
import { MedicalCondition } from './useMedicalConditions'
import { MedicalTreatment } from './useMedicalTreatments'
import { TreatmentProduct } from './useTreatmentProducts'

export interface Study {
  id: string
  name: string // 'Tendinosis'
  shortName: string
  slug: string
  description: string
  hasEconsent: boolean
  bodySites: BodySite[]
  medicalConditions: MedicalCondition[]
  medicalTreatments: MedicalTreatment[]
  treatmentProducts: TreatmentProduct[]
}

interface StudyResponse {
  studies: Study[]
}

const useStudies = (extraOptions: UseQueryOptions<Study[]> = {}) => {
  const siteId = useCurrentSite().id
  const key = QueryKeys.studies()
  const url = `/sites/studies?site_ids[]=${siteId}`

  return useQuery(
    key,
    () =>
      request.get<StudyResponse>(url).then((r) => {
        const studies = r.data.studies
        const noStudyIndex = studies.findIndex((x) => x.slug == 'none')
        if (noStudyIndex > 0) {
          const swapVal = studies[0]
          studies[0] = studies[noStudyIndex]
          studies[noStudyIndex] = swapVal
        }
        return studies
      }),
    {
      // Studys are static. Don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useStudies
