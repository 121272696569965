import { FC } from 'react'
import { Box, capitalize, CircularProgress, H3, styled } from '@perk-ui/core'
import { mapKeys } from 'lodash'

import MetricCard from '../../../components/MetricCard'
import { useCurrentSite } from '../../../components/SitesProvider'
import useClinicOverview, {
  BodyPartPercentages,
  TreatmentPercentages,
} from '../../../features/query-hooks/useClinicOverview'
import { TreatmentTypeNameCustomizations } from '../../PatientFlow/Treatment/Treatment'
import PercentageBarGraph from './PercentageBarGraph'

const BodyPartNameCustomizations: Record<string, string> = {}

const humanizeKebabCase = (string: string) =>
  Array.from(string)
    .flatMap((char) => (char.match(/[A-Z]/) ? [' ', char] : [char]))
    .join('')
    .split(' ')
    .map(capitalize)
    .join(' ')

export const mapTreatmentNames = (treatments: TreatmentPercentages) =>
  mapKeys(treatments, (value, treatmentName) => {
    return treatmentName in TreatmentTypeNameCustomizations
      ? TreatmentTypeNameCustomizations[treatmentName]
      : humanizeKebabCase(treatmentName)
  })

const mapBodyPartNames = (bodyParts: BodyPartPercentages) =>
  mapKeys(bodyParts, (value, bodyPartName) => {
    return bodyPartName in BodyPartNameCustomizations
      ? BodyPartNameCustomizations[bodyPartName]
      : humanizeKebabCase(bodyPartName)
  })

const MetricsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr 1fr',
  gap: theme.spacing(3),
}))

const GraphsContainer = styled(Box)(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr 1fr',
  gap: theme.spacing(6),
}))

const ClinicOverview: FC = () => {
  const currentSite = useCurrentSite()
  const { data: clinicOverview } = useClinicOverview()
  const showSexPercentage =
    clinicOverview?.patientSexPercentages &&
    typeof clinicOverview?.patientSexPercentages.male === 'number' &&
    typeof clinicOverview?.patientSexPercentages?.female === 'number'
      ? true
      : false

  return (
    <Box>
      <Box
        sx={{
          display: 'flex',
          py: 3,
          ml: 3,
          borderBottom: ({ palette }) => `1px solid ${palette.divider}`,
        }}
      >
        <H3>{currentSite.name} Overview</H3>
      </Box>
      <Box px={3} mt={4}>
        {clinicOverview ? (
          <MetricsContainer sx={{ display: 'grid' }}>
            <MetricCard
              cardTitle="Avg. Age"
              metrics={[{ value: clinicOverview?.averagePatientAge?.years }]}
            />
            <MetricCard
              cardTitle="Avg. BMI"
              metrics={[
                {
                  value: Number(clinicOverview?.averagePatientBmiScore).toFixed(
                    1,
                  ),
                },
              ]}
            />
            {showSexPercentage && (
              <MetricCard
                cardTitle="Sex"
                metrics={[
                  {
                    legend: 'Male',
                    value: `${clinicOverview?.patientSexPercentages.male.toFixed(
                      1,
                    )}%`,
                  },
                  {
                    legend: 'Female',
                    value: `${clinicOverview?.patientSexPercentages.female.toFixed(
                      1,
                    )}%`,
                  },
                ]}
              />
            )}
          </MetricsContainer>
        ) : (
          <CircularProgress />
        )}
      </Box>
      <GraphsContainer p={3}>
        <Box>
          {clinicOverview ? (
            <PercentageBarGraph
              title="Body Parts Treated"
              percentages={mapBodyPartNames(
                clinicOverview?.bodyPartsPercentages,
              )}
            />
          ) : (
            <CircularProgress />
          )}
        </Box>
        <Box>
          {clinicOverview ? (
            <PercentageBarGraph
              title="Treatment Types"
              percentages={mapTreatmentNames(
                clinicOverview?.treatmentPercentages,
              )}
            />
          ) : (
            <CircularProgress />
          )}
        </Box>
      </GraphsContainer>
    </Box>
  )
}

export default ClinicOverview
