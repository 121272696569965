import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import clsx from 'clsx';
import { Body2, makeStyles, Slider } from '../';
const useStyles = makeStyles((theme) => ({
    root: {
        height: '100%',
    },
    // Classes are nested here to mimic the Slider's style nesting and
    // to raise CSS specificty
    vertical: {
        // Root
        '&.MuiSlider-root': {
            color: 'rgba(0, 0, 0, 0.3)',
            width: theme.spacing(2),
            minHeight: 200,
            height: '100%',
        },
        '& .MuiSlider-active:not(disabled)': {
            boxShadow: `0px 0px 0px 10px rgba(0,0,0,0.3)`, // Thumb's shadow when pressed
        },
        // Rail
        '& .MuiSlider-rail': {
            borderRadius: 8,
            marginTop: '-12px',
            paddingTop: '12px',
            width: theme.spacing(2),
        },
        // Thumb
        '& .MuiSlider-thumb': {
            boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.5)',
            color: theme.palette.primary.main,
            height: 40,
            width: 40,
            '&:hover': {
                boxShadow: `0px 0px 0px 8px rgba(0,0,0,0.3)`,
            },
            // Adds a dash inside the Thumb button
            '&::after': {
                content: '""',
                height: 2,
                width: 16,
                background: 'white',
                position: 'absolute',
                top: 19,
                left: 19,
                // unset of the default .MuiSlider-thumb::after styles
                borderRadius: 0,
            },
        },
        // ValueLabel
        '& .MuiSlider-valueLabel': {
            top: 36,
            // The combination of left + width positions the element a guaranteed distance from the
            // Thumb element while the content is always right-aligned
            left: -90,
            width: 75,
            justifyContent: 'flex-end',
            padding: 0,
            backgroundColor: 'transparent',
            fontSize: theme.typography.h3.fontSize,
            fontWeight: 'bold',
            '& *': {
                color: theme.palette.primary.main,
            },
        },
        '& .MuiSlider-mark': {
            // We want to display the markLabels, but not the mark
            display: 'none',
        },
        '& .MuiSlider-markLabel': {
            color: theme.palette.text.primary,
            left: theme.spacing(7),
            fontSize: '16px',
            fontWeight: 'bold',
            whiteSpace: 'normal',
            width: theme.spacing(15),
            '&[data-index="0"]': {
                paddingTop: theme.spacing(1), // scooch the top markLabel down
            },
            '&[data-index="1"]': {
                paddingBottom: theme.spacing(1.5), // scooch the bottom markLabel up
            },
        },
        '& .MuiSlider-markLabelActive': {
            left: '65px', // When active, move the labels to the right
        },
    },
    horizontal: {
        width: '70%',
        display: 'flex',
        margin: 'auto',
        'margin-top': '50px',
        'margin-bottom': '30px',
        '& .MuiSlider-rail': {
            borderRadius: 8,
            height: theme.spacing(2),
        },
        '& .MuiSlider-mark': {
            width: '5px',
            height: '5px',
            'border-radius': '100%',
        },
        // Thumb
        '& .MuiSlider-thumb': {
            boxShadow: '0px 2px 20px rgba(0, 0, 0, 0.5)',
            color: theme.palette.primary.main,
            height: 30,
            width: 30,
            '&:hover': {
                boxShadow: `0px 0px 0px 5px rgba(0,0,0,0.3)`,
            },
        },
    },
}));
const SurveySlider = ({ value, id, initialValue, onChange, labelMaxValue, labelMinValue, rangeMin, rangeMax, className, vertical = true, }) => {
    const classes = useStyles();
    const marks = [
        { value: rangeMax, label: labelMaxValue || rangeMax },
        { value: rangeMin, label: labelMinValue || rangeMin },
    ];
    const marksFull = [
        { value: rangeMax, label: labelMaxValue || rangeMax },
        { value: 1 },
        { value: 2 },
        { value: 3 },
        { value: 4 },
        { value: 5 },
        { value: 6 },
        { value: 7 },
        { value: 8 },
        { value: 9 },
        { value: rangeMin, label: labelMinValue || rangeMin },
    ];
    // Use the middle of the range as the base default value
    // Overrideable by the item's configuration
    const middleValue = Math.floor(Math.abs(rangeMax - rangeMin) / 2);
    const defaultValue = initialValue != null ? initialValue : middleValue;
    const sliderValue = value != null ? value : defaultValue;
    // If the Slider is mounted without an initial value (value == null)
    useEffect(() => {
        if (value == null) {
            onChange(sliderValue);
        }
        // eslint-disable-next-line
    }, []);
    return (_jsxs("div", Object.assign({ className: clsx('SurveySlider-root', className, {
            [classes.root]: vertical,
        }) }, { children: [labelMaxValue && vertical && value !== rangeMax && (_jsx(MarkNumber, Object.assign({ translateY: "-33%" }, { children: rangeMax }), void 0)), _jsx(Slider, { id: id, value: sliderValue, onChange: (_, val) => onChange(val), classes: vertical
                    ? { vertical: classes.vertical }
                    : { root: classes.horizontal }, orientation: vertical ? 'vertical' : 'horizontal', min: rangeMin, max: rangeMax, step: 1, marks: vertical ? marks : marksFull, valueLabelDisplay: "on", track: false }, void 0), labelMinValue && vertical && value !== rangeMin && (_jsx(MarkNumber, Object.assign({ translateY: "-100%" }, { children: rangeMin }), void 0))] }), void 0));
};
const MarkNumber = ({ children, translateY }) => (_jsx(Body2, Object.assign({ style: {
        color: 'rgb(46, 46, 46)',
        fontSize: '1rem',
        position: 'absolute',
        transform: `translate(-100%, ${translateY})`,
        width: '32px',
        textAlign: 'end',
        paddingRight: 8,
    } }, { children: children }), void 0));
export default SurveySlider;
