import { FC, MouseEvent, useEffect, useState } from 'react'
import { Button, H5, Icon, Menu, MenuItem } from '@perk-ui/core'
import { ChevronDown, ChevronUp } from 'react-feather'

import { Site } from '../features/query-hooks/useSitesAvailable'
import { useSitesContext } from './SitesProvider'

export interface SitesDropdownProps {}

const SitesDropdown: FC<SitesDropdownProps> = () => {
  const { currentSite, setCurrentSite, sitesQuery } = useSitesContext()
  const sites = sitesQuery.data || []
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const isOpen = Boolean(anchorEl)
  const menuId = 'sites-dropdown-id'

  useEffect(() => {
    if (sites.length && !currentSite) {
      setCurrentSite(sites[0])
    }
  }, [sites, currentSite])

  const handleOpen = (evt: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(evt.currentTarget)
  }

  const handleSelect = (site: Site | null) => {
    setAnchorEl(null)
    site && setCurrentSite(site)
  }

  const startIcon = (
    <Icon size="large">{isOpen ? <ChevronUp /> : <ChevronDown />}</Icon>
  )

  if (!sites.length || !currentSite) return null

  return sites.length === 1 ? (
    <H5 style={{ display: 'inline-flex' }}>{currentSite.name}</H5>
  ) : (
    <>
      <Button
        variant="text"
        color="inherit"
        size="medium"
        onClick={handleOpen}
        aria-controls={menuId}
        aria-haspopup="true"
        startIcon={startIcon}
      >
        {currentSite.name}
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        open={isOpen}
        onClose={() => handleSelect(null)}
        keepMounted
      >
        {sites.map((site) => (
          <MenuItem key={site.id} onClick={() => handleSelect(site)}>
            {site.name}
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

export default SitesDropdown
