import { FC, useState } from 'react'
import { Box, H3, IconButton, makeStyles, Popover } from '@perk-ui/core'
import { ChevronDown } from 'react-feather'
import { Cell, Label, Legend, Pie, PieChart, Tooltip } from 'recharts'

import usePatientTreatmentStats from '../../../features/query-hooks/usePatientTreatmentStats'
import { BodyPartColorList, keyToLabel } from './BodyPartGraph'
import { PieChartItem } from './BodyPartPieChart'

export const TreatmentSlugNameCustomizations: Record<string, string> = {
  prp: 'Platelet-Rich Plasma (PRP)',
  ppp: 'Platelet Poor Plasma (PPP)',
  a2m: 'Alpha-2-Macroglobulin (A2M)',
  emtt: 'Extracorporeal Magnetotransduction Therapy',
  'Bone Marrow': 'Bone Marrow (BMAC)',
  aps: 'Autologous Protein Solution (APS)',
  hifem: 'High-Intensity Focused Electromagnetic (HIFEM)',
  classIvLaserTherapy: 'Class IV Laser Therapy',
  ssPrp: 'SuperShot PRP',
  ssBmc: 'SuperShot BMC',
}

const useStyles = makeStyles((theme) => ({
  root: {
    color: '#000000',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '20px',
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      color: '#2396CF',
    },
  },
  iconButton: {
    padding: 0,
    marginLeft: theme.spacing(0.5),
  },
  popoverContainer: {
    padding: theme.spacing(2),
    '& .recharts-wrapper': {
      '& svg.recharts-surface': {
        '& .recharts-label': {
          '& tspan': {
            fontSize: '45px',
            fontWeight: 700,
          },
        },
      },
      '& .recharts-legend-wrapper': {
        overflow: 'auto',
        '& .recharts-legend-item': {
          '& svg': {
            borderRadius: '4px',
          },
          '& .recharts-legend-item-text': {
            '& span': {
              color: '#000000',
            },
          },
        },
      },
    },
  },
}))

export interface TreatmentPieChartProps {}
const sumValue = (arr: PieChartItem[]) =>
  arr.reduce((sum, item) => sum + item.value, 0)

const TreatmentPieChart: FC<TreatmentPieChartProps> = () => {
  const classes = useStyles()
  const { data: stats = {} } = usePatientTreatmentStats()

  const dataItems = Object.keys(stats).map((key) => ({
    label: key,
    value: stats[key as string],
  })) as PieChartItem[]
  const sortedItems = dataItems.sort((a, b) => b.value - a.value)
  const sum = sumValue(sortedItems)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const openPopover = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClosePopover = () => {
    setAnchorEl(null)
  }

  const open = Boolean(anchorEl)
  const id = open ? 'treatment-chart' : undefined

  return (
    <Box className={classes.root}>
      {sum}
      <IconButton
        onClick={openPopover}
        size="small"
        className={classes.iconButton}
      >
        <ChevronDown size={20} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClosePopover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
      >
        <Box className={classes.popoverContainer}>
          <H3>Total Treatments</H3>
          <PieChart width={250} height={320}>
            <Pie
              data={sortedItems}
              cx={'50%'}
              cy={100}
              startAngle={90}
              endAngle={-360}
              innerRadius={65}
              outerRadius={80}
              fill="#8884d8"
              paddingAngle={1}
              nameKey="label"
              dataKey="value"
              legendType="square"
            >
              <Label width={30} position="center" fill={'#000000'}>
                {sum}
              </Label>
              {sortedItems.map((entry, index) => (
                <Cell
                  key={`cell-${index}`}
                  fill={BodyPartColorList[index % BodyPartColorList.length]}
                />
              ))}
            </Pie>
            <Tooltip
              formatter={(value: number, name: string) => {
                return [
                  value,
                  name in TreatmentSlugNameCustomizations
                    ? TreatmentSlugNameCustomizations[name]
                    : keyToLabel(name),
                ]
              }}
            />
            <Legend
              height={105}
              verticalAlign="bottom"
              fill={'#000000'}
              // eslint-disable-next-line @typescript-eslint/no-explicit-any
              formatter={(value: string, entry: any) => {
                const { payload } = entry
                return (
                  <span>
                    {value in TreatmentSlugNameCustomizations
                      ? TreatmentSlugNameCustomizations[value]
                      : keyToLabel(value)}{' '}
                    <b>{payload?.value}</b>
                  </span>
                )
              }}
            />
          </PieChart>
        </Box>
      </Popover>
    </Box>
  )
}

export default TreatmentPieChart
