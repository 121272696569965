/* eslint-disable @typescript-eslint/no-explicit-any */

import { FC, useEffect, useRef } from 'react'
import {
  Box,
  Button,
  Card,
  H2,
  IconButton,
  makeStyles,
  PageTitle,
} from '@perk-ui/core'
import clsx from 'clsx'
import { ArrowLeft } from 'react-feather'
import { useLocation, useParams } from 'react-router'

import AppLinkButton from '../../components/AppLinkButton'
import PatientAdverseEvents from '../../components/PatientAdverseEvents'
import PatientCommunicationInfo from '../../components/PatientCommunicationInfo'
import PatientInfo from '../../components/PatientInfo'
import { useSitesContext } from '../../components/SitesProvider'
import { useAuth } from '../../features/auth/AuthContext'
import useAdverseEvents from '../../features/query-hooks/useAdverseEvents'
import useBodyPartsSurveyCompletionStatus from '../../features/query-hooks/useBodyPartsSurveyCompletionStatus'
import usePatient from '../../features/query-hooks/usePatient'
import usePatientCases from '../../features/query-hooks/usePatientCases'
import useSurveyResponseGroups from '../../features/query-hooks/useSurveyResponseGroups'
import { useHistory } from '../../utils/useHistory'
import PatientFlowUrls from '../PatientFlow/PatientFlowUrls'
import CaseDetails from './components/CaseDetails'
import SurveyStatus from './components/SurveyStatus'
import PatientReportPdf from './PatientReportPdf'

export interface PatientDetailsProps {}

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    padding: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  patientInfo: {
    marginBottom: theme.spacing(3),
  },
  patientCommunicationInfo: {
    marginBottom: theme.spacing(3),
  },
  adverseEvents: {
    marginBottom: theme.spacing(3),
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  leftCol: {
    paddingRight: theme.spacing(4),
    width: '50%',
  },
  rightCol: {
    width: '50%',
    '& > *': {
      marginBottom: theme.spacing(4),
    },
  },
  editButton: {
    margin: 'auto 0',
    padding: theme.spacing(0, 4),
  },
}))

const PatientDetails: FC<PatientDetailsProps> = () => {
  const { user } = useAuth()
  const { goBack, push } = useHistory()
  const location = useLocation<{ from?: string }>()
  const classes = useStyles()
  const { currentSite } = useSitesContext()
  const { patientId } = useParams<{ patientId: string }>()
  const { data: patient, isFetchedAfterMount } = usePatient(patientId)
  const { data: patientCases = [] } = usePatientCases(patientId)
  const { data: searchResult } = useAdverseEvents({ patientId: patientId })
  const { data: openSurveys = [] } = useSurveyResponseGroups({
    patientId: patientId,
  })
  const { data: surveyCompletionList } =
    useBodyPartsSurveyCompletionStatus(patientId)
  const adverseEvents = searchResult?.adverseEvents || []
  const reportPDFRef = useRef<any>()

  useEffect(() => {
    if (!patient && isFetchedAfterMount) {
      goBack()
    }
  }, [currentSite])

  const generatePDF = () => {
    if (reportPDFRef.current) {
      reportPDFRef.current.save()
    }
  }

  return (
    <>
      {user && user.email && user.email.includes('databiologics.com') && (
        <PatientReportPdf ref={reportPDFRef} />
      )}
      <Box p={3}>
        <PageTitle title="Patient" />
        <div className={classes.header}>
          <H2 className={classes.headerTitle}>
            <IconButton
              onClick={() => {
                if (location.state?.from) {
                  push(location.state.from)
                } else {
                  push('/patients')
                }
                // goBack()
              }}
              className={classes.backButton}
              size="large"
            >
              <ArrowLeft />
            </IconButton>
            <span>{patient && `${patient.firstName} ${patient.lastName}`}</span>
          </H2>
          <Button
            id="patient-details-report-button"
            onClick={() => {
              generatePDF()
            }}
          >
            Report
          </Button>
          {patient?.status === 'active' && (
            <AppLinkButton
              id="patient-details-edit-info-button"
              className={classes.editButton}
              to={PatientFlowUrls.editPatient.create(patientId)}
              size="medium"
            >
              Edit Patient Info
            </AppLinkButton>
          )}
        </div>
        <div className={classes.content}>
          <div className={classes.leftCol}>
            {patientCases.map((pCase, index) => (
              <Box mb={4} key={pCase.id}>
                <Card className={classes.card}>
                  <CaseDetails patientCase={pCase} index={index} />
                </Card>
              </Box>
            ))}
            {patient && adverseEvents.length > 0 && (
              <Card className={clsx(classes.card, classes.adverseEvents)}>
                <PatientAdverseEvents adverseEvents={adverseEvents} />
              </Card>
            )}
          </div>
          <div className={classes.rightCol}>
            <Card className={clsx(classes.card, classes.patientInfo)}>
              {patient && <PatientInfo patient={patient} />}
            </Card>
            {!__PROD__ && (
              <Card
                className={clsx(classes.card, classes.patientCommunicationInfo)}
              >
                {patient && <PatientCommunicationInfo patient={patient} />}
              </Card>
            )}
            {patient &&
              patient.status === 'active' &&
              (openSurveys.length > 0 ||
                (surveyCompletionList || []).length > 0) && (
                <Card className={classes.card}>
                  <SurveyStatus openSurveys={openSurveys} patient={patient} />
                </Card>
              )}
          </div>
          <div>
            <PatientReportPdf />
          </div>
        </div>
      </Box>
    </>
  )
}

export default PatientDetails
