/**
 * Use Typescript module augmentation to extend the Theme interface with the PerkThemeValues.
 */
import './themeAugmentation';
// import { adaptV4Theme } from '@mui/material/styles'
import { alpha, createTheme, } from '../';
/**
 * Create a Perk Theme! Accepts a Palette and set of customizations that will be applied on top of the
 * base Material-UI theme and the base Perk theme.
 *
 * @param palette A Material-UI Palette of colors
 * @param customizations A set of customizations to the base Material-UI theme.
 *  These will be deep-merged with our defaults
 */
const createPerkTheme = (customizations) => {
    const customPalette = customizations === null || customizations === void 0 ? void 0 : customizations.palette;
    const customTypography = customizations === null || customizations === void 0 ? void 0 : customizations.typography;
    // Create a standard theme so that we can access some default helpers in our custom theme.
    const theme = createTheme(customPalette
        ? { palette: customPalette, typography: customTypography }
        : {});
    // Custom Perk values we make available via the theme object
    // e.g. makeStyles(theme => .. theme.perk.inputBackground ..)
    const perkThemeValues = {
        cssVar: {
            totalHeaderHeight: 'var(--total-header-height, 0px)',
            totalFooterHeight: 'var(--total-footer-height, 0px)',
        },
        icon: {
            size: {
                small: 16,
                medium: 20,
                large: 24,
                'x-large': 30,
            },
        },
        boxShadow: {
            button: '0 2px 20px rgba(0, 0, 0, .10)',
            card: '0 2px 24px rgba(0, 0, 0, .08)',
            switch: '0 2px 5px rgba(0, 0, 0, .25)',
        },
        borderRadius: 10,
        inputBackground: '#f5f5f8',
        textPrimaryActiveStyle: {
            // This not() increases specificity to make :active work on Mobile Safari
            '&:not(:disabled):active': {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            },
        },
        backgroundContainerColor: theme.palette.common.white,
    };
    const perkThemeOptions = {
        perk: perkThemeValues,
        palette: theme.palette,
        components: {
            MuiAppBar: {
                defaultProps: {
                    color: 'inherit',
                    position: 'sticky',
                },
            },
            MuiAccordion: {
                styleOverrides: {
                    root: {
                        boxShadow: perkThemeValues.boxShadow.card,
                    },
                },
            },
            MuiAlert: {
                styleOverrides: {
                    standardError: {
                        backgroundColor: theme.palette.grey[100],
                        color: theme.palette.error.main,
                    },
                    standardWarning: {
                        backgroundColor: theme.palette.grey[100],
                        color: theme.palette.warning.dark,
                    },
                    standardInfo: {
                        backgroundColor: theme.palette.grey[100],
                        color: theme.palette.info.main,
                    },
                    standardSuccess: {
                        backgroundColor: theme.palette.grey[100],
                        color: theme.palette.success.main,
                    },
                },
            },
            MuiAutocomplete: {
                styleOverrides: {
                    endAdornment: {
                        paddingRight: theme.spacing(2),
                    },
                    popper: {
                        boxShadow: perkThemeValues.boxShadow.card,
                    },
                    input: {
                        maxWidth: '100%',
                        // Autocomplete has some high-specificty styles it applies to the
                        // underlying input element, so we use `!important` to override
                        paddingLeft: `${theme.spacing(2)} !important`,
                    },
                    tag: {
                        marginLeft: theme.spacing(1),
                    },
                },
            },
            MuiAvatar: {
                styleOverrides: {
                    root: {
                        height: 48,
                        width: 48,
                    },
                },
            },
            MuiButton: {
                defaultProps: {
                    color: 'primary',
                    disableTouchRipple: true,
                    size: 'large',
                    variant: 'contained',
                },
                styleOverrides: {
                    root: {
                        // These values represent size "medium", but by default the Button size is set to "large"
                        fontSize: '1rem',
                        minHeight: 48,
                    },
                    contained: {
                        boxShadow: perkThemeValues.boxShadow.button,
                        '&:hover': {
                            boxShadow: perkThemeValues.boxShadow.button,
                            '@media (hover: none)': {
                                // Hovered items on mobile devices should look normal - not
                                // as if they've been de-selected or hovered over
                                boxShadow: undefined,
                                // backgroundColor: 'transparent',
                            },
                        },
                        '&:active': {
                            boxShadow: perkThemeValues.boxShadow.button,
                        },
                    },
                    containedPrimary: {
                        color: 'white !important',
                        // This not() increases specificity to make :active work on Mobile Safari
                        '&:not(:disabled):active': {
                            backgroundColor: theme.palette.primary.light,
                        },
                    },
                    outlined: {
                        boxShadow: perkThemeValues.boxShadow.button,
                    },
                    outlinedPrimary: Object.assign({ border: 'none', '&:hover, &:disabled': {
                            border: 'none',
                        } }, perkThemeValues.textPrimaryActiveStyle),
                    sizeSmall: {
                        minHeight: 32,
                    },
                    text: {
                        transition: theme.transitions.create('background-color', {
                            duration: theme.transitions.duration.short,
                        }),
                    },
                    textPrimary: Object.assign({ color: theme.palette.primary.main, '&:hover': {
                            backgroundColor: alpha(theme.palette.primary.light, theme.palette.action.hoverOpacity),
                        } }, perkThemeValues.textPrimaryActiveStyle),
                    textSizeSmall: {
                        fontSize: '.875rem',
                        minWidth: 0,
                        paddingLeft: theme.spacing(1),
                        paddingRight: theme.spacing(1),
                    },
                    iconSizeSmall: {
                        '&.MuiButton-startIcon': {
                            marginLeft: 0,
                            marginRight: theme.spacing(0.5),
                        },
                        '&.MuiButton-endIcon': {
                            marginLeft: theme.spacing(0.5),
                            marginRight: 0,
                        },
                    },
                    // Note: MUI does not have a sizeMedium class override
                    sizeLarge: {
                        fontSize: '1rem',
                        minHeight: 60,
                        padding: theme.spacing(1, 4),
                    },
                },
            },
            MuiButtonBase: {
                defaultProps: {
                    disableTouchRipple: true,
                },
            },
            MuiButtonGroup: {
                defaultProps: {
                    fullWidth: true,
                    orientation: 'vertical',
                },
                styleOverrides: {
                    root: {
                        boxShadow: perkThemeValues.boxShadow.card,
                    },
                    grouped: {
                        '&.MuiButton-root': {
                            border: 'none',
                            '&:hover, &:disabled': {
                                border: 'none',
                            },
                        },
                    },
                    groupedOutlined: {
                        boxShadow: 'none',
                        '&:active': {
                            backgroundColor: theme.palette.primary.main,
                            color: theme.palette.primary.contrastText,
                        },
                    },
                    groupedOutlinedHorizontal: {
                        '&:not(:last-of-type)': {
                            borderRight: `0.125rem solid ${theme.palette.divider} !important`,
                        },
                    },
                    groupedOutlinedVertical: {
                        '&:not(:first-of-type)': {
                            borderTop: `1px solid ${theme.palette.divider} !important`,
                        },
                    },
                },
            },
            MuiCard: {
                styleOverrides: {
                    root: {
                        boxShadow: perkThemeValues.boxShadow.card,
                    },
                },
            },
            MuiCardActions: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(1.5, 3, 3),
                    },
                    spacing: {
                        '& > :not(:first-of-type)': {
                            marginLeft: theme.spacing(2),
                        },
                    },
                },
            },
            MuiCardActionArea: {
                styleOverrides: {
                    focusHighlight: {
                        // give the hover and focus background effect a primary.light color
                        backgroundColor: theme.palette.primary.light,
                    },
                },
            },
            MuiCardContent: {
                styleOverrides: {
                    root: {
                        padding: theme.spacing(3),
                        '.MuiCard-root > &:not(:last-child), .MuiCardActionArea-root:not(:last-child) > &': {
                            // Shorten the bottom padding of CardContent items within a Card or CardActionArea
                            paddingBottom: theme.spacing(1.5),
                        },
                        '& > .muitypography-root:first-of-type': {
                            // typography's line-height causes a small but noticeable amount of additional top padding
                            // in cards, making them feel off-balance. Add a negative margin to compensate.
                            margintop: theme.spacing(-0.5),
                        },
                    },
                },
            },
            MuiCheckbox: {
                styleOverrides: {
                    root: {
                        borderRadius: 2,
                        padding: 0,
                        marginRight: 2,
                        transition: theme.transitions.create('color', {
                            duration: theme.transitions.duration.short,
                        }),
                        '&:hover': {
                            backgroundColor: 'transparent',
                        },
                        '&.MuiCheckbox-colorSecondary': {
                            '&:active': {
                                color: theme.palette.primary.light,
                            },
                            '&.Mui-checked:hover': {
                                backgroundColor: 'transparent',
                            },
                        },
                        // Add browser-appropriate focus state to Checkbox
                        '&.Mui-focusVisible': {
                            outline: '0px auto -webkit-focus-ring-color',
                            fallbacks: [{ outline: '1px dotted #212121' }],
                        },
                    },
                },
            },
            MuiCssBaseline: {
                styleOverrides: `
          html {
            background-color: ${theme.palette.background.paper};
          }
          body {
            background-color: ${theme.palette.background.paper};
            overscroll-behavior: none;
          }
          // Use the Link component. These anchor styles are just a fallback.
          a:link {
            color: ${theme.palette.primary.main};
          }
          a:visited {
            color: ${theme.palette.primary.dark};
          }
          a:active {
            color: ${theme.palette.primary.dark};
          }
          // For some reason, these @material-ui/pickers classes are not overrideable
          // and must be referenced by standard CSS selectors
          .MuiPickersStaticWrapper-staticWrapperRoot .MuiPickersBasePicker-pickerView {
            max-width: 310px;
            padding: 0;
            width: 100%;
            // prevent a scrollbar flash when changing between with a different number
            // of weeks
            overflow: hidden;
            justify-content: initial;
          }
          .MuiPickersBasePicker-containerLandscape {
            justify-content: center;
          }
        `,
            },
            MuiFormControl: {
                defaultProps: {
                    variant: 'standard',
                },
            },
            MuiFormHelperText: {
                styleOverrides: {
                    root: {
                        fontSize: '.875rem',
                    },
                },
            },
            MuiFormLabel: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.primary,
                        fontWeight: theme.typography.fontWeightBold,
                        lineHeight: 1,
                        '&.Mui-focused, &.Mui-error': {
                            color: theme.palette.text.primary,
                        },
                    },
                    asterisk: {
                        color: theme.palette.error.main,
                    },
                },
            },
            MuiIconButton: {
                defaultProps: {
                    disableRipple: true,
                },
                styleOverrides: {
                    root: {
                        color: theme.palette.text.primary,
                        '&:hover': {
                            backgroundColor: alpha(theme.palette.action.hover, theme.palette.action.hoverOpacity),
                        },
                        // This not() increases specificity to make :active work on Mobile Safari
                        '&:not(:disabled):active': {
                            backgroundColor: alpha(theme.palette.action.active, theme.palette.action.activatedOpacity),
                            transition: theme.transitions.create('background-color', {
                                duration: theme.transitions.duration.short,
                            }),
                        },
                    },
                },
            },
            MuiInput: {
                defaultProps: {
                    disableUnderline: true,
                },
                styleOverrides: {
                    root: {
                        backgroundColor: perkThemeValues.inputBackground,
                        border: '2px solid transparent',
                        borderRadius: perkThemeValues.borderRadius,
                        transition: theme.transitions.create(['border-color', 'background-color'], {
                            duration: theme.transitions.duration.short,
                        }),
                        '&:hover:not(.Mui-disabled), &.Mui-focused': {
                            borderColor: theme.palette.common.black,
                        },
                        '&.Mui-focused': {
                            backgroundColor: theme.palette.common.white,
                        },
                    },
                    input: {
                        borderRadius: perkThemeValues.borderRadius - 2,
                        boxSizing: 'border-box',
                        minHeight: 56,
                        padding: theme.spacing(2),
                        // This allows the input's autofilled style to flow beneath the end adornment
                        '&.MuiInputBase-inputAdornedEnd': {
                            marginRight: -56,
                            paddingRight: 56,
                        },
                    },
                    formControl: {
                        'label + &': {
                            marginTop: theme.spacing(1),
                        },
                    },
                    multiline: {
                        paddingBottom: 0,
                        paddingTop: 0,
                    },
                    inputMultiline: {
                        // These padding values cause the text of the multiline textarea to
                        // match the vertical alignment of the default text input
                        paddingBottom: theme.spacing(2.1),
                        paddingTop: theme.spacing(2.4),
                    },
                },
            },
            MuiInputAdornment: {
                styleOverrides: {
                    positionEnd: {
                        marginRight: theme.spacing(0.5),
                    },
                    // Match the end adornment's icon hover and focus styles to the border
                    root: {
                        '& svg': {
                            transition: theme.transitions.create('color', {
                                duration: theme.transitions.duration.short,
                            }),
                        },
                        '.MuiInput-root:hover & svg, .Mui-focused & svg': {
                            color: theme.palette.text.primary,
                        },
                    },
                },
            },
            MuiInputLabel: {
                defaultProps: {
                    shrink: true,
                },
                styleOverrides: {
                    root: {
                        '&.Mui-error': {
                            color: 'unset',
                        },
                    },
                    shrink: {
                        position: 'relative',
                        whiteSpace: 'inherit',
                        transform: 'none',
                    },
                    formControl: {
                        // These properties are usually set to enable the label 'shrink' effect,
                        // which we've decided not to use.
                        top: undefined,
                        left: undefined,
                        position: undefined,
                        transform: undefined,
                    },
                },
            },
            MuiLink: {
                defaultProps: {
                    color: 'primary',
                },
                // styleOverrides: {
                //   root: {
                //     '&:link': {
                //       color: theme.palette.primary.main,
                //     },
                //     '&:visited': {
                //       color: theme.palette.primary.light,
                //     },
                //     '&:active': {
                //       color: theme.palette.primary.dark,
                //     },
                //   },
                // },
            },
            MuiList: {
                defaultProps: {
                    disablePadding: true,
                },
                styleOverrides: {
                    padding: {
                        paddingBottom: theme.spacing(2),
                        paddingTop: theme.spacing(2),
                    },
                },
            },
            MuiListItem: {
                styleOverrides: {
                    root: {
                        minHeight: theme.spacing(9),
                        '&.Mui-selected': {
                            backgroundColor: theme.palette.action.selected,
                        },
                    },
                    dense: {
                        minHeight: theme.spacing(7.5),
                    },
                    gutters: {
                        paddingLeft: theme.spacing(3),
                        paddingRight: theme.spacing(3),
                    },
                },
            },
            MuiListItemIcon: {
                styleOverrides: {
                    root: {
                        color: theme.palette.text.primary,
                        minWidth: `calc(${theme.spacing(2)} + 24px)`, // 24 = standard icon width
                    },
                },
            },
            MuiListItemSecondaryAction: {
                styleOverrides: {
                    root: {
                        right: theme.spacing(3),
                    },
                },
            },
            MuiMenu: {
                styleOverrides: {
                    paper: {
                        // Give dropdown menus a max-height of ~ half the page.
                        // Otherwise, the dropdowns can be hard to click out of
                        // on mobile because they take up the entire viewport.
                        maxHeight: '45vh',
                    },
                },
            },
            MuiSelect: {
                defaultProps: {
                    variant: 'standard',
                },
                styleOverrides: {
                    select: {
                        display: 'inline-flex',
                        alignItems: 'center',
                        minHeight: 56,
                    },
                },
            },
            MuiSkeleton: {
                defaultProps: {
                    animation: 'wave',
                },
            },
            MuiSwitch: {
                defaultProps: {
                    color: 'secondary',
                },
                styleOverrides: {
                    root: {
                        // Reducing the vertical padding increases the height of the track.
                        paddingTop: 11,
                        paddingBottom: 11,
                    },
                    thumb: {
                        boxShadow: perkThemeValues.boxShadow.switch,
                    },
                    track: {
                        backgroundColor: theme.palette.text.secondary,
                        borderRadius: 8,
                    },
                    switchBase: {
                        '&.Mui-focusVisible': {
                            backgroundColor: alpha(theme.palette.action.active, theme.palette.action.hoverOpacity),
                        },
                    },
                    /* The MUI Switch's default color is "secondary". */
                    colorSecondary: {
                        '&.Mui-checked': {
                            color: theme.palette.success.main,
                            '&:not(.Mui-disabled) + .MuiSwitch-track': {
                                backgroundColor: theme.palette.success.light,
                            },
                            '&:hover': {
                                backgroundColor: alpha(theme.palette.success.main, theme.palette.action.hoverOpacity + 0.05),
                            },
                            '&:active': {
                                backgroundColor: alpha(theme.palette.success.dark, theme.palette.action.activatedOpacity + 0.1),
                            },
                        },
                    },
                },
            },
            MuiTableCell: {
                styleOverrides: {
                    stickyHeader: {
                        backgroundColor: theme.palette.grey[50],
                    },
                },
            },
            MuiTextField: {
                defaultProps: {
                    variant: 'standard',
                    InputLabelProps: {
                        shrink: true,
                    },
                },
            },
            MuiToolbar: {
                defaultProps: {
                    disableGutters: true,
                },
            },
            MuiTouchRipple: {
                styleOverrides: {
                    child: {
                        // give the ripple a light primary-theme color
                        backgroundColor: theme.palette.primary.light,
                    },
                    ripple: {
                        animation: 'none',
                    },
                    rippleVisible: {
                        animation: 'none',
                        opacity: 0.08,
                    },
                },
            },
        },
        shape: {
            borderRadius: perkThemeValues.borderRadius,
        },
        typography: {
            /* Note: body2 is defaulted to the textSecondary color by the Typography
            component. We can't set that color here, because body2's color is applied to
            the entire document */
            fontFamily: theme.typography.fontFamily
                ? theme.typography.fontFamily
                : ['Roboto', 'sans-serif'].join(','),
            button: {
                fontWeight: theme.typography.fontWeightBold,
                textTransform: 'none',
            },
            h1: {
                fontSize: '2.5rem',
                fontWeight: theme.typography.fontWeightBold,
            },
            h2: {
                fontSize: '2rem',
                fontWeight: theme.typography.fontWeightBold,
            },
            h3: {
                fontSize: '1.5rem',
                fontWeight: theme.typography.fontWeightBold,
            },
            h5: {
                fontSize: '1rem',
                fontWeight: theme.typography.fontWeightBold,
            },
            body1: {
                lineHeight: '1.3',
            },
        },
    };
    // return createTheme(perkThemeOptions, adaptV4Theme(customizations || {}))
    return createTheme(perkThemeOptions, customizations || {});
};
export default createPerkTheme;
