import { useMutation, useQueryClient } from 'react-query'

import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Site } from './useSitesAvailable'

interface ClinicSiteCreateResponse {
  site: Site
}
interface ClinicSiteCreateBody {
  name: string
  country: string
  timeZone: string
  specialty: string[]
  street: string
  unitApartmentSuite: string
  city: string
  stateProvince: string
  zipCode: string
  phone: string
}

const useCreateClinicSite = () => {
  const queryClient = useQueryClient()
  const url = '/sites/sites'

  return useMutation((vars: ClinicSiteCreateBody) =>
    request
      .post<ClinicSiteCreateResponse>(url, {
        site: {
          ...vars,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.sitesAvailable())
        queryClient.invalidateQueries(QueryKeys.onboardingStep(r.data.site.id))
        return r.data
      }),
  )
}

export default useCreateClinicSite
