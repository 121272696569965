import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Pagination } from './types'
import { SurveyResponse, SurveyResponseOwner } from './useSurveyResponse'

export interface SurveyResponseSearchResponse {
  surveyResponses: Omit<SurveyResponse, 'surveyResponseAnswer'>[]
  meta: Pagination
}

export interface SurveyResponseSearchOptions {
  ownerIds?: string[]
  ownerType?: SurveyResponseOwner
  surveySlugs?: string[]
  includes?: string[]
  orderDirection?: 'asc' | 'desc'
  perPage?: string
  completed?: boolean
  siteId?: string
}

const useSurveyResponseSearch = (
  {
    ownerIds,
    ownerType,
    surveySlugs = [],
    includes = [],
    orderDirection,
    perPage,
    completed,
    siteId,
  }: SurveyResponseSearchOptions,
  extraOptions: UseQueryOptions<SurveyResponseSearchResponse> = {},
) => {
  if (!siteId) siteId = useCurrentSite().id

  const key = QueryKeys.surveyResponseSearch({
    ownerIds,
    ownerType,
    surveySlugs,
    siteId,
    includes,
    orderDirection,
    perPage,
    completed,
  })

  const urlBase = `/sites/survey_responses?site_ids[]=${siteId}`
  const ownerIdPart = (ownerIds || [])
    .filter(Boolean)
    .map((ownerId) => `&owner_ids[]=${ownerId}`)
    .join('')
  const ownerTypePart = ownerIdPart && `&owner_type=${ownerType}`

  const slugPart = surveySlugs
    .filter(Boolean)
    .map((slug) => `&survey_slugs[]=${slug}`)
    .join('')

  const includesPart = includes
    .filter(Boolean)
    .map((include) => `&includes[]=${include}`)
    .join('')
  const url = urlBase + ownerIdPart + ownerTypePart + slugPart + includesPart

  let isEnabled = true
  if (ownerIds || ownerType) {
    isEnabled = Boolean((ownerIds || []).length > 0 && ownerType)
  }
  if (isEnabled && surveySlugs.length > 0) {
    isEnabled = Boolean(slugPart)
  }

  return useQuery(
    key,
    () =>
      request
        .get<SurveyResponseSearchResponse>(url, {
          params: {
            order_direction: orderDirection,
            per_page: perPage,
            completed: completed,
          },
        })
        .then((r) => r.data),
    {
      enabled: Boolean(isEnabled),
      ...extraOptions,
    },
  )
}

export default useSurveyResponseSearch
