import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export interface ScoreDefinition {
  id?: string
  primary?: boolean
  scoreRange?: {
    max?: number
    min?: number
  }
  slug?: string
  surveySlug?: string
  legend?: string
}

export interface SurveyScoreDefinitionsResponse {
  surveyScoreDefinitions: ScoreDefinition[]
}

const useSurveyScoreDefinitions = (
  extraOptions: UseQueryOptions<SurveyScoreDefinitionsResponse> = {},
) => {
  const currentSiteId = useCurrentSite().id
  const siteIds = currentSiteId

  const key = QueryKeys.surveyScoreDefinitions()
  const url = `/sites/survey_score_definitions?site_ids[]=${siteIds}`

  return useQuery(
    key,
    () => request.get<SurveyScoreDefinitionsResponse>(url).then((r) => r.data),
    {
      ...extraOptions,
    },
  )
}

export default useSurveyScoreDefinitions
