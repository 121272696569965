import { Body1, Button, Divider, H3, makeStyles } from '@perk-ui/core'
import { useHistory } from 'react-router'

import useDeleteProtocol from '../../../features/query-hooks/useDeleteProtocol'
import useDeleteProtocolDiagnosis from '../../../features/query-hooks/useDeleteProtocolDiagnosis'
import useDeleteProtocolTreatment from '../../../features/query-hooks/useDeleteProtocolTreatment'
import useProtocol from '../../../features/query-hooks/useProtocol'
import useProtocolDiagnosis from '../../../features/query-hooks/useProtocolDiagnosis'
import useProtocolTreatment from '../../../features/query-hooks/useProtocolTreatment'

const useStyles = makeStyles((theme) => ({
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  nameText: {
    marginBottom: theme.spacing(2),
  },
  boldNameText: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  reasonLabel: {
    marginBottom: theme.spacing(1),
  },
  reasonSelect: {
    marginBottom: theme.spacing(2),
  },
  xButton: {
    padding: 0,
  },
  archiveButtonRow: {
    textAlign: 'end',
  },
  archiveButtonAndTextRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  cantBeUndoneText: {
    color: 'red',
    fontWeight: 'bold',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  redText: {
    color: 'red',
    marginBottom: theme.spacing(2),
  },
}))

export interface ArchiveModalProps {
  protocolId?: string
  protocolDiagnosisId?: string
  protocolTreatmentId?: string
  toggle: () => void
}

const ArchiveModal: React.FC<ArchiveModalProps> = ({
  protocolId,
  protocolDiagnosisId,
  protocolTreatmentId,
  toggle,
}) => {
  const classes = useStyles()

  const { data: protocol } = useProtocol(protocolId || '')
  const { data: protocolDiagnosis } = useProtocolDiagnosis(
    protocolDiagnosisId || '',
  )
  const { data: protocolTreatment } = useProtocolTreatment(
    protocolTreatmentId || '',
  )

  const deleteProtocol = useDeleteProtocol()
  const deleteProtocolDiagnosis = useDeleteProtocolDiagnosis()
  const deleteProtocolTreatment = useDeleteProtocolTreatment()

  const { push } = useHistory()

  const protocolName = protocol ? protocol.name : ''
  const protocolDiagnosisName = protocolDiagnosis
    ? protocolDiagnosis.medicalCondition?.name
    : ''
  const protocolTreatmentName = protocolTreatment
    ? protocolTreatment.medicalTreatment?.name
    : ''

  const archivalType = protocolId
    ? 'protocol'
    : protocolDiagnosisId
    ? 'diagnosis'
    : 'treatment'

  const typeString = protocolId
    ? protocolName
    : protocolDiagnosisId
    ? protocolDiagnosisName
    : protocolTreatmentName

  const handleProtocolRemove = () => {
    if (protocolId) {
      return deleteProtocol.mutateAsync(protocolId).then(() => {
        push('/home')
      })
    }
  }

  const handleProtocolDiagnosisRemove = () => {
    if (protocolDiagnosisId) {
      return deleteProtocolDiagnosis
        .mutateAsync(protocolDiagnosisId)
        .then(() => {
          push('/home')
        })
    }
  }

  const handleProtocolTreatmentRemove = () => {
    if (protocolTreatmentId) {
      return deleteProtocolTreatment
        .mutateAsync(protocolTreatmentId)
        .then(() => {
          push('/home')
        })
    }
  }

  return (
    <>
      <div className={classes.titleRow}>
        <H3>{`Remove ${archivalType} (${typeString})`}</H3>
      </div>
      <Body1 className={classes.nameText}>
        {`Everything related to this ${archivalType} will be deleted`}
      </Body1>
      <Divider className={classes.divider} />
      <div className={classes.archiveButtonRow}>
        <Button
          size="large"
          onClick={() => {
            return archivalType === 'protocol'
              ? handleProtocolRemove()
              : archivalType === 'diagnosis'
              ? handleProtocolDiagnosisRemove()
              : handleProtocolTreatmentRemove()
          }}
        >
          Remove
        </Button>
      </div>
      <Button size="small" variant="text" onClick={() => toggle()}>
        Cancel
      </Button>
    </>
  )
}

export default ArchiveModal
