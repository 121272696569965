import { useQuery } from 'react-query'

import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Subscription } from './useSubscription'

export interface SubscriptionResponse {
  subscription: Subscription
}

const useUnauthenticatedSubscription = (token: string) => {
  const key = QueryKeys.unauthenticatedSubscription()
  const url = `/sites/subscription_unauth?s=${token}`

  return useQuery(
    key,
    () => request.get<SubscriptionResponse>(url).then((r) => r.data),
    {
      // refetchInterval: 0,
      staleTime: Number.POSITIVE_INFINITY,
    },
  )
}

export default useUnauthenticatedSubscription
