import { FC, memo } from 'react'
import { Box, Toolbar } from '@perk-ui/core'
import { Route, Switch, useLocation } from 'react-router'

import { pathsWithoutHeader } from '../components/AppHeader'
import PrivateRoute from '../components/PrivateRoute'
import config from '../config/app-config'
import AnimatedRedirect from '../features/animation/AnimatedRedirect'
import { useAuth } from '../features/auth/AuthContext'
import ActivateAccount from './ActivateAccount/ActivateAccount'
import Adjudication from './AdverseEvents/Adjudication/Adjudication'
import AdverseEvents from './AdverseEvents/AdverseEvents'
import AccountVerification from './Authentication/AccountVerification/AccountVerification'
import NewActivateAccount from './Authentication/ActivateAccount/ActivateAccount'
import NewForgotPassword from './Authentication/ForgotPassword/ForgotPassword'
import NewLogin from './Authentication/Login/Login'
import NewPasswordReset from './Authentication/PasswordReset/PasswordReset'
import NewRegister from './Authentication/Register/Register'
import NewResendActivationLink from './Authentication/ResendActivationLink/ResendActivationLink'
import Data from './Data/Data'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import Home from './Home/Home'
// import AdverseEventOverview from './Insights/AdverseEventOverview'
// import EssentialsExplorer from './Insights/EssentialsExplorer'
import Insights from './Insights/Insights'
// import PerformanceExplorer from './Insights/PerformanceExplorer'
// import ReportBuilder from './Insights/ReportBuilder'
// import StarterExplorer from './Insights/StarterExplorer'
import Login from './Login/Login'
import Onboarding from './Onboarding/Onboarding'
import UnauthenticatedAgreementSignature from './Onboarding/steps/UnauthenticatedContractSignature'
import UnauthenticatedPayment from './Onboarding/steps/UnauthenticatedPayment'
import UnauthenticatedSigncontractDone from './Onboarding/UnauthenticatedSignContractDone.tsx'
import PasswordReset from './PasswordReset/PasswordReset'
import PatientCaseSurveys from './PatientCaseSurveys/PatientCaseSurveys'
import PatientDetails from './PatientDetails/PatientDetails'
import PatientFlow from './PatientFlow/PatientFlow'
import PatientFlowUrls from './PatientFlow/PatientFlowUrls'
import Patients from './Patients/Patients'
import PatientsCD from './PatientsCD/PatientsCD'
import ProtocolFlow from './ProtocolFlow/ProtocolFlow'
import ProtocolFlowUrls from './ProtocolFlow/ProtocolFlowUrls'
import ResendActivationLink from './ResendActivationLink/ResendActivationLink'

const Routes: FC = memo(() => {
  const { isAuthenticated } = useAuth()
  const { pathname, search } = useLocation()
  const isCurrentLocation = (path: string) => pathname.startsWith(path)
  const showHeader = !pathsWithoutHeader.some(isCurrentLocation)

  return (
    <Box component="main" sx={{ flexGrow: 1 }}>
      {showHeader && <Toolbar style={{ height: '64px' }} />}
      <Switch>
        {/**
         * Authentication (OLD)
         */}
        <Route path={config.unAuthenticatedRootUrl} component={Login} />
        <Route path="/forgot-password" component={ForgotPassword} />
        <Route path="/password-reset/:token" component={PasswordReset} />
        <Route path="/activate-account/:token" component={ActivateAccount} />
        <Route
          path="/resend-activation-link"
          component={ResendActivationLink}
        />

        {/**
         * Authentication (NEW)
         */}
        {!__PROD__ && <Route path="/create-account" component={NewRegister} />}
        {!__PROD__ && (
          <Route
            path="/account-verification/:token"
            component={AccountVerification}
          />
        )}
        {!__PROD__ && <Route path="/login-new" component={NewLogin} />}
        {!__PROD__ && (
          <Route path="/forgot-password-new" component={NewForgotPassword} />
        )}
        {!__PROD__ && (
          <Route
            path="/password-reset-new/:token"
            component={NewPasswordReset}
          />
        )}
        {!__PROD__ && (
          <Route
            path="/activate-account-new/:token"
            component={NewActivateAccount}
          />
        )}
        {!__PROD__ && (
          <Route
            path="/resend-activation-link-new"
            component={NewResendActivationLink}
          />
        )}

        {/**
         * Onboarding
         */}
        {!__PROD__ && (
          <PrivateRoute path="/onboarding" component={Onboarding} />
        )}
        {!__PROD__ && (
          <Route
            path="/sign-contract/thank-you"
            render={() => <UnauthenticatedSigncontractDone type="thank-you" />}
          />
        )}
        {!__PROD__ && (
          <Route
            path="/sign-contract"
            component={UnauthenticatedAgreementSignature}
          />
        )}
        {!__PROD__ && (
          <Route
            path="/payment/thank-you"
            render={() => <UnauthenticatedSigncontractDone type="thank-you" />}
          />
        )}
        {!__PROD__ && (
          <Route path="/payment" component={UnauthenticatedPayment} />
        )}

        {/**
         * Home
         */}
        <PrivateRoute path="/home" component={Home} />

        {/**
         * Patient Flow
         */}
        <PrivateRoute
          path={PatientFlowUrls.base.match}
          component={PatientFlow}
        />

        {/**
         * Patients
         */}
        <PrivateRoute
          path="/patients/:patientId/patient-case-surveys/:caseId"
          component={PatientCaseSurveys}
        />
        <PrivateRoute path="/patients/:patientId" component={PatientDetails} />
        <PrivateRoute path="/patientsCD" component={Patients} />
        <PrivateRoute path="/patients" component={PatientsCD} />
        {/**
         * Adverse Events
         */}
        <PrivateRoute
          path="/adverse-events/:adverseEventId"
          component={Adjudication}
        />
        <PrivateRoute path="/adverse-events" component={AdverseEvents} />

        {/**
         * Data
         */}
        <PrivateRoute path="/data" component={Data} />

        {/* *
         * Insight essentials
         */}
        {/* <PrivateRoute
          path="/essentials-explorer"
          component={EssentialsExplorer}
        /> */}

        {/**
         * Insight performance
         */}
        {/* <PrivateRoute
          path="/performance-explorer"
          component={PerformanceExplorer}
        /> */}

        {/**
         * Insight adverse events
         */}
        {/* <PrivateRoute
          path="/adverse-event-overview"
          component={AdverseEventOverview}
        /> */}

        {/**
         * Insight report builder
         */}
        {/* <PrivateRoute path="/report-builder" component={ReportBuilder} /> */}

        {/**
         * Insights Starter
         */}
        {/* <PrivateRoute path="/starter-explorer" component={StarterExplorer} /> */}

        {/* New Insights */}
        <PrivateRoute path="/my-impact" component={Insights} />
        <PrivateRoute path="/my-teams" component={Insights} />
        <PrivateRoute path="/my-studies" component={Insights} />
        <PrivateRoute path="/raw-data" component={Insights} />

        {/**
         * Protocol Flow
         */}
        <PrivateRoute
          path={ProtocolFlowUrls.base.match}
          component={ProtocolFlow}
        />

        {/**
         * 404
         */}

        {/**
         * Catch-all
         */}
        <Route
          path="*"
          render={() => (
            <AnimatedRedirect
              to={{
                pathname: isAuthenticated
                  ? config.authenticatedRootUrl
                  : config.unAuthenticatedRootUrl,
                search: search,
              }}
            />
          )}
        />
      </Switch>
    </Box>
  )
})

export default Routes
