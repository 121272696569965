import MuiIconButton from '@mui/material/IconButton';
import { shouldForwardProp as baseShouldForwardProp } from '@mui/system';
import { alpha, styled } from '../';
const shouldForwardProp = (prop) => baseShouldForwardProp(prop) && prop !== 'variant'; // prevents the prop from spreading to DOM
const IconButton = styled(MuiIconButton, {
    shouldForwardProp,
})(({ variant, theme }) => {
    if (variant === 'always-hover') {
        return {
            backgroundColor: alpha(theme.palette.action.hover, theme.palette.action.hoverOpacity),
            '&:hover': {
                backgroundColor: alpha(theme.palette.action.selected, theme.palette.action.selectedOpacity),
            },
            '&:active': {
                backgroundColor: alpha(theme.palette.action.active, theme.palette.action.activatedOpacity),
            },
        };
    }
});
export default IconButton;
