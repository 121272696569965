var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import CssBaseline from '@mui/material/CssBaseline';
import { createPerkTheme, StyledEngineProvider, ThemeProvider, } from '../';
export const DefaultPerkTheme = createPerkTheme({
    palette: {
        primary: {
            main: '#0166d7',
        },
        secondary: {
            main: '#008000',
        },
        success: {
            main: '#008000',
        },
        error: {
            main: '#B30000',
        },
    },
});
const PerkThemeProvider = (_a) => {
    var { children, theme = DefaultPerkTheme } = _a, rest = __rest(_a, ["children", "theme"]);
    return (_jsx(StyledEngineProvider, Object.assign({ injectFirst: true }, { children: _jsxs(ThemeProvider, Object.assign({ theme: theme }, rest, { children: [_jsx(CssBaseline, {}, void 0), children] }), void 0) }), void 0));
};
export default PerkThemeProvider;
