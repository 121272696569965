var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Body1, H3, Link, makeStyles } from '../';
const useStyles = makeStyles((theme) => ({
    root: {
        width: theme.spacing(41),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly',
        backgroundColor: '#fff',
        borderRadius: 10,
        boxShadow: theme.perk.boxShadow.card,
        padding: theme.spacing(4),
    },
    title: {
        marginBottom: theme.spacing(2),
    },
    password: {
        marginTop: theme.spacing(2),
    },
    links: {
        marginTop: theme.spacing(3),
        textAlign: 'center',
    },
    spacer: {
        marginBottom: theme.spacing(3),
    },
}), { name: 'PasswordResetContainer' });
const PasswordResetContainer = (_a) => {
    var { title, MessagesComponent, PasswordComponent, ConfirmPasswordComponent, ButtonComponent, loginURL, className } = _a, rest = __rest(_a, ["title", "MessagesComponent", "PasswordComponent", "ConfirmPasswordComponent", "ButtonComponent", "loginURL", "className"]);
    const classes = useStyles();
    return (_jsxs("div", Object.assign({ className: clsx(classes.root, className) }, rest, { children: [_jsx(H3, Object.assign({ className: classes.title }, { children: title || 'Reset Password' }), void 0), MessagesComponent && (_jsxs(_Fragment, { children: [MessagesComponent, _jsx("div", { className: classes.spacer }, void 0)] }, void 0)), PasswordComponent, ConfirmPasswordComponent, ButtonComponent, loginURL && (_jsx(Body1, Object.assign({ color: "textSecondary", className: classes.links }, { children: loginURL && (_jsx(Link, Object.assign({ href: loginURL, underline: "hover" }, { children: "Log In" }), void 0)) }), void 0))] }), void 0));
};
export default PasswordResetContainer;
