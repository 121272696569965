import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { ProtocolTreatment } from './useProtocols'

interface UpdateTreatmentResponse {
  protocolTreatment: ProtocolTreatment
}

export interface UpdateTreatmentVars extends ProtocolTreatment {
  protocolId: string
}

const useUpdateProtocolTreatment = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation(({ protocolId, ...vars }: UpdateTreatmentVars) => {
    const url = `/sites/protocol_treatments/${vars.id}`

    return request
      .patch<UpdateTreatmentResponse>(url, {
        siteIds: [siteId],
        protocolTreatment: {
          protocolId: protocolId,
          ...vars,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.protocols({ clinicId: siteId }))
        queryClient.invalidateQueries(
          QueryKeys.protocolTreatment(r.data.protocolTreatment.id),
        )

        queryClient.invalidateQueries(QueryKeys.protocol(protocolId || ''))

        return r.data.protocolTreatment
      })
  })
}

export default useUpdateProtocolTreatment
