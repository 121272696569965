import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { PatientTreatment } from './usePatientTreatment'

interface UpdateTreatmentResponse {
  patientTreatment: PatientTreatment
}

export interface UpdateTreatmentVars extends PatientTreatment {}

const useUpdateTreatment = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation((vars: UpdateTreatmentVars) => {
    const url = `/sites/patient_treatments/${vars.id}`

    return request
      .patch<UpdateTreatmentResponse>(url, {
        siteIds: [siteId],
        patientTreatment: {
          siteId: siteId,
          ...vars,
          // Remove if undefined
          researchStudyNumber: vars.researchStudyNumber
            ? vars.researchStudyNumber
            : undefined,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.patientCases({}))
        queryClient.invalidateQueries(
          QueryKeys.patientTreatment(r.data.patientTreatment.id),
        )

        return r.data.patientTreatment
      })
  })
}

export default useUpdateTreatment
