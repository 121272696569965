declare global {
  // eslint-disable-next-line @typescript-eslint/no-namespace
  namespace NodeJS {
    interface ProcessEnv {
      // All keys in process.env are string key/value pairs by default
      // Let's use our knowledge of the env var to narrow the signature of
      // REACT_APP_STAGE. There is code below that checks that one of these
      // keys are used.
      REACT_APP_STAGE: 'dev' | 'staging' | 'production' | 'test'
    }
  }

  /**
   * These variables are accessible either via the ambient global context
   * (`globalThis`), or directly through the `window`
   *
   * i.e. `__DEV__` and `window.__DEV__` are the same thing
   */
  /* eslint-disable no-var */
  var __DEV__: boolean
  var __STAGING__: boolean
  var __PROD__: boolean
  var __TEST__: boolean
  /* eslint-enable no-var */
}

globalThis.__DEV__ = false
globalThis.__STAGING__ = false
globalThis.__PROD__ = false
globalThis.__TEST__ = false

switch (process.env.REACT_APP_STAGE) {
  case 'production':
    __PROD__ = true
    break
  case 'dev':
    __DEV__ = true
    break
  case 'staging':
    __STAGING__ = true
    break
  case 'test':
    __TEST__ = true
    break
  default:
    // If we don't recognize the stage, it's likely a symptom of a mis-configured
    // system. Bail.
    throw new Error(`Unknown REACT_APP_STAGE "${process.env.REACT_APP_STAGE}"`)
}

// Make TS happy with this side-effect full file.
export {}
