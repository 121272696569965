import { FC, Fragment, useEffect, useState } from 'react'
import {
  Alert,
  alpha,
  Autocomplete,
  Avatar,
  Body1,
  Box,
  Button,
  CardHeader,
  Chip,
  DialogTrigger,
  Divider,
  Grid,
  H1,
  H3,
  Icon,
  IconButton,
  makeStyles,
  styled,
  Switch,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tabs,
  TextField,
  Tooltip,
} from '@perk-ui/core'
import clsx from 'clsx'
import { useFormik } from 'formik'
import * as _ from 'lodash'
import { matchSorter } from 'match-sorter'
import { Info, Trash2, X } from 'react-feather'
import * as yup from 'yup'

import { useAuth, useUser } from '../../../features/auth/AuthContext'
import { StaffRole } from '../../../features/query-hooks/types'
import useClinicStaffs from '../../../features/query-hooks/useClinicStaffs'
import useCreateAccount from '../../../features/query-hooks/useCreateAccount'
import { Staff } from '../../../features/query-hooks/useCurrentUser'
import useDeactivateAccount from '../../../features/query-hooks/useDeactivateAccount'
import useNpiValidation from '../../../features/query-hooks/useNpiValidation'
import useProductsAvailable, {
  SubscriptionProduct,
} from '../../../features/query-hooks/useProductsAvailable'
import useUpdateOnboardingStep from '../../../features/query-hooks/useUpdateOnboardingStep'
import useUpdateStaff from '../../../features/query-hooks/useUpdateStaff'
// import { useAuth } from '../../../features/auth/AuthContext'
import { useHistory } from '../../../utils/useHistory'

interface ClinicUsersStepProps {
  // currentStep: number
  // setCurrentStep: Dispatch<SetStateAction<number>>
}

interface FormAlertProp {
  type: 'error' | 'success'
  message: string
}
interface ClinicUsersStepAddAccountFormValues {
  firstName: string
  lastName: string
  email: string
  type: string
  subscriptionPlan?: 'essentials' | 'performance' | 'demo' | 'starter'
  npi?: string
  specialty?: string[]
  title?: string
}

const useStyles = makeStyles((theme) => ({
  form: {
    '& .MuiFormHelperText-root': {
      textAlign: 'center',
    },
  },
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    overflow: 'hidden',
  },
  sectionContainer: {
    height: '100%',
    flex: '1 1 auto',
    overflow: 'auto',
    paddingTop: theme.spacing(2),
  },
  tabsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    flex: '0 1 auto',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('md')]: {
      display: 'ruby',
    },
  },
  accountTypeTabs: {
    border: '1px solid #f1f1f1',
    borderRadius: '16px',
    padding: theme.spacing(1),
    '& .MuiTabs-flexContainer': {
      '& button.Mui-selected': {
        borderRadius: '8px',
        backgroundColor: '#E9F7FC', // primary.light?
      },
      '& button': {
        minHeight: '24px',
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
    [theme.breakpoints.down('md')]: {
      marginBottom: theme.spacing(1),
    },
  },
  productChip: {
    borderRadius: '20px',
    marginLeft: theme.spacing(1),
    '& .MuiChip-label': {
      '& span': {
        display: 'flex',
        alignItems: 'center',
        fontSize: '16px',
        '& b': {
          fontSize: '20px',
          marginRight: theme.spacing(1),
        },
      },
    },
  },
  clinicStaffAlert: {
    borderRadius: '12px',
    fontSize: '16px',
    color: '#9F8821',
    backgroundColor: '#FFFBEB',
    padding: theme.spacing(0, 1.5),
  },
  createAccountContainer: {},
  usersTableContainer: {
    overflow: 'hidden',
    padding: theme.spacing(2, 0),
    '& .MuiCardHeader-root': {
      padding: 0,
    },
    '& thead': {
      '& th': {
        backgroundColor: theme.palette.common.white,
        color: theme.palette.common.black,
        fontSize: '16px',
        fontWeight: 400,
      },
    },
    '& tbody': {
      '& td': {
        padding: theme.spacing(1),
        borderBottom: 'none',
        '& .MuiInputBase-root': {
          borderRadius: '16px',
          '& input': {
            minHeight: '40px',
            height: '40px',
          },
        },
      },
    },
  },
  avatarIcon: {
    backgroundColor: '#526CEB',
  },
  inactiveAvatar: {
    border: 'dashed',
    color: '#A3A3A3',
    backgroundColor: 'transparent',
  },
  dialogContent: {},
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  nameText: {
    marginBottom: theme.spacing(2),
  },
  xButton: {
    padding: 0,
  },
  deactivateAccountButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  iconTitle: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing(0.5),
    },
  },
}))
const ClinicUsersStep: FC<ClinicUsersStepProps> = () => {
  // const { signIn } = useAuth()
  const classes = useStyles()
  const { push } = useHistory()
  const { data: clinicStaffs } = useClinicStaffs()
  const currentUser = useUser()
  const { data: subscriptionProducts } = useProductsAvailable()
  const [accountType, setAccountType] = useState('provider')
  const [userList, setUserList] = useState<Staff[]>([])
  const [formAlert, setFormAlert] = useState<FormAlertProp>({
    type: 'error',
    message: '',
  })
  const updateOnboardingStep = useUpdateOnboardingStep()
  const addUser = useCreateAccount()
  const updateUser = useUpdateStaff()
  const deactivateAccount = useDeactivateAccount()

  const pushNextOnboardingStep = () => {
    // Pending: clinic validations (make sure clinic has at least 1 provider with valid NPI)
    updateOnboardingStep
      .mutateAsync({
        onboardingStep: 'done',
      })
      .then(() => {
        push('/home')
      })
  }

  const accountTypes = [
    {
      key: 'provider',
      label: 'Provider',
      // icon: <Type />,
      disabled: false,
    },
    {
      key: 'clinic-staff',
      label: 'Clinic Staff',
      // icon: <Camera />,
      disabled: false,
    },
  ]

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setAccountType(newValue)
  }
  useEffect(() => {
    const users =
      clinicStaffs?.filter((user) => {
        return (
          user.type === (accountType == 'provider' ? 'Provider' : 'ClinicStaff')
        )
      }) || []
    if (users !== userList) setUserList(users)
  }, [accountType, clinicStaffs])

  const CreateAccountEl: FC = () => {
    const validateNpi = useNpiValidation()
    const { requestPasswordReset } = useAuth()
    const [previousNpiValidated, setPreviousNpiValidated] = useState('')
    const [isPreviousNpiValid, setIsPreviousNpiValid] = useState(false)
    const clinicUsersValidations = yup.object({
      firstName: yup.string().required('First name is required'),
      lastName: yup.string().required('Last name is required'),
      email: yup
        .string()
        .email('Ensure the email is formatted correctly')
        .required('Email is required'),

      type: yup.string().required('Last name is required'),
      subscriptionPlan: yup
        .string()
        .when('type', {
          is: (type: string) => type === 'Provider',
          then: yup.string().required('Subscription is required.'),
        })
        .nullable(),
      npi: yup
        .string()
        .when('type', {
          is: (type: string) => type === 'Provider',
          then: yup
            .string()
            .min(10, 'NPI must be 10 digits')
            .max(10, 'NPI must be 10 digits')
            .required('NPI is required.')
            .test('valid-npi', 'Invalid NPI Number', (value = '') =>
              value && value !== previousNpiValidated
                ? NpiVerification(value)
                : isPreviousNpiValid,
            ),
        })
        .nullable(),
    })
    const canAdd =
      accountType === 'clinic-staff'
        ? true
        : (
            subscriptionProducts?.filter(
              (prod) => prod.usedQuantity < prod.quantity,
            ) || []
          ).length > 0
    const {
      values,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      // setFieldError,
      setFieldValue,
      // setTouched,
      isSubmitting,
      // isValid,
      touched,
    } = useFormik<ClinicUsersStepAddAccountFormValues>({
      enableReinitialize: true,
      initialValues: {
        firstName: '',
        lastName: '',
        email: '',
        type: accountType == 'provider' ? 'Provider' : 'ClinicStaff',
        subscriptionPlan: 'starter',
        npi: '',
        specialty: [],
        title: '',
      },
      validationSchema: clinicUsersValidations,
      onSubmit: (values) => {
        const userType: StaffRole =
          accountType == 'provider' ? 'Provider' : 'ClinicStaff'
        return addUser
          .mutateAsync({
            ...values,
            password: 'Secret',
            type: userType,
            needsVerify: true,
          })
          .catch((err) => {
            if (err.response.data.keratin) {
              setFormAlert({
                message: err.response.data.keratin.message,
                type: 'error',
              })
            } else if (
              err.response.data.errors.npi &&
              err.response.data.errors.npi[0] == 'has already been taken'
            ) {
              setFormAlert({
                message: 'NPI number already taken.',
                type: 'error',
              })
            } else {
              setFormAlert({
                message:
                  'An error ocurred creating the account. Please try again',
                type: 'error',
              })
            }
            throw err
          })
          .then(() => {
            setFormAlert({
              message: 'Account created successfully',
              type: 'success',
            })
            setTimeout(() => {
              setFormAlert({ message: '', type: 'error' })
            }, 3000)
            return requestPasswordReset(values.email)
          })
      },
    })

    const NpiVerification = (npi: string) => {
      if (npi.length !== 10) return false
      return validateNpi
        .mutateAsync({
          npi: npi,
        })
        .then((res) => {
          setPreviousNpiValidated(npi)
          if (res.resultCount > 0) {
            const responseProvider = res.results[0].basic
            setFieldValue(
              'firstName',
              _.startCase(_.lowerCase(responseProvider.firstName)),
            )
            setFieldValue(
              'lastName',
              _.startCase(_.lowerCase(responseProvider.lastName)),
            )
            setFieldValue('title', responseProvider.credential)
            setFieldValue(
              'specialty',
              res.results[0].taxonomies.map((taxonomy) => {
                return taxonomy.desc
              }),
            )
            setIsPreviousNpiValid(true)
            return true
          } else {
            setFieldValue('firstName', '')
            setFieldValue('lastName', '')
            setFieldValue('title', '')
            setFieldValue('specialty', [])
            setIsPreviousNpiValid(false)
            return false
          }
        })
        .catch(() => false)
    }
    return (
      <Box className={classes.createAccountContainer}>
        <form onSubmit={handleSubmit} name="add-clinic-user-form">
          <Grid container spacing={2}>
            {accountType == 'provider' && (
              <Grid item xs={12} sm={4}>
                <TextField
                  label="NPI"
                  name="npi"
                  placeholder="Enter NPI"
                  type={'number'}
                  fullWidth
                  value={values.npi}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.npi && Boolean(errors.npi)}
                  helperText={touched.npi && errors.npi}
                  inputProps={{ size: 10 }}
                />
              </Grid>
            )}
            <Grid item xs={12} sm={accountType == 'provider' ? 4 : 6}>
              <TextField
                label="First Name"
                name="firstName"
                required
                disabled={accountType == 'provider'}
                fullWidth
                value={values.firstName}
                onChange={handleChange}
                error={touched.firstName && Boolean(errors.firstName)}
                helperText={touched.firstName && errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={accountType == 'provider' ? 4 : 6}>
              <TextField
                label="Last Name"
                name="lastName"
                required
                disabled={accountType == 'provider'}
                fullWidth
                value={values.lastName}
                onChange={handleChange}
                error={touched.lastName && Boolean(errors.lastName)}
                helperText={touched.lastName && errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                label="Email"
                name="email"
                required
                fullWidth
                value={values.email}
                onChange={(e) =>
                  setFieldValue('email', e.target.value.toLowerCase())
                }
                error={touched.email && Boolean(errors.email)}
                helperText={touched.email && errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              {accountType == 'provider' && (
                <Autocomplete
                  options={
                    subscriptionProducts?.filter(
                      (prod: SubscriptionProduct) =>
                        prod.usedQuantity < prod.quantity,
                    ) || []
                  }
                  getOptionLabel={(opt) => opt.stripeProduct?.name || ''}
                  filterOptions={(options, { inputValue }) =>
                    inputValue
                      ? matchSorter(options, inputValue, {
                          keys: ['stripeProduct.name'],
                        })
                      : options
                  }
                  onChange={(_event, val) => {
                    setFieldValue(
                      'subscriptionPlan',
                      val?.stripeProduct?.metadata.systemValue,
                    )
                  }}
                  value={
                    (subscriptionProducts || []).find(
                      (prod: SubscriptionProduct) =>
                        prod.stripeProduct?.metadata.systemValue ===
                        values.subscriptionPlan,
                    ) ?? null
                  }
                  renderInput={(params) => {
                    // @ts-expect-error Mui's autocomplete types here are incomplete
                    params.InputLabelProps.shrink = true
                    return (
                      <TextField
                        required
                        {...params}
                        name="subscriptionPlan"
                        label="Subscription"
                        onBlur={handleBlur}
                        InputProps={{
                          ...params.InputProps,
                        }}
                        error={
                          touched.subscriptionPlan &&
                          Boolean(errors.subscriptionPlan)
                        }
                        helperText={
                          touched.subscriptionPlan && errors.subscriptionPlan
                        }
                      />
                    )
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {formAlert.message && (
                <Alert
                  severity={formAlert.type}
                  fade
                  grow
                  onClose={() => {
                    setFormAlert({ type: 'error', message: '' })
                  }}
                >
                  {formAlert.message}
                </Alert>
              )}
              <Button type="submit" disabled={isSubmitting || !canAdd}>
                Send Invite
              </Button>
            </Grid>
          </Grid>
        </form>
      </Box>
    )
  }

  const UserTableEl: FC = () => {
    const AdminSwitch = styled(Switch)(({ theme }) => ({
      '& .MuiSwitch-switchBase.Mui-checked': {
        color: '#2BADEE',
        '&:hover': {
          backgroundColor: alpha('#2BADEE', theme.palette.action.hoverOpacity),
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled': {
        color: '#f5f5f5',
        '&:hover': {
          backgroundColor: alpha('#000', theme.palette.action.hoverOpacity),
        },
      },
      '& .MuiSwitch-switchBase.Mui-checked:not(.Mui-disabled) + .MuiSwitch-track':
        {
          backgroundColor: '#2BADEE',
        },
      '& .MuiSwitch-switchBase.Mui-checked.Mui-disabled + .MuiSwitch-track': {
        backgroundColor: '#000',
      },
      '& .MuiTypography-body1.MuiFormControlLabel-label': {
        display: 'none',
        '& span': {
          color: '#2BADEE',
        },
      },
    }))
    return (
      <Box className={classes.usersTableContainer}>
        <TableContainer component={Box} sx={{ maxHeight: 240 }}>
          <Table stickyHeader sx={{ minWidth: 700 }} aria-label="users table">
            <TableHead>
              <TableRow>
                <TableCell>User</TableCell>
                {accountType === 'provider' && (
                  <TableCell align="center">Plan</TableCell>
                )}
                <TableCell align="center">
                  <div className={classes.iconTitle}>
                    Admin{' '}
                    <Tooltip
                      title={
                        <Fragment>
                          <b>
                            Admin access will allow you to manage users and
                            billing
                          </b>
                        </Fragment>
                      }
                    >
                      <Info size={18} />
                    </Tooltip>
                  </div>
                </TableCell>
                <TableCell align="right">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userList.map((user) => {
                return (
                  <TableRow key={user.id}>
                    <TableCell>
                      <CardHeader
                        avatar={
                          <Avatar
                            className={clsx(classes.avatarIcon, {
                              [classes.inactiveAvatar]: !user.activated,
                            })}
                          >
                            {(
                              Array.from(user.firstName)[0] +
                              Array.from(user.lastName)[0]
                            ).toUpperCase()}
                          </Avatar>
                        }
                        title={`${user.firstName} ${user.lastName}`}
                        subheader={user.user?.email}
                      />
                    </TableCell>
                    {accountType === 'provider' && (
                      <TableCell style={{ minWidth: 200 }}>
                        <Autocomplete
                          disableClearable
                          options={
                            subscriptionProducts?.filter(
                              (prod: SubscriptionProduct) =>
                                prod.usedQuantity < prod.quantity,
                            ) || []
                          }
                          getOptionLabel={(opt) =>
                            opt.stripeProduct?.name || ''
                          }
                          filterOptions={(options, { inputValue }) =>
                            inputValue
                              ? matchSorter(options, inputValue, {
                                  keys: ['stripeProduct.name'],
                                })
                              : options
                          }
                          onChange={(_event, val) => {
                            // setFieldValue('subscriptionPlan', val?.stripeProduct?.metadata.systemValue)
                            const newSubscription =
                              val?.stripeProduct?.metadata.systemValue ||
                              'starter'
                            updateUser.mutateAsync({
                              ...user,
                              subscriptionPlan: newSubscription,
                            })
                          }}
                          value={
                            (subscriptionProducts || []).find(
                              (prod) =>
                                prod.stripeProduct?.metadata.systemValue.toLocaleLowerCase() ===
                                user.subscriptionPlan,
                            ) ?? undefined
                          }
                          renderInput={(params) => {
                            // @ts-expect-error Mui's autocomplete types here are incomplete
                            params.InputLabelProps.shrink = true
                            return (
                              <TextField
                                required
                                {...params}
                                name="subscriptionPlan"
                                InputProps={{
                                  ...params.InputProps,
                                }}
                              />
                            )
                          }}
                        />
                      </TableCell>
                    )}
                    <TableCell align="center">
                      <AdminSwitch
                        checked={user.isAdmin}
                        disabled={
                          currentUser ? currentUser.staff.id == user.id : true
                        }
                        onChange={(_event, val) => {
                          updateUser.mutateAsync({
                            ...user,
                            isAdmin: val,
                          })
                        }}
                      />
                    </TableCell>
                    <TableCell align="right">
                      <DialogTrigger
                        disabled={
                          currentUser ? currentUser.staff.id == user.id : true
                        }
                        action={
                          <IconButton
                            disabled={
                              currentUser
                                ? currentUser.staff.id == user.id
                                : true
                            }
                          >
                            <Trash2
                              color={
                                currentUser && currentUser.staff.id == user.id
                                  ? '#919191'
                                  : '#EB3E3E'
                              }
                            />
                          </IconButton>
                        }
                        content={(toggle) => (
                          <Box p={3} className={classes.dialogContent}>
                            <div className={classes.titleRow}>
                              <H3>Are you sure?</H3>
                              <Button
                                size="small"
                                variant="text"
                                onClick={() => toggle()}
                              >
                                <Icon size="medium" color="secondaryText">
                                  <X />
                                </Icon>
                              </Button>
                            </div>
                            <Divider className={classes.divider} />
                            <Body1 className={classes.nameText}>
                              Deactivating {user.firstName}`s account cannot be
                              undone. All data associated with this account will
                              be archived.
                            </Body1>
                            <Divider className={classes.divider} />
                            <div className={classes.deactivateAccountButton}>
                              <Button
                                size="large"
                                disabled={currentUser?.staff.id == user.id}
                                onClick={() => {
                                  deactivateAccount
                                    .mutateAsync({
                                      staffId: user.id,
                                    })
                                    .then(() => {
                                      toggle()
                                    })
                                }}
                              >
                                Deactivate Account
                              </Button>
                            </div>
                          </Box>
                        )}
                      />
                    </TableCell>
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }

  return (
    <Box className="step-content">
      <Box className="step-header">
        <H1>Add Users</H1>
        <H3>{'Invite users to your account'}</H3>
      </Box>
      <Box className={classes.root}>
        <Box className={classes.tabsContainer}>
          <Tabs
            className={classes.accountTypeTabs}
            value={accountType}
            onChange={handleTabChange}
          >
            {accountTypes.map((type) => {
              return (
                <Tab
                  key={type.key}
                  value={type.key}
                  label={type.label}
                  disabled={type.disabled}
                />
              )
            })}
          </Tabs>
          {accountType == 'provider' && (
            <Box>
              {subscriptionProducts
                ?.sort(
                  (a, b) =>
                    (a.stripeProduct?.metadata.order || 0) -
                    (b.stripeProduct?.metadata.order || 0),
                )
                .map((product) => {
                  const chipName = (
                    <span>
                      <b>
                        {product.usedQuantity}/{product.quantity}
                      </b>{' '}
                      {product.stripeProduct?.name}
                    </span>
                  )
                  return (
                    <Chip
                      key={product.id}
                      label={chipName}
                      variant="outlined"
                      style={{
                        backgroundColor:
                          product.stripeProduct?.metadata.backgroundColor,
                        borderColor: product.stripeProduct?.metadata.mainColor,
                      }}
                      className={classes.productChip}
                    />
                  )
                })}
            </Box>
          )}
          {accountType == 'clinic-staff' && (
            <Box>
              <Alert icon={false} className={classes.clinicStaffAlert}>
                Unlimited Staff Seats Included
              </Alert>
            </Box>
          )}
        </Box>
        <Box className={classes.sectionContainer}>
          <CreateAccountEl />
          <UserTableEl />
          <Button onClick={() => pushNextOnboardingStep()}>Continue</Button>
          <Button onClick={() => pushNextOnboardingStep()} variant={'text'}>
            Skip for now
          </Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ClinicUsersStep
