import { useMemo } from 'react'
import { format, isAfter, isBefore, isEqual } from 'date-fns'
import * as yup from 'yup'

import useDates from '../../../hooks/useDates'
import { TreatmentFormValues } from './Treatment'

const createTreatmentSessionValidations = (
  datePattern: string,
  parseDateFn: (date: string) => false | Date,
  prevSession: string,
  nextSession?: string,
  initialValues?: TreatmentFormValues,
) =>
  yup.object({
    providerId: yup.string().defined('A provider must be selected'),
    medicalTreatmentId: yup
      .string()
      .defined('A treatment session type must be selected'),
    treatmentProductId: yup
      .string()
      .defined('A treatment session product must be selected'),
    treatmentDate: yup
      .string()
      .defined('Treatment session date is required for scheduling assessments')
      .test(
        'is-valid-date',
        `Treatment session must be in format ${datePattern.toLocaleLowerCase()} and a valid date`,
        (value = '') => !!parseDateFn(value),
      )
      .test(
        'is-after-last-session',
        'Treatment date must be after: ' +
          format(parseDateFn(prevSession) || 0, 'yyyy-MM-dd'),
        (value) => {
          const asDate = parseDateFn(value || '')
          const prevDate = parseDateFn(prevSession) || 0
          if (
            value !== '' &&
            (!initialValues?.treatmentDate ||
              // If we have an initialValue (aka we're operating on an existing treatment)
              format(asDate || 0, 'yyyy-MM-dd') !== initialValues.treatmentDate)
          ) {
            return (
              asDate && (isAfter(asDate, prevDate) || isEqual(asDate, prevDate))
            )
          }
          return true
        },
      )
      .test(
        'is-before-next-session',
        'Treatment date must be before: ' + (nextSession || ''),
        (value) => {
          const asDate = parseDateFn(value || '')
          const nextDate = parseDateFn(nextSession || '') || 0
          if (
            value !== '' &&
            (!initialValues?.treatmentDate ||
              // If we have an initialValue (aka we're operating on an existing treatment)
              format(asDate || 0, 'yyyy-MM-dd') !==
                initialValues.treatmentDate) &&
            nextSession
          ) {
            return (
              asDate &&
              (isBefore(asDate, nextDate) || isEqual(asDate, nextDate))
            )
          }
          return true
        },
      ),
  })

const useTreatmentSession = (prevSession: string, nextSession?: string) => {
  const { localizedDatePattern, localizedParseDate } = useDates()

  return useMemo(
    () => ({
      createTreatmentSessionValidations: (
        initialValues?: TreatmentFormValues,
      ) =>
        createTreatmentSessionValidations(
          localizedDatePattern,
          localizedParseDate,
          prevSession,
          nextSession,
          initialValues,
        ),
    }),
    [localizedDatePattern, localizedParseDate],
  )
}

export default useTreatmentSession
