var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { format, isValid as isValidDate } from 'date-fns';
import { DatePicker, makeStyles, } from '..';
const useStyles = makeStyles(() => ({
    root: {},
    input: {
        textAlign: 'center',
    },
}));
const SurveyDateField = (_a) => {
    var { item, answer, onAnswer, className, pattern } = _a, rest = __rest(_a, ["item", "answer", "onAnswer", "className", "pattern"]);
    const classes = useStyles();
    const initialValue = item.initialValue != null ? String(item.initialValue) : '';
    const defaultValue = answer != null ? answer : initialValue;
    const [value, setValue] = useState(defaultValue);
    const charLimit = item.data.characterLimit || Infinity;
    useEffect(() => {
        // On mount, if `answer` is not set and the initialValue is sufficient,
        // give our parent a valid answer.
        if (answer == null && isValid(value)) {
            onAnswer(item, initialValue);
        }
        // eslint-disable-next-line
    }, []);
    const isValid = (val) => {
        const isEmptyString = val === '';
        if (!Array.isArray(pattern)) {
            const inCharLimit = val.length <= charLimit;
            const matchesPattern = pattern ? new RegExp(pattern).test(val) : true;
            return inCharLimit && !isEmptyString && matchesPattern;
        }
        const inCharLimit = val.length <= pattern.length;
        const patternTests = val.split('').map((stringItem, index) => {
            const stringItemMatchesPattern = new RegExp(pattern[index]).test(stringItem);
            return stringItemMatchesPattern;
        });
        const allPatternTestsPasses = patternTests.every((test) => !!test);
        return inCharLimit && !isEmptyString && allPatternTestsPasses;
    };
    return (_jsx(DatePicker, Object.assign({ className: clsx(classes.root, className), value: value, inputFormat: 'MM/dd/yyyy', inputProps: { className: classes.input }, fullWidth: true, setFieldValue: setValue, customOnChange: (val) => {
            const eventValue = isValidDate(val) ? format(val, 'yyyy-MM-dd') : '';
            if (isValid(eventValue)) {
                onAnswer(item, eventValue);
                setValue(eventValue);
            }
            else {
                if (eventValue === '') {
                    setValue(eventValue);
                    onAnswer(item, undefined); // unsetting answer to indicate an invalid pending value
                }
            }
        } }, rest), void 0));
};
export default SurveyDateField;
