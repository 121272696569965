import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import clsx from 'clsx';
import { MaskedRange } from 'imask';
import { MinusCircle, PlusCircle } from 'react-feather';
import { Box, Button, Checkbox, Chip, ControlledButtonGroup, findBy, FormControlLabel, Grid, makeStyles, MenuItem, QuestionVariants, Select, SurveyChecklist, SurveyDateField, SurveyNumberField, SurveySlider, SurveyStringField, } from '../';
const useStyles = makeStyles((theme) => ({
    root: {},
    buttonGroup: {
        marginTop: theme.spacing(1.2),
        [theme.breakpoints.down('md')]: {
            margin: theme.spacing(2),
            width: '88%',
        },
        // This is important for when we're showing a selected button
        // that doesn't have an :active state. To reproduce, answer
        // a choice-standard/long question, progress to the next question,
        // then go back to the one you just answered.
        '& > .Button-selected': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
    },
    flatButtonGroup: {
        boxShadow: 'none',
        '& > .Button-selected': {
            color: theme.palette.common.white,
            backgroundColor: theme.palette.primary.main,
        },
    },
    flatButtonGroupButton: {
        fontWeight: 'bold',
        minHeight: 56,
        color: theme.palette.primary.main,
        backgroundColor: 'rgb(245, 245, 248)',
    },
    button: {
        fontWeight: 'bold',
        minHeight: 56,
        color: theme.palette.primary.main,
    },
    dropdownMenu: {
        maxHeight: 'calc(75% - 96px)',
    },
    dropdownItem: {
        whiteSpace: 'inherit',
    },
    dropdownItemCentered: {
        whiteSpace: 'inherit',
        justifyContent: 'center',
        backgroundColor: '#e3e3e3',
        fontWeight: 'bold',
        color: theme.palette.primary.main,
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
        '& .MuiChip-label': {
            whiteSpace: 'nowrap',
            textOverflow: 'ellipsis',
            [theme.breakpoints.down('md')]: {
                maxWidth: '55vw',
            },
        },
    },
    chip: {
        margin: 2,
    },
    checkboxForm: {
        margin: 0,
        '& svg': {
            maxWidth: '100%',
        },
    },
    collapsibleContainer: {
        marginBottom: theme.spacing(2),
        textAlign: 'right',
    },
    collapsibleInjectionsContainer: {
        display: 'block',
        width: '100%',
    },
    collapsibleInjections: {
        color: '#2396CF',
        minHeight: '30px',
        padding: 0,
        fontSize: '16px',
        fontWeight: 400,
        '&:hover': {
            opacity: '0.7',
        },
    },
    collapsibleRemove: {
        color: '#767676',
    },
    collapsibleGeneral: {
        color: '#2396CF',
        border: '1px solid #2396CF',
        borderRadius: '8px',
        fontSize: '16px',
        minHeight: '30px',
        fontWeight: 700,
        boxShadow: 'none',
        padding: theme.spacing(1, 2),
        '&:hover': {
            opacity: '0.7',
            border: '1px solid #2396CF',
        },
    },
}), { name: 'SurveyItemQuestion' });
/**
 * TODO: Refactor many of the question variant components to specific Survey sub-components
 */
const SurveyItemQuestion = ({ item, answer = [], onAnswer, defaultIcon, checkedIcon, }) => {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    const classes = useStyles();
    const baseClasses = clsx('SurveyItemQuestion-root', classes.root);
    const [openMultiselect, setOpenMultiselect] = useState(false);
    const handleMultiselectOpen = () => {
        setOpenMultiselect(true);
    };
    const handleMultiselectClose = () => {
        setOpenMultiselect(false);
    };
    if (item.custom && item.custom.component) {
        const Component = item.custom.component;
        return (_jsx(Component, { className: baseClasses, answer: answer, onAnswer: onAnswer }, void 0));
    }
    switch (item.variant) {
        case QuestionVariants.choiceStandard: {
            return (_jsx(ControlledButtonGroup, Object.assign({ id: item.slug.toLowerCase(), onButtonSelect: (id) => onAnswer(item, id), className: clsx(baseClasses, classes.buttonGroup) }, { children: item.data.answerOptions.map((opt) => {
                    const isSelected = !!answer.find((v) => v === opt.value);
                    return (_jsx(Button, Object.assign({ className: classes.button, id: String(opt.value), fullWidth: true, size: "large", variant: "outlined", selected: isSelected }, { children: opt.label }), opt.value));
                }) }), void 0));
        }
        case QuestionVariants.choiceLong:
            return (_jsx(SurveyChecklist, { id: item.slug.toLowerCase(), className: baseClasses, items: item.data.answerOptions, onItemSelect: (value) => onAnswer(item, value) }, void 0));
        case QuestionVariants.choiceFlat: {
            const options = item.data.answerOptions;
            return (_jsx(ControlledButtonGroup, Object.assign({ id: item.slug.toLowerCase(), onButtonSelect: (id) => onAnswer(item, id), orientation: "horizontal", className: clsx(baseClasses, classes.flatButtonGroup) }, { children: options.map((opt) => (_jsx(Button, Object.assign({ className: clsx(classes.flatButtonGroupButton), id: String(opt.value), size: "large", variant: "outlined", selected: answer.length > 0 ? answer.includes(opt.value) : undefined }, { children: opt.label }), opt.value))) }), void 0));
        }
        case QuestionVariants.choiceToggle: {
            const option = item.data.answerOptions.find((opt) => !answer.includes(opt.value));
            return (_jsx(Button, Object.assign({ id: item.slug.toLowerCase(), className: baseClasses, fullWidth: true, variant: "outlined" }, { children: option.label }), void 0));
        }
        case QuestionVariants.slider:
        case QuestionVariants.sliderHorizontal: {
            const sliderValue = answer[0] != null ? Number(answer[0]) : null;
            // TODO: Migrate SurveySlider to an API where it accepts an `item` prop
            // instead of each individual attribute
            return (_jsx(SurveySlider, { id: item.slug.toLowerCase(), className: baseClasses, onChange: (val) => {
                    onAnswer(item, val);
                }, value: sliderValue, labelMaxValue: item.data.maxLabel, labelMinValue: item.data.minLabel, rangeMin: Number(item.data.minValue), rangeMax: Number(item.data.maxValue), initialValue: Number(item.initialValue), vertical: item.variant == QuestionVariants.slider }, void 0));
        }
        case QuestionVariants.choiceDropdown: {
            const options = item.data.answerOptions;
            let selectedOptions = options
                .filter((opt) => answer.includes(opt.value))
                .map((o) => o.value);
            const noneValue = 'None';
            const noneSelection = options.filter((opt) => answer.includes(opt.value) && opt.label === noneValue);
            const isNoneSelected = noneSelection.length > 0;
            if (isNoneSelected)
                selectedOptions = noneSelection.map((o) => o.value);
            const sharedProps = {
                className: clsx('SurveyItemQuestion-root', 'SurveyItemQuestion-select', classes.root),
                options: options.map((opt) => (_jsxs(MenuItem, Object.assign({ className: classes.dropdownItem, value: opt.value, disabled: isNoneSelected && opt.label !== noneValue }, { children: [item.data.repeat ? (_jsx(Checkbox, { checked: selectedOptions.indexOf(opt.value) > -1 }, void 0)) : null, opt.label] }), opt.label))),
                MenuProps: {
                    classes: {
                        paper: classes.dropdownMenu,
                    },
                    getContentAnchorEl: null,
                },
            };
            // TODO Refactor this code to new Survey sub-components
            if (item.data.repeat) {
                const selectedOptions = options
                    .filter((opt) => answer.includes(opt.value))
                    .map((o) => o.value);
                const handleDeleteChip = (value) => {
                    const nextAnswers = selectedOptions.filter((opt) => opt !== value);
                    onAnswer(item, nextAnswers);
                };
                return (_jsxs(Select, Object.assign({ id: item.slug.toLowerCase(), multiple: true, className: sharedProps.className, value: selectedOptions, MenuProps: sharedProps.MenuProps, renderValue: (selected) => (_jsx("div", Object.assign({ className: classes.chips }, { children: selected.map((value) => {
                            var _a;
                            return (_jsx(Chip, { label: ((_a = findBy('value', value, options)) === null || _a === void 0 ? void 0 : _a.label) || '', className: classes.chip, onDelete: () => handleDeleteChip(value), onMouseDown: (event) => {
                                    event.stopPropagation();
                                } }, value));
                        }) }), void 0)), onChange: (ev) => {
                        const value = ev.target.value;
                        let asOptions = options.filter((opt) => value.includes(opt.value));
                        const noneSelection = options.filter((opt) => value.includes(opt.value) && opt.label === noneValue);
                        const isNoneSelected = noneSelection.length > 0;
                        if (isNoneSelected)
                            asOptions = noneSelection;
                        if (!asOptions.length) {
                            onAnswer(item, undefined);
                        }
                        else {
                            onAnswer(item, asOptions.map((o) => o.value));
                        }
                    }, open: openMultiselect, onClose: handleMultiselectClose, onOpen: handleMultiselectOpen }, { children: [_jsx(MenuItem, Object.assign({ className: classes.dropdownItemCentered, onClick: () => {
                                setOpenMultiselect(false);
                            } }, { children: "Close" }), void 0), sharedProps.options] }), void 0));
            }
            else {
                const selectedOption = ((_a = options.find((opt) => answer.includes(opt.value))) === null || _a === void 0 ? void 0 : _a.value) || '';
                // If there is only one option and the question is required, auto-set the value
                if (item.required && options.length === 1 && !selectedOption) {
                    onAnswer(item, [options[0].value]);
                }
                return (_jsx(Select, Object.assign({ id: item.slug.toLowerCase(), className: sharedProps.className, value: selectedOption, MenuProps: sharedProps.MenuProps, onChange: (ev) => {
                        const value = ev.target.value;
                        const asOption = options.find((opt) => value === opt.value);
                        if (!asOption) {
                            onAnswer(item, undefined);
                        }
                        else {
                            onAnswer(item, [asOption.value]);
                        }
                    } }, { children: sharedProps.options }), void 0));
            }
        }
        case QuestionVariants.choiceCollapse: {
            const options = item.data.answerOptions;
            // TODO Refactor this code to new Survey sub-components
            const selectedOption = ((_b = options.find((opt) => answer.includes(opt.value))) === null || _b === void 0 ? void 0 : _b.value) || '';
            // If there is only one option and the question is required, auto-set the value
            if (!selectedOption) {
                onAnswer(item, ['0.0']);
            }
            const isInjectionSite = item.data.prompt === 'New Injection Site';
            const displayButtonText = selectedOption === '0.0'
                ? `Add ${item.data.prompt}`
                : isInjectionSite
                    ? 'Remove'
                    : `Remove ${item.data.prompt}`;
            return (_jsx(Box, Object.assign({ className: clsx(classes.collapsibleContainer, {
                    [classes.collapsibleInjectionsContainer]: isInjectionSite,
                }) }, { children: _jsx(Button, Object.assign({ id: item.slug.toLowerCase(), className: clsx({
                        [classes.collapsibleInjections]: isInjectionSite,
                        [classes.collapsibleGeneral]: !isInjectionSite,
                        [classes.collapsibleRemove]: isInjectionSite && selectedOption === '1.0',
                    }), variant: isInjectionSite ? 'text' : 'outlined', startIcon: isInjectionSite ? null : selectedOption === '0.0' ? (_jsx(PlusCircle, {}, void 0)) : (_jsx(MinusCircle, {}, void 0)), onClick: () => {
                        if (selectedOption === '1.0') {
                            onAnswer(item, ['0.0']);
                        }
                        else {
                            onAnswer(item, ['1.0']);
                        }
                    } }, { children: displayButtonText }), void 0) }), void 0));
        }
        case QuestionVariants.choiceIcon: {
            const options = item.data.answerOptions;
            const rowColumns = item.data.maxValue || 3;
            let selectedOptions = options
                .filter((opt) => answer.includes(opt.value))
                .map((o) => o.value);
            const noneValue = 'None';
            const noneSelection = options.filter((opt) => answer.includes(opt.value) && opt.label === noneValue);
            const isNoneSelected = noneSelection.length > 0;
            if (isNoneSelected)
                selectedOptions = noneSelection.map((o) => o.value);
            const altOptions = [...options];
            const displayArray = Array.from({ length: Math.ceil(options.length / rowColumns) }, () => altOptions.splice(0, rowColumns));
            // TODO Refactor this code to new Survey sub-components
            if (item.data.repeat) {
                const selectedOptions = options
                    .filter((opt) => answer.includes(opt.value))
                    .map((o) => o.value);
                return (_jsx(Box, { children: displayArray.map((group, index) => {
                        return (_jsx(Grid, Object.assign({ container: true, spacing: 1 }, { children: group.map((option, idx) => {
                                const isOptionChecked = selectedOptions.includes(option.value);
                                return (_jsx(Grid, Object.assign({ item: true, xs: Math.ceil(12 / rowColumns) }, { children: _jsx(FormControlLabel, { className: classes.checkboxForm, value: option.value, control: _jsx(Checkbox, { id: item.slug.toLowerCase() + idx, checkedIcon: checkedIcon && checkedIcon(option.value), checked: isOptionChecked, icon: defaultIcon && defaultIcon(option.value), onChange: (ev) => {
                                                if (!ev.target.checked)
                                                    selectedOptions.splice(selectedOptions.indexOf(option.value), 1);
                                                const value = ev.target.checked
                                                    ? [...selectedOptions, option.value]
                                                    : selectedOptions;
                                                let asOptions = options.filter((opt) => value.includes(opt.value));
                                                const noneSelection = options.filter((opt) => value.includes(opt.value) &&
                                                    opt.label === noneValue);
                                                const isNoneSelected = noneSelection.length > 0;
                                                if (isNoneSelected)
                                                    asOptions = noneSelection;
                                                if (!asOptions.length) {
                                                    onAnswer(item, undefined);
                                                }
                                                else {
                                                    onAnswer(item, asOptions.map((o) => o.value));
                                                }
                                            } }, void 0), label: option.label, labelPlacement: "bottom" }, void 0) }), option.value));
                            }) }), index));
                    }) }, void 0));
            }
            else {
                const selectedOption = ((_c = options.find((opt) => answer.includes(opt.value))) === null || _c === void 0 ? void 0 : _c.value) || '';
                // If there is only one option and the question is required, auto-set the value
                if (item.required && options.length === 1 && !selectedOption) {
                    onAnswer(item, [options[0].value]);
                }
                return (_jsx(Box, { children: displayArray.map((group, index) => {
                        return (_jsx(Grid, Object.assign({ container: true, spacing: 1 }, { children: group.map((option, idx) => {
                                const isOptionChecked = selectedOptions.includes(option.value);
                                return (_jsx(Grid, Object.assign({ item: true, xs: Math.ceil(12 / rowColumns) }, { children: _jsx(FormControlLabel, { className: classes.checkboxForm, value: option.value, control: _jsx(Checkbox, { id: item.slug.toLowerCase() + idx, checkedIcon: checkedIcon && checkedIcon(option.value), checked: isOptionChecked, icon: defaultIcon && defaultIcon(option.value), onChange: (ev) => {
                                                if (!ev.target.checked)
                                                    selectedOptions.splice(selectedOptions.indexOf(option.value), 1);
                                                const value = ev.target.checked
                                                    ? option.value
                                                    : undefined;
                                                const asOption = options.find((opt) => value === opt.value);
                                                if (!asOption) {
                                                    onAnswer(item, undefined);
                                                }
                                                else {
                                                    onAnswer(item, [asOption.value]);
                                                }
                                            } }, void 0), label: option.label, labelPlacement: "bottom" }, void 0) }), option.value));
                            }) }), index));
                    }) }, void 0));
            }
        }
        case QuestionVariants.zipCode: {
            const asStringItem = item;
            asStringItem.data.characterLimit = '00000'.length;
            return (_jsx(SurveyStringField, { id: item.slug.toLowerCase(), className: baseClasses, item: asStringItem, answer: answer[0], onAnswer: onAnswer, fullWidth: true, name: "zipCode", type: "text", placeholder: "10001", mask: {
                    mask: MaskedRange,
                    from: 0,
                    to: 99999,
                    maxLength: 5,
                }, autoFocus: (_e = (_d = item.custom) === null || _d === void 0 ? void 0 : _d.autoFocus) !== null && _e !== void 0 ? _e : false, inputProps: {
                    inputMode: 'numeric',
                }, error: answer[0] && answer[0].length !== 5, helperText: answer[0] && answer[0].length !== 5
                    ? 'Zip Code needs to be 5 digits'
                    : '' }, void 0));
        }
        case QuestionVariants.postalCode: {
            const asStringItem = item;
            asStringItem.data.characterLimit = 7;
            return (_jsx(SurveyStringField, { id: item.slug.toLowerCase(), className: baseClasses, item: asStringItem, answer: answer[0], onAnswer: onAnswer, fullWidth: true, name: "postalCode", type: "text", placeholder: "HOH-0H0", mask: {
                    prepare: function (str) {
                        return str.toUpperCase();
                    },
                    definitions: {
                        L: /[A-Z]/,
                    },
                    mask: 'L0L[-]0L0', // letter-number-letter number-letter-number
                }, autoFocus: (_g = (_f = item.custom) === null || _f === void 0 ? void 0 : _f.autoFocus) !== null && _g !== void 0 ? _g : false, error: answer[0] && answer[0].length !== 7, helperText: answer[0] && answer[0].length !== 7
                    ? 'Postal Code needs to be 6 characters'
                    : '' }, void 0));
        }
        case QuestionVariants.inputDecimal:
        case QuestionVariants.inputInteger: {
            const asNum = Number(answer[0]);
            const hasAnswer = answer[0] !== undefined;
            if (hasAnswer && Number.isNaN(asNum)) {
                throw new Error(`Encountered invalid string answer for survey question variant ${item.variant}. Was: ${answer[0]}`);
            }
            return (_jsx(SurveyNumberField, { id: item.slug.toLowerCase(), className: baseClasses, item: item, 
                // Number fields are implemented with a text field so the answer needs to be a string
                answer: hasAnswer ? String(answer[0]) : undefined, autoFocus: (_j = (_h = item.custom) === null || _h === void 0 ? void 0 : _h.autoFocus) !== null && _j !== void 0 ? _j : false, onAnswer: onAnswer }, void 0));
        }
        case QuestionVariants.inputText:
        case QuestionVariants.inputString: {
            if (typeof answer[0] === 'number') {
                throw new Error('Encountered number answer for survey question variant input-string.');
            }
            return (_jsx(SurveyStringField, { id: item.slug.toLowerCase(), className: baseClasses, item: item, answer: answer[0], autoFocus: (_l = (_k = item.custom) === null || _k === void 0 ? void 0 : _k.autoFocus) !== null && _l !== void 0 ? _l : false, onAnswer: onAnswer }, void 0));
        }
        case QuestionVariants.inputTextarea: {
            if (typeof answer[0] === 'number') {
                throw new Error('Encountered number answer for survey question variant input-textarea.');
            }
            return (_jsx(SurveyStringField, { id: item.slug.toLowerCase(), className: baseClasses, item: item, answer: answer[0], autoFocus: (_o = (_m = item.custom) === null || _m === void 0 ? void 0 : _m.autoFocus) !== null && _o !== void 0 ? _o : false, onAnswer: onAnswer }, void 0));
        }
        case QuestionVariants.inputDate: {
            if (typeof answer[0] === 'number') {
                throw new Error('Encountered number answer for survey question variant input-date.');
            }
            return (_jsx(SurveyDateField, { id: item.slug.toLowerCase(), className: baseClasses, item: item, answer: answer[0], autoFocus: (_q = (_p = item.custom) === null || _p === void 0 ? void 0 : _p.autoFocus) !== null && _q !== void 0 ? _q : false, onAnswer: onAnswer }, void 0));
        }
        default:
            throw new Error(
            //The item is now known to be unknown. Allow the `any`
            // eslint-disable-next-line
            `Unknown QuestionVariant ${item.variant}.`);
    }
};
export default SurveyItemQuestion;
