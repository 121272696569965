import { ISurveyQuestionItem, ISurveyResponseAnswer } from '@perk-ui/core'
import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { BodySiteKeyCamelCase } from './useBodySites'

export interface MinimalSurvey {
  id: string
  slug: string
  name: string
  description: string
}

export interface SurveyResponseMetadataItem {
  type: 'schedule' | 'order'
  value: string | number
}

interface SurveyResponseAnswer extends ISurveyResponseAnswer {
  id: string
  surveyResponseId: string
  surveyQuestion: ISurveyQuestionItem
  createdAt: string
  updatedAt: string
  surveyQuestionType: never
}

export interface SurveyResponseScore {
  name: string
  isPrimary: boolean
  value: string
}

export type SurveyResponseOwner = 'Patient' | 'PatientCase' | 'PatientTreatment'

export interface SurveyResponse {
  id: string
  ownerType: SurveyResponseOwner
  ownerId: string
  surveyId: string
  bodySiteSlug: BodySiteKeyCamelCase | null
  startedAt: null | string // date
  completedAt: null | string // date
  createdAt: string // date
  updatedAt: string // date
  availableOn: string // date
  expiresOn: string // date
  surveyResponseAnswers: SurveyResponseAnswer[]
  survey: MinimalSurvey
  surveyResponseScores: SurveyResponseScore[]
  metadata: SurveyResponseMetadataItem[]
}

interface SurveyResponseResponse {
  surveyResponse: SurveyResponse
}

interface Options {
  id?: string
  ownerType: SurveyResponseOwner
}

const useSurveyResponse = (
  { id, ownerType }: Options,
  extraOptions: UseQueryOptions<SurveyResponse> = {},
) => {
  const currentSiteId = useCurrentSite().id
  const key = id ? QueryKeys.surveyResponse(id) : []
  const url = `/sites/survey_responses/${id}?site_ids[]=${currentSiteId}&owner_type=${ownerType}`

  return useQuery(
    key,
    () =>
      request
        .get<SurveyResponseResponse>(url)
        .then((r) => r.data.surveyResponse),
    {
      enabled: Boolean(id),
      ...extraOptions,
    },
  )
}

export default useSurveyResponse
