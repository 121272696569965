import { useQuery, UseQueryOptions } from 'react-query'

import { useSitesContext } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

interface SurveyResponseGroupsResponse {
  surveyResponseGroups: SurveyResponseGroup[]
}

export interface SurveyResponseGroup {
  id: string
  availableOn: string
  expiresOn: string
  ownerType: 'Patient'
  ownerId: string
  shortLink: string
}

interface SurveyResponseGroupsParams {
  patientId?: string
  patientCaseId?: string
}

const useSurveyResponseGroupsAlt = (
  { patientId, patientCaseId }: SurveyResponseGroupsParams,
  extraOptions: UseQueryOptions<SurveyResponseGroup[]> = {},
) => {
  const currentSite = useSitesContext().currentSite
  const currentSiteId = currentSite?.id || ``

  const key = QueryKeys.surveyResponseGroupsAlt({
    currentSiteId,
    patientId,
    patientCaseId,
  })

  const url = `/sites/survey_response_groups_alt?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () =>
      request
        .get<SurveyResponseGroupsResponse>(url, {
          params: {
            patient_id: patientId,
            patient_case_id: patientCaseId,
          },
        })
        .then((r) => r.data.surveyResponseGroups),
    {
      ...extraOptions,
    },
  )
}

export default useSurveyResponseGroupsAlt
