import { FC, Fragment, useEffect } from 'react'
import {
  Body1,
  capitalize,
  Divider,
  findBy,
  H3,
  H5,
  List,
  ListItem,
  makeStyles,
} from '@perk-ui/core'

import useAggregateOutcomes from '../../../features/query-hooks/useAggregateOutcomes'
import { BodySiteKey } from '../../../features/query-hooks/useBodySites'

const useStyles = makeStyles((theme) => ({
  list: {},
  listItem: {
    paddingLeft: theme.spacing(4),
  },
  outcomeList: {
    height: '100%',
    overflowY: 'auto',
  },
}))

export interface DataSidebarProps {
  tab: BodySiteKey | 'clinic-overview'
  onItemClick: (key: BodySiteKey | 'clinic-overview') => void
}

const DataSidebar: FC<DataSidebarProps> = ({ tab, onItemClick }) => {
  const classes = useStyles()
  const { data: outcomes = [] } = useAggregateOutcomes()

  const bodySiteN = outcomes.reduce((acc, outcome) => {
    const key = outcome.bodySiteKey
    const prevN = acc[key] || 0
    const baselineN = findBy('x', 0, outcome.data)?.n || 0

    acc[key] = prevN + baselineN

    return acc
  }, {} as Record<BodySiteKey, number>)
  const sitesByN = Object.entries(bodySiteN)
    .sort((a, b) => b[1] - a[1])
    .map((a) => a[0]) as BodySiteKey[]

  useEffect(() => {
    if (!tab) {
      onItemClick('clinic-overview')
    }
  }, [tab, onItemClick])

  return (
    <>
      <H3 sx={{ fontSize: 20, pt: 5, pb: 2, px: 4 }}>Data</H3>
      <Divider />
      <ListItem
        className={classes.listItem}
        button
        dense
        disableGutters
        selected={tab === 'clinic-overview'}
        onClick={() => onItemClick('clinic-overview')}
      >
        <Body1>Clinic overview</Body1>
      </ListItem>
      <Divider />
      <H5 sx={{ fontSize: 16, pt: 3, pb: 1, px: 4 }}>Outcomes</H5>
      <Divider />
      <List disablePadding className={classes.outcomeList}>
        {sitesByN.map((bodySiteKey) => {
          return (
            <Fragment key={bodySiteKey}>
              <ListItem
                className={classes.listItem}
                button
                dense
                disableGutters
                selected={tab === bodySiteKey}
                onClick={() => onItemClick(bodySiteKey)}
              >
                <Body1>{humanize(bodySiteKey)}</Body1>
              </ListItem>
              <Divider />
            </Fragment>
          )
        })}
      </List>
    </>
  )
}

const humanize = (str: string) =>
  str.toLowerCase().split('-').map(capitalize).join(' ')

export default DataSidebar
