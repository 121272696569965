import { useMutation, useQueryClient } from 'react-query'

import request from '../../config/axios'
import QueryKeys from './QueryKeys'

interface RemoveCouponResponse {
  subscription: CouponResponse
}

export interface CouponResponse {
  clientSecret: string
  paymentLink: string
  subscription: string
}

interface RemoveCouponVars {
  coupon: string
  subscriptionId: string
}

const useRemoveCoupon = () => {
  const queryClient = useQueryClient()

  return useMutation((vars: RemoveCouponVars) => {
    const url = `/sites/remove_coupon`
    return request
      .post<RemoveCouponResponse>(url, {
        coupon: vars.coupon,
        subscriptionId: vars.subscriptionId,
      })
      .then((response) => {
        queryClient.invalidateQueries(QueryKeys.subscription())
        queryClient.invalidateQueries(QueryKeys.unauthenticatedSubscription())
        return response.data.subscription
      })
  })
}

export default useRemoveCoupon
