var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { makeStyles } from '../';
const useStyles = makeStyles((theme) => ({
    root: ({ backgroundImageProps, backgroundColorProps, }) => ({
        display: 'flex',
        flex: 1,
        flexDirection: 'column',
        backgroundColor: backgroundColorProps || theme.perk.backgroundContainerColor || null,
        backgroundImage: !backgroundColorProps && theme.perk.backgroundContainerImage
            ? `url(${backgroundImageProps || theme.perk.backgroundContainerImage})`
            : null,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        height: '100vh',
    }),
    logoContainer: {
        display: 'flex',
        flex: 1,
        width: '100%',
        maxHeight: theme.spacing(7),
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(4),
        justifyContent: 'center',
    },
    logo: {
        height: '100%',
        // Firefox-specific fix. Images in FF are not restricted to the size of their container.
        maxHeight: theme.spacing(7),
    },
}), { name: 'BackgroundContainer' });
/** A component that has the client's logo on the top and a background */
const BackgroundContainer = (_a) => {
    var { backgroundImageProps = null, backgroundColorProps = null, logo, logoWidth, children, className } = _a, rest = __rest(_a, ["backgroundImageProps", "backgroundColorProps", "logo", "logoWidth", "children", "className"]);
    const classes = useStyles({ backgroundImageProps, backgroundColorProps });
    const logoContainer = logo &&
        (typeof logo === 'string' ? (_jsx("div", Object.assign({ className: classes.logoContainer }, { children: _jsx("img", { src: logo, className: classes.logo, width: logoWidth }, void 0) }), void 0)) : (logo));
    return (_jsxs("div", Object.assign({ className: clsx(classes.root, className) }, rest, { children: [logoContainer, children] }), void 0));
};
export default BackgroundContainer;
