import { FC } from 'react'
import { Body1, Box, H3, makeStyles } from '@perk-ui/core'

import SegmentedBar, {
  SegmentedBarItem,
} from '../../../components/SegmentedBar'

export const BodyPartColorList = [
  '#55AADC',
  '#ACECFC',
  '#9031D9',
  '#DBC04A',
  '#6BDADF',
  '#ACECFC',
  '#D16A38',
  '#5CC5E5',
  '#2947A6',
  '#29A67F',
  '#26BDB8',
  '#993D58',
  '#0F3E4D',
  '#26BD62',
  '#E5785C',
  '#8A3D99',
  '#2C6EB0',
  '#2E8099',
  '#2CB0AC',
  '#F2497C',
]

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  segmentedLine: {
    marginBottom: theme.spacing(2),
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  legendSquare: {
    height: 24,
    width: 24,
    borderRadius: 5,
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
}))

export interface BodyPartGraphProps {
  title?: string
  percentages: Record<string, number>
}

const PercentageBarGraph: FC<BodyPartGraphProps> = ({ percentages, title }) => {
  const classes = useStyles()

  const dataItems = Object.keys(percentages).map((key) => ({
    label: key,
    value: percentages[key],
  })) as SegmentedBarItem[]
  const sortedItems = dataItems.sort((a, b) => b.value - a.value)
  const count = dataItems.length

  return (
    <Box>
      <H3 paragraph>
        {title}: {count}
      </H3>
      <SegmentedBar
        className={classes.segmentedLine}
        items={sortedItems}
        colorList={BodyPartColorList}
      />
      {sortedItems.map((item, idx) => {
        return (
          <div key={item.label} className={classes.legendItem}>
            <div
              className={classes.legendSquare}
              style={{
                background: BodyPartColorList[idx],
              }}
            />
            <Body1 component="span">
              {item.label} {item.value}%
            </Body1>
          </div>
        )
      })}
    </Box>
  )
}

export default PercentageBarGraph
