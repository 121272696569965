import { FC, Fragment } from 'react'
import {
  Body1,
  Button,
  Divider,
  H1,
  Icon,
  IconButton,
  Link,
  makeStyles,
} from '@perk-ui/core'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { ArrowLeft } from 'react-feather'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import useCreateProtocolTreatment from '../../../features/query-hooks/useCreateProtocolTreatment'
import useDeleteProtocolTreatment from '../../../features/query-hooks/useDeleteProtocolTreatment'
import useProtocol from '../../../features/query-hooks/useProtocol'
import { ProtocolTreatment } from '../../../features/query-hooks/useProtocols'
import useUpdateProtocolTreatment from '../../../features/query-hooks/useUpdateProtocolTreatment'
import { useHistory } from '../../../utils/useHistory'
import ProtocolFlowUrls, {
  ProtocolFlowLocationParams,
} from '../ProtocolFlowUrls'
import TreatmentForm, { TreatmentFormValues } from './components/TreatmentForm'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  headerIcon: {
    marginRight: theme.spacing(1),
  },
  archiveButton: {
    cursor: 'pointer',
  },
  dialogContent: {
    '& > p': {
      textAlign: 'start',
    },
  },
  dialogButtons: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'center',
    '& button+button': {
      marginLeft: theme.spacing(5),
    },
  },
  form: {
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '& > .MuiDivider-root': {
      marginBottom: theme.spacing(3),
    },
    '& .MuiAutocomplete-input': {
      maxWidth: '100%',
    },
  },
}))

interface TreatmentsFormValues {
  treatments: ProtocolTreatment[]
}
const TreatmentHasChanged = (
  treat1?: TreatmentFormValues & Pick<ProtocolTreatment, 'isPrimary'>,
  treat2?: ProtocolTreatment,
) => {
  if (!treat1 || !treat2) return true
  if (treat1.isPrimary !== treat2.isPrimary) return true
  if (treat1.siteLocationId !== treat2.siteLocationId) return true
  if (treat1.medicalTreatmentId !== treat2.medicalTreatmentId) return true
  if (treat1.treatmentProductId !== treat2.treatmentProductId) return true
  if (treat1.staffId !== treat2.staffId) return true

  return false
}

const treatmentValidationSchema = yup.object({
  treatments: yup.array().of(
    yup.object({
      medicalTreatmentId: yup
        .string()
        .defined('A Primary Treatment must be selected'),
      staffId: yup.string().defined('A provider must be selected'),
      treatmentProductId: yup
        .string()
        .defined('A treatment product must be selected'),
    }),
  ),
})

export interface TreatmentProps {}

const Treatment: FC<TreatmentProps> = () => {
  const { goBack, push } = useHistory()
  const classes = useStyles()
  const treatmentMatch = useRouteMatch<ProtocolFlowLocationParams>(
    ProtocolFlowUrls.addTreatment.match,
  )
  const editTreatmentMatch = useRouteMatch<ProtocolFlowLocationParams>(
    ProtocolFlowUrls.treatment.match,
  )
  const { protocolId = undefined } = treatmentMatch ? treatmentMatch.params : {}
  const { treatmentId = undefined } = editTreatmentMatch
    ? editTreatmentMatch.params
    : {}
  const createTreatment = useCreateProtocolTreatment()
  const updateTreatment = useUpdateProtocolTreatment()
  const deleteTreatment = useDeleteProtocolTreatment()
  const { data: protocol } = useProtocol(protocolId || '')
  const existingTreatments = protocol?.protocolTreatments ?? []

  const formik = useFormik<TreatmentsFormValues>({
    validationSchema: treatmentValidationSchema,
    enableReinitialize: true,
    initialValues: {
      treatments:
        existingTreatments.length > 0
          ? existingTreatments
          : [
              {
                id: '',
                medicalTreatmentId: '',
                treatmentProductId: '',
                staffId: '',
                order: 0,
                periodAmount: 0,
                periodType: 'days',
                isPrimary: true,
              },
            ],
    },
    onSubmit(values) {
      if (!protocolId) return

      const createsAndUpdates = values.treatments.map((treatment, idx) => {
        if (treatment.id) {
          const shouldUpdate = TreatmentHasChanged(
            {
              ...treatment,
              isPrimary: idx === 0,
            },
            existingTreatments[idx],
          )

          if (shouldUpdate) {
            return updateTreatment.mutateAsync({
              ...treatment,
              id: treatment.id,
              isPrimary: idx === 0,
              protocolId,
            })
          }
        } else {
          return createTreatment.mutateAsync({
            ...treatment,
            isPrimary: idx === 0,
            order: idx + 1,
            protocolId,
          })
        }
        return
      })

      return Promise.all(createsAndUpdates).then(() => {
        const destination = ProtocolFlowUrls.previewProtocol.create(protocolId)

        push({
          pathname: destination,
          state: { from: location.pathname },
        })
      })
    },
  })

  const {
    errors,
    touched,
    values,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik

  return (
    <>
      <div className={classes.header}>
        <IconButton
          className={classes.headerIcon}
          onClick={() => {
            goBack()
          }}
          size="large"
        >
          <Icon size="x-large">
            <ArrowLeft />
          </Icon>
        </IconButton>
        <H1>
          {protocol?.type === 'FullProtocol'
            ? 'Full Protocol'
            : 'Treatment Protocol'}
        </H1>
      </div>

      <form className={classes.form} onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <FieldArray name="treatments" validateOnChange={false}>
            {({ remove, push }) => {
              return (
                <>
                  {values.treatments.map((treatment, idx) => {
                    if (treatmentId && treatment.id !== treatmentId) return null
                    return (
                      <Fragment key={idx}>
                        <TreatmentForm
                          treatment={treatment}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched.treatments?.[idx] ?? null}
                          errors={errors.treatments?.[idx] ?? null}
                          index={idx}
                          onRemove={async () => {
                            if (treatment.id) {
                              await deleteTreatment.mutateAsync(treatment.id)
                            }
                            remove(idx)
                          }}
                        />
                        {!treatmentId && <Divider />}
                      </Fragment>
                    )
                  })}
                  {!treatmentId && (
                    <Link
                      component="button"
                      type="button"
                      underline="hover"
                      onClick={() =>
                        push({
                          medicalTreatmentId: '',
                          treatmentProductId: '',
                          staffId: '',
                          order: 0,
                          periodAmount: 0,
                          periodType: 'days',
                          isPrimary: true,
                        })
                      }
                    >
                      <Body1>Add Additional Treatment</Body1>
                    </Link>
                  )}
                </>
              )
            }}
          </FieldArray>
          <Button type="submit" fullWidth loading={isSubmitting}>
            Next
          </Button>
        </FormikProvider>
      </form>
    </>
  )
}

export default Treatment
