import { FC } from 'react'
import { Box, Card, H2, IconButton, makeStyles, PageTitle } from '@perk-ui/core'
import { ArrowLeft } from 'react-feather'
import { useHistory, useParams } from 'react-router'

import useAdverseEvent from '../../../features/query-hooks/useAdverseEvent'
import usePatient from '../../../features/query-hooks/usePatient'
import usePatientCases from '../../../features/query-hooks/usePatientCases'
import CaseDetails from '../../PatientDetails/components/CaseDetails'
import AdjudicationCard from './components/AdjudicationCard'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  backButton: {
    marginRight: theme.spacing(1),
  },
  headerTitle: {
    display: 'flex',
    alignItems: 'center',
  },
  card: {
    paddingLeft: theme.spacing(3),
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  content: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  leftCol: {
    paddingRight: theme.spacing(4),
    width: '50%',
  },
  rightCol: {
    width: '50%',
    '& > *': {
      marginBottom: theme.spacing(4),
    },
  },
}))

export interface AdjudicationProps {}

const Adjudication: FC<AdjudicationProps> = () => {
  const classes = useStyles()
  const { goBack } = useHistory()
  const { adverseEventId } = useParams<{ adverseEventId: string }>()
  const { data: adverseEvent } = useAdverseEvent(adverseEventId)
  const patientId = adverseEvent?.patientId

  const { data: patient } = usePatient(patientId)
  const { data: patientCases = [] } = usePatientCases(patientId)
  const relatedCases = patientCases.filter(
    (pCase) => pCase.bodySiteId === adverseEvent?.bodySiteId,
  )

  const loading = !adverseEvent || !patientCases.length || !patient
  if (loading) return null

  return (
    <Box p={3} pl={1} pr={7}>
      <PageTitle title="Adverse Event" />
      <div className={classes.header}>
        <H2 className={classes.headerTitle}>
          <IconButton
            onClick={() => {
              goBack()
            }}
            className={classes.backButton}
            size="large"
          >
            <ArrowLeft />
          </IconButton>
          <span>
            Adverse Event:{' '}
            {patient && `${patient.firstName} ${patient.lastName}`}
          </span>
        </H2>
      </div>
      <div className={classes.content}>
        <div className={classes.leftCol}>
          {relatedCases.map((pCase, index) => (
            <Card key={pCase.id} className={classes.card}>
              <CaseDetails patientCase={pCase} index={index} />
            </Card>
          ))}
        </div>
        <div className={classes.rightCol}>
          <Card className={classes.card}>
            {adverseEvent && <AdjudicationCard adverseEvent={adverseEvent} />}
          </Card>
        </div>
      </div>
    </Box>
  )
}

export default Adjudication
