var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import * as React from 'react';
import { styled } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { format, isValid } from 'date-fns';
const CssTextField = styled(TextField)({
    '&.MuiError': {
        '& .MuiFormHelperText-root': {
            color: '#b30000',
        },
    },
});
export default function DatePicker(props) {
    const { label, defaultDate, inputFormat, setFieldValue, isEditing, disabled, readOnly, maxDate, minDate, customOnChange } = props, rest = __rest(props, ["label", "defaultDate", "inputFormat", "setFieldValue", "isEditing", "disabled", "readOnly", "maxDate", "minDate", "customOnChange"]);
    const [initialValue, setInitialValue] = React.useState(false);
    const [value, setValue] = React.useState(defaultDate ? new Date(defaultDate) : '');
    const handleChange = (newValue) => {
        setValue(newValue);
        setFieldValue(props.name, isValid(newValue) && newValue instanceof Date
            ? format(newValue, inputFormat)
            : newValue === null
                ? ''
                : newValue);
        if (customOnChange)
            customOnChange(newValue);
    };
    React.useEffect(() => {
        if (value) {
            setFieldValue(props.name, isValid(value) && value instanceof Date
                ? format(value, inputFormat)
                : value === null
                    ? ''
                    : value);
        }
    }, []);
    React.useEffect(() => {
        if (isEditing && !initialValue) {
            handleChange(new Date(isEditing));
            setInitialValue(true);
        }
        if (!isEditing && initialValue) {
            handleChange(null);
        }
    }, [isEditing]);
    return (_jsx(LocalizationProvider, Object.assign({ dateAdapter: AdapterDateFns }, { children: _jsx(DesktopDatePicker, { label: label, inputFormat: inputFormat, value: value, onChange: handleChange, disabled: disabled, readOnly: readOnly, maxDate: maxDate, minDate: minDate, renderInput: (params) => {
                return (_jsx(CssTextField, Object.assign({}, rest, params, { className: props.error ? 'MuiError' : '' }), void 0));
            } }, void 0) }), void 0));
}
