import { FC, useState } from 'react'
import { Alert, Box, Button, makeStyles, PasswordField } from '@perk-ui/core'
// import { FileText, Lock, User as UserICon, X } from 'react-feather'
import { useFormik } from 'formik'
import * as yup from 'yup'

import { useAuth } from '../features/auth/AuthContext'

interface ChangePasswordFormProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 2, 0, 0),
    height: 'calc(100% - 55px)',
    overflow: 'scroll',
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    },
  },
  alertSuccess: {
    marginBottom: theme.spacing(2),
    padding: theme.spacing(0, 2),
  },
}))

interface ChangePasswordFormValues {
  currentPassword: string
  password: string
  passwordConfirmation: string
}

const ChangePasswordForm: FC<ChangePasswordFormProps> = () => {
  const classes = useStyles()
  const { changePassword } = useAuth()
  const [passwordChanged, setPasswordChanged] = useState(false)

  const loginValidations = yup.object({
    currentPassword: yup.string().required('Current password is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/(?=.*[0-9])/, 'Password must have a number')
      .matches(/(?=.*[^A-Za-z0-9])/, 'Password must have a symbol'),
    passwordConfirmation: yup
      .string()
      .required('Password confirmation is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    isSubmitting,
    setFieldError,
    isValid,
    touched,
  } = useFormik<ChangePasswordFormValues>({
    enableReinitialize: true,
    initialValues: {
      currentPassword: '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: loginValidations,
    onSubmit: (values) => {
      return changePassword(values)
        .then(() => {
          setPasswordChanged(true)
          resetForm()
        })
        .catch(() => {
          setFieldError('currentPassword', 'Invalid password')
        })
    },
  })
  return (
    <Box className={classes.root}>
      <form className="form-content" onSubmit={handleSubmit}>
        {passwordChanged && (
          <Alert
            className={classes.alertSuccess}
            severity="success"
            fade
            grow
            onClose={() => {
              setPasswordChanged(false)
            }}
          >
            Password Updated
          </Alert>
        )}
        <PasswordField
          id="change-password-form-current-password"
          label="Current Password"
          name="currentPassword"
          placeholder="Enter your current password"
          fullWidth
          value={values.currentPassword}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={touched.currentPassword && Boolean(errors.currentPassword)}
          helperText={touched.currentPassword && errors.currentPassword}
        />
        <PasswordField
          id="change-password-form-new-password"
          label="New Password"
          name="password"
          placeholder="Enter your new password"
          fullWidth
          value={values.password}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={touched.password && Boolean(errors.password)}
          helperText={touched.password && errors.password}
        />
        <PasswordField
          id="change-password-form-confirm-new-password"
          label="Confirm New Password"
          name="passwordConfirmation"
          placeholder="Confirm new password"
          fullWidth
          value={values.passwordConfirmation}
          onChange={handleChange}
          onBlur={handleBlur}
          required
          error={
            touched.passwordConfirmation && Boolean(errors.passwordConfirmation)
          }
          helperText={
            touched.passwordConfirmation && errors.passwordConfirmation
          }
        />
        <Button
          id="change-password-form-submit-button"
          type="submit"
          loading={isSubmitting}
          disabled={!isValid}
        >
          Change Password
        </Button>
      </form>
    </Box>
  )
}

export default ChangePasswordForm
