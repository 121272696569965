import { FC, HTMLProps, ReactChild } from 'react'
import { Box, Grid, makeStyles } from '@perk-ui/core'
// import clsx from 'clsx'

// import BrandLogo from '../assets/BrandLogo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  bannerContainer: {
    height: '100%',
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  contentContainer: {
    height: '100%',
    '& form': {
      width: '100%',
      '& .MuiFormControl-root': {
        marginBottom: theme.spacing(3),
      },
      '& .MuiInput-root': {
        overflow: 'hidden',
      },
      '& .MuiButton-root': {
        marginBottom: theme.spacing(3),
        borderRadius: '16px',
      },
      '& .MuiLink-root': {
        textDecoration: 'none',
        fontWeight: 700,
        '&:visited': {
          color: theme.palette.primary.main,
        },
        '&:hover': {
          color: theme.palette.primary.dark,
          textDecoration: 'underline',
        },
      },
      '& .MuiAlert-root': {
        marginBottom: theme.spacing(3),
        '&.MuiAlert-standardSuccess': {
          color: theme.palette.success.dark,
          '& .MuiAlert-icon': {
            color: theme.palette.success.dark,
          },
        },
      },
      '& .form-checkbox-control-label': {
        margin: 0,
      },
    },
  },
}))

export interface SideBannerContainerProps extends HTMLProps<HTMLDivElement> {
  banner: ReactChild
  bannerWidth: number
  bannerSide: 'left' | 'right'
}

const SideBannerContainer: FC<SideBannerContainerProps> = ({
  children,
  banner,
  bannerSide,
  bannerWidth,
}) => {
  const classes = useStyles()

  return (
    <Grid container className={classes.root}>
      {bannerSide === 'left' && (
        <Grid item xs sm md={bannerWidth} className={classes.bannerContainer}>
          {banner}
        </Grid>
      )}

      <Grid item xs md className={classes.contentContainer}>
        {children}
      </Grid>

      {bannerSide === 'right' && (
        <Grid item xs sm md={bannerWidth}>
          <Box
            className={classes.bannerContainer}
            display={{ xs: 'none', md: 'flex' }}
          >
            {banner}
          </Box>
        </Grid>
      )}
    </Grid>
  )
}

export default SideBannerContainer
