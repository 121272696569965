/**
 * Returns the last element of @param array
 */
export function tail(array) {
    return array[array.length - 1];
}
/**
 * Returns an array of @param thing
 */
export function ensureArray(thing) {
    return Array.isArray(thing) ? thing : [thing];
}
/**
 * Returns the first element in @param array that has a @param key with value @param value
 *
 * i.e.
 * const list = [{color: 'red'}, {color: 'blue'}]
 * findBy('color', 'blue', list) //=> {color: 'blue'}
 */
export function findBy(key, value, array) {
    return array.find((item) => item[key] === value);
}
/**
 * Splits an array into two groups based on whether they pass a predicate.
 *
 * @example
 * const arr = [1, 2, 3, 'red', 'green', 'blue']
 * const [strings, numbers] = partition(arr, (item) => typeof item === 'string')
 */
export function partition(predicate, arr) {
    const trues = arr.filter((el) => predicate(el));
    const falses = arr.filter((el) => !predicate(el));
    return [trues, falses];
}
/**
 * Returns an object with values from @param items grouped by a key or predicate fn.
 * A simpler version of the groupBy available in lodash/alternatives.
 *
 * @example
 * const sentiments = [{ label: 'height', value: 67}, { label: 'height', value: 62 }]
 * groupBy('label', sentiments)
 * // => { height: [{ label: 'height', value: 67}, { label: 'height', value: 62 }] }
 *
 * @see https://lodash.com/docs/#groupBy
 *
 * @param keyOrFn A string key or function with which to group the items. If the predicate
 *                returns null, it is excluded from the @return grouping
 * @param items The items to process for grouping
 */
export function groupBy(keyOrFn, items) {
    return items.reduce((groups, item) => {
        const key = typeof keyOrFn === 'function' ? keyOrFn(item) : item[keyOrFn];
        if (key === null)
            return groups;
        if (key in groups) {
            groups[key].push(item);
        }
        else {
            groups[key] = [item];
        }
        return groups;
    }, {});
}
