import { FC, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  H1,
  H3,
  makeStyles,
  PageTitle,
  PasswordField,
  Tooltip,
} from '@perk-ui/core'
import { useFormik } from 'formik'
import { KeratinError } from 'keratin-authn/dist/types'
import { HelpCircle } from 'react-feather'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import AppLink from '../../../components/AppLink'
import SideBannerContainer from '../../../components/SideBannerContainer'
import apm from '../../../config/analytics'
import { useAuth } from '../../../features/auth/AuthContext'
import AuthenticationBase from '../AuthenticationBase'
import AuthenticationBanner from '../components/AuthenticationBanner'

interface PasswordResetProps {}

export interface PasswordResetLocationParams {
  token: string
}

interface PasswordResetFormValues {
  password: string
  passwordConfirmation: string
  token?: string
}

const COUNTDOWN_SECONDS = 3

const useStyles = makeStyles((theme) => ({
  helperTooltip: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing(1),
      width: '18px',
      height: '18px',
      color: theme.palette.primary.main,
    },
  },
}))

const PasswordReset: FC<PasswordResetProps> = () => {
  const { resetPassword, initialize } = useAuth()
  const classes = useStyles()
  const [ticker, setTicker] = useState<number>(COUNTDOWN_SECONDS)
  const [didReset, setDidReset] = useState(false)

  const match = useRouteMatch<PasswordResetLocationParams>()
  const token = match.params.token

  const startTicking = () => {
    const tId = window.setInterval(() => {
      setTicker((tick) => {
        if (tick <= 1) {
          window.clearInterval(tId)
        }

        return tick - 1
      })
    }, 1000)
  }

  const loginValidations = yup.object({
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be at least 8 characters')
      .matches(/(?=.*[0-9])/, 'Password must have a number')
      .matches(/(?=.*[^A-Za-z0-9])/, 'Password must have a symbol'),
    passwordConfirmation: yup
      .string()
      .required('Password confirmation is required')
      .oneOf([yup.ref('password'), null], 'Passwords must match'),
  })

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldError,
    isSubmitting,
    isValid,
    touched,
  } = useFormik<PasswordResetFormValues>({
    enableReinitialize: true,
    initialValues: {
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: loginValidations,
    onSubmit: (values) => {
      const { password } = values
      resetPassword({ password, token })
        .then((newToken) => {
          if (!newToken) {
            // Reset failed for an unknown reason. Bail
            setFieldError(
              'password',
              'Failed to reset password. Token invalid.',
            )
          } else {
            setDidReset(true)
            startTicking()

            setTimeout(() => {
              initialize()
            }, COUNTDOWN_SECONDS * 1000)
          }
        })
        .catch((error) => {
          apm.captureError(error)
          const keratinErrors = error as KeratinError[]

          const hasInsecurePassword = keratinErrors.some(
            (e) => e.field === 'password' && e.message === 'INSECURE',
          )
          if (hasInsecurePassword) {
            setFieldError(
              'password',
              'That is a common password. Please try something more unique.',
            )
          }

          const hasTokenIssue = keratinErrors.some((e) => e.field === 'token')
          if (hasTokenIssue) {
            setFieldError(
              'token',
              'It looks like your password reset link has expired. Please click below to get a new one.',
            )
          }
          return
        })
    },
  })

  return (
    <SideBannerContainer
      bannerWidth={5}
      bannerSide="left"
      banner={<AuthenticationBanner />}
    >
      <PageTitle title="Password Reset" />
      <AuthenticationBase>
        <Box className="form-header centered">
          <H1>Change Password</H1>
          <H3>Please set up your new password</H3>
        </Box>
        <form className="form-content" onSubmit={handleSubmit}>
          {didReset && (
            <Alert severity="success" fade grow>
              Password successfully reset!
              <br />
              Redirecting you in {ticker} seconds.
            </Alert>
          )}
          {errors.token && (
            <Alert severity="error" fade grow>
              {errors.token}
              <br />
              <br />
              <AppLink to="/forgot-password-new">Request a new link</AppLink>
            </Alert>
          )}
          <PasswordField
            label={
              <span className={classes.helperTooltip}>
                Password{' '}
                <Tooltip title="Password must be at least 8 characters and include a symbol and a number">
                  <HelpCircle />
                </Tooltip>
              </span>
            }
            name="password"
            placeholder="Enter new password"
            fullWidth
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
          />
          <PasswordField
            label="Confirm Password"
            name="passwordConfirmation"
            placeholder="Confirm new password"
            fullWidth
            value={values.passwordConfirmation}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.passwordConfirmation &&
              Boolean(errors.passwordConfirmation)
            }
            helperText={
              touched.passwordConfirmation && errors.passwordConfirmation
            }
          />
          <Button
            type="submit"
            fullWidth
            loading={isSubmitting}
            disabled={!isValid}
          >
            Confirm Password Change
          </Button>
        </form>
      </AuthenticationBase>
    </SideBannerContainer>
  )
}

export default PasswordReset
