import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { AdverseEvent } from './useAdverseEvents'

interface AdverseEventsResponse {
  adverseEvent: AdverseEvent
}

const useAdverseEvent = (
  adverseEventId: string,
  extraOptions: UseQueryOptions<AdverseEvent> = {},
) => {
  const currentSiteId = useCurrentSite().id
  const key = QueryKeys.adverseEvent(adverseEventId)
  const url = `/sites/adverse_events/${adverseEventId}?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () =>
      request.get<AdverseEventsResponse>(url).then((r) => r.data.adverseEvent),
    extraOptions,
  )
}

export default useAdverseEvent
