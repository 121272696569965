export interface PatientFlowLocationParams {
  patientId: string
  caseId: string
  treatmentId: string
  sessionId: string
}

const PatientFlowUrls = {
  base: {
    match: '/flow',
  },
  hasPatient: {
    match: '/flow/:patientId',
  },
  invitePatient: {
    match: '/flow',
    create: () => `/flow`,
  },
  editPatient: {
    match: '/flow/:patientId/edit',
    create: (patientId: string) => `/flow/${patientId}/edit`,
  },
  addCase: {
    match: '/flow/:patientId/case',
    create: (patientId: string) => `/flow/${patientId}/case`,
  },
  diagnosis: {
    match: '/flow/:patientId/case/:caseId/diagnosis',
    create: (patientId: string, caseId: string) =>
      `/flow/${patientId}/case/${caseId}/diagnosis`,
  },
  treatment: {
    match: '/flow/:patientId/case/:caseId/treatment/:treatmentId',
    create: (patientId: string, caseId: string, treatmentId: string) =>
      `/flow/${patientId}/case/${caseId}/treatment/${treatmentId}`,
  },
  addTreatment: {
    match: '/flow/:patientId/case/:caseId/treatment',
    create: (patientId: string, caseId: string) =>
      `/flow/${patientId}/case/${caseId}/treatment`,
  },
  session: {
    match:
      '/flow/:patientId/case/:caseId/treatment/:treatmentId/session/:sessionId',
    create: (
      patientId: string,
      caseId: string,
      treatmentId: string,
      sessionId: string,
    ) =>
      `/flow/${patientId}/case/${caseId}/treatment/${treatmentId}/session/${sessionId}`,
  },
  addSession: {
    match: '/flow/:patientId/case/:caseId/treatment/:treatmentId/session',
    create: (patientId: string, caseId: string, treatmentId: string) =>
      `/flow/${patientId}/case/${caseId}/treatment/${treatmentId}/session`,
  },
}

export default PatientFlowUrls
