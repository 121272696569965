import { FC, useEffect, useState } from 'react'
import {
  areQuestionsAnswered,
  Box,
  Button,
  ChoiceDropdownQuestion,
  CircularProgress,
  findBy,
  getQuestions,
  ISurveyGroupItem,
  ISurveyResponseAnswer,
  makeStyles,
  OnAnswerCallback,
  QuestionVariants,
  SurveyItem,
  SurveyItemTypes,
  tail,
} from '@perk-ui/core'
import { useHistory } from 'react-router'

import { buildTreatmentDetailSlug } from '../../../components/buildTreatmentDetailSlug'
import useBodySites, {
  BodySite,
} from '../../../features/query-hooks/useBodySites'
import useCreateSurveyResponse from '../../../features/query-hooks/useCreateSurveyResponse'
import useInjectionSites, {
  InjectionSite,
} from '../../../features/query-hooks/useInjectionSites'
// import useManyAnatomicalLocations from '../../../features/query-hooks/useManyAnatomicalLocations'
import usePatientCases, {
  PatientCase,
} from '../../../features/query-hooks/usePatientCases'
import { PatientTreatment } from '../../../features/query-hooks/usePatientTreatment'
import useSurvey from '../../../features/query-hooks/useSurvey'
import useSurveyResponse from '../../../features/query-hooks/useSurveyResponse'
import useSurveyResponseSearch from '../../../features/query-hooks/useSurveyResponseSearch'
import PatientFlowUrls from '../PatientFlowUrls'

const useStyles = makeStyles((theme) => ({
  saveButton: {
    marginTop: theme.spacing(3),
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
  },
}))

interface TreatmentDetailsFormProps {
  medicalTreatmentSlug: string
  treatment: PatientTreatment
  patientCase: PatientCase
  onSubmit: () => void
}

const TreatmentDetailsForm: FC<TreatmentDetailsFormProps> = ({
  medicalTreatmentSlug,
  treatment,
  patientCase,
  onSubmit,
}) => {
  const classes = useStyles()
  const { push } = useHistory()
  const [startedAt] = useState(() => new Date().toISOString())
  const [answers, setAnswers] = useState<ISurveyResponseAnswer[]>([])
  const patientId = patientCase.patientId

  const surveySlug = buildTreatmentDetailSlug(medicalTreatmentSlug)
  const medicalConditionIds =
    patientCase.patientDiagnoses?.map((d) => d.medicalConditionId) || []
  const dedupedMedConditionIds = Array.from(new Set(medicalConditionIds))

  const { data: bodySites = [] } = useBodySites()
  const { data: patientCases = [] } = usePatientCases(patientId)
  const createSurveyResponse = useCreateSurveyResponse()
  const { data: survey, isLoading } = useSurvey({
    slug: medicalTreatmentSlug && surveySlug,
  })

  const { data: injectionSites = [] } = useInjectionSites(
    dedupedMedConditionIds,
  )

  const treatments = patientCase.patientTreatments || []
  const treatmentsIds: string[] = []
  patientCases.map((pc) => {
    pc.patientTreatments?.map((pt) => treatmentsIds.push(pt.id))
  })
  const { data: treatmentDetails } = useSurveyResponseSearch({
    ownerIds: treatmentsIds,
    ownerType: 'PatientTreatment',
  })

  const otherIncompleteTreatment = treatments.find((t) => {
    const hasTreatmentDetails = (treatmentDetails?.surveyResponses || []).some(
      (sr) => t.id === sr.ownerId,
    )

    if (!hasTreatmentDetails && t.id !== treatment.id) {
      return t
    }
  })

  const nextIncompletePatientCaseDiagnosisOrTreatment = patientCases.find(
    (pc) => {
      const pcTreatmentIncompleteTreatmentDetails = (
        pc.patientTreatments || []
      ).find((t) => {
        const hasTreatmentDetails = (
          treatmentDetails?.surveyResponses || []
        ).some((sr) => t.id === sr.ownerId)
        if (!hasTreatmentDetails && t.id !== treatment.id) {
          return t
        }
      })
      return (
        pc.patientDiagnoses?.length === 0 ||
        pc.patientTreatments?.length === 0 ||
        pcTreatmentIncompleteTreatmentDetails
      )
    },
  )

  const { data: existingResponses } = useSurveyResponseSearch({
    ownerIds: [treatment.id],
    ownerType: 'PatientTreatment',
    surveySlugs: [surveySlug],
  })
  const { data: existingSrWithAnswers } = useSurveyResponse({
    id: tail(existingResponses?.surveyResponses || [])?.id,
    ownerType: 'PatientTreatment',
  })
  useEffect(() => {
    if (existingSrWithAnswers) {
      setAnswers(
        existingSrWithAnswers.surveyResponseAnswers.map((sra) => ({
          surveyQuestionId: sra.surveyQuestionId,
          values: sra.values,
        })),
      )
    }
  }, [existingSrWithAnswers])

  const rootItem = survey?.items[0] as ISurveyGroupItem | undefined
  const canSave =
    rootItem &&
    areQuestionsAnswered(
      getQuestions(rootItem),
      answers,
      patientCase.study?.slug,
    )
  const bodySite = findBy('id', patientCase.bodySiteId, bodySites)
  rootItem && addInjectionSiteItems(rootItem, injectionSites, bodySite)

  const handleAnswer: OnAnswerCallback = (item, answer) => {
    const answerItem: ISurveyResponseAnswer = {
      surveyQuestionId: item.id,
      values: Array.isArray(answer) ? answer : [answer],
    }

    const nextAnswers = answers
      .filter((a) => a.surveyQuestionId !== item.id)
      .concat(answerItem)
    setAnswers(nextAnswers)

    return Promise.resolve()
  }

  const handleSubmit = () => {
    if (!survey) return

    createSurveyResponse
      .mutateAsync({
        surveyId: survey.id,
        ownerId: treatment.id,
        ownerType: 'PatientTreatment',
        answers,
        startedAt,
        completedAt: new Date().toISOString(),
        treatmentIds: treatmentsIds,
      })
      .then(() => {
        onSubmit()
        if (otherIncompleteTreatment) {
          if (otherIncompleteTreatment.parentId) {
            push(
              PatientFlowUrls.session.create(
                patientId,
                patientCase.id,
                otherIncompleteTreatment.parentId,
                otherIncompleteTreatment.id,
              ),
            )
          } else {
            push(
              PatientFlowUrls.treatment.create(
                patientId,
                patientCase.id,
                otherIncompleteTreatment.id,
              ),
            )
          }
        } else {
          if (nextIncompletePatientCaseDiagnosisOrTreatment) {
            if (
              nextIncompletePatientCaseDiagnosisOrTreatment.patientDiagnoses
                ?.length === 0
            ) {
              push({
                pathname: `/flow/${patientId}/case/${nextIncompletePatientCaseDiagnosisOrTreatment.id}/diagnosis`,
                state: { from: location.pathname },
              })
            } else {
              const nextIncompleteTreatment =
                nextIncompletePatientCaseDiagnosisOrTreatment.patientTreatments?.find(
                  (t) => {
                    const hasTreatmentDetails = (
                      treatmentDetails?.surveyResponses || []
                    ).some((sr) => t.id === sr.ownerId)

                    if (!hasTreatmentDetails && t.id !== treatment.id) {
                      return t
                    }
                  },
                )
              if (nextIncompleteTreatment) {
                if (nextIncompleteTreatment.parentId) {
                  push(
                    PatientFlowUrls.session.create(
                      patientId,
                      nextIncompletePatientCaseDiagnosisOrTreatment.id,
                      nextIncompleteTreatment.parentId,
                      nextIncompleteTreatment.id,
                    ),
                  )
                } else {
                  push(
                    PatientFlowUrls.treatment.create(
                      patientId,
                      nextIncompletePatientCaseDiagnosisOrTreatment.id,
                      nextIncompleteTreatment.id,
                    ),
                  )
                }
              } else {
                push({
                  pathname: `/flow/${patientId}/case/${nextIncompletePatientCaseDiagnosisOrTreatment.id}/treatment`,
                  state: { from: location.pathname },
                })
              }
            }
          } else {
            push(`/patients/${patientId}`)
          }
        }
      })
  }

  const currentBodySite = bodySites.find(
    (bs) => bs.id === patientCase.bodySiteId,
  )
  const currentBodySiteKey = currentBodySite?.bodySiteKey
  return isLoading ? (
    <Box className={classes.loadingContainer}>
      <CircularProgress />
    </Box>
  ) : survey ? (
    <div>
      <SurveyItem
        item={survey.items[0]}
        answers={answers}
        onAnswer={handleAnswer}
        studySlug={patientCase.study?.slug}
        bodySiteKey={currentBodySiteKey}
      />
      <Button
        className={classes.saveButton}
        type="button"
        fullWidth
        disabled={!canSave}
        onClick={handleSubmit}
      >
        Save
      </Button>
    </div>
  ) : null
}

const injectionItemRegex = /-INJECTION-\d$/

// Turns all Injection Site questions into a choice-dropdown with
// injectionSites as options.
const addInjectionSiteItems = (
  rootItem: ISurveyGroupItem,
  injectionSites: InjectionSite[],
  fallback: BodySite | undefined,
) => {
  for (const item of rootItem?.items || []) {
    if (
      item.slug.match(injectionItemRegex) &&
      item.type === SurveyItemTypes.question
    ) {
      const injectionItem = item as unknown as ChoiceDropdownQuestion
      injectionItem.variant = QuestionVariants.choiceDropdown

      if (injectionSites.length > 0) {
        injectionItem.data.answerOptions = injectionSites.map((al) => ({
          label: al.name,
          value: al.name,
        }))
      } else {
        injectionItem.data.answerOptions = [
          {
            label: fallback?.name || 'Other',
            value: fallback?.slug || 'other',
          },
        ]
      }
    }
  }
}

export default TreatmentDetailsForm
