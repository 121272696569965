import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

interface UpdateOnboardingStepResponse {
  onboardingStep: string
}

export interface updateOnboardingStepVars {
  onboardingStep: string
}

const useUpdateOnboardingStep = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite()?.id || ''

  return useMutation(({ onboardingStep }: updateOnboardingStepVars) => {
    const url = '/sites/onboarding_step'

    return request
      .post<UpdateOnboardingStepResponse>(url, {
        siteIds: [siteId],
        onboardingStep,
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.onboardingStep(siteId))
        return r.data.onboardingStep
      })
  })
}

export default useUpdateOnboardingStep
