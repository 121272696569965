import { FC, HTMLProps } from 'react'
import { Box, makeStyles } from '@perk-ui/core'
// import clsx from 'clsx'

// import BrandLogo from '../assets/BrandLogo.png'

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    padding: theme.spacing(3),
    '& .authentication-container': {
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'center',
      // alignItems: 'center',
      height: '100%',
      overflow: 'hidden',
      '& .logo-content': {
        textAlign: 'center',
      },
      '& .form-header': {
        margin: theme.spacing(3, 0, 4, 0),
        textAlign: 'center',
        width: '100%',
        flex: '0 1 auto',
        '& h1': {
          fontSize: '32px',
          fontWeight: '700',
        },
        '& h3': {
          fontSize: '16px',
          fontWeight: '500',
        },
        '&.centered': {
          textAlign: 'center',
        },
      },
      '& .form-content': {
        maxWidth: '550px',
        margin: '0 auto',
        overflow: 'auto',
        '& input::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '& input::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
      },
    },
  },
}))

export interface AuthenticationBaseProps extends HTMLProps<HTMLDivElement> {}

const AuthenticationBase: FC<AuthenticationBaseProps> = ({ children }) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className="authentication-container">
        <Box className="logo-content">
          <img
            src={`${process.env.PUBLIC_URL}/assets/DataBiologics_Logo.png`}
            height="50"
          />
        </Box>
        {children}
      </Box>
    </Box>
  )
}

export default AuthenticationBase
