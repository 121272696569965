import { useMemo } from 'react'
import { tail } from '@perk-ui/core'
import { format, isValid, parse } from 'date-fns'

import { useSitesContext } from '../components/SitesProvider'

const useLocalizedDatePattern = (showTime = false): string => {
  const { currentSite } = useSitesContext()

  const country = currentSite?.country
  const isCanada = country === 'canada'

  return showTime
    ? isCanada
      ? 'dd/MM/yyyy HH:mm:ss'
      : 'MM/dd/yyyy HH:mm:ss'
    : isCanada
    ? 'dd/MM/yyyy'
    : 'MM/dd/yyyy'
}

const localizedFormatDate = (datePattern: string, date: number | Date) => {
  return format(date, datePattern)
}

const localizedParseDate = (datePattern: string, date: string) => {
  const asDate = parse(date, datePattern, new Date())
  const has4LetterYear = tail(date.split('/')).length === 4
  return isValid(asDate) && has4LetterYear && asDate
}

const useDates = (showTime = false) => {
  const localizedDatePattern = useLocalizedDatePattern(showTime)

  return useMemo(
    () => ({
      localizedFormatDate: (date: number | Date) =>
        localizedFormatDate(localizedDatePattern, date),
      localizedParseDate: (date: string) =>
        localizedParseDate(localizedDatePattern, date),
      localizedDatePattern,
    }),
    [localizedParseDate, localizedFormatDate, useLocalizedDatePattern],
  )
}

export default useDates
