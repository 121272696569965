import { useQuery, UseQueryOptions } from 'react-query'

import { useSitesContext } from '../../components/SitesProvider'
import request from '../../config/axios'
import { useUser } from '../auth/AuthContext'
import QueryKeys from './QueryKeys'
import { Pagination } from './types'
import { Patient } from './usePatient'
import { PatientCase } from './usePatientCases'
import { Provider } from './useProviders'

interface AdverseEventsListResponse {
  adverseEvents: AdverseEvent[]
  meta: Pagination
}

export interface AdverseEvent {
  id: string
  expectedness: 'expected' | 'unexpected'
  issueType: 'body_part_treated' | 'tissue_collection_site' | 'other'
  patientNotes: string
  problemDate?: string
  dateOfProblem?: string
  providerNotes: string
  relatedness: 'related' | 'unrelated'
  seekingMedicalTreatment:
    | 'no'
    | 'treating_doctor'
    | 'emergency_department'
    | 'primary_care_doctor'
    | 'surgery'
  seriousness:
    | 'grade_1_mild'
    | 'grade_2_moderate'
    | 'grade_3_severe'
    | 'grade_4_life_threatening'
    | 'grade_5_death'
  status: 'created' | 'adjudicated'
  issue: string
  bodySiteId: string
  patientId: string
  providerId: string
  patientCaseId?: string
  siteId: string
  createdAt: string
  updatedAt: string
  patient: Patient
  provider?: Provider
  patientCase?: PatientCase
}

export const SeriousnessLabelMap = {
  grade_1_mild: 'Mild',
  grade_2_moderate: 'Moderate',
  grade_3_severe: 'Severe',
  grade_4_life_threatening: 'Life Threatening',
  grade_5_death: 'Death',
}

export const ExpectednessLabelMap = {
  expected: 'Expected',
  unexpected: 'Unexpected',
}
export const RelatednessLabelMap = {
  related: 'Related',
  unrelated: 'Unrelated',
}

interface AdverseEventsParams {
  page?: number
  perPage?: number
  providerId?: string
  patientId?: string
  status?: string
}

const useAdverseEvents = (
  { patientId, providerId, page, perPage, status }: AdverseEventsParams,
  extraOptions: UseQueryOptions<AdverseEventsListResponse> = {},
) => {
  const currentSite = useSitesContext().currentSite
  const currentSiteId = currentSite?.id
  const currentUser = useUser()
  const isLoggedIn: boolean = currentUser ? true : false
  const providerParam =
    providerId && providerId !== 'All Providers' ? providerId : undefined

  const shouldFetch = !!isLoggedIn && !!currentSite

  const key = currentSiteId
    ? QueryKeys.adverseEvents({
        currentSiteId,
        status,
        providerId,
        patientId,
        page,
        perPage,
      })
    : []

  const url = `/sites/adverse_events?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () =>
      request
        .get<AdverseEventsListResponse>(url, {
          params: {
            provider_id: providerParam,
            patient_id: patientId,
            page,
            per_page: perPage,
            status,
          },
        })
        .then((r) => r.data),
    {
      enabled: shouldFetch,
      ...extraOptions,
    },
  )
}

export default useAdverseEvents
