import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import queryClient from '../../config/react-query'
import QueryKeys from './QueryKeys'

interface TableauToken {
  type?:
    | 'tableauEssentialToken'
    | 'tableauPerformanceToken'
    | 'tableauAEToken'
    | 'tableauReportToken'
    | 'tableauStarterToken'
}

interface TableauTokenResponse {
  tableauToken: string
}

const useTableauToken = (
  { type }: TableauToken,
  extraOptions: UseQueryOptions = {},
) => {
  const currentSiteId = useCurrentSite().id
  const url = `/sites/tableau_token?site_ids[]=${currentSiteId}`
  let key

  switch (type) {
    case 'tableauEssentialToken':
      key = QueryKeys.tableauEssentialToken()
      break
    case 'tableauPerformanceToken':
      key = QueryKeys.tableauPerformanceToken()
      break
    case 'tableauAEToken':
      key = QueryKeys.tableauAEToken()
      break
    case 'tableauReportToken':
      key = QueryKeys.tableauReportToken()
      break
    case 'tableauStarterToken':
      key = QueryKeys.tableauReportToken()
      break
    default:
      key = QueryKeys.tableauStarterToken()
      break
  }

  return useQuery(
    key,
    () =>
      request.get<TableauTokenResponse>(url).then((r) => {
        queryClient.invalidateQueries(QueryKeys.tableauEssentialToken())
        queryClient.invalidateQueries(QueryKeys.tableauPerformanceToken())
        queryClient.invalidateQueries(QueryKeys.tableauAEToken())
        queryClient.invalidateQueries(QueryKeys.tableauReportToken())
        queryClient.invalidateQueries(QueryKeys.tableauStarterToken())
        return r.data.tableauToken
      }),
    {
      ...extraOptions,
      // staleTime: 0,
      // cacheTime: 0,
      // refetchInterval: 0,
    },
  )
}

export default useTableauToken
