import { FC } from 'react'
import {
  Box,
  Button,
  CardHeader,
  FormControlLabel,
  H1,
  H3,
  H5,
  Icon,
  IconButton,
  makeStyles,
  Radio,
  RadioGroup,
  TextField,
} from '@perk-ui/core'
import { AxiosError } from 'axios'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { ArrowLeft } from 'react-feather'
import { useLocation, useRouteMatch } from 'react-router'
import * as yup from 'yup'

import AppLinkButton from '../../../components/AppLinkButton'
import useCreateProtocol from '../../../features/query-hooks/useCreateProtocol'
import useProtocol from '../../../features/query-hooks/useProtocol'
import { ProtocolType } from '../../../features/query-hooks/useProtocols'
import useUpdateProtocol from '../../../features/query-hooks/useUpdateProtocol'
import { useHistory } from '../../../utils/useHistory'
// import ArchiveModal from '../components/ArchiveModal'
import ProtocolFlowUrls, {
  ProtocolFlowLocationParams,
} from '../ProtocolFlowUrls'

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      // paddingBottom: theme.spacing(2),
    },
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(2),
    },
  },
  archiveButton: {
    cursor: 'pointer',
  },
  dialogContent: {
    '& > p': {
      textAlign: 'start',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  headerIcon: {
    marginRight: theme.spacing(1),
  },

  protocolContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
    maxWidth: '548px',
    margin: 'auto',
  },
  protocolStateIcon: {
    display: 'flex',
    backgroundColor: '#F1F1F1',
    borderRadius: '50%',
    marginBottom: theme.spacing(2),
    '& svg': {
      color: '#6B6B6B',
    },
  },
  protocolStateTitle: {
    color: '#000',
    fontWeight: 700,
    fontSize: '32px',
    marginBottom: theme.spacing(1),
  },
  protocolStateText: {
    color: '#000',
    fontWeight: 400,
    fontSize: '16px',
    marginBottom: theme.spacing(2),
  },
  protocolStateButton: {
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    minHeight: 40,
    padding: theme.spacing(1, 3),
  },

  radioGroupContainer: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  radioGroup: {
    width: '100%',
    '& .MuiBox-root': {
      width: 'auto',
    },
  },
  radioInput: {
    marginBottom: '10px',
    margin: 0,
    paddingLeft: '5px',
    paddingRight: '15px',
    backgroundColor: '#f5f5f8',
    border: '1px solid #f5f5f8',
    borderRadius: '16px',
    '&:hover': {
      border: '1px solid #000000',
    },
    '& .MuiRadio-root': {
      display: 'none',
    },
  },
  radioInputActive: {
    border: '1px solid #56AAEB',
    backgroundColor: '#EAF2F5',
  },
}))

interface ProtocolFormValues {
  name: string
  slug: string
  description: string
  type: ProtocolType
}

// type ProtocolFormValuesKeys = 'name' | 'slug' | 'description' | 'type'

// TODO Devise some way to disable browser autocomplete
// @see https://gist.github.com/niksumeiko/360164708c3b326bd1c8#gistcomment-3748492
const Create: FC = () => {
  const classes = useStyles()
  const { goBack, push } = useHistory()
  const location = useLocation<{ from?: string }>()

  const hasProtocolMatch = useRouteMatch<ProtocolFlowLocationParams>(
    ProtocolFlowUrls.hasProtocol.match,
  )
  const protocolId = hasProtocolMatch ? hasProtocolMatch.params.protocolId : ''
  const hasProtocol = !!hasProtocolMatch
  const { data: protocol } = useProtocol(protocolId, {
    enabled: hasProtocol,
  })

  const protocolEditMatch = useRouteMatch<ProtocolFlowLocationParams>(
    ProtocolFlowUrls.editProtocol.match,
  )
  const isEditing = Boolean(protocolEditMatch && protocolId)

  const createProtocol = useCreateProtocol()
  const updateProtocol = useUpdateProtocol()

  const { data: initialValues } = useProtocol(protocolId || '', {
    enabled: Boolean(isEditing && protocolId),
  })

  const protocolValidations = yup.object({
    name: yup.string(),
    slug: yup.string(),
    description: yup.string(),
    type: yup.string().defined('Type name is required'),
  })

  const initialProtocolValues = () => {
    if (initialValues) {
      return {
        name: initialValues.name,
        slug: initialValues.slug,
        type: initialValues.type,
        description: initialValues.description,
      } as ProtocolFormValues
    } else {
      return {
        name: 'Protocol Default Name',
        slug: 'protcol-default-slug',
        type: '' as ProtocolType,
        description: '',
      }
    }
  }

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    // setFieldTouched,
    isSubmitting,
    // isValid,
    touched,
    // Ideally, useFormik should typed with `PatientAndCaseFormValues | ProtocolFormValues`
    // but that causes a lot of type headaches elsewhere, and this is reasonably good.
  } = useFormik<ProtocolFormValues>({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues: initialProtocolValues(),
    validationSchema: protocolValidations,
    onSubmit: (values) => {
      // Trim whitespace from all values
      Object.keys(values).forEach((key) => {
        const k = key as keyof ProtocolFormValues
        if (typeof values[k] === 'string') {
          ;(values[k] as string).trim()
        }
      })
      values.slug = values.slug + new Date().getTime()
      let mutation
      if (!isEditing) {
        // Creating a new protocol
        mutation = createProtocol
          .mutateAsync({
            ...values,
            status: 'inactive',
          } as ProtocolFormValues)
          .then((protocol) => {
            if (protocol.type === 'FullProtocol') {
              push(ProtocolFlowUrls.addDiagnosis.create(protocol.id))
            } else {
              push(ProtocolFlowUrls.addTreatment.create(protocol.id))
            }
          })
      } else {
        // Updating an existing patient
        mutation = updateProtocol
          .mutateAsync({
            id: protocolId,
            ...values,
          } as ProtocolFormValues)
          .then((protocol) => {
            if (protocol.type === 'FullProtocol') {
              push(ProtocolFlowUrls.addDiagnosis.create(protocol.id))
            } else {
              push(ProtocolFlowUrls.addTreatment.create(protocol.id))
            }
          })
      }

      return mutation.catch((err) => {
        if (err?.isAxiosError) {
          const error = err as AxiosError
          if (error.response?.status === 422) {
            setFieldError('type', 'Error ocurred, please try again')
          }
        }
      })
    },
  })

  const handleGoBack = () => {
    if (location.state?.from === '/home') {
      push('/home')
    } else {
      goBack()
    }
  }

  const renderHeader = () => {
    return isEditing ? (
      <div className={classes.header}>
        <IconButton
          className={classes.headerIcon}
          onClick={handleGoBack}
          size="large"
        >
          <Icon size="x-large">
            <ArrowLeft />
          </Icon>
        </IconButton>
        <H1>{title}</H1>
      </div>
    ) : (
      <>
        <H3 className={classes.protocolStateTitle}>Create Protocol</H3>
        <H5 className={classes.protocolStateText}>
          Select what type of protocol you would like to create
        </H5>
      </>
    )
  }

  // const hasUpdatedFields = () => {
  //   let updateAvailable = false
  //   if (!initialValues) return true
  //   Object.keys(values).forEach((key: string) => {
  //     const valueKey = key as ProtocolFormValuesKeys
  //     const initialValuesVal = initialValues
  //       ? initialValues[valueKey]
  //       : undefined
  //     if (initialValues && values[valueKey] !== initialValuesVal)
  //       updateAvailable = true
  //   })
  //   return updateAvailable
  // }

  const title = hasProtocol ? (protocol ? protocol.name : '') : 'New Patient'

  return (
    <>
      <Box p={3} height={'100%'}>
        <Box className={classes.protocolContainer}>
          {renderHeader()}
          <form className={classes.form} onSubmit={handleSubmit}>
            {isEditing && (
              <TextField
                label="Name"
                name="name"
                fullWidth
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.name && Boolean(errors.name)}
                helperText={touched.name && errors.name}
              />
            )}
            {isEditing && (
              <TextField
                label="Description"
                name="description"
                multiline
                fullWidth
                value={values.description}
                onChange={handleChange}
                onBlur={handleBlur}
                error={touched.description && Boolean(errors.description)}
                helperText={touched.description && errors.description}
              />
            )}
            {!isEditing && (
              <div className={classes.radioGroupContainer}>
                <RadioGroup className={classes.radioGroup} value={values.type}>
                  <FormControlLabel
                    value="FullProtocol"
                    className={clsx(classes.radioInput, {
                      [classes.radioInputActive]: values.type == 'FullProtocol',
                    })}
                    control={
                      <Radio
                        onClick={() => {
                          setFieldValue('type', 'FullProtocol')
                        }}
                      />
                    }
                    label={
                      <CardHeader
                        title={'Full Protocol'}
                        subheader={
                          'Include Body Part, Diagnosis and Treatments on your protocol.'
                        }
                      />
                    }
                  />
                  <FormControlLabel
                    value="TreatmentProtocol"
                    className={clsx(classes.radioInput, {
                      [classes.radioInputActive]:
                        values.type == 'TreatmentProtocol',
                    })}
                    control={
                      <Radio
                        onClick={() => {
                          setFieldValue('type', 'TreatmentProtocol')
                        }}
                      />
                    }
                    label={
                      <CardHeader
                        title={'Treatment Protocol'}
                        subheader={'Include Treatments on your protocol.'}
                      />
                    }
                  />
                </RadioGroup>
              </div>
            )}
            <Button
              type="submit"
              fullWidth
              className={classes.protocolStateButton}
              loading={isSubmitting}
              // disabled={!isValid}
            >
              Next
            </Button>
            <AppLinkButton
              fullWidth
              variant="text"
              className={classes.protocolStateButton}
              to={'/home'}
            >
              Cancel
            </AppLinkButton>
          </form>
        </Box>
      </Box>
    </>
  )
}

export default Create
