import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Patient } from './usePatient'

interface PatientUpdateResponse {
  patient: Patient
}

interface PatientUpdateVars extends Partial<Omit<Patient, 'sites'>> {}

const useUpdatePatient = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation(({ id, ...vars }: PatientUpdateVars) => {
    const url = `/sites/patients/${id}`
    return request
      .patch<PatientUpdateResponse>(url, {
        siteIds: [siteId],
        patient: {
          ...vars,
          email: vars.email || null,
          medicalRecordNumber: vars.medicalRecordNumber || undefined,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(
          QueryKeys.patient({
            currentSiteId: siteId,
            patientId: r.data?.patient.id,
          }),
        )
        queryClient.invalidateQueries(
          QueryKeys.patientsSearch({ currentSiteId: siteId }),
        )
        return r.data
      })
  })
}

export default useUpdatePatient
