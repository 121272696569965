import { FC } from 'react'
import {
  Box,
  capitalize,
  Card,
  Divider,
  findBy,
  H2,
  H3,
  H5,
  IconButton,
  PageTitle,
  toRelativeDateString,
} from '@perk-ui/core'
import {
  addWeeks,
  endOfDay,
  isAfter,
  isBefore,
  isFuture,
  isPast,
  parseISO,
  subWeeks,
} from 'date-fns'
import { zonedTimeToUtc } from 'date-fns-tz'
import { ArrowLeft } from 'react-feather'
import { useLocation, useParams } from 'react-router'

import { useSitesContext } from '../../components/SitesProvider'
import {
  PENETRATION_PAIN_LOCATION,
  PGII_SCORE,
  SATISFACTION_PSC,
  SATISFACTION_SCORE,
  surveyPeriodsOrdered,
  TIME_ZONES,
  UROLOGY_CONDITION,
} from '../../config/constants'
import { templateTheme as theme } from '../../config/theme'
import useBodySites from '../../features/query-hooks/useBodySites'
import usePatientCases from '../../features/query-hooks/usePatientCases'
import {
  SurveyResponse,
  SurveyResponseScore,
} from '../../features/query-hooks/useSurveyResponse'
import useSurveyResponseSearch from '../../features/query-hooks/useSurveyResponseSearch'
import useDates from '../../hooks/useDates'
import { useHistory } from '../../utils/useHistory'

export interface PatientCaseSurveysProps {}

const statusProperties = {
  completed: {
    scheduleLabel: 'Completed',
    label: 'Not open',
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  'not-open': {
    scheduleLabel: 'Opens',
    label: 'Not open',
    color: theme.palette.common.black,
    fontWeight: 700,
  },
  open: {
    scheduleLabel: 'Due',
    label: 'Open',
    color: theme.palette.success.main,
    fontWeight: 700,
  },
  expired: {
    scheduleLabel: 'Expired',
    label: 'Expired',
    color: theme.palette.error.main,
    fontWeight: 700,
  },
}

const isRelativeDate = (date: Date): boolean => {
  return (
    !isAfter(date, addWeeks(Date.now(), 1)) &&
    !isBefore(date, subWeeks(Date.now(), 1))
  )
}

const getSurveyStatus = (
  scores: SurveyResponseScore[],
  availableOn: Date,
  expiresOn: Date,
  timeZone: string,
  survey: SurveyResponse,
) => {
  const score = scores[0]

  if (score) {
    return 'completed'
  }
  if (
    survey.survey.slug === 'AE-PATIENT' ||
    survey.survey.slug === 'SATISFACTION-SCORE' ||
    survey.survey.slug === 'REVIEW-ROUTING' ||
    survey.survey.slug === 'SATISFACTION-PSC' ||
    survey.survey.slug === 'VIVEX-ADDITIONAL-TREATMENT' ||
    survey.survey.slug === 'VIVEX-BACK-PAIN-MEDICATION' ||
    survey.survey.slug === 'PHYSICAL-THERAPY' ||
    survey.survey.slug === 'SEXUALLY-ACTIVE'
  ) {
    if (survey.completedAt) {
      return 'completed'
    }
  }
  const status = isFuture(zonedTimeToUtc(availableOn, timeZone))
    ? 'not-open'
    : isPast(zonedTimeToUtc(endOfDay(expiresOn), timeZone))
    ? 'expired'
    : 'open'

  return status
}

const getSurveyScore = (
  surveyStatus: string,
  survey: SurveyResponse,
  status: string,
) => {
  let score = status as string | number
  if (surveyStatus === 'completed') {
    if (survey.survey.slug === 'AE-PATIENT') {
      const aeScore = survey.surveyResponseAnswers[0].values[0]
      score = aeScore === '1.0' ? 'Yes' : 'No'
    } else if (survey.survey.slug === 'SEXUALLY-ACTIVE') {
      const aeScore = survey.surveyResponseAnswers[0].values[0]
      score = aeScore === '1.0' ? 'Yes' : 'No'
    } else if (survey.survey.slug === 'SATISFACTION-SCORE') {
      const satisfactionScore = SATISFACTION_SCORE.find(
        (ss) => ss.value === survey.surveyResponseAnswers[0].values[0],
      )
      score = satisfactionScore
        ? satisfactionScore.slug
        : survey.surveyResponseAnswers[0].values[0]
    } else if (survey.survey.slug === 'REVIEW-ROUTING') {
      const satisfactionAnswer = survey.surveyResponseAnswers.find(
        (a) => a.surveyQuestion.slug === 'REVIEW-ROUTING-SATISFACTION-Q',
      )
      const answerValue = satisfactionAnswer
        ? satisfactionAnswer.values[0]
        : survey.surveyResponseAnswers[survey.surveyResponseAnswers.length - 1]
            .values[0]

      const satisfactionScore = SATISFACTION_SCORE.find(
        (ss) => ss.value === answerValue,
      )
      score = satisfactionScore
        ? satisfactionScore.slug
        : survey.surveyResponseAnswers[0].values[0]
    } else if (survey.survey.slug === 'SATISFACTION-PSC') {
      const satisfactionScore = SATISFACTION_PSC.find(
        (ss) => ss.value === survey.surveyResponseAnswers[0].values[0],
      )
      score = satisfactionScore
        ? satisfactionScore.slug
        : survey.surveyResponseAnswers[0].values[0]
    } else if (survey.survey.slug === 'PGI-I') {
      const pgiiScore = PGII_SCORE.find(
        (pgii) => pgii.value === survey.surveyResponseAnswers[0].values[0],
      )
      score = pgiiScore
        ? pgiiScore.slug
        : survey.surveyResponseAnswers[0].values[0]
    } else if (survey.survey.slug === 'VIVEX-ADDITIONAL-TREATMENT') {
      const vivexATScore = survey.surveyResponseAnswers[0].values[0]
      score =
        vivexATScore === '1.0'
          ? 'Taking Additional Medication'
          : 'Not Taking Additional Medication'
    } else if (survey.survey.slug === 'VIVEX-BACK-PAIN-MEDICATION') {
      const vivexBPMScore = survey.surveyResponseAnswers[0].values[0]
      score =
        vivexBPMScore === '1.0'
          ? 'Taking Pain Medications'
          : 'Not Taking Pain Medications'
    } else if (survey.survey.slug === 'BDI') {
      const bdiScore = +Number(
        (findBy('isPrimary', true, survey?.surveyResponseScores) || {}).value,
      ).toFixed(1)
      score = `${bdiScore} (${bdiScoreLabel(parseInt(bdiScore.toString()))})`
    } else if (survey.survey.slug === 'PHYSICAL-THERAPY') {
      const aeScore = survey.surveyResponseAnswers[0].values[0]
      score = aeScore === '1.0' ? 'Yes' : 'No'
    } else {
      score = +Number(
        (findBy('isPrimary', true, survey?.surveyResponseScores) || {}).value,
      ).toFixed(1)
    }
  }
  return score
}

const bdiScoreLabel = (value: number) => {
  if (value > 40) {
    return 'Extreme depression'
  } else if (value > 30 && value <= 40) {
    return 'Severe depression'
  } else if (value > 20 && value <= 30) {
    return 'Moderate depression'
  } else if (value > 16 && value <= 20) {
    return 'Borderline clinical depression'
  } else if (value > 10 && value <= 16) {
    return 'Mild mood disturbance'
  } else {
    return 'These ups and downs are considered normal'
  }
}

const PatientCaseSurveys: FC<PatientCaseSurveysProps> = () => {
  const { push } = useHistory()
  const location = useLocation<{ from?: string }>()
  const { caseId, patientId } = useParams<{
    patientId: string
    caseId: string
  }>()
  const { localizedFormatDate } = useDates()

  const { data: allSurveys } = useSurveyResponseSearch({
    ownerIds: [caseId],
    ownerType: 'PatientCase',
    includes: ['survey_response_scores'],
    perPage: '300',
  })
  const { data: patientCases = [] } = usePatientCases(patientId)
  const patientCase = findBy('id', caseId, patientCases)
  const isKcqStudy =
    patientCase?.study?.slug ===
    'platelet-rich-plasma-and-the-effects-of-nsaids-on-pain-and-functional-scores-in-knee-osteoarthritis'

  const { currentSite } = useSitesContext()
  const siteTimeZone =
    TIME_ZONES.find((tz) => tz.rails === currentSite?.timeZone)?.client || 'UTC'
  const bodySiteSlug = allSurveys?.surveyResponses[0]?.bodySiteSlug
  const { data: bodySites = [] } = useBodySites()
  const bodySite = findBy('slug', bodySiteSlug, bodySites)

  const surveysGroupedBySchedule = allSurveys?.surveyResponses?.reduce(
    (acc, item) => {
      if (Object.keys(item?.metadata).length === 0) return acc

      const itemSchedule = findBy('type', 'schedule', item.metadata)

      if (itemSchedule && item?.survey?.slug !== 'TREATMENT-HISTORY') {
        acc[itemSchedule.value] = acc[itemSchedule.value] || []
        acc[itemSchedule.value].push(item)
      }
      return acc
    },
    Object.create(null),
  )

  const surveyPeriods = surveysGroupedBySchedule
    ? surveyPeriodsOrdered
        .sort((a, b) => a.order - b.order)
        .filter((period) => {
          return Object.keys(surveysGroupedBySchedule).find(
            (sgbs) => period.slug === sgbs,
          )
        })
        .map((per) => per.slug)
    : []

  return (
    <Box p={3}>
      <PageTitle title="Patient" />
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 2,
        }}
      >
        <H2 sx={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => {
              if (location.state?.from) {
                push(location.state.from)
              } else {
                push(`/patients/${patientId}`)
              }
              // goBack()
            }}
            sx={{ mr: 1 }}
            size="large"
          >
            <ArrowLeft />
          </IconButton>
          <span>{bodySite?.name || '...'} Survey Scores & Schedule</span>
        </H2>
      </Box>
      <Box sx={{ pr: 4, width: '100%' }}>
        <Card
          sx={{
            p: 3,
            pr: 0,
            backgroundColor: ({ palette }) => palette.background.paper,
          }}
        >
          {surveysGroupedBySchedule ? (
            <>
              {surveyPeriods.map((period, index) => {
                return (
                  <Box
                    key={`survey-period-${period}`}
                    sx={{ mt: index > 0 ? 6 : 2 }}
                  >
                    <H3
                      paragraph
                      sx={{ fontSize: 20, textTransform: 'capitalize' }}
                    >
                      {capitalize(period)}
                    </H3>
                    <Divider sx={{ mt: 2 }} />
                    {surveysGroupedBySchedule[period] ? (
                      <>
                        {surveysGroupedBySchedule[period].map(
                          (survey: SurveyResponse) => {
                            const surveyStatus = getSurveyStatus(
                              survey.surveyResponseScores,
                              parseISO(survey.availableOn),
                              parseISO(survey.expiresOn),
                              siteTimeZone,
                              survey,
                            )
                            const surveyDate = parseISO(
                              surveyStatus === 'not-open' && survey.availableOn
                                ? survey.availableOn
                                : surveyStatus === 'completed' &&
                                  survey.completedAt
                                ? survey.completedAt
                                : survey.expiresOn,
                            )
                            if (
                              survey.survey.slug === 'UROLOGY' &&
                              survey.surveyResponseScores.length > 0
                            ) {
                              return survey.surveyResponseScores
                                .sort((a, b) => {
                                  return a.isPrimary === b.isPrimary
                                    ? 0
                                    : a.isPrimary
                                    ? -1
                                    : 1
                                })
                                .map((urologyScore, index) => {
                                  const condition = UROLOGY_CONDITION.find(
                                    (condition) =>
                                      condition.value === urologyScore.value &&
                                      urologyScore.name == 'Condition',
                                  )
                                  let showScore = condition
                                    ? condition.slug
                                    : urologyScore.value
                                  if (urologyScore.name === 'Back Pain')
                                    showScore =
                                      urologyScore.value === '1.0'
                                        ? 'Yes'
                                        : 'No'
                                  return (
                                    <Box
                                      key={`${survey.id}-${urologyScore.name}-${index}`}
                                      sx={{
                                        pr: 2,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        pt: 2,
                                        pb: 1,
                                        borderBottom: ({ palette }) =>
                                          `1px solid ${palette.divider}`,
                                      }}
                                    >
                                      <Box width="40%">
                                        <H5
                                          gutterBottom
                                          sx={{
                                            pointerEvents: 'none',
                                            fontWeight: 400,
                                          }}
                                        >
                                          {urologyScore.name}
                                        </H5>
                                      </Box>

                                      {!isKcqStudy && (
                                        <Box
                                          width="20%"
                                          sx={{
                                            color:
                                              statusProperties[surveyStatus]
                                                .color,
                                            fontWeight: 700,
                                          }}
                                        >
                                          {showScore}
                                        </Box>
                                      )}

                                      <Box
                                        width="40%"
                                        sx={{
                                          display: 'flex',
                                          fontWeight: 700,
                                          color: ({ palette }) =>
                                            palette.text.secondary,
                                        }}
                                      >
                                        {
                                          statusProperties[surveyStatus]
                                            .scheduleLabel
                                        }
                                        {isRelativeDate(surveyDate)
                                          ? ''
                                          : ' on'}
                                        &nbsp;
                                        {isRelativeDate(surveyDate)
                                          ? toRelativeDateString(
                                              surveyDate,
                                              Date.now(),
                                              'ceil',
                                            )
                                          : localizedFormatDate(surveyDate)}
                                      </Box>
                                      {/* <Icon>
                                  <ChevronRight />
                                </Icon> */}
                                    </Box>
                                  )
                                })
                            } else if (
                              survey.survey.slug === 'TAMPON-TEST' &&
                              survey.surveyResponseScores.length > 0
                            ) {
                              return survey.surveyResponseScores
                                .sort((a, b) => {
                                  return a.isPrimary === b.isPrimary
                                    ? 0
                                    : a.isPrimary
                                    ? -1
                                    : 1
                                })
                                .map((tamponTestScore, index) => {
                                  const painLocation =
                                    PENETRATION_PAIN_LOCATION.find(
                                      (location) =>
                                        location.value ===
                                          tamponTestScore.value &&
                                        tamponTestScore.name == 'Pain Location',
                                    )
                                  let showScore = painLocation
                                    ? painLocation.slug
                                    : tamponTestScore.value
                                  if (tamponTestScore.name === 'Tampon Test')
                                    showScore = parseInt(
                                      tamponTestScore.value,
                                    ).toString()
                                  return (
                                    <Box
                                      key={`${survey.id}-${tamponTestScore.name}-${index}`}
                                      sx={{
                                        pr: 2,
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        pt: 2,
                                        pb: 1,
                                        borderBottom: ({ palette }) =>
                                          `1px solid ${palette.divider}`,
                                      }}
                                    >
                                      <Box width="40%">
                                        <H5
                                          gutterBottom
                                          sx={{
                                            pointerEvents: 'none',
                                            fontWeight: 400,
                                          }}
                                        >
                                          {tamponTestScore.name}
                                        </H5>
                                      </Box>

                                      {!isKcqStudy && (
                                        <Box
                                          width="20%"
                                          sx={{
                                            color:
                                              statusProperties[surveyStatus]
                                                .color,
                                            fontWeight: 700,
                                          }}
                                        >
                                          {showScore}
                                        </Box>
                                      )}

                                      <Box
                                        width="40%"
                                        sx={{
                                          display: 'flex',
                                          fontWeight: 700,
                                          color: ({ palette }) =>
                                            palette.text.secondary,
                                        }}
                                      >
                                        {
                                          statusProperties[surveyStatus]
                                            .scheduleLabel
                                        }
                                        {isRelativeDate(surveyDate)
                                          ? ''
                                          : ' on'}
                                        &nbsp;
                                        {isRelativeDate(surveyDate)
                                          ? toRelativeDateString(
                                              surveyDate,
                                              Date.now(),
                                              'ceil',
                                            )
                                          : localizedFormatDate(surveyDate)}
                                      </Box>
                                      {/* <Icon>
                                  <ChevronRight />
                                </Icon> */}
                                    </Box>
                                  )
                                })
                            } else {
                              return (
                                <Box
                                  key={survey.id}
                                  sx={{
                                    pr: 2,
                                    display: 'flex',
                                    justifyContent: 'space-between',
                                    pt: 2,
                                    pb: 1,
                                    borderBottom: ({ palette }) =>
                                      `1px solid ${palette.divider}`,
                                  }}
                                >
                                  <Box width="40%">
                                    <H5
                                      gutterBottom
                                      sx={{
                                        pointerEvents: 'none',
                                        fontWeight: 400,
                                      }}
                                    >
                                      {survey.survey.name}
                                    </H5>
                                  </Box>

                                  {!isKcqStudy && (
                                    <Box
                                      width="20%"
                                      sx={{
                                        color:
                                          statusProperties[surveyStatus].color,
                                        fontWeight: 700,
                                      }}
                                    >
                                      {getSurveyScore(
                                        surveyStatus,
                                        survey,
                                        statusProperties[surveyStatus].label,
                                      )}
                                    </Box>
                                  )}

                                  <Box
                                    width="40%"
                                    sx={{
                                      display: 'flex',
                                      fontWeight: 700,
                                      color: ({ palette }) =>
                                        palette.text.secondary,
                                    }}
                                  >
                                    {
                                      statusProperties[surveyStatus]
                                        .scheduleLabel
                                    }
                                    {isRelativeDate(surveyDate) ? '' : ' on'}
                                    &nbsp;
                                    {isRelativeDate(surveyDate)
                                      ? toRelativeDateString(
                                          surveyDate,
                                          Date.now(),
                                          'ceil',
                                        )
                                      : localizedFormatDate(surveyDate)}
                                  </Box>
                                  {/* <Icon>
                                <ChevronRight />
                              </Icon> */}
                                </Box>
                              )
                            }
                          },
                        )}
                      </>
                    ) : (
                      <Box sx={{ my: 2 }}>No surveys available</Box>
                    )}
                  </Box>
                )
              })}
            </>
          ) : (
            <Box>No surveys available</Box>
          )}
        </Card>
      </Box>
    </Box>
  )
}

export default PatientCaseSurveys
