var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import { Eye, EyeOff } from 'react-feather';
import { Icon, IconButton, InputAdornment, makeStyles, TextField, } from '../';
const useStyles = makeStyles((theme) => ({
    icon: {
        color: theme.palette.action.active,
    },
}), { name: 'PasswordField' });
/**
 * Extends the TextField component to add features specific to a password input.
 * Sets autocomplete="current-password" on the input. Pass in autoComplete="new-password" when appropriate.
 */
const PasswordField = (_a) => {
    var { showToggleButton = true, toggleButtonAriaLabel = 'Toggle password visibility.' } = _a, rest = __rest(_a, ["showToggleButton", "toggleButtonAriaLabel"]);
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(false);
    return (_jsx(TextField, Object.assign({ autoComplete: "current-password", label: "Password", type: showPassword ? 'text' : 'password', InputProps: {
            endAdornment: showToggleButton && (_jsx(InputAdornment, Object.assign({ position: "end" }, { children: _jsx(IconButton, Object.assign({ "aria-label": toggleButtonAriaLabel, classes: { root: classes.icon }, disableRipple: false, onClick: () => setShowPassword(!showPassword), size: "large" }, { children: showPassword ? (_jsx(Icon, Object.assign({ size: "medium", color: "inherit" }, { children: _jsx(Eye, {}, void 0) }), void 0)) : (_jsx(Icon, Object.assign({ size: "medium", color: "inherit" }, { children: _jsx(EyeOff, {}, void 0) }), void 0)) }), void 0) }), void 0)),
        } }, rest), void 0));
};
export default PasswordField;
