var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Body1, Body2, H5, makeStyles } from '../';
const useStyles = makeStyles((theme) => ({
    root: {
        margin: theme.spacing(0.5, 0),
        width: '100%',
        '& .MuiButton-text': {
            // Scooch text buttons to the left so their content aligns with the text above it
            marginLeft: theme.spacing(-1),
        },
    },
    title: {
        flex: 1,
        fontWeight: theme.typography.fontWeightBold,
    },
    // The title row is top-aligned when there is text content, and vertically centered otherwise
    titleRow: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    titleRowCentered: {
        alignItems: 'center',
    },
    // Styles for when title and helper are inline and vertically-centered
    helperInline: {
        paddingLeft: theme.spacing(1),
        textAlign: 'right',
        whiteSpace: 'normal',
    },
    // This lines up the baseline of the first line of title and helper text
    helperOffset: {
        marginTop: 2,
        paddingLeft: theme.spacing(1),
        textAlign: 'right',
    },
}), { name: 'ListItemText' });
/**
 * A replacement for MUI's ListItemText. Has a third content area (helper) and two layout options.
 * Note: this does not support MUI ListItemText's features.
 */
const ListItemText = (_a) => {
    var { layout = 'stacked', title, body, helper: helperProp } = _a, rest = __rest(_a, ["layout", "title", "body", "helper"]);
    const classes = useStyles();
    const inline = layout === 'inline';
    // If the helper prop is a string, wrap it in a Typography component
    const helper = typeof helperProp === 'string' ? (_jsx(Body2, Object.assign({ classes: {
            root: clsx(inline && {
                [classes.helperOffset]: body,
                [classes.helperInline]: !body,
            }),
        }, sx: { userSelect: 'all' } }, { children: helperProp }), void 0)) : (helperProp);
    return (_jsxs("div", Object.assign({ className: clsx('ListItemText', classes.root) }, rest, { children: [_jsxs("div", Object.assign({ className: clsx(classes.titleRow, {
                    [classes.titleRowCentered]: !body,
                }) }, { children: [!!title && (_jsx(H5, Object.assign({ classes: { root: classes.title }, gutterBottom: Boolean((!inline && helper) || body) }, { children: title }), void 0)), inline && helper] }), void 0), !!body && (_jsx(Body1, Object.assign({ gutterBottom: Boolean(!inline && helper) }, { children: body }), void 0)), !inline && helper] }), void 0));
};
export default ListItemText;
