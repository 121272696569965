import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Dialog, useBool } from '../';
/**
 * A simple wrapper that makes it easier to add a triggerable Dialog
 *
 * @example
 * <DialogTrigger
 *   action={
 *    <Link component={Body1}>
 *       Click to open
 *    </Link>
 *   }
 *   content={(toggle) => (
 *     <Box p={3}>
 *       <Body1>It's open!</Body1>
 *       <Button size="medium" onClick={() => toggle()}>
 *         Close
 *       </Button>
 *     </Box>
 *   )}
 * />
 */
const DialogTrigger = ({ action, content, dialogProps, actionProps, disableBackdropClick, disabled = false, onOpen, }) => {
    const [isOpen, toggle] = useBool(false);
    const handleClose = (_event, reason) => {
        if (disableBackdropClick && reason !== 'backdropClick') {
            return;
        }
        toggle();
    };
    return (_jsxs(_Fragment, { children: [_jsx("div", Object.assign({ onClick: () => {
                    if (!disabled)
                        toggle();
                    onOpen === null || onOpen === void 0 ? void 0 : onOpen();
                } }, actionProps, { children: action }), void 0), _jsx(Dialog, Object.assign({ open: isOpen, onClose: handleClose, fullWidth: true }, dialogProps, { children: typeof content === 'function' ? content(toggle, isOpen) : content }), void 0)] }, void 0));
};
export default DialogTrigger;
