import { useQuery } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { ClinicContractResponse } from './useUnauthenticatedClinicContracts'

const useClinicContracts = () => {
  const currentSiteId = useCurrentSite()?.id || ''

  const key = QueryKeys.clinicContract()
  const url = `/sites/contracts?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () => request.get<ClinicContractResponse>(url).then((r) => r.data),
    {},
  )
}

export default useClinicContracts
