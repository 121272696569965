import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { StaffRole } from './types'
import { Staff } from './useCurrentUser'

interface AccountCreateResponse {
  staff: Staff
}

interface AccountCreateBody {
  firstName: string
  lastName: string
  email: string
  type: StaffRole
  password: string
  npi?: string
  subscriptionPlan?: string
  needsVerify?: boolean
}

const useCreateAccount = () => {
  const queryClient = useQueryClient()
  const url = '/sites/staffs'
  const siteId = useCurrentSite()?.id || ''

  return useMutation((vars: AccountCreateBody) =>
    request
      .post<AccountCreateResponse>(url, {
        ...vars,
        subscriptionPlan: vars.subscriptionPlan
          ? vars.subscriptionPlan
          : undefined,
      })
      .then((r) => {
        if (vars.needsVerify) {
          queryClient.invalidateQueries(QueryKeys.staffs(siteId))
          queryClient.invalidateQueries(QueryKeys.productsAvailable())
        }
        return r.data
      }),
  )
}

export default useCreateAccount
