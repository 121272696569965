import { useState } from 'react';
/**
 * Syncs read and write changes of this state to either localStorage or sessionStorage.
 * Uses a similar API as `useState`.
 *
 * Details on sessionStorage: @see https://developer.mozilla.org/en-US/docs/Web/API/Window/sessionStorage
 * Details on localStorage: @see https://developer.mozilla.org/en-US/docs/Web/API/Window/localStorage
 *
 * @example `const [sites, setSites] = useStorage('localStorage', 'sites', [])`
 *
 * @param type The storage implementation to use
 * @param key The key to store this state under in localStorage
 * @param initialValue The initial value.
 * @returns a tuple with the value and a setter [value, setValue]
 */
function useStorage(type, key, initialValue) {
    const storage = window[type];
    // Store the value in-memory via useState. Set the initial value
    // either from storage or via the initialValue param
    const [storedValue, setStoredValue] = useState(() => {
        try {
            const item = storage.getItem(key);
            // Parse stored json or return initialValue
            if (item) {
                if (item === 'undefined')
                    return undefined;
                return JSON.parse(item);
            }
            else {
                const value = initialValue instanceof Function ? initialValue() : initialValue;
                return value;
            }
        }
        catch (error) {
            // If we hit an error, return initialValue
            // Potential reasons could be malformed data or if storage is disabled by the user/browser
            console.warn(`Failed to get item ${key} from localStorage`, error);
            return initialValue;
        }
    });
    // Wrap the useState setter to sync any state updates to storage
    const setValue = (value) => {
        try {
            // Allow value to be a function to align with the API of useState
            const valueToStore = value instanceof Function ? value(storedValue) : value;
            // Save to local state
            setStoredValue(valueToStore);
            // Save to storage
            storage.setItem(key, JSON.stringify(valueToStore));
        }
        catch (error) {
            // A more advanced implementation would handle the error case
            // eslint-disable-next-line
            console.log(error);
        }
    };
    return [storedValue, setValue];
}
export default useStorage;
