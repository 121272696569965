import { FC } from 'react'
import { HeaderBar, makeStyles } from '@perk-ui/core'
import { useLocation } from 'react-router-dom'

import config from '../config/environments/development'
import { useAuth } from '../features/auth/AuthContext'
import AppLink from './AppLink'
import KnowledgeBaseButton from './KnowledgeBaseButton'
import ProfileDropdown from './ProfileDropdown'
import SitesDropdown from './SitesDropdown'
import { useSitesContext } from './SitesProvider'

export interface AppHeaderProps {}

const useStyles = makeStyles((theme) => ({
  gap: {
    paddingRight: theme.spacing(3),
  },
  header: {
    zIndex: 1201,
  },
}))

export const pathsWithoutHeader = [
  '/login',
  '/create-account',
  '/login-new',
  '/forgot-password',
  '/password-reset',
  '/activate-account',
  '/resend-activation-link',
  '/onboarding',
  '/sign-contract',
  '/payment',
]

const AppHeader: FC<AppHeaderProps> = () => {
  const classes = useStyles()
  const { isAuthenticated } = useAuth()
  const { pathname } = useLocation()
  const { currentSite } = useSitesContext()
  const isCurrentLocation = (path: string) => pathname.startsWith(path)
  const showHeader = !pathsWithoutHeader.some(isCurrentLocation)

  return showHeader && isAuthenticated && currentSite ? (
    <HeaderBar
      className={classes.header}
      left={
        <AppLink
          to={
            isAuthenticated
              ? config.authenticatedRootUrl
              : config.unAuthenticatedRootUrl
          }
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/DataBiologics_Logo.png`}
            height="30"
          />
        </AppLink>
      }
      right={
        isAuthenticated && (
          <>
            <KnowledgeBaseButton />
            <SitesDropdown />
            <div className={classes.gap} />
            <ProfileDropdown />
          </>
        )
      }
      position="fixed"
    />
  ) : null
}

export default AppHeader
