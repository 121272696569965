import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { BodySite } from './useBodySites'
import { MedicalCondition } from './useMedicalConditions'
import { MedicalTreatment } from './useMedicalTreatments'
import { Organization, Site } from './useSitesAvailable'
import { TreatmentProduct } from './useTreatmentProducts'

export interface Protocol {
  id: string
  name: string
  slug: string
  description?: string
  type: ProtocolType
  status: string
  siteId?: string
  site?: Site
  organizationId?: string
  organization?: Organization
  userId?: string
  protocolDiagnoses?: ProtocolDiagnosis[]
  protocolTreatments?: ProtocolTreatment[]
  bodySite?: BodySite
  bodySiteId?: string
}

export interface ProtocolDiagnosis {
  id: string
  isPrimary: boolean
  locations: string[]
  severity: ProtocolDiagnosisSeverity
  medicalConditionId?: string
  medicalCondition?: MedicalCondition
}

export type ProtocolDiagnosisSeverity = 'mild' | 'moderate' | 'severe'
export interface ProtocolTreatment {
  id: string
  isPrimary: boolean
  medicalTreatmentId: string
  order: number
  periodAmount: number
  periodType?: 'days' | 'weeks' | 'months' | 'years'
  siteLocationId?: string
  staffId?: string
  treatmentProductId?: string
  medicalTreatment?: MedicalTreatment
  treatmentProduct?: TreatmentProduct
}

export type ProtocolType = 'FullProtocol' | 'TreatmentProtocol'

interface ProtocolResponse {
  protocols: Protocol[]
}

const useProtocols = (
  type?: string,
  extraOptions: UseQueryOptions<Protocol[]> = {},
) => {
  const siteId = useCurrentSite().id
  const key = QueryKeys.protocols({ clinicId: siteId, type })
  let url = `sites/protocols?site_ids[]=${siteId}`
  if (type) url = `sites/protocols?site_ids[]=${siteId}&filter=${type}`

  return useQuery(
    key,
    () => request.get<ProtocolResponse>(url).then((r) => r.data.protocols),
    {
      // Protocols are static. Don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useProtocols
