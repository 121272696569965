import { FC } from 'react'

import { BodyPartColorList } from '../pages/Home/components/BodyPartGraph'

export interface SegmentedBarItem {
  label: string
  value: number
}

export interface SegmentedBarProps {
  /** value is not a percent */
  items: SegmentedBarItem[]
  colorList?: string[]
  className?: string
}

const toPercent = (num: number, sum: number) => (num / sum) * 100

const SegmentedBar: FC<SegmentedBarProps> = ({
  items,
  className,
  colorList,
}) => {
  const sum = items.reduce((sum, item) => sum + item.value, 0)
  // const [bigItems, others] = partition(
  //   (item) => toPercent(item.value, sum) > 5,
  //   items,
  // )
  const columns = items
    .map((i) => `${toPercent(i.value, sum)}%`)
    .concat('auto') // The last group, 'others', should extend to fill the remaining space
    .join(' ')

  return (
    <div
      className={className}
      style={{
        display: 'grid',
        gridTemplateColumns: columns,
        width: '100%',
      }}
    >
      {items.map((item, idx) => {
        return (
          <span
            key={item.label}
            style={{
              height: 16,
              background: (colorList || BodyPartColorList)[idx],
            }}
          ></span>
        )
      })}
      {/* {others.length > 0 && (
        <span
          style={{ height: 16, background: BodyPartColorList[bigItems.length] }}
        ></span>
      )} */}
    </div>
  )
}

export default SegmentedBar
