import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Patient } from './usePatient'
import { PatientCase } from './usePatientCases'

interface ArchivePatientResponse {
  patient: Patient
  patientCases: PatientCase[]
}

interface ArchivePatientVars {
  archiveReasonId: string
  patientId: string
}

const useArchivePatient = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation((vars: ArchivePatientVars) => {
    const url = `/sites/patients/${vars.patientId}/archive`

    return request
      .post<ArchivePatientResponse>(url, {
        siteIds: [siteId],
        patient: {
          archivalReasonId: vars.archiveReasonId,
        },
      })
      .then((response) => {
        queryClient.invalidateQueries(
          QueryKeys.patientCases({ patientId: vars.patientId }),
        )
        queryClient.invalidateQueries(
          QueryKeys.patient({
            patientId: vars.patientId,
            currentSiteId: siteId,
          }),
        )
        queryClient.invalidateQueries(
          QueryKeys.patientsSearch({ currentSiteId: siteId }),
        )
        return response.data
      })
  })
}

export default useArchivePatient
