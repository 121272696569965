var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles, QuestionVariants, TextField, } from '../';
const useStyles = makeStyles((theme) => ({
    root: {
    // width: '50%',
    },
    input: {
    // textAlign: 'center',
    },
}));
const SurveyNumberField = (_a) => {
    var _b;
    var { item, answer, onAnswer, className, inputProps } = _a, rest = __rest(_a, ["item", "answer", "onAnswer", "className", "inputProps"]);
    const classes = useStyles();
    const initialValue = (_b = item.initialValue) !== null && _b !== void 0 ? _b : '';
    const defaultValue = answer != null ? answer : initialValue;
    const [value, setValue] = useState(defaultValue);
    const min = item.data.minValue ? Number(item.data.minValue) : -Infinity;
    const max = item.data.maxValue ? Number(item.data.maxValue) : Infinity;
    const allowDecimal = item.variant === QuestionVariants.inputDecimal;
    useEffect(() => {
        // On mount, if `answer` is not set and the initialValue is sufficient,
        // give our parent a valid answer.
        if (answer == null && isValid(value)) {
            onAnswer(item, value);
        }
        // eslint-disable-next-line
    }, []);
    const isValid = (val) => {
        const asNum = Number(val);
        const isEmptyString = val === '';
        const isNaN = Number.isNaN(asNum);
        const inRange = asNum >= min && asNum <= max;
        const isNumberFamilyValid = allowDecimal
            ? true
            : // values like `123.` are still considered Integers, so we need to check for `.`
                Number.isInteger(asNum) && !String(val).includes('.');
        return !isNaN && inRange && !isEmptyString && isNumberFamilyValid;
    };
    return (_jsx(TextField, Object.assign({ className: clsx(classes.root, className), value: answer || value, type: "text", fullWidth: true, inputProps: Object.assign({ min,
            max, className: classes.input, inputMode: 'numeric' }, inputProps), onChange: (ev) => {
            if (isValid(ev.target.value)) {
                onAnswer(item, ev.target.value);
                setValue(ev.target.value);
            }
            else {
                if (ev.target.value === '') {
                    setValue(ev.target.value);
                    onAnswer(item, undefined); // unsetting answer to indicate an invalid pending value
                }
            }
        } }, rest), void 0));
};
export default SurveyNumberField;
