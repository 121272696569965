import { useMutation } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import queryClient from '../../config/react-query'
import QueryKeys from './QueryKeys'
import { Patient } from './usePatient'
import { PatientCase } from './usePatientCases'

interface PatientInviteResponse {
  patient: Patient
  patientCases: PatientCase[]
}

interface PatientInviteBody
  extends Omit<
    Patient,
    | 'id'
    | 'cellPhoneCountryCode'
    | 'biologicalSex'
    | 'sites'
    | 'createdAt'
    | 'status'
  > {
  bodySiteIds: string[]
  studyId: string
  anticipatedTreatmentDate: string
}

const useInvitePatient = () => {
  const siteId = useCurrentSite().id
  const url = '/sites/patients/invite'

  return useMutation((vars: PatientInviteBody) =>
    request
      .post<PatientInviteResponse>(url, {
        siteIds: [siteId],
        patientInvite: {
          siteId: siteId,
          ...vars,
          // If these fields are empty strings, set them to undefined so we don't send them
          email: vars.email || undefined,
          medicalRecordNumber: vars.medicalRecordNumber || undefined,
          studyId: vars.studyId || undefined,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.upcomingDashboard(siteId))
        return r.data
      }),
  )
}

export default useInvitePatient
