var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Body1, H2, Link, MuiStack } from '../';
const LoginContainer = (_a) => {
    var { backgroundColorProps, title = 'Log In', MessagesComponent, UsernameComponent, PasswordComponent, LoginButtonComponent, OAuthButtons, signupURL, forgotPasswordURL } = _a, rest = __rest(_a, ["backgroundColorProps", "title", "MessagesComponent", "UsernameComponent", "PasswordComponent", "LoginButtonComponent", "OAuthButtons", "signupURL", "forgotPasswordURL"]);
    return (_jsxs(MuiStack, Object.assign({ id: "login-container", spacing: 3, justifyContent: "space-evenly", sx: (t) => ({
            width: t.spacing(41),
            boxShadow: t.perk.boxShadow.card,
            backgroundColor: backgroundColorProps || t.palette.common.white,
            borderRadius: 1,
            p: 4,
            mb: 2,
        }) }, rest, { children: [_jsx(H2, { children: title }, void 0), MessagesComponent, UsernameComponent, PasswordComponent, LoginButtonComponent, forgotPasswordURL && (_jsx(Body1, Object.assign({ color: "textSecondary", gutterBottom: true, textAlign: "center" }, { children: _jsx(Link, Object.assign({ id: "login-forgot-password-link", href: forgotPasswordURL, underline: "hover" }, { children: "Forgot Password" }), void 0) }), void 0)), OAuthButtons && (_jsx(MuiStack, Object.assign({ direction: "row", justifyContent: "space-around" }, { children: OAuthButtons }), void 0)), signupURL && (_jsxs(Body1, Object.assign({ color: "textSecondary", textAlign: "center" }, { children: ["Don't have an Account?", ' ', _jsx(Link, Object.assign({ id: "login-sign-up-link", href: signupURL, underline: "hover" }, { children: "Sign Up" }), void 0)] }), void 0))] }), void 0));
};
export default LoginContainer;
