import { useMutation } from 'react-query'

import request from '../../config/axios'

interface ActivateAccountResponse {
  active: boolean
}

export interface ActivateAccountVars {
  token: string
}

const useActivateAccount = () => {
  return useMutation((vars: ActivateAccountVars) => {
    const url = `/sites/activate_account`
    const { token } = vars
    return request
      .post<ActivateAccountResponse>(url, null, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((r) => {
        return r.data.active
      })
  })
}

export default useActivateAccount
