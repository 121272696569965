import { FC, MouseEvent } from 'react'
import {
  Box,
  Button,
  Card,
  Chip,
  FormControlLabel,
  H2,
  makeStyles,
  PageTitle,
  Radio,
  RadioGroup,
  TablePagination,
} from '@perk-ui/core'
import {
  NumberParam,
  StringParam,
  useQueryParam,
  withDefault,
} from 'use-query-params'

import TableHeaderAutocomplete from '../../components/TableHeaderAutocomplete'
import usePatientCDSearch from '../../features/query-hooks/usePatientCDSearch'
import PatientTable from './components/PatientTable'
import PatientTableSearch from './components/PatientTableSearch'

export interface PatientsProps {}

export interface SwitchOnChangeProps {
  event: React.SyntheticEvent<Element, Event>
  checked: boolean
}
const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 5, 3, 3),
  },
  title: {
    marginBottom: theme.spacing(2),
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  container: {
    paddingTop: theme.spacing(3),
    backgroundColor: theme.palette.background.paper,
  },
  radioGroupContainer: {
    display: 'inline-flex',
    width: '100%',
    alignItems: 'center',
    margin: theme.spacing(0, 2),
    justifyContent: 'space-between',
  },
  radioGroup: {
    display: 'inline-flex',
    // width: '100%',
    justifyContent: 'space-evenly',
    '& .MuiBox-root': {
      width: 'auto',
    },
  },
  inlineFilter: {
    padding: theme.spacing(0, 2),
    '& .MuiBox-root': {
      width: 'auto',
    },
  },
  tableTop: {
    display: 'inline-flex',
    width: '100%',
  },
  radioInput: {
    marginLeft: '10px',
    margin: 0,
    paddingLeft: '5px',
    paddingRight: '15px',
    backgroundColor: '#f5f5f8',
    border: '1px solid #f5f5f8',
    borderRadius: '4px',
    '&:hover': {
      border: '1px solid #000000',
    },
  },
  radioInputActive: {
    border: '1px solid #56AAEB',
    backgroundColor: '#EAF2F5',
  },
  clearFilterButton: {
    width: '150px',
    marginLeft: theme.spacing(1),
    // marginRight: theme.spacing(3),
    paddingLeft: '10px',
    paddingRight: '10px',
    fontSize: '14px',
  },
  warningChip: {
    backgroundColor: 'rgba(179,0,0,0.1)',
    marginLeft: theme.spacing(1),
  },
}))

const perPage = 10

const PatientsCD: FC<PatientsProps> = () => {
  const classes = useStyles()

  const [searchValue, setSearchValue] = useQueryParam(
    'q',
    withDefault(StringParam, ''),
  )
  const [bodySites, setBodySites] = useQueryParam(
    'body_sites',
    withDefault(StringParam, ''),
  )
  const [diagnoses, setDiagnoses] = useQueryParam(
    'diagnoses',
    withDefault(StringParam, ''),
  )
  const [treatments, setTreatments] = useQueryParam(
    'treatments',
    withDefault(StringParam, ''),
  )
  const [surveyStatuses, setSurveyStatuses] = useQueryParam(
    'surveyStatuses',
    withDefault(StringParam, ''),
  )
  const [showFilterToggle, setShowFilterToggle] = useQueryParam(
    'filterToggle',
    withDefault(StringParam, ''),
  )
  const [studies, setStudies] = useQueryParam(
    'studies',
    withDefault(StringParam, ''),
  )
  const [providers, setProviders] = useQueryParam(
    'providers',
    withDefault(StringParam, ''),
  )
  const [page, setPage] = useQueryParam('page', withDefault(NumberParam, 0))

  const {
    data: searchResult,
    isLoading,
    isFetching,
  } = usePatientCDSearch({
    search: searchValue,
    bodySites: bodySites,
    diagnoses: diagnoses,
    treatments: treatments,
    studies: studies,
    providers: providers,
    surveyStatuses: surveyStatuses,
    filterToggle: showFilterToggle,
    // missingDiagnoses: showMissingDiagnoses,
    // missingTreatments: showMissingTreatments,
    // missingBaselineSurveys: showMissingBaselineSurveys,
    // `page` as used by MUI table components is 0-indexed while our backend
    // pagination is 1-indexed.
    // ¯\_(ツ)_/¯
    page: page + 1,
    perPage,
  })
  const count = searchResult?.meta.count || 0
  const tableData = searchResult?.patients || []
  const bodySitesOptions = searchResult?.meta.bodySites || []
  const diagnosesOptions = searchResult?.meta.medicalConditions || []
  const treatmentsOptions = searchResult?.meta.medicalTreatments || []
  const surveyStatusesOptions = searchResult?.meta.surveyStatuses || []
  const providersOptions = searchResult?.meta.providers || []
  const studiesOptions = searchResult?.meta.studies || []
  const missingTreatmentsCount = searchResult?.meta.missingTreatmentsCount || 0
  const missingBaselineSurveysCount =
    searchResult?.meta.missingBaselineSurveysCount || 0

  const handleChangePage = (
    _event: MouseEvent<HTMLButtonElement> | null,
    newPage: number,
  ) => {
    setPage(newPage)
  }

  const handleSetSearchValue = (val: string) => {
    setSearchValue(val)
    setPage(0)
  }

  const handleSetBodiSiteValue = (val: string) => {
    setBodySites(val)
    setPage(0)
  }

  const handleSetDiagnosesValue = (val: string) => {
    setDiagnoses(val)
    setPage(0)
  }

  const handleSetTreatmentsValue = (val: string) => {
    setTreatments(val)
    setPage(0)
  }

  const handleSetSurveyStatusesValue = (val: string) => {
    setSurveyStatuses(val)
    setPage(0)
  }

  const handleSetProvidersValue = (val: string) => {
    setProviders(val)
    setPage(0)
  }

  const handleSetStudiesValue = (val: string) => {
    setStudies(val)
    setPage(0)
  }

  const handleSetFilterToggleValue = (
    event: React.SyntheticEvent<Element, Event>,
  ) => {
    if (showFilterToggle == (event.target as HTMLInputElement).value) {
      setShowFilterToggle('')
    } else {
      setShowFilterToggle((event.target as HTMLInputElement).value)
    }
    setPage(0)
  }

  const handleResetFilter = () => {
    setBodySites('')
    setDiagnoses('')
    setTreatments('')
    setSurveyStatuses('')
    setShowFilterToggle('')
    setSearchValue('')
    setStudies('')
    setProviders('')
    setPage(0)
  }

  return (
    <Box className={classes.root}>
      <PageTitle title="Patients" />
      <div className={classes.title}>
        <H2>All Patients</H2>
        <PatientTableSearch
          setSearchValue={handleSetSearchValue}
          searchValue={searchValue}
        />
      </div>
      <Card className={classes.container}>
        <div className={classes.tableTop}>
          <div className={classes.radioGroupContainer}>
            <RadioGroup
              row
              className={classes.radioGroup}
              value={showFilterToggle}
            >
              <FormControlLabel
                value="treatments"
                className={
                  showFilterToggle === 'treatments'
                    ? `${classes.radioInput} ${classes.radioInputActive}`
                    : classes.radioInput
                }
                control={<Radio onClick={handleSetFilterToggleValue} />}
                label={
                  <>
                    Missing Treatments{'  '}
                    <Chip
                      className={classes.warningChip}
                      label={missingTreatmentsCount}
                      variant="outlined"
                      color="error"
                      size="small"
                    />
                  </>
                }
              />
              <FormControlLabel
                value="baselinesurveys"
                className={
                  showFilterToggle === 'baselinesurveys'
                    ? `${classes.radioInput} ${classes.radioInputActive}`
                    : classes.radioInput
                }
                control={<Radio onClick={handleSetFilterToggleValue} />}
                label={
                  <>
                    Missing Baseline Surveys{'  '}
                    <Chip
                      className={classes.warningChip}
                      label={missingBaselineSurveysCount}
                      variant="outlined"
                      color="error"
                      size="small"
                    />
                  </>
                }
              />
              <div className={classes.inlineFilter}>
                <TableHeaderAutocomplete
                  options={studiesOptions}
                  onChange={(data) => {
                    const filterResult = data.map(
                      (study: { slug: string }) => study.slug,
                    )
                    handleSetStudiesValue(filterResult.toString())
                  }}
                  value={
                    studiesOptions?.filter((study) =>
                      studies.split(',')?.some((stdy) => study.slug === stdy),
                    ) ?? undefined
                  }
                  placeholder="Study"
                  styleProps={{
                    width: '220px',
                  }}
                />
              </div>
            </RadioGroup>
            <Button
              variant={'text'}
              onClick={handleResetFilter}
              className={classes.clearFilterButton}
            >
              Clear Filters
            </Button>
          </div>
        </div>
        <PatientTable
          data={tableData}
          headers={{
            providerHeader: (
              <TableHeaderAutocomplete
                options={providersOptions}
                onChange={(data) => {
                  const filterResult = data.map(
                    (provider: { id: string }) => provider.id,
                  )
                  handleSetProvidersValue(filterResult.toString())
                }}
                optionsType="alt"
                value={
                  providersOptions?.filter((provider) =>
                    providers
                      .split(',')
                      ?.some((provId) => provider.id === provId),
                  ) ?? undefined
                }
                placeholder="Provider"
                styleProps={{
                  width: '220px',
                }}
              />
            ),
            bodyPartHeader: (
              <TableHeaderAutocomplete
                options={bodySitesOptions}
                onChange={(data) => {
                  const filterResult = data.map(
                    (bodySite: { slug: string }) => bodySite.slug,
                  )
                  handleSetBodiSiteValue(filterResult.toString())
                }}
                value={
                  bodySitesOptions?.filter((bodySite) =>
                    bodySites
                      .split(',')
                      ?.some((bodyS) => bodySite.slug === bodyS),
                  ) ?? undefined
                }
                placeholder="Body Part"
                styleProps={{
                  width: '220px',
                }}
              />
            ),
            diagnosesHeader: (
              <TableHeaderAutocomplete
                options={diagnosesOptions}
                onChange={(data) => {
                  const filterResult = data.map(
                    (diagnosis: { name: string }) => diagnosis.name,
                  )
                  handleSetDiagnosesValue(filterResult.toString())
                }}
                value={
                  diagnosesOptions?.filter((diagnosis) =>
                    diagnoses
                      .split(',')
                      ?.some((diag) => diagnosis.name === diag),
                  ) ?? undefined
                }
                placeholder="Primary Diagnosis"
                styleProps={{
                  width: '220px',
                }}
              />
            ),
            treatmentsHeader: (
              <TableHeaderAutocomplete
                options={treatmentsOptions}
                onChange={(data) => {
                  const filterResult = data.map(
                    (treatment: { slug: string }) => treatment.slug,
                  )
                  handleSetTreatmentsValue(filterResult.toString())
                }}
                value={
                  treatmentsOptions?.filter((treatment) =>
                    treatments
                      .split(',')
                      ?.some((treat) => treatment.slug === treat),
                  ) ?? undefined
                }
                placeholder="Primary Treatment"
                styleProps={{
                  width: '220px',
                }}
              />
            ),
            surveyStatusHeader: (
              <TableHeaderAutocomplete
                options={surveyStatusesOptions}
                onChange={(data) => {
                  const filterResult = data.map(
                    (status: { slug: string }) => status.slug,
                  )
                  handleSetSurveyStatusesValue(filterResult.toString())
                }}
                value={
                  surveyStatusesOptions?.filter((status) =>
                    surveyStatuses
                      .split(',')
                      ?.some((stat) => status.slug === stat),
                  ) ?? undefined
                }
                placeholder="Survey Status"
                styleProps={{
                  width: '220px',
                }}
              />
            ),
          }}
          isLoading={isFetching || isLoading}
          pagination={
            tableData.length > 0 && (
              <TablePagination
                rowsPerPageOptions={[]}
                component="div"
                count={count}
                rowsPerPage={perPage}
                page={page}
                onPageChange={handleChangePage}
              />
            )
          }
        />
      </Card>
    </Box>
  )
}

export default PatientsCD
