import { FC } from 'react'
import { Box, makeStyles, PageTitle } from '@perk-ui/core'
import { Route, Switch, useRouteMatch } from 'react-router'

import Preview from './components/Preview'
import Create from './Create/Create'
import Diagnosis from './Diagnosis/Diagnosis'
import ProtocolFlowUrls, {
  ProtocolFlowLocationParams,
} from './ProtocolFlowUrls'
import Treatment from './Treatment/Treatment'

export interface ProtocolFlowProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
      padding: theme.spacing(3, 0),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%',
    },
  },
  content: {
    display: 'flex',
    height: '100%',
    // boxShadow: theme.perk.boxShadow.card,
    maxHeight: '80vh',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  headerIcon: {
    marginRight: theme.spacing(1),
  },
  sidebar: {
    flex: 1,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  formContainer: {
    flex: 2,
    overflow: 'auto',

    // The below groups serve to always show the scrollbar if there is one.
    '&::-webkit-scrollbar': {
      width: 7,
      '-webkit-appearance': 'none',
      // Important for iPad Safari
      '-webkit-overflow-scrolling': 'auto',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .4)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
}))

const ProtocolFlow: FC<ProtocolFlowProps> = () => {
  const classes = useStyles()

  const hasProtocolMatch = useRouteMatch<ProtocolFlowLocationParams>(
    ProtocolFlowUrls.hasProtocol.match,
  )
  const hasProtocol = !!hasProtocolMatch

  return (
    <Box className={classes.root}>
      <PageTitle title={hasProtocol ? 'Edit Protocol' : 'Create Protocol'} />
      <Box className={classes.content}>
        <Box className={classes.formContainer} p={3}>
          <Switch>
            <Route
              path={ProtocolFlowUrls.createProtocol.match}
              exact
              component={Create}
            />
            <Route
              path={ProtocolFlowUrls.editProtocol.match}
              exact
              component={Create}
            />
            <Route
              path={ProtocolFlowUrls.previewProtocol.match}
              exact
              component={Preview}
            />
            <Route
              path={ProtocolFlowUrls.diagnosis.match}
              exact
              component={Diagnosis}
            />
            <Route
              path={ProtocolFlowUrls.addDiagnosis.match}
              exact
              component={Diagnosis}
            />
            <Route
              path={ProtocolFlowUrls.treatment.match}
              exact
              component={Treatment}
            />
            <Route
              path={ProtocolFlowUrls.addTreatment.match}
              exact
              component={Treatment}
            />
          </Switch>
        </Box>
      </Box>
    </Box>
  )
}
export default ProtocolFlow
