import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

const useDeleteProtocol = () => {
  const queryClient = useQueryClient()
  const currentSiteId = useCurrentSite().id

  return useMutation((protocolId: string) => {
    const url = `/sites/protocols/${protocolId}?site_ids[]=${currentSiteId}`

    return request.delete(url).then(() => {
      queryClient.invalidateQueries(
        QueryKeys.protocols({ clinicId: currentSiteId }),
      )
    })
  })
}

export default useDeleteProtocol
