import { FC } from 'react'
import { Box, Grid, H3, H5, Link, makeStyles } from '@perk-ui/core'

import Phone from '../../../assets/Phone.png'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3, 0.5),
    position: 'relative',
    width: '100%',
    maxWidth: '678px',
  },
  container: {
    background:
      'linear-gradient(49deg, rgba(224,247,254,1) 0%, rgba(109,245,196,1) 70%, rgba(220,253,119,1) 100%)',
    height: '100%',
    borderRadius: '40px',
  },
  textContainer: {
    padding: theme.spacing(3),
    '& h3': {
      fontSize: '28px',
      paddingBottom: theme.spacing(2),
    },
    '& a': {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.common.white,
      textDecoration: 'unset',
      padding: theme.spacing(2, 4),
      borderRadius: '16px',
      fontWeight: 700,
      fontSize: '16px',
      display: 'inline-block',
      '&:hover': {
        backgroundColor: theme.palette.primary.dark,
      },
    },
  },
  imageContainer: {
    padding: theme.spacing(3, 3, 1, 3),
    backgroundImage: `url(${Phone})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'bottom',
    backgroundSize: 'contain',
    marginTop: theme.spacing(4),
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    '& h3': {
      color: '#5BB0FF',
      fontSize: '42px',
      textAlign: 'center',
    },
    '& h5': {
      fontSize: '7px',
      color: theme.palette.common.black,
      textAlign: 'center',
      padding: theme.spacing(0, 3),
    },
  },
  content: {
    margin: theme.spacing(0, 6, 0, 4),
  },
}))

interface LearnMoreBannerProps {}

const LearnMoreBanner: FC<LearnMoreBannerProps> = () => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Grid container>
            <Grid xs={12} sm={7} className={classes.textContainer}>
              <H3>Learn more about databiologics</H3>
              <Link href="https://databiologics.com" target="_blank">
                Learn More
              </Link>
            </Grid>
            <Grid xs={12} sm={5} className={classes.imageContainer}>
              <H3>Hello!</H3>
              <H5>
                Determine which treatments work best for which patients, compare
                treatment protocols and ultimately make clinical decisions
                rooted in evidence.
              </H5>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  )
}

export default LearnMoreBanner
