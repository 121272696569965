import { FC, Fragment } from 'react'
import {
  Body1,
  Body2,
  Box,
  Button,
  Chip,
  CircularProgress,
  DialogTrigger,
  groupBy,
  List,
  makeStyles,
} from '@perk-ui/core'
import clsx from 'clsx'
import { isToday, isTomorrow } from 'date-fns'
import { Calendar, Check, ChevronRight, Info } from 'react-feather'

import { ReactComponent as EmptyTreatmentImage } from '../../../assets/EmptyState-Treatments.svg'
import PatientOpenSurveys from '../../../components/PatientOpenSurveys'
import { PatientCase } from '../../../features/query-hooks/usePatientCases'
import useDates from '../../../hooks/useDates'
import { parseYMD } from '../../../utils/dates'
import { useHistory } from '../../../utils/useHistory'
import PatientFlowUrls from '../../PatientFlow/PatientFlowUrls'

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(2),
  },
  container: {
    // padding: theme.spacing(1),
    borderRadius: theme.spacing(1),
    border: '1px solid #F1F1F1',
    marginBottom: theme.spacing(2),
  },
  rowHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: '#FAFAFB',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    '& .MuiChip-clickable': {
      color: '#767676',
      height: '28px',
      padding: theme.spacing(1),
      borderColor: 'transparent',
      backgroundColor: 'transparent',
      paddingLeft: 0,

      '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#FAFAFB',
        borderColor: '#767676',
      },
      '& .MuiChip-icon': {
        color: '#767676',
        backgroundColor: '#FAFAFB',
        borderRadius: '25px',
        display: 'flex',
        margin: 0,
        padding: '7px',
      },
      '& .MuiChip-deleteIcon': {
        color: '#767676',
      },
    },
  },
  missingInfo: {
    '& .MuiChip-clickable': {
      // backgroundColor: '#EAF2F5',
      color: theme.palette.error.main,
      '&:hover': {
        backgroundColor: '#F9E6E6',
        borderColor: theme.palette.error.main,
      },
      '&.active': {
        backgroundColor: '#F9E6E6',
        borderColor: theme.palette.error.main,
      },
      '& .MuiChip-icon': {
        color: theme.palette.error.main,
        backgroundColor: '#F9E6E6',
      },
      '& .MuiChip-deleteIcon': {
        color: theme.palette.error.main,
      },
    },
  },
  patientName: {
    fontWeight: 'bold',
    color: 'black',
    padding: theme.spacing(0, 2),
  },
  upcomingDate: {
    fontWeight: 'bold',
    padding: theme.spacing(0, 2),
    '& span': {
      display: 'flex',
      alignItems: 'center',
      '& svg': {
        marginRight: theme.spacing(0.5),
      },
    },
  },
  caseContainer: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  caseData: {
    width: '25%',
    padding: theme.spacing(2, 4),
  },

  alertCircle: {
    color: 'red',
    marginRight: theme.spacing(0.5),
  },
  checkCircle: {
    color: 'black',
    marginRight: theme.spacing(0.5),
  },
  column: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '50%',
  },
  textAndIconRow: {
    display: 'flex',
    flexDirection: 'row',
  },
  textAndIconRowCompletedBaseline: {
    display: 'flex',
    flexDirection: 'row',
    paddingLeft: theme.spacing(1),
  },
  red: {
    color: theme.palette.error.main,
    '& svg': {
      color: theme.palette.error.main,
    },
  },
  black: {
    color: 'black',
  },
  baselineColumn: {
    flex: '25%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    paddingLeft: theme.spacing(1),
  },
  contentColumn: {
    flex: '75%',
  },
  flexItem: {
    flex: '33%',
    paddingRight: theme.spacing(0.5),
  },
  flexItemBodySite: {
    flex: '33%',
    paddingRight: theme.spacing(0.5),
    paddingLeft: theme.spacing(1),
    paddingTop: theme.spacing(0.5),
    margin: 'auto',
  },
  boldText: {
    fontWeight: 'bold',
  },
  paddingRight: {
    paddingRight: theme.spacing(0.5),
  },
  treatmentRow: {
    display: 'flex',
    flex: '33%',
    flexDirection: 'column',
    overflowY: 'auto',
    maxHeigth: '100px',
  },
  emptyDataContainer: {
    padding: theme.spacing(5),
  },
  emptyDataText: {
    paddingTop: theme.spacing(3),
    fontSize: '14px',
    color: '#767676',
  },
}))

export interface UpcomingTreatmentTableProps {
  patientCases: PatientCase[]
  isLoading: boolean
}

const UpcomingTreatmentTable: FC<UpcomingTreatmentTableProps> = ({
  patientCases,
  isLoading,
}) => {
  const classes = useStyles()
  const { push } = useHistory()
  const { localizedFormatDate } = useDates()

  const formatTreatmentDate = (dateString: string) => {
    const unformattedDate = parseYMD(dateString)
    let formattedDate

    if (isTomorrow(unformattedDate)) {
      formattedDate = 'Tomorrow'
    } else if (isToday(unformattedDate)) {
      formattedDate = 'Today'
    } else {
      formattedDate = localizedFormatDate(unformattedDate)
    }

    return formattedDate
  }

  // function for grouping the patient cases by date and patient based on
  // the earliest treatment date if treatments exist, or
  // anticipated treatment date if not
  const determineDate = (patientCase: PatientCase) => {
    let dateForUse
    if (
      patientCase.patientTreatments &&
      patientCase.patientTreatments.length > 0
    ) {
      const treatmentDates = patientCase.patientTreatments.map((treatment) => {
        return treatment.treatmentDate
      })
      const treatmentNumbers = treatmentDates.map((date) => {
        return Number(new Date(date))
      })
      const minNumber = Math.min.apply(null, treatmentNumbers)
      const minIndex = treatmentNumbers.indexOf(minNumber)
      dateForUse = String(treatmentDates[minIndex])
    } else {
      dateForUse = String(patientCase.anticipatedTreatmentDate)
    }
    return dateForUse
  }

  const renderDiagnosis = (patientCase: PatientCase) => {
    const diagnosesDone =
      patientCase.patientDiagnoses && patientCase.patientDiagnoses.length > 0

    const diagnosisIcon = diagnosesDone ? (
      <span>
        <Check size={12} />
      </span>
    ) : (
      <span>
        <Info size={12} />
      </span>
    )
    const diagnosisLabel = diagnosesDone
      ? patientCase?.patientDiagnoses?.[0]?.medicalCondition?.name
      : 'Diagnosis'
    return (
      <Box
        className={clsx({
          [classes.missingInfo]: !diagnosesDone,
        })}
      >
        <Chip
          size="small"
          icon={diagnosisIcon}
          label={diagnosisLabel}
          variant="outlined"
          onDelete={() => {}}
          deleteIcon={<ChevronRight size={12} />}
          onClick={() => {
            push({
              pathname: PatientFlowUrls.diagnosis.create(
                patientCase.patientId,
                patientCase.id,
              ),
              state: { from: location.pathname },
            })
          }}
        />
      </Box>
    )
  }

  const renderTreatment = (patientCase: PatientCase) => {
    const treatmentDone =
      patientCase.patientTreatments && patientCase.patientTreatments?.length > 0

    const treatmentIcon = treatmentDone ? (
      <span>
        <Check size={12} />
      </span>
    ) : (
      <span>
        <Info size={12} />
      </span>
    )
    const sortedTreatments = patientCase.patientTreatments?.sort(
      (a, b) =>
        new Date(a.treatmentDate).getTime() -
        new Date(b.treatmentDate).getTime(),
    )

    const treatmentLabel = treatmentDone
      ? sortedTreatments?.[0]?.medicalTreatment?.name
      : 'Treatment'
    return (
      <Box
        className={clsx({
          [classes.missingInfo]: !treatmentDone,
        })}
      >
        <Chip
          size="small"
          icon={treatmentIcon}
          label={treatmentLabel}
          variant="outlined"
          onDelete={() => {}}
          deleteIcon={<ChevronRight size={12} />}
          onClick={() => {
            if (sortedTreatments?.[0]?.id) {
              push({
                pathname: `/flow/${patientCase.patientId}/case/${patientCase.id}/treatment/${sortedTreatments?.[0]?.id}`,
                state: { from: location.pathname },
              })
            } else {
              push({
                pathname: `/flow/${patientCase.patientId}/case/${patientCase.id}/treatment`,
                state: { from: location.pathname },
              })
            }
          }}
        />
      </Box>
    )
  }

  const renderBaseline = (patientCase: PatientCase) => {
    const baselineDone = patientCase.responsesCompleted
    const srg = patientCase.baselineSurveyResponseGroup
    const baselineIcon = baselineDone ? (
      <span>
        <Check size={12} />
      </span>
    ) : (
      <span>
        <Info size={12} />
      </span>
    )
    const baselineLabel = 'Baseline'

    const baselineChip = () => {
      return (
        <Box
          className={clsx({
            [classes.missingInfo]: !baselineDone,
          })}
        >
          <Chip
            size="small"
            icon={baselineIcon}
            label={baselineLabel}
            variant="outlined"
            onDelete={() => {}}
            deleteIcon={<ChevronRight size={12} />}
            onClick={() => {}}
          />
        </Box>
      )
    }
    return baselineDone ? (
      baselineChip()
    ) : srg ? (
      <DialogTrigger
        dialogProps={{ keepMounted: true }}
        action={baselineChip()}
        content={(toggle) => (
          <Box p={3}>
            <PatientOpenSurveys openSurveys={[srg]} parentToggle={toggle} />
          </Box>
        )}
      />
    ) : null
  }

  const casesByPatientAndDate = groupBy(
    (pc) => `${pc.patientId}-${determineDate(pc)}`,
    patientCases,
  )

  return (
    <Box className={classes.root}>
      {isLoading ? (
        <div className={classes.loadingContainer}>
          <CircularProgress />
        </div>
      ) : patientCases.length ? (
        <List>
          {Object.values(casesByPatientAndDate).map((patientCases) => {
            const relevantCase = patientCases[0]
            return (
              <Fragment key={relevantCase.patientId}>
                <div className={classes.container}>
                  <div className={classes.rowHeader}>
                    <Button
                      size="small"
                      variant="text"
                      onClick={() =>
                        push({
                          pathname: `/patients/${relevantCase.patientId}`,
                          state: { from: location.pathname },
                        })
                      }
                    >
                      <Body1 className={classes.patientName}>
                        {`${relevantCase.patient?.firstName} ${relevantCase.patient?.lastName}`}
                      </Body1>
                    </Button>

                    <Body2 className={classes.upcomingDate}>
                      <span
                        className={clsx({
                          [classes.red]: isToday(
                            parseYMD(determineDate(relevantCase)),
                          ),
                        })}
                      >
                        <Calendar size={16} /> Coming{' '}
                        {formatTreatmentDate(determineDate(relevantCase))}
                      </span>
                    </Body2>
                  </div>
                  <div className={classes.row}>
                    <div className={classes.contentColumn}>
                      {patientCases.map((patientCase) => {
                        return (
                          <div
                            className={classes.caseContainer}
                            key={patientCase.id}
                          >
                            <Body1 className={classes.caseData}>
                              {patientCase.bodySite?.name}
                            </Body1>
                            <div className={classes.caseData}>
                              {renderBaseline(patientCase)}
                            </div>
                            <div className={classes.caseData}>
                              {renderDiagnosis(patientCase)}
                            </div>
                            <div className={classes.caseData}>
                              {renderTreatment(patientCase)}
                            </div>
                          </div>
                        )
                      })}
                    </div>
                  </div>
                </div>
              </Fragment>
            )
          })}
        </List>
      ) : (
        <Box p={3} width="100%" textAlign="center">
          <Body1>{isLoading ? 'Loading...' : <EmptyTreatmentImage />}</Body1>
          <Body1 className={classes.emptyDataText}>
            {isLoading
              ? ''
              : 'You don’t have any Upcoming Treatments scheduled for this week.'}
          </Body1>
        </Box>
      )}
    </Box>
  )
}

export default UpcomingTreatmentTable
