var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Children, cloneElement, isValidElement, useState, } from 'react';
import clsx from 'clsx';
import { Button, ButtonGroup } from '../';
const ControlledButtonGroup = (_a) => {
    var { children, onButtonSelect } = _a, rest = __rest(_a, ["children", "onButtonSelect"]);
    const [loading, setLoading] = useState();
    const [selected, setSelected] = useState();
    const handleClick = (id) => __awaiter(void 0, void 0, void 0, function* () {
        setSelected(undefined);
        if (id !== selected) {
            setLoading(id);
            try {
                yield onButtonSelect(id);
                setSelected(id);
            }
            finally {
                setLoading(undefined);
            }
        }
    });
    const processedChildren = Children.map(children, (child) => {
        if (!isValidElement(child) || child.type !== Button) {
            return child;
        }
        const { id, selected: childSelected } = child.props;
        const isSelected = childSelected || (selected !== undefined && id === selected);
        const isLoading = loading !== undefined && id === loading;
        return cloneElement(child, {
            loading: isLoading,
            selected: isSelected,
            className: clsx(child.props.className, {
                'Button-selected': isSelected,
            }),
            onClick: (event) => {
                handleClick(id);
                if (child.props.onClick) {
                    child.props.onClick(event);
                }
            },
        });
    });
    return _jsx(ButtonGroup, Object.assign({}, rest, { children: processedChildren }), void 0);
};
export default ControlledButtonGroup;
