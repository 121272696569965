import { isItemEnabled } from '../';
/**
 * Determines whether the provided set of answers satisifies the
 * provided questions.
 *
 * This is very basic right now as most of the validation is happening
 * in each question component. I'm not sure if that will be the case long-term
 */
export const areQuestionsAnswered = (questions, answers, studySlug, bodySiteKey) => {
    return questions.every((question) => {
        // TODO non-required questions can have invalid answers and should be checked
        if (!question.required)
            return true;
        if (!isItemEnabled(answers, question, studySlug, bodySiteKey))
            return true;
        return answers.some((answer) => answer.surveyQuestionId === question.id &&
            answer.values.some((value) => value !== undefined));
    });
};
