import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { ArchivalReason } from './useArchivalReasons'
import { PatientCase } from './usePatientCases'
import { Site } from './useSitesAvailable'

export interface ArchivalOccurrence {
  archivalReason: ArchivalReason
  archivedBy: ArchivedBy
}

interface ArchivedBy {
  id: string
  firstName: string
  lastName: string
  organizationId: string
  type: string
  userId: string
}

export interface Patient {
  id: string
  sites: Site[]
  biologicalSex: 'male' | 'female' | 'other'
  bmiScore?: string
  weight?: number
  heightFeet?: number
  heightInches?: number
  firstName: string
  lastName: string
  email: string
  cellPhoneCountryCode: string
  cellPhoneLocal: string // i.e. '5555555555'
  phoneData?: PatientPhoneData
  dateOfBirth: string // in the shape of 'yyyy-mm-dd'
  createdAt: string
  updatedAt?: string
  medicalRecordNumber: string
  status: 'active' | 'archived' | 'hidden'
  archivalOccurrence?: ArchivalOccurrence
  patientCases?: PatientCase[]
  isValidPhone?: boolean
  isOptedOutPhone?: boolean
  patientCommunications?: PatientCommunication[]
  ethnicity?: string[]
  smoker?: 'never_a_smoker' | 'former_smoker' | 'current_smoker'
  zipCode?: string
}

interface PatientPhoneData {
  phoneType: string
}

interface PatientCommunication {
  id: string
  commChannel: 'sms' | 'email'
  content: string
  endpoint: string
  purpose: string
  status: string
  deliveryResults: string
  createdAt: string
  discardedAt: string
  deliveryData: PatientCommunicationDeliveryData
}

interface PatientCommunicationDeliveryData {
  link?: string
  method?: string
  patientId?: string
  siteName?: string
  content?: string
  localPhone?: string
  countryCode?: string
}
interface PatientDetailsResponse {
  patient: Patient
}

const usePatient = (
  patientId?: string,
  extraOptions: UseQueryOptions<Patient> = {},
) => {
  const currentSiteId = useCurrentSite()?.id
  const siteIds = currentSiteId

  const key = patientId ? QueryKeys.patient({ currentSiteId, patientId }) : ''
  const url = `/sites/patients/${patientId}?site_ids[]=${siteIds}`

  return useQuery(
    key,
    () => request.get<PatientDetailsResponse>(url).then((r) => r.data.patient),
    {
      enabled: Boolean(patientId) && Boolean(currentSiteId),
      ...extraOptions,
    },
  )
}

export default usePatient
