import { FC, useEffect, useState } from 'react'
import {
  Backdrop,
  Body1,
  Box,
  Button,
  Card,
  CardContent,
  ErrorBoundary,
  FallbackProps,
  H3,
  Link,
  makeStyles,
  Paper,
} from '@perk-ui/core'
import { AlertCircle } from 'react-feather'
import { useHistory, useLocation } from 'react-router-dom'

import apm from '../config/analytics'
import { useAuth } from '../features/auth/AuthContext'
import useClinicStaffs from '../features/query-hooks/useClinicStaffs'
import AppLink from './AppLink'
import { useSitesContext } from './SitesProvider'

export interface AppErrorBoundaryProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '75%',
  },
  link: {
    cursor: 'pointer',
    '&&': {
      color: theme.palette.primary.main,
    },
  },
  errorDetails: {
    whiteSpace: 'break-spaces',
    lineHeight: 1,
    marginTop: 0,
  },
  backdrop: {
    zIndex: 9999,
    backdropFilter: 'blur(4px)',
  },
  backdropContainer: {
    padding: theme.spacing(3),
  },
  backdropContent: {
    padding: theme.spacing(2, 3),
  },
  backdropIcon: {
    textAlign: 'center',
    padding: theme.spacing(2, 0, 2, 0),
    '& svg': {
      backgroundColor: '#EAF7FD',
      borderRadius: '40px',
      height: '64px',
      width: '64px',
      padding: theme.spacing(2),
    },
  },
  backdropTextContainer: {
    textAlign: 'center',
    padding: theme.spacing(1, 0, 3, 0),
    '& h3': {
      fontSize: '32px',
      fontWeight: 700,
      marginBottom: theme.spacing(2),
    },
    '& p.MuiTypography-body1': {
      fontSize: '16px',
    },
  },
  alertButton: {
    borderRadius: '16px',
  },
}))

export const pathsWithoutBackdrop = [
  '/login',
  '/create-account',
  '/login-new',
  '/forgot-password',
  '/password-reset',
  '/activate-account',
  '/resend-activation-link',
  '/onboarding',
  '/sign-contract',
  '/payment',
]

const AppErrorBoundary: FC<AppErrorBoundaryProps> = ({ children }) => {
  const { isAuthenticated } = useAuth()
  const { currentSite } = useSitesContext()

  const BackdropEl: FC = () => {
    const { data: clinicStaffs, isLoading } = useClinicStaffs()
    const [backdropOpen, setBackdropOpen] = useState(false)
    const classes = useStyles()
    const { pathname } = useLocation()
    const { push } = useHistory()
    const isCurrentLocation = (path: string) => pathname.startsWith(path)
    useEffect(() => {
      const validNpiProvidersCount = (clinicStaffs || []).filter(
        (staff) => staff.type === 'Provider' && staff.npi,
      ).length
      const showBackdrop =
        !pathsWithoutBackdrop.some(isCurrentLocation) &&
        validNpiProvidersCount == 0
      if (!isLoading && showBackdrop && !backdropOpen)
        setBackdropOpen(showBackdrop)
    }, [clinicStaffs, pathname])
    return (
      <Backdrop className={classes.backdrop} open={backdropOpen}>
        <Paper className={classes.backdropContainer}>
          <Box className={classes.backdropContent}>
            <Box className={classes.backdropIcon}>
              <AlertCircle color={'#2BADEE'} />
            </Box>
            <Box className={classes.backdropTextContainer}>
              <H3>Missing Verified Provider</H3>
              <Body1>
                You need at least one verified provider in order to access the
                platform.
              </Body1>
            </Box>
            <Button
              onClick={() => push('/onboarding/clinic-users')}
              fullWidth
              className={classes.alertButton}
            >
              Add Provider
            </Button>
          </Box>
        </Paper>
      </Backdrop>
    )
  }

  return (
    <ErrorBoundary FallbackComponent={ErrorPage}>
      {isAuthenticated && currentSite && !__PROD__ && <BackdropEl />}
      {children}
    </ErrorBoundary>
  )
}

const ErrorPage: FC<FallbackProps> = ({ error, resetErrorBoundary }) => {
  const classes = useStyles()

  useEffect(() => {
    apm.captureError(error)
  }, [apm, error])

  const handleRefreshClick = () => {
    // Full reload of the page
    window.location.reload()
  }

  return (
    <Box p={3} pl={0} className={classes.root}>
      <Card role="alert">
        <CardContent>
          <H3 paragraph>Something went wrong.</H3>
          <Body1 gutterBottom>
            We&apos;re sorry about that. We&apos;ve alerted our team of this
            issue.
          </Body1>
          <Body1 gutterBottom>
            In the meantime, try{' '}
            <Link
              className={classes.link}
              component="span"
              underline="hover"
              onClick={handleRefreshClick}
            >
              <b>Refreshing</b>
            </Link>{' '}
            or going back to the{' '}
            <AppLink
              className={classes.link}
              to="/home"
              underline="hover"
              onClick={resetErrorBoundary}
            >
              <b>Home</b>
            </AppLink>{' '}
            page.
          </Body1>
          <br />
          <Body1>Details for our team</Body1>
          <pre className={classes.errorDetails}>{error.message}</pre>
        </CardContent>
      </Card>
    </Box>
  )
}

export default AppErrorBoundary
