import { cloneElement, isValidElement } from 'react';
import { useTheme } from '../';
/**
 * A convenient way to apply standard colors and sizes to an icon.
 * (This is not an extension of Material UI's Icon component.)
 */
const Icon = ({ children, color: colorProp = 'inherit', size: sizeProp = 'large', }) => {
    const { perk, palette } = useTheme();
    const iconSize = perk.icon.size;
    /* We only set the new props if they are present and have valid values. This
       prevents undefined or invalid props from overriding props that were set
       directly on the icon child (e.g., color="inherit"). */
    const newIconProps = {};
    const colorMap = {
        disabled: palette.action.disabled,
        error: palette.error.main,
        warning: palette.warning.main,
        primary: palette.primary.main,
        primaryText: palette.text.primary,
        secondaryText: palette.text.secondary,
        success: palette.success.main,
    };
    if (Object.keys(colorMap).includes(colorProp)) {
        newIconProps.color = colorMap[colorProp];
    }
    // Verify that the specified iconSize property exists on the theme.
    if (iconSize != undefined && Object.keys(iconSize).includes(sizeProp)) {
        const size = iconSize[sizeProp];
        newIconProps.height = size;
        newIconProps.width = size;
    }
    if (isValidElement(children)) {
        return cloneElement(children, newIconProps);
    }
};
export default Icon;
