import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { BodySite } from './useBodySites'
import { PatientDiagnosis } from './useCreateDiagnosis'
import { ArchivalOccurrence, Patient } from './usePatient'
import { PatientTreatment } from './usePatientTreatment'
import { Protocol } from './useProtocols'
import { Study } from './useStudies'
import { SurveyResponseGroup } from './useSurveyResponseGroups'

type MinimalPatient = Pick<Patient, 'id' | 'firstName' | 'lastName'>

export interface PatientCase {
  id: string
  bodySiteId: string
  patientId: string
  providerId: string
  siteId: string
  anticipatedTreatmentDate: string // i.e. "2022-06-08"
  patientDiagnoses?: PatientDiagnosis[]
  patientTreatments?: PatientTreatment[]
  patient?: MinimalPatient
  bodySite?: BodySite
  baselineSurveyResponseGroup?: SurveyResponseGroup
  responsesCompleted?: boolean
  status: 'active' | 'manually_archived' | 'auto_archived' | 'hidden'
  surveyStatus?: 'archived' | 'expiring_soon' | 'open' | 'none_pending'
  archivalOccurrence?: ArchivalOccurrence
  study?: Study
  studyConsents: StudyConsent[]
  siteNumber?: number
  patientStudyNumber?: string
  signedConsent: boolean | undefined
  surveyExpiresOn?: string
  surveyPeriod?: string
  protocol?: Protocol
  protocolId?: string
}

export interface StudyConsent {
  id: string
  consentsToStudy: boolean
  link: string
  createdAt: string
}

export interface PatientCaseResponse {
  patientCases: PatientCase[]
}

const usePatientCases = (
  patientId?: string,
  extraOptions: UseQueryOptions<PatientCase[]> = {},
) => {
  const currentSiteId = useCurrentSite()?.id

  const key = patientId ? QueryKeys.patientCases({ patientId }) : []
  const url = `/sites/patient_cases?patient_id=${patientId}&site_ids[]=${currentSiteId}&includes[]=patient_diagnoses&includes[]=patient_treatments`

  return useQuery(
    key,
    () =>
      request.get<PatientCaseResponse>(url).then((r) => r.data.patientCases),
    {
      enabled: Boolean(patientId) && Boolean(currentSiteId),
      ...extraOptions,
    },
  )
}

export default usePatientCases
