import { FC, useState } from 'react'
import {
  Alert,
  Body1,
  Box,
  Button,
  DialogTrigger,
  Divider,
  Grid,
  H3,
  H5,
  IconButton,
  InputAdornment,
  Link,
  makeStyles,
  TextField,
} from '@perk-ui/core'
import {
  AddressElement,
  Elements,
  // ElementsConsumer,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import clsx from 'clsx'
import { useFormik } from 'formik'
import { Link as LinkIcon, Tag, X } from 'react-feather'
import { NumericFormat } from 'react-number-format'
import Swal from 'sweetalert2'
import * as yup from 'yup'

import config from '../config/app-config'
import useApplyCoupon from '../features/query-hooks/useApplyCoupon'
import useEmailPaymentLink from '../features/query-hooks/useEmailPaymentLink'
import { Product } from '../features/query-hooks/useProducts'
import useRemoveCoupon from '../features/query-hooks/useRemoveCoupon'
import { Subscription } from '../features/query-hooks/useSubscription'
import useUpdateSubscription from '../features/query-hooks/useUpdateSubscription'

// import BrandLogo from '../assets/BrandLogo.png'

const useStyles = makeStyles((theme) => ({
  checkoutSummary: {
    backgroundColor: '#FAFAFB',
    borderRadius: '20px',
    padding: theme.spacing(3),
    '& h3': {
      marginBottom: theme.spacing(2),
    },
  },
  paymentLink: {
    marginLeft: theme.spacing(3),
    cursor: 'pointer',
    color: theme.palette.common.black,
    '&:hover': {
      color: theme.palette.primary.main,
    },
  },
  stripeFormSection: {
    '& h3': {
      fontSize: '20px',
      fontWeight: 700,
      marginBottom: theme.spacing(2),
      '&:last-of-type': {
        marginTop: theme.spacing(2),
      },
    },
  },
  couponSection: {
    '& h5': {
      margin: theme.spacing(1, 0),
      fontSize: '16px',
    },
    paddingBottom: theme.spacing(2),
  },
  couponInput: {
    '& input': {
      minHeight: '40px',
      height: '40px',
      padding: theme.spacing(1, 2),
    },
  },
  couponButton: {
    alignSelf: 'end',
    '& button': {
      width: '100%',
      padding: theme.spacing(1, 2),
    },
  },
  errorButton: {
    marginBottom: '27px!important',
  },
  summaryProductDescripton: {},
  summaryProductName: {},
  summaryProductPriceDetails: {
    color: '#767676',
  },
  summaryPrices: {
    textAlign: 'right',
  },
  summaryTotalText: {
    lineHeight: '32px',
    alignSelf: 'center',
  },
  summaryTotal: {
    textAlign: 'right',
    fontSize: '32px',
    fontWeight: 700,
  },
  couponBox: {
    width: '100%',
    '& .MuiPaper-root': {
      backgroundColor: 'rgb(234, 248, 234)',
    },
    '& .MuiAlert-icon': {
      '& svg': {
        margin: 'auto',
      },
    },
    '& .MuiAlert-action': {
      '& button': {
        margin: 'auto',
      },
    },
  },
  summaryProductItem: {
    marginBottom: theme.spacing(1),
  },
  summaryDivider: {
    margin: theme.spacing(1, 0),
  },
  modalHeader: {
    textAlign: 'center',
    '& h3': {
      padding: theme.spacing(1, 0, 2, 0),
    },
    paddingBottom: theme.spacing(2),
  },
  modalCloseButton: {
    float: 'right',
  },
  paymentLinkForm: {
    '& .MuiFormControl-root': {
      padding: theme.spacing(3, 0),
      borderRadius: '16px',
      '& input:-webkit-autofill': {
        transition: 'background-color 0s 600000s, color 0s 600000s !important',
        '&:focus': {
          transition:
            'background-color 0s 600000s, color 0s 600000s !important',
        },
      },
      '& .MuiInput-root': {
        '&:hover': {
          // '&.Mui-disabled': {
          '& svg': {
            color: 'inherit',
          },
          // },
        },
      },
    },
    '& button': {
      borderRadius: '16px',
    },
  },
  inputAdornment: {
    height: '100%',
  },
  adornmentButton: {
    borderRadius: '8px',
    color: '#545454',
    fontSize: '14px',
    padding: theme.spacing(0, 2),
    marginRight: theme.spacing(1.5),
    '& svg': {
      color: '#545454',
    },
    '&.Mui-disabled': {
      '& svg': {
        color: 'inherit',
      },
    },
  },
}))

export interface StripeCheckoutProps {
  subscription?: Subscription
  products: Array<Product>
  successAction: () => void
  showPaymentLink?: boolean
  unauthSiteId?: string
}

export interface StripeElementProps {}
interface EmailPaymentLinkFormValues {
  email: string
}
const StripeCheckout: FC<StripeCheckoutProps> = ({
  subscription,
  products,
  successAction,
  showPaymentLink = true,
  unauthSiteId,
}) => {
  const classes = useStyles()
  const stripePromise = loadStripe(config.stripePublictKey || '')
  const options = {
    // passing the client secret obtained from the server
    clientSecret: subscription?.lastInvoicePi,
    // Fully customizable with appearance API.
    appearance: {
      variables: {
        colorPrimary: '#56AAEB',
        colorBackground: '#FAFAFB',
        colorText: '#000000',
        // colorDanger: '#df1b41',
        fontFamily: 'DM Sans,sans-serif',
        spacingUnit: '2px',
        borderRadius: '16px',
      },
      rules: {
        '.Input': {
          padding: '16px 14px',
        },
        '.Input:focus': {
          boxShadow: 'none',
        },
        '.Label': {
          fontWeight: '700',
          marginBottom: '8px',
        },
        '.Input--invalid': {
          boxShadow: 'none',
        },
      },
    },
  }

  const ShareableLinkEl: FC = () => {
    const emailPaymentLink = useEmailPaymentLink()
    const [emailSent, setEmailSent] = useState(false)
    const [linkCopied, setLinkCopied] = useState(false)
    const loginValidations = yup.object({
      email: yup
        .string()
        .required('Email is required')
        .email('Ensure the email is formatted correctly'),
    })
    const {
      values,
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      isSubmitting,
      isValid,
      touched,
    } = useFormik<EmailPaymentLinkFormValues>({
      enableReinitialize: true,
      initialValues: {
        email: '',
      },
      validationSchema: loginValidations,
      onSubmit: (values) => {
        return emailPaymentLink
          .mutateAsync({
            email: values.email,
            link: subscription?.id || '',
          })
          .then(() => {
            setEmailSent(true)
          })
      },
    })
    return (
      <DialogTrigger
        // disabled={!paymentLink}
        action={
          <Link
            component={Body1}
            className={classes.paymentLink}
            underline="hover"
          >
            Get shareable Payment link
          </Link>
        }
        actionProps={{
          style: { display: 'inline-flex' },
        }}
        content={(toggle) => (
          <Box p={3}>
            <Box className={classes.modalHeader}>
              <IconButton
                className={classes.modalCloseButton}
                aria-label="close"
                onClick={() => {
                  toggle()
                }}
              >
                <X size={16} />
              </IconButton>
              <H3>Send Payment Link</H3>
              <H5>Share payment link with authorised user</H5>
            </Box>
            <Box>
              <form className={classes.paymentLinkForm} onSubmit={handleSubmit}>
                {(emailSent || linkCopied) && (
                  <Alert severity="success" fade grow>
                    {emailSent
                      ? 'Payment link has been sent to the email.'
                      : ''}
                    {emailSent && linkCopied && <br />}
                    {linkCopied ? 'Link Copied' : ''}
                  </Alert>
                )}
                <TextField
                  label="Email"
                  name="email"
                  placeholder="Enter email"
                  fullWidth
                  value={values.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  error={touched.email && Boolean(errors.email)}
                  helperText={touched.email && errors.email}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment
                        position="end"
                        className={classes.inputAdornment}
                      >
                        <Button
                          size="small"
                          variant="outlined"
                          className={classes.adornmentButton}
                          disabled={linkCopied}
                          startIcon={<LinkIcon size={16} />}
                          onClick={() => {
                            setLinkCopied(true)
                            navigator.clipboard.writeText(
                              window.location.origin +
                                '/payment?s=' +
                                subscription?.id,
                            )
                            setTimeout(() => {
                              setLinkCopied(false)
                            }, 2000)
                          }}
                        >
                          Copy Link
                        </Button>
                      </InputAdornment>
                    ),
                  }}
                />

                <Button
                  type="submit"
                  fullWidth
                  loading={isSubmitting}
                  disabled={!isValid}
                >
                  Send payment link by email
                </Button>
                <Button fullWidth variant="text" onClick={() => toggle()}>
                  Cancel
                </Button>
              </form>
            </Box>
          </Box>
        )}
      />
    )
  }

  const StripeEl: FC = () => {
    const stripe = useStripe()
    const elements = useElements()
    const updateSubscription = useUpdateSubscription()
    const [isLoading, setIsLoading] = useState(false)

    const checkout = async (
      event: React.FormEvent<HTMLFormElement> | undefined,
    ) => {
      event?.preventDefault()
      if (subscription?.status == 'active') {
        return successAction()
      }
      if (elements == null) {
        return
      }
      setIsLoading(true)
      // Trigger form validation and wallet collection
      const { error: submitError } = await elements.submit()
      if (submitError) {
        // Show error to your customer
        // setErrorMessage(submitError.message)
        setIsLoading(false)
        return
      }

      if (stripe) {
        const { error } = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          clientSecret: subscription?.lastInvoicePi || '',
          confirmParams: {
            return_url: 'https://example.com/order/123/complete',
          },
          redirect: 'if_required',
        })

        if (error) {
          // This point will only be reached if there is an immediate error when
          // confirming the payment. Show error to your customer (for example, payment
          // details incomplete)
          // setErrorMessage(error.message)
          Swal.fire({
            toast: false,
            width: 500,
            position: 'center',
            showConfirmButton: true,
            showCloseButton: true,
            confirmButtonText: 'Try Again',
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonColor: '#56AAEB',
            customClass: {
              container: 'payment-swal-container',
              popup: 'payment-swal-popup',
            },
            icon: 'warning',
            title: 'Payment failed',
            text: 'Your payment failed, please recheck card information and try again',
          }).then(() => {
            setIsLoading(false)
          })
        } else {
          updateSubscription
            .mutateAsync({
              subscriptionId: subscription?.id || '',
              unauthSiteId: unauthSiteId,
            })
            .then(() => {
              Swal.fire({
                toast: false,
                width: 500,
                position: 'center',
                showConfirmButton: true,
                showCloseButton: true,
                confirmButtonText: 'Continue',
                allowOutsideClick: false,
                allowEscapeKey: false,
                confirmButtonColor: '#56AAEB',
                customClass: {
                  container: 'payment-swal-container',
                  popup: 'payment-swal-popup',
                },
                icon: 'success',
                title: 'Payment Successful',
                html: `Your order has been placed. We'll send you an email with your order details. <br/> Continue to start your journey with DataBiologics`,
              }).then((result) => {
                if (result.isConfirmed) {
                  setIsLoading(false)
                  successAction()
                }
              })
            })
          // Your customer will be redirected to your `return_url`. For some payment
          // methods like iDEAL, your customer will be redirected to an intermediate
          // site first to authorize the payment, then redirected to the `return_url`.
        }
      }
    }

    if (subscription?.status === 'free') successAction()
    const subtotal = subscription?.stripeSubscription?.latestInvoice.subtotal
    const total = subscription?.stripeSubscription?.latestInvoice.total
    const discountAmount =
      subscription?.stripeSubscription?.latestInvoice.totalDiscountAmounts.reduce(
        (accumulator, discount) => {
          return accumulator + discount.amount
        },
        0,
      ) || 0

    return (
      <form onSubmit={checkout}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} className={classes.stripeFormSection}>
            {subscription?.status !== 'active' && (
              <Box>
                <H3>Payment Details</H3>
                <PaymentElement />
                <H3>Billing Address</H3>
                <AddressElement options={{ mode: 'billing' }} />
              </Box>
            )}
          </Grid>
          <Grid item xs={12} md={6}>
            <Box className={classes.checkoutSummary}>
              <H3>Order Summary</H3>
              <CouponEl />
              <Box>
                {products &&
                  products.map((product) => {
                    const foundSubscriptionProduct =
                      subscription?.subscriptionProducts.find(
                        (prod) => prod.stripeProductId == product.id,
                      )
                    if (!foundSubscriptionProduct) return <></>
                    let displayPrice = product.defaultPrice.amount
                    if (product.defaultPrice.tiers.length > 0) {
                      const currentTier = product.defaultPrice.tiers.find(
                        (tier) => {
                          return (
                            Number(tier.upTo) >=
                              foundSubscriptionProduct.quantity ||
                            tier.upTo == null
                          )
                        },
                      )
                      displayPrice =
                        (currentTier?.flatAmount || 0) +
                        (currentTier?.unitAmount || 0)
                    }
                    return (
                      <Grid
                        container
                        key={product.id}
                        spacing={2}
                        className={classes.summaryProductItem}
                      >
                        <Grid
                          item
                          xs={6}
                          className={classes.summaryProductDescripton}
                        >
                          <Grid className={classes.summaryProductName}>
                            {product.name}
                          </Grid>
                          <Grid className={classes.summaryProductPriceDetails}>
                            <NumericFormat
                              value={(displayPrice || 0) / 100}
                              displayType="text"
                              thousandSeparator
                              prefix={'$'}
                            />
                            x{foundSubscriptionProduct.quantity}/
                            {product.defaultPrice.interval}
                          </Grid>
                        </Grid>
                        <Grid item xs={6} className={classes.summaryPrices}>
                          <div>
                            <NumericFormat
                              value={
                                (displayPrice *
                                  foundSubscriptionProduct.quantity || 0) / 100
                              }
                              displayType="text"
                              thousandSeparator
                              prefix={'$'}
                            />
                          </div>
                          <div>/{product.defaultPrice.interval}</div>
                        </Grid>
                      </Grid>
                    )
                  })}
              </Box>
              <Divider className={classes.summaryDivider} />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Grid item xs={12}>
                    Sub Total
                  </Grid>
                  <Grid item xs={12}>
                    Discount
                  </Grid>
                </Grid>
                <Grid item xs={6} className={classes.summaryPrices}>
                  <Grid item xs={12}>
                    <NumericFormat
                      value={(subtotal || 0) / 100}
                      displayType="text"
                      thousandSeparator
                      prefix={'$'}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    {discountAmount > 0 ? (
                      <NumericFormat
                        value={discountAmount / 100}
                        displayType="text"
                        thousandSeparator
                        prefix={'$'}
                      />
                    ) : (
                      '--'
                    )}
                  </Grid>
                </Grid>
              </Grid>
              <Divider className={classes.summaryDivider} />
              <Grid container spacing={2}>
                <Grid item xs={6} className={classes.summaryTotalText}>
                  Total Due Today
                </Grid>
                <Grid item xs={6} className={classes.summaryTotal}>
                  <NumericFormat
                    value={(total || 0) / 100}
                    displayType="text"
                    thousandSeparator
                    prefix={'$'}
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Button
              type={'submit'}
              disabled={!stripe || !elements}
              loading={isLoading}
            >
              {subscription?.status == 'active' ? 'Continue' : 'Make Payment'}
            </Button>
            {showPaymentLink && subscription?.status !== 'active' && (
              <ShareableLinkEl />
            )}
          </Grid>
        </Grid>
      </form>
    )
  }

  const CouponEl: FC = () => {
    const applyCoupon = useApplyCoupon()
    const removeCoupon = useRemoveCoupon()
    const [coupon, setCoupon] = useState<string>('')
    const [couponError, setCouponError] = useState<string>('')
    const handleApplyCoupon = () => {
      return applyCoupon
        .mutateAsync({
          coupon: coupon,
          subscriptionId: subscription?.id || '',
        })
        .then(() => {})
        .catch((err) => {
          if (err.response.data.errors == 'invalid coupon') {
            setCouponError('The code you entered is not valid.')
          } else {
            setCouponError(
              'An error ocurred applying the coupon, please try again',
            )
          }
        })
    }

    const handleRemoveCoupon = (couponId: string) => {
      return removeCoupon
        .mutateAsync({
          coupon: couponId,
          subscriptionId: subscription?.id || '',
        })
        .then(() => {})
        .catch((err) => {
          if (err.response.data.errors == 'invalid coupon') {
            setCouponError('Invalid Coupon')
          } else {
            setCouponError(
              'An error ocurred applying the coupon, please try again',
            )
          }
        })
    }
    return (
      <Box className={classes.couponSection}>
        <H5>Discount Code</H5>
        {subscription?.stripeSubscription &&
          subscription.stripeSubscription.latestInvoice.totalDiscountAmounts
            .length == 0 && (
            <Grid container spacing={2}>
              <Grid item xs={9} className={classes.couponInput}>
                <TextField
                  fullWidth
                  disabled={subscription?.status == 'active'}
                  className={couponError != '' ? 'error' : ''}
                  // label={'Discount Code'}
                  placeholder="Enter Code"
                  size={'small'}
                  onChange={(e) => {
                    setCoupon(e.target.value)
                    if (couponError != '') setCouponError('')
                  }}
                  error
                  helperText={couponError !== '' ? couponError : ''}
                />
              </Grid>
              <Grid item xs={3} className={classes.couponButton}>
                <Button
                  size={'small'}
                  onClick={handleApplyCoupon}
                  disabled={coupon == '' || subscription.status == 'active'}
                  className={clsx({
                    [classes.errorButton]: couponError !== '',
                  })}
                >
                  Apply
                </Button>
              </Grid>
            </Grid>
          )}
        {subscription?.stripeSubscription &&
          subscription.stripeSubscription.latestInvoice.discount && (
            <Box className={classes.couponBox}>
              <Alert
                icon={<Tag size={16} />}
                severity="success"
                action={
                  <IconButton
                    aria-label="close"
                    color="inherit"
                    size="small"
                    onClick={() => {
                      handleRemoveCoupon(
                        subscription?.stripeSubscription?.latestInvoice.discount
                          .coupon.id || '',
                      )
                    }}
                  >
                    <X size={16} />
                  </IconButton>
                }
              >
                {
                  subscription.stripeSubscription.latestInvoice.discount.coupon
                    .name
                }
                {' ('}
                {subscription.stripeSubscription.latestInvoice.discount.coupon
                  .amountOff > 0 && (
                  <NumericFormat
                    value={
                      subscription.stripeSubscription.latestInvoice.discount
                        .coupon.amountOff / 100
                    }
                    displayType="text"
                    thousandSeparator
                    suffix={'$'}
                  />
                )}
                {subscription.stripeSubscription.latestInvoice.discount.coupon
                  .percentOff > 0 &&
                  subscription.stripeSubscription.latestInvoice.discount.coupon
                    .percentOff + '%'}
                {' off)'}
              </Alert>
            </Box>
          )}
      </Box>
    )
  }
  if (!subscription) return <></>
  return (
    <Elements stripe={stripePromise} options={options}>
      <StripeEl />
    </Elements>
  )
}

export default StripeCheckout
