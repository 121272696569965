import { FC } from 'react'
import { Body1, capitalize, Card, H3, makeStyles } from '@perk-ui/core'

import SegmentedBar, {
  SegmentedBarItem,
} from '../../../components/SegmentedBar'
import { BodySiteKeyCamelCase } from '../../../features/query-hooks/useBodySites'
import usePatientDiagnosisStats from '../../../features/query-hooks/usePatientDiagnosisStats'

export const BodyPartColorList = [
  '#2396CF',
  '#76B1BC',
  '#6E91B4',
  '#D87246',
  '#6DA76D',
  '#AD4F59',
  '#8D7CA7',
  '#7C7366',
  '#AA7E96',
  '#808080',
  '#999A6C',
  '#A6C6D1',
  '#D8A68E',
  '#91BA97',
  '#D87E7B',
  '#B58A9D',
  '#B3998C',
  '#CF9FB6',
  '#A9A9A9',
  '#BABD8A',
  '#90B5BE',
  '#76B1BC',
  '#6E91B4',
  '#D87246',
  '#6DA76D',
  '#AD4F59',
  '#8D7CA7',
  '#7C7366',
  '#AA7E96',
  '#808080',
  '#999A6C',
] as const

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
  },
  divider: {
    margin: theme.spacing(2, 0),
  },
  segmentedLine: {
    marginBottom: theme.spacing(2),
  },
  legendItem: {
    display: 'flex',
    alignItems: 'center',
    margin: theme.spacing(2, 0),
  },
  legendSquare: {
    height: 24,
    width: 24,
    borderRadius: 5,
    display: 'inline-block',
    marginRight: theme.spacing(1),
  },
}))

// camelCase to space-separated Capital Case
export const keyToLabel = (key: string) =>
  Array.from(key)
    .flatMap((char) => (char.match(/[A-Z]/) ? [' ', char] : [char]))
    .join('')
    .split(' ')
    .map(capitalize)
    .join(' ')

export interface BodyPartGraphProps {}

const sumValue = (arr: SegmentedBarItem[]) =>
  arr.reduce((sum, item) => sum + item.value, 0)

const BodyPartGraph: FC<BodyPartGraphProps> = () => {
  const classes = useStyles()
  const { data: stats = {} } = usePatientDiagnosisStats()

  const dataItems = Object.keys(stats).map((key) => ({
    label: key,
    value: stats[key as BodySiteKeyCamelCase],
  })) as SegmentedBarItem[]
  const sortedItems = dataItems.sort((a, b) => b.value - a.value)
  const sum = sumValue(sortedItems)

  // const [bigItems, others] = partition(
  //   (item) => (item.value / sum) * 100 > 5,
  //   sortedItems,
  // )
  // const items = bigItems.concat([{ label: 'other', value: sumValue(others) }])

  return (
    <Card className={classes.root}>
      <H3 paragraph>Body Parts Treated: {sum}</H3>
      <SegmentedBar className={classes.segmentedLine} items={sortedItems} />
      {sortedItems.map((item, idx) => {
        return (
          <div key={item.label} className={classes.legendItem}>
            <div
              className={classes.legendSquare}
              style={{
                background: BodyPartColorList[idx],
              }}
            />
            <Body1 component="span">
              {keyToLabel(item.label)} {item.value}
            </Body1>
          </div>
        )
      })}
    </Card>
  )
}

export default BodyPartGraph
