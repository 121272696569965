import { FC } from 'react'
import { Box, H1, makeStyles } from '@perk-ui/core'
import { useLocation } from 'react-router'

import SideBannerContainer from '../../../components/SideBannerContainer'
import StripeCheckout from '../../../components/StripeCheckout'
import useProducts from '../../../features/query-hooks/useProducts'
import useUnauthenticatedSubscription from '../../../features/query-hooks/useUnauthenticatedSubscription'
import OnboardingBanner from '../components/OnboardingBanner'
import UnauthenticatedPaymentDone from '../UnauthenticatedPayment'

interface UnauthenticatedContractSignatureProps {}
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(3),
    maxWidth: '80%',
    // height: '100vh',
    margin: '0 auto',
    '& .step-header': {
      marginBottom: theme.spacing(4),
      '& h1': {
        fontSize: '32px',
        fontWeight: '700',
      },
      '& h3': {
        fontSize: '16px',
        fontWeight: '500',
      },
    },
    '& .step-content': {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      '& form': {
        height: '100%',
        overflow: 'auto',
        '& .MuiFormControl-root': {
          marginBottom: theme.spacing(0),
        },
        '& .MuiButton-root': {
          marginBottom: theme.spacing(0),
        },
      },
    },
  },
  stepContainer: {
    flex: '1 1 auto',
    // padding: theme.spacing(0, 2),
    overflow: 'hidden',
  },
}))

const UnauthenticatedContractSignature: FC<
  UnauthenticatedContractSignatureProps
> = () => {
  const classes = useStyles()
  const search = useLocation().search
  const searchParams = new URLSearchParams(search)
  const token = searchParams.get('s') || ''
  const {
    data: subscription,
    isLoading: isLoadingSubscription,
    isFetching: isFetchingSubscription,
  } = useUnauthenticatedSubscription(token)
  const {
    data: products,
    isLoading: isLoadingProducts,
    isFetching: isFetchingProducts,
  } = useProducts()
  const paymentType = subscription?.subscription
    ? subscription.subscription.status == 'active'
      ? 'payment-done'
      : 'payment-incomplete'
    : 'no-subscription'

  if (
    isLoadingSubscription ||
    isLoadingProducts ||
    isFetchingSubscription ||
    isFetchingProducts
  )
    return <></>
  if (
    !isLoadingSubscription &&
    !isLoadingProducts &&
    !isFetchingSubscription &&
    !isFetchingProducts &&
    paymentType !== 'payment-incomplete'
  )
    return <UnauthenticatedPaymentDone type={paymentType} />

  return (
    <SideBannerContainer
      bannerWidth={4}
      bannerSide="left"
      banner={<OnboardingBanner />}
    >
      <Box className={classes.root}>
        <Box className="step-content">
          <Box className="step-header">
            <H1>Payment Information</H1>
          </Box>
          <Box className={classes.stepContainer}>
            <StripeCheckout
              subscription={subscription?.subscription}
              products={products || []}
              showPaymentLink={false}
              successAction={() => {
                // setCurrentStep(currentStep + 1)
              }}
              unauthSiteId={subscription?.subscription.siteId}
            />
          </Box>
        </Box>
      </Box>
    </SideBannerContainer>
  )
}

export default UnauthenticatedContractSignature
