import { FC, useState } from 'react'
import {
  Box,
  capitalize,
  Collapse,
  findBy,
  H5,
  Icon,
  styled,
} from '@perk-ui/core'
import { ChevronDown } from 'react-feather'

import DataPair from '../../../components/DataPair'
import useAnatomicalLocations from '../../../features/query-hooks/useAnatomicalLocations'
import { PatientDiagnosis } from '../../../features/query-hooks/useCreateDiagnosis'
import useMedicalConditions from '../../../features/query-hooks/useMedicalConditions'

type IconWrapperProps = {
  isCollapsed?: boolean
}

const IconWrapper = styled(Box)<IconWrapperProps>`
  transition: all 0.3s ease-in-out;
  transform: rotate(${({ isCollapsed }) => (isCollapsed ? 0 : 180)}deg);
`
interface DiagnosisDetailProps {
  bodySiteId: string
  diagnosis: PatientDiagnosis
  isSecondary: boolean
}

const DiagnosisDetail: FC<DiagnosisDetailProps> = ({
  diagnosis,
  bodySiteId,
  isSecondary,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true)
  const { data: medicalConditions = [] } = useMedicalConditions(bodySiteId)
  const { data: locations = [] } = useAnatomicalLocations(
    diagnosis.medicalConditionId,
  )
  const conditionName = findBy(
    'id',
    diagnosis.medicalConditionId,
    medicalConditions,
  )?.name
  const locationDetails = locations.filter(
    (loc) => !!findBy('id', loc.id, diagnosis.locations),
  )

  return (
    <>
      <Box
        pr={2}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        pt={2}
        pb={1}
        onClick={() => setIsCollapsed(!isCollapsed)}
        sx={{ cursor: 'pointer' }}
      >
        <H5 gutterBottom sx={{ pointerEvents: 'none' }}>
          {isSecondary ? 'Secondary: ' : ''}
          {conditionName || ' '}
        </H5>
        <IconWrapper isCollapsed={isCollapsed}>
          <Icon>
            <ChevronDown />
          </Icon>
        </IconWrapper>
      </Box>
      <Collapse in={!isCollapsed}>
        <Box mb={2}>
          <DataPair label="Severity" value={capitalize(diagnosis.severity)} />
          <DataPair
            label="Location Detail"
            value={
              locationDetails.length > 0
                ? locationDetails.map((l) => capitalize(l.name)).join(', ')
                : 'None'
            }
          />
        </Box>
      </Collapse>
    </>
  )
}

export default DiagnosisDetail
