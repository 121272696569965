import { FC, ReactElement, useMemo } from 'react'
import {
  Body1,
  Body2,
  Box,
  DialogTrigger,
  findBy,
  H3,
  makeStyles,
} from '@perk-ui/core'
import { CSVLink } from 'react-csv'
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
} from 'recharts'

import { AggregateOutcomeData } from '../../../features/query-hooks/useAggregateOutcomes'
import { ScoreDefinition } from '../../../features/query-hooks/useSurveyScoreDefinitions'

const useStyles = makeStyles((theme) => ({
  title: {
    paddingBottom: theme.spacing(2),
  },
  graphContainer: {
    position: 'relative',
    marginRight: theme.spacing(4),
    backgroundColor: theme.palette.grey[100],
    borderRadius: 10,
  },
  footer: {
    textAlign: 'right',
    paddingRight: theme.spacing(2),
    paddingBottom: theme.spacing(1),
  },
  buttonContainer: {
    display: 'inline-flex',
  },
  exportButton: {
    color: theme.palette.primary.main,
    fontSize: '0.875rem',
    minHeight: '32px',
    borderRadius: '10px',
    fontWeight: '700',
    textTransform: 'none',
    textDecoration: 'none',
    display: 'inline-flex',
    alignItems: 'center',
    outline: '0',
    border: '0',
    cursor: 'pointer',
    fontFamily: theme.typography.fontFamily,
    padding: theme.spacing(1, 4),
    lineHeight: '1.5',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    verticalAlign: 'middle',
    margin: '0',
    boxSizing: 'border-box',
    justifyContent: 'center',
    position: 'relative',
    backgroundColor: 'transparent',
  },
}))

export interface OutcomeChartProps {
  title: string
  bodyPart: string
  data: AggregateOutcomeData[]
  scoreDefinition?: ScoreDefinition
  hasEnoughData?: boolean
}

const OutcomeChart: FC<OutcomeChartProps> = ({
  title,
  bodyPart,
  data,
  scoreDefinition,
  hasEnoughData,
}) => {
  const classes = useStyles()
  // The tick component needs access to the full data so it can show the N count
  const CustomTick = useMemo(() => createTickComponent(data), [data])

  const Chart = (
    <ResponsiveContainer width="100%" height={200} debounce={1}>
      <LineChart
        data={data}
        margin={{
          left: 48,
          right: 48,
          top: 16,
          bottom: 24,
        }}
      >
        <CartesianGrid horizontal={false} />
        <XAxis
          dataKey="name"
          interval="preserveEnd"
          tickLine={false}
          axisLine={false}
          tick={CustomTick as unknown as ReactElement}
        />
        <YAxis
          domain={[
            (dataMin: number) =>
              scoreDefinition?.scoreRange
                ? scoreDefinition?.scoreRange.min
                : dataMin - 10,
            (dataMax: number) =>
              scoreDefinition?.scoreRange
                ? scoreDefinition?.scoreRange.max
                : dataMax + 10,
            // (dataMin: number) => dataMin - 10,
            // (dataMax: number) => dataMax + 10,
          ]}
          width={50}
        />
        <Line
          type="monotone"
          connectNulls
          dataKey="y"
          stroke="rgb(225, 98, 39, 0.6)"
          strokeWidth={2}
          animationDuration={222}
          // The charts component has a bug where it doesn't show the tooltips
          // when the animation is enabled.
          // (This only happens when the data is cached)
          isAnimationActive={false}
          // @ts-expect-error Rechart's types are wrong here.
          label={CustomLabel}
          dot={{ fill: 'rgb(225, 98, 39)' }}
        />
      </LineChart>
    </ResponsiveContainer>
  )
  return (
    <div>
      <Box className={classes.graphContainer}>
        {!hasEnoughData && (
          <Box
            sx={{
              position: 'absolute',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
              height: '100%',
              zIndex: 200,
              background: 'rgba(250,250,250,0.6)',
              fontWeight: 'bold',
              fontSize: '18px',
            }}
          >
            Not enough data points in the first 3 months
          </Box>
        )}
        <Box sx={!hasEnoughData ? { filter: 'blur(4px)' } : null}>
          <Box sx={{ p: 3 }}>
            <H3 className={classes.title}>{title}</H3>
            {scoreDefinition?.legend && (
              <Body1
                sx={{
                  color: ({ palette }) => palette.text.secondary,
                  fontWeight: 600,
                }}
              >
                {scoreDefinition?.legend}
              </Body1>
            )}
          </Box>
          {Chart}
          <DialogTrigger
            dialogProps={{
              keepMounted: true,
              fullWidth: true,
              maxWidth: 'lg',
            }}
            actionProps={{
              className: classes.buttonContainer,
            }}
            action={<a className={classes.exportButton}>View Chart</a>}
            content={() => (
              <Box p={3} textAlign={'center'}>
                {Chart}
                <CSVLink
                  data={data}
                  filename={`${bodyPart}-${title}.csv`}
                  className={classes.exportButton}
                >
                  Export Data
                </CSVLink>
              </Box>
            )}
          />
          <CSVLink
            data={data}
            filename={`${bodyPart}-${title}.csv`}
            className={classes.exportButton}
          >
            Export Data
          </CSVLink>
          <Body2 className={classes.footer}>Change from Baseline</Body2>
        </Box>
      </Box>
    </div>
  )
}

interface CustomLabelProps {
  x: number
  y: number
  value: number
  index: number
  viewBox: {
    x: number
    y: number
  }
  // There are more props passed here if you need them. I'm unsure of the complete type
}

// TODO: Show the % change from baseline. It will require backend changes
// because the `y` value that is passed to us is not normalized and we do not know the ranges
// from which to calculate the % change for each Survey type.
const CustomLabel: FC<CustomLabelProps> = ({ x, y, value, index, viewBox }) => {
  if (index === 0 || isNaN(value)) return null

  return (
    <g>
      <rect
        x={viewBox.x - 30}
        y={viewBox.y - 34}
        rx={6}
        fill="#F9CF8E"
        width={60}
        height={24}
      />
      <text
        x={x}
        y={y}
        dy={-16}
        textAnchor="middle"
        fontSize={16}
        fontWeight="bold"
      >
        {Math.round(value)}
      </text>
    </g>
  )
}

interface CustomAxisTickProps {
  x: number
  y: number
  stroke: number
  payload: {
    value: string
  }
}

const createTickComponent = (
  data: AggregateOutcomeData[],
): FC<CustomAxisTickProps> => {
  return ({ x, y, payload }) => {
    const n = findBy('name', payload.value, data)?.n || 0
    return (
      <g transform={`translate(${x},${y})`}>
        <text
          x={0}
          y={0}
          dy={16}
          textAnchor="middle"
          fill="#666"
          fontSize="16px"
        >
          {payload.value}
        </text>
        <text
          x={0}
          y={0}
          dy={32}
          textAnchor="middle"
          fill="#888"
          fontSize="14px"
          fontWeight="bold"
        >
          N = {n}
        </text>
      </g>
    )
  }
}

export default OutcomeChart
