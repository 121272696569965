import { useEffect, useState } from 'react'

type Device = 'default' | 'desktop' | 'tablet' | 'phone'

export default function useWindowSize() {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [device, setDevice] = useState<Device>('default')
  const breakpoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  }

  useEffect(() => {
    function handleWindowResize() {
      setWindowWidth(window.innerWidth)
    }
    window.addEventListener('resize', handleWindowResize)
    return () => {
      window.removeEventListener('resize', handleWindowResize)
    }
  }, [])

  // Breakpoints
  if (windowWidth <= breakpoints.sm) {
    if (device !== 'phone') setDevice('phone')
  } else if (windowWidth > breakpoints.sm && windowWidth <= breakpoints.md) {
    if (device !== 'tablet') setDevice('tablet')
  } else {
    if (device !== 'desktop') setDevice('desktop')
  }
  return device
}
