import { Dispatch, FC, SetStateAction, useRef, useState } from 'react'
import {
  Badge,
  Collapse,
  CSSObject,
  Drawer,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  makeStyles,
  Popover,
  styled,
  Theme,
  Toolbar,
  Tooltip,
} from '@perk-ui/core'
import clsx from 'clsx'
import {
  AlertCircle,
  ChevronDown,
  ChevronsLeft,
  ChevronsRight,
  ChevronUp,
  Database,
  Eye,
  FileText,
  Home,
  // Lock,
  PlusCircle,
  Share2,
  TrendingUp,
  Users,
} from 'react-feather'
import { useLocation } from 'react-router'

import { useUser } from '../features/auth/AuthContext'
import useAdverseEvents from '../features/query-hooks/useAdverseEvents'
import PatientFlowUrls from '../pages/PatientFlow/PatientFlowUrls'
import { useHistory } from '../utils/useHistory'
import AppLinkButton from './AppLinkButton'
import { useSitesContext } from './SitesProvider'

const useStyles = makeStyles((theme) => ({
  root: {
    background: theme.palette.background.paper,
  },
  toolbarGap: theme.mixins.toolbar,
  linkContainer: {
    background: theme.palette.background.paper,
    marginTop: theme.spacing(3),
    // Somewhat arbitrary sizing, but matches design
    width: '100%',
    height: '100%',
    maxWidth: 290,
    padding: theme.spacing(0, 0, 2, 0),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  link: {
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(1.7, 1.5),
    borderRadius: 8,
    '&:hover': {
      background: 'rgba(87, 153, 173, 0.04)',
    },
    '& .ListItemText': {
      margin: 0,
    },
    '& .MuiListItemIcon-root': {
      height: 30,
    },
    '& h5': {
      fontSize: 20,
    },
    '& svg': {
      margin: 'auto',
    },
    '& span': {
      margin: 'auto',
    },
  },
  fancyButton: {
    '&:hover': {
      // background: theme.palette.primary.light,
    },
  },
  active: {
    // css specificity hack to override `a:link`
    '&&': {
      color: theme.palette.primary.main,
    },
    background: 'rgba(87, 153, 173, 0.04)',
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
  subHeader: {
    margin: theme.spacing(0, 1, 2, 1),
    padding: 0,
  },
  subMenu: {},
  subMenuLink: {
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(1, 1.5),
    '&:hover': {
      background: 'rgba(87, 153, 173, 0.04)',
    },
    '& .ListItemText': {
      margin: 0,
      position: 'relative',
      '&::before': {
        content: '""',
        display: 'block',
        position: 'absolute',
        left: 0,
        top: 0,
        borderRadius: '8px',
        width: '4px',
        height: '100%',
      },
    },
    '& svg': {
      margin: 'auto',
    },
    '& h5': {
      fontSize: 16,
      fontWeight: 500,
      paddingLeft: theme.spacing(1),
    },
  },
  insights1: {
    '& .ListItemText': {
      '&::before': {
        backgroundColor: '#2BADEE',
      },
    },
  },
  insights2: {
    '& .ListItemText': {
      '&::before': {
        backgroundColor: '#526CEB',
      },
    },
  },
  insights3: {
    '& .ListItemText': {
      '&::before': {
        backgroundColor: '#FB4242',
      },
    },
  },
  insights4: {
    '& .ListItemText': {
      '&::before': {
        backgroundColor: '#DCFD77',
      },
    },
  },
  insights5: {
    '& .ListItemText': {
      '&::before': {
        backgroundColor: '#DCFD77',
      },
    },
  },
  disabledLink: {
    color: '#919191 !important',
    cursor: 'not-allowed',
    '& .ListItemText': {
      '&::before': {
        backgroundColor: '#919191',
      },
    },
  },
  disabledTooltip: {
    '& .MuiTooltip-tooltip': {
      maxWidth: '250px',
    },
  },
  drawerHeader: {
    height: '64px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
  },
  drawerContainer: {
    // padding: theme.spacing(0,2),
    '& .MuiDrawer-paper': {
      borderRight: 'none',
      paddingLeft: theme.spacing(2),
    },
  },
  menuLinkIcon: {
    margin: 'auto',
    minWidth: 'unset',
    '& svg': {
      margin: 'auto',
    },
  },
  smallFancyButton: {
    minWidth: 'unset',
    '& .MuiButton-startIcon': {
      margin: 0,
      padding: theme.spacing(0.3),
    },
  },
  menuExpandButton: {
    margin: 'unset',
    marginLeft: 'auto',
  },
  withMargin: {
    marginLeft: theme.spacing(5),
  },
}))

export interface AppSidebarProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  drawerWidth: number
}

export const pathsWithoutSidebar = [
  '/login',
  '/create-account',
  '/login-new',
  '/forgot-password-new',
  '/forgot-password',
  '/password-reset',
  '/activate-account',
  '/resend-activation-link',
  '/onboarding',
  '/sign-contract',
  '/payment',
  '/protocols',
  PatientFlowUrls.base.match,
]

const AppSidebar: FC<AppSidebarProps> = ({ open, setOpen, drawerWidth }) => {
  const classes = useStyles()
  const currentUser = useUser()
  const { currentSite } = useSitesContext()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const isCurrentLocation = (path: string) => pathname.startsWith(path)

  const staffTableauSubscription = currentUser?.staff.subscriptionPlan

  const [openSubmenu, setOpenSubmenu] = useState(true)

  const myRef = useRef<HTMLDivElement>(null)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openInsightMenu = Boolean(anchorEl)
  const insightMenuId = openInsightMenu ? `insight-menu-popover` : undefined

  const handleInsightMenuClose = () => {
    setAnchorEl(null)
  }

  const openedMixin = (theme: Theme): CSSObject => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
  })

  const closedMixin = (theme: Theme): CSSObject => ({
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: `calc(${theme.spacing(9)} + 1px)`,
    [theme.breakpoints.up('sm')]: {
      width: `calc(${theme.spacing(10)} + 1px)`,
    },
  })

  const MuiDrawer = styled(Drawer, {
    shouldForwardProp: (prop) => prop !== 'open',
  })(({ theme, open }) => ({
    width: drawerWidth,

    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }))

  const handleDraweClick = () => {
    setOpen(!open)
  }

  const handleClick = () => {
    if (open) {
      setOpenSubmenu(!openSubmenu)
    } else {
      setAnchorEl(myRef.current)
    }
  }

  const InsightMenu = () => {
    const menuOptions = [
      {
        title: 'My IMPACT',
        key: 'my-impact',
        blockedTableauSubscriptions: [''],
        hiddenTableauSubscriptions: [''],
        icon: <TrendingUp />,
      },
      {
        title: 'My Teams',
        key: 'my-teams',
        blockedTableauSubscriptions: [''],
        hiddenTableauSubscriptions: [''],
        icon: <Share2 />,
      },
      {
        title: 'My Studies',
        key: 'my-studies',
        blockedTableauSubscriptions: [''],
        hiddenTableauSubscriptions: [''],
        icon: <FileText />,
      },
      {
        title: 'Raw Data',
        key: 'raw-data',
        blockedTableauSubscriptions: [''],
        hiddenTableauSubscriptions: [''],
        icon: <Database />,
      },
    ]
    // const showIcon =
    //   ['essentials', 'starter'].includes(staffTableauSubscription) || open
    return (
      <List component="div" disablePadding className={classes.subMenu}>
        {menuOptions.map((menuItem) => {
          const isHidden = menuItem.hiddenTableauSubscriptions.includes(
            staffTableauSubscription,
          )
          const isBlocked = menuItem.blockedTableauSubscriptions.includes(
            staffTableauSubscription,
          )

          if (isHidden) return
          return (
            // <Tooltip
            //   key={menuItem.key}
            //   arrow
            //   classes={{ popper: classes.disabledTooltip }}
            //   placement="bottom"
            //   title={
            //     isBlocked
            //       ? 'To unlock these insights, please contact your DataBiologics representative to upgrade your account'
            //       : open
            //       ? ''
            //       : menuItem.title
            //   }
            // >
            <ListItemButton
              id={'sidebar-insights-' + menuItem.key + '-link'}
              key={menuItem.key}
              onClick={() =>
                isCurrentLocation(`/${menuItem.key}`)
                  ? undefined
                  : push({
                      pathname: `/${menuItem.key}`,
                      state: { from: location.pathname },
                    })
              }
              className={clsx(
                classes.subMenuLink,
                {
                  [classes.active]: isCurrentLocation(`/${menuItem.key}`),
                },
                {
                  [classes.withMargin]: open,
                },
                {
                  [classes.insights1]: menuItem.key === 'essentials-explorer',
                },
                {
                  [classes.insights2]: menuItem.key === 'performance-explorer',
                },
                {
                  [classes.insights3]:
                    menuItem.key === 'adverse-event-overview',
                },
                {
                  [classes.insights4]: menuItem.key === 'report-builder',
                },
                {
                  [classes.insights5]: menuItem.key === 'starter-explorer',
                },
                {
                  [classes.disabledLink]: isBlocked,
                },
              )}
            >
              {/* {showIcon && ( */}
              <ListItemIcon>{menuItem.icon}</ListItemIcon>
              {/* )} */}
              <ListItemText title={menuItem.title} />
            </ListItemButton>
            // </Tooltip>
          )
        })}
      </List>
    )
  }

  const AdverseEventEl: FC = () => {
    const { data: searchResult } = useAdverseEvents({
      status: 'created',
    })

    const count = searchResult?.meta.count || 0
    return (
      <ListItemButton
        onClick={() =>
          isCurrentLocation('/adverse-events')
            ? undefined
            : push({
                pathname: `/adverse-events`,
                state: { from: location.pathname },
              })
        }
        className={clsx(classes.link, {
          [classes.active]: isCurrentLocation('/adverse-events'),
        })}
      >
        <ListItemIcon className={open ? '' : classes.menuLinkIcon}>
          <Tooltip arrow title={open ? '' : 'Adverse Events'}>
            <Badge badgeContent={count} color="error">
              <AlertCircle
                className={
                  isCurrentLocation('/adverse-events') ? classes.active : ''
                }
              />
            </Badge>
          </Tooltip>
        </ListItemIcon>
        {open && <ListItemText title={'Adverse Events'} />}
      </ListItemButton>
    )
  }

  if (pathsWithoutSidebar.some(isCurrentLocation) || !currentSite) return null
  return (
    <>
      <div ref={myRef} />
      <MuiDrawer
        variant="permanent"
        open={open}
        className={classes.drawerContainer}
        hideBackdrop
      >
        <Toolbar className={classes.drawerHeader} />
        <List
          className={classes.linkContainer}
          component="nav"
          subheader={
            <Tooltip arrow title={open ? '' : 'Add Patient'}>
              <ListSubheader component="div" className={classes.subHeader}>
                <AppLinkButton
                  id="sidebar-add-patient-button"
                  className={clsx(classes.fancyButton, {
                    [classes.smallFancyButton]: !open,
                  })}
                  startIcon={<PlusCircle />}
                  fullWidth
                  to={PatientFlowUrls.invitePatient.create()}
                  size="medium"
                >
                  {open ? 'Add Patient' : ''}
                </AppLinkButton>
              </ListSubheader>
            </Tooltip>
          }
        >
          <ListItemButton
            id="sidebar-home-link"
            onClick={() =>
              isCurrentLocation('/home')
                ? undefined
                : push({
                    pathname: `/home`,
                    state: { from: location.pathname },
                  })
            }
            className={clsx(classes.link, {
              [classes.active]: isCurrentLocation('/home'),
            })}
          >
            <ListItemIcon className={open ? '' : classes.menuLinkIcon}>
              <Tooltip arrow title={open ? '' : 'Home'}>
                <Home
                  className={isCurrentLocation('/home') ? classes.active : ''}
                />
              </Tooltip>
            </ListItemIcon>
            {open && <ListItemText title={'Home'} />}
          </ListItemButton>
          <ListItemButton
            id="sidebar-patients-link"
            onClick={() =>
              isCurrentLocation('/patients')
                ? undefined
                : push({
                    pathname: `/patients`,
                    state: { from: location.pathname },
                  })
            }
            className={clsx(
              classes.link,
              isCurrentLocation('/patients') && classes.active,
            )}
          >
            <ListItemIcon className={open ? '' : classes.menuLinkIcon}>
              <Tooltip arrow title={open ? '' : 'Patients'}>
                <Users
                  className={
                    isCurrentLocation('/patients') ? classes.active : ''
                  }
                />
              </Tooltip>
            </ListItemIcon>
            {open && <ListItemText title={'Patients'} />}
          </ListItemButton>
          <AdverseEventEl />
          <ListItemButton
            id="sidebar-insights-dropdown"
            onClick={handleClick}
            className={clsx(classes.link, {
              [classes.active]:
                isCurrentLocation('/essentials-explorer') ||
                isCurrentLocation('/performance-explorer') ||
                isCurrentLocation('/adverse-event-overview') ||
                isCurrentLocation('/report-builder') ||
                isCurrentLocation('/starter-explorer'),
            })}
          >
            <ListItemIcon className={open ? '' : classes.menuLinkIcon}>
              <Tooltip arrow title={open ? '' : 'Insights'}>
                <Eye
                  className={
                    isCurrentLocation('/essentials-explorer') ||
                    isCurrentLocation('/performance-explorer') ||
                    isCurrentLocation('/adverse-event-overview') ||
                    isCurrentLocation('/report-builder') ||
                    isCurrentLocation('/starter-explorer')
                      ? classes.active
                      : ''
                  }
                />
              </Tooltip>
            </ListItemIcon>
            {open && <ListItemText title={'Insights'} />}
            {open ? openSubmenu ? <ChevronUp /> : <ChevronDown /> : ''}
          </ListItemButton>
          {open && (
            <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
              <InsightMenu />
            </Collapse>
          )}
        </List>
        <List>
          <Tooltip arrow title={open ? 'Collapse Menu' : 'Expand Menu'}>
            <ListItemButton
              onClick={handleDraweClick}
              className={clsx(classes.link)}
            >
              <ListItemIcon
                className={clsx(classes.menuLinkIcon, {
                  [classes.menuExpandButton]: open,
                })}
              >
                {open ? <ChevronsLeft /> : <ChevronsRight />}
              </ListItemIcon>
            </ListItemButton>
          </Tooltip>
        </List>
      </MuiDrawer>
      <Popover
        id={insightMenuId}
        anchorEl={anchorEl}
        open={openInsightMenu}
        onClose={handleInsightMenuClose}
        PaperProps={{
          sx: {
            mt: 42,
            ml: 7,
          },
        }}
      >
        <InsightMenu />
      </Popover>
    </>
  )
}

export default AppSidebar
