import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { PatientCase } from './usePatientCases'

interface ArchiveCaseResponse {
  patientCase: PatientCase
}

interface ArchiveCaseVars {
  patientCaseId: string
  archiveReasonId: string
  patientId: string
}

const useArchivePatientCase = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation((vars: ArchiveCaseVars) => {
    const url = `/sites/patient_cases/${vars.patientCaseId}/archive`

    return request
      .post<ArchiveCaseResponse>(url, {
        siteIds: [siteId],
        patientCase: {
          archivalReasonId: vars.archiveReasonId,
        },
      })
      .then((response) => {
        queryClient.invalidateQueries(
          QueryKeys.patientCases({ patientId: vars.patientId }),
        )
        queryClient.invalidateQueries(
          QueryKeys.bodyPartsSurveyCompletionStatus({
            currentSiteId: siteId,
            patientId: vars.patientId,
          }),
        )
        return response.data.patientCase
      })
  })
}

export default useArchivePatientCase
