import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Pagination } from './types'
import { Patient } from './usePatient'

export interface PatientListSearchResponse {
  patients: Patient[]
  meta: Pagination
}

interface PatientSearchParams {
  search: string
  page?: number
  perPage?: number
}

const usePatientSearch = (
  { search, page = 1, perPage = 20 }: PatientSearchParams,
  extraOptions: UseQueryOptions<PatientListSearchResponse> = {},
) => {
  const currentSiteId = useCurrentSite().id

  const key = QueryKeys.patientsSearch({ currentSiteId, search, page, perPage })
  const url = '/sites/patients/search'

  return useQuery(
    key,
    () =>
      request
        .post<PatientListSearchResponse>(url, {
          siteIds: [currentSiteId],
          search,
          page,
          perPage,
        })
        .then((r) => r.data),
    {
      keepPreviousData: true,
      ...extraOptions,
    },
  )
}

export default usePatientSearch
