import { FC, useState } from 'react'
import {
  Avatar,
  Box,
  Card,
  CardContent,
  CardHeader,
  makeStyles,
  MobileStepper,
} from '@perk-ui/core'
import SwipeableViews from 'react-swipeable-views'
import { autoPlay } from 'react-swipeable-views-utils'

import OnboardingBannerImage from '../../../assets/OnboardingBanner.svg'
import ArianaDeMers from '../../../assets/testimonials/ArianaDeMers.png'
import JhonKnab from '../../../assets/testimonials/JhonKnab.png'
import JhonRSantaAna from '../../../assets/testimonials/JhonRSantaAna.png'
import ThomasNabity from '../../../assets/testimonials/ThomasNabity.png'

const AutoPlaySwipeableViews = autoPlay(SwipeableViews)

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    position: 'relative',
    width: '100%',
    height: '100%',
  },
  container: {
    backgroundImage: `url(${OnboardingBannerImage})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    height: '100%',
    borderRadius: '40px',
  },
  carrouselContainer: {
    maxWidth: '85%',
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing(0, 2, 5, 2),
  },
  carrouselInnerContainer: {
    height: '100%',
    '& .react-swipeable-view-container': {
      height: '100%',
      '&>div': {
        height: '100%',
        '&>div': {
          height: '100%',
        },
      },
    },
  },
  carrouselStepper: {
    padding: theme.spacing(0, 2),
    background: 'none',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    height: '100%',
  },
  testimonialContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    background: 'none',
    boxShadow: 'none',
  },
  testimonialHeader: {
    fontSize: '16px',
    '& .MuiCardHeader-title': {
      fontWeight: 700,
    },
    '& .MuiCardHeader-subheader': {},
  },
  testimonialContent: {
    fontSize: '20px',
    fontWeight: 700,
  },
}))

interface OnboardingBannerProps {}

const testimonials = [
  {
    title: 'Thomas Nabity, MD',
    image: ThomasNabity,
    content:
      'Partnering with DataBiologics is one of the ways our clinic is doing our part to leverage the most forward thinking treatment options possible.',
    clinic: 'Michigan Center for Regenerative Medicine Rochester, MI',
  },
  {
    title: 'John Knab, MD',
    image: JhonKnab,
    content:
      "The responsiveness of the team at DataBiologics is unparalleled. To me this is like having a personalized research team at your fingertips! I am happy to have finally found my 'forever registry' in DataBiologics.",
    clinic: 'Center for Pain Management Wilmington, NC',
  },
  {
    title: 'John R. Santa Ana, DO, FAAPMR',
    image: JhonRSantaAna,
    content:
      "My credibility is on the line with every consult and with every procedure. Having data to back up patient discussions and consultations helps me present their options better and in-turn earn patients' trust. Data separates Regenerative Medicine Physicians from hobbyists.",
    clinic: 'Michigan Center for Regenerative Medicine, Rochester, MI',
  },
  {
    title: 'Ariana DeMers DO, RMSK',
    image: ArianaDeMers,
    content:
      "I have been using DataBiologics for the last two years... I appreciate the wide access to outcomes across the nation for benchmarking, and to be able to convey expectations for outcomes for my patients. I think it's important to track your own data to make sure your patient outcomes are optimized.",
    clinic:
      'Joint Vitality Institute at Restore Orthopedics and Sports Medicine Sonora, CA',
  },
]
const OnboardingBanner: FC<OnboardingBannerProps> = () => {
  const classes = useStyles()
  const [activeStep, setActiveStep] = useState(0)
  const maxSteps = testimonials.length

  const handleStepChange = (step: number) => {
    setActiveStep(step)
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <Box className={classes.content}>
          <Box className={classes.carrouselContainer}>
            <AutoPlaySwipeableViews
              axis={'x'}
              index={activeStep}
              onChangeIndex={handleStepChange}
              enableMouseEvents
              interval={10000}
              className={classes.carrouselInnerContainer}
            >
              {testimonials.map((step, index) => (
                <div key={index}>
                  {Math.abs(activeStep - index) <= 2 ? (
                    <Card className={classes.testimonialContainer}>
                      <CardContent className={classes.testimonialContent}>
                        {`"${step.content}"`}
                      </CardContent>
                      <CardHeader
                        className={classes.testimonialHeader}
                        avatar={<Avatar src={step.image} alt={step.title} />}
                        title={step.title}
                        subheader={step.clinic}
                      />
                    </Card>
                  ) : null}
                </div>
              ))}
            </AutoPlaySwipeableViews>
            <MobileStepper
              className={classes.carrouselStepper}
              steps={maxSteps}
              position="static"
              activeStep={activeStep}
              nextButton={null}
              backButton={null}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

export default OnboardingBanner
