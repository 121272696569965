import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Avatar, Box, makeStyles, parseYMD } from '@perk-ui/core'
import clsx from 'clsx'
import { differenceInYears } from 'date-fns'
import jsPdf from 'jspdf'
import ReactDOMServer, { renderToStaticMarkup } from 'react-dom/server'
import { useParams } from 'react-router'

import { formatPhoneNumber } from '../../components/PatientInfo'
import { useSitesContext } from '../../components/SitesProvider'
import useBodyPartsSurveyCompletionStatus from '../../features/query-hooks/useBodyPartsSurveyCompletionStatus'
import usePatient from '../../features/query-hooks/usePatient'
import usePatientCases from '../../features/query-hooks/usePatientCases'
import useSurveyResponseSearch from '../../features/query-hooks/useSurveyResponseSearch'
import useDates from '../../hooks/useDates'
import {
  feetToInches,
  inchesToMeters,
  lbsToKgs,
} from '../../utils/unitConversion'
import SurveyDetailsTable from './components/SurveyDetailsTable'

export interface PatientReportPdfProps {}

const pageMaxHeight = 1122
const pageMaxWidth = 800
const headerHeight = 90
const footerHeight = 110
const pagePadding = 2
const patientBasicInfoHeight = 120

const useStyles = makeStyles((theme) => ({
  pdfPage: {
    fontFamily: 'Helvetica',
    // letterSpacing: '0.01rem!important',
    pageBreakInside: 'avoid',
    height: pageMaxHeight,
    width: pageMaxWidth,
    padding: theme.spacing(pagePadding),
    // border: '1px solid blue',
  },
  pageHeader: {
    height: headerHeight,
    fontSize: '32px',
    fontWeight: 700,
    display: 'flex',
    alignItems: 'center',
  },
  pageFooter: {
    marginTop: '10px',
    // height: footerHeight,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  footerLogo: {},
  footerDate: {},
  patientAvatar: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '16px',
    fontWeight: 700,
    marginBottom: theme.spacing(1),
    textTransform: 'capitalize',
    '& .MuiAvatar-root': {
      backgroundColor: '#F1B79C',
      height: '25px',
      width: '25px',
      marginRight: theme.spacing(1),
      color: theme.palette.common.black,
      fontSize: '12px',
      textTransform: 'uppercase',
    },
  },
  patientMetricContainer: {},
  patientGeneralDataSection: {
    height: patientBasicInfoHeight,
  },
  dataPair: {
    display: 'inline-block',
    marginRight: theme.spacing(1),
    paddingRight: theme.spacing(1),
    '& b.label': {
      fontSize: '10px',
      fontWeight: 500,
      textTransform: 'uppercase',
      color: '#919191',
      marginBottom: theme.spacing(1),
    },
    '& span': {
      display: 'flex',
      alignItems: 'center',
      fontSize: '12px',
      '& b': {
        color: '#919191',
        marginLeft: theme.spacing(1),
        fontWeight: 400,
      },
    },
  },
  capitalizeText: {
    textTransform: 'capitalize',
  },
  rightBorder: {
    borderRight: '1px solid #F1F1F1',
  },
}))

const PatientReportPdf = forwardRef((_, ref) => {
  const { localizedFormatDate } = useDates()
  const classes = useStyles()
  const { currentSite } = useSitesContext()
  const { patientId } = useParams<{ patientId: string }>()
  const { data: patient } = usePatient(patientId)
  const { data: patientCases = [] } = usePatientCases(patientId)
  const { data: allSurveys } = useSurveyResponseSearch({
    ownerIds: patientCases.map((pc) => pc.id),
    ownerType: 'PatientCase',
    includes: ['survey_response_scores'],
    perPage: '300',
  })
  const { data: surveyCompletionList } =
    useBodyPartsSurveyCompletionStatus(patientId)
  useEffect(() => {
    if (allSurveys) {
      createPdf()
    }
  }, [allSurveys])

  const isCanada = currentSite?.country === 'canada'

  const nameInitials =
    (patient?.firstName || '').split('')[0] +
    (patient?.lastName || '').split('')[0]

  const [pdfPages, setPdfPages] = useState<JSX.Element[]>([])
  const pages: JSX.Element[] = []
  let currentPage: JSX.Element[] = []
  let currentHeight = 0

  const getElementHeight = (element: JSX.Element) => {
    const placeholder = document.createElement('div')
    placeholder.innerHTML = renderToStaticMarkup(element)
    document.body.appendChild(placeholder)
    const childHeight = placeholder.offsetHeight
    document.body.removeChild(placeholder)

    return childHeight
  }

  const basePage = (children: JSX.Element[]) => (
    <>
      <Box className={classes.pdfPage} style={{ position: 'relative' }}>
        {children}
        <Box
          className={classes.pageFooter}
          style={{ position: 'absolute', bottom: '10px', width: '95%' }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/assets/DataBiologics_Logo.png`}
            height="30"
          />
          <span>
            <b>DATE:</b> {localizedFormatDate(new Date())}
          </span>
        </Box>
      </Box>
    </>
  )

  const addElementToPage = (element: JSX.Element) => {
    if (
      getElementHeight(element) + currentHeight <
      pageMaxHeight - footerHeight
    ) {
      currentPage.push(element)
      currentHeight += getElementHeight(element)
    } else {
      pages.push(basePage(currentPage))
      currentPage = []
      currentHeight = 0
      addElementToPage(element)
    }
  }

  const createPdf = () => {
    // First page: inclyude patient general data
    const patientGeneralData = (
      <Box key={1}>
        <Box className={classes.pageHeader}>{currentSite?.name}</Box>
        <Box className={classes.patientGeneralDataSection}>
          <Box className={classes.patientAvatar}>
            <Avatar>{nameInitials}</Avatar>{' '}
            {`${patient?.firstName} ${patient?.lastName}`}
          </Box>
          <Box className={classes.patientMetricContainer}>
            <Box className={classes.dataPair}>
              <b className={'label'}>phone</b>
              <span className={classes.capitalizeText}>
                {`${
                  formatPhoneNumber(patient?.cellPhoneLocal || '') || 'None'
                } `}
                {patient?.phoneData?.phoneType ? (
                  <b>({patient?.phoneData.phoneType})</b>
                ) : (
                  ''
                )}
              </span>
            </Box>
            <Box className={clsx(classes.dataPair, classes.rightBorder)}>
              <b className={'label'}>email</b>
              <span>{patient?.email || '-'}</span>
            </Box>
            <Box className={classes.dataPair}>
              <b className={'label'}>race/ethnicity</b>
              <span className={classes.capitalizeText}>
                {patient?.ethnicity?.toString().replaceAll(',', ', ') || '-'}
              </span>
            </Box>
            <Box className={classes.dataPair}>
              <b className={'label'}>zip code</b>
              <span>{patient?.zipCode || '-'}</span>
            </Box>
            <Box className={classes.dataPair}>
              <b className={'label'}>Date of Birth</b>
              <span>
                {patient?.dateOfBirth
                  ? `${localizedFormatDate(
                      parseYMD(patient?.dateOfBirth || ''),
                    )} (${differenceInYears(
                      new Date(),
                      parseYMD(patient.dateOfBirth),
                    )})`
                  : '-'}
              </span>
            </Box>
            <Box className={classes.dataPair}>
              <b className={'label'}>gender</b>
              <span className={classes.capitalizeText}>
                {patient?.biologicalSex || '-'}
              </span>
            </Box>
            <Box className={classes.dataPair}>
              <b className={'label'}>height</b>
              <span>
                {patient
                  ? isCanada
                    ? `${inchesToMeters(
                        feetToInches(patient.heightFeet) +
                          (patient.heightInches || 0),
                      ).toFixed(2)}m`
                    : `${patient.heightFeet}'${Number(
                        patient?.heightInches,
                      ).toFixed(0)}"`
                  : '-'}
              </span>
            </Box>
            <Box className={classes.dataPair}>
              <b className={'label'}>weight</b>
              <span>
                {patient
                  ? isCanada
                    ? `${lbsToKgs(patient.weight || 0).toFixed(1)}kg`
                    : `${patient.weight}lbs`
                  : '-'}
              </span>
            </Box>
            <Box className={classes.dataPair}>
              <b className={'label'}>bmi</b>
              <span>{patient?.bmiScore || '-'}</span>
            </Box>
            <Box className={classes.dataPair}>
              <b className={'label'}>smoking status</b>
              <span className={classes.capitalizeText}>
                {(patient?.smoker || '').replaceAll('_', ' ') || '-'}
              </span>
            </Box>
          </Box>
        </Box>
      </Box>
    )

    addElementToPage(patientGeneralData)

    // Add patient case data
    ;(surveyCompletionList || []).map((surveyCompletion) => {
      const currentCase = patientCases.find(
        (pc) => pc.id === surveyCompletion.patientCase,
      )
      const currentSurveys = allSurveys?.surveyResponses.filter(
        (survey) => survey.ownerId === currentCase?.id,
      )
      if (!currentCase) return

      SurveyDetailsTable({
        patientCase: currentCase,
        surveyStatus: surveyCompletion,
        currentSurveys: currentSurveys,
        addElementToPdf: addElementToPage,
        localizeDate: localizedFormatDate,
      })
    })

    if (currentPage.length != 0) {
      pages.push(basePage(currentPage))
    }

    setPdfPages(pages)
  }
  useImperativeHandle(ref, () => ({
    async save() {
      const doc = new jsPdf({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        hotfixes: ['px_scaling'],
      })

      doc.setFont('Helvetica', 'normal')

      pdfPages.map((page, index) => {
        if (index > 0) {
          doc.addPage()
        }
      })

      return doc.html(ReactDOMServer.renderToStaticMarkup(<>{pdfPages}</>), {
        html2canvas: {
          letterRendering: true,
        },
        callback: () => {
          doc.save(`${patient?.firstName} ${patient?.lastName}.pdf`)
        },
      })
    },
  }))

  return <div>{/* {pdfPages} */}</div>
})

export default PatientReportPdf
