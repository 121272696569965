import { FC, MouseEvent, useEffect, useState } from 'react'
import {
  Box,
  Collapse,
  findBy,
  H5,
  Icon,
  ListItem,
  ListItemProps,
  makeStyles,
  styled,
} from '@perk-ui/core'
import clsx from 'clsx'
import { CheckCircle, ChevronDown, Circle } from 'react-feather'
import { useLocation, useRouteMatch } from 'react-router'

import useBodySites from '../../../features/query-hooks/useBodySites'
import useMedicalTreatments from '../../../features/query-hooks/useMedicalTreatments'
import { PatientCase } from '../../../features/query-hooks/usePatientCases'
import { PatientTreatment } from '../../../features/query-hooks/usePatientTreatment'
import useSurveyResponseSearch from '../../../features/query-hooks/useSurveyResponseSearch'
import useDates from '../../../hooks/useDates'
import { parseYMD } from '../../../utils/dates'
import { useHistory } from '../../../utils/useHistory'
import PatientFlowUrls from '../PatientFlowUrls'

type IconWrapperProps = {
  isCollapsed?: boolean
}

const IconWrapper = styled(Box)<IconWrapperProps>`
  transition: all 0.3s ease-in-out;
  transform: rotate(${({ isCollapsed }) => (isCollapsed ? 0 : 180)}deg);
`

const useStyles = makeStyles((theme) => ({
  case: {
    marginTop: theme.spacing(1),
  },
  icon: {
    marginRight: theme.spacing(1),
  },
  whiteHalfCircle: {
    transform: 'rotate(-92deg)', // 92 (instead of 90) because the base circle is not painted perfectly
    strokeLinecap: 'square',
    strokeDasharray: 63, // total length of circle path: $circleElement.getTotalLength()
    strokeDashoffset: 93.5, // 1.5 times the circle's length
    color: 'white',
  },
  greenHalfCircle: {
    transform: 'rotate(-90deg)',
    position: 'absolute',
    strokeLinecap: 'square',
    strokeDasharray: 63, // total length of circle path: $circleElement.getTotalLength()
    strokeDashoffset: 31.5, // Half of the circle's length
  },
  addTreatmentButton: {
    padding: 0,
    marginTop: theme.spacing(1),
    justifyContent: 'center',
  },
  treatmentName: {
    display: 'flex',
    justifyContent: 'space-between',
    width: 'calc(100% - 32px)',
    alignItems: 'center',
  },
  sessionName: {
    paddingLeft: '30px',
  },
}))

export interface SidebarCaseItemProps {
  patientCase: PatientCase
  id: string
}

const SidebarCaseItem: FC<SidebarCaseItemProps> = ({ patientCase, id }) => {
  const classes = useStyles()
  const { push } = useHistory()
  const location = useLocation<{ from?: string }>()
  const { data: medicalTreatments = [] } = useMedicalTreatments()
  const treatments = patientCase.patientTreatments || []
  const [isSessionCollapsed, setIsSessionCollapsed] = useState(
    treatments.map(() => false),
  )
  const treatmentIds = treatments.map((pt) => pt.id)
  const { data: bodySites = [] } = useBodySites()
  const { data: treatmentDetails } = useSurveyResponseSearch({
    ownerIds: treatmentIds,
    ownerType: 'PatientTreatment',
  })
  const { localizedFormatDate } = useDates()

  const bodySiteName = findBy('id', patientCase.bodySiteId, bodySites)?.name
  const hasDiagnosis = (patientCase.patientDiagnoses || [])?.length > 0
  const treatmentItems = treatments.concat({
    id: 'placeholder',
  } as PatientTreatment)

  const changeSessionCollapseStatus = (index: number) => {
    setIsSessionCollapsed((prevState) =>
      prevState.map((item, idx) => (idx === index ? !item : item)),
    )
  }
  useEffect(() => {
    // Update the document title using the browser API
    setIsSessionCollapsed(treatments.map(() => false))
  }, [treatments])

  return (
    <Box key={patientCase.id} id={id}>
      <ListItem className={classes.case}>
        <H5>{bodySiteName}</H5>
      </ListItem>
      <ActiveLinkListItem
        id={id + '-diagnosis-link'}
        button
        dense
        match={{
          url: PatientFlowUrls.diagnosis.match,
          params: {
            caseId: patientCase.id,
          },
        }}
        onClick={() =>
          push({
            pathname: PatientFlowUrls.diagnosis.create(
              patientCase.patientId,
              patientCase.id,
            ),
            state: { from: location.state?.from ? location.state?.from : null },
          })
        }
      >
        <Icon color={hasDiagnosis ? 'success' : 'inherit'}>
          {hasDiagnosis ? (
            <CheckCircle className={classes.icon} />
          ) : (
            <Circle className={classes.icon} />
          )}
        </Icon>{' '}
        Diagnosis
      </ActiveLinkListItem>
      {treatmentItems.map((treatment, index) => {
        if (treatment.parentId) return
        const hasTreatment = treatment.id !== 'placeholder' // treatments.length > 0

        const hasTreatmentDetails = (
          treatmentDetails?.surveyResponses || []
        ).some((sr) => treatment.id === sr.ownerId)
        const medicalTreatment = findBy(
          'id',
          treatment.medicalTreatmentId,
          medicalTreatments,
        )

        const destination = hasTreatment
          ? PatientFlowUrls.treatment.create(
              patientCase.patientId,
              patientCase.id,
              treatment.id,
            )
          : PatientFlowUrls.addTreatment.create(
              patientCase.patientId,
              patientCase.id,
            )
        const treatmentSessions = treatmentItems.filter(
          (pt) => pt.parentId == treatment.id,
        )
        let hasSessionsDetails = true
        treatmentSessions.map((session) => {
          const hasSessionDetails = (
            treatmentDetails?.surveyResponses || []
          ).some((sr) => session.id === sr.ownerId)
          if (!hasSessionDetails) {
            hasSessionsDetails = false
          }
        })
        const treatmentName = medicalTreatment
          ? treatmentSessions && treatmentSessions.length > 0
            ? `${medicalTreatment.name} (${treatmentSessions.length + 1})`
            : medicalTreatment.name
          : 'New Treatment'
        const allTreatmentHasDetails = hasTreatmentDetails && hasSessionsDetails

        return (
          <ActiveLinkListItem
            id={id + '-treatment-link' + index}
            key={treatment.id}
            button
            dense
            disabled={!hasDiagnosis}
            match={
              treatmentSessions.length > 0
                ? {
                    url: '',
                    params: {},
                  }
                : {
                    url: hasTreatment
                      ? PatientFlowUrls.treatment.match
                      : PatientFlowUrls.addTreatment.match,
                    params: {
                      caseId: patientCase.id,
                      ...(hasTreatment ? { treatmentId: treatment.id } : {}),
                    },
                  }
            }
            onClick={() =>
              treatmentSessions.length > 0
                ? changeSessionCollapseStatus(index)
                : push({
                    pathname: destination,
                    state: {
                      from: location.state?.from ? location.state?.from : null,
                    },
                  })
            }
            sessions={
              treatmentSessions.length > 0 ? (
                <Collapse in={isSessionCollapsed[index]}>
                  <ActiveLinkListItem
                    key={treatment.id}
                    button
                    pl={4}
                    dense
                    disabled={!hasDiagnosis}
                    match={{
                      url: hasTreatment
                        ? PatientFlowUrls.treatment.match
                        : PatientFlowUrls.addTreatment.match,
                      params: {
                        caseId: patientCase.id,
                        ...(hasTreatment ? { treatmentId: treatment.id } : {}),
                      },
                    }}
                    onClick={() =>
                      push({
                        pathname: destination,
                        state: {
                          from: location.state?.from
                            ? location.state?.from
                            : null,
                        },
                      })
                    }
                  >
                    <span className={classes.sessionName}>
                      Session (
                      {treatment.treatmentDate
                        ? localizedFormatDate(parseYMD(treatment.treatmentDate))
                        : ''}
                      )
                    </span>
                  </ActiveLinkListItem>
                  {treatmentSessions &&
                    treatmentSessions.map((session) => {
                      return (
                        <ActiveLinkListItem
                          key={session.id}
                          button
                          dense
                          match={{
                            url:
                              treatmentSessions.length > 0
                                ? PatientFlowUrls.session.match
                                : PatientFlowUrls.addSession.match,
                            params: {
                              caseId: patientCase.id,
                              treatmentId: treatment.id,
                              ...(treatmentSessions.length
                                ? { sessionId: session.id }
                                : {}),
                            },
                          }}
                          onClick={() =>
                            push({
                              pathname: PatientFlowUrls.session.create(
                                patientCase.patientId || '',
                                treatment.patientCaseId || '',
                                treatment.id || '',
                                session.id || '',
                              ),
                              state: {
                                from: location.state?.from
                                  ? location.state?.from
                                  : null,
                              },
                            })
                          }
                        >
                          <span className={classes.sessionName}>
                            Session (
                            {localizedFormatDate(
                              parseYMD(session.treatmentDate),
                            )}
                            )
                          </span>
                        </ActiveLinkListItem>
                      )
                    })}
                </Collapse>
              ) : null
            }
          >
            {allTreatmentHasDetails ? (
              <Icon color="success">
                <CheckCircle className={classes.icon} />
              </Icon>
            ) : (
              <>
                <Icon>
                  <Circle
                    className={clsx(
                      classes.icon,
                      hasTreatment && classes.whiteHalfCircle,
                    )}
                  />
                </Icon>
                {hasTreatment && (
                  <Icon color="success">
                    <Circle className={classes.greenHalfCircle} />
                  </Icon>
                )}
              </>
            )}{' '}
            <div className={classes.treatmentName}>
              {treatmentName}
              {hasTreatment && treatmentSessions.length > 0 && (
                <IconWrapper isCollapsed={!isSessionCollapsed[index]}>
                  <Icon>
                    <ChevronDown />
                  </Icon>
                </IconWrapper>
              )}
            </div>
          </ActiveLinkListItem>
        )
      })}
    </Box>
  )
}

interface ActiveLinkListItemProps extends ListItemProps {
  match: {
    url: string
    params: Record<string, string>
  }
}

// This component is intended to be private in this file.
// The ListItem types are kind of janky and I don't want to
// encourage using this elsewhere.
const ActiveLinkListItem: FC<ActiveLinkListItemProps> = ({
  match: matchProp,
  sessions,
  ...rest
}) => {
  // eslint-disable-next-line
  const match = useRouteMatch<any>(matchProp.url)

  const matchesAllParams = Object.keys(matchProp.params).every(
    (key) => match?.params[key] === matchProp.params[key],
  )

  const selected = match?.isExact && matchesAllParams

  return (
    <>
      <ListItem
        {...rest}
        selected={selected}
        onClick={(event: MouseEvent) => {
          !selected && rest.onClick(event)
        }}
      />
      {sessions}
    </>
  )
}

export default SidebarCaseItem
