import { Dispatch, FC, SetStateAction } from 'react'
import {
  Box,
  CardHeader,
  Chip,
  DialogTrigger,
  H3,
  H5,
  IconButton,
  makeStyles,
  // PasswordField,
} from '@perk-ui/core'
import clsx from 'clsx'
import { Edit, FileText, Plus, Trash2 } from 'react-feather'
import { useHistory } from 'react-router'

import useProtocols from '../features/query-hooks/useProtocols'
import ArchiveModal from '../pages/ProtocolFlow/components/ArchiveModal'
import ProtocolFlowUrls from '../pages/ProtocolFlow/ProtocolFlowUrls'
import AppLinkButton from './AppLinkButton'

interface UserProtocolsSettingsProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflow: 'hidden',
  },
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  emptyStateIcon: {
    display: 'flex',
    backgroundColor: '#F1F1F1',
    borderRadius: '50%',
    marginBottom: theme.spacing(2),
    '& svg': {
      color: '#6B6B6B',
    },
  },
  emptyStateTitle: {
    color: '#6B6B6B',
    fontWeight: 700,
    fontSize: '16px',
    marginBottom: theme.spacing(1),
  },
  emptyStateText: {
    color: '#6B6B6B',
    fontWeight: 400,
    fontSize: '12px',
    marginBottom: theme.spacing(2),
  },
  createProtocolButton: {
    fontSize: '14px',
    fontWeight: 400,
    minHeight: 40,
    padding: theme.spacing(1, 3),
  },
  protocolCard: {
    backgroundColor: '#FAFAFB',
    borderRadius: '12px',
    marginBottom: theme.spacing(2),
    '& .MuiCardHeader-action': {
      alignSelf: 'auto',
      paddingLeft: theme.spacing(2),
    },
  },
  protocolCardTitle: {
    fontSize: '16px',
    fontWeight: 700,
  },
  protocolCardChip: {
    color: '#2396CF',
    fontSize: '12px',
    fontWeight: 400,
    backgroundColor: '#269DD924',
    opacity: 0.86,
    borderRadius: '16px',
    marginLeft: theme.spacing(1),
    padding: theme.spacing(0.5, 1),
    '& span': {
      padding: 0,
    },
  },
  protocolCardSubheader: {
    fontSize: '14px',
    '& span': {
      marginRight: theme.spacing(1),
    },
  },
  protocolsContainer: {
    height: 'calc(100% - 50px)',
    overflow: 'scroll',
    marginBottom: theme.spacing(1),
  },
  actionContainer: {
    display: 'flex',
  },
  dialogContent: {
    '& > p': {
      textAlign: 'start',
    },
  },
}))

const UserProtocolsSettings: FC<UserProtocolsSettingsProps> = ({
  open,
  setOpen,
  setAnchorEl,
}) => {
  const classes = useStyles()
  const { data: protocols } = useProtocols()
  const { push } = useHistory()
  // const protocols: Array<Protocol> = []

  // Empty State
  if (!protocols || protocols.length === 0) {
    return (
      <Box p={3} height={'100%'}>
        <Box className={classes.emptyContainer}>
          <Box className={classes.emptyStateIcon} p={2}>
            <FileText />
          </Box>
          <H3 className={classes.emptyStateTitle}>No Protocols</H3>
          <H5 className={classes.emptyStateText}>
            Create protocols to facilitate adding new patients
          </H5>
          <AppLinkButton
            className={classes.createProtocolButton}
            to={'/protocols'}
            onClick={() => {
              setOpen(!open)
              setAnchorEl(null)
            }}
            size="medium"
            startIcon={<Plus />}
          >
            Create Protocol
          </AppLinkButton>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Box className={classes.protocolsContainer}>
        {protocols?.map((protocol) => {
          return (
            <CardHeader
              key={protocol.id}
              className={classes.protocolCard}
              action={
                <Box className={classes.actionContainer}>
                  <IconButton
                    disabled={protocol.organization ? true : false}
                    onClick={() => {
                      push(ProtocolFlowUrls.editProtocol.create(protocol.id))
                      setOpen(!open)
                      setAnchorEl(null)
                    }}
                  >
                    <Edit />
                  </IconButton>
                  <DialogTrigger
                    action={
                      <IconButton
                        disabled={protocol.organization ? true : false}
                      >
                        <Trash2 />
                      </IconButton>
                    }
                    content={(toggle) => (
                      <Box p={3} className={classes.dialogContent}>
                        <ArchiveModal
                          protocolId={protocol.id}
                          toggle={toggle}
                        />
                      </Box>
                    )}
                  />
                </Box>
              }
              title={
                <Box className={classes.protocolCardTitle}>
                  {protocol.name}
                  <Chip
                    className={clsx(classes.protocolCardChip, {
                      // [classes.active]: isCurrentLocation('/adverse-events'),
                    })}
                    label={
                      protocol.type === 'FullProtocol'
                        ? 'Full Protocol'
                        : 'Treatment Protocol'
                    }
                    size="small"
                  />
                </Box>
              }
              subheader={
                <Box className={classes.protocolCardSubheader}>
                  {protocol.bodySite ? (
                    <span>
                      Body Site: <b>{protocol.bodySite.name}</b>
                    </span>
                  ) : null}
                  {protocol.protocolTreatments &&
                  protocol.protocolTreatments.length > 0
                    ? protocol.protocolTreatments.map((treatment) => {
                        return (
                          <span key={treatment.id}>
                            {treatment.order == 1
                              ? 'Primary Treatment'
                              : 'Additional Treatment'}
                            :{' '}
                            <b>
                              {treatment.medicalTreatment?.name}
                              {treatment.order !== 1
                                ? ` (+${treatment.periodAmount} ${treatment.periodType})`
                                : ''}
                            </b>
                          </span>
                        )
                      })
                    : null}
                </Box>
              }
            />
          )
        })}
      </Box>
      <AppLinkButton
        className={classes.createProtocolButton}
        to={'/protocols'}
        onClick={() => {
          setOpen(!open)
          setAnchorEl(null)
        }}
        size="medium"
        startIcon={<Plus />}
      >
        Create Protocol
      </AppLinkButton>
    </Box>
  )
}

export default UserProtocolsSettings
