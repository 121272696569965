import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Patient } from './usePatient'

interface PatientUpdateResponse {
  patient: Patient
}

interface PatientUpdateVars extends Partial<Omit<Patient, 'sites'>> {}

const useUpdatePatientPhoneData = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation(({ id }: PatientUpdateVars) => {
    const url = `/sites/patients/${id}/update_phone_data`
    return request
      .post<PatientUpdateResponse>(url, {
        siteIds: [siteId],
      })
      .then((r) => {
        queryClient.invalidateQueries(
          QueryKeys.patient({
            currentSiteId: siteId,
            patientId: r.data?.patient.id,
          }),
        )
        queryClient.invalidateQueries(
          QueryKeys.patientsSearch({ currentSiteId: siteId }),
        )
        return r.data
      })
  })
}

export default useUpdatePatientPhoneData
