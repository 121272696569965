import { FC, ReactChild, ReactNode } from 'react'
import {
  Body1,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@perk-ui/core'

import AppLink from '../../../components/AppLink'
import { AdverseEvent } from '../../../features/query-hooks/useAdverseEvents'
import useDates from '../../../hooks/useDates'

export interface AdverseEventTableProps {
  pagination: ReactNode
  data: AdverseEvent[]
}

interface Column {
  id: 'name' | 'provider' | 'status' | 'createdAt'
  label: string
  minWidth?: number
  align?: 'right'
  icon?: ReactChild
  format?: (value?: string | number | Date) => string | ReactChild
}

const useStyles = makeStyles((theme) => ({
  container: {},
  tableHeader: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(3),
  },
  tableHeaderIcon: {
    display: 'flex',
    fontWeight: 'bold',
    backgroundColor: theme.palette.common.white,
    justifyContent: 'flex-start',
  },
  contentRow: {
    cursor: 'pointer',
    '&:active': {
      backgroundColor: 'rgba(0, 0, 0, 0.1) !important',
    },
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0.05)',
    },
  },
  contentCell: {
    paddingLeft: theme.spacing(3),
  },
}))

const AdverseEventTable: FC<AdverseEventTableProps> = ({
  pagination,
  data,
}) => {
  const classes = useStyles()
  const { localizedFormatDate } = useDates()

  const columns: Column[] = [
    { id: 'name', label: 'Name' },
    { id: 'provider', label: 'Provider' },
    { id: 'status', label: 'Status' },
    {
      id: 'createdAt',
      label: 'Date Reported',
      format: (value) => (value ? localizedFormatDate(new Date(value)) : ''),
    },
  ]

  return (
    <>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {columns.map((column: Column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                  className={
                    column.icon ? classes.tableHeaderIcon : classes.tableHeader
                  }
                >
                  {column.label}
                  {column.icon}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.length > 0 ? (
              <>
                {data.map((row) => (
                  <TableRow
                    hover
                    key={row.id}
                    className={classes.contentRow}
                    component={AppLink}
                    to={`/adverse-events/${row.id}`}
                    underline="none"
                  >
                    {columns.map((column) => {
                      const value = getValueforTable(column, row)
                      return (
                        <TableCell
                          key={column.id}
                          align={column.align}
                          className={classes.contentCell}
                          style={
                            column.id === 'status' &&
                            row[column.id] === 'created'
                              ? { color: 'red' }
                              : { color: 'black' }
                          }
                        >
                          {column.format ? column.format(value) : value}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                ))}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Box p={3} width="100%" textAlign="center">
                    <Body1>No adverse event records</Body1>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination}
    </>
  )
}

const getValueforTable = (column: Column, row: AdverseEvent) => {
  switch (column.id) {
    case 'name':
      return `${row.patient.firstName} ${row.patient.lastName}`
    case 'provider':
      return row.provider
        ? `${row.provider.firstName} ${row.provider.lastName}`
        : '---'
    case 'status':
      return row[column.id] === 'created' ? 'Needs Review' : 'Reviewed'
    case 'createdAt':
      return row[column.id]
    default:
  }
}

export default AdverseEventTable
