import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Protocol } from './useProtocols'

interface ProtocolUpdateResponse {
  protocol: Protocol
}

interface ProtocolUpdateVars extends Partial<Omit<Protocol, 'sites'>> {}

const useUpdateProtocol = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation(({ id, ...vars }: ProtocolUpdateVars) => {
    const url = `/sites/protocols/${id}`
    return request
      .patch<ProtocolUpdateResponse>(url, {
        siteIds: [siteId],
        protocol: {
          ...vars,
          // email: vars.email || null,
          // medicalRecordNumber: vars.medicalRecordNumber || undefined,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.protocol(id || ''))
        queryClient.invalidateQueries(QueryKeys.protocols({ clinicId: siteId }))
        return r.data.protocol
      })
  })
}

export default useUpdateProtocol
