import deepmerge from 'deepmerge'

import { AppConfig } from '../app-config'
import developmentConfig from './development'

const productionConfig = deepmerge<AppConfig>(developmentConfig, {
  baseApiPath: 'https://api.databiologics.com/',
  authHost: 'https://auth.databiologics.com/',
  apmUrl: 'https://apm.databiologics.com/',
  tableauProductionBaseUrl: 'https://impact.databiologics.com/',
  tableauProductionEmbeddedApiUrl:
    'https://impact.databiologics.com/javascripts/api/tableau.embedding.3.latest.min.js',
  tableauStagingBaseUrl: 'https://access.databiologics.com/',
  tableauStagingEmbeddedApiUrl:
    'https://access.databiologics.com/javascripts/api/tableau.embedding.3.latest.min.js',
  googleMapsApiKey: 'AIzaSyDI2QYc4_MUQBPdgJeefb1sAQkEeAvf35g',
  stripePublictKey:
    'pk_live_51Mt0Y9Avm1kprluZo1A3xREFK74XbCAO4JnmmjWJqp5YJ4M4ZI7oiwaFhESBFRkZFgI2gjENdfwjaxeaPpuVXTs000KSbDrcJ0',
  newRelicAccountID: '4313352',
  newRelicTrustKey: '4313352',
  newRelicAgentID: '1103305417',
  newRelicLicenseKey: 'NRJS-c7c9c3e2974022f7dd5',
  newRelicApplicationID: '1103305417',
  sentryDSN:
    'https://b5864863156f0070c60fb0367c91c0a7@o4506389729181696.ingest.us.sentry.io/4507368921694208',
})

export default productionConfig
