import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
// import { BodySiteKeyCamelCase } from './useBodySites'

interface PatientTreatmentStatsResponse
  extends Partial<Record<string, number>> {}

const usePatientTreatmentStats = (
  extraOptions: UseQueryOptions<PatientTreatmentStatsResponse> = {},
) => {
  const currentSiteId = useCurrentSite()?.id || ''
  const key = QueryKeys.patientTreatmentStats(currentSiteId)
  const url = `/sites/patient_treatments/stats?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () => request.get<PatientTreatmentStatsResponse>(url).then((r) => r.data),
    {
      enabled: Boolean(currentSiteId),
      ...extraOptions,
    },
  )
}

export default usePatientTreatmentStats
