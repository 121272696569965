import { FC, useEffect, useRef, useState } from 'react'
import {
  Box,
  Button,
  CircularProgress,
  H1,
  H3,
  H5,
  IconButton,
  makeStyles,
  Tab,
  Tabs,
} from '@perk-ui/core'
import { format } from 'date-fns'
import parse from 'html-react-parser'
import { ArrowUp, Download } from 'react-feather'
import { useHistory, useLocation } from 'react-router'

import useClinicContracts from '../../../features/query-hooks/useClinicContracts'
import { ClinicContract } from '../../../features/query-hooks/useUnauthenticatedClinicContracts'
import { parseYMD } from '../../../utils/dates'
import SendEmailContractDialog from '../components/SendEmailContractDialog'
import SignContractDialog from '../components/SignContractDialog'
import { onboardingUrls } from '../Onboarding'

interface ContractSignatureStepProps {
  type: 'authenticated' | 'unauthenticated'
  contracts?: Array<ClinicContract>
}

const TabTitles = {
  baa: 'BAA',
  'service-agreement': 'Service Agreement',
}

const useStyles = makeStyles((theme) => ({
  actionBox: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  downloadButton: {
    borderRadius: '16px',
    border: `1px solid ${theme.palette.primary.main}`,
  },
  contractTabs: {
    border: '1px solid #f1f1f1',
    borderRadius: '16px',
    padding: theme.spacing(1),
    '& .MuiTabs-flexContainer': {
      '& button.Mui-selected': {
        borderRadius: '8px',
        backgroundColor: '#E9F7FC', // primary.light?
      },
    },
    '& .MuiTabs-indicator': {
      display: 'none',
    },
  },
  pdfSample: {
    position: 'relative',
    overflow: 'auto',
    width: 'auto',
    minHeight: '100px',
    margin: theme.spacing(2, 0),
    padding: theme.spacing(2, 3),
    backgroundColor: '#FAFAFB',
    borderRadius: '20px',
    '& body': {
      backgroundColor: '#FAFAFB',
      padding: theme.spacing(3),
    },
  },
  signatureContainer: {
    width: '100%',
    borderRadius: '20px',
    backgroundColor: '#FAFAFB',
    padding: theme.spacing(3),
    marginBottom: theme.spacing(2),
    '& h5': {
      fontSize: '14px',
      fontWeight: '400',
    },
  },
  signature: {
    display: 'flex',
    alignItems: 'baseline',
    '& .signature': {
      fontSize: '40px',
      textDecoration: 'underline',
      fontFamily: 'Caveat',
      fontWeight: 'bold',
      fontStyle: 'oblique',
      margin: theme.spacing(0, 1, 0, 0),
    },
    '& span': {
      fontSize: '14px',
      textDecoration: 'none',
      fontWeight: '400',
    },
  },
  signatureStepContainer: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    overflow: 'auto',
  },
  signatureFooter: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
  },
  signAgreementButton: {
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  },
  emailButton: {
    color: theme.palette.common.black,
    minHeight: '20px',
    padding: 0,
    marginTop: theme.spacing(2),
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.dark,
    },
    '&:not(:disabled):active': {
      backgroundColor: 'unset',
    },
  },
  footerDate: {
    marginLeft: theme.spacing(2),
  },
  scrollToTopContainer: {
    position: 'absolute',
    bottom: theme.spacing(6),
    right: theme.spacing(6),
  },
  scrollToTopButton: {
    position: 'fixed',
  },
  loadingContainer: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    '& .MuiCircularProgress-root': {
      margin: 'auto',
    },
  },
}))

const ContractSignatureStep: FC<ContractSignatureStepProps> = ({
  type,
  contracts = [],
}) => {
  const classes = useStyles()
  const { push } = useHistory()
  const { pathname } = useLocation()
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const pdfSampleContainerRef = useRef<any>(null)
  const [currentContractId, setCurrentContractId] = useState<string | null>(
    null,
  )
  const [pdfSampleScrolled, setPdfSampleScrolled] = useState(false)
  const [openSendEmailDialog, setOpenSendEmailDialog] = useState(false)
  const [openSignContractDialog, setOpenSignContractDialog] = useState(false)
  const [currentContract, setCurrentContract] = useState<ClinicContract>()

  // const stepContracts =

  const { data: authenticatedContracts = [], isLoading } = useClinicContracts()
  const stepContracts =
    type == 'authenticated' ? authenticatedContracts : contracts
  const currentStepIndex = onboardingUrls.indexOf(pathname)
  // type == 'authenticated' ? useClinicContracts().data || [] : contracts || []

  useEffect(() => {
    const pendingContract = stepContracts.find(
      (contract) => contract.status == 'pending',
    )
    if (stepContracts.length > 0 && !currentContractId) {
      if (pendingContract) {
        setCurrentContractId(pendingContract.id)
        setCurrentContract(pendingContract)
      } else {
        setCurrentContractId(stepContracts[0].id)
        setCurrentContract(stepContracts[0])
      }
    } else {
      const contract = stepContracts.find(
        (contract) => contract.id == currentContractId,
      )
      setCurrentContract(contract)
    }
  }, [stepContracts])

  const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
    setCurrentContractId(newValue)
    const contract = stepContracts.find((contract) => contract.id == newValue)
    setCurrentContract(contract)
    scrollToTop()
  }

  const scrollToTop = () => {
    if (pdfSampleContainerRef.current)
      pdfSampleContainerRef.current.scrollTo({ top: 0, behavior: 'smooth' })
  }

  const downloadDocument = () => {
    if (currentContract?.url) {
      // download from url
      window.open(currentContract?.url, '_blank', 'noreferrer')
    }
  }
  const handlePdfSampleScroll = () => {
    if (pdfSampleContainerRef?.current?.scrollTop) {
      setPdfSampleScrolled(true)
    } else {
      setPdfSampleScrolled(false)
    }
  }
  useEffect(() => {
    pdfSampleContainerRef.current &&
      pdfSampleContainerRef.current.addEventListener(
        'scroll',
        handlePdfSampleScroll,
      )
  }, [pdfSampleContainerRef])

  return (
    <Box className="step-content">
      <Box className="step-header">
        <H1>E-signing contracts</H1>
        {type == 'authenticated' && (
          <H3>
            Do you have authority to sign a clinic Business Associate Agreement
            and Services Agreement? If yes, click Continue, if no, click Email
            to Authorized Personnel
          </H3>
        )}
      </Box>
      <Box className={classes.signatureStepContainer}>
        <Box className={classes.actionBox}>
          <Box>
            {!isLoading && stepContracts.length > 0 && (
              <Tabs
                className={classes.contractTabs}
                value={currentContractId}
                onChange={handleTabChange}
              >
                {stepContracts.map((contract) => {
                  return (
                    <Tab
                      key={contract.id}
                      value={contract.id}
                      label={TabTitles[contract.name]}
                    />
                  )
                })}
              </Tabs>
            )}
          </Box>
          <Button
            className={classes.downloadButton}
            variant="outlined"
            startIcon={<Download />}
            onClick={downloadDocument}
          >
            Download
          </Button>
        </Box>
        <Box className={classes.pdfSample} ref={pdfSampleContainerRef}>
          {isLoading && (
            <Box className={classes.loadingContainer}>
              <CircularProgress />
            </Box>
          )}
          {parse(currentContract?.content || '')}
          {pdfSampleScrolled && (
            <Box className={classes.scrollToTopContainer}>
              <IconButton
                onClick={scrollToTop}
                className={classes.scrollToTopButton}
              >
                <ArrowUp />
              </IconButton>
            </Box>
          )}
        </Box>
        {currentContract?.status == 'signed' && (
          <Box className={classes.signatureContainer}>
            <H5>Signed By</H5>
            <Box className={classes.signature}>
              <p className="signature">{currentContract.signature}</p>{' '}
              <span>
                {currentContract.signDate &&
                  format(
                    parseYMD(currentContract.signDate),
                    currentContract.country === 'canada'
                      ? 'dd/MM/yyyy'
                      : 'MM/dd/yyyy',
                  )}
              </span>
            </Box>
          </Box>
        )}
        <Box className={classes.signatureFooter}>
          <Box>
            <Button
              className={classes.signAgreementButton}
              onClick={() => {
                if (currentContract?.status == 'pending') {
                  setOpenSignContractDialog(true)
                } else {
                  const nextContract = stepContracts.find(
                    (contract) =>
                      contract.id !== currentContractId &&
                      contract.status == 'pending',
                  )
                  if (nextContract) {
                    setCurrentContractId(nextContract.id)
                    setCurrentContract(nextContract)
                  } else {
                    if (type === 'authenticated') {
                      push(onboardingUrls[currentStepIndex + 1])
                    } else {
                      // Go to signature done page
                      push(
                        '/sign-contract/thank-you?link=' +
                          (currentContract?.url || ''),
                      )
                    }
                  }
                }
              }}
            >
              {currentContract?.status == 'pending'
                ? 'Sign Agreement'
                : 'Continue'}
            </Button>
            {currentContract?.status == 'pending' && (
              <span className={classes.footerDate}>
                <b>
                  Date:{' '}
                  {format(
                    new Date(),
                    currentContract.country === 'canada'
                      ? 'dd/MM/yyyy'
                      : 'MM/dd/yyyy',
                  )}
                </b>
              </span>
            )}
          </Box>
          {type == 'authenticated' && (
            <Button
              variant="text"
              className={classes.emailButton}
              onClick={() => {
                setOpenSendEmailDialog(true)
              }}
            >
              Email Authorized personnel
            </Button>
          )}
          <SignContractDialog
            setOpen={setOpenSignContractDialog}
            open={openSignContractDialog}
            currentContractId={currentContractId}
            contracts={stepContracts}
            setCurrentContractId={setCurrentContractId}
            type={type}
          />
          {type == 'authenticated' && (
            <SendEmailContractDialog
              setOpen={setOpenSendEmailDialog}
              open={openSendEmailDialog}
            />
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default ContractSignatureStep
