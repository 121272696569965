import { FC } from 'react'
import { H5, Icon, List, ListItem, makeStyles } from '@perk-ui/core'
import { CheckCircle, Circle } from 'react-feather'
import { useLocation, useRouteMatch } from 'react-router'

import AppLink from '../../components/AppLink'
import usePatientCases from '../../features/query-hooks/usePatientCases'
import { useHistory } from '../../utils/useHistory'
import SidebarCaseItem from './components/SidebarCaseItem'
import PatientFlowUrls, { PatientFlowLocationParams } from './PatientFlowUrls'

const useStyles = makeStyles((theme) => ({
  root: {
    marginBottom: theme.spacing(2),
    height: '100%',
    overflow: 'auto',
  },
  addCaseButton: {
    padding: 0,
    marginTop: theme.spacing(1),
    justifyContent: 'center',
    color: theme.palette.primary.main,
    '&:visited': {
      color: theme.palette.primary.main,
    },
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  icon: {
    marginRight: theme.spacing(1),
  },
}))

export interface PatientFlowSidebarProps {}

const PatientFlowSidebar: FC<PatientFlowSidebarProps> = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const { pathname } = useLocation()
  const location = useLocation<{ from?: string }>()

  const editingPatientMatch = useRouteMatch<PatientFlowLocationParams>(
    PatientFlowUrls.hasPatient.match,
  )
  const patientId = editingPatientMatch?.params.patientId
  const invitePath =
    editingPatientMatch && patientId
      ? PatientFlowUrls.editPatient.create(patientId)
      : PatientFlowUrls.invitePatient.create()

  const { data: patientCases = [] } = usePatientCases(patientId)

  const activeCases = patientCases.filter((pCase) => pCase.status === 'active')

  return (
    <List className={classes.root}>
      <ListItem>
        <H5>Patient Context</H5>
      </ListItem>
      <ListItem
        id="patient-flow-sidebar-invite-button"
        button
        dense
        selected={pathname === invitePath}
        onClick={() =>
          pathname !== invitePath &&
          push({
            pathname: invitePath,
            state: { from: location.state?.from ? location.state?.from : null },
          })
        }
      >
        <Icon color={patientId ? 'success' : 'inherit'}>
          {patientId ? (
            <CheckCircle className={classes.icon} />
          ) : (
            <Circle className={classes.icon} />
          )}
        </Icon>{' '}
        {editingPatientMatch ? 'Edit Patient Info' : 'Invite Patient'}
      </ListItem>
      {patientId && (
        <>
          <ListItem
            id="patient-flow-sidebar-add-case-button"
            className={classes.addCaseButton}
            component={AppLink}
            dense
            to={{
              pathname: PatientFlowUrls.addCase.create(patientId),
              state: {
                from: location.state?.from ? location.state?.from : null,
              },
            }}
          >
            <H5>Add Another Body Part</H5>
          </ListItem>
        </>
      )}
      {patientId &&
        activeCases.map((pCase, index) => (
          <SidebarCaseItem
            id={'patient-invite-flow-case-container' + index}
            key={pCase.id}
            patientCase={pCase}
          />
        ))}
    </List>
  )
}

export default PatientFlowSidebar
