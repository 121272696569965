import { createContext, FC, useContext, useEffect, useState } from 'react'
import { changePassword, requestPasswordReset } from 'keratin-authn'
import { useQueryClient } from 'react-query'
import { useLocation } from 'react-router'

import apm from '../../config/analytics'
import config from '../../config/environments/development'
import { buildName } from '../../utils/buildName'
import { useHistory } from '../../utils/useHistory'
import useCurrentUser, { User } from '../query-hooks/useCurrentUser'
import { AuthService } from './AuthService'

interface AuthState {
  signIn: (username: string, password: string) => Promise<void>
  signOut: () => Promise<void>
  changePassword: typeof changePassword
  requestPasswordReset: typeof requestPasswordReset
  resetPassword: AuthService['resetPassword']
  isAuthenticated: boolean
  user?: User
  isLoading: boolean
  initialize: () => void
}

const authService = new AuthService()

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const AuthContext = createContext<AuthState>(undefined as any)
export const useIsAuthenticated = () => useContext(AuthContext).isAuthenticated
export const useAuth = () => useContext(AuthContext)
export const useUser = () => useContext(AuthContext).user as User

const AuthProvider: FC = ({ children }) => {
  const { push } = useHistory()
  const { search } = useLocation()
  const queryParams = new URLSearchParams(search)
  const queryClient = useQueryClient()
  const [initialized, setInitialized] = useState(false)
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [isLoading, setIsLoading] = useState(true)
  const [currentUser, setCurrentUser] = useState<User | undefined>()

  const {
    changePassword,
    signIn,
    signOut,
    requestPasswordReset,
    resetPassword,
  } = authService

  const { data: user } = useCurrentUser({
    enabled: initialized,
    onError(err) {
      const error = err as Error

      if (error?.message.match(/401/)) {
        // We attempted to fetch the current user with a token but it was unauthorized,
        // meaning our token is invalid.
        // This block doesn't do anything, just documenting the failure cases we run into.
      }
    },
    async onSuccess(user) {
      if (user.staff) {
        setIsAuthenticated(true)
        setIsLoading(false)
        setCurrentUser(user)
        // Don't redirect here to home, because it already moves in sign in, or if
        // coming from a refresh, stay in the same page
        if (queryParams.has('redirectTo')) {
          push(queryParams.get('redirectTo') || '')
        }
      } else {
        await handleSignout()
      }
    },
  })

  // This has to be called after using the reset password or verify user
  const initialize = () => {
    setInitialized(true)
    if (queryParams.has('redirectTo')) {
      push(queryParams.get('redirectTo') || '')
    } else {
      push(config.authenticatedRootUrl)
    }
  }

  const handleSignIn = async (username: string, password: string) => {
    await signIn(username, password) // throws on invalid credentials
    await initialize()
  }

  const handleSignout = async () => {
    await signOut()
    setCurrentUser(undefined)
    setInitialized(false)
    setIsAuthenticated(false)
    setIsLoading(true)

    // Remove all cached ReactQuery data and any active queries
    await queryClient.cancelQueries()
    await queryClient.resetQueries()
    queryClient.clear()
  }

  const handleResetPassword: AuthService['resetPassword'] = async (args) => {
    return resetPassword(args)
  }

  useEffect(() => {
    authService
      .initAuthenticatedSession()
      .then(() => {
        setInitialized(true)
      })
      .catch(async (_err) => {
        setIsLoading(false)
        // Hack to redirect to login correctly
        setIsLoading(true)
        // await handleSignout()

        // One possible error shape:
        // error: [{ message: '401' }]
        // Happens when the app is loaded and the refresh token is invalid
        // Regardless, the best way out of errors here is to log-in again.
        // Note: We intentionally reload the app with this href change
        // window.location.href = config.unAuthenticatedRootUrl
      })
  }, [setInitialized])

  useEffect(() => {
    apm.setUserContext(
      user
        ? {
            id: user.id,
            email: user?.email || 'None',
            username: buildName(user.staff),
          }
        : {
            username: 'Not logged in',
          },
    )
  }, [user])

  const contextValue = {
    signIn: handleSignIn,
    signOut: handleSignout,
    changePassword,
    requestPasswordReset,
    resetPassword: handleResetPassword,
    isAuthenticated,
    user: currentUser,
    isLoading,
    initialize,
  }

  // If we have a token, we're ready once we've loaded the currentUser. Otherwise, once we've initialized.
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  )
}

export default AuthProvider
