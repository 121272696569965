import { FC, ReactChild, ReactNode, useEffect, useState } from 'react'
import {
  Body1,
  Box,
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
} from '@perk-ui/core'
import { AlertCircle, Archive } from 'react-feather'
import { useHistory } from 'react-router'

import { Patient } from '../../../features/query-hooks/usePatient'
import { PatientCase } from '../../../features/query-hooks/usePatientCases'
import SurveyStatusChip from './SurveyStatusChip'

export interface PatientTableProps {
  pagination: ReactNode
  data: Patient[]
  isLoading: boolean
  headers: Header
}
interface Header {
  providerHeader: ReactChild
  bodyPartHeader: ReactChild
  diagnosesHeader: ReactChild
  treatmentsHeader: ReactChild
  surveyStatusHeader: ReactChild
}

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(2),
    border: '1px solid rgb(224,224,224)',
  },
  tableHeader: {
    fontWeight: 'bold',
    backgroundColor: theme.palette.common.white,
    paddingLeft: theme.spacing(3),
    // minWidth: '230px',
  },
  tableHeaderIcon: {
    display: 'flex',
    fontWeight: 'bold',
    backgroundColor: theme.palette.common.white,
    justifyContent: 'flex-start',
  },
  contentRow: {},
  contentCell: {
    paddingLeft: theme.spacing(3),
  },
  clickableContentCell: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#F1F1F1',
    },
    '&:active': {
      backgroundColor: '#F1F1F1',
    },
    '&:focus': {
      backgroundColor: '#F1F1F1',
    },
  },
  noBorderContentCell: {
    borderBottom: 'none',
  },
  missingInfo: {
    color: theme.palette.error.main,
    '&:hover': {
      backgroundColor: '#F9E6E6',
    },
    '&:active': {
      backgroundColor: '#F9E6E6',
    },
    '&:focus': {
      backgroundColor: '#F9E6E6',
    },
  },
  cellData: {
    display: 'flex',
    alignItems: 'center',
    '& svg': {
      marginRight: '2px',
    },
  },
  archivedRow: {
    color: '#A3A3A3',
  },
  archivedCell: {
    color: '#A3A3A3',
  },
  archivedStatusIcon: {
    borderRadius: '25px',
    padding: '2px 8px',
    color: '#919191',
    backgroundColor: '#D5D5D5',
    marginLeft: theme.spacing(1),
    display: 'flex',
    '& svg': {
      margin: 0,
    },
  },
}))

const PatientTable: FC<PatientTableProps> = ({
  pagination,
  data,
  isLoading,
  headers,
}) => {
  const classes = useStyles()
  const { push } = useHistory()
  const [tBodyRows, setTBodyRows] = useState<Array<JSX.Element>>([])

  useEffect(() => {
    loadTableBody()
  }, [data])
  const loadTableBody = () => {
    const tableRows: JSX.Element[] = []
    data.map((row: Patient) => {
      const patientName = `${row.firstName} ${row.lastName}`
      const patientCasesCount = row.patientCases?.length || 0
      const hiddenPatientCasesCount =
        row.patientCases?.filter((hpc) => hpc.status === 'hidden').length || 0
      let displayEmptyPatient = false
      let showPatientName = true
      let displayIndex = 0
      if (patientCasesCount === hiddenPatientCasesCount)
        displayEmptyPatient = true
      if (row.patientCases?.length === 0 || displayEmptyPatient) {
        let nameCellClassName = `${classes.contentCell} ${classes.clickableContentCell}`
        if (row.status !== 'active') {
          nameCellClassName += ` ${classes.archivedRow}`
        }
        tableRows.push(
          <TableRow key={row.id} className={classes.contentRow}>
            {/* Name column */}
            <TableCell
              key={'name'}
              className={nameCellClassName}
              onClick={() =>
                push({
                  pathname: `/patients/${row.id}`,
                  state: { from: location.pathname },
                })
              }
            >
              {showPatientName && (
                <div className={classes.cellData}>
                  <div>{patientName}</div>{' '}
                  {row.status !== 'active' ? (
                    <Tooltip
                      title={
                        row.archivalOccurrence?.archivalReason.description
                          ? row.archivalOccurrence?.archivalReason.description
                          : ''
                      }
                    >
                      <span className={classes.archivedStatusIcon}>
                        <Archive size={14} />
                      </span>
                    </Tooltip>
                  ) : (
                    ''
                  )}
                </div>
              )}
            </TableCell>
            {/* Provider  */}
            <TableCell
              key={'provider'}
              className={`${classes.archivedCell} ${classes.clickableContentCell}`}
              onClick={() => {
                push({
                  pathname: `/patients/${row.id}`,
                  state: { from: location.pathname },
                })
              }}
            >
              {'—'}
            </TableCell>
            {/* Body Parts */}
            <TableCell
              key={'bodyPart'}
              className={`${classes.archivedCell} ${classes.clickableContentCell}`}
              onClick={() => {
                push({
                  pathname: `/patients/${row.id}`,
                  state: { from: location.pathname },
                })
              }}
            >
              {'—'}
            </TableCell>
            {/* Primary Diagnosis */}
            <TableCell
              key={'primaryDiagnosis'}
              className={`${classes.archivedCell} ${classes.clickableContentCell}`}
              onClick={() => {
                push({
                  pathname: `/patients/${row.id}`,
                  state: { from: location.pathname },
                })
              }}
            >
              {'—'}
            </TableCell>
            {/* Primary Treatment */}
            <TableCell
              key={'primarTreatment'}
              className={`${classes.archivedCell} ${classes.clickableContentCell}`}
              onClick={() => {
                push({
                  pathname: `/patients/${row.id}`,
                  state: { from: location.pathname },
                })
              }}
            >
              {'—'}
            </TableCell>
            {/* Survey Status */}
            <TableCell
              key={'surveyStatus'}
              className={`${classes.archivedCell} ${classes.clickableContentCell}`}
              onClick={() => {
                push({
                  pathname: `/patients/${row.id}`,
                  state: { from: location.pathname },
                })
              }}
            >
              {'—'}
            </TableCell>
          </TableRow>,
        )
      } else {
        row.patientCases?.map((innerRow: PatientCase) => {
          const sortedTreatments = innerRow.patientTreatments?.sort(
            (a, b) =>
              new Date(a.treatmentDate).getTime() -
              new Date(b.treatmentDate).getTime(),
          )
          let skipRowPush = false
          let cellClassName = `${classes.contentCell} ${classes.clickableContentCell}`
          let nameCellClassName = `${classes.contentCell} ${classes.clickableContentCell}`
          let diagnosisCellClassName = `${classes.contentCell} ${classes.clickableContentCell}`
          let treatmentCellClassName = `${classes.contentCell} ${classes.clickableContentCell}`
          let surveyStatusClassName = classes.contentCell
          let diagnosisName: React.ReactElement | string | undefined =
            innerRow.patientDiagnoses?.[0]?.medicalCondition?.name
          let treatmentName: React.ReactElement | string | undefined =
            sortedTreatments?.[0]?.medicalTreatment?.name
          let providerName:
            | React.ReactElement
            | string
            | undefined = `${sortedTreatments?.[0]?.provider?.firstName} ${sortedTreatments?.[0]?.provider?.lastName}`
          if (
            displayIndex + 1 !== patientCasesCount - hiddenPatientCasesCount &&
            patientCasesCount > 1
          ) {
            cellClassName += ` ${classes.noBorderContentCell}`
            nameCellClassName += ` ${classes.noBorderContentCell}`
            diagnosisCellClassName += ` ${classes.noBorderContentCell}`
            treatmentCellClassName += ` ${classes.noBorderContentCell}`
            surveyStatusClassName += ` ${classes.noBorderContentCell}`
          }
          if (row.status !== 'active') {
            cellClassName += ` ${classes.archivedRow}`
            nameCellClassName += ` ${classes.archivedRow}`
            diagnosisCellClassName += ` ${classes.archivedRow}`
            treatmentCellClassName += ` ${classes.archivedRow}`
            diagnosisName = '—'
            treatmentName = '—'
            providerName = '—'
          }
          if (innerRow.status !== 'active') {
            diagnosisCellClassName += ` ${classes.archivedCell}`
            treatmentCellClassName += ` ${classes.archivedCell}`
            cellClassName += ` ${classes.archivedRow}`
            if (innerRow.patientDiagnoses?.length === 0) diagnosisName = '—'
            if (sortedTreatments?.length === 0) {
              treatmentName = '—'
              providerName = '—'
            }
          } else {
            if (innerRow.patientDiagnoses?.length === 0) {
              diagnosisCellClassName += ` ${classes.missingInfo}`
              diagnosisName = (
                <div className={`${classes.cellData}`}>
                  <AlertCircle size={16} /> <div>Diagnosis missing</div>
                </div>
              )
            }
            if (sortedTreatments?.length === 0) {
              treatmentCellClassName += ` ${classes.missingInfo}`
              treatmentName = (
                <div className={`${classes.cellData}`}>
                  <AlertCircle size={16} /> <div>Treatment missing</div>
                </div>
              )
              providerName = '—'
            }
          }
          if (
            patientCasesCount !== hiddenPatientCasesCount &&
            innerRow.status === 'hidden'
          )
            skipRowPush = true
          if (!skipRowPush) {
            tableRows.push(
              <TableRow key={innerRow.id} className={classes.contentRow}>
                {/* Name column */}
                <TableCell
                  key={'name'}
                  className={nameCellClassName}
                  onClick={() =>
                    push({
                      pathname: `/patients/${row.id}`,
                      state: { from: location.pathname },
                    })
                  }
                >
                  {showPatientName && (
                    <div className={classes.cellData}>
                      <div>{patientName}</div>{' '}
                      {row.status !== 'active' ? (
                        <Tooltip
                          title={
                            row.archivalOccurrence?.archivalReason.description
                              ? row.archivalOccurrence?.archivalReason
                                  .description
                              : ''
                          }
                        >
                          <span className={classes.archivedStatusIcon}>
                            <Archive size={14} />
                          </span>
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </TableCell>
                {/* Provider */}
                <TableCell
                  key={'provider'}
                  onClick={() => {
                    if (innerRow.status === 'active')
                      if (sortedTreatments?.[0]?.id) {
                        push({
                          pathname: `/flow/${row.id}/case/${innerRow.id}/treatment/${sortedTreatments?.[0]?.id}`,
                          state: { from: location.pathname },
                        })
                      } else {
                        push({
                          pathname: `/flow/${row.id}/case/${innerRow.id}/treatment`,
                          state: { from: location.pathname },
                        })
                      }
                    else {
                      push({
                        pathname: `/patients/${row.id}`,
                        state: { from: location.pathname },
                      })
                    }
                  }}
                  className={treatmentCellClassName}
                >
                  {providerName}
                </TableCell>
                {/* Body Parts */}
                <TableCell
                  key={'bodyPart'}
                  onClick={() =>
                    push({
                      pathname: `/patients/${row.id}`,
                      state: { from: location.pathname },
                    })
                  }
                  className={cellClassName}
                >
                  <div className={classes.cellData}>
                    <div>
                      {innerRow.status !== 'hidden'
                        ? innerRow.bodySite?.name
                        : '—'}
                    </div>{' '}
                    {row.status === 'active' &&
                    innerRow.status !== 'active' &&
                    innerRow.status !== 'hidden' ? (
                      <span className={classes.archivedStatusIcon}>
                        <Archive size={14} />
                      </span>
                    ) : (
                      ''
                    )}
                  </div>
                </TableCell>
                {/* Primary Diagnosis */}
                <TableCell
                  key={'primaryDiagnosis'}
                  onClick={() => {
                    if (innerRow.status === 'active')
                      push({
                        pathname: `/flow/${row.id}/case/${innerRow.id}/diagnosis`,
                        state: { from: location.pathname },
                      })
                    else {
                      push({
                        pathname: `/patients/${row.id}`,
                        state: { from: location.pathname },
                      })
                    }
                  }}
                  className={diagnosisCellClassName}
                >
                  {diagnosisName}
                </TableCell>
                {/* Primary Treatment */}
                <TableCell
                  key={'primarTreatment'}
                  onClick={() => {
                    if (innerRow.status === 'active')
                      if (sortedTreatments?.[0]?.id) {
                        push({
                          pathname: `/flow/${row.id}/case/${innerRow.id}/treatment/${sortedTreatments?.[0]?.id}`,
                          state: { from: location.pathname },
                        })
                      } else {
                        push({
                          pathname: `/flow/${row.id}/case/${innerRow.id}/treatment`,
                          state: { from: location.pathname },
                        })
                      }
                    else {
                      push({
                        pathname: `/patients/${row.id}`,
                        state: { from: location.pathname },
                      })
                    }
                  }}
                  className={treatmentCellClassName}
                >
                  {treatmentName}
                </TableCell>
                {/* Survey Status */}
                {innerRow.status !== 'hidden' ? (
                  <TableCell
                    key={'surveyStatus'}
                    className={surveyStatusClassName}
                  >
                    <SurveyStatusChip
                      status={innerRow.surveyStatus}
                      patientId={row.id}
                      patient={row}
                      patientCaseId={innerRow.id}
                      patientName={patientName}
                      bodyPart={innerRow.bodySite?.name}
                      tooltip={
                        innerRow.surveyStatus === 'archived'
                          ? innerRow.archivalOccurrence?.archivalReason
                              .description
                          : undefined
                      }
                    />
                  </TableCell>
                ) : (
                  <TableCell
                    key={'surveyStatus'}
                    className={`${classes.archivedCell} ${classes.clickableContentCell}`}
                    onClick={() => {
                      push({
                        pathname: `/patients/${row.id}`,
                        state: { from: location.pathname },
                      })
                    }}
                  >
                    {'—'}
                  </TableCell>
                )}
              </TableRow>,
            )
            showPatientName = false
            displayIndex += 1
          }
        })
      }
    })
    setTBodyRows(tableRows || [])
  }
  return (
    <div>
      <TableContainer className={classes.container}>
        <Table stickyHeader aria-label="sticky table">
          <TableHead>
            <TableRow>
              {/* name header */}
              <TableCell key={'nameHeader'} className={classes.tableHeader}>
                Name
              </TableCell>
              {/* provider header */}
              <TableCell key={'providerHeader'} className={classes.tableHeader}>
                {headers.providerHeader}
              </TableCell>
              {/* body Part header */}
              <TableCell key={'bodyPartHeader'} className={classes.tableHeader}>
                {headers.bodyPartHeader}
              </TableCell>
              {/* diagnoses header */}
              <TableCell
                key={'diagnosesHeader'}
                className={classes.tableHeader}
              >
                {headers.diagnosesHeader}
              </TableCell>
              {/* treatments header */}
              <TableCell
                key={'treatmentsHeader'}
                className={classes.tableHeader}
              >
                {headers.treatmentsHeader}
              </TableCell>
              {/* survey status header */}
              <TableCell
                key={'surveyStatusHeader'}
                className={classes.tableHeader}
              >
                {headers.surveyStatusHeader}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {!isLoading && data.length > 0 ? (
              <>
                {tBodyRows.map((row) => {
                  return row
                })}
              </>
            ) : (
              <TableRow>
                <TableCell colSpan={100}>
                  <Box p={3} width="100%" textAlign="center">
                    <Body1>
                      {isLoading ? 'Loading...' : 'No patient records'}
                    </Body1>
                  </Box>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      {pagination}
    </div>
  )
}

export default PatientTable
