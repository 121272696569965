import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { ProtocolTreatment } from './useProtocols'

interface ProtocolDiagnosesResponse {
  protocolTreatment: ProtocolTreatment
}

const useProtocolTreatment = (
  protocolTreatmentId: string,
  extraOptions: UseQueryOptions<ProtocolTreatment> = {},
) => {
  const currentSiteId = useCurrentSite().id
  const key = QueryKeys.protocol(protocolTreatmentId)
  const url = `/sites/protocol_treatments/${protocolTreatmentId}?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () =>
      request
        .get<ProtocolDiagnosesResponse>(url)
        .then((r) => r.data.protocolTreatment),
    extraOptions,
  )
}

export default useProtocolTreatment
