import '../assets/sweetalert.css'

import axios, { AxiosError } from 'axios'
import { logout, session } from 'keratin-authn'
import Swal from 'sweetalert2'

import apm from './analytics'
import config from './app-config'

export const BaseAxiosConfig = {
  baseURL: config.baseApiPath,
  timeout: 0,
  headers: {
    'Content-Type': 'application/json',
    'Key-Inflection': 'camel',
  },
}

const request = axios.create(BaseAxiosConfig)

request.interceptors.request.use(async (req) => {
  // Strip our Auth headers if we're requesting a resource from
  // anywhere other than our servers
  try {
    if (req.baseURL === config.baseApiPath) {
      req.headers['Authorization'] = `Bearer ${await session()}`
    }

    const url = new URL(req.url || '')
    if (url.origin.startsWith(config.baseApiPath)) {
      req.headers['Authorization'] = `Bearer ${await session()}`
    }
  } catch {
    // request was not directed to an external server
  }
  return req
})

request.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    return response
  },
  async function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    const response = error.response as AxiosError['response']
    if (
      response?.status === 401 &&
      (response.data?.error === 'not logged in' ||
        response.data?.error === 'authentication failed') &&
      response.config.url !== '/sites/available' &&
      response.config.url !== '/users/current'
    ) {
      // We're not authorized - clear token and send to a login page.
      if (!(await session())) {
        Swal.fire({
          toast: false,
          width: 500,
          position: 'center',
          showConfirmButton: true,
          confirmButtonText: 'Okay',
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: '#56AAEB',
          // icon: 'warning',
          title: 'Session Expired',
          text: 'You have been logged out from your account due to 15 minutes of inactivity. Please log in again.',
          customClass: 'custom-sweetalert',
        }).then(async () => {
          await logout()
          localStorage.removeItem('currentSite')
          window.location.assign(
            config.unAuthenticatedRootUrl + '?sessionExpired=1',
          )
        })
      }
    } else {
      const requestConfig = error.config as AxiosError['config']

      if (
        error.response.data?.errors?.patientTreatment?.length > 0 ||
        error.response.data?.errors?.treatmentDate?.length > 0
      ) {
        Swal.fire({
          toast: false,
          width: 500,
          position: 'center',
          showConfirmButton: true,
          confirmButtonText: 'Okay',
          allowOutsideClick: false,
          allowEscapeKey: false,
          confirmButtonColor: '#56AAEB',
          // icon: 'warning',
          title: 'Error!',
          text: error.response.data?.errors?.patientTreatment
            ? error.response.data?.errors?.patientTreatment[0]
            : error.response.data?.errors?.treatmentDate[0],
          customClass: 'custom-sweetalert',
        })
      }
      /**
       * Undocumented but official behavior of Elastic APM: all fields on
       * an error object get serialized as custom context
       * @see https://github.com/elastic/apm-agent-rum-js/issues/1049
       */
      error.responseBody = JSON.stringify(response?.data)
      error.requestBody = requestConfig?.data
      error.requestPath = requestConfig?.url
      apm.captureError(error)
    }
    return Promise.reject(error)
  },
)

export default request
