import { useMutation, useQueryClient } from 'react-query'

import { ISurveyResponse } from '../../../../core/dist'
import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

interface CreateSurveyResponseResponse {
  // surveyResponse: ISurveyResponse
}

interface CreateSurveyResponseVars
  extends Pick<
    ISurveyResponse,
    | 'surveyId'
    | 'ownerId'
    | 'ownerType'
    | 'answers'
    | 'startedAt'
    | 'completedAt'
  > {
  treatmentIds?: string[]
}

const useCreateSurveyResponse = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation(
    ({
      ownerId,
      ownerType,
      answers,
      treatmentIds,
      ...rest
    }: CreateSurveyResponseVars) => {
      const url = `/sites/survey_responses`

      return request
        .post<CreateSurveyResponseResponse>(url, {
          siteIds: [siteId],
          ownerType,
          ownerId,
          surveyResponse: {
            ...rest,
            surveyResponseAnswers: answers,
          },
        })
        .then((response) => {
          queryClient.invalidateQueries(
            QueryKeys.surveyResponseSearch({ siteId, ownerIds: [ownerId] }),
          )
          if (ownerType === 'PatientTreatment') {
            queryClient.invalidateQueries(
              QueryKeys.surveyResponseSearch({
                siteId,
                ownerIds: treatmentIds,
              }),
            )
          }

          return response.data // .surveyResponse
        })
    },
  )
}

export default useCreateSurveyResponse
