import { FC, Fragment } from 'react'
import {
  _themeAugmentation,
  Divider,
  H3,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@perk-ui/core'
import { AlertCircle, CheckCircle, ChevronRight } from 'react-feather'

import { AdverseEvent } from '../features/query-hooks/useAdverseEvents'
import useDates from '../hooks/useDates'
import AppLink from './AppLink'

export interface PatientAdverseEventsProps {
  adverseEvents: AdverseEvent[]
}

const useStyles = makeStyles((theme) => ({
  cardTitle: {
    marginBottom: theme.spacing(2),
  },
  alertCircle: {
    color: theme.palette.error.main,
    marginRight: theme.spacing(1),
  },
  checkCircle: {
    color: 'black',
    marginRight: theme.spacing(1),
  },
  listItem: {
    paddingLeft: 0,
    minHeight: 'auto',
    '&&': {
      color: theme.palette.text.primary,
    },
    '&:last-child': {
      // To not impact the containing Card's spacing
      marginBottom: -8,
    },
    '&:hover': {
      textDecoration: 'none',
    },
  },
}))

const PatientAdverseEvents: FC<PatientAdverseEventsProps> = ({
  adverseEvents,
}) => {
  const classes = useStyles()
  const { localizedFormatDate } = useDates()

  const renderIcon = (adverseEvent: AdverseEvent) => {
    if (adverseEvent.status === 'created') {
      return <AlertCircle className={classes.alertCircle} />
    } else {
      return <CheckCircle className={classes.checkCircle} />
    }
  }

  const renderText = (adverseEvent: AdverseEvent) => {
    const localizedAdverseEventDate = localizedFormatDate(
      new Date(adverseEvent.createdAt),
    )

    if (adverseEvent.status === 'created') {
      return 'Adverse Event Needs Review'
    } else {
      return `Adverse Event ${localizedAdverseEventDate}`
    }
  }

  return (
    <>
      <H3 className={classes.cardTitle}>Adverse Events</H3>
      <List>
        {adverseEvents.map((adverseEvent: AdverseEvent) => (
          <Fragment key={adverseEvent.id}>
            <Divider />
            <ListItem
              className={classes.listItem}
              component={AppLink}
              to={`/adverse-events/${adverseEvent.id}`}
              disableGutters
            >
              <ListItemIcon>{renderIcon(adverseEvent)}</ListItemIcon>
              <ListItemText body={renderText(adverseEvent)} />
              <ListItemIcon>
                <ChevronRight />
              </ListItemIcon>
            </ListItem>
          </Fragment>
        ))}
      </List>
    </>
  )
}

export default PatientAdverseEvents
