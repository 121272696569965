import { FC } from 'react'
import { InputAdornment, makeStyles, TextField } from '@perk-ui/core'
import { Search } from 'react-feather'

export interface PatientTableSearch {
  searchValue: string
  setSearchValue: (value: string) => void
}

const useStyles = makeStyles((theme) => ({
  textField: {
    marginLeft: theme.spacing(3),
    paddingLeft: 0,
    width: theme.spacing(40),
  },
  icon: {
    marginLeft: theme.spacing(1.5),
    width: theme.spacing(4),
    marginRight: theme.spacing(-2.5),
    color: theme.palette.grey[600],
  },
  button: {
    marginLeft: theme.spacing(2),
  },
}))

const PatientTableSearch: FC<PatientTableSearch> = ({
  setSearchValue,
  searchValue,
}) => {
  const classes = useStyles()

  return (
    <TextField
      className={classes.textField}
      placeholder="Search"
      onChange={(e) => setSearchValue(e.target.value)}
      value={searchValue}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <Search className={classes.icon} />
          </InputAdornment>
        ),
      }}
    />
  )
}

export default PatientTableSearch
