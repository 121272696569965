import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

const useDeleteDiagnosis = () => {
  const queryClient = useQueryClient()
  const currentSiteId = useCurrentSite().id

  return useMutation((diagnosisId: string) => {
    const url = `/sites/patient_diagnoses/${diagnosisId}?site_ids[]=${currentSiteId}`

    return request.delete(url).then(() => {
      queryClient.invalidateQueries(QueryKeys.patientCases({}))
    })
  })
}

export default useDeleteDiagnosis
