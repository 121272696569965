import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Product } from './useProducts'

export interface SubscriptionProduct {
  id: string
  stripePriceId: string
  stripeProductId: string
  siteId: string
  subscriptionId: string
  amount: number
  quantity: number
  usedQuantity: number
  stripeProduct?: Product
}

interface ProductsAvailableResponse {
  products: SubscriptionProduct[]
}

const useProductsAvailable = (
  extraOptions: UseQueryOptions<SubscriptionProduct[]> = {},
) => {
  const key = QueryKeys.productsAvailable()
  const currentSiteId = useCurrentSite()?.id || ''
  const url = `/sites/products_available?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () =>
      request.get<ProductsAvailableResponse>(url).then((r) => r.data.products),
    {
      // Products are static - don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useProductsAvailable
