export const LINEAR_GRADIENT_PRIMARY =
  'linear-gradient(30deg, rgba(30,148,207,1) 0%, rgba(142,211,240,1) 100%)'

export const TIME_ZONES = [
  {
    rails: 'International Date Line West',
    client: 'Etc/GMT+12',
  },
  {
    rails: 'Midway Island',
    client: 'Pacific/Midway',
  },
  {
    rails: 'American Samoa',
    client: 'Pacific/Pago_Pago',
  },
  {
    rails: 'Hawaii',
    client: 'Pacific/Honolulu',
  },
  {
    rails: 'Alaska',
    client: 'America/Juneau',
  },
  {
    rails: 'Pacific Time (US & Canada)',
    client: 'America/Los_Angeles',
  },
  {
    rails: 'Tijuana',
    client: 'America/Tijuana',
  },
  {
    rails: 'Mountain Time (US & Canada)',
    client: 'America/Denver',
  },
  {
    rails: 'Arizona',
    client: 'America/Phoenix',
  },
  {
    rails: 'Chihuahua',
    client: 'America/Chihuahua',
  },
  {
    rails: 'Mazatlan',
    client: 'America/Mazatlan',
  },
  {
    rails: 'Central Time (US & Canada)',
    client: 'America/Chicago',
  },
  {
    rails: 'Saskatchewan',
    client: 'America/Regina',
  },
  {
    rails: 'Guadalajara',
    client: 'America/Mexico_City',
  },
  {
    rails: 'Mexico City',
    client: 'America/Mexico_City',
  },
  {
    rails: 'Monterrey',
    client: 'America/Monterrey',
  },
  {
    rails: 'Central America',
    client: 'America/Guatemala',
  },
  {
    rails: 'Eastern Time (US & Canada)',
    client: 'America/New_York',
  },
  {
    rails: 'Indiana (East)',
    client: 'America/Indiana/Indianapolis',
  },
  {
    rails: 'Bogota',
    client: 'America/Bogota',
  },
  {
    rails: 'Lima',
    client: 'America/Lima',
  },
  {
    rails: 'Quito',
    client: 'America/Lima',
  },
  {
    rails: 'Atlantic Time (Canada)',
    client: 'America/Halifax',
  },
  {
    rails: 'Caracas',
    client: 'America/Caracas',
  },
  {
    rails: 'La Paz',
    client: 'America/La_Paz',
  },
  {
    rails: 'Santiago',
    client: 'America/Santiago',
  },
  {
    rails: 'Newfoundland',
    client: 'America/St_Johns',
  },
  {
    rails: 'Brasilia',
    client: 'America/Sao_Paulo',
  },
  {
    rails: 'Buenos Aires',
    client: 'America/Argentina/Buenos_Aires',
  },
  {
    rails: 'Montevideo',
    client: 'America/Montevideo',
  },
  {
    rails: 'Georgetown',
    client: 'America/Guyana',
  },
  {
    rails: 'Puerto Rico',
    client: 'America/Puerto_Rico',
  },
  {
    rails: 'Greenland',
    client: 'America/Godthab',
  },
  {
    rails: 'Mid-Atlantic',
    client: 'Atlantic/South_Georgia',
  },
  {
    rails: 'Azores',
    client: 'Atlantic/Azores',
  },
  {
    rails: 'Cape Verde Is.',
    client: 'Atlantic/Cape_Verde',
  },
  {
    rails: 'Dublin',
    client: 'Europe/Dublin',
  },
  {
    rails: 'Edinburgh',
    client: 'Europe/London',
  },
  {
    rails: 'Lisbon',
    client: 'Europe/Lisbon',
  },
  {
    rails: 'London',
    client: 'Europe/London',
  },
  {
    rails: 'Casablanca',
    client: 'Africa/Casablanca',
  },
  {
    rails: 'Monrovia',
    client: 'Africa/Monrovia',
  },
  {
    rails: 'UTC',
    client: 'Etc/UTC',
  },
  {
    rails: 'Belgrade',
    client: 'Europe/Belgrade',
  },
  {
    rails: 'Bratislava',
    client: 'Europe/Bratislava',
  },
  {
    rails: 'Budapest',
    client: 'Europe/Budapest',
  },
  {
    rails: 'Ljubljana',
    client: 'Europe/Ljubljana',
  },
  {
    rails: 'Prague',
    client: 'Europe/Prague',
  },
  {
    rails: 'Sarajevo',
    client: 'Europe/Sarajevo',
  },
  {
    rails: 'Skopje',
    client: 'Europe/Skopje',
  },
  {
    rails: 'Warsaw',
    client: 'Europe/Warsaw',
  },
  {
    rails: 'Zagreb',
    client: 'Europe/Zagreb',
  },
  {
    rails: 'Brussels',
    client: 'Europe/Brussels',
  },
  {
    rails: 'Copenhagen',
    client: 'Europe/Copenhagen',
  },
  {
    rails: 'Madrid',
    client: 'Europe/Madrid',
  },
  {
    rails: 'Paris',
    client: 'Europe/Paris',
  },
  {
    rails: 'Amsterdam',
    client: 'Europe/Amsterdam',
  },
  {
    rails: 'Berlin',
    client: 'Europe/Berlin',
  },
  {
    rails: 'Bern',
    client: 'Europe/Zurich',
  },
  {
    rails: 'Zurich',
    client: 'Europe/Zurich',
  },
  {
    rails: 'Rome',
    client: 'Europe/Rome',
  },
  {
    rails: 'Stockholm',
    client: 'Europe/Stockholm',
  },
  {
    rails: 'Vienna',
    client: 'Europe/Vienna',
  },
  {
    rails: 'West Central Africa',
    client: 'Africa/Algiers',
  },
  {
    rails: 'Bucharest',
    client: 'Europe/Bucharest',
  },
  {
    rails: 'Cairo',
    client: 'Africa/Cairo',
  },
  {
    rails: 'Helsinki',
    client: 'Europe/Helsinki',
  },
  {
    rails: 'Kyiv',
    client: 'Europe/Kiev',
  },
  {
    rails: 'Riga',
    client: 'Europe/Riga',
  },
  {
    rails: 'Sofia',
    client: 'Europe/Sofia',
  },
  {
    rails: 'Tallinn',
    client: 'Europe/Tallinn',
  },
  {
    rails: 'Vilnius',
    client: 'Europe/Vilnius',
  },
  {
    rails: 'Athens',
    client: 'Europe/Athens',
  },
  {
    rails: 'Istanbul',
    client: 'Europe/Istanbul',
  },
  {
    rails: 'Minsk',
    client: 'Europe/Minsk',
  },
  {
    rails: 'Jerusalem',
    client: 'Asia/Jerusalem',
  },
  {
    rails: 'Harare',
    client: 'Africa/Harare',
  },
  {
    rails: 'Pretoria',
    client: 'Africa/Johannesburg',
  },
  {
    rails: 'Kaliningrad',
    client: 'Europe/Kaliningrad',
  },
  {
    rails: 'Moscow',
    client: 'Europe/Moscow',
  },
  {
    rails: 'St. Petersburg',
    client: 'Europe/Moscow',
  },
  {
    rails: 'Volgograd',
    client: 'Europe/Volgograd',
  },
  {
    rails: 'Samara',
    client: 'Europe/Samara',
  },
  {
    rails: 'Kuwait',
    client: 'Asia/Kuwait',
  },
  {
    rails: 'Riyadh',
    client: 'Asia/Riyadh',
  },
  {
    rails: 'Nairobi',
    client: 'Africa/Nairobi',
  },
  {
    rails: 'Baghdad',
    client: 'Asia/Baghdad',
  },
  {
    rails: 'Tehran',
    client: 'Asia/Tehran',
  },
  {
    rails: 'Abu Dhabi',
    client: 'Asia/Muscat',
  },
  {
    rails: 'Muscat',
    client: 'Asia/Muscat',
  },
  {
    rails: 'Baku',
    client: 'Asia/Baku',
  },
  {
    rails: 'Tbilisi',
    client: 'Asia/Tbilisi',
  },
  {
    rails: 'Yerevan',
    client: 'Asia/Yerevan',
  },
  {
    rails: 'Kabul',
    client: 'Asia/Kabul',
  },
  {
    rails: 'Ekaterinburg',
    client: 'Asia/Yekaterinburg',
  },
  {
    rails: 'Islamabad',
    client: 'Asia/Karachi',
  },
  {
    rails: 'Karachi',
    client: 'Asia/Karachi',
  },
  {
    rails: 'Tashkent',
    client: 'Asia/Tashkent',
  },
  {
    rails: 'Chennai',
    client: 'Asia/Kolkata',
  },
  {
    rails: 'Kolkata',
    client: 'Asia/Kolkata',
  },
  {
    rails: 'Mumbai',
    client: 'Asia/Kolkata',
  },
  {
    rails: 'New Delhi',
    client: 'Asia/Kolkata',
  },
  {
    rails: 'Kathmandu',
    client: 'Asia/Kathmandu',
  },
  {
    rails: 'Astana',
    client: 'Asia/Dhaka',
  },
  {
    rails: 'Dhaka',
    client: 'Asia/Dhaka',
  },
  {
    rails: 'Sri Jayawardenepura',
    client: 'Asia/Colombo',
  },
  {
    rails: 'Almaty',
    client: 'Asia/Almaty',
  },
  {
    rails: 'Novosibirsk',
    client: 'Asia/Novosibirsk',
  },
  {
    rails: 'Rangoon',
    client: 'Asia/Rangoon',
  },
  {
    rails: 'Bangkok',
    client: 'Asia/Bangkok',
  },
  {
    rails: 'Hanoi',
    client: 'Asia/Bangkok',
  },
  {
    rails: 'Jakarta',
    client: 'Asia/Jakarta',
  },
  {
    rails: 'Krasnoyarsk',
    client: 'Asia/Krasnoyarsk',
  },
  {
    rails: 'Beijing',
    client: 'Asia/Shanghai',
  },
  {
    rails: 'Chongqing',
    client: 'Asia/Chongqing',
  },
  {
    rails: 'Hong Kong',
    client: 'Asia/Hong_Kong',
  },
  {
    rails: 'Urumqi',
    client: 'Asia/Urumqi',
  },
  {
    rails: 'Kuala Lumpur',
    client: 'Asia/Kuala_Lumpur',
  },
  {
    rails: 'Singapore',
    client: 'Asia/Singapore',
  },
  {
    rails: 'Taipei',
    client: 'Asia/Taipei',
  },
  {
    rails: 'Perth',
    client: 'Australia/Perth',
  },
  {
    rails: 'Irkutsk',
    client: 'Asia/Irkutsk',
  },
  {
    rails: 'Ulaanbaatar',
    client: 'Asia/Ulaanbaatar',
  },
  {
    rails: 'Seoul',
    client: 'Asia/Seoul',
  },
  {
    rails: 'Osaka',
    client: 'Asia/Tokyo',
  },
  {
    rails: 'Sapporo',
    client: 'Asia/Tokyo',
  },
  {
    rails: 'Tokyo',
    client: 'Asia/Tokyo',
  },
  {
    rails: 'Yakutsk',
    client: 'Asia/Yakutsk',
  },
  {
    rails: 'Darwin',
    client: 'Australia/Darwin',
  },
  {
    rails: 'Adelaide',
    client: 'Australia/Adelaide',
  },
  {
    rails: 'Canberra',
    client: 'Australia/Melbourne',
  },
  {
    rails: 'Melbourne',
    client: 'Australia/Melbourne',
  },
  {
    rails: 'Sydney',
    client: 'Australia/Sydney',
  },
  {
    rails: 'Brisbane',
    client: 'Australia/Brisbane',
  },
  {
    rails: 'Hobart',
    client: 'Australia/Hobart',
  },
  {
    rails: 'Vladivostok',
    client: 'Asia/Vladivostok',
  },
  {
    rails: 'Guam',
    client: 'Pacific/Guam',
  },
  {
    rails: 'Port Moresby',
    client: 'Pacific/Port_Moresby',
  },
  {
    rails: 'Magadan',
    client: 'Asia/Magadan',
  },
  {
    rails: 'Srednekolymsk',
    client: 'Asia/Srednekolymsk',
  },
  {
    rails: 'Solomon Is.',
    client: 'Pacific/Guadalcanal',
  },
  {
    rails: 'New Caledonia',
    client: 'Pacific/Noumea',
  },
  {
    rails: 'Fiji',
    client: 'Pacific/Fiji',
  },
  {
    rails: 'Kamchatka',
    client: 'Asia/Kamchatka',
  },
  {
    rails: 'Marshall Is.',
    client: 'Pacific/Majuro',
  },
  {
    rails: 'Auckland',
    client: 'Pacific/Auckland',
  },
  {
    rails: 'Wellington',
    client: 'Pacific/Auckland',
  },
  {
    rails: "Nuku'alofa",
    client: 'Pacific/Tongatapu',
  },
  {
    rails: 'Tokelau Is.',
    client: 'Pacific/Fakaofo',
  },
  {
    rails: 'Chatham Is.',
    client: 'Pacific/Chatham',
  },
  {
    rails: 'Samoa',
    client: 'Pacific/Apia',
  },
]
export const SATISFACTION_SCORE = [
  {
    value: '1.0',
    slug: 'Not at all satisfied',
  },
  {
    value: '2.0',
    slug: 'Somewhat satisfied',
  },
  {
    value: '3.0',
    slug: 'Satisfied',
  },
  {
    value: '4.0',
    slug: 'Very Satisfied',
  },
  {
    value: '5.0',
    slug: 'Extremly Satisfied',
  },
]
export const SATISFACTION_PSC = [
  {
    value: '4.0',
    slug: 'Very dissatisfied',
  },
  {
    value: '3.0',
    slug: 'Dissatisfied',
  },
  {
    value: '2.0',
    slug: 'Neutral',
  },
  {
    value: '1.0',
    slug: 'Satisfied',
  },
  {
    value: '0.0',
    slug: 'Very Satisfied',
  },
]

export const UROLOGY_CONDITION = [
  {
    value: '1.0',
    slug: 'Nocturia',
  },
  {
    value: '0.0',
    slug: 'Erectile Dysfunction',
  },
]

export const PGII_SCORE = [
  {
    value: '1.0',
    slug: 'Very much better',
  },
  {
    value: '2.0',
    slug: 'Much better',
  },
  {
    value: '3.0',
    slug: 'A little better',
  },
  {
    value: '4.0',
    slug: 'No change',
  },
  {
    value: '5.0',
    slug: 'A little worse',
  },
  {
    value: '6.0',
    slug: 'Much worse',
  },
  {
    value: '7.0',
    slug: 'Very much worse',
  },
]

export const PENETRATION_PAIN_LOCATION = [
  {
    value: '1.0',
    slug: 'Superficial',
  },
  {
    value: '0.0',
    slug: 'Deep',
  },
]

export const staffRoles = ['Provider', 'ClinicStaff']

export const secondaryRoles = [
  'FrontDesk',
  'Scheduling',
  'MedicalAssistant',
  'Phlebotomist',
  'Technician',
  'Scribe',
  'Manager',
  'BusinessOffice',
]

export const providerSpecialties = [
  'Anesthesiology',
  'FamilyMedicine',
  'PainMedicine',
  'PhysicalMedicine&Rehabilitation',
  'PreventiveMedicine',
  'Radiology',
  'Chiropractor',
  'Rheumatology',
  'Podiatry',
  'Naturopath',
  'PhysicianAssistant',
  'NursePractitioner',
]

export const providerTitles = [
  'MD',
  'DO',
  'ND',
  'DPM',
  'DC',
  'NMD',
  'PA',
  'PA-C',
  'NP',
  'RN',
]

export const societiesAffiliations = ['AMMSM', 'AAOS', 'TOBI', 'IOF', 'AAOM']

export const clinicStaffPositions = [
  {
    value: 'oa',
    label: 'OA',
  },
  {
    value: 'officeClinicManager',
    label: 'Office/clinic manager',
  },
  {
    value: 'billing',
    label: 'Billing',
  },
  {
    value: 'frontDesk',
    label: 'Front Desk',
  },
  {
    value: 'scheduling',
    label: 'Scheduling',
  },
]

export const residentFellow = [
  {
    label: 'Resident',
    value: 'resident',
  },
  {
    label: 'Fellow',
    value: 'fellow',
  },
]

export const countryList = [
  {
    label: 'United States',
    value: 'united_states_of_america',
  },
  {
    label: 'Canada',
    value: 'canada',
  },
]

export const canadaTimeZones = [
  'Pacific Time (US & Canada)',
  'Mountain Time (US & Canada)',
  'Central Time (US & Canada)',
  'Eastern Time (US & Canada)',
  'Atlantic Time (Canada)',
  'Newfoundland',
]

export const unitedStatesTimeZones = [
  'Hawaii',
  'Alaska',
  'Pacific Time (US & Canada)',
  'Mountain Time (US & Canada)',
  'Central Time (US & Canada)',
  'Eastern Time (US & Canada)',
]

export const subscriptionPlan = [
  'demo',
  'starter',
  'essentials',
  'performance',
  'performance_rsx',
  'performance_ebm',
  'performance_registry',
  'performance_rsx_ebm',
  'performance_rsx_registry',
  'performance_ebm_registry',
  'performance_rsx_ebm_registry',
]

export const siteSpecialties = ['Orthopedics', 'Urology/Gynecology']

export const adverseEventsIssues = [
  {
    value: 'Pain',
    slug: 'Pain',
  },
  {
    value: 'Swelling',
    slug: 'Swelling',
  },
  {
    value: 'Infection',
    slug: 'Infection',
  },
  {
    value: 'Bleeding',
    slug: 'Bleeding',
  },
  {
    value: 'Bruising',
    slug: 'Bruising',
  },
  {
    value: 'other',
    slug: 'Other',
  },
]

export const adverseEventsSeekingMedicalAttention = [
  {
    value: 'no',
    slug: 'No',
  },
  {
    value: 'treating_doctor',
    slug: 'Treating Doctor',
  },
  {
    value: 'emergency_department',
    slug: 'Emergency Department',
  },
  {
    value: 'primary_care_doctor',
    slug: 'Primary Care Doctor',
  },
  {
    value: 'surgery',
    slug: 'Surgery',
  },
]

export const surveyPeriodsOrdered = [
  {
    slug: 'baseline',
    type: 'pre',
    value: 0,
    order: 0,
  },
  {
    slug: '2 weeks',
    type: '2wk',
    value: 0.5,
    order: 1,
  },
  {
    slug: '1 month',
    type: '1mo',
    value: 1,
    order: 2,
  },
  {
    slug: '6 weeks',
    type: '6wk',
    value: 1.5,
    order: 3,
  },
  {
    slug: '2 months',
    type: '2mo',
    value: 2,
    order: 4,
  },
  {
    slug: '3 months',
    type: '3mo',
    value: 3,
    order: 5,
  },
  {
    slug: '6 months',
    type: '6mo',
    value: 6,
    order: 6,
  },
  {
    slug: '9 months',
    type: '9mo',
    value: 9,
    order: 7,
  },
  {
    slug: '12 months',
    type: '12mo',
    value: 12,
    order: 8,
  },
  {
    slug: '18 months',
    type: '18mo',
    value: 18,
    order: 9,
  },
  {
    slug: '24 months',
    type: '24mo',
    value: 24,
    order: 10,
  },
  {
    slug: '36 months',
    type: '36mo',
    value: 36,
    order: 11,
  },
]

export const radiographicClassifications = [
  {
    label: 'Grade 0',
    value: 'kl0',
  },
  {
    label: 'Grade 1',
    value: 'kl1',
  },
  {
    label: 'Grade 2',
    value: 'kl2',
  },
  {
    label: 'Grade 3',
    value: 'kl3',
  },
  {
    label: 'Grade 4',
    value: 'kl4',
  },
]

export const patientReportSurveySlugs = [
  'KOOS-JR',
  'NUMERIC-PAIN-RATING',
  'PHQ4',
  'ODI',
  'FAAM',
]

export const qcKinetixProtocols = [
  {
    key: 1,
    name: 'Protocol 1',
    description: '',
    treatments: [
      {
        order: 1,
        treatmentSlug: 'ppp',
        treatmentDateWeeks: 0,
      },
      {
        order: 2,
        treatmentSlug: 'prp',
        treatmentDateWeeks: 3,
      },
      {
        order: 3,
        treatmentSlug: 'prp',
        treatmentDateWeeks: 6,
      },
      {
        order: 4,
        treatmentSlug: 'ppp',
        treatmentDateWeeks: 9,
      },
      {
        order: 5,
        treatmentSlug: 'ppp',
        treatmentDateWeeks: 21,
      },
    ],
  },
  {
    key: 2,
    name: 'Protocol 2',
    description: '',
    treatments: [
      {
        order: 1,
        treatmentSlug: 'ppp',
        treatmentDateWeeks: 0,
      },
      {
        order: 2,
        treatmentSlug: 'prp',
        treatmentDateWeeks: 3,
      },
      {
        order: 3,
        treatmentSlug: 'a2m',
        treatmentDateWeeks: 6,
      },
      {
        order: 4,
        treatmentSlug: 'prp',
        treatmentDateWeeks: 10,
      },
      {
        order: 5,
        treatmentSlug: 'ppp',
        treatmentDateWeeks: 22,
      },
    ],
  },
  {
    key: 3,
    name: 'Protocol 3',
    description: '',
    treatments: [
      {
        order: 1,
        treatmentSlug: 'ppp',
        treatmentDateWeeks: 0,
      },
      {
        order: 2,
        treatmentSlug: 'a2m',
        treatmentDateWeeks: 3,
      },
      {
        order: 3,
        treatmentSlug: 'bone-marrow',
        treatmentDateWeeks: 7,
      },
      {
        order: 4,
        treatmentSlug: 'ppp',
        treatmentDateWeeks: 10,
      },
      {
        order: 5,
        treatmentSlug: 'ppp',
        treatmentDateWeeks: 22,
      },
    ],
  },
  {
    key: 4,
    name: 'Kick Start',
    description: '',
    treatments: [
      {
        order: 1,
        treatmentSlug: 'ppp',
        treatmentDateWeeks: 0,
      },
      {
        order: 2,
        treatmentSlug: 'prp',
        treatmentDateWeeks: 4,
      },
      {
        order: 3,
        treatmentSlug: 'ppp',
        treatmentDateWeeks: 8,
      },
    ],
  },
]

export const qcKinetixProtocolsSecondaryTreatments = [
  {
    key: 1,
    slug: 'Class IV Laser',
    value: 'class-iv-laser',
  },
  {
    key: 2,
    slug: 'Shockwave Therapy',
    value: 'shockwave-therapy',
  },
  {
    key: 3,
    slug: 'Testosterone Replacement Therapy (TRT)',
    value: 'trt',
  },
  {
    key: 4,
    slug: 'Weight Loss Protocol',
    value: 'weight-loss-protocol',
  },
]

export const insightsWorkbooks = {
  myImpact: {
    demo: 'views/IMPACTEssentials/EssentialsExplorer',
    starter: 'views/IMPACTStarter/IMPACTHome',
    essentials: 'views/IMPACTEssentials/EssentialsExplorer',
    performance: 'views/IMPACTPerformance/IMPACTHome',
    performance_rsx: 'views/IMPACTPerformanceRSX/IMPACTHome',
    performance_ebm: 'views/IMPACTPerformanceEBM/IMPACTHome',
    performance_registry: 'views/IMPACTPerformanceIMPACTRegistry/IMPACTHome',
    performance_rsx_ebm: 'views/IMPACTPerformanceRSXEBM/IMPACTHome',
    performance_rsx_registry:
      'views/IMPACTPerformanceRSXIMPACTRegistry/IMPACTHome',
    performance_ebm_registry:
      'views/IMPACTPerformanceEBMIMPACTRegistry/IMPACTHome',
    performance_rsx_ebm_registry:
      'views/IMPACTPerformanceRSXEBMIMPACTRegistry/IMPACTHome',
  },
  rawData: {
    demo: 'views/IMPACTEssentialsReports/IMPACTReportsHome',
    starter: 'views/IMPACTReportsStarter/IMPACTReportsHome',
    essentials: 'views/IMPACTEssentialsReports/IMPACTReportsHome',
    performance: 'views/IMPACTPerformanceReports/IMPACTReportsHome',
    performance_rsx: 'views/IMPACTPerformanceRSXReports/IMPACTReportsHome',
    performance_ebm: 'views/IMPACTPerformanceEBMReports/IMPACTReportsHome',
    performance_registry:
      'views/IMPACTPerformanceIMPACTRegistryReports/IMPACTReportsHome',
    performance_rsx_ebm:
      'views/IMPACTPerformanceRSXEBMReports/IMPACTReportsHome',
    performance_rsx_registry:
      'views/IMPACTPerformanceRSXRegistryReports/IMPACTReportsHome',
    performance_ebm_registry:
      'views/IMPACTPerformanceEBMRegistryReports/IMPACTReportsHome',
    performance_rsx_ebm_registry:
      'views/IMPACTPerformanceRSXEBMRegistryReports/IMPACTReportsHome',
  },
}

export const periodTypes = [
  {
    value: 'days',
    label: 'Days',
  },
  {
    value: 'weeks',
    label: 'Weeks',
  },
  {
    value: 'months',
    label: 'Months',
  },
  {
    value: 'years',
    label: 'Years',
  },
]
