import { useState } from 'react'
import { SelectChangeEvent } from '@mui/material'
import {
  Body1,
  Button,
  Divider,
  H3,
  Icon,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
} from '@perk-ui/core'
import { X } from 'react-feather'
import { useHistory } from 'react-router'

import useArchivalReasons, {
  ArchivalReasonType,
} from '../../../features/query-hooks/useArchivalReasons'
import useArchivePatient from '../../../features/query-hooks/useArchivePatient'
import useArchivePatientCase from '../../../features/query-hooks/useArchivePatientCase'
import usePatient from '../../../features/query-hooks/usePatient'
import { buildName } from '../../../utils/buildName'

const useStyles = makeStyles((theme) => ({
  titleRow: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingBottom: theme.spacing(2),
  },
  divider: {
    marginBottom: theme.spacing(2),
  },
  nameText: {
    marginBottom: theme.spacing(2),
  },
  boldNameText: {
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  reasonLabel: {
    marginBottom: theme.spacing(1),
  },
  reasonSelect: {
    marginBottom: theme.spacing(2),
  },
  xButton: {
    padding: 0,
  },
  archiveButtonRow: {
    textAlign: 'end',
  },
  archiveButtonAndTextRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  cantBeUndoneText: {
    color: 'red',
    fontWeight: 'bold',
    alignSelf: 'center',
    justifySelf: 'center',
  },
  redText: {
    color: 'red',
    marginBottom: theme.spacing(2),
  },
}))

export interface ArchiveModalProps {
  bodySiteName?: string
  patientId?: string
  patientCaseId?: string
  archiveType: ArchivalReasonType
  toggle: () => void
}

const ArchiveModal: React.FC<ArchiveModalProps> = ({
  bodySiteName,
  patientId,
  patientCaseId,
  archiveType,
  toggle,
}) => {
  const classes = useStyles()
  const [contentState, setContentState] = useState<
    'initial' | 'archive' | 'hide'
  >('initial')
  const [reasonIdSelected, setReasonIdSelected] = useState<string | undefined>()

  const { data: archivalReasons } = useArchivalReasons(archiveType)
  const { data: patient } = usePatient(patientId)

  const archiveCase = useArchivePatientCase()
  const archivePatient = useArchivePatient()

  const { push } = useHistory()

  const findReasonAction = (reasonId: string) => {
    const reason = archivalReasons?.find((reason) => reason.id === reasonId)
    if (archivalReasons && reason) {
      return reason.action
    }
    return 'initial'
  }

  const patientName = patient ? buildName(patient) : ''

  const archiveTypeString = archiveType === 'Patient' ? 'Patient' : 'Body Part'

  const archiveString =
    archiveType === 'Patient'
      ? `Archive All Data for ${patientName}`
      : `Archive ${bodySiteName} for ${patientName}`

  const renderTitleText = () => {
    switch (contentState) {
      case 'initial':
        return `Archive ${archiveTypeString}`
      case 'archive':
      case 'hide':
        return 'Are You Sure?'
    }
  }

  const renderBody = () => {
    switch (contentState) {
      case 'initial':
        return (
          <>
            <Body1 className={classes.nameText}>
              {archiveType === 'PatientCase'
                ? `${patientName}, ${bodySiteName}`
                : `Archive All Data For ${patientName}`}
            </Body1>
            <InputLabel className={classes.reasonLabel} id="provider-label">
              Reason
            </InputLabel>
            <Select
              placeholder="Select"
              fullWidth
              className={classes.reasonSelect}
              onChange={(event: SelectChangeEvent) => {
                const ev = event.target as HTMLInputElement
                const val = ev.value as string
                setReasonIdSelected(val)
              }}
            >
              {archivalReasons?.map((reason) => (
                <MenuItem key={reason.id} value={reason.id}>
                  {reason.description}
                </MenuItem>
              ))}
            </Select>
          </>
        )
      case 'archive':
        return (
          <>
            <Body1 className={classes.boldNameText}>{archiveString}</Body1>
            <Body1 className={classes.nameText}>
              This data will no longer be editable. If you need to add
              information for this {archiveTypeString.toLowerCase()} in the
              future you will need to start by adding a new{' '}
              {archiveType === 'PatientCase' ? 'body part for this ' : ''}
              patient.
            </Body1>
          </>
        )
      case 'hide':
        return (
          <>
            <Body1 className={classes.boldNameText}>{archiveString}</Body1>
            <Body1 className={classes.redText}>
              This data will be deleted from the system forever.
            </Body1>
          </>
        )
    }
  }

  const renderBottom = () => {
    switch (contentState) {
      case 'initial':
        return (
          <div className={classes.archiveButtonRow}>
            <Button
              size="large"
              onClick={() => {
                if (reasonIdSelected) {
                  const reasonAction = findReasonAction(reasonIdSelected)
                  setContentState(reasonAction)
                }
              }}
            >
              Archive
            </Button>
          </div>
        )
      case 'archive':
      case 'hide':
        return (
          <div className={classes.archiveButtonAndTextRow}>
            <Body1 className={classes.cantBeUndoneText}>
              This cannot be undone.
            </Body1>
            <Button
              size="large"
              onClick={() => {
                return archiveType === 'Patient'
                  ? handlePatientArchive()
                  : handlePatientCaseArchive()
              }}
            >
              Archive {archiveTypeString}
            </Button>
          </div>
        )
    }
  }

  const handlePatientArchive = () => {
    if (reasonIdSelected && patientId) {
      return archivePatient
        .mutateAsync({
          archiveReasonId: reasonIdSelected,
          patientId: patientId,
        })
        .then(() => {
          if (findReasonAction(reasonIdSelected) === 'hide') {
            push('/home')
          } else {
            push(`/patients/${patientId}`)
          }
        })
    }
  }

  const handlePatientCaseArchive = () => {
    if (patientCaseId && reasonIdSelected && patientId) {
      return archiveCase
        .mutateAsync({
          patientCaseId: patientCaseId,
          archiveReasonId: reasonIdSelected,
          patientId: patientId,
        })
        .then(() => {
          push(`/patients/${patientId}`)
        })
    }
  }

  return (
    <>
      <div className={classes.titleRow}>
        <H3>{renderTitleText()}</H3>
        <Button size="small" variant="text" onClick={() => toggle()}>
          <Icon size="medium" color="secondaryText">
            <X />
          </Icon>
        </Button>
      </div>
      <Divider className={classes.divider} />
      {renderBody()}
      <Divider className={classes.divider} />
      {renderBottom()}
    </>
  )
}

export default ArchiveModal
