import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

interface HomeMetricStats {
  patients: number
  surveys: number
  satisfactionPercent: number | null
  satisfactionTotal: number
  satisfactionSatisfied: number
  baselineSurveys: number
  followupSurveys: number
  baselineCompletedSurveys: number
  followupCompletedSurveys: number
  baselinePercent: number | null
  followupPercent: number | null
}

const useHomeMetricStats = (
  extraOptions: UseQueryOptions<HomeMetricStats> = {},
) => {
  const currentSiteId = useCurrentSite()?.id || ''
  const url = `/sites/dashboard-stats?site_ids[]=${currentSiteId}`
  const key = QueryKeys.homeMetricStats(currentSiteId)
  return useQuery(
    key,
    () => request.get<HomeMetricStats>(url).then((r) => r.data),
    extraOptions,
  )
}

export default useHomeMetricStats
