import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Protocol } from './useProtocols'

interface ProtocolsResponse {
  protocol: Protocol
}

const useProtocol = (
  protocolId: string,
  extraOptions: UseQueryOptions<Protocol> = {},
) => {
  const currentSiteId = useCurrentSite().id
  const key = QueryKeys.protocol(protocolId)
  const url = `/sites/protocols/${protocolId}?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () => request.get<ProtocolsResponse>(url).then((r) => r.data.protocol),
    extraOptions,
  )
}

export default useProtocol
