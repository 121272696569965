import {
  useMutation,
  // useQueryClient,
} from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
// import QueryKeys from './QueryKeys'

interface EmailPaymentLinkResponse {}

interface EmailPaymentLinkBody {
  email: string
  link: string
}

const useEmailPaymentLink = () => {
  // const queryClient = useQueryClient()
  const url = '/sites/email_payment_link'
  const siteId = useCurrentSite().id

  return useMutation((vars: EmailPaymentLinkBody) =>
    request
      .post<EmailPaymentLinkResponse>(url, {
        siteIds: [siteId],
        ...vars,
      })
      .then((r) => {
        return r.data
      }),
  )
}

export default useEmailPaymentLink
