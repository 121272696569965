import React, { useEffect, useState } from 'react'
import {
  Alert,
  Button,
  EmailField,
  LoginContainer,
  makeStyles,
  PageTitle,
  PasswordField,
} from '@perk-ui/core'
import { KeratinError } from 'keratin-authn/dist/types'

import AuthBackground from '../../components/AuthBackground'
import { useAuth } from '../../features/auth/AuthContext'

const useStyles = makeStyles((theme) => ({
  login: {
    display: 'flex',
    justifyContent: 'center',
  },
  password: {
    marginTop: theme.spacing(3),
  },
  loginButton: {
    marginTop: theme.spacing(3),
    // background: 'linear-gradient(to right, #5E59FA, #28ACEB);',
  },
}))

interface LoginErrors {
  credentials?: boolean
  expired?: boolean
}

interface LoginProps {}

const Login: React.FC<LoginProps> = () => {
  const classes = useStyles()
  const { signIn, signOut } = useAuth()

  const [errors, setErrors] = useState<LoginErrors>({} as LoginErrors)
  const [loading, setLoading] = useState(false)
  const [username, setUsername] = useState<string>('')
  const [password, setPassword] = useState<string>('')

  useEffect(() => {
    signOut()
  }, [])

  const handleSignIn = async () => {
    // If successful, signIn will trigger an `auth.isAuthenticated` context state change,
    // which will be picked up in the Routes file and trigger the correct redirect.
    setLoading(true)
    signIn(username, password).catch((error) => {
      if (!error || !Array.isArray(error)) {
        return
      }
      const keratinErrors = error as KeratinError[]
      const badCredentials = keratinErrors.some(
        (err) => err.field === 'credentials' && err.message === 'FAILED',
      )
      const expiredCredentials = keratinErrors.some(
        (err) => err.field === 'credentials' && err.message === 'EXPIRED',
      )
      if (badCredentials) {
        setErrors({
          credentials: true,
        })
      }
      if (expiredCredentials) {
        setErrors({
          expired: true,
        })
      }
      setLoading(false)
    })
  }

  return (
    <AuthBackground>
      <PageTitle title="Login" />
      <div className={classes.login}>
        <LoginContainer
          MessagesComponent={
            errors.credentials ? (
              <>
                <Alert severity="error" fade grow>
                  Invalid username or password
                </Alert>
              </>
            ) : errors.expired ? (
              <>
                <Alert severity="error" fade grow>
                  Account is not active.
                </Alert>
              </>
            ) : null
          }
          UsernameComponent={
            <EmailField
              id="login-email"
              onChange={(ev) => setUsername(ev.target.value)}
            />
          }
          PasswordComponent={
            <PasswordField
              id="login-password"
              className={classes.password}
              onChange={(ev) => setPassword(ev.target.value)}
            />
          }
          LoginButtonComponent={
            <Button
              id="login-button"
              className={classes.loginButton}
              onClick={handleSignIn}
              loading={loading}
            >
              Log In
            </Button>
          }
          forgotPasswordURL="/forgot-password"
        />
      </div>
    </AuthBackground>
  )
}

export default Login
