import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Staff } from './useCurrentUser'

interface DeactivateAccountResponse {
  staff: Staff
}

interface DeactivateAccountVars {
  staffId: string
}

const useDeactivateAccount = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite()?.id || ''

  return useMutation((vars: DeactivateAccountVars) => {
    const url = `/sites/staffs/${vars.staffId}?site_ids[]=${siteId}`

    return request.delete<DeactivateAccountResponse>(url).then((response) => {
      queryClient.invalidateQueries(QueryKeys.staffs(siteId || ''))
      queryClient.invalidateQueries(QueryKeys.productsAvailable())
      return response.data
    })
  })
}

export default useDeactivateAccount
