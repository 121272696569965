import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import clsx from 'clsx';
import { CheckCircle, Circle } from 'react-feather';
import { Icon, List, ListItem, makeStyles, Paper } from '../';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        '& .SurveyChecklist-item:last-child': {
            marginBottom: 0,
        },
        '& .SurveyChecklist-item:first-child': {
            marginTop: 0,
        },
    },
    item: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
        minHeight: 0,
    },
    iconContainer: {
        display: 'flex',
        [theme.breakpoints.down('md')]: {
            width: theme.spacing(3),
        },
    },
    itemCard: {
        alignItems: 'center',
        cursor: 'pointer',
        display: 'flex',
        padding: theme.spacing(2),
        textAlign: 'left',
        width: '100%',
        boxShadow: theme.perk.boxShadow.card,
    },
    itemLabel: {
        marginLeft: theme.spacing(1),
        fontSize: '1rem',
        [theme.breakpoints.down('md')]: {
            fontSize: '0.875rem',
        },
    },
}));
const SurveyChecklist = ({ items, id, onItemSelect, readOnly, className, }) => {
    const classes = useStyles();
    const [itemSelected, setItemSelected] = useState('');
    if (!readOnly && !onItemSelect) {
        throw new Error('Checklist requires an `onItemSelect` callback when not readOnly');
    }
    const handleSelect = (i) => {
        setItemSelected(i.value);
        onItemSelect && onItemSelect(i.value);
    };
    return (_jsx(List, Object.assign({ id: id, className: clsx(classes.root, className) }, { children: items.map((i) => (_jsx(ListItem, Object.assign({ className: clsx('SurveyChecklist-item', classes.item), button: true, disabled: readOnly, 
            // Undo the opacity that is applied when disabled === true
            style: readOnly ? { opacity: 1 } : {}, disableGutters: true, onClick: () => handleSelect(i) }, { children: _jsxs(Paper, Object.assign({ className: classes.itemCard }, { children: [_jsx("div", Object.assign({ className: classes.iconContainer }, { children: _jsx(Icon, Object.assign({ color: i.value === itemSelected ? 'success' : 'disabled' }, { children: i.value === itemSelected ? _jsx(CheckCircle, {}, void 0) : _jsx(Circle, {}, void 0) }), void 0) }), void 0), _jsx("span", Object.assign({ className: classes.itemLabel }, { children: i.label }), void 0)] }), void 0) }), i.value))) }), void 0));
};
export default SurveyChecklist;
