import { Dispatch, FC, SetStateAction, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  Dialog,
  H2,
  H5,
  makeStyles,
  TextField,
} from '@perk-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'

import useSendEmailContract from '../../../features/query-hooks/useSendEmailcontracts'

interface SendEmailContractDialogProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
}

interface SendEmailContractDialogFormValues {
  email: string
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiDialog-container': {
      '& .MuiPaper-root:not(.MuiAlert-root)': {
        width: '100%',
        maxWidth: '548px',
        padding: theme.spacing(4),
      },
    },
  },
  form: {
    '& .MuiFormControl-root': {
      marginBottom: theme.spacing(3),
    },
  },
  dialogHeader: {
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    '& h2': {
      fontSize: '32px',
      fontWeight: 700,
    },
    '& h5': {
      fontSize: '16px',
      fontWeight: 500,
    },
  },
  alert: {
    padding: theme.spacing(1),
    marginBottom: theme.spacing(2),
  },
  submitButton: {},
  closeButton: {
    color: theme.palette.common.black,
    minHeight: '20px',
    padding: 0,
    marginTop: theme.spacing(2),
    '&:hover': {
      textDecoration: 'underline',
      color: theme.palette.primary.dark,
    },
    '&:not(:disabled):active': {
      backgroundColor: 'unset',
    },
  },
}))

const COUNTDOWN_SECONDS = 3

const SendEmailContractDialog: FC<SendEmailContractDialogProps> = ({
  open,
  setOpen,
}) => {
  // const { signIn } = useAuth()
  const classes = useStyles()
  const { mutateAsync: sendEmail } = useSendEmailContract()
  const [emailSent, setEmailSent] = useState(false)
  const [ticker, setTicker] = useState<number>(COUNTDOWN_SECONDS)

  const sendEmailContractValidation = yup.object({
    email: yup
      .string()
      .required('Email is required')
      .email('Ensure the email is formatted correctly'),
  })

  const startTicking = () => {
    const tId = window.setInterval(() => {
      setTicker((tick) => {
        if (tick <= 1) {
          window.clearInterval(tId)
        }

        return tick - 1
      })
    }, 1000)
  }
  const handleDialogClose = () => {
    setOpen(false)
    resetForm()
    setEmailSent(false)
    setTicker(COUNTDOWN_SECONDS)
  }

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldError,
    // setFieldValue,
    resetForm,
    isSubmitting,
    isValid,
    touched,
  } = useFormik<SendEmailContractDialogFormValues>({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: sendEmailContractValidation,
    onSubmit: async (values) => {
      return sendEmail(values.email)
        .then(() => {
          setEmailSent(true)
          startTicking()

          setTimeout(() => {
            handleDialogClose()
          }, COUNTDOWN_SECONDS * 1000)
        })
        .catch(() => {
          setFieldError(
            'email',
            'There was en error when sending the contracts by email.',
          )
        })
    },
  })

  return (
    <Dialog onClose={handleDialogClose} open={open} className={classes.root}>
      <Box className={classes.dialogHeader}>
        <H2>Email agreement</H2>
        <H5>Share agreement with authorised user</H5>
      </Box>
      <form className={classes.form} onSubmit={handleSubmit}>
        {emailSent && (
          <Alert severity="success" fade grow className={classes.alert}>
            Email Sent!
            <br />
            closing pop-up in {ticker} seconds.
          </Alert>
        )}
        <TextField
          label="Email"
          name="email"
          placeholder="Enter email"
          fullWidth
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />
        <Button
          type="submit"
          fullWidth
          loading={isSubmitting}
          disabled={!isValid}
          className={classes.submitButton}
        >
          Send Agreements
        </Button>
        <Button
          variant="text"
          onClick={handleDialogClose}
          fullWidth
          className={classes.closeButton}
        >
          Sign agreement yourself
        </Button>
      </form>
    </Dialog>
  )
}

export default SendEmailContractDialog
