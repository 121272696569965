import { headlessNewRelicSnippet } from './headlessNewRelicSnippet';
export const generateNewRelicSnippet = (config) => {
    const { accountID, trustKey, agentID, licenseKey, applicationID } = config;
    if (!accountID || !trustKey || !agentID || !licenseKey || !applicationID)
        return;
    const generatedSnippet = `;window.NREUM||(NREUM={});NREUM.init={distributed_tracing:{enabled:true},privacy:{cookies_enabled:true},ajax:{deny_list:["bam.nr-data.net"]}};
    ;NREUM.loader_config={accountID:"${accountID}",trustKey:"${trustKey}",agentID:"${agentID}",licenseKey:"${licenseKey}",applicationID:"${applicationID}"};
    ;NREUM.info={beacon:"bam.nr-data.net",errorBeacon:"bam.nr-data.net",licenseKey:"${licenseKey}",applicationID:"${applicationID}",sa:1};
    ;/*! For license information please see nr-loader-spa-1.250.0.min.js.LICENSE.txt */` +
        headlessNewRelicSnippet;
    return generatedSnippet;
};
