export interface ProtocolFlowLocationParams {
  protocolId: string
  diagnosisId: string
  treatmentId: string
}

const ProtocolFlowUrls = {
  base: {
    match: '/protocols',
  },
  hasProtocol: {
    match: '/protocols/:protocolId',
  },
  createProtocol: {
    match: '/protocols',
    create: () => `/protocols`,
  },
  editProtocol: {
    match: '/protocols/:protocolId/edit',
    create: (protocolId: string) => `/protocols/${protocolId}/edit`,
  },
  previewProtocol: {
    match: '/protocols/:protocolId/preview',
    create: (protocolId: string) => `/protocols/${protocolId}/preview`,
  },
  diagnosis: {
    match: '/protocols/:protocolId/diagnosis/:diagnosisId',
    create: (protocolId: string, diagnosisId: string) =>
      `/protocols/${protocolId}/diagnosis/${diagnosisId}`,
  },
  addDiagnosis: {
    match: '/protocols/:protocolId/diagnosis',
    create: (protocolId: string) => `/protocols/${protocolId}/diagnosis`,
  },
  treatment: {
    match: '/protocols/:protocolId/treatment/:treatmentId',
    create: (protocolId: string, treatmentId: string) =>
      `/protocols/${protocolId}/treatment/${treatmentId}`,
  },
  addTreatment: {
    match: '/protocols/:protocolId/treatment',
    create: (protocolId: string) => `/protocols/${protocolId}/treatment`,
  },
}

export default ProtocolFlowUrls
