import React, { ReactNode } from 'react'
import {
  Box,
  capitalize,
  H5,
  IconButton,
  makeStyles,
  Step,
  StepButton,
  Stepper,
} from '@perk-ui/core'
import { endOfToday, startOfToday } from 'date-fns'
import { AlertCircle, Check, Clock, Loader, X } from 'react-feather'

import PatientCDOpenSurveys from '../../../components/PatientCDOpenSurveys'
import { surveyPeriodsOrdered } from '../../../config/constants'
import { Patient } from '../../../features/query-hooks/usePatient'
// import { humanizeBodySite } from '../../../features/query-hooks/useBodySites'
import useSurveyCompletionCDStatus, {
  SurveyStatusObject,
  SurveyStatusPeriod,
  SurveyStatusText,
} from '../../../features/query-hooks/useSurveyCompletionCDStatus'
import useSurveyResponseGroupsAlt from '../../../features/query-hooks/useSurveyResponseGroupsAlt'
import useDates from '../../../hooks/useDates'
import { parseYMD } from '../../../utils/dates'

interface SurveyCompletionCDProps {
  patientId: string
  patientName: string
  patientCaseId: string
  currentStatus?: string
  bodyPart?: string
  patient: Patient
  onClose: () => void
}

const useStyles = makeStyles((theme) => ({
  containerBox: {
    padding: theme.spacing(2),
    minWidth: 'calc(55px * 7)',
    overflow: 'auto',
  },
  bodyPartListContainer: {
    overflow: 'auto',
    maxHeight: '100px',
  },
  surveyLinksContainer: {
    paddingTop: theme.spacing(2),
    overflow: 'auto',
    maxHeight: '100px',
  },
  boxTitle: {
    paddingTop: theme.spacing(1),
    color: '#000',
    fontSize: '1rem',
    textAlign: 'center',
    fontWeight: '700',
  },
  patientName: {
    fontSize: '16px',
    color: '#323232',
    textAlign: 'left',
    fontWeight: '600',
    paddingTop: theme.spacing(2),
  },
  emptyMessage: {
    textAlign: 'center',
  },
  stepper: {
    paddingBottom: theme.spacing(2),
    borderBottom: '1px solid #DCDBDC',
    marginBottom: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    '& .MuiStepLabel-root': {
      flexDirection: 'column-reverse',
    },
    '& .MuiStep-root': {
      padding: 0,
      flex: 'none',
    },
    '& .MuiStepConnector-root': {
      top: '52px',
      left: 'calc(-50% - 5px)',
      right: 'calc(50% + 13px)',
      zIndex: 1,
    },
  },
  statusIcon: {
    borderRadius: '25px',
    padding: theme.spacing(0.7),
    color: theme.palette.common.white,
    // border: '4px solid #ffffff00',
    margin: '4px',
    '&.not-available': {
      backgroundColor: '#5899AD',
    },
    '&.open': {
      backgroundColor: '#F79A48',
    },
    '&.expiring-soon': {
      backgroundColor: '#C00202',
    },
    '&.missed': {
      backgroundColor: '#A3A3A3',
    },
    '&.done': {
      backgroundColor: '#00A800',
    },
  },
  stepButton: {
    minWidth: '35px',
    padding: 0,
    margin: 0,
    zIndex: 2,
    '&.active': {
      '& svg': {
        margin: 0,
        border: '4px solid #fff',
        '&.not-available': {
          borderColor: '#EAF2F5',
        },
        '&.open': {
          borderColor: '#F6CEBC',
        },
        '&.expiring-soon': {
          borderColor: '#F6CEBC',
        },
        '&.missed': {
          borderColor: '#F1F1F1',
        },
        '&.done': {
          borderColor: '#B0E4B0',
        },
      },
    },
  },
  boxDetailsContainer: {},
  groupDetailsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: '4px',
    padding: '5px',
    margin: '5px 0',
  },
  bodyPartTitle: {
    margin: 0,
    maxWidth: '135px',
    marginRight: '5px',
    whiteSpace: 'break-spaces',
  },
  bodyPartDate: {
    fontWeight: 700,
    margin: 'auto 0',
    '&.not-available': {
      color: '#545454',
    },
    '&.open': {
      color: '#F79A48',
    },
    '&.expiring-soon': {
      color: '#C00202',
    },
    '&.missed': {
      color: '#A3A3A3',
    },
    '&.done': {
      color: '#00A800',
    },
  },
  activeBodyPart: {
    backgroundColor: '#EAF2F5',
  },
}))

const SurveyCompletionCD: React.FC<SurveyCompletionCDProps> = ({
  patientId,
  patientName,
  patientCaseId,
  // currentStatus,
  patient,
  bodyPart,
  onClose,
}) => {
  const { data: surveyCompletionList } = useSurveyCompletionCDStatus(
    patientId,
    patientCaseId,
  )
  const { data: openSurveys = [] } = useSurveyResponseGroupsAlt({
    patientId: patientId,
    patientCaseId: patientCaseId,
  })
  const classes = useStyles()
  const { localizedFormatDate } = useDates()
  const [activeStep, setActiveStep] = React.useState(0)
  const statusToComponent: Record<SurveyStatusText, ReactNode> = {
    done: (
      <Check className={`${classes.statusIcon} done`} size={14} fontSize={14} />
    ),
    missed: (
      <X className={`${classes.statusIcon} missed`} size={14} fontSize={14} />
    ),
    open: (
      <Clock className={`${classes.statusIcon} open`} size={14} fontSize={14} />
    ),
    expiring_soon: (
      <AlertCircle
        className={`${classes.statusIcon} expiring-soon`}
        size={14}
        fontSize={14}
      />
    ),
    'n/a': (
      <Loader
        className={`${classes.statusIcon} not-available`}
        size={14}
        fontSize={14}
      />
    ),
  }
  const indexToPeriod = surveyPeriodsOrdered
    .sort((a, b) => a.order - b.order)
    .filter(
      (per) =>
        per.value !== 0.5 &&
        Object.keys(surveyCompletionList?.surveys || {}).find(
          (surve) => surve === per.type,
        ),
    )
    .map((p) => p.type)

  const handleStep = (step: number) => () => {
    setActiveStep(step)
  }

  const statusDetails = (survey: SurveyStatusObject) => {
    const { groupItems, status } = survey
    const surveyLinks =
      status === 'open' || status === 'expiring_soon' ? (
        <PatientCDOpenSurveys openSurveys={openSurveys} patient={patient} />
      ) : undefined
    if (
      groupItems.length === 0 &&
      !survey.availableOn &&
      !survey.expiresOn &&
      !survey.group &&
      status === 'n/a'
    )
      return <div className={classes.emptyMessage}>No surveys available</div>
    return (
      <>
        <div className={classes.bodyPartListContainer}>
          <H5>Body Parts</H5>
          {groupItems.map((item) => {
            const { status, completedAt, expiresOn, availableOn, bodySite } =
              item
            let dateColorClassname = classes.bodyPartDate
            let dateText = ''
            let displayDate = new Date()
            let bodyPartItemClass = classes.groupDetailsContainer
            const groupLength = groupItems.length
            const archivedGroup = groupItems.filter(
              (group) => group.status === 'archived',
            )?.length
            if (bodyPart === bodySite && groupLength - archivedGroup > 1)
              bodyPartItemClass += ` ${classes.activeBodyPart}`
            switch (status) {
              case 'open':
                dateColorClassname += ' open'
                dateText = 'Complete before'
                displayDate = parseYMD(expiresOn)
                break
              case 'expiring_soon':
                dateColorClassname += ' expiring-soon'
                dateText = 'Expires on'
                displayDate = parseYMD(expiresOn)
                break
              case 'archived':
                dateText = 'Archived'
                break
              default:
                // not available
                if (completedAt) {
                  // done
                  dateColorClassname += ' done'
                  dateText = 'Completed on'
                  displayDate = parseYMD(completedAt)
                } else if (parseYMD(expiresOn) < startOfToday()) {
                  // missed
                  dateColorClassname += ' missed'
                  dateText = 'Expired on'
                  displayDate = parseYMD(expiresOn)
                } else if (parseYMD(availableOn) > endOfToday()) {
                  dateColorClassname += ' not-available'
                  dateText = 'Survey Opens'
                  displayDate = parseYMD(availableOn)
                }
                break
            }
            if (status === 'archived') return <></>
            return (
              <Box key={item.patientCaseId} className={bodyPartItemClass}>
                <p className={classes.bodyPartTitle}>
                  {/* {humanizeBodySite(item.bodySiteSlug)} */}
                  {item.bodySite}
                </p>
                <p className={dateColorClassname}>
                  {dateText} {localizedFormatDate(displayDate)}
                </p>
              </Box>
            )
          })}
        </div>
        <div className={classes.surveyLinksContainer}>{surveyLinks}</div>
      </>
    )
  }

  React.useEffect(() => {
    let newActiveStep = 0
    Object.keys(surveyCompletionList?.surveys || {}).map((label) => {
      const period = label as SurveyStatusPeriod
      if (
        surveyCompletionList?.surveys[period].status != 'n/a' &&
        indexToPeriod.indexOf(period) >= 0
      )
        newActiveStep = indexToPeriod.indexOf(period)
    })
    if (activeStep != newActiveStep) setActiveStep(newActiveStep)
  }, [surveyCompletionList?.surveys])

  return (
    <Box className={classes.containerBox}>
      <IconButton
        aria-label="close"
        onClick={onClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: '#767676',
        }}
      >
        <X size={16} />
      </IconButton>
      <H5 className={classes.boxTitle}>Survey Status</H5>
      <H5 className={classes.patientName}>{patientName}</H5>
      <Box>
        <Stepper
          activeStep={0}
          alternativeLabel
          nonLinear
          className={classes.stepper}
        >
          {surveyCompletionList &&
            indexToPeriod.map((label, index) => {
              const period = label as SurveyStatusPeriod
              let buttonClassName = classes.stepButton
              if (index === activeStep) buttonClassName += ` active`
              return (
                <Step key={index}>
                  <StepButton
                    color="inherit"
                    className={buttonClassName}
                    onClick={handleStep(index)}
                    icon={
                      statusToComponent[
                        surveyCompletionList.surveys[period]
                          .status as SurveyStatusText
                      ]
                    }
                  >
                    {capitalize(label)}
                  </StepButton>
                </Step>
              )
            })}
        </Stepper>
        <Box className={classes.boxDetailsContainer}>
          {surveyCompletionList &&
            statusDetails(
              surveyCompletionList.surveys[
                indexToPeriod[activeStep] as SurveyStatusPeriod
              ],
            )}
        </Box>
      </Box>
    </Box>
  )
}

export default SurveyCompletionCD
