import { FC } from 'react'
import {
  Autocomplete,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@perk-ui/core'
import { FormikErrors, FormikTouched, useFormik } from 'formik'
import { matchSorter } from 'match-sorter'

import { useSitesContext } from '../../../../components/SitesProvider'
import { periodTypes } from '../../../../config/constants'
import useMedicalTreatments, {
  MedicalTreatment,
} from '../../../../features/query-hooks/useMedicalTreatments'
import useProviders from '../../../../features/query-hooks/useProviders'
import useTreatmentProducts from '../../../../features/query-hooks/useTreatmentProducts'
import { buildName } from '../../../../utils/buildName'
import { TreatmentTypeNameCustomizations } from '../../../PatientFlow/Treatment/Treatment'

export interface TreatmentFormValues {
  /**
   * An id exists only for treatments that have previously been saved to the DB
   */
  id?: string
  isPrimary: boolean
  medicalTreatmentId: string
  order: number
  periodAmount: number
  periodType?: 'days' | 'weeks' | 'months' | 'years'
  siteLocationId?: string
  staffId?: string
  treatmentProductId?: string
  medicalTreatment?: MedicalTreatment
}

const useStyles = makeStyles((theme) => ({
  removeContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > *:first-child': {
      flex: 5,
    },
  },
  removeButton: {
    flex: 1,
    height: 'auto',
    marginLeft: theme.spacing(2),
  },
  severityButton: {
    color: theme.palette.primary.main,
  },
  severityButtonSelected: {
    color: theme.palette.common.white,
  },
  severityButtonGroup: {
    marginTop: theme.spacing(1),
  },
}))

export interface TreatmentFormProps {
  treatment: TreatmentFormValues
  setFieldValue: ReturnType<typeof useFormik>['setFieldValue']
  handleBlur: ReturnType<typeof useFormik>['handleBlur']
  touched: FormikTouched<TreatmentFormValues> | null
  errors: FormikErrors<TreatmentFormValues> | null | string
  index: number
  onRemove: () => Promise<void>
}

const TreatmentForm: FC<TreatmentFormProps> = ({
  treatment,
  setFieldValue,
  touched,
  handleBlur,
  errors,
  onRemove,
  index,
}) => {
  const classes = useStyles()
  const name = (label: string) => `treatments.${index}.${label}`
  const { currentSite } = useSitesContext()
  const { data: providers = [] } = useProviders()
  const { data: medicalTreatments = [] } = useMedicalTreatments()
  const { data: treatmentProducts = [] } = useTreatmentProducts(
    treatment.medicalTreatmentId,
  )

  const availableMedicalTreatmentOptions = medicalTreatments.filter(
    (treatment) => {
      return treatment.onlyForStudy == false
    },
  )
  const availableTreatmentProductOptions = treatmentProducts.filter(
    (product) => {
      return product.onlyForStudy == false
    },
  )

  return (
    <>
      <div className={classes.removeContainer}>
        <Autocomplete
          options={availableMedicalTreatmentOptions}
          getOptionLabel={(opt) =>
            opt.name in TreatmentTypeNameCustomizations
              ? TreatmentTypeNameCustomizations[opt.name]
              : opt.name
          }
          isOptionEqualToValue={(opt, val) => opt.id === val.id}
          filterOptions={(options, { inputValue }) =>
            inputValue
              ? matchSorter(options, inputValue, { keys: ['name'] })
              : options
          }
          value={
            availableMedicalTreatmentOptions.find(
              (treat) => treat.id === treatment.medicalTreatmentId,
            ) ?? null
          }
          onChange={(_event, treatment) => {
            setFieldValue(name('medicalTreatmentId'), treatment?.id || '')
          }}
          renderInput={(params) => {
            // @ts-expect-error Mui's autocomplete types here are incomplete
            params.InputLabelProps.shrink = true
            const label =
              index === 0 ? 'Primary Treatment' : 'Additional Treatment'
            const innerErrors =
              errors as FormikErrors<TreatmentFormValues> | null
            return (
              <TextField
                {...params}
                label={label}
                name={name('medicalTreatmentId')}
                required
                value={treatment.medicalTreatmentId}
                onBlur={handleBlur}
                error={
                  touched?.medicalTreatmentId &&
                  Boolean(innerErrors?.medicalTreatmentId)
                }
                helperText={
                  touched?.medicalTreatmentId && innerErrors?.medicalTreatmentId
                }
              />
            )
          }}
        />
        {index !== 0 && (
          <Button
            className={classes.removeButton}
            variant="outlined"
            onClick={onRemove}
          >
            Remove
          </Button>
        )}
      </div>
      <Autocomplete
        options={availableTreatmentProductOptions}
        getOptionLabel={(opt) => opt.name}
        isOptionEqualToValue={(opt, val) => opt.id === val.id}
        filterOptions={(options, { inputValue }) =>
          inputValue
            ? matchSorter(options, inputValue, { keys: ['name'] })
            : options
        }
        value={
          treatmentProducts.find(
            (prod) => prod.id === treatment.treatmentProductId,
          ) ?? null
        }
        onChange={(_event, prov) => {
          setFieldValue(name('treatmentProductId'), prov?.id || '')
        }}
        renderInput={(params) => {
          // @ts-expect-error Mui's autocomplete types here are incomplete
          params.InputLabelProps.shrink = true
          const innerErrors = errors as FormikErrors<TreatmentFormValues> | null
          return (
            <TextField
              {...params}
              name="treatmentProductId"
              label="Product Name"
              onBlur={handleBlur}
              required
              value={treatment.treatmentProductId}
              error={
                touched?.treatmentProductId &&
                Boolean(innerErrors?.treatmentProductId)
              }
              helperText={
                touched?.treatmentProductId && innerErrors?.treatmentProductId
              }
            />
          )
        }}
      />
      {currentSite && currentSite.siteLocations.length > 1 && (
        <FormControl
          style={{ width: '100%' }}
          error={typeof errors !== 'string' && !!errors?.siteLocationId}
        >
          <InputLabel id="site-location-label" required>
            Clinic Location
          </InputLabel>
          <Select
            labelId="site-location-label"
            placeholder="Select"
            value={treatment.siteLocationId}
            fullWidth
            required
            onChange={(event) => {
              setFieldValue(name('siteLocationId'), event.target.value)
            }}
          >
            {currentSite?.siteLocations.map((location) => (
              <MenuItem key={location.id} value={location.id}>
                {location.name}
              </MenuItem>
            ))}
          </Select>
          {typeof errors !== 'string' && errors?.siteLocationId && (
            <FormHelperText error>{errors?.siteLocationId}</FormHelperText>
          )}
        </FormControl>
      )}
      <FormControl
        style={{ width: '100%' }}
        error={typeof errors !== 'string' && !!errors?.staffId}
      >
        <InputLabel id="provider-label" required>
          Provider
        </InputLabel>
        <Select
          labelId="provider-label"
          placeholder="Select"
          value={treatment.staffId}
          fullWidth
          required
          onChange={(event) => {
            setFieldValue(name('staffId'), event.target.value)
          }}
        >
          {providers.map((prov) => (
            <MenuItem key={prov.id} value={prov.id}>
              {buildName(prov)}
            </MenuItem>
          ))}
        </Select>
        {typeof errors !== 'string' && errors?.staffId && (
          <FormHelperText error>{errors?.staffId}</FormHelperText>
        )}
      </FormControl>
      {index !== 0 && (
        <Grid container spacing={2}>
          <Grid item xs={9}>
            <TextField
              label="Period Amount"
              name="periodAmount"
              fullWidth
              required
              value={treatment.periodAmount}
              onChange={(event) => {
                setFieldValue(name('periodAmount'), event.target.value)
              }}
              onBlur={handleBlur}
              error={
                typeof errors !== 'string' &&
                touched?.periodAmount &&
                Boolean(errors?.periodAmount)
              }
              helperText={
                typeof errors !== 'string' &&
                touched?.periodAmount &&
                errors?.periodAmount
              }
            />
          </Grid>
          <Grid item xs={3}>
            <FormControl
              style={{ width: '100%' }}
              error={typeof errors !== 'string' && !!errors?.periodType}
            >
              <InputLabel id="period-type-label" required>
                Period Type
              </InputLabel>
              <Select
                labelId="period-type-label"
                placeholder="Select"
                value={treatment.periodType}
                fullWidth
                required
                onChange={(event) => {
                  setFieldValue(name('periodType'), event.target.value)
                }}
              >
                {periodTypes.map((type) => (
                  <MenuItem key={type.value} value={type.value}>
                    {type.label}
                  </MenuItem>
                ))}
              </Select>
              {typeof errors !== 'string' && errors?.periodType && (
                <FormHelperText error>{errors?.periodType}</FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default TreatmentForm
