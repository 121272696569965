import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Pagination } from './types'
import { BodySite } from './useBodySites'
import { MedicalCondition } from './useMedicalConditions'
import { MedicalTreatment } from './useMedicalTreatments'
import { Patient } from './usePatient'
import { Provider } from './useProviders'
import { Study } from './useStudies'

export interface PatientListSearchResponse {
  patients: Patient[]
  meta: PatientListCDMeta
}

interface PatientListCDMeta extends Pagination {
  bodySites?: BodySite[]
  medicalConditions?: MedicalCondition[]
  medicalTreatments?: MedicalTreatment[]
  providers?: Provider[]
  studies?: Study[]
  missingTreatmentsCount?: number
  missingBaselineSurveysCount?: number
  missingFollowupSurveysCount?: number
  surveyStatuses: SurveyStatus[]
  surveyPeriods: SurveyStatus[]
}
interface SurveyStatus {
  id: string
  slug: string
  name: string
}
interface PatientCDSearchParams {
  search: string
  bodySites?: string
  diagnoses?: string
  treatments?: string
  providers?: string
  studies?: string
  surveyStatuses?: string
  surveyPeriods?: string
  missingDiagnoses?: boolean
  missingTreatments?: boolean
  missingBaselineSurveys?: boolean
  filterToggle?: string
  dashboardToggle?: boolean
  page?: number
  perPage?: number
}

const usePatientSearch = (
  {
    search,
    page = 1,
    perPage = 20,
    bodySites,
    diagnoses,
    treatments,
    studies,
    providers,
    surveyStatuses,
    surveyPeriods,
    missingDiagnoses,
    missingTreatments,
    missingBaselineSurveys,
    filterToggle,
    dashboardToggle,
  }: PatientCDSearchParams,
  extraOptions: UseQueryOptions<PatientListSearchResponse> = {},
) => {
  const currentSiteId = useCurrentSite()?.id

  const key = QueryKeys.patientsCDSearch({
    currentSiteId,
    search,
    bodySites,
    diagnoses,
    treatments,
    providers,
    studies,
    surveyStatuses,
    surveyPeriods,
    missingDiagnoses,
    missingTreatments,
    missingBaselineSurveys,
    filterToggle,
    page,
    perPage,
  })
  const url = '/sites/patients/adv_search'

  return useQuery(
    key,
    () =>
      request
        .post<PatientListSearchResponse>(url, {
          siteIds: [currentSiteId],
          search,
          // If these fields are empty strings, set them to undefined so we don't send them
          bodySites: bodySites || undefined,
          diagnoses: diagnoses || undefined,
          treatments: treatments || undefined,
          studies: studies || undefined,
          providers: providers || undefined,
          surveyStatuses: surveyStatuses || undefined,
          surveyPeriods: surveyPeriods || undefined,
          filterToggle: filterToggle || undefined,
          dashboardToggle: dashboardToggle || undefined,
          // missingDiagnoses: missingDiagnoses ? missingDiagnoses : undefined,
          // missingTreatments: missingTreatments ? missingTreatments : undefined,
          // missingBaselineSurveys: missingBaselineSurveys
          //   ? missingBaselineSurveys
          //   : undefined,
          page,
          perPage,
        })
        .then((r) => r.data),
    {
      keepPreviousData: true,
      ...extraOptions,
      enabled: Boolean(currentSiteId),
    },
  )
}

export default usePatientSearch
