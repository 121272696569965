import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export interface AnatomicalLocation {
  id: string
  name: string // 'Supraspinatus'
  slug: string // 'supraspinatus'
}

export interface AnatomicalLocationsResponse {
  anatomicalLocations: AnatomicalLocation[]
}

const useAnatomicalLocations = (
  medicalConditionId?: string,
  extraOptions: UseQueryOptions<AnatomicalLocation[]> = {},
) => {
  const siteId = useCurrentSite().id
  const key = medicalConditionId
    ? QueryKeys.anatomicalLocations(medicalConditionId)
    : []
  const url = `/sites/anatomical_locations?site_ids[]=${siteId}&medical_condition_id=${medicalConditionId}`

  return useQuery(
    key,
    () =>
      request
        .get<AnatomicalLocationsResponse>(url)
        .then((r) => r.data.anatomicalLocations),
    {
      // AnatomicalLocations are static - don't refresh them
      staleTime: Number.POSITIVE_INFINITY,
      enabled: Boolean(medicalConditionId),
      ...extraOptions,
    },
  )
}

export default useAnatomicalLocations
