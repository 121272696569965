import { FC, MouseEvent, useState } from 'react'
import {
  Button,
  Divider,
  Icon,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Menu,
  MenuItem,
} from '@perk-ui/core'
import { LogOut, User } from 'react-feather'

import { useAuth, useUser } from '../features/auth/AuthContext'
import UserSettingsModal from './UserSettingsModal'

export interface ProfileDropdownProps {}
const useStyles = makeStyles((theme) => ({
  menuContainer: {
    '& .MuiMenu-list': {
      padding: theme.spacing(1),
    },
  },
}))

const ProfileDropdown: FC<ProfileDropdownProps> = () => {
  const { signOut } = useAuth()
  const classes = useStyles()
  const user = useUser()
  const [anchorEl, setAnchorEl] = useState<null | HTMLButtonElement>(null)
  const [userSettingsModal, setUserSettingsModal] = useState(false)
  const isOpen = Boolean(anchorEl)
  const menuId = 'user-profile-dropdown-id'

  const handleOpen = (evt: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(evt.currentTarget)
  }

  const handleSelect = () => {
    setAnchorEl(null)
  }

  const handleLogout = () => {
    setAnchorEl(null)
    signOut()
  }

  return (
    <>
      <Button
        id="user-profile-dropdown-button"
        variant="text"
        size="medium"
        aria-haspopup="true"
        onClick={handleOpen}
        aria-controls={menuId}
        startIcon={
          <Icon>
            <User />
          </Icon>
        }
      >
        {user?.staff.firstName}
      </Button>
      <Menu
        id={menuId}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={isOpen}
        onClose={() => handleSelect()}
        keepMounted
        className={classes.menuContainer}
      >
        <MenuItem
          id="user-settings-menu-item-button"
          onClick={() => setUserSettingsModal(true)}
        >
          <ListItemIcon>
            <User />
          </ListItemIcon>
          <ListItemText title={'User Settings'} />
        </MenuItem>
        <Divider variant="middle" />
        <MenuItem id="log-out-menu-item-button" onClick={() => handleLogout()}>
          <ListItemIcon>
            <LogOut />
          </ListItemIcon>
          <ListItemText title={'Sign Out'} />
        </MenuItem>
      </Menu>
      <UserSettingsModal
        open={userSettingsModal}
        setOpen={setUserSettingsModal}
        user={user}
        setAnchorEl={setAnchorEl}
      />
    </>
  )
}

export default ProfileDropdown
