import { FC } from 'react'
import {
  Box,
  Button,
  DialogTrigger,
  Divider,
  H1,
  H3,
  H5,
  Icon,
  IconButton,
  makeStyles,
  TextField,
} from '@perk-ui/core'
import { AxiosError } from 'axios'
import { useFormik } from 'formik'
import { ArrowLeft } from 'react-feather'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import AppLinkButton from '../../../components/AppLinkButton'
import useDeleteProtocolDiagnosis from '../../../features/query-hooks/useDeleteProtocolDiagnosis'
import useDeleteProtocolTreatment from '../../../features/query-hooks/useDeleteProtocolTreatment'
import useProtocol from '../../../features/query-hooks/useProtocol'
import useUpdateProtocol from '../../../features/query-hooks/useUpdateProtocol'
import { useHistory } from '../../../utils/useHistory'
import ProtocolFlowUrls, {
  ProtocolFlowLocationParams,
} from '../ProtocolFlowUrls'
import DiagnosisDetail from './DiagnosisDetail'
import TreatmentDetail from './TreatmentDetail'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  headerIcon: {
    marginRight: theme.spacing(1),
  },
  archiveButton: {
    cursor: 'pointer',
  },
  dialogContent: {
    '& > p': {
      textAlign: 'start',
    },
  },
  dialogButtons: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'center',
    '& button+button': {
      marginLeft: theme.spacing(5),
    },
  },
  form: {
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '& > .MuiDivider-root': {
      marginBottom: theme.spacing(3),
    },
    '& .MuiAutocomplete-input': {
      maxWidth: '100%',
    },
  },
  protocolStateButton: {
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    minHeight: 40,
    padding: theme.spacing(1, 3),
  },
  protocolCancelButton: {
    fontSize: '14px',
    fontWeight: 400,
    minHeight: 40,
    padding: theme.spacing(1, 3),
  },
  modalContainer: {
    padding: theme.spacing(2, 4),
    width: '100%',
  },
  protocolStateTitle: {
    textAlign: 'center',
    color: '#000',
    fontWeight: 700,
    fontSize: '32px',
    marginBottom: theme.spacing(1),
  },
  protocolStateText: {
    textAlign: 'center',
    color: '#000',
    fontWeight: 400,
    fontSize: '16px',
    marginBottom: theme.spacing(2),
  },
}))

export interface PreviewProps {}
interface ProtocolFormValues {
  name: string
}

const Treatment: FC<PreviewProps> = () => {
  const { goBack, push } = useHistory()
  const classes = useStyles()

  const hasProtocolMatch = useRouteMatch<ProtocolFlowLocationParams>(
    ProtocolFlowUrls.hasProtocol.match,
  )
  const protocolId = hasProtocolMatch ? hasProtocolMatch.params.protocolId : ''
  const hasProtocol = !!hasProtocolMatch
  const { data: protocol } = useProtocol(protocolId, {
    enabled: hasProtocol,
  })
  const deleteDiagnosis = useDeleteProtocolDiagnosis()
  const deleteTreatment = useDeleteProtocolTreatment()
  const updateProtocol = useUpdateProtocol()
  const protocolValidations = yup.object({
    name: yup.string(),
  })
  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    // setFieldValue,
    setFieldError,
    // setFieldTouched,
    isSubmitting,
    // isValid,
    touched,
    // Ideally, useFormik should typed with `PatientAndCaseFormValues | ProtocolFormValues`
    // but that causes a lot of type headaches elsewhere, and this is reasonably good.
  } = useFormik<ProtocolFormValues>({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues: {
      name: protocol?.name || '',
    },
    validationSchema: protocolValidations,
    onSubmit: (values) => {
      // Trim whitespace from all values
      Object.keys(values).forEach((key) => {
        const k = key as keyof ProtocolFormValues
        if (typeof values[k] === 'string') {
          ;(values[k] as string).trim()
        }
      })
      updateProtocol
        .mutateAsync({
          id: protocolId,
          slug:
            values.name.toLowerCase().replace(/\s/g, '-') +
            '-' +
            new Date().getTime(),
          status: 'active',
          ...values,
        } as ProtocolFormValues)
        .then((protocol) => {
          if (protocol.id) {
            push('/home')
          }
        })
        .catch((err) => {
          if (err?.isAxiosError) {
            const error = err as AxiosError
            if (error.response?.status === 422) {
              setFieldError('name', 'Error ocurred, please try again')
            }
          }
        })
    },
  })
  return (
    <>
      <div className={classes.header}>
        <IconButton
          className={classes.headerIcon}
          onClick={() => {
            goBack()
          }}
          size="large"
        >
          <Icon size="x-large">
            <ArrowLeft />
          </Icon>
        </IconButton>
        <H1>
          {protocol?.type === 'FullProtocol'
            ? 'Full Protocol'
            : 'Treatment Protocol'}
        </H1>
      </div>
      {protocol?.protocolDiagnoses &&
        protocol?.protocolDiagnoses.map((diagnosis) => {
          return (
            <Box key={diagnosis.id} pb={2} pt={1}>
              <DiagnosisDetail
                diagnosis={diagnosis}
                onEditClick={() => {
                  push(
                    ProtocolFlowUrls.diagnosis.create(protocolId, diagnosis.id),
                  )
                }}
                onDeleteClick={async () => {
                  if (diagnosis.id) {
                    await deleteDiagnosis.mutateAsync(diagnosis.id)
                  }
                }}
              />
              <Divider />
            </Box>
          )
        })}
      {protocol?.protocolTreatments &&
        protocol?.protocolTreatments.map((treatment, index) => {
          return (
            <Box key={treatment.id} pb={2} pt={1}>
              <TreatmentDetail
                treatment={treatment}
                onEditClick={() => {
                  push(
                    ProtocolFlowUrls.treatment.create(protocolId, treatment.id),
                  )
                }}
                onDeleteClick={async () => {
                  if (treatment.id) {
                    await deleteTreatment.mutateAsync(treatment.id)
                  }
                }}
              />
              {index + 1 !== protocol?.protocolTreatments?.length && (
                <Divider />
              )}
            </Box>
          )
        })}
      {protocol?.status === 'inactive' && (
        <DialogTrigger
          action={<Button fullWidth>Save</Button>}
          content={(toggle) => {
            return (
              <Box className={classes.modalContainer}>
                <H3 className={classes.protocolStateTitle}>Name Protocol</H3>
                <H5 className={classes.protocolStateText}>
                  Name your protocol so you can use it later
                </H5>
                <form className={classes.form} onSubmit={handleSubmit}>
                  <TextField
                    label="Name"
                    name="name"
                    fullWidth
                    value={values.name}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                  <Button
                    type="submit"
                    fullWidth
                    className={classes.protocolStateButton}
                    loading={isSubmitting}
                    // disabled={!isValid}
                  >
                    Save
                  </Button>

                  <Button
                    variant="text"
                    fullWidth
                    className={classes.protocolCancelButton}
                    loading={isSubmitting}
                    onClick={() => {
                      toggle()
                    }}
                  >
                    Cancel
                  </Button>
                </form>
              </Box>
            )
          }}
        />
      )}
      {protocol?.name !== 'Protocol Default Name' && (
        <AppLinkButton
          fullWidth
          className={classes.protocolCancelButton}
          to={'/home'}
        >
          Done
        </AppLinkButton>
      )}
    </>
  )
}

export default Treatment
