import { useQuery } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { PatientCaseResponse } from './usePatientCases'

const useUpcomingDashboard = () => {
  const currentSiteId = useCurrentSite()?.id || ''
  const url = `/sites/dashboard/upcoming?site_ids[]=${currentSiteId}`
  const key = QueryKeys.upcomingDashboard(currentSiteId)

  return useQuery(key, () =>
    request.get<PatientCaseResponse>(url).then((r) => r.data.patientCases),
  )
}

export default useUpcomingDashboard
