/**
 * Capitalize a string!
 */
export const capitalize = (str) => str.length > 0 ? str[0].toUpperCase() + str.slice(1) : '';
/**
 * Format a phone string/number!
 * @examples
 * toPrettyPhoneString('+12345678900') // => "+1 (234) 567-8900"
 * toPrettyPhoneString('2345678900')   // => "(234) 567-8900"
 *
 * @returns a string that looks like (111) 222-3333, potentially with a leading +1
 */
export const formatPhoneNumber = (phone) => {
    const cleaned = String(phone).replace(/\D/g, '');
    const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        const intlCode = match[1] ? '+1 ' : '';
        return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
    }
    else if (!cleaned) {
        return '';
    }
    else {
        return `Invalid format: ${phone}`;
    }
};
