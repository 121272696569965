import { useMutation } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'

const useSendEmailContract = () => {
  const siteId = useCurrentSite()?.id || ''
  const url = `/sites/email_contracts?site_ids[]=${siteId}`

  return useMutation((email: string) =>
    request
      .post(url, {
        email,
      })
      .then((r) => {
        return r.data
      }),
  )
}

export default useSendEmailContract
