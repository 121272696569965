import { useMutation, useQueryClient } from 'react-query'

// import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

interface ApplyCouponResponse {
  subscription: CouponResponse
}

export interface CouponResponse {
  clientSecret: string
  paymentLink: string
  subscription: string
}

interface ApplyCouponVars {
  coupon: string
  subscriptionId: string
}

const useApplyCoupon = () => {
  const queryClient = useQueryClient()
  // const siteId = useCurrentSite().id

  return useMutation((vars: ApplyCouponVars) => {
    const url = `/sites/coupon`
    return request
      .post<ApplyCouponResponse>(url, {
        coupon: vars.coupon,
        subscriptionId: vars.subscriptionId,
      })
      .then((response) => {
        queryClient.invalidateQueries(QueryKeys.subscription())
        queryClient.invalidateQueries(QueryKeys.unauthenticatedSubscription())
        return response.data.subscription
      })
  })
}

export default useApplyCoupon
