import { FC, useState } from 'react'
import {
  Alert,
  Body1,
  Collapse,
  H3,
  IconButton,
  Link,
  makeStyles,
  Tooltip,
} from '@perk-ui/core'
import { AlertCircle, RefreshCw, X } from 'react-feather'

import { Patient } from '../features/query-hooks/usePatient'
import useUpdatePatientPhoneData from '../features/query-hooks/useUpdatePatientPhoneData'
import useDates from '../hooks/useDates'
import { parseYMD } from '../utils/dates'
import { feetToInches, inchesToMeters, lbsToKgs } from '../utils/unitConversion'
import DataPair from './DataPair'
import { useSitesContext } from './SitesProvider'

export interface PatientInfoProps {
  patient: Patient
}

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  columnContainer: {
    marginRight: theme.spacing(4),
    // flexGrow: 1,
  },
  columnContainer2: {
    // marginRight: theme.spacing(4),
  },
  cardTitle: {
    marginBottom: theme.spacing(2),
  },
  text: {
    marginBottom: theme.spacing(1),
  },
  archiveText: {
    color: theme.palette.error.main,
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
  },
  phoneDescription: {
    display: 'inline-flex',
    marginBottom: theme.spacing(0.5),
    alignItems: 'center',
    '& svg': {
      marginLeft: theme.spacing(0.5),
    },
    '& p': {
      marginBottom: 0,
    },
  },
  revalidateIcon: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  disabledRevalidateIcon: {
    pointerEvents: 'none',
    color: '#919191',
  },
  updatePhoneDataAlert: {
    marginBottom: '4px',
    paddingTop: '2px',
    paddingBottom: '2px',
    alignItems: 'center',
    '& .MuiAlert-message': {
      padding: 0,
    },
    '& .MuiAlert-icon': {
      padding: 0,
      fontSize: '16px',
    },
    '& .MuiAlert-action': {
      padding: 0,
    },
  },
}))

const PatientInfo: FC<PatientInfoProps> = ({ patient }) => {
  const { currentSite } = useSitesContext()
  const { localizedFormatDate } = useDates()
  const { localizedFormatDate: localizedFormatDateWithTime } = useDates(true)
  const updatePatientPhoneData = useUpdatePatientPhoneData()
  const [updatePhoneDataAlert, setUpdatePhoneDataAlert] = useState({
    open: false,
    type: 'success',
    text: 'Phone data updated!',
  })

  const country = currentSite?.country
  const isCanada = country === 'canada'
  const classes = useStyles()
  let phoneErrorText = ''
  if (patient.isOptedOutPhone && !patient.isValidPhone) {
    phoneErrorText =
      "Phone number can't receive SMS messages and has also opted-out"
  } else if (!patient.isValidPhone) {
    phoneErrorText = "Phone number can't receive SMS messages"
  } else if (patient.isOptedOutPhone) {
    phoneErrorText = 'Phone number has opted-out for SMS messages'
  }
  const revalidatePhone = () => {
    updatePatientPhoneData
      .mutateAsync({
        id: patient.id,
      })
      .then((result) => {
        if (result.patient.id) {
          setUpdatePhoneDataAlert({
            open: true,
            type: 'success',
            text: 'Phone data updated!',
          })
        }
      })
      .catch(() => {
        setUpdatePhoneDataAlert({
          open: true,
          type: 'error',
          text: 'Error updating phone data',
        })
      })
  }

  return (
    <>
      <H3 className={classes.cardTitle}>Patient Info</H3>
      {patient.status !== 'active' && (
        <Body1 className={classes.archiveText}>
          Archived: {patient.archivalOccurrence?.archivalReason.description}
        </Body1>
      )}
      <div className={classes.rowContainer}>
        <div className={classes.columnContainer}>
          {patient?.medicalRecordNumber && (
            <DataPair label="MRN" value={patient.medicalRecordNumber} />
          )}
          <DataPair
            label="DOB"
            value={localizedFormatDate(parseYMD(patient?.dateOfBirth))}
          />
          {patient?.biologicalSex && (
            <DataPair
              label="Sex"
              value={
                patient.biologicalSex[0].toUpperCase() +
                patient.biologicalSex.slice(1)
              }
            />
          )}
          {patient?.weight && (
            <DataPair
              label="Weight"
              value={
                isCanada
                  ? `${lbsToKgs(patient.weight).toFixed(1)}kg`
                  : `${patient.weight}lbs`
              }
            />
          )}
          {patient !== undefined && patient?.bmiScore && (
            <DataPair
              label="Height"
              value={
                isCanada
                  ? `${inchesToMeters(
                      feetToInches(patient.heightFeet) +
                        (patient.heightInches || 0),
                    ).toFixed(2)}m`
                  : `${patient.heightFeet}'${Number(
                      patient?.heightInches,
                    ).toFixed(0)}"`
              }
            />
          )}
          {patient?.bmiScore && (
            <DataPair label="BMI" value={patient.bmiScore} />
          )}
          {patient?.updatedAt && (
            <DataPair
              label="Last Update"
              value={localizedFormatDateWithTime(new Date(patient.updatedAt))}
            />
          )}
        </div>
        <div className={classes.columnContainer2}>
          {patient?.cellPhoneLocal && (
            <div className={classes.phoneDescription}>
              <DataPair
                label="Phone"
                value={`${
                  formatPhoneNumber(patient.cellPhoneLocal) || 'None'
                } ${
                  patient.phoneData?.phoneType
                    ? ` [${patient.phoneData.phoneType}]`
                    : ''
                }`}
              />
              <Tooltip title={'Revalidate phone number'} arrow placement="top">
                <RefreshCw
                  className={
                    updatePhoneDataAlert.open
                      ? classes.disabledRevalidateIcon
                      : classes.revalidateIcon
                  }
                  size={16}
                  onClick={revalidatePhone}
                />
              </Tooltip>
              {(!patient.isValidPhone || patient.isOptedOutPhone) && (
                <Tooltip title={phoneErrorText} arrow placement="top">
                  <AlertCircle size={16} className={classes.errorIcon} />
                </Tooltip>
              )}
            </div>
          )}
          <Collapse in={updatePhoneDataAlert.open}>
            <Alert
              variant="outlined"
              className={classes.updatePhoneDataAlert}
              action={
                <IconButton
                  aria-label="close"
                  size="small"
                  onClick={() => {
                    setUpdatePhoneDataAlert({
                      ...updatePhoneDataAlert,
                      open: false,
                    })
                  }}
                >
                  <X size={14} />
                </IconButton>
              }
              sx={{ mb: 2 }}
              severity={updatePhoneDataAlert.type as 'success' | 'error'}
            >
              {updatePhoneDataAlert.text}
            </Alert>
          </Collapse>
          {patient?.email && (
            <DataPair
              label="Email"
              value={
                <Link
                  href={`mailto:${patient.email}`}
                  underline="hover"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {patient.email}
                </Link>
              }
            />
          )}
        </div>
      </div>
    </>
  )
}

export const formatPhoneNumber = (phoneNumber: string) => {
  const cleaned = ('' + phoneNumber).replace(/\D/g, '')
  const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
  if (match) {
    return '(' + match[1] + ') ' + match[2] + '-' + match[3]
  }
  return null
}

export default PatientInfo
