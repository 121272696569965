import { createContext, FC, useContext, useEffect } from 'react'
import { useStorage } from '@perk-ui/core'
import { useLocation } from 'react-router-dom'

import { useIsAuthenticated } from '../features/auth/AuthContext'
import useSitesAvailable, {
  Site,
} from '../features/query-hooks/useSitesAvailable'
import { useHistory } from '../utils/useHistory'

interface SitesState {
  currentSite: Site | null // TODO update the currentSite type to be not-nullable
  setCurrentSite: (site: Site) => void
  sitesQuery: ReturnType<typeof useSitesAvailable>
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const SitesContext = createContext<SitesState>(undefined as any)
export const useSitesContext = () => useContext(SitesContext)
export const useCurrentSite = () => useContext(SitesContext).currentSite as Site

const SitesProvider: FC = ({ children }) => {
  const isAuthenticated = useIsAuthenticated()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const sitesQuery = useSitesAvailable({ enabled: isAuthenticated })
  const [currentSite, setCurrentSite] = useStorage<Site | null>(
    'localStorage',
    'currentSite',
    null,
  )

  // If the currentSite is no longer in our list of available Sites, unset the currentSite
  useEffect(() => {
    if (sitesQuery.data?.length) {
      if (currentSite) {
        const currentSiteIsAvailable = sitesQuery.data.some(
          (site) => site.id === currentSite.id,
        )
        if (currentSiteIsAvailable) {
          const currentAvailableSite = sitesQuery.data.find(
            (site) => site.id === currentSite.id,
          )
          if (currentAvailableSite && currentSite !== currentAvailableSite) {
            setCurrentSite(currentAvailableSite)
          }
        } else {
          setCurrentSite(sitesQuery.data[0])
        }
      } else {
        setCurrentSite(sitesQuery.data[0])
      }
    }
  }, [currentSite, sitesQuery.data])

  if (sitesQuery.data && !sitesQuery.data.length && isAuthenticated) {
    // throw new Error('Logged-in User does not belong to any clinic sites.')
    // Need to check if we should redirect to onboarding
    if (pathname !== '/onboarding/clinic-setup')
      push('/onboarding/clinic-setup')
  }

  const contextValue: SitesState = {
    currentSite,
    setCurrentSite,
    sitesQuery,
  }

  return (
    <SitesContext.Provider value={contextValue}>
      {children}
    </SitesContext.Provider>
  )
}

export default SitesProvider
