import { FC, useEffect, useState } from 'react'
import {
  Autocomplete,
  Body1,
  Box,
  Button,
  DatePicker,
  DialogTrigger,
  Divider,
  H5,
  Link,
  makeStyles,
  PhoneField,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@perk-ui/core'
import { AxiosError } from 'axios'
import {
  addDays,
  addMonths,
  addWeeks,
  addYears,
  differenceInYears,
  endOfYesterday,
  format,
  getYear,
  isFuture,
  isPast,
  isToday,
} from 'date-fns'
import { useFormik } from 'formik'
import { matchSorter } from 'match-sorter'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import { formatPhoneNumber } from '../../../components/PatientInfo'
import { useSitesContext } from '../../../components/SitesProvider'
import useAnatomicalLocations, {
  AnatomicalLocation,
} from '../../../features/query-hooks/useAnatomicalLocations'
import useCreateDiagnosis, {
  PatientDiagnosisSeverity,
} from '../../../features/query-hooks/useCreateDiagnosis'
import useCreateTreatment from '../../../features/query-hooks/useCreateTreatment'
import useInvitePatient from '../../../features/query-hooks/useInvitePatient'
import usePatient from '../../../features/query-hooks/usePatient'
import useProtocols, {
  Protocol,
} from '../../../features/query-hooks/useProtocols'
import useSpecialtyBodySites, {
  BodySite,
} from '../../../features/query-hooks/useSpecialtyBodySites'
import useStudies, { Study } from '../../../features/query-hooks/useStudies'
import useUpdatePatient from '../../../features/query-hooks/useUpdatePatient'
import useDates from '../../../hooks/useDates'
import { parseYMD } from '../../../utils/dates'
import { useHistory } from '../../../utils/useHistory'
import ArchiveModal from '../components/ArchiveModal'
import PatientFlowUrls, { PatientFlowLocationParams } from '../PatientFlowUrls'

const useStyles = makeStyles((theme) => ({
  form: {
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      paddingBottom: theme.spacing(2),
    },
  },
  archiveButton: {
    cursor: 'pointer',
  },
  dialogContent: {
    '& > p': {
      textAlign: 'start',
    },
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
}))

interface PatientFormValues {
  firstName: string
  lastName: string
  dateOfBirth: string | Date
  cellPhoneLocal: string
  email: string
  medicalRecordNumber: string
}
type PatientFormValuesKeys =
  | 'firstName'
  | 'lastName'
  | 'dateOfBirth'
  | 'cellPhoneLocal'
  | 'email'
  | 'medicalRecordNumber'

interface CaseFormValues {
  bodySiteIds: string[]
  anticipatedTreatmentDate: string
  studyId: string
  // patientStudyNumber: string
  enrollment: string
  protocol?: Protocol
}

type PatientAndCaseFormValues = PatientFormValues & CaseFormValues
type PatientAndCaseFormValuesDateString = Omit<
  PatientAndCaseFormValues,
  'dateOfBirth'
> & { dateOfBirth: string }

// TODO Devise some way to disable browser autocomplete
// @see https://gist.github.com/niksumeiko/360164708c3b326bd1c8#gistcomment-3748492
const Invite: FC = () => {
  const classes = useStyles()
  const { push } = useHistory()
  const patientEditMatch = useRouteMatch<PatientFlowLocationParams>(
    PatientFlowUrls.editPatient.match,
  )
  const patientId = patientEditMatch?.params.patientId
  const isEditing = Boolean(patientEditMatch && patientId)
  const { data: bodySites = [] } = useSpecialtyBodySites()
  const { data: studies = [] } = useStudies()
  const invitePatient = useInvitePatient()
  const updatePatient = useUpdatePatient()
  const { currentSite } = useSitesContext()
  const { data: protocols } = useProtocols('FullProtocol')
  const noneStudy = studies.find((s) => s.slug === 'none')
  const createDiagnosis = useCreateDiagnosis()
  const createTreatment = useCreateTreatment()
  const [protocolAnatomicalLocations, setProtocolAnatomicalLocations] =
    useState<AnatomicalLocation[]>([])
  const { localizedFormatDate, localizedParseDate, localizedDatePattern } =
    useDates()
  const [bodySitesOptions, setBodySitesOptions] = useState<BodySite[]>([])

  const country = currentSite?.country
  const isCanada = country === 'canada'

  const { data: initialValues } = usePatient(patientId || '', {
    enabled: Boolean(isEditing && patientId),
  })

  const patientValidations = yup.object({
    firstName: yup.string().defined('First name is required'),
    lastName: yup.string().defined('Last name is required'),
    dateOfBirth: yup
      .string()
      .defined('Birthdate is required')
      .test(
        'is-valid-date',
        `Date of birth must be in format ${localizedDatePattern.toLowerCase()} and a valid date`,
        (value = '') => !!localizedParseDate(value),
      )
      .test(
        'is-before-now',
        'Date of birth must be before today',
        (value = '') => {
          const asDate = localizedParseDate(value)
          return asDate && isPast(asDate) && !isToday(asDate)
        },
      )
      .test('is-after-year-1900', 'Year must be after 1900', (value = '') => {
        const asDate = localizedParseDate(value)
        return asDate && getYear(asDate) >= 1900
      }),
    cellPhoneLocal: yup
      .string()
      .transform((value) => value.replace(/[()-\s]/g, '')) // strip parenthesis, space, and dash
      .length(10, 'Cell phone should be 10 numbers')
      .matches(/^[0-9]{10}$/, 'Please check the cell phone')
      .defined('Cell phone is required to send assessments'),
    email: yup
      .string()
      .email('Ensure the email is formatted correctly')
      .when('studyId', {
        is: (studyId: string) => {
          const selectedStudy = studies.find((study) => study.id == studyId)
          return (
            selectedStudy?.hasEconsent ||
            selectedStudy?.slug ==
              'platelet-rich-plasma-and-the-effects-of-nsaids-on-pain-and-functional-scores-in-knee-osteoarthritis'
          )
        },
        then: yup.string().required('Email is required for study enrollment'),
      }),
    medicalRecordNumber: yup.string(),
  })

  const caseValidations = yup.object({
    bodySiteIds: yup
      .array()
      .of(yup.string())
      .min(1, 'Please select a body part'),
    anticipatedTreatmentDate: yup
      .string()
      .defined('Treatment date is required for scheduling assessments')
      .test(
        'is-valid-date',
        `Treatment must be in format ${localizedDatePattern.toLowerCase()} and a valid date`,
        (value = '') => !!localizedParseDate(value),
      )
      .test(
        'is-after-yesterday',
        'Treatment date must be today or later',
        (value = '') => {
          const asDate = localizedParseDate(value)
          return asDate && (isToday(asDate) || isFuture(asDate))
        },
      )
      .test(
        'is-before-a-year',
        'Treatment cannot be after 1 year from today',
        (value = '') => {
          const asDate = localizedParseDate(value)
          return asDate && differenceInYears(asDate, new Date()) < 1
        },
      ),
    studyId: yup.string().nullable(),
    // .when('bodySiteIds', {
    //   is: (bodySitesIds: Array<string>) => bodySitesIds && bodySitesIds.length > 0,
    //   then: yup.array().of(yup.string()).min(bodySitesIds.length, 'Please select a study'),
    // }),
    // patientStudyNumber: yup.string(),
  })

  const initialPatientValues = () => {
    if (initialValues) {
      return {
        firstName: initialValues.firstName,
        lastName: initialValues.lastName,
        dateOfBirth: parseYMD(initialValues.dateOfBirth),
        cellPhoneLocal: initialValues.cellPhoneLocal,
        email: initialValues.email || '',
        medicalRecordNumber: initialValues.medicalRecordNumber || '',
        // patientStudyNumber: initialValues.medicalRecordNumber || '',
      } as PatientAndCaseFormValues
    } else {
      return {
        firstName: '',
        lastName: '',
        dateOfBirth: '',
        bodySiteIds: [],
        anticipatedTreatmentDate: '',
        cellPhoneLocal: '',
        email: '',
        medicalRecordNumber: '',
        studyId: noneStudy?.id || '',
        enrollment: 'standard',
      }
    }
  }

  useEffect(() => {
    const filteredBodySites = bodySites.filter((bs) => bs.onlyForStudy == false)
    setBodySitesOptions(filteredBodySites)
  }, [bodySites])

  const updateBodySitesOptions = (study: Study | null) => {
    if (study && study.slug != 'none') {
      const selectedStudy = studies.find((s) => s.id === study.id)
      if (selectedStudy) {
        const selectedStudyBodysites = bodySites.filter((bs) => {
          return !(
            selectedStudy.bodySites.filter((sbs) => sbs.id === bs.id).length ===
            0
          )
        })
        setBodySitesOptions(selectedStudyBodysites)
        if (selectedStudyBodysites.length === 1) {
          setFieldValue('bodySiteIds', [selectedStudyBodysites[0].id])
        }
      }
    } else {
      const filteredBodySites = bodySites.filter(
        (bs) => bs.onlyForStudy == false,
      )
      setBodySitesOptions(filteredBodySites)
    }
  }

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldValue,
    setFieldError,
    setFieldTouched,
    isSubmitting,
    isValid,
    touched,
    // Ideally, useFormik should typed with `PatientAndCaseFormValues | PatientFormValues`
    // but that causes a lot of type headaches elsewhere, and this is reasonably good.
  } = useFormik<PatientAndCaseFormValues>({
    isInitialValid: false,
    enableReinitialize: true,
    initialValues: initialPatientValues(),
    validationSchema: isEditing
      ? patientValidations
      : patientValidations.concat(caseValidations),
    onSubmit: (values) => {
      // Be careful - `values` will only contain case-related info (providerId
      // and bodySiteId) when inviting a new patient. Not editing an existing one.

      const formattedDates = {} as Partial<PatientAndCaseFormValuesDateString>

      // Trim whitespace from all values
      Object.keys(values).forEach((key) => {
        const k = key as keyof PatientAndCaseFormValues
        if (typeof values[k] === 'string') {
          ;(values[k] as string).trim()
        }
      })

      // Remove () and -
      values.cellPhoneLocal = values.cellPhoneLocal.replace(/[()-\s]/g, '')
      // Remove accidental #
      values.medicalRecordNumber = values.medicalRecordNumber.replace(/#/g, '')
      // Format to mm/dd/yyyy
      if (values.dateOfBirth instanceof Date) {
        formattedDates.dateOfBirth = format(values.dateOfBirth, 'yyyy-MM-dd')
      } else {
        const dob = localizedParseDate(values.dateOfBirth)

        if (!dob) return
        formattedDates.dateOfBirth = format(dob, 'yyyy-MM-dd')
      }

      let mutation
      if (!isEditing) {
        // Creating a new patient
        const atd = localizedParseDate(values.anticipatedTreatmentDate)

        if (!atd) return
        formattedDates.anticipatedTreatmentDate = format(atd, 'yyyy-MM-dd')

        mutation = invitePatient
          .mutateAsync({
            ...values,
            protocolId: values.protocol?.id,
            ...formattedDates,
          } as PatientAndCaseFormValuesDateString)
          .then(async (patient) => {
            if (values.protocol) {
              await createDiagnosis.mutateAsync({
                patientCaseId: patient.patientCases[0].id,
                protocolId: values.protocol?.id,
                medicalConditionId:
                  (values.protocol?.protocolDiagnoses || [])[0]
                    .medicalConditionId || '',
                severity: (values.protocol?.protocolDiagnoses || [])[0]
                  .severity as PatientDiagnosisSeverity,
                isPrimary: (values.protocol?.protocolDiagnoses || [])[0]
                  .isPrimary,
                locations: protocolAnatomicalLocations,
                patientId: patient.patient.id,
              })

              const protocolPrimaryTreatments =
                values.protocol?.protocolTreatments || []
              if (protocolPrimaryTreatments) {
                protocolPrimaryTreatments?.sort().map(async (treatment) => {
                  let unformattedTreatmentDate
                  switch (treatment.periodType) {
                    case 'days':
                      unformattedTreatmentDate = addDays(
                        new Date(values.anticipatedTreatmentDate),
                        treatment.periodAmount || 0,
                      )
                      break
                    case 'weeks':
                      unformattedTreatmentDate = addWeeks(
                        new Date(values.anticipatedTreatmentDate),
                        treatment.periodAmount || 0,
                      )
                      break
                    case 'months':
                      unformattedTreatmentDate = addMonths(
                        new Date(values.anticipatedTreatmentDate),
                        treatment.periodAmount || 0,
                      )
                      break
                    case 'years':
                      unformattedTreatmentDate = addYears(
                        new Date(values.anticipatedTreatmentDate),
                        treatment.periodAmount || 0,
                      )
                      break

                    default:
                      unformattedTreatmentDate = addWeeks(
                        new Date(values.anticipatedTreatmentDate),
                        0,
                      )
                      break
                  }
                  await createTreatment.mutateAsync({
                    patientCaseId: patient.patientCases[0].id,
                    siteLocationId: treatment.siteLocationId || '',
                    providerId: treatment.staffId || '',
                    medicalTreatmentId: treatment.medicalTreatmentId,
                    treatmentProductId: treatment.treatmentProductId,
                    protocolId: values.protocol?.id,
                    treatmentDate: format(
                      unformattedTreatmentDate as Date,
                      'yyyy-MM-dd',
                    ),
                  })
                })
              }
            }
            push(
              PatientFlowUrls.diagnosis.create(
                patient.patient.id,
                patient.patientCases[0].id,
              ),
            )
          })
      } else {
        // Updating an existing patient
        mutation = updatePatient.mutateAsync({
          id: patientId,
          ...values,
          ...formattedDates,
        } as PatientAndCaseFormValuesDateString)
      }

      return mutation.catch((err) => {
        if (err?.isAxiosError) {
          const error = err as AxiosError

          if (error.response?.status === 422) {
            const data = error.response.data
            const isPhoneTaken = data?.errors?.cellPhoneLocal?.[0]?.includes(
              'has already been taken',
            )
            isPhoneTaken &&
              setFieldError('cellPhoneLocal', 'This number is already in use')
          }
        }
      })
    },
  })

  const { data: anatomicalLocations = [] } = useAnatomicalLocations(
    (values.protocol?.protocolDiagnoses || [])[0]?.medicalConditionId || '',
  )

  useEffect(() => {
    if (values.protocol && anatomicalLocations) {
      const pLocations = anatomicalLocations.filter((al) =>
        (
          (values.protocol?.protocolDiagnoses || [])[0]?.locations || []
        ).includes(al.name),
      )
      setProtocolAnatomicalLocations(pLocations || [])
    }
  }, [values.protocol])

  const renderHeader = () => {
    return isEditing ? (
      <div className={classes.header}>
        <H5 paragraph>Edit Patient</H5>
        <DialogTrigger
          action={
            <Link
              component={Body1}
              className={classes.archiveButton}
              underline="hover"
            >
              Archive Patient
            </Link>
          }
          content={(toggle) => (
            <Box p={3} className={classes.dialogContent}>
              <ArchiveModal
                patientId={patientId}
                archiveType={'Patient'}
                toggle={toggle}
              />
            </Box>
          )}
        />
      </div>
    ) : (
      <H5 paragraph>Invite Patient</H5>
    )
  }

  const hasUpdatedFields = () => {
    let updateAvailable = false
    if (!initialValues) return true
    Object.keys(values).forEach((key: string) => {
      const valueKey = key as PatientFormValuesKeys
      let initialValuesVal = initialValues ? initialValues[valueKey] : undefined
      if (key === 'cellPhoneLocal' && initialValuesVal)
        initialValuesVal =
          formatPhoneNumber(initialValuesVal) || initialValuesVal
      if (key === 'dateOfBirth' && initialValuesVal)
        initialValuesVal =
          localizedFormatDate(parseYMD(initialValuesVal)) || initialValuesVal
      if (key === 'medicalRecordNumber' && !initialValuesVal)
        initialValuesVal = ''
      if (initialValues && values[valueKey] !== initialValuesVal)
        updateAvailable = true
    })
    return updateAvailable
  }

  const isQckStudy =
    studies &&
    studies.find(
      (study) =>
        study.id === values.studyId &&
        study.slug ===
          'platelet-rich-plasma-and-the-effects-of-nsaids-on-pain-and-functional-scores-in-knee-osteoarthritis',
    ) !== undefined

  return (
    <>
      {renderHeader()}
      <Divider />
      <form className={classes.form} onSubmit={handleSubmit}>
        {!isEditing && (
          <ToggleButtonGroup
            color="primary"
            value={values.enrollment}
            exclusive
            fullWidth
            onChange={(_event, s) => {
              setFieldValue('enrollment', s)
              if (s === 'standard') {
                setFieldValue('studyId', noneStudy?.id || '')
              } else {
                setFieldValue('studyId', '')
                setFieldValue('protocol', null)
                setFieldValue('bodySiteIds', [])
              }
            }}
            aria-label="Platform"
          >
            <ToggleButton id="enrollment-standard-button" value="standard">
              <Box>
                Standard <br />
                <span>General Care</span>
              </Box>
            </ToggleButton>
            <ToggleButton id="enrollment-study-button" value="study">
              <Box>
                Study
                <br />
                <span>Research Participant</span>
              </Box>
            </ToggleButton>
          </ToggleButtonGroup>
        )}
        {!isEditing &&
          values.enrollment === 'study' &&
          currentSite?.studies &&
          currentSite?.studies.length > 0 && (
            <Autocomplete
              id="patient-invite-studies-dropdown"
              options={studies}
              getOptionLabel={(opt) => opt.shortName}
              filterOptions={(options, { inputValue }) =>
                inputValue
                  ? matchSorter(options, inputValue, { keys: ['shortName'] })
                  : options
              }
              onChange={(_event, s) => {
                if (!touched.email) setFieldTouched('email')
                setFieldValue('bodySiteIds', [])
                updateBodySitesOptions(s || null)
                setFieldValue('studyId', s?.id || '')
              }}
              value={
                studies.find((study) => study.id === values.studyId) ?? null
              }
              renderInput={(params) => {
                // @ts-expect-error Mui's autocomplete types here are incomplete
                params.InputLabelProps.shrink = true
                return (
                  <TextField
                    required
                    {...params}
                    name="studyId"
                    label="Study Enrollment"
                    onBlur={handleBlur}
                    InputProps={{
                      ...params.InputProps,
                    }}
                    error={touched.studyId && Boolean(errors.studyId)}
                    helperText={touched.studyId && errors.studyId}
                  />
                )
              }}
            />
          )}
        <TextField
          id="patient-invite-first-name"
          label="First Name"
          name="firstName"
          required
          fullWidth
          value={values.firstName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.firstName && Boolean(errors.firstName)}
          helperText={touched.firstName && errors.firstName}
        />
        <TextField
          id="patient-invite-last-name"
          label="Last Name"
          name="lastName"
          required
          fullWidth
          value={values.lastName}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.lastName && Boolean(errors.lastName)}
          helperText={touched.lastName && errors.lastName}
        />
        <DatePicker
          id="patient-invite-date-of-birth"
          label="Date of Birth"
          name="dateOfBirth"
          // defaultDate={isCanada ? '31/01/1990' : '01/31/1990'}
          inputFormat={isCanada ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
          required
          fullWidth
          maxDate={endOfYesterday()}
          isEditing={values.dateOfBirth ? values.dateOfBirth : undefined}
          setFieldValue={setFieldValue}
          onBlur={handleBlur}
          error={touched.dateOfBirth && Boolean(errors.dateOfBirth)}
          helperText={touched.dateOfBirth && errors.dateOfBirth}
        />
        {protocols &&
          protocols.length > 0 &&
          !isEditing &&
          values.enrollment !== 'study' && (
            <Autocomplete
              id="patient-flow-invite-protocol"
              options={protocols || []}
              getOptionLabel={(opt) => opt.name}
              isOptionEqualToValue={(opt, val) => opt.id === val.id}
              filterOptions={(options, { inputValue }) =>
                inputValue
                  ? matchSorter(options, inputValue, { keys: ['name'] })
                  : options
              }
              value={values.protocol}
              fullWidth
              onChange={(_event, data) => {
                setFieldValue('protocol', data)
                const protocolBodySite = bodySitesOptions.find(
                  (bs) => bs.id === data?.bodySite?.id,
                )
                setFieldValue('bodySiteIds', [protocolBodySite?.id] || '')
                if (!protocolBodySite) {
                  setFieldError(
                    'protocol',
                    'Body site of this protocol not available',
                  )
                }
              }}
              renderInput={(params) => {
                // @ts-expect-error Mui's autocomplete types here are incomplete
                params.InputLabelProps.shrink = true
                return (
                  <TextField
                    {...params}
                    label="Protocol"
                    error={touched.protocol && Boolean(errors.protocol)}
                    helperText={touched.protocol && errors.protocol}
                  />
                )
              }}
            />
          )}
        {!isEditing && (
          <Autocomplete
            id="patient-invite-body-sites-dropdown"
            multiple
            options={bodySitesOptions}
            getOptionLabel={(opt) => opt.name}
            filterOptions={(options, { inputValue }) =>
              inputValue
                ? matchSorter(options, inputValue, { keys: ['name'] })
                : options
            }
            onChange={(_event, sites) => {
              setFieldValue(
                'bodySiteIds',
                sites.map((s) => s.id),
              )
            }}
            disabled={values.protocol ? true : false}
            value={
              bodySitesOptions.filter((site) =>
                values.bodySiteIds.includes(site.id),
              ) ?? null
            }
            renderInput={(params) => {
              // @ts-expect-error Mui's autocomplete types here are incomplete
              params.InputLabelProps.shrink = true
              return (
                <TextField
                  {...params}
                  name="bodySiteIds"
                  label="Body Part"
                  onBlur={handleBlur}
                  required
                  InputProps={{
                    ...params.InputProps,
                    required: values.bodySiteIds.length === 0,
                  }}
                  error={touched.bodySiteIds && Boolean(errors.bodySiteIds)}
                  helperText={touched.bodySiteIds && errors.bodySiteIds}
                />
              )
            }}
          />
        )}
        {!isEditing && (
          <DatePicker
            id="patient-invite-anticipated-treatment-date"
            label="Treatment Date"
            name="anticipatedTreatmentDate"
            // defaultDate={localizedFormatDate(aWeekFromNow)}
            inputFormat={isCanada ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
            required
            fullWidth
            minDate={new Date()}
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            error={
              touched.anticipatedTreatmentDate &&
              Boolean(errors.anticipatedTreatmentDate)
            }
            helperText={
              touched.anticipatedTreatmentDate &&
              errors.anticipatedTreatmentDate
            }
          />
        )}
        {/* Our PhoneField (and its underlying react-imask component) does not play
         * well with Formik. In the interest of time, let's fix this later.
         * TODO
         */}
        <PhoneField
          id="patient-invite-cell-phone"
          label="Cell Phone"
          name="cellPhoneLocal"
          required
          fullWidth
          value={values.cellPhoneLocal}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.cellPhoneLocal && Boolean(errors.cellPhoneLocal)}
          helperText={touched.cellPhoneLocal && errors.cellPhoneLocal}
        />
        <TextField
          id="patient-invite-email"
          label="Email"
          name="email"
          fullWidth
          value={values.email}
          onChange={handleChange}
          onBlur={handleBlur}
          error={touched.email && Boolean(errors.email)}
          helperText={touched.email && errors.email}
        />

        {!isQckStudy && (
          <TextField
            id="patient-invite-medical-record-number"
            label="MRN #"
            name="medicalRecordNumber"
            fullWidth
            value={values.medicalRecordNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.medicalRecordNumber && Boolean(errors.medicalRecordNumber)
            }
            helperText={
              touched.medicalRecordNumber && errors.medicalRecordNumber
            }
          />
        )}
        {/* {isQckStudy && (
          <TextField
            label="Research Study Number"
            name="patientStudyNumber"
            fullWidth
            value={values.patientStudyNumber}
            onChange={handleChange}
            onBlur={handleBlur}
            error={
              touched.patientStudyNumber && Boolean(errors.patientStudyNumber)
            }
            helperText={
              touched.patientStudyNumber && errors.patientStudyNumber
            }
          />
        )} */}
        <Button
          id="patient-invite-submit-button"
          type="submit"
          fullWidth
          loading={isSubmitting}
          disabled={!isValid || !hasUpdatedFields()}
        >
          {isEditing ? 'Update' : 'Save & Invite'}
        </Button>
      </form>
    </>
  )
}

export default Invite
