import { FC, useEffect, useState } from 'react'
import {
  Alert,
  Box,
  Button,
  ButtonGroup,
  Divider,
  Grid,
  H1,
  H3,
  makeStyles,
} from '@perk-ui/core'
import { NumericFormat } from 'react-number-format'
import { useHistory, useLocation } from 'react-router'

// import { useFormik } from 'formik'
// import * as yup from 'yup'
import StripeCheckout from '../../../components/StripeCheckout'
import useCreateSubscription from '../../../features/query-hooks/useCreateSubscription' // SubscriptionResponse,
import useProducts from '../../../features/query-hooks/useProducts'
import useSubscription from '../../../features/query-hooks/useSubscription'
import { onboardingUrls } from '../Onboarding'

// import { useAuth } from '../../../features/auth/AuthContext'

interface PaymentInformationStepProps {}

// interface PaymentInformationStepFormValues {}
interface CartItemProps {
  product: string
  price: string
  amount: number
  quantity: number
}

const useStyles = makeStyles((theme) => ({
  paymentContent: {
    overflow: 'auto',
    paddingRight: theme.spacing(1.5),
  },
  productContainer: {
    borderRadius: '20px',
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
  },
  productTitle: {
    color: theme.palette.common.white,
    padding: theme.spacing(2),
    borderRadius: '20px',
  },
  productContent: {
    padding: theme.spacing(2),
    display: 'flex',
    flexFlow: 'column',
    height: '100%',
    justifyContent: 'space-between',
  },
  productDescription: {},
  productQuantityTitle: {},
  productQuantitySelection: {
    backgroundColor: 'transparent',
    width: '150px',
    '& button': {
      backgroundColor: 'transparent',
      borderStyle: 'solid !important',
      borderWidth: '1px !important',
      color: theme.palette.common.black,
      '&.quantity-text': {
        fontSize: '16px',
        fontWeight: 500,
        '&:hover': {
          cursor: 'auto',
          backgroundColor: 'transparent',
        },
        '&:active': {
          color: theme.palette.common.black,
          backgroundColor: 'transparent',
        },
      },
    },
  },
  productPriceContainer: {},
  productPriceAmount: {},
  productPriceAmountTotalText: {
    lineHeight: '32px',
  },
  productPriceAmountTotal: {
    fontSize: '32px',
  },
  cartProductsHelpText: {
    color: '#9F8821',
    backgroundColor: '#FFFBEB',
  },
  sumaryDot: {
    marginLeft: theme.spacing(0.5),
    marginRight: theme.spacing(0.5),
  },
  sumaryText: {
    color: '#767676',
  },
  sumaryDivider: {
    width: '100%',
  },
  nextHelpText: {
    color: '#919191',
    fontSize: '12px',
    padding: theme.spacing(2, 0),
  },
}))

const PaymentInformationStep: FC<PaymentInformationStepProps> = () => {
  // const { signIn } = useAuth()
  const classes = useStyles()
  const { pathname } = useLocation()
  const { push } = useHistory()
  const { data: products = [] } = useProducts()
  const { data: subscription } = useSubscription()
  const currentStepIndex = onboardingUrls.indexOf(pathname)

  const ProductSelectionEl: FC = () => {
    const createSubscription = useCreateSubscription()
    const [cart, setCart] = useState<CartItemProps[]>([])

    useEffect(() => {
      products.map((prod) => {
        let displayPrice = prod.defaultPrice.amount
        if (prod.defaultPrice.tiers.length > 0) {
          const currentTier = prod.defaultPrice.tiers.find((tier) => {
            return Number(tier.upTo) >= 0 || tier.upTo == null
          })
          displayPrice =
            (currentTier?.flatAmount || 0) + (currentTier?.unitAmount || 0)
        }
        setCart((oldCart) => [
          ...oldCart,
          {
            product: prod.id,
            price: prod.defaultPrice.id,
            amount: displayPrice,
            quantity: 0,
          },
        ])
      })
    }, [products])

    const handleCartItemQuantityChange = ({
      price,
      type,
    }: {
      price: string
      type: 'increase' | 'decrease'
    }) => {
      const newCart = cart.map((item) => {
        const productItem = products.find(
          (product) => item.product === product.id,
        )
        const cartQuantity =
          type === 'increase' ? item.quantity + 1 : item.quantity - 1
        let displayPrice = productItem?.defaultPrice.amount || 0
        if (productItem && productItem.defaultPrice.tiers.length > 0) {
          const currentTier = productItem.defaultPrice.tiers.find((tier) => {
            return Number(tier.upTo) >= cartQuantity || tier.upTo == null
          })
          displayPrice =
            (currentTier?.flatAmount || 0) + (currentTier?.unitAmount || 0)
        }
        if (item.price === price) {
          return {
            product: item.product,
            price: item.price,
            amount: displayPrice,
            quantity: cartQuantity,
          }
        } else {
          return item
        }
      })
      setCart(newCart)
    }

    const handleSubmit = () => {
      if (!subscription) {
        return createSubscription
          .mutateAsync({
            items: cart,
            zeroSubscription: cartItemsTotalAmount === 0 ? true : undefined,
          })
          .then((subs) => {
            if (subs && subs.status === 'free')
              push(onboardingUrls[currentStepIndex + 1])
          })
      } else {
        push(onboardingUrls[currentStepIndex + 1])
      }
    }

    const cartItemsTotalQuantity = cart.reduce((accumulator, cartItem) => {
      return accumulator + cartItem.quantity
    }, 0)
    const cartItemsTotalAmount = cart.reduce((accumulator, cartItem) => {
      return accumulator + cartItem.amount * cartItem.quantity
    }, 0)
    const performanceProductId = products.find(
      (product) => product.metadata.systemValue.toLowerCase() == 'performance',
    )?.id
    const essentialsProductId = products.find(
      (product) => product.metadata.systemValue.toLowerCase() == 'essentials',
    )?.id
    return (
      <Box className="step-content">
        <Box className="step-header">
          <H1>Pricing</H1>
          <H3>
            You can select a max of 10 providers in total. If you are an
            enterprise user and would like to purchase additional licenses,
            please contact{' '}
            <a href="mailto:sales@databiologics.com">sales@databiologics.com</a>
          </H3>
        </Box>
        <Box className={classes.paymentContent}>
          <Grid container spacing={2} py={2}>
            {products
              .sort((a, b) => a.metadata.order - b.metadata.order)
              .map((product) => {
                const cartItem = cart.find(
                  (item) => item.price === product.defaultPrice.id,
                )
                let displayPrice = product.defaultPrice.amount
                if (product.defaultPrice.tiers.length > 0) {
                  const currentTier = product.defaultPrice.tiers.find(
                    (tier) => {
                      return (
                        Number(tier.upTo) >= (cartItem?.quantity || 0) ||
                        tier.upTo == null
                      )
                    },
                  )
                  displayPrice =
                    (currentTier?.flatAmount || 0) +
                    (currentTier?.unitAmount || 0)
                }
                const isPerformanceProduct = product.id == performanceProductId
                const isEssentialProduct = product.id == essentialsProductId
                const essentialCartQuantity =
                  cart.find(
                    (cartItem) => cartItem.product == essentialsProductId,
                  )?.quantity || 0
                const performanceCartQuantity =
                  cart.find(
                    (cartItem) => cartItem.product == performanceProductId,
                  )?.quantity || 0
                const isAddButtonDisabled = isPerformanceProduct
                  ? essentialCartQuantity > 0
                  : isEssentialProduct
                  ? performanceCartQuantity > 0
                  : false
                return (
                  <Grid item xs={4} key={product.id}>
                    <Box
                      className={classes.productContainer}
                      bgcolor={product.metadata.backgroundColor}
                    >
                      <H3
                        className={classes.productTitle}
                        bgcolor={product.metadata.mainColor}
                      >
                        {product.name}
                      </H3>
                      <div className={classes.productContent}>
                        <p className={classes.productDescription}>
                          {product.description}
                        </p>
                        <div>
                          <p className={classes.productQuantityTitle}>
                            Numbers of Providers
                          </p>
                          <ButtonGroup
                            className={classes.productQuantitySelection}
                            variant="outlined"
                            size="small"
                            orientation="horizontal"
                          >
                            <Button
                              disabled={cartItem?.quantity === 0}
                              onClick={() =>
                                handleCartItemQuantityChange({
                                  price: cartItem?.price || '',
                                  type: 'decrease',
                                })
                              }
                              style={{
                                borderColor: `${product.metadata.mainColor}`,
                              }}
                            >
                              -
                            </Button>
                            <Button
                              className="quantity-text"
                              style={{
                                borderColor: `${product.metadata.mainColor}`,
                              }}
                            >
                              {cartItem?.quantity}
                            </Button>
                            <Button
                              disabled={
                                cartItemsTotalQuantity >= 10 ||
                                isAddButtonDisabled
                              }
                              onClick={() =>
                                handleCartItemQuantityChange({
                                  price: cartItem?.price || '',
                                  type: 'increase',
                                })
                              }
                              style={{
                                borderColor: `${product.metadata.mainColor}`,
                              }}
                            >
                              +
                            </Button>
                          </ButtonGroup>
                          <Divider
                            style={{
                              borderColor: product.metadata.mainColor,
                              margin: '12px 0',
                            }}
                          />
                          <div className={classes.productPriceContainer}>
                            {product.defaultPrice.amount === 0 ? (
                              <span>FREE</span>
                            ) : (
                              <>
                                <NumericFormat
                                  className={classes.productPriceAmount}
                                  value={displayPrice / 100}
                                  displayType="text"
                                  thousandSeparator
                                  prefix={'$'}
                                />{' '}
                                /{product.defaultPrice.interval} per provider
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    </Box>
                  </Grid>
                )
              })}
          </Grid>
          <Alert
            className={classes.cartProductsHelpText}
            severity="warning"
            icon={false}
          >
            Unlimited Staff Seats Included
          </Alert>
          {products
            .sort((a, b) => a.metadata.order - b.metadata.order)
            .map((product) => {
              const cartItem = cart.find(
                (item) => item.price === product.defaultPrice.id,
              )
              let displayPrice = product.defaultPrice.amount
              if (product.defaultPrice.tiers.length > 0) {
                const currentTier = product.defaultPrice.tiers.find((tier) => {
                  return (
                    Number(tier.upTo) >= (cartItem?.quantity || 0) ||
                    tier.upTo == null
                  )
                })
                displayPrice =
                  (currentTier?.flatAmount || 0) +
                  (currentTier?.unitAmount || 0)
              }
              return (
                <Grid key={product.id}>
                  <Grid container spacing={2} py={2}>
                    <Grid item xs={8}>
                      {product.name}
                      <span className={classes.sumaryDot}>&bull;</span>
                      <span className={classes.sumaryText}>
                        <NumericFormat
                          className={classes.productPriceAmount}
                          value={(displayPrice || 0) / 100}
                          displayType="text"
                          thousandSeparator
                          prefix={'$'}
                        />
                        x{cartItem?.quantity || 0}
                      </span>
                    </Grid>
                    <Grid
                      item
                      xs={4}
                      justifyContent={'flex-end'}
                      textAlign={'right'}
                    >
                      <NumericFormat
                        className={classes.productPriceAmount}
                        value={(displayPrice / 100) * (cartItem?.quantity || 0)}
                        displayType="text"
                        thousandSeparator
                        prefix={'$'}
                      />
                      /{product.defaultPrice.interval}
                    </Grid>
                  </Grid>
                  <Divider />
                </Grid>
              )
            })}
          <Grid container spacing={2} py={2}>
            <Grid item xs={8} className={classes.productPriceAmountTotalText}>
              Total Due Today
            </Grid>
            <Grid item xs={4} justifyContent={'flex-end'} textAlign={'right'}>
              <NumericFormat
                className={classes.productPriceAmountTotal}
                value={cartItemsTotalAmount / 100}
                displayType="text"
                thousandSeparator
                prefix={'$'}
              />
            </Grid>
          </Grid>
          <Button
            onClick={handleSubmit}
            disabled={cartItemsTotalQuantity === 0}
          >
            Continue
          </Button>
          <div className={classes.nextHelpText}>
            If you have a discount code, that can be applied on the next page
          </div>
        </Box>
      </Box>
    )
  }

  const CheckoutEl: FC = () => {
    return (
      <Box className="step-content">
        <Box className="step-header">
          <H1>Payment Information</H1>
        </Box>
        <Box className={classes.paymentContent}>
          <StripeCheckout
            subscription={subscription}
            products={products}
            successAction={() => {
              push(onboardingUrls[currentStepIndex + 1])
            }}
          />
        </Box>
      </Box>
    )
  }

  if (!subscription) {
    return <ProductSelectionEl />
  } else {
    return <CheckoutEl />
  }
}

export default PaymentInformationStep
