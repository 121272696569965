import { SurveyItemTypes, } from '../';
/**
 * Test that an item id and all of its ancestor items are enabled.
 */
export const isItemIdEnabled = (answers, items, surveyQuestionId, studySlug, bodySiteKey) => {
    for (const item of items) {
        const isEnabled = isItemEnabled(answers, item, studySlug, bodySiteKey);
        // We found the question!
        if (item.id === surveyQuestionId) {
            return isEnabled;
        }
        // Only delve into groups if the group is enabled
        if (isEnabled && item.type === SurveyItemTypes.group) {
            const isEnabledIn = isItemIdEnabled(answers, item.items, surveyQuestionId);
            // If the item is enabled and was found down this tree, signal up the recursion
            // stack that we found it.
            // Otherwise, we don't report anything and let the below `return false` report
            // to the caller.
            if (isEnabledIn)
                return true;
        }
    }
    // We've checked all items and their descendents.
    // The item either doesn't exist or is a child of a disabled item
    return false;
};
/**
 * Test that a specific item object is enabled
 */
export const isItemEnabled = (answers, surveyItem, studySlug, bodySiteKey) => {
    const enableWhens = surveyItem.enableWhens;
    // Items are enabled by default
    if (!enableWhens || enableWhens.length === 0)
        return true;
    // enableWhens in the same array are OR'd together
    return enableWhens.some((enableWhen) => evaluateEnableWhen(answers, enableWhen, studySlug, bodySiteKey));
};
const evaluateEnableWhen = (answers, enableWhen, studySlug, bodySiteKey) => {
    const { surveyQuestionId, operator, value, enableWhens, studySlug: studySlugCondition, bodySiteKey: bodySiteKeyCondition, } = enableWhen;
    if (studySlug && studySlugCondition) {
        return studySlug === studySlugCondition;
    }
    if (bodySiteKey && bodySiteKeyCondition) {
        return bodySiteKey === bodySiteKeyCondition;
    }
    const answerToCheck = answers.find((answer) => answer.surveyQuestionId === surveyQuestionId);
    // Dependent item was not answered
    if (!answerToCheck)
        return false;
    const valueMatches = answerToCheck.values.some((answerValue) => {
        switch (operator) {
            case '==':
                return answerValue === value;
            case '!=':
                return answerValue !== value;
            case '!!':
                return typeof answerValue != null;
            // These operations are specifically for number values
            case '<':
                return toNumber(answerValue) < toNumber(value);
            case '<=':
                return toNumber(answerValue) <= toNumber(value);
            case '>':
                return toNumber(answerValue) > toNumber(value);
            case '>=':
                return toNumber(answerValue) >= toNumber(value);
            default:
                throw new Error(`Unknown enableWhen operator: ${operator}. EnableWhen id: ${enableWhen.id}`);
        }
    });
    // If children exist, they are AND'd
    const childrenAreEnabled = enableWhens.length > 0
        ? enableWhens.some((ew) => evaluateEnableWhen(answers, ew, studySlug, bodySiteKey))
        : true;
    return valueMatches && childrenAreEnabled;
};
const toNumber = (arg) => {
    const num = Number(arg);
    if (Number.isNaN(num)) {
        throw new Error(`Failed to cast "${arg}" to number`);
    }
    return num;
};
