import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { Staff } from './useCurrentUser'

export interface ClinicStaffsResponse {
  staffs: Staff[]
}

const useClinicStaffs = (extraOptions: UseQueryOptions<Staff[]> = {}) => {
  const currentSiteId = useCurrentSite()?.id || ''

  const key = currentSiteId ? QueryKeys.staffs(currentSiteId) : []
  const url = `/sites/staffs?site_ids[]=${currentSiteId}`

  return useQuery(
    key,
    () => request.get<ClinicStaffsResponse>(url).then((r) => r.data.staffs),
    {
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useClinicStaffs
