import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { ProtocolTreatment } from './useProtocols'

interface CreateTreatmentResponse {
  protocolTreatment: ProtocolTreatment
}

export interface CreateTreatmentVars extends Omit<ProtocolTreatment, 'id'> {
  protocolId: string
}

const useCreateProtocolTreatment = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation(({ protocolId, ...vars }: CreateTreatmentVars) => {
    const url = `/sites/protocol_treatments`

    return request
      .post<CreateTreatmentResponse>(url, {
        siteIds: [siteId],
        protocolTreatment: {
          protocolId: protocolId,
          siteId: siteId,
          ...vars,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.protocols({ clinicId: siteId }))
        queryClient.invalidateQueries(QueryKeys.protocol(protocolId))

        return r.data.protocolTreatment
      })
  })
}

export default useCreateProtocolTreatment
