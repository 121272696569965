import { FC } from 'react'
import { makeStyles, MenuItem, Select } from '@perk-ui/core'

import useProviders from '../../../features/query-hooks/useProviders'

export interface AdverseEventTableProviderSelect {
  selectValue: string
  setSelectValue: (value: string) => void
}

const useStyles = makeStyles((theme) => ({
  select: {
    marginLeft: theme.spacing(3),
    paddingLeft: 0,
    marginBottom: theme.spacing(1),
    width: theme.spacing(40),
  },
}))

const AdverseEventTableProviderSelect: FC<AdverseEventTableProviderSelect> = ({
  setSelectValue,
  selectValue,
}) => {
  const classes = useStyles()
  const { data: providers = [], isLoading } = useProviders()

  return (
    <Select
      className={classes.select}
      value={isLoading ? '' : selectValue}
      onChange={(e) => {
        setSelectValue(String(e.target.value))
      }}
      defaultValue="All Providers"
    >
      <MenuItem key={'0-initial'} value="All Providers">
        All Providers
      </MenuItem>
      {providers.map((provider) => (
        <MenuItem key={provider.id} value={provider.id}>
          {provider.firstName} {provider.lastName}
        </MenuItem>
      ))}
    </Select>
  )
}

export default AdverseEventTableProviderSelect
