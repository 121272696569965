import { FC, useState } from 'react'
import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  Chip,
  FormHelperText,
  H1,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@perk-ui/core'
import { useFormik } from 'formik'
import { matchSorter } from 'match-sorter'
import { useHistory, useLocation } from 'react-router'
import * as yup from 'yup'

import {
  clinicStaffPositions,
  providerTitles,
  residentFellow,
  societiesAffiliations,
} from '../../../config/constants'
import { useUser } from '../../../features/auth/AuthContext'
import { SecondaryRole } from '../../../features/query-hooks/types'
import { Staff } from '../../../features/query-hooks/useCurrentUser'
// import useStaff from '../../../features/query-hooks/useStaff'
import useUpdateOnboardingStep from '../../../features/query-hooks/useUpdateOnboardingStep'
import useUpdateStaff from '../../../features/query-hooks/useUpdateStaff'
import { onboardingUrls } from '../Onboarding'

interface AccountDetailsStepProps {}

interface AccountDetailsStepFormValues {
  title: string
  societiesAffiliations: string[]
  residentFellow: string
  secondaryRole: SecondaryRole
  positions: string[]
}

const useStyles = makeStyles((theme) => ({
  row: {
    display: 'flex',
    gap: '20px',
  },
  input: {
    marginTop: '5px',
  },
  rowItem: {
    width: '100%',
    marginTop: '5px',
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
    '& .MuiChip-label': {
      whiteSpace: 'nowrap',
      textOverflow: 'ellipsis',
      [theme.breakpoints.down('md')]: {
        maxWidth: '55vw',
      },
    },
  },
  chip: {
    margin: 2,
  },
  dropdownItemCentered: {
    whiteSpace: 'inherit',
    justifyContent: 'center',
    backgroundColor: '#e3e3e3',
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  inputLabel: {
    marginBottom: '5px',
    marginTop: '5px',
  },
}))

const accountDetailsValidations = yup.object({
  societiesAffiliations: yup
    .array()
    .when('type', {
      is: (type: string) => type === 'Provider',
      then: yup.array().of(yup.string()),
    })
    .nullable(),
  residentFellow: yup
    .string()
    .when('type', {
      is: (type: string) => type === 'Provider',
      then: yup.string().required('Resident or fellow is required'),
    })
    .nullable(),
  title: yup
    .string()
    .when('type', {
      is: (type: string) => type === 'Provider',
      then: yup.string().required('Title is required'),
    })
    .nullable(),
  secondaryRole: yup
    .string()
    .when('type', {
      is: (type: string) => type === 'ClinicStaff',
      then: yup.string().required('Clinic staff role is required.'),
    })
    .nullable(),
})

const AccountDetailsStep: FC<AccountDetailsStepProps> = () => {
  const { push } = useHistory()
  const { pathname } = useLocation()
  const classes = useStyles()
  const updateStaff = useUpdateStaff()
  const updateOnboardingStep = useUpdateOnboardingStep()
  const currentStepIndex = onboardingUrls.indexOf(pathname)

  const [otherSocietyShow, setOtherSocietyShow] = useState<boolean>(false)
  const [otherSociety, setOtherSociety] = useState<string>('')
  const currentUser = useUser()
  const staffInfo = currentUser?.staff as Staff
  const pushNextOnboardingStep = () => {
    updateOnboardingStep
      .mutateAsync({
        onboardingStep: 'clinic-users',
      })
      .then(() => {
        setTimeout(() => {
          push(onboardingUrls[currentStepIndex + 1])
        }, 2000)
      })
  }

  const {
    values,
    errors,
    // handleBlur,
    handleSubmit,
    // setFieldError,
    setFieldValue,
    isSubmitting,
    isValid,
    touched,
  } = useFormik<AccountDetailsStepFormValues>({
    validateOnMount: true,
    initialValues: {
      title: staffInfo?.title || '',
      societiesAffiliations: staffInfo?.societiesAffiliations || [],
      residentFellow: staffInfo?.residentFellow || '',
      secondaryRole: staffInfo?.secondaryRole || '',
      positions: staffInfo?.positions || [],
    },
    validationSchema: accountDetailsValidations,
    onSubmit: async (values, { setSubmitting }) => {
      try {
        Object.keys(values).forEach((key) => {
          const k = key as keyof AccountDetailsStepFormValues
          if (typeof values[k] === 'string') {
            ;(values[k] as string).trim()
          }
        })
        if (otherSociety) {
          values.societiesAffiliations.push(otherSociety)
        }

        await updateStaff
          .mutateAsync({
            ...staffInfo,
            ...values,
          })
          .then(() => pushNextOnboardingStep())
      } catch (e) {
        setSubmitting(false)
      }
    },
  })
  const [openMultiSelect, setOpenMultiSelect] = useState<boolean>(false)

  return (
    <Box className="step-content">
      <form className="clinic-setup-form" onSubmit={handleSubmit}>
        <Box className="step-header">
          <H1>Finish setting up your account</H1>
          {staffInfo && staffInfo.type == 'Provider' && (
            <>
              <div className={classes.row}>
                <Autocomplete
                  className={`${classes.input} ${classes.rowItem}`}
                  disableClearable
                  options={providerTitles}
                  getOptionLabel={(opt) => opt}
                  filterOptions={(options, { inputValue }) =>
                    inputValue ? matchSorter(options, inputValue, {}) : options
                  }
                  onChange={(_event, title) => {
                    setFieldValue('title', title)
                  }}
                  value={values.title || undefined}
                  renderInput={(params) => {
                    // @ts-expect-error Mui's autocomplete types here are incomplete
                    params.InputLabelProps.shrink = true
                    return (
                      <TextField
                        {...params}
                        placeholder="Select title"
                        label="Title"
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    )
                  }}
                />
              </div>
              <div className={classes.row}>
                <div className={classes.rowItem}>
                  <InputLabel className={classes.inputLabel}>
                    Societies and affiliations
                  </InputLabel>
                  <Select
                    fullWidth
                    multiple
                    label="Societies and affiliations"
                    placeholder="Select Societies and affiliations"
                    value={values.societiesAffiliations}
                    renderValue={(selected: string[]) => (
                      <div className={classes.chips}>
                        {(selected as string[]).map((value) => (
                          <Chip
                            key={value}
                            label={value}
                            className={classes.chip}
                          />
                        ))}
                      </div>
                    )}
                    open={openMultiSelect}
                    onOpen={() => setOpenMultiSelect(true)}
                    onClose={() => setOpenMultiSelect(false)}
                    onChange={(ev) => {
                      const value = ev.target.value as Array<string | undefined>
                      if (value.findIndex((x) => x == 'Other') > 0) {
                        value.splice(value.indexOf('Other'), 1)
                      }
                      if (value.indexOf(undefined) > -1) {
                        value.splice(value.indexOf(undefined), 1)
                      }
                      setFieldValue('societiesAffiliations', value)
                    }}
                  >
                    <MenuItem
                      className={classes.dropdownItemCentered}
                      onClick={() => {
                        setOpenMultiSelect(false)
                      }}
                    >
                      Close
                    </MenuItem>
                    {societiesAffiliations.map((val) => (
                      <MenuItem key={val} value={val}>
                        <Checkbox
                          checked={
                            values.societiesAffiliations.indexOf(val) > -1
                          }
                        />
                        {val}
                      </MenuItem>
                    ))}
                    <MenuItem
                      key="Other"
                      value="Other"
                      onClick={() => {
                        if (otherSocietyShow) {
                          setOtherSociety('')
                        }
                        setOtherSocietyShow(!otherSocietyShow)
                      }}
                    >
                      <Checkbox checked={otherSocietyShow} />
                      Other
                    </MenuItem>
                  </Select>
                </div>
                <div className={classes.rowItem}>
                  <InputLabel className={classes.inputLabel}>
                    Designate Resident or Fellow
                  </InputLabel>
                  <Select
                    error={!!errors.residentFellow}
                    fullWidth
                    label="Designate Resident of Fellow"
                    value={values.residentFellow}
                    onChange={(e) => {
                      setFieldValue('residentFellow', e.target.value)
                    }}
                  >
                    {residentFellow.map((x) => (
                      <MenuItem key={x.value} value={x.value}>
                        {x.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {touched.residentFellow && errors.residentFellow && (
                    <FormHelperText error>
                      Resident or fellow is required
                    </FormHelperText>
                  )}
                </div>
              </div>
              {otherSocietyShow && (
                <TextField
                  className={classes.rowItem}
                  label="Other society/affiliation"
                  placeholder="Other society/affiliation"
                  fullWidth
                  value={otherSociety}
                  onChange={(e) => {
                    setOtherSociety(e.target.value)
                  }}
                ></TextField>
              )}
            </>
          )}
          {staffInfo && staffInfo.type == 'ClinicStaff' && (
            <>
              <InputLabel className={classes.inputLabel}>
                Position(s)
              </InputLabel>
              <Select
                fullWidth
                multiple
                label="Position(s)"
                placeholder="Select Position(s)"
                value={values.positions}
                renderValue={(selected: string[]) => (
                  <div className={classes.chips}>
                    {(selected as string[]).map((value) => (
                      <Chip
                        key={value}
                        label={
                          clinicStaffPositions.find((x) => x.value == value)
                            ?.label
                        }
                        className={classes.chip}
                      />
                    ))}
                  </div>
                )}
                open={openMultiSelect}
                onOpen={() => setOpenMultiSelect(true)}
                onClose={() => setOpenMultiSelect(false)}
                onChange={(ev) => {
                  const value = ev.target.value as Array<string | undefined>
                  if (value.indexOf(undefined) > -1) {
                    value.splice(value.indexOf(undefined), 1)
                  }
                  setFieldValue('positions', value)
                }}
              >
                <MenuItem
                  className={classes.dropdownItemCentered}
                  onClick={() => {
                    setOpenMultiSelect(false)
                  }}
                >
                  Close
                </MenuItem>
                {clinicStaffPositions.map((val) => (
                  <MenuItem key={val.value} value={val.value}>
                    <Checkbox
                      checked={values.positions.indexOf(val.value) > -1}
                    />
                    {val.label}
                  </MenuItem>
                ))}
                <MenuItem
                  key="Other"
                  value="Other"
                  onClick={() => {
                    if (otherSocietyShow) {
                      setOtherSociety('')
                    }
                    setOtherSocietyShow(!otherSocietyShow)
                  }}
                >
                  <Checkbox checked={otherSocietyShow} />
                  Other
                </MenuItem>
              </Select>
            </>
          )}
        </Box>
        <Button type="submit" loading={isSubmitting} disabled={!isValid}>
          Continue
        </Button>
        <Button onClick={() => pushNextOnboardingStep()} variant={'text'}>
          Skip for now
        </Button>
      </form>
    </Box>
  )
}

export default AccountDetailsStep
