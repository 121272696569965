import { FC } from 'react'
import {
  Body1,
  Box,
  Button,
  capitalize,
  DialogTrigger,
  H3,
  Link,
  makeStyles,
} from '@perk-ui/core'
import { Edit, Trash2 } from 'react-feather'
import { useRouteMatch } from 'react-router'

import DataPair from '../../../components/DataPair'
import useProtocol from '../../../features/query-hooks/useProtocol'
import { ProtocolDiagnosis } from '../../../features/query-hooks/useProtocols'
import ProtocolFlowUrls, {
  ProtocolFlowLocationParams,
} from '../ProtocolFlowUrls'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  treatmentDate: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    fontWeight: 'bold',
  },
  editButton: {
    marginLeft: theme.spacing(3),
  },
  dialogTrigger: {
    display: 'inline',
  },
  deleteButton: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    marginLeft: theme.spacing(3),
  },
  dialogContent: {
    '& > p': {
      textAlign: 'center',
    },
  },
  dialogButtons: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'center',
    '& button+button': {
      marginLeft: theme.spacing(5),
    },
  },
  accordion: {
    // Ovverrides of MUI styles
    '&&': {
      boxShadow: 'none',
      margin: 0,
      '&:before': {
        content: 'none',
      },
    },
  },
  accordionSummary: {
    // Overrides of MUI styles
    '&&': {
      paddingLeft: 0,
      minHeight: 0,
      '& > *': {
        margin: 0,
      },
    },
  },
  accordionTitle: {
    fontWeight: 500,
  },
  accordionDetails: {
    display: 'flex',
    flexDirection: 'column',

    '&&': {
      padding: 0,
      paddingTop: theme.spacing(1),
    },
  },
  dataPair: {
    '& p.MuiTypography-root': {
      marginBottom: theme.spacing(1),
      '& span': {
        display: 'block',
        fontWeight: 700,
      },
    },
  },
  periodChip: {
    backgroundColor: '#269DD924',
    color: '#2396CF',
    fontSie: '16px',
    fontWeight: 400,
    marginLeft: theme.spacing(1),
  },
}))

export interface DiagnosisDetailProps {
  diagnosis: ProtocolDiagnosis
  onEditClick?: () => void
  onDeleteClick?: () => void
}

const DiagnosisDetail: FC<DiagnosisDetailProps> = ({
  diagnosis,
  onEditClick,
  onDeleteClick,
}) => {
  const classes = useStyles()
  const hasProtocolMatch = useRouteMatch<ProtocolFlowLocationParams>(
    ProtocolFlowUrls.hasProtocol.match,
  )
  const protocolId = hasProtocolMatch ? hasProtocolMatch.params.protocolId : ''
  const hasProtocol = !!hasProtocolMatch
  const { data: protocol } = useProtocol(protocolId, {
    enabled: hasProtocol,
  })
  if (!diagnosis) return null

  return (
    <>
      <Box
        pr={2}
        display="flex"
        justifyContent="space-between"
        flexDirection="row"
        pt={2}
        pb={1}
      >
        <H3 sx={{ pointerEvents: 'none' }}>Patient Intake</H3>
        <Box display="flex">
          {onEditClick && (
            <Link
              className={classes.editButton}
              component="button"
              underline="hover"
              onClick={onEditClick}
            >
              <Edit />
            </Link>
          )}
          {onDeleteClick && !diagnosis.isPrimary && (
            <DialogTrigger
              action={
                <Link
                  component={Body1}
                  className={classes.deleteButton}
                  underline="hover"
                >
                  <Trash2 />
                </Link>
              }
              content={(toggle) => (
                <Box p={3} className={classes.dialogContent}>
                  <Body1>Are you sure you want to delete this treatment?</Body1>
                  <div className={classes.dialogButtons}>
                    <Button
                      size="medium"
                      variant="outlined"
                      onClick={onDeleteClick}
                    >
                      Confirm
                    </Button>
                    <Button size="medium" onClick={() => toggle()}>
                      Cancel
                    </Button>
                  </div>
                </Box>
              )}
            />
          )}
        </Box>
      </Box>
      <Box className={classes.dataPair}>
        <DataPair label="Body Part" value={protocol?.bodySite?.name} />
        {diagnosis.medicalCondition && (
          <DataPair
            label="Primary Diagnosis"
            value={diagnosis.medicalCondition.name}
          />
        )}
        {diagnosis.locations && (
          <DataPair
            label="Clinical classification of severity"
            value={capitalize(diagnosis.severity)}
          />
        )}
        {diagnosis.locations && (
          <DataPair
            label="Locations Details"
            value={diagnosis.locations.join(', ')}
          />
        )}
      </Box>
    </>
  )
}

export default DiagnosisDetail
