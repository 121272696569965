var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useState } from 'react';
import MUIButton from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { makeStyles } from '../';
// Most styles are applied via the theme. These styles are dynamically applied, and therefore don't fit into the theme object.
const useStyles = makeStyles((theme) => ({
    // Our custom loading state style.
    containedPrimaryLoading: {
        cursor: 'default',
        '&, &:hover': {
            backgroundColor: theme.palette.primary.light,
            color: theme.palette.primary.contrastText,
        },
    },
    // Loading state style for the outlined variant, which is used by ButtonGroup.
    outlinedLoading: {
        cursor: 'default',
        '&, &:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
    // The selected state is used by the ButtonGroup.
    selected: {
        '&, &:hover': {
            backgroundColor: theme.palette.primary.main,
            color: theme.palette.primary.contrastText,
        },
    },
}), { name: 'Button' });
/**
 * Extends the MUI Button to add loading and selected states. Defaults to contained primary style.
 *
 * If an `onClick` handler returns a Promise and a `loading` prop is not provided, it will
 * toggle its own loading state.
 */
const Button = (_a) => {
    var { 
    // function Button<C extends React.ElementType>({
    children = null, loading: loadingProp = null, selected = false, onClick = undefined } = _a, rest = __rest(_a, ["children", "loading", "selected", "onClick"]);
    // }: ButtonProps<C, { component?: C}>) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(loadingProp);
    // If a loading prop is provided, always use that. Otherwise, maintain our own loading state.
    const hasLoadingProp = loadingProp != null;
    const isLoading = hasLoadingProp ? loadingProp : loading;
    return (_jsx(MUIButton, Object.assign({ classes: {
            containedPrimary: isLoading
                ? classes.containedPrimaryLoading
                : undefined,
            outlined: isLoading ? classes.outlinedLoading : undefined,
            root: selected ? classes.selected : undefined,
        }, onClick: (event) => {
            const handlerValue = onClick === null || onClick === void 0 ? void 0 : onClick(event);
            if (!hasLoadingProp && handlerValue instanceof Promise) {
                setLoading(true);
                handlerValue.finally(() => setLoading(false));
            }
        } }, rest, { children: isLoading ? _jsx(CircularProgress, { color: "inherit", size: 24 }, void 0) : children }), void 0));
};
export default Button;
