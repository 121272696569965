import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import { useUser } from '../auth/AuthContext'
import QueryKeys from './QueryKeys'
import { Protocol } from './useProtocols'

interface CreateProtocolResponse {
  protocol: Protocol
}

interface CreateCaseVars {}

const useCreateProtocol = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id
  const currentUser = useUser()

  return useMutation((vars: CreateCaseVars) => {
    const url = `/sites/protocols`

    return request
      .post<CreateProtocolResponse>(url, {
        siteIds: [siteId],
        protocol: {
          siteId: siteId,
          userId: currentUser.id,
          ...vars,
        },
      })
      .then((response) => {
        queryClient.invalidateQueries(QueryKeys.protocols({ clinicId: siteId }))
        return response.data.protocol
      })
  })
}

export default useCreateProtocol
