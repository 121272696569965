import { FC, useState } from 'react'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  H3,
  makeStyles,
  Typography,
} from '@perk-ui/core'
import { ChevronDown } from 'react-feather'

import { Patient } from '../features/query-hooks/usePatient'
import useDates from '../hooks/useDates'
import DataPair from './DataPair'

export interface PatientInfoProps {
  patient: Patient
}

const useStyles = makeStyles((theme) => ({
  rowContainer: {
    display: 'flex',
    flexDirection: 'row',
    paddingBottom: theme.spacing(2),
    marginBottom: theme.spacing(2),
    borderBottom: '1px solid #e3e3e3',
  },
  columnContainer: {
    marginRight: theme.spacing(4),
  },
  cardTitle: {
    marginBottom: theme.spacing(2),
  },
  content: {
    overflow: 'auto',
    maxHeight: '300px',
  },
}))

const PatientCommunicationsInfo: FC<PatientInfoProps> = ({ patient }) => {
  const classes = useStyles()
  const [expanded, setExpanded] = useState<string | false>(false)
  const { localizedFormatDate: localizedFormatDateWithTime } = useDates(true)

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false)
    }

  return (
    <>
      <H3 className={classes.cardTitle}>Patient Communications</H3>
      <div className={classes.content}>
        {patient &&
          patient.patientCommunications?.map((comm) => {
            return (
              <Accordion
                key={comm.id}
                expanded={expanded === comm.id}
                onChange={handleChange(comm.id)}
              >
                <AccordionSummary
                  expandIcon={<ChevronDown />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography sx={{ width: '33%', flexShrink: 0 }}>
                    {comm.commChannel}
                  </Typography>
                  <Typography sx={{ color: 'text.secondary' }}>
                    {localizedFormatDateWithTime(new Date(comm.createdAt))}
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <DataPair label="Endpoint" value={comm.endpoint} />
                  <DataPair
                    label="Purpose"
                    value={comm.purpose.split('::')[2]}
                  />
                  <DataPair label="Status" value={comm.status} />
                  {comm.commChannel === 'sms' && (
                    <DataPair label="Content" value={comm.content} />
                  )}
                  {comm.commChannel === 'email' && (
                    <DataPair
                      label="Content"
                      value={JSON.parse(comm.content)['link']}
                    />
                  )}
                  <DataPair
                    label="Delivery Result"
                    value={comm.deliveryResults}
                  />
                </AccordionDetails>
              </Accordion>
            )
          })}
        {patient && patient.patientCommunications?.length === 0 && (
          <span>No patient communication records for this patient</span>
        )}
      </div>
    </>
  )
}

export default PatientCommunicationsInfo
