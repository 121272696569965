import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export interface TreatmentProduct {
  id: string
  name: string // 'EmCyte AbsolutePRP'
  onlyForStudy: boolean
}

interface TreatmentProductResponse {
  treatmentProducts: TreatmentProduct[]
}

const useTreatmentProducts = (
  medicalTreatmentId?: string,
  extraOptions: UseQueryOptions<TreatmentProduct[]> = {},
) => {
  const siteId = useCurrentSite().id
  const key = medicalTreatmentId
    ? QueryKeys.treatmentProducts(medicalTreatmentId)
    : []
  const url = `/sites/treatment_products?site_ids[]=${siteId}&medical_treatment_id=${medicalTreatmentId}`

  return useQuery(
    key,
    () =>
      request
        .get<TreatmentProductResponse>(url)
        .then((r) => r.data.treatmentProducts),
    {
      // TreatmentProducts are static - don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      enabled: Boolean(medicalTreatmentId),
      ...extraOptions,
    },
  )
}

export default useTreatmentProducts
