import { AppConfig } from '../app-config'

const developmentConfig: AppConfig = {
  baseApiPath: 'http://localhost:21003',
  authenticatedRootUrl: '/home',
  unAuthenticatedRootUrl: '/login',
  authHost: 'http://localhost:21001',
  apmUrl: 'https://staging-apm.databiologics.com',
  tableauProductionBaseUrl: 'https://impact.databiologics.com/',
  tableauProductionEmbeddedApiUrl:
    'https://impact.databiologics.com/javascripts/api/tableau.embedding.3.latest.min.js',
  tableauStagingBaseUrl: 'https://access.databiologics.com/',
  tableauStagingEmbeddedApiUrl:
    'https://access.databiologics.com/javascripts/api/tableau.embedding.3.latest.min.js',
  googleMapsApiKey: 'AIzaSyDI2QYc4_MUQBPdgJeefb1sAQkEeAvf35g',
  stripePublictKey:
    'pk_test_51Mt0Y9Avm1kprluZY3C8E1ckDIwUgzJufUXnh7jCwT4YKC5P2rgAar1zlqdXDSPf6xIHHvtfQmXHZpOG0MKsEbdQ00UYx3BNJp',
  newRelicAccountID: '4313352',
  newRelicTrustKey: '4313352',
  newRelicAgentID: '1103305825',
  newRelicLicenseKey: 'NRJS-c7c9c3e2974022f7dd5',
  newRelicApplicationID: '1103305825',
}

export default developmentConfig
