import { FC } from 'react'
import { Box, Card, makeStyles } from '@perk-ui/core'
import { StringParam, useQueryParam } from 'use-query-params'

import useAggregateOutcomesFilters from '../../features/query-hooks/useAggregateOutcomesFilters'
import { BodySiteKey } from '../../features/query-hooks/useBodySites'
import ClinicOverview from './components/ClinicOverview'
import DataSidebar from './components/DataSidebar'
import Outcome from './components/Outcome'

export const SurveySortOrder = [
  'HOOS-JR',
  'KOOS-JR',
  'FAAM',
  'VISA-A',
  'NDI',
  'ODI',
  'QUICKDASH',
  'GAD-7',
  'PCL-5',
  'PHQ4',
  'NUMERIC-PAIN-RATING',
  'DESIRED-FUNCTIONALITY',
  'SATISFACTION-SCORE',
]

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '70vh',
    maxHeight: '85vh',
    display: 'flex',
  },
  sidebar: {
    width: '25%',
    height: 'inherit',
  },
  content: {
    overflow: 'auto',
    width: '75%',
    borderLeft: `1px solid ${theme.palette.divider}`,
  },
  header: {
    padding: theme.spacing(3, 0),
  },
  back: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    color: theme.palette.primary.main,
  },
  diagnosisSection: {
    paddingTop: theme.spacing(2),
  },
  diagnosisItem: {
    minHeight: theme.spacing(4.5),
    cursor: 'pointer',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  centered: {
    width: '100%',
    textAlign: 'center',
  },
}))

export interface DataProps {}

const Data: FC<DataProps> = () => {
  const classes = useStyles()
  const [tab, setTab] = useQueryParam('tab', StringParam)
  const [treatment, setTreatment] = useQueryParam('treatment', StringParam)
  const [diagnosis, setDiagnosis] = useQueryParam('diagnosis', StringParam)
  const [provider, setProvider] = useQueryParam('provider', StringParam)
  const [sex, setSex] = useQueryParam('sex', StringParam)
  const [age, setAge] = useQueryParam('age', StringParam)
  const [bmi, setBmi] = useQueryParam('bmi', StringParam)
  const { data: aggregateOutcomesFilters } = useAggregateOutcomesFilters()

  const handleSidebarTabClick = (tab: BodySiteKey | 'clinic-overview') => {
    setTab(tab)
    setTreatment('all')
    setDiagnosis('all')
    setProvider('all')
    setSex('all')
    setAge('all')
    setBmi('avg')
  }

  return (
    <Box p={3}>
      <Card className={classes.root}>
        <Box className={classes.sidebar}>
          <DataSidebar
            tab={tab as BodySiteKey}
            onItemClick={handleSidebarTabClick}
          />
        </Box>
        <Box className={classes.content}>
          {tab === 'clinic-overview' ? (
            <ClinicOverview />
          ) : (
            <Outcome
              bodyPart={tab}
              filters={aggregateOutcomesFilters?.filters}
              treatment={treatment}
              setTreatment={setTreatment}
              diagnosis={diagnosis}
              setDiagnosis={setDiagnosis}
              provider={provider}
              setProvider={setProvider}
              sex={sex}
              setSex={setSex}
              age={age}
              setAge={setAge}
              bmi={bmi}
              setBmi={setBmi}
            />
          )}
        </Box>
      </Card>
    </Box>
  )
}

export default Data
