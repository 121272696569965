import { FC, useState } from 'react'
import { Alert, Box, Button, H1, H3, PageTitle, TextField } from '@perk-ui/core'
import { useFormik } from 'formik'
import * as yup from 'yup'

import AppLink from '../../../components/AppLink'
import SideBannerContainer from '../../../components/SideBannerContainer'
import { useAuth } from '../../../features/auth/AuthContext'
import AuthenticationBase from '../AuthenticationBase'
import AuthenticationBanner from '../components/AuthenticationBanner'

interface ForgotPasswordProps {}

interface ForgotPasswordFormValues {
  email: string
}

const ForgotPassword: FC<ForgotPasswordProps> = () => {
  const { requestPasswordReset } = useAuth()
  const [emailSent, setEmailSent] = useState(false)

  const loginValidations = yup.object({
    email: yup
      .string()
      .required('Email is required')
      .email('Ensure the email is formatted correctly'),
  })

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    isSubmitting,
    isValid,
    touched,
  } = useFormik<ForgotPasswordFormValues>({
    enableReinitialize: true,
    initialValues: {
      email: '',
    },
    validationSchema: loginValidations,
    onSubmit: (values) => {
      return requestPasswordReset(values.email).then(() => {
        setEmailSent(true)
      })
    },
  })

  return (
    <SideBannerContainer
      bannerWidth={5}
      bannerSide="left"
      banner={<AuthenticationBanner />}
    >
      <PageTitle title="Forgot Password" />
      <AuthenticationBase>
        <Box className="form-header">
          <H1>Recover Password</H1>
          <H3>Enter your email to receive a recovery link</H3>
        </Box>
        <form className="form-content" onSubmit={handleSubmit}>
          {emailSent && (
            <Alert severity="success" fade grow>
              Please check your email for the next step.
            </Alert>
          )}
          <TextField
            label="Email"
            name="email"
            placeholder="Enter email"
            fullWidth
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
          <Button
            type="submit"
            fullWidth
            loading={isSubmitting}
            disabled={!isValid}
          >
            Send Password Recovery Email
          </Button>
          <Box textAlign={'center'}>
            <span>
              <AppLink to="/login-new">Login</AppLink>
            </span>
          </Box>
        </form>
      </AuthenticationBase>
    </SideBannerContainer>
  )
}

export default ForgotPassword
