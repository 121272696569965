import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { PatientDiagnosis } from './useCreateDiagnosis'

interface UpdateDiagnosisResponse {
  patientDiagnosis: PatientDiagnosis
}

export interface UpdateDiagnosisVars extends PatientDiagnosis {
  patientId: string
}

const useUpdateDiagnosis = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation(({ patientId, ...vars }: UpdateDiagnosisVars) => {
    const url = `/sites/patient_diagnoses/${vars.id}`

    return request
      .patch<UpdateDiagnosisResponse>(url, {
        siteIds: [siteId],
        patientDiagnosis: {
          siteId: siteId,
          ...vars,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.patientCases({ patientId }))

        return r.data.patientDiagnosis
      })
  })
}

export default useUpdateDiagnosis
