import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { PatientCase } from './usePatientCases'

interface CreateCaseResponse {
  patientCase: PatientCase
}

interface CreateCaseVars
  extends Pick<
    PatientCase,
    'anticipatedTreatmentDate' | 'bodySiteId' | 'patientId' | 'protocolId'
  > {}

const useCreateCase = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation((vars: CreateCaseVars) => {
    const url = `/sites/patient_cases`

    return request
      .post<CreateCaseResponse>(url, {
        siteIds: [siteId],
        patientCase: {
          siteId: siteId,
          ...vars,
        },
      })
      .then((response) => {
        queryClient.invalidateQueries(
          QueryKeys.patientCases({ patientId: vars.patientId }),
        )
        queryClient.invalidateQueries(
          QueryKeys.bodyPartsSurveyCompletionStatus({
            currentSiteId: siteId,
            patientId: vars.patientId,
          }),
        )
        queryClient.invalidateQueries(QueryKeys.upcomingDashboard(siteId))
        queryClient.invalidateQueries(
          QueryKeys.patient({
            currentSiteId: siteId,
            patientId: response.data?.patientCase.patientId,
          }),
        )
        queryClient.invalidateQueries(
          QueryKeys.patientsSearch({ currentSiteId: siteId }),
        )

        return response.data.patientCase
      })
  })
}

export default useCreateCase
