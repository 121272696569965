import React from 'react'
import { Box, H3 } from '@perk-ui/core'

import PatientOpenSurveys from '../../../../components/PatientOpenSurveys'
import { Patient } from '../../../../features/query-hooks/usePatient'
import { SurveyResponseGroup } from '../../../../features/query-hooks/useSurveyResponseGroups'
import SurveyCompletion from './components/SurveyCompletion'

export interface SurveyStatusProps {
  openSurveys: SurveyResponseGroup[]
  patient: Patient
}

const SurveyStatus: React.FC<SurveyStatusProps> = ({
  openSurveys,
  patient,
}) => {
  return (
    <>
      <Box mb={6}>
        <H3>Survey Status</H3>
      </Box>
      <PatientOpenSurveys openSurveys={openSurveys} patient={patient} />
      <Box mt={2}>
        <SurveyCompletion />
      </Box>
    </>
  )
}

export default SurveyStatus
