import { FC } from 'react'
import { Box, Button, makeStyles } from '@perk-ui/core'
import clsx from 'clsx'
import { ArrowLeft, LogOut } from 'react-feather'
import { Route, Switch, useHistory, useLocation } from 'react-router'

import SideBannerContainer from '../../components/SideBannerContainer'
import { useAuth } from '../../features/auth/AuthContext'
import useOnboardingStep from '../../features/query-hooks/useOnboardingStep'
import OnboardingBanner from './components/OnboardingBanner'
import AccountDetailsStep from './steps/AccountDetailsStep'
import ClinicSetupStep from './steps/ClinicSetupStep'
import ClinicUsersStep from './steps/ClinicUsersStep'
import ContractSignatureStep from './steps/ContractSignatureStep'
import PaymentInformationStep from './steps/PaymentInformationStep'

interface OnboardingProps {}
const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexFlow: 'column',
    padding: theme.spacing(3),
    maxWidth: '80%',
    // height: '100vh',
    margin: '0 auto',
    '& .form-content': {
      display: 'flex',
      overflow: 'hidden',
      maxHeight: '90%',
      '& form': {
        overflow: 'auto',
        height: '100%',
        '& .MuiButton-root': {
          marginBottom: 0,
        },
        '& .MuiFormControl-root': {
          marginBottom: 0,
        },
      },
      '&.full-height': {
        maxHeight: '100%',
      },
    },
    [theme.breakpoints.down('md')]: {
      maxWidth: '100%',
    },
  },
  stepperContainer: {
    flex: '0 1 10px',
    display: 'flex',
    flexDirection: 'row',
    gridGap: theme.spacing(1),
    padding: theme.spacing(0, 2),
  },
  step: {
    borderRadius: theme.spacing(1),
    width: '75px',
    height: '10px',
    background: '#F1F1F1',
  },
  activeStep: {
    background: 'var(--brand-gradient,linear-gradient(118deg,#2badee,#526ceb))',
  },
  actionsContainer: {
    display: 'flex',
    flex: ' 0 1 auto',
    width: '100%',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 2, 1, 2),
    '& button': {
      padding: theme.spacing(2, 3),
      '&:hover': {
        color: theme.palette.primary.dark,
      },
    },
  },
  stepContainer: {
    flex: '1 1 auto',
    padding: theme.spacing(2, 2, 0, 2),
    overflow: 'hidden',
    '& .step-header': {
      margin: theme.spacing(3, 0, 4, 0),
      '& h1': {
        fontSize: '32px',
        fontWeight: '700',
      },
      '& h3': {
        fontSize: '16px',
        fontWeight: '500',
        '& a': {
          color: theme.palette.common.black,
        },
      },
    },
    '& .step-content': {
      display: 'flex',
      flexFlow: 'column',
      height: '100%',
      '& form': {
        height: '100%',
        overflow: 'auto',
        '& .MuiFormControl-root': {
          marginBottom: theme.spacing(0),
        },
        '& .MuiButton-root': {
          marginBottom: theme.spacing(0),
        },
      },
    },
  },
  backButton: {},
  exitButton: {},
}))

export const onboardingUrls = [
  '/onboarding/clinic-setup',
  '/onboarding/contract-signature',
  '/onboarding/payment-information',
  '/onboarding/account-details',
  '/onboarding/clinic-users',
]

const Onboarding: FC<OnboardingProps> = () => {
  const classes = useStyles()
  const { signOut } = useAuth()
  const { push } = useHistory()
  const { data: currentStep, isLoading } = useOnboardingStep()
  const { pathname } = useLocation()
  // const [currentOnboardingStep, setCurrentOnboardingStep] = useState<number>(1)

  const onboardingSteps = [
    {
      step: 1,
      key: 'clinic-setup',
    },
    {
      step: 2,
      key: 'contract-signature',
    },
    {
      step: 3,
      key: 'payment-information',
    },
    {
      step: 4,
      key: 'account-details',
    },
    {
      step: 5,
      key: 'clinic-users',
    },
  ]

  // if (currentStep == 'done') push('/home')
  if (!isLoading && currentStep && pathname == '/onboarding')
    push('/onboarding/' + currentStep)

  const currentStepIndex =
    (onboardingSteps.find((step) => step.key == (currentStep || ''))?.step ||
      0) - 1
  const pathStepIndex =
    (onboardingSteps.find((step) => step.key == pathname.split('/')[2])?.step ||
      0) - 1

  const currentOnboardingStep = onboardingUrls.indexOf(pathname) + 1
  const goBack = () => {
    push(onboardingUrls[currentOnboardingStep - 2])
  }
  const urlComponent = (step: number) => {
    if (
      !isLoading &&
      currentStep &&
      (currentStepIndex < pathStepIndex || pathStepIndex < 0) &&
      currentStep !== 'done'
    )
      return
    switch (step) {
      case 0:
        return ClinicSetupStep
      case 2:
        return PaymentInformationStep
      case 3:
        return AccountDetailsStep
      case 4:
        return ClinicUsersStep
      default:
        break
    }
  }

  if (
    !isLoading &&
    currentStep &&
    (currentStepIndex < pathStepIndex || pathStepIndex < 0) &&
    currentStep !== 'done'
  )
    push('/onboarding/' + currentStep)

  return (
    <SideBannerContainer
      bannerWidth={4}
      bannerSide="left"
      banner={<OnboardingBanner />}
    >
      <Box className={classes.root}>
        <Box className={classes.actionsContainer}>
          <Button
            onClick={goBack}
            disabled={currentOnboardingStep === 1}
            className={classes.backButton}
            startIcon={<ArrowLeft />}
            variant="text"
          >
            Back
          </Button>
          <Button
            onClick={signOut}
            className={classes.exitButton}
            startIcon={<LogOut />}
            variant="text"
          >
            Exit
          </Button>
        </Box>
        <Box className={classes.stepperContainer}>
          {onboardingSteps.map((step) => (
            <div
              key={step.key}
              className={clsx(classes.step, {
                [classes.activeStep]: currentOnboardingStep >= step.step,
              })}
            />
          ))}
        </Box>
        <Box className={classes.stepContainer} p={3}>
          <Switch>
            {onboardingUrls.map((step, index) => {
              if (index == 1)
                return (
                  <Route
                    path={step}
                    key={index}
                    render={() => (
                      <ContractSignatureStep type="authenticated" />
                    )}
                  />
                )
              return (
                <Route
                  key={index}
                  path={step}
                  exact
                  component={urlComponent(index)}
                />
              )
            })}
          </Switch>
        </Box>
      </Box>
    </SideBannerContainer>
  )
}

export default Onboarding
