import { addWeeks, differenceInWeeks, format, formatDistanceStrict, isAfter, isToday, isTomorrow, isWithinInterval, isYesterday, parse, subMonths, subWeeks, } from 'date-fns';
/**
 *  Returns a human readable relative date string with these patterns:
 *    - Today
 *    - Yesterday
 *    - 2..6 days + 'ago'
 *    - 1..4 weeks + 'ago'
 *    - 1..11 months + 'ago'
 *    - N years + 'ago'
 *
 * @param date A ms-based number, or a Date object
 * @param baseDate the date to base the comparison to. Defaults to now
 */
export const toRelativeDateString = (date, baseDate = Date.now(), roundingMethod) => {
    if (isToday(date))
        return 'Today';
    if (isYesterday(date))
        return 'Yesterday';
    if (isTomorrow(date))
        return 'Tomorrow';
    const oneWeekAgo = subWeeks(baseDate, 1);
    const oneWeekFromNow = addWeeks(baseDate, 1);
    if (isWithinInterval(date, { start: oneWeekAgo, end: oneWeekFromNow })) {
        return formatDistanceStrict(date, baseDate, {
            addSuffix: true,
            roundingMethod: roundingMethod,
        });
    }
    // Unfortunately, formatDistanceStrict does not accept a `unit` of weeks
    // If it did, everything after `isYesterday` would be just one
    // formatDistanceStrict call.
    const lastMonth = subMonths(baseDate, 1);
    if (isAfter(date, lastMonth)) {
        const weeksSince = Math.abs(differenceInWeeks(date, baseDate));
        return `${weeksSince} week${weeksSince > 1 ? 's' : ''} ago`;
    }
    return formatDistanceStrict(date, baseDate, {
        addSuffix: true,
    });
};
/**
 *  Returns a human readable relative date string with these patterns:
 *    - Today
 *    - Yesterday
 *    - 'Last' + Day of the week
 *    - m/d/y date
 *
 * @param date A ms-based number, or a Date object
 * @param baseDate The date to base the comparison to. Defaults to now
 */
export const toRelativeDay = (date, baseDate = Date.now()) => {
    if (isToday(date))
        return 'Today';
    if (isYesterday(date))
        return 'Yesterday';
    const oneWeekAgo = subWeeks(baseDate, 1);
    if (isAfter(date, oneWeekAgo)) {
        return `Last ${format(date, 'EEEE')}`;
    }
    return format(date, 'M/d/y');
};
/**
 * Parses a YYYY-MM-DD date like ones that Rails generates.
 * Returns a Date object at the 0th minute of the day, in the
 * users local timezone.
 *
 * @explanation
 * Dates in this format do not have a timezone so `new Date(x)`
 * parses it as having a GMT-timezone. This causes problems as JS
 * then turns that date into a local timezone, potentially displaying the
 * day incorrectly.
 * i.e. 2021-06-08 is parsed as 2021-06-08T00:00:00.000Z
 * Which, when rendered locally before 8PM EST, is shown as June 7th (not 8th).
 */
export const parseYMD = (date) => typeof date === 'string' ? parse(date, 'yyyy-MM-dd', new Date()) : date;
