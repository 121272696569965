import { FC, useEffect, useState } from 'react'
import {
  Autocomplete,
  Button,
  ButtonGroup,
  capitalize,
  FormHelperText,
  InputLabel,
  makeStyles,
  TextField,
} from '@perk-ui/core'
import clsx from 'clsx'
import { FormikErrors, FormikTouched, useFormik } from 'formik'
import { matchSorter } from 'match-sorter'

import useAnatomicalLocations from '../../../../features/query-hooks/useAnatomicalLocations'
import { MedicalCondition } from '../../../../features/query-hooks/useMedicalConditions'
import { ProtocolDiagnosisSeverity } from '../../../../features/query-hooks/useProtocols'

export interface DiagnosisFormValues {
  /**
   * An id exists only for Diagnoses that have previously been saved to the DB
   */
  id?: string
  // radiographicClassification?: PatientDiagnosisRadiographicClassification
  locations: string[]
  severity: ProtocolDiagnosisSeverity
  medicalConditionId?: string
  medicalCondition?: MedicalCondition
}

const useStyles = makeStyles((theme) => ({
  removeContainer: {
    display: 'flex',
    alignItems: 'flex-end',
    '& > *:first-child': {
      flex: 5,
    },
  },
  removeButton: {
    flex: 1,
    height: 'auto',
    marginLeft: theme.spacing(2),
  },
  severityButton: {
    color: theme.palette.primary.main,
  },
  severityButtonSelected: {
    color: theme.palette.common.white,
  },
  severityButtonGroup: {
    marginTop: theme.spacing(1),
  },
}))

export interface DiagnosisFormProps {
  diagnosis: DiagnosisFormValues
  bodySiteId: string
  conditions: MedicalCondition[]
  setFieldValue: ReturnType<typeof useFormik>['setFieldValue']
  handleBlur: ReturnType<typeof useFormik>['handleBlur']
  touched: FormikTouched<DiagnosisFormValues> | null
  errors: FormikErrors<DiagnosisFormValues> | null | string
  index: number
  onRemove: () => Promise<void>
}

const DiagnosisForm: FC<DiagnosisFormProps> = ({
  diagnosis,
  bodySiteId,
  conditions,
  setFieldValue,
  touched,
  handleBlur,
  errors,
  onRemove,
  index,
}) => {
  const classes = useStyles()
  const name = (label: string) => `diagnoses.${index}.${label}`
  const { data: anatomicalLocations = [] } = useAnatomicalLocations(
    diagnosis.medicalConditionId,
  )
  const [
    availableMedicalConditionsOptions,
    setAvailableMedicalConditionsOptions,
  ] = useState<MedicalCondition[]>([])

  useEffect(() => {
    if (index === 0 && bodySiteId) {
      const filteredMedicalConditions = conditions.filter(
        (mc) => mc.onlyForStudy == false,
      )
      setAvailableMedicalConditionsOptions(filteredMedicalConditions)
    } else {
      setAvailableMedicalConditionsOptions(conditions)
    }
  }, [conditions])

  useEffect(() => {
    if (diagnosis.severity) setFieldValue(name('severity'), diagnosis.severity)
  }, [diagnosis])

  // const activeBodySite = bodySites.find(
  //   (site) => site.id === bodySiteId,
  // )
  // const isKneeOrHip =
  //   activeBodySite?.bodySiteKey === 'knee' ||
  //   activeBodySite?.bodySiteKey === 'hip'
  // const activeCondition = availableMedicalConditionsOptions.find(
  //   (cond) => cond.id == diagnosis.medicalConditionId,
  // )
  // const showRadiographicClassificationInput =
  //   isKneeOrHip && activeCondition?.name == 'Osteoarthritis'

  return (
    <>
      <div className={classes.removeContainer}>
        <Autocomplete
          options={availableMedicalConditionsOptions}
          getOptionLabel={(opt) => opt.name}
          isOptionEqualToValue={(opt, val) => opt.id === val.id}
          filterOptions={(options, { inputValue }) =>
            inputValue
              ? matchSorter(options, inputValue, { keys: ['name'] })
              : options
          }
          value={
            conditions.find(
              (condition) => condition.id === diagnosis.medicalConditionId,
            ) ?? null
          }
          onChange={(_event, condition) => {
            // unset the location details when the condition changes
            setFieldValue(name('locations'), [])

            setFieldValue(name('medicalConditionId'), condition?.id || '')
          }}
          renderInput={(params) => {
            // @ts-expect-error Mui's autocomplete types here are incomplete
            params.InputLabelProps.shrink = true
            const label =
              index === 0 ? 'Primary Diagnosis' : 'Secondary Diagnosis'
            const innerErrors =
              errors as FormikErrors<DiagnosisFormValues> | null
            return (
              <TextField
                {...params}
                label={label}
                name={name('medicalConditionId')}
                required
                value={diagnosis.medicalConditionId}
                onBlur={handleBlur}
                error={
                  touched?.medicalConditionId &&
                  Boolean(innerErrors?.medicalConditionId)
                }
                helperText={
                  touched?.medicalConditionId && innerErrors?.medicalConditionId
                }
              />
            )
          }}
        />
        {index !== 0 && (
          <Button
            className={classes.removeButton}
            variant="outlined"
            onClick={onRemove}
          >
            Remove
          </Button>
        )}
      </div>
      <div>
        <InputLabel required>Clinical classification of severity</InputLabel>
        <ButtonGroup
          orientation="horizontal"
          className={classes.severityButtonGroup}
        >
          {['mild', 'moderate', 'severe'].map((severity) => {
            const selected = diagnosis.severity === severity
            return (
              <Button
                key={severity}
                className={clsx(
                  classes.severityButton,
                  selected && classes.severityButtonSelected,
                )}
                size="large"
                selected={selected}
                onClick={() => {
                  setFieldValue(name('severity'), severity)
                }}
              >
                {capitalize(severity)}
              </Button>
            )
          })}
        </ButtonGroup>
        {typeof errors !== 'string' && errors?.severity && (
          <FormHelperText error>{errors?.severity}</FormHelperText>
        )}
      </div>

      {/* {showRadiographicClassificationInput && (
        <Autocomplete
          options={radiographicClassifications || []}
          getOptionLabel={(opt) => opt.label}
          isOptionEqualToValue={(opt, val) => opt.value === val.value}
          filterOptions={(options, { inputValue }) =>
            inputValue
              ? matchSorter(options, inputValue, { keys: ['name'] })
              : options
          }
          value={
            radiographicClassifications.find(
              (classif) =>
                classif.value === diagnosis.radiographicClassification,
            ) ?? null
          }
          onChange={(_event, classification) => {
            setFieldValue(
              name('radiographicClassification'),
              classification?.value || '',
            )
          }}
          renderInput={(params) => {
            // @ts-expect-error Mui's autocomplete types here are incomplete
            params.InputLabelProps.shrink = true
            return (
              <TextField
                {...params}
                label="Radiographic classification (Kellgren-Lawrence scale)"
              />
            )
          }}
        />
      )} */}
      <Autocomplete
        multiple
        disableCloseOnSelect
        disabled={!diagnosis.medicalConditionId}
        options={anatomicalLocations || []}
        getOptionLabel={(opt) => opt.name}
        isOptionEqualToValue={(opt, val) => opt.id === val.id}
        filterOptions={(options, { inputValue }) =>
          inputValue
            ? matchSorter(options, inputValue, { keys: ['name'] })
            : options
        }
        onChange={(_event, data) => {
          setFieldValue(
            name('locations'),
            data.map((d) => d.name),
          )
        }}
        value={
          anatomicalLocations.filter((loc) =>
            diagnosis.locations?.some((diagLoc) => diagLoc === loc.name),
          ) ?? null
        }
        renderInput={(params) => {
          // @ts-expect-error Mui's autocomplete types here are incomplete
          params.InputLabelProps.shrink = true
          return <TextField {...params} label="Location details" />
        }}
      />
    </>
  )
}

export default DiagnosisForm
