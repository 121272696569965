import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { AdverseEvent } from './useAdverseEvents'

interface UpdateAverseEventResponse {
  adverseEvent: AdverseEvent
}

export interface UpdateAdverseEventVars
  extends Pick<
    AdverseEvent,
    'id' | 'status' | 'seriousness' | 'expectedness' | 'relatedness'
  > {}

const useUpdateAdverseEvent = () => {
  const queryClient = useQueryClient()
  const currentSiteId = useCurrentSite().id

  return useMutation((vars: UpdateAdverseEventVars) => {
    const url = `/sites/adverse_events/${vars.id}?site_ids[]=${currentSiteId}`

    return request
      .patch<UpdateAverseEventResponse>(url, {
        siteIds: [currentSiteId],
        adverseEvent: {
          ...vars,
        },
      })
      .then((r) => {
        // Invalidate this particular AE
        queryClient.invalidateQueries(QueryKeys.adverseEvent(vars.id))
        // Invalidate all AE search queries.
        queryClient.invalidateQueries(
          QueryKeys.adverseEvents({ currentSiteId }),
        )

        return r.data.adverseEvent
      })
  })
}

export default useUpdateAdverseEvent
