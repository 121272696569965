import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import clsx from 'clsx';
import { Body1, DisplayVariants, Divider, H3, H5, makeStyles, Tooltip, } from '../';
const useStyles = makeStyles((theme) => ({
    item: {
        maxWidth: '100%',
        marginBottom: theme.spacing(1.3),
    },
    callout: {
        maxWidth: '100%',
        marginBottom: theme.spacing(1.3),
    },
    divider: {
        maxWidth: '100%',
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        borderBottomWidth: 1.5,
    },
    requiredField: {
        color: 'rgb(179, 0, 0)',
        fontWeight: 700,
        marginLeft: theme.spacing(0.5),
    },
    helperTextContainer: {
        display: 'flex',
        alignItems: 'center',
    },
    helpTextChip: {
        margin: theme.spacing(0, 0.5),
        border: `solid 1px ${theme.palette.primary.main}`,
        borderRadius: '10px',
        padding: theme.spacing(0, 0.5),
        fontSize: '11px',
        color: theme.palette.primary.main,
        cursor: 'help',
    },
}));
const SurveyItemDisplay = ({ item }) => {
    var _a, _b, _c, _d, _e;
    const classes = useStyles();
    // TODO Figure out how to mark things with asterisks
    // const asterisk = item.required ? (
    //   <span aria-hidden className="MuiFormLabel-asterisk">
    //     &thinsp;{'*'}
    //   </span>
    // ) : null
    if (item.custom && item.custom.component) {
        const Component = item.custom.component;
        return _jsx(Component, { className: classes.item }, void 0);
    }
    switch (item.variant) {
        case DisplayVariants.title: {
            return (_jsx(H3, Object.assign({ className: clsx(classes.item, (_a = item.custom) === null || _a === void 0 ? void 0 : _a.className) }, { children: item.data.value }), void 0));
        }
        case DisplayVariants.body: {
            return (_jsx(Body1, Object.assign({ className: clsx(classes.item, (_b = item.custom) === null || _b === void 0 ? void 0 : _b.className) }, { children: item.data.value }), void 0));
        }
        case DisplayVariants.callout: {
            const itemData = item.data.value;
            if (itemData === 'Cell Analysis' || itemData === 'New Injection Site')
                return null;
            return (_jsx(H5, Object.assign({ className: clsx(classes.callout, (_c = item.custom) === null || _c === void 0 ? void 0 : _c.className, {
                    [classes.helperTextContainer]: item.helpText,
                }) }, { children: item.data.value ? (item.data.value.trim().endsWith('*') ? (item.data.value.trim().replace(/.$/, '') && (_jsxs(_Fragment, { children: [item.data.value.trim().replace(/.$/, ''), _jsx("span", Object.assign({ className: classes.requiredField }, { children: "*" }), void 0), item.helpText && (_jsx(Tooltip, Object.assign({ title: item.helpText, arrow: true }, { children: _jsx("span", Object.assign({ className: classes.helpTextChip }, { children: "?" }), void 0) }), void 0))] }, void 0))) : (_jsxs(_Fragment, { children: [item.data.value.trim(), item.helpText && (_jsx(Tooltip, Object.assign({ title: item.helpText, arrow: true }, { children: _jsx("span", Object.assign({ className: classes.helpTextChip }, { children: "?" }), void 0) }), void 0))] }, void 0))) : (_jsxs(_Fragment, { children: [item.data.value, item.helpText && (_jsx(Tooltip, Object.assign({ title: item.helpText, arrow: true }, { children: _jsx("span", Object.assign({ className: classes.helpTextChip }, { children: "?" }), void 0) }), void 0))] }, void 0)) }), void 0));
        }
        case DisplayVariants.image: {
            return (_jsx("div", Object.assign({ className: clsx(classes.item, (_d = item.custom) === null || _d === void 0 ? void 0 : _d.className) }, { children: "Status: 418 \uD83E\uDED6" }), void 0));
        }
        case DisplayVariants.divider: {
            return (_jsx(Divider, { className: clsx(classes.divider, (_e = item.custom) === null || _e === void 0 ? void 0 : _e.className) }, void 0));
        }
        default:
            // The item is now known to be unknown. Allow the `any`
            // eslint-disable-next-line
            throw new Error(`Unknown DisplayVariant ${item.variant}.`);
    }
};
export default SurveyItemDisplay;
