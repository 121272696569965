import { FC, useEffect, useMemo, useState } from 'react'
import {
  Autocomplete,
  Body1,
  Box,
  Button,
  CircularProgress,
  DatePicker,
  Divider,
  findBy,
  FormControl,
  FormHelperText,
  H5,
  InputLabel,
  makeStyles,
  MenuItem,
  Select,
  TextField,
} from '@perk-ui/core'
import { addDays, addMonths, addWeeks, addYears, format } from 'date-fns'
import { useFormik } from 'formik'
import { matchSorter } from 'match-sorter'
import { useQueryClient } from 'react-query'
import { Redirect, useHistory, useLocation, useRouteMatch } from 'react-router'

import AppLink from '../../../components/AppLink'
import { buildTreatmentDetailSlug } from '../../../components/buildTreatmentDetailSlug'
import { useSitesContext } from '../../../components/SitesProvider'
import TreatmentDetail from '../../../components/TreatmentDetail'
import { qcKinetixProtocolsSecondaryTreatments } from '../../../config/constants'
import QueryKeys from '../../../features/query-hooks/QueryKeys'
import useBodyPartsSurveyCompletionStatus from '../../../features/query-hooks/useBodyPartsSurveyCompletionStatus'
import useBodySites from '../../../features/query-hooks/useBodySites'
import useCreateTreatment from '../../../features/query-hooks/useCreateTreatment'
import useDeleteTreatment from '../../../features/query-hooks/useDeleteTreatment'
import useMedicalTreatments from '../../../features/query-hooks/useMedicalTreatments'
import usePatientCases from '../../../features/query-hooks/usePatientCases'
import { PatientTreatment } from '../../../features/query-hooks/usePatientTreatment'
import useProtocols, {
  Protocol,
} from '../../../features/query-hooks/useProtocols'
import useProviders from '../../../features/query-hooks/useProviders'
import useSurveyResponseSearch from '../../../features/query-hooks/useSurveyResponseSearch'
import useTreatmentProducts from '../../../features/query-hooks/useTreatmentProducts'
import useUpdateTreatment from '../../../features/query-hooks/useUpdateTreatment'
import useDates from '../../../hooks/useDates'
import { buildName } from '../../../utils/buildName'
import { parseYMD } from '../../../utils/dates'
import PatientFlowUrls, { PatientFlowLocationParams } from '../PatientFlowUrls'
import useTreatment from './Treatment.hooks'
import TreatmentDetailsForm from './TreatmentDetailsForm'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'baseline',
  },
  form: {
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '& > .MuiDivider-root': {
      marginBottom: theme.spacing(3),
    },
  },
  loadingContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(6),
  },
  divider: {
    marginTop: theme.spacing(3),
  },
}))

const treatmentHasChanged = (
  treatment1: TreatmentFormValues,
  treatment2: PatientTreatment,
) => {
  if (treatment1.providerId !== treatment2.providerId) return true
  if (treatment1.medicalTreatmentId !== treatment2.medicalTreatmentId)
    return true
  if (treatment1.treatmentDate !== treatment2.treatmentDate) return true
  if (treatment1.treatmentProductId !== treatment2.treatmentProductId)
    return true

  return false
}

export const TreatmentTypeNameCustomizations: Record<string, string> = {
  PRP: 'Platelet-Rich Plasma (PRP)',
  PPP: 'Platelet Poor Plasma (PPP)',
  A2M: 'Alpha-2-Macroglobulin (A2M)',
  EMTT: 'Extracorporeal Magnetotransduction Therapy',
  'Bone Marrow': 'Bone Marrow (BMAC)',
  APS: 'Autologous Protein Solution (APS)',
  HIFEM: 'High-Intensity Focused Electromagnetic (HIFEM)',
  'Trigger Finger Release': 'Trigger Finger Release (TFR)',
}

export interface TreatmentFormValues {
  siteLocationId?: string
  providerId: string
  medicalTreatmentId: string
  treatmentProductId?: string
  treatmentDate: string | Date
  researchStudyNumber?: string
  protocol?: Protocol
  protocolSecondaryTreatments?: string
}
export interface MvpProtocol {
  key: number
  name: string
  description: string
  treatments: {
    order: number
    treatmentSlug: string
    treatmentDateWeeks: number
  }[]
}
export interface TreatmentProps {}

/**
 * TODO: Refactor this component to use Routes to handle its internal `pageState`
 */
const Treatment: FC<TreatmentProps> = () => {
  const classes = useStyles()
  const queryClient = useQueryClient()
  const { push } = useHistory()
  const treatmentMatch = useRouteMatch<PatientFlowLocationParams>()
  const [pageState, setPageState] = useState<
    'page1-edit' | 'page2-edit' | 'readonly'
  >('page1-edit')
  const {
    patientId = undefined,
    caseId = undefined,
    treatmentId = undefined,
  } = treatmentMatch ? treatmentMatch.params : {}
  const {
    data: surveyCompletionList,
    isLoading: surveyCompletionStatusIsLoading,
  } = useBodyPartsSurveyCompletionStatus(patientId)
  const currentCaseSurveyCompletionStatus = surveyCompletionList?.find(
    (survey) => {
      return survey.patientCase === caseId
    },
  )
  const isBaselineCompleted =
    currentCaseSurveyCompletionStatus?.surveys['pre'].status === 'done'
  const createTreatment = useCreateTreatment()
  const updateTreatment = useUpdateTreatment()
  const deleteTreatment = useDeleteTreatment()
  const { data: bodySites = [] } = useBodySites()
  const { data: providers = [] } = useProviders()
  const { currentSite } = useSitesContext()
  const { localizedFormatDate, localizedParseDate } = useDates()
  const { createTreatmentValidations } = useTreatment()
  const { data: protocols } = useProtocols('TreatmentProtocol')

  const { data: patientCases = [], isLoading: isLoadingCases } =
    usePatientCases(patientId)
  const {
    data: medicalTreatments = [],
    isLoading: isLoadingMedicalTreatments,
  } = useMedicalTreatments()
  const location = useLocation<{ from?: string }>()

  const patientCase = findBy('id', caseId, patientCases)
  const existingTreatment = findBy(
    'id',
    treatmentId,
    patientCase?.patientTreatments || [],
  )
  const primaryTreatment = patientCase?.patientTreatments
    ? patientCase.patientTreatments[0]
    : undefined

  const isKcqStudy =
    patientCase?.study?.slug ===
      'platelet-rich-plasma-and-the-effects-of-nsaids-on-pain-and-functional-scores-in-knee-osteoarthritis' ||
    false

  const isPrimaryTreatment =
    existingTreatment?.id === primaryTreatment?.id || false
  const isRsnRequired = isKcqStudy && isPrimaryTreatment
  const treatmentSessions = (patientCase?.patientTreatments || [])
    .filter((pt) => pt.parentId == treatmentId)
    .sort((a, b) => {
      const date1 = parseYMD(a.treatmentDate)
      const date2 = parseYMD(b.treatmentDate)
      return Number(date1) - Number(date2)
    })
  const bodySiteName = findBy('id', patientCase?.bodySiteId, bodySites)?.name
  const medicalTreatment =
    existingTreatment &&
    findBy('id', existingTreatment.medicalTreatmentId, medicalTreatments)
  const medicalTreatmentSlug = medicalTreatment
    ? buildTreatmentDetailSlug(medicalTreatment.slug)
    : ''

  const { data: existingSrs, isLoading: isLoadingSrs } =
    useSurveyResponseSearch({
      ownerIds: [existingTreatment?.id || ''],
      ownerType: 'PatientTreatment',
      surveySlugs: [medicalTreatmentSlug],
    })

  const isLoading =
    isLoadingCases ||
    isLoadingMedicalTreatments ||
    isLoadingSrs ||
    surveyCompletionStatusIsLoading
  useEffect(() => {
    const srForThisTreatment = (existingSrs?.surveyResponses || []).filter(
      (sr) => sr.ownerId === existingTreatment?.id,
    )

    if (srForThisTreatment.length) {
      setPageState('readonly')
    } else if (existingTreatment) {
      setPageState('page2-edit')
    } else {
      setPageState('page1-edit')
    }
  }, [existingTreatment, existingSrs, isBaselineCompleted])

  const handleDeleteTreatment = () => {
    if (!patientId || !caseId || !existingTreatment?.id) return

    return deleteTreatment.mutateAsync(existingTreatment.id).then(() => {
      setPageState('page1-edit')
      const otherTreatment = (patientCase?.patientTreatments || []).find(
        (t) => t.id !== treatmentId,
      )
      const destination = otherTreatment
        ? PatientFlowUrls.treatment.create(patientId, caseId, otherTreatment.id)
        : PatientFlowUrls.addTreatment.create(patientId, caseId)

      push(destination)
    })
  }

  const country = currentSite?.country
  const isCanada = country === 'canada'

  const validationSchema = useMemo(
    () =>
      createTreatmentValidations(
        existingTreatment,
        isBaselineCompleted,
        isPrimaryTreatment,
        isRsnRequired,
      ),
    [
      existingTreatment,
      createTreatmentValidations,
      isBaselineCompleted,
      isPrimaryTreatment,
      isRsnRequired,
    ],
  )

  const {
    values,
    errors,
    touched,
    isSubmitting,
    isValid,
    handleBlur,
    handleSubmit,
    handleChange,
    setFieldValue,
  } = useFormik<TreatmentFormValues>({
    enableReinitialize: true,
    validationSchema,
    initialValues: existingTreatment
      ? {
          ...existingTreatment,
          treatmentDate: parseYMD(existingTreatment.treatmentDate),
        }
      : {
          providerId: '',
          medicalTreatmentId: '',
          treatmentProductId: '',
          treatmentDate: '',
          researchStudyNumber: '',
          protocolSecondaryTreatments: '',
          siteLocationId: '',
        },
    onSubmit: async (values) => {
      if (!patientId || !caseId) return
      let formattedTreatmentDate = ''
      if (values.treatmentDate instanceof Date) {
        formattedTreatmentDate = format(values.treatmentDate, 'yyyy-MM-dd')
      } else {
        const mdyTreatmentDate = localizedParseDate(
          values.treatmentDate as string,
        )
        if (!mdyTreatmentDate) return
        formattedTreatmentDate = format(mdyTreatmentDate, 'yyyy-MM-dd')
      }

      if (currentSite?.siteLocations.length === 1) {
        values.siteLocationId = currentSite.siteLocations[0].id
      }

      if (existingTreatment && treatmentHasChanged(values, existingTreatment)) {
        // Updating a Treatment
        await updateTreatment.mutateAsync({
          id: existingTreatment.id,
          patientCaseId: caseId,
          ...values,
          treatmentDate: formattedTreatmentDate,
        })
      } else if (!existingTreatment) {
        // Creating a new Treatment
        await createTreatment
          .mutateAsync({
            patientCaseId: caseId,
            ...values,
            treatmentDate: formattedTreatmentDate,
            protocolId: values.protocol?.id,
          })
          .then((treatment) => {
            push(
              PatientFlowUrls.treatment.create(patientId, caseId, treatment.id),
            )
          })
        if (values.protocol) {
          const protocolPrimaryTreatments = (
            (protocols || []).find((prot) => prot === values.protocol)
              ?.protocolTreatments || []
          ).filter((treat) => treat.order > 1)
          if (protocolPrimaryTreatments) {
            protocolPrimaryTreatments?.map(async (treatment) => {
              const protTreatment = availableMedicalTreatmentOptions.find(
                (treat) => treat.id === treatment.medicalTreatmentId,
              )
              let unformattedTreatmentDate
              switch (treatment.periodType) {
                case 'days':
                  unformattedTreatmentDate = addDays(
                    new Date(formattedTreatmentDate),
                    treatment.periodAmount,
                  )
                  break
                case 'weeks':
                  unformattedTreatmentDate = addWeeks(
                    new Date(formattedTreatmentDate),
                    treatment.periodAmount,
                  )
                  break
                case 'months':
                  unformattedTreatmentDate = addMonths(
                    new Date(formattedTreatmentDate),
                    treatment.periodAmount,
                  )
                  break
                case 'years':
                  unformattedTreatmentDate = addYears(
                    new Date(formattedTreatmentDate),
                    treatment.periodAmount,
                  )
                  break

                default:
                  unformattedTreatmentDate = addWeeks(
                    new Date(formattedTreatmentDate),
                    0,
                  )
                  break
              }
              if (protTreatment) {
                await createTreatment.mutateAsync({
                  patientCaseId: caseId,
                  siteLocationId: treatment.siteLocationId || '',
                  providerId: treatment.staffId || values.providerId,
                  medicalTreatmentId: protTreatment.id,
                  treatmentProductId: treatment.treatmentProductId,
                  protocolId: values.protocol?.id,
                  treatmentDate: format(
                    unformattedTreatmentDate as Date,
                    'yyyy-MM-dd',
                  ),
                })
              }
            })
            setTimeout(() => {
              queryClient.invalidateQueries(
                QueryKeys.patientCases({ patientId }),
              )
            }, 1000)
          }
        }
      } else {
        // Just continue to the next apge
      }

      setPageState('page2-edit')
    },
  })

  const { data: treatmentProducts = [] } = useTreatmentProducts(
    values.medicalTreatmentId || '',
  )

  useEffect(() => {
    if (patientCase && patientCase.study) {
      if (
        patientCase.study.medicalTreatments &&
        patientCase.study.medicalTreatments.length === 1
      ) {
        const availableMedicalTreatment = medicalTreatments.find(
          (mt) =>
            patientCase &&
            patientCase.study &&
            mt.id === patientCase.study.medicalTreatments[0].id,
        )
        if (
          availableMedicalTreatment &&
          patientCase.patientTreatments &&
          patientCase.patientTreatments.length == 0
        )
          setFieldValue(
            'medicalTreatmentId',
            patientCase.study.medicalTreatments[0].id,
          )
      }
    }
  }, [medicalTreatments])

  useEffect(() => {
    if (patientCase && patientCase.study) {
      if (
        patientCase.study.treatmentProducts &&
        patientCase.study.treatmentProducts.length === 1
      ) {
        const availableTreatmentProduct = treatmentProducts.find(
          (tp) =>
            patientCase &&
            patientCase.study &&
            tp.id === patientCase.study.treatmentProducts[0].id,
        )
        if (
          availableTreatmentProduct &&
          patientCase.patientTreatments &&
          patientCase.patientTreatments.length == 0
        )
          setFieldValue(
            'treatmentProductId',
            patientCase.study.treatmentProducts[0].id,
          )
      }
    }
    if (values.protocol) {
      const protocolPrimaryTreatment = (
        (protocols || []).find((prot) => prot.id === values.protocol?.id)
          ?.protocolTreatments || []
      ).find((treat) => treat.order === 1)
      if (
        protocolPrimaryTreatment &&
        protocolPrimaryTreatment.treatmentProductId &&
        protocolPrimaryTreatment.treatmentProductId !==
          values.treatmentProductId
      ) {
        setFieldValue(
          'treatmentProductId',
          protocolPrimaryTreatment.treatmentProductId || '',
        )
      }
    }
  }, [treatmentProducts])

  if (isLoading) {
    return (
      <div className={classes.loadingContainer}>
        <CircularProgress />
      </div>
    )
  }

  if (
    patientCase &&
    patientCase.patientDiagnoses &&
    patientCase?.patientDiagnoses.length === 0 &&
    !location.state?.from?.includes('/diagnosis')
  ) {
    return (
      <Redirect
        to={{
          pathname: PatientFlowUrls.diagnosis.create(
            patientCase.patientId,
            patientCase.id,
          ),
          state: { from: location.state?.from ? location.state?.from : null },
        }}
      />
    )
  }

  const availableMedicalTreatmentOptions = medicalTreatments.filter(
    (treatment) => {
      if (patientCase?.study) {
        if (patientCase.patientTreatments?.length == 0) {
          return patientCase?.study?.medicalTreatments
            .map((mt) => mt.id)
            .includes(treatment.id)
        } else {
          return (
            treatment.onlyForStudy == false ||
            patientCase?.study?.medicalTreatments
              .map((mt) => mt.id)
              .includes(treatment.id)
          )
        }
      }
      return treatment.onlyForStudy == false
    },
  )

  const availableTreatmentProductOptions = treatmentProducts.filter(
    (product) => {
      if (
        patientCase?.study &&
        patientCase?.study.slug !==
          'platelet-rich-plasma-and-the-effects-of-nsaids-on-pain-and-functional-scores-in-knee-osteoarthritis'
      ) {
        if (patientCase.patientTreatments?.length == 0) {
          return patientCase?.study?.treatmentProducts
            .map((tp) => tp.id)
            .includes(product.id)
        } else {
          return (
            product.onlyForStudy == false ||
            patientCase?.study?.treatmentProducts
              .map((tp) => tp.id)
              .includes(product.id)
          )
        }
      }
      return product.onlyForStudy == false
    },
  )

  return (
    <>
      <div className={classes.header}>
        <H5 paragraph>
          {bodySiteName} {treatmentSessions.length > 0 ? '(Main Session)' : ''}
        </H5>
        {existingTreatment && (
          <AppLink
            underline="hover"
            to={PatientFlowUrls.addSession.create(
              patientId || '',
              caseId || '',
              existingTreatment.id || '',
            )}
          >
            <Body1>Add New Session</Body1>
          </AppLink>
        )}
      </div>
      <Divider />

      {pageState === 'page1-edit' ? (
        <form className={classes.form} onSubmit={handleSubmit}>
          {currentSite && currentSite.siteLocations.length > 1 && (
            <FormControl style={{ width: '100%' }} error={!!errors.providerId}>
              <InputLabel id="site-location-label" required>
                Clinic Location
              </InputLabel>
              <Select
                id="patient-flow-treatment-site-location"
                labelId="site-location-label"
                placeholder="Select"
                disabled={
                  values.protocol && !values.protocol.organization?.id
                    ? true
                    : false
                }
                value={values.siteLocationId || ''}
                fullWidth
                required
                onChange={(event) => {
                  setFieldValue('siteLocationId', event.target.value)
                }}
              >
                {currentSite?.siteLocations.map((location) => (
                  <MenuItem key={location.id} value={location.id}>
                    {location.name}
                  </MenuItem>
                ))}
              </Select>
              {errors.siteLocationId && (
                <FormHelperText error>{errors.siteLocationId}</FormHelperText>
              )}
            </FormControl>
          )}
          <FormControl style={{ width: '100%' }} error={!!errors.providerId}>
            <InputLabel id="provider-label" required>
              Provider
            </InputLabel>
            <Select
              id="patient-flow-treatment-provider"
              labelId="provider-label"
              placeholder="Select"
              disabled={
                values.protocol && !values.protocol.organization?.id
                  ? true
                  : false
              }
              value={values.providerId}
              fullWidth
              required
              onChange={(event) => {
                setFieldValue('providerId', event.target.value)
              }}
            >
              {providers.map((prov) => (
                <MenuItem key={prov.id} value={prov.id}>
                  {buildName(prov)}
                </MenuItem>
              ))}
            </Select>
            {errors.providerId && (
              <FormHelperText error>{errors.providerId}</FormHelperText>
            )}
          </FormControl>

          {currentSite?.organization.name.includes('QCK') &&
            (protocols || []).length > 0 &&
            patientCase?.patientTreatments?.length === 0 && (
              <Autocomplete
                id="patient-flow-treatment-protocol"
                options={protocols || []}
                getOptionLabel={(opt) => opt.name}
                isOptionEqualToValue={(opt, val) => opt.id === val.id}
                filterOptions={(options, { inputValue }) =>
                  inputValue
                    ? matchSorter(options, inputValue, { keys: ['name'] })
                    : options
                }
                value={values.protocol || null}
                fullWidth
                onChange={(_event, data) => {
                  setFieldValue('protocol', data || null)
                  const protocolPrimaryTreatment = (
                    (protocols || []).find((prot) => prot.id === data?.id)
                      ?.protocolTreatments || []
                  ).find((treat) => treat.order === 1)
                  if (protocolPrimaryTreatment) {
                    const protocolTreatment =
                      availableMedicalTreatmentOptions.find(
                        (treat) =>
                          treat.id ===
                          protocolPrimaryTreatment.medicalTreatmentId,
                      )
                    const protocolProvider = providers.find(
                      (staff) => staff.id === protocolPrimaryTreatment?.staffId,
                    )
                    const protocolSiteLocation =
                      currentSite?.siteLocations.find(
                        (location) =>
                          location.id ===
                          protocolPrimaryTreatment?.siteLocationId,
                      )
                    if (protocolPrimaryTreatment.id)
                      setFieldValue(
                        'medicalTreatmentId',
                        protocolTreatment?.id || '',
                      )
                    if (protocolProvider)
                      setFieldValue('providerId', protocolProvider?.id)
                    if (protocolSiteLocation)
                      setFieldValue('siteLocationId', protocolSiteLocation?.id)
                  } else {
                    setFieldValue('medicalTreatmentId', '')
                    setFieldValue('treatmentProductId', '')
                    setFieldValue('providerId', '')
                    setFieldValue('siteLocationId', '')
                  }
                }}
                renderInput={(params) => {
                  // @ts-expect-error Mui's autocomplete types here are incomplete
                  params.InputLabelProps.shrink = true
                  return <TextField {...params} label="Protocol" />
                }}
              />
            )}
          {values.protocol && (
            <Autocomplete
              id="patient-flow-treatment-protocol-secondary-treatments"
              multiple
              disableCloseOnSelect
              options={qcKinetixProtocolsSecondaryTreatments || []}
              getOptionLabel={(opt) => opt.slug}
              isOptionEqualToValue={(opt, val) => opt.value === val.value}
              filterOptions={(options, { inputValue }) =>
                inputValue
                  ? matchSorter(options, inputValue, { keys: ['slug'] })
                  : options
              }
              onChange={(_event, data) => {
                setFieldValue(
                  'protocolSecondaryTreatments',
                  data.map((tr) => tr.value).join(','),
                )
              }}
              value={
                qcKinetixProtocolsSecondaryTreatments.filter((treat) =>
                  values.protocolSecondaryTreatments
                    ?.split(',')
                    .some((secTreat) => secTreat === treat.value),
                ) ?? null
              }
              renderInput={(params) => {
                // @ts-expect-error Mui's autocomplete types here are incomplete
                params.InputLabelProps.shrink = true
                return (
                  <TextField
                    {...params}
                    label="Protocol Additional Treatments"
                  />
                )
              }}
            />
          )}
          <Autocomplete
            id="patient-flow-treatment-medical-treatment"
            options={availableMedicalTreatmentOptions}
            getOptionLabel={(opt) =>
              opt.name in TreatmentTypeNameCustomizations
                ? TreatmentTypeNameCustomizations[opt.name]
                : opt.name
            }
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            filterOptions={(options, { inputValue }) =>
              inputValue
                ? matchSorter(options, inputValue, { keys: ['name'] })
                : options
            }
            disabled={
              values.protocol && !values.protocol.organization?.id
                ? true
                : false
            }
            value={
              availableMedicalTreatmentOptions.find(
                (treatment) => treatment.id === values.medicalTreatmentId,
              ) ?? null
            }
            onChange={(_event, treatment) => {
              setFieldValue('medicalTreatmentId', treatment?.id || '')
            }}
            renderInput={(params) => {
              // @ts-expect-error Mui's autocomplete types here are incomplete
              params.InputLabelProps.shrink = true
              return (
                <TextField
                  {...params}
                  label="Treatment Type"
                  name="medicalTreatmentId"
                  required
                  value={values.medicalTreatmentId}
                  onBlur={handleBlur}
                  error={
                    touched.medicalTreatmentId &&
                    Boolean(errors.medicalTreatmentId)
                  }
                  helperText={
                    touched.medicalTreatmentId && errors.medicalTreatmentId
                  }
                />
              )
            }}
          />
          <Autocomplete
            id="patient-flow-treatment-product"
            options={availableTreatmentProductOptions}
            getOptionLabel={(opt) => opt.name}
            isOptionEqualToValue={(opt, val) => opt.id === val.id}
            filterOptions={(options, { inputValue }) =>
              inputValue
                ? matchSorter(options, inputValue, { keys: ['name'] })
                : options
            }
            disabled={
              values.protocol &&
              !values.protocol.organization?.id &&
              !existingTreatment
                ? true
                : false
            }
            value={
              treatmentProducts.find(
                (prod) => prod.id === values.treatmentProductId,
              ) ?? null
            }
            onChange={(_event, prov) => {
              setFieldValue('treatmentProductId', prov?.id || '')
            }}
            renderInput={(params) => {
              // @ts-expect-error Mui's autocomplete types here are incomplete
              params.InputLabelProps.shrink = true
              return (
                <TextField
                  {...params}
                  name="treatmentProductId"
                  label="Product Name"
                  onBlur={handleBlur}
                  required
                  value={values.treatmentProductId}
                  error={
                    touched.treatmentProductId &&
                    Boolean(errors.treatmentProductId)
                  }
                  helperText={
                    touched.treatmentProductId && errors.treatmentProductId
                  }
                />
              )
            }}
          />
          <DatePicker
            id="patient-flow-treatment-date"
            label="Treatment Date"
            name="treatmentDate"
            // defaultDate={localizedFormatDate(aWeekFromNow)}
            inputFormat={isCanada ? 'dd/MM/yyyy' : 'MM/dd/yyyy'}
            // inputFormat={'dd/MM/yyyy'}
            required
            fullWidth
            minDate={
              isBaselineCompleted || isPrimaryTreatment
                ? undefined
                : primaryTreatment
                ? parseYMD(primaryTreatment?.treatmentDate || '')
                : new Date()
            }
            isEditing={
              values.treatmentDate
                ? values.treatmentDate
                : patientCase &&
                  patientCase.patientTreatments &&
                  patientCase.patientTreatments.length > 0
                ? undefined
                : localizedFormatDate(
                    parseYMD(patientCase?.anticipatedTreatmentDate || ''),
                  )
            }
            setFieldValue={setFieldValue}
            onBlur={handleBlur}
            error={touched.treatmentDate && Boolean(errors.treatmentDate)}
            helperText={touched.treatmentDate && errors.treatmentDate}
          />
          {isRsnRequired && (
            <TextField
              id="patient-flow-treatment-research-study-number"
              label="Research Study Number"
              name="researchStudyNumber"
              required
              fullWidth
              value={values.researchStudyNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              error={
                touched.researchStudyNumber &&
                Boolean(errors.researchStudyNumber)
              }
              helperText={
                touched.researchStudyNumber && errors.researchStudyNumber
              }
            />
          )}
          <Button
            id="patient-flow-treatment-submit-button"
            type="submit"
            fullWidth
            loading={isSubmitting}
            disabled={!isValid}
          >
            Next
          </Button>
        </form>
      ) : (
        existingTreatment &&
        medicalTreatment && (
          <Box pb={3} pt={1}>
            <TreatmentDetail
              treatment={existingTreatment}
              onEditClick={() => setPageState('page1-edit')}
              onDeleteClick={() => handleDeleteTreatment()}
              withDetails={pageState === 'readonly'}
              patientId={patientCase?.patientId || ''}
              sessions={treatmentSessions}
            />
            {pageState === 'page2-edit' && (
              <Divider className={classes.divider} />
            )}
          </Box>
        )
      )}

      {existingTreatment && patientCase && pageState === 'page2-edit' && (
        <TreatmentDetailsForm
          treatment={existingTreatment}
          patientCase={patientCase}
          medicalTreatmentSlug={medicalTreatment?.slug || ''}
          onSubmit={() => setPageState('readonly')}
        />
      )}
    </>
  )
}

export default Treatment
