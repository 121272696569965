import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { SecondaryRole } from './types'
import { User } from './useCurrentUser'

export interface Provider {
  id: string
  userId: string
  organizationId: string
  firstName: string
  lastName: string
  type: 'Provider'
  subscriptionPlan: 'essentials' | 'performance' | 'demo' | 'starter'
  npi: string
  title: string
  residentFellow: string | null
  secondaryRole: SecondaryRole
  societiesAffiliations: string[]
  specialty: string[]
  positions: string[]
  user?: User
  isAdmin: boolean
  activated: boolean
}

interface ProviderResponse {
  providers: Provider[]
}

const useProviders = (extraOptions: UseQueryOptions<Provider[]> = {}) => {
  const siteId = useCurrentSite().id
  const key = QueryKeys.providers(siteId)
  const url = `/sites/providers?site_ids[]=${siteId}`

  return useQuery(
    key,
    () => request.get<ProviderResponse>(url).then((r) => r.data.providers),
    extraOptions,
  )
}

export default useProviders
