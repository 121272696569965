import { FC } from 'react'
import { Box, Button, H3, H5, Link, makeStyles, PageTitle } from '@perk-ui/core'
import { FileText } from 'react-feather'
import { useLocation } from 'react-router-dom'

import { ReactComponent as TeamIcon } from '../../assets/TeamIcon.svg'
import { useSitesContext } from '../../components/SitesProvider'
import TableauView from '../../components/TableauView'
import { insightsWorkbooks } from '../../config/constants'
import { useUser } from '../../features/auth/AuthContext'

export interface InsightsProps {}

const useStyles = makeStyles((theme) => ({
  emptyContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100%',
    width: '100%',
  },
  emptyStateIcon: {
    display: 'flex',
    backgroundColor: '#F1F1F1',
    borderRadius: '50%',
    marginBottom: theme.spacing(2),
    '& svg': {
      color: '#6B6B6B',
    },
  },
  emptyStateTitle: {
    color: '#6B6B6B',
    fontWeight: 700,
    fontSize: '16px',
    marginBottom: theme.spacing(1),
  },
  emptyStateText: {
    color: '#6B6B6B',
    fontWeight: 400,
    fontSize: '12px',
    marginBottom: theme.spacing(2),
  },
  emptyStateButton: {
    fontSize: '14px',
    fontWeight: 400,
    marginBottom: theme.spacing(2),
    minHeight: 40,
    padding: theme.spacing(1, 3),
  },
}))

const Insights: FC<InsightsProps> = () => {
  const sitesContext = useSitesContext()
  const { pathname } = useLocation()
  const currentUser = useUser()
  const classes = useStyles()
  const site = sitesContext.currentSite
  const staffTableauSubscription = currentUser?.staff.subscriptionPlan

  let pageTitle = 'Insights'
  let workbookUrl = ''

  switch (pathname) {
    case '/my-impact':
      pageTitle = 'My IMPACT'
      workbookUrl = insightsWorkbooks.myImpact[staffTableauSubscription]
      break
    case '/my-teams':
      pageTitle = 'My Teams'
      break
    case '/my-studies':
      pageTitle = 'My Studies'
      break
    case '/raw-data':
      pageTitle = 'Raw Data'
      workbookUrl = insightsWorkbooks.rawData[staffTableauSubscription]
      break
    default:
      break
  }

  if (!site) return null

  const emptyStatePage = () => {
    const icon = pageTitle === 'My Teams' ? <TeamIcon /> : <FileText />
    const title = pageTitle === 'My Teams' ? 'No Teams' : 'No Studies'
    const pageText =
      pageTitle === 'My Teams'
        ? 'You are currently not part of any Teams'
        : 'You are currently not part of any Studies.'
    const buttonText =
      pageTitle === 'My Teams'
        ? 'Learn more about Teams'
        : 'Learn more about Studies'
    const buttonLink =
      pageTitle === 'My Teams'
        ? 'https://www.databiologics.com/teams'
        : 'https://www.databiologics.com/active-research'

    return (
      <Box p={3} height={'100%'}>
        <Box className={classes.emptyContainer}>
          <Box
            className={classes.emptyStateIcon}
            p={pageTitle === 'My Teams' ? 1 : 2}
          >
            {icon}
          </Box>
          <H3 className={classes.emptyStateTitle}>{title}</H3>
          <H5 className={classes.emptyStateText}>{pageText}</H5>
          {/* <Button
            className={classes.emptyStateButton}
            size="small"
            startIcon={<Plus size={14} />}
          >
            {buttonText}
          </Button> */}
          <Link
            component={Button}
            className={classes.emptyStateButton}
            href={buttonLink}
            target="_blank"
          >
            {buttonText}
          </Link>
        </Box>
      </Box>
    )
  }
  return (
    <Box p={3} height={'100%'}>
      <PageTitle title={pageTitle} />
      {pageTitle === 'My Teams' && emptyStatePage()}

      {pageTitle === 'My Studies' && emptyStatePage()}

      {workbookUrl !== '' && (
        <TableauView
          src={workbookUrl}
          type="tableauEssentialToken"
          toolbar="top"
          hideTabs={false}
        />
      )}
    </Box>
  )
}

export default Insights
