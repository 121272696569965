import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { BodySiteKey } from './useBodySites'

export type SurveyStatusObject = {
  status: SurveyStatusText
  availableOn: string
  expiresOn: string
  group: string
  groupItems: SurveyStatusGroupItem[]
}
export type SurveyStatusText =
  | 'done'
  | 'missed'
  | 'open'
  | 'expiring_soon'
  | 'n/a'
export type SurveyStatusPeriod =
  | 'pre'
  | '2wk'
  | '1mo'
  | '6wk'
  | '2mo'
  | '3mo'
  | '6mo'
  | '9mo'
  | '12mo'
  | '18mo'
  | '24mo'
  | '36mo'
export interface SurveyStatusGroupItem {
  patientCaseId: string
  bodySite: string
  bodySiteSlug: string
  availableOn: string
  expiresOn: string
  completedAt: string
  status: string
}
export interface SurveyCompletionCDStatus {
  name: BodySiteKey
  patientCase: string
  surveys: Record<SurveyStatusPeriod, SurveyStatusObject>
}

const useSurveyCompletionCDStatus = (
  patientId?: string,
  patientCaseId?: string,
  extraOptions: UseQueryOptions<SurveyCompletionCDStatus> = {},
) => {
  const currentSiteId = useCurrentSite().id
  const siteIds = currentSiteId

  const key = patientId
    ? QueryKeys.surveyCompletionCDStatus({
        currentSiteId,
        patientId,
        patientCaseId,
      })
    : ''
  const url = `/sites/patients/${patientId}/survey_status_alt_overview?site_ids[]=${siteIds}&patient_case_id=${patientCaseId}`

  return useQuery(
    key,
    () => request.get<SurveyCompletionCDStatus>(url).then((r) => r.data),
    {
      enabled: Boolean(patientId),
      ...extraOptions,
    },
  )
}

export default useSurveyCompletionCDStatus
