import { FC, useEffect, useState } from 'react'
import {
  Body1,
  Box,
  Card,
  CircularProgress,
  Divider,
  findBy,
  groupBy,
  H3,
  makeStyles,
  MenuItem,
  Select,
  SelectChangeEvent,
} from '@perk-ui/core'
import { find } from 'lodash'

import useAggregateOutcomes, {
  AggregateOutcome,
  AggregateOutcomeData,
} from '../../../features/query-hooks/useAggregateOutcomes'
import {
  AggregateOutcomesFilters,
  BodySiteFilter,
} from '../../../features/query-hooks/useAggregateOutcomesFilters'
import {
  BodySiteKey,
  humanizeBodySite,
} from '../../../features/query-hooks/useBodySites'
import useSurveyScoreDefinitions from '../../../features/query-hooks/useSurveyScoreDefinitions'
import OutcomeChart from '../components/OutcomeChart'

export const SurveySortOrder = [
  'HOOS-JR',
  'KOOS-JR',
  'FAAM',
  'VISA-A',
  'NDI',
  'ODI',
  'QUICKDASH',
  'GAD-7',
  'PCL-5',
  'PHQ4',
  'NUMERIC-PAIN-RATING',
  'DESIRED-FUNCTIONALITY',
  'SATISFACTION-SCORE',
]

interface FilterRange {
  label: string
  min?: number
  max?: number
  value: string
}

const ageRanges: FilterRange[] = [
  {
    label: 'All ages',
    min: 0,
    max: 150,
    value: 'all',
  },
  {
    label: '<21',
    min: 0,
    max: 20,
    value: '0-20',
  },
  {
    label: '21-29',
    min: 21,
    max: 29,
    value: '21-29',
  },
  {
    label: '30-49',
    min: 30,
    max: 49,
    value: '30-49',
  },
  {
    label: '50-69',
    min: 50,
    max: 69,
    value: '50-69',
  },
  {
    label: '>70',
    min: 70,
    max: 150,
    value: '70-150',
  },
]

const bmiRanges: FilterRange[] = [
  {
    label: 'All BMI',
    value: 'avg',
  },
  {
    label: '<18.5 (Underweight)',
    min: 0,
    max: 18.5,
    value: '0-18.5',
  },
  {
    label: '18.5-24.9 (Healthy Weight)',
    min: 18.5,
    max: 24.9,
    value: '18.5-24.9',
  },
  {
    label: '25-29.9 (Overweight)',
    min: 25,
    max: 29.9,
    value: '25-29.9',
  },
  {
    label: '30-39.9 (Obese)',
    min: 30,
    max: 39.9,
    value: '30-39.9',
  },
]

const useStyles = makeStyles((theme) => ({
  root: {
    minHeight: '70vh',
    maxHeight: '85vh',
    display: 'flex',
  },
  header: {
    padding: theme.spacing(3, 0),
  },
  back: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(2, 0),
    color: theme.palette.primary.main,
  },
  diagnosisSection: {
    paddingTop: theme.spacing(2),
  },
  diagnosisItem: {
    minHeight: theme.spacing(4.5),
    cursor: 'pointer',
    justifyContent: 'space-between',
    paddingRight: theme.spacing(3),
    paddingTop: 0,
    paddingBottom: 0,
  },
  outcomeChartGroup: {
    '& > *+*': {
      paddingTop: theme.spacing(2),
    },
  },
  centered: {
    width: '100%',
    textAlign: 'center',
  },
}))

const SurveyNameMap: Record<string, string> = {
  PHQ4: 'PHQ 4',
  FAAM: 'FAAM',
  ODI: 'ODI',
}

const humanizeSurveyName = (name: string) => {
  if (name in SurveyNameMap) return SurveyNameMap[name]
  else return humanizeBodySite(name)
}

export interface OutcomesProps {
  bodyPart?: string | null | undefined
  filters?: AggregateOutcomesFilters
  treatment?: string | null | undefined
  setTreatment: (treatment: string | null | undefined) => void
  diagnosis?: string | null | undefined
  setDiagnosis: (diagnosis: string | null | undefined) => void
  provider?: string | null | undefined
  setProvider: (provider: string | null | undefined) => void
  sex?: string | null | undefined
  setSex: (sex: string | null | undefined) => void
  age?: string | null | undefined
  setAge: (age: string | null | undefined) => void
  bmi?: string | null | undefined
  setBmi: (bmi: string | null | undefined) => void
}

const Outcomes: FC<OutcomesProps> = ({
  bodyPart,
  filters,
  treatment,
  setTreatment,
  diagnosis,
  setDiagnosis,
  provider,
  setProvider,
  sex,
  setSex,
  age,
  setAge,
  bmi,
  setBmi,
}) => {
  const bodyPartFilters =
    bodyPart && filters
      ? ((find(
          filters?.bodySites,
          (filter) => Object.keys(filter)[0] === bodyPart,
        ) || {})[bodyPart as BodySiteKey] as BodySiteFilter)
      : ({} as BodySiteFilter)

  const classes = useStyles()

  const { data: scoreDefinitions } = useSurveyScoreDefinitions()
  const { data: outcomes = [], isLoading } = useAggregateOutcomes(
    {
      bodySite: bodyPart,
      treatment,
      diagnosis,
      provider,
      sex,
      age: findBy('value', age, ageRanges),
      bmi: findBy('value', bmi, bmiRanges),
    },
    { keepPreviousData: true },
  )
  const [isChartsHidden, setIsChartsHidden] = useState(true)

  useEffect(() => {
    setIsChartsHidden(true)
    if (!treatment) setTreatment('all')
    if (!diagnosis) setDiagnosis('all')
    if (!provider) setProvider('all')
    if (!sex) setSex('all')
    if (!bmi) setBmi('avg')
    if (!age) setAge('all')
    setTimeout(() => {
      setIsChartsHidden(false)
    }, 100)
  }, [bodyPart])

  useEffect(() => {
    setIsChartsHidden(true)
    setTimeout(() => {
      setIsChartsHidden(false)
    }, 1000)
  }, [])

  const outcomesByBodySite = groupBy('bodySiteKey', outcomes)

  const bodyPartOutcomes = bodyPart ? outcomesByBodySite[bodyPart] || [] : []
  const bodyPartOutcomesBySurvey = groupBy('surveyName', bodyPartOutcomes)

  return (
    <Box>
      {isLoading && (
        <Card className={classes.root}>
          <Box className={classes.centered} my={10}>
            <CircularProgress />
          </Box>
        </Card>
      )}

      <Card className={classes.root}>
        {bodyPart && (
          <Box
            sx={{
              pl: 3,
              overflow: 'auto',
              width: '100%',
            }}
          >
            <H3 className={classes.header}>{humanizeBodySite(bodyPart)}</H3>
            <Divider sx={{ mb: 3 }} />

            <Body1
              sx={{
                mb: 2,
                color: ({ palette }) => palette.text.secondary,
                fontWeight: 600,
              }}
            >
              Filters
            </Body1>
            <Box
              sx={{
                display: 'grid',
                gridTemplateColumns: '1fr 1fr 1fr',
                gap: 2,
                pr: 4,
              }}
            >
              {bodyPartFilters?.treatment && (
                <Box>
                  <Select
                    labelId="treatment-filter-label"
                    placeholder="Select"
                    value={treatment || ''}
                    fullWidth
                    required
                    onChange={(event: SelectChangeEvent) => {
                      const ev = event.target as HTMLInputElement
                      const val = ev.value as string
                      setTreatment(val)
                    }}
                  >
                    <MenuItem key="treatment-filter-default" value="all">
                      All treatments
                    </MenuItem>
                    {bodyPartFilters?.treatment.map((treatment, index) => (
                      <MenuItem
                        key={`treatment-filter-${index}`}
                        value={treatment}
                      >
                        {treatment}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
              {bodyPartFilters?.diagnosis && (
                <Box>
                  <Select
                    labelId="diagnosis-filter-label"
                    placeholder="Select"
                    value={diagnosis || ''}
                    fullWidth
                    required
                    onChange={(event: SelectChangeEvent) => {
                      const ev = event.target as HTMLInputElement
                      const val = ev.value as string
                      setDiagnosis(val)
                    }}
                  >
                    <MenuItem key="diagnosis-filter-default" value="all">
                      All diagnoses
                    </MenuItem>
                    {bodyPartFilters?.diagnosis.map((diagnosis, index) => (
                      <MenuItem
                        key={`diagnosis-filter-${index}`}
                        value={diagnosis}
                      >
                        {diagnosis}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
              {bodyPartFilters?.providerName && (
                <Box>
                  <Select
                    labelId="provider-filter-label"
                    placeholder="Select"
                    value={provider || ''}
                    fullWidth
                    required
                    onChange={(event: SelectChangeEvent) => {
                      const ev = event.target as HTMLInputElement
                      const val = ev.value as string
                      setProvider(val)
                    }}
                  >
                    <MenuItem key="providerName-default" value="all">
                      All providers
                    </MenuItem>
                    {bodyPartFilters?.providerName.map(
                      (providerName, index) => (
                        <MenuItem
                          key={`providerName-${index}`}
                          value={providerName}
                        >
                          {providerName}
                        </MenuItem>
                      ),
                    )}
                  </Select>
                </Box>
              )}
              <Box>
                <Select
                  labelId="sex-filter-label"
                  placeholder="Select"
                  value={sex || ''}
                  fullWidth
                  required
                  onChange={(event: SelectChangeEvent) => {
                    const ev = event.target as HTMLInputElement
                    const val = ev.value as string
                    setSex(val)
                  }}
                >
                  <MenuItem key="filter-sex-all" value="all">
                    All sex
                  </MenuItem>
                  <MenuItem key="filter-sex-male" value="male">
                    Male
                  </MenuItem>
                  <MenuItem key="filter-sex-female" value="female">
                    Female
                  </MenuItem>
                </Select>
              </Box>
              {bodyPartFilters?.providerName && (
                <Box>
                  <Select
                    labelId="age-filter-label"
                    placeholder="Select"
                    value={age || ''}
                    fullWidth
                    required
                    onChange={(event: SelectChangeEvent) => {
                      const ev = event.target as HTMLInputElement
                      const val = ev.value as string
                      setAge(val)
                    }}
                  >
                    {ageRanges.map((range, index) => (
                      <MenuItem
                        key={`providerName-${index}`}
                        value={range.value}
                      >
                        {range.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
              {bodyPartFilters?.bmi && (
                <Box>
                  <Select
                    labelId="bmi-filter-label"
                    placeholder="Select"
                    value={bmi || ''}
                    fullWidth
                    required
                    onChange={(event: SelectChangeEvent) => {
                      const ev = event.target as HTMLInputElement
                      const val = ev.value as string
                      setBmi(val)
                    }}
                  >
                    {bmiRanges.map((range, index) => (
                      <MenuItem
                        key={`providerName-${index}`}
                        value={range.value}
                      >
                        {range.label}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              )}
            </Box>
            {!isLoading && outcomes.length === 0 && (
              <Box className={classes.centered} sx={{ my: 10, pr: 4 }}>
                <H3 gutterBottom>Outcomes</H3>
                <Body1>Not enough data to display outcomes</Body1>
              </Box>
            )}

            <Divider sx={{ mt: 2 }} />

            {!isLoading && outcomes.length > 0 && !isChartsHidden && (
              <Box pt={2} pb={3}>
                <div className={classes.outcomeChartGroup}>
                  {SurveySortOrder.map((slug) => {
                    const surveyOutcomes = bodyPartOutcomesBySurvey[slug]
                    const surveyScoreDefinition = findBy(
                      'surveySlug',
                      slug,
                      scoreDefinitions?.surveyScoreDefinitions || [],
                    )
                    if (!surveyOutcomes) return null
                    const outcomeData = surveyOutcomes

                    const sampleSurvey = surveyOutcomes[0]
                    const aggregatedData = sampleSurvey.data.map((data) =>
                      reAverage(data.x, outcomeData),
                    )

                    // @ts-expect-error: This exists and is improperly typed in the library
                    const hasEnoughData = (data) => {
                      const baseline = findBy('name', 'Baseline', data)
                      const thirdMonth = findBy('name', '3 Months', data)
                      return true
                      // @ts-expect-error: ts error
                      if (!baseline?.n || !thirdMonth?.n) return false
                      // @ts-expect-error: ts error
                      return baseline.n >= 3 && thirdMonth.n >= 3
                    }

                    return (
                      <OutcomeChart
                        key={slug}
                        bodyPart={humanizeBodySite(bodyPart)}
                        title={humanizeSurveyName(slug)}
                        data={aggregatedData}
                        scoreDefinition={surveyScoreDefinition}
                        hasEnoughData={hasEnoughData(aggregatedData)}
                      />
                    )
                  })}
                </div>
              </Box>
            )}
          </Box>
        )}
      </Card>
    </Box>
  )
}

const reAverage = (x: number, outcomes: AggregateOutcome[]) => {
  const data = outcomes.map((o) =>
    findBy('x', x, o.data),
  ) as AggregateOutcomeData[]

  const totalN = data.map((d) => d.n).reduce((sum, a) => sum + a, 0)
  const newY = data.reduce((newY, a) => newY + (a.abs * a.n) / totalN, 0)
  return {
    name: data[0].name,
    x,
    y: newY,
    n: totalN,
  } as AggregateOutcomeData
}

export default Outcomes
