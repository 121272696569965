import { ensureArray } from '..';
import { SurveyItemTypes } from './types';
/**
 * Recursively finds all Question items descendent from @param item
 * @returns a list of the items
 */
export const getQuestions = (items) => {
    return ensureArray(items).flatMap((item) => {
        switch (item.type) {
            case SurveyItemTypes.question:
                return [item];
            case SurveyItemTypes.group:
                return item.items.flatMap(getQuestions);
            case SurveyItemTypes.display:
                return [];
        }
    });
};
