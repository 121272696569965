import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export interface InjectionSite {
  id: string
  name: string // 'Supraspinatus'
  slug: string // 'supraspinatus'
}

export interface InjectionSitesResponse {
  injectionSites: InjectionSite[]
}

const useInjectionSites = (
  medicalConditionIds?: string[],
  extraOptions: UseQueryOptions<InjectionSite[]> = {},
) => {
  const siteId = useCurrentSite().id
  const key = medicalConditionIds
    ? QueryKeys.injectionSites(medicalConditionIds)
    : []
  let medicalConditionIdsUrlParams = ''
  medicalConditionIds?.map(
    (mcId) =>
      (medicalConditionIdsUrlParams += `&medical_condition_ids[]=${mcId}`),
  )
  const url = `/sites/injection_sites?site_ids[]=${siteId}${medicalConditionIdsUrlParams}`

  return useQuery(
    key,
    () =>
      request
        .get<InjectionSitesResponse>(url)
        .then((r) => r.data.injectionSites),
    {
      // InjectionSites are static - don't refresh them
      staleTime: Number.POSITIVE_INFINITY,
      enabled: Boolean(medicalConditionIds),
      ...extraOptions,
    },
  )
}

export default useInjectionSites
