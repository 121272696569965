import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { BodySiteKey } from './useBodySites'

export type OutcomeX = 0 | 1 | 3 | 6 | 12 | 18 | 24
export type OutcomeName =
  | 'Baseline'
  | '1 Month'
  | '3 Months'
  | '6 Months'
  | '12 Months'
  | '18 Months'
  | '24 Months'

export interface AggregateOutcomeData {
  name: OutcomeName
  x: OutcomeX
  y: number
  n: number
  abs: number
}

export interface AggregateOutcome {
  bodySiteKey: BodySiteKey
  diagnosis: string
  surveyName: string
  treatment: string
  siteId: string
  data: AggregateOutcomeData[]
}

export interface AggregateOutcomesParams {
  bodySite?: null | string
  treatment?: null | string
  diagnosis?: null | string
  provider?: null | string
  sex?: null | string
  age?: null | 'all' | { min?: number | string; max?: number | string }
  bmi?: null | 'avg' | { min?: number | string; max?: number | string }
}

interface AggregateOutcomesResponse {
  aggregateOutcomes: AggregateOutcome[]
}

const useAggregateOutcomes = (
  params: AggregateOutcomesParams = {} as AggregateOutcomesParams,
  extraOptions: UseQueryOptions<AggregateOutcome[]> = {},
) => {
  const siteId = useCurrentSite().id
  const key = QueryKeys.aggregateOutcomes(params)
  const url = `/sites/aggregate_outcomes/search?site_ids[]=${siteId}`

  return useQuery(
    key,
    () =>
      request
        .post<AggregateOutcomesResponse>(url, {
          bodySite: params.bodySite,
          biologicalSex: params.sex === 'all' ? null : params.sex,
          treatments: params.treatment === 'all' ? null : [params.treatment],
          diagnoses: params.diagnosis === 'all' ? null : [params.diagnosis],
          providers: params.provider === 'all' ? null : [params.provider],
          age:
            params.age === 'all'
              ? null
              : { minimum: params?.age?.min, maximum: params?.age?.max },
          bmiScore:
            params.bmi === 'avg' || !params?.bmi?.min || !params?.bmi?.max
              ? null
              : { minimum: params?.bmi?.min, maximum: params?.bmi?.max },
        })
        .then((r) => r.data.aggregateOutcomes),
    {
      ...extraOptions,
    },
  )
}

export default useAggregateOutcomes
