import { FC, Fragment, useEffect, useState } from 'react'
import {
  Autocomplete,
  // Body1,
  Button,
  Divider,
  H1,
  H5,
  Icon,
  IconButton,
  // Link,
  makeStyles,
  TextField,
} from '@perk-ui/core'
import { FieldArray, FormikProvider, useFormik } from 'formik'
import { matchSorter } from 'match-sorter'
import { ArrowLeft } from 'react-feather'
import { useRouteMatch } from 'react-router'
import * as yup from 'yup'

import useBodySites, {
  BodySite,
} from '../../../features/query-hooks/useBodySites'
import useCreateProtocolDiagnosis from '../../../features/query-hooks/useCreateProtocolDiagnosis'
import useDeleteProtocolDiagnosis from '../../../features/query-hooks/useDeleteProtocolDiagnosis'
import useMedicalConditions from '../../../features/query-hooks/useMedicalConditions'
import useProtocol from '../../../features/query-hooks/useProtocol'
import {
  ProtocolDiagnosis,
  ProtocolDiagnosisSeverity,
} from '../../../features/query-hooks/useProtocols'
import useUpdateProtocol from '../../../features/query-hooks/useUpdateProtocol'
import useUpdateProtocolDiagnosis from '../../../features/query-hooks/useUpdateProtocolDiagnosis'
import { useHistory } from '../../../utils/useHistory'
import ProtocolFlowUrls, {
  ProtocolFlowLocationParams,
} from '../ProtocolFlowUrls'
import DiagnosisForm, { DiagnosisFormValues } from './components/DiagnosisForm'

const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  headerIcon: {
    marginRight: theme.spacing(1),
  },
  archiveButton: {
    cursor: 'pointer',
  },
  dialogContent: {
    '& > p': {
      textAlign: 'start',
    },
  },
  dialogButtons: {
    display: 'flex',
    marginTop: theme.spacing(3),
    justifyContent: 'center',
    '& button+button': {
      marginLeft: theme.spacing(5),
    },
  },
  form: {
    marginTop: theme.spacing(2),
    '& > *:not(:last-child)': {
      marginBottom: theme.spacing(2),
    },
    '& > .MuiDivider-root': {
      marginBottom: theme.spacing(3),
    },
    '& .MuiAutocomplete-input': {
      maxWidth: '100%',
    },
  },
}))

interface DiagnosesFormValues {
  diagnoses: ProtocolDiagnosis[]
}
interface ProtocolFormValues {
  bodySiteId: string
}
type ProtocolAndDiagnosessFormValues = DiagnosesFormValues & ProtocolFormValues

const diagnosisHasChanged = (
  diag1?: DiagnosisFormValues & Pick<ProtocolDiagnosis, 'isPrimary'>,
  diag2?: ProtocolDiagnosis,
) => {
  if (!diag1 || !diag2) return true
  if (diag1.isPrimary !== diag2.isPrimary) return true
  if (diag1.severity !== diag2.severity) return true
  if (diag1.medicalConditionId !== diag2.medicalConditionId) return true
  if (diag1.locations !== diag2.locations) return true

  return false
}

const diagnosisValidationSchema = yup.object({
  diagnoses: yup.array().of(
    yup.object({
      medicalConditionId: yup
        .string()
        .defined('A Primary Diagnosis must be selected'),
      severity: yup
        .string()
        .oneOf(['mild', 'moderate', 'severe'])
        .defined('Please select a severity'),
      locations: yup.array().of(yup.string()),
      // radiographicClassification: yup.string().nullable(),
    }),
  ),
})

const protocolValidationSchema = yup.object({
  bodySiteId: yup.string().defined('Body Part must be selected'),
})

export interface DiagnosisProps {}

const Diagnosis: FC<DiagnosisProps> = () => {
  const { goBack, push } = useHistory()
  const classes = useStyles()
  const diagnosisMatch = useRouteMatch<ProtocolFlowLocationParams>(
    ProtocolFlowUrls.addDiagnosis.match,
  )
  const { protocolId = undefined } = diagnosisMatch ? diagnosisMatch.params : {}
  const updateProtocol = useUpdateProtocol()
  const createDiagnosis = useCreateProtocolDiagnosis()
  const updateDiagnosis = useUpdateProtocolDiagnosis()
  const deleteDiagnosis = useDeleteProtocolDiagnosis()
  const { data: protocol } = useProtocol(protocolId || '')
  const existingDiagnoses = protocol?.protocolDiagnoses ?? []

  const { data: bodySites = [] } = useBodySites()

  const [bodySitesOptions, setBodySitesOptions] = useState<BodySite[]>([])
  // const bodySiteName = bodySites.find(
  //   (site) => site.id === patientCase?.bodySiteId,
  // )?.name

  const formik = useFormik<ProtocolAndDiagnosessFormValues>({
    validationSchema: diagnosisValidationSchema.concat(
      protocolValidationSchema,
    ),
    enableReinitialize: true,
    initialValues: {
      bodySiteId: protocol?.bodySite?.id || '',
      diagnoses:
        existingDiagnoses.length > 0
          ? existingDiagnoses
          : [
              {
                id: '',
                medicalConditionId: '',
                severity: '' as ProtocolDiagnosisSeverity,
                locations: [],
                isPrimary: true,
              },
            ],
    },
    onSubmit(values) {
      if (!protocolId || !values.bodySiteId) return
      if (values.bodySiteId) {
        updateProtocol.mutateAsync({
          id: protocolId,
          bodySiteId: values.bodySiteId,
        })
      }

      const createsAndUpdates = values.diagnoses.map((diagnosis, idx) => {
        if (diagnosis.id) {
          const shouldUpdate = diagnosisHasChanged(
            {
              ...diagnosis,
              isPrimary: idx === 0,
            },
            existingDiagnoses[idx],
          )

          if (shouldUpdate) {
            return updateDiagnosis.mutateAsync({
              ...diagnosis,
              id: diagnosis.id,
              severity: diagnosis.severity as ProtocolDiagnosisSeverity,
              isPrimary: idx === 0,
              protocolId,
            })
          }
        } else {
          return createDiagnosis.mutateAsync({
            ...diagnosis,
            severity: diagnosis.severity as ProtocolDiagnosisSeverity,
            isPrimary: idx === 0,
            protocolId,
          })
        }
        return
      })

      return Promise.all(createsAndUpdates).then(() => {
        const treatments = protocol?.protocolTreatments || []
        const destination =
          treatments.length > 0
            ? ProtocolFlowUrls.treatment.create(protocolId, treatments[0].id)
            : ProtocolFlowUrls.addTreatment.create(protocolId)

        push({
          pathname: destination,
          state: { from: location.pathname },
        })
      })
    },
  })

  useEffect(() => {
    const filteredBodySites = bodySites.filter((bs) => bs.onlyForStudy == false)
    setBodySitesOptions(filteredBodySites)
  }, [bodySites])

  const {
    errors,
    touched,
    values,
    handleBlur,
    handleSubmit,
    isSubmitting,
    setFieldValue,
  } = formik

  const { data: conditions = [] } = useMedicalConditions(
    values.bodySiteId || '',
  )

  return (
    <>
      <div className={classes.header}>
        {/* <DialogTrigger
          action={
            <Link
              component={Body1}
              className={classes.archiveButton}
              underline="hover"
            >
              Archive Body Part
            </Link>
          }
          content={(toggle) => (
            <Box p={3} className={classes.dialogContent}>
              <ArchiveModal

                toggle={toggle}
              />
            </Box>
          )}
        /> */}
        <IconButton
          className={classes.headerIcon}
          onClick={() => {
            goBack()
          }}
          size="large"
        >
          <Icon size="x-large">
            <ArrowLeft />
          </Icon>
        </IconButton>
        <H1>{protocol?.type === 'FullProtocol' ? 'Full Protocol' : ''}</H1>
      </div>

      <H5 paragraph> Patient Intake</H5>
      <form className={classes.form} onSubmit={handleSubmit}>
        <FormikProvider value={formik}>
          <Autocomplete
            options={bodySitesOptions}
            getOptionLabel={(opt) => opt.name}
            filterOptions={(options, { inputValue }) =>
              inputValue
                ? matchSorter(options, inputValue, { keys: ['name'] })
                : options
            }
            onChange={(_event, site) => {
              setFieldValue('bodySiteId', site?.id || '')
            }}
            value={
              bodySites.find((site) => values.bodySiteId == site.id) ?? null
            }
            renderInput={(params) => {
              // @ts-expect-error Mui's autocomplete types here are incomplete
              params.InputLabelProps.shrink = true
              return (
                <TextField
                  {...params}
                  name="bodySiteId"
                  label="Body Part"
                  onBlur={handleBlur}
                  required
                  InputProps={{
                    ...params.InputProps,
                    required: values.bodySiteId.length === 0,
                  }}
                  error={touched.bodySiteId && Boolean(errors.bodySiteId)}
                  helperText={touched.bodySiteId && errors.bodySiteId}
                />
              )
            }}
          />
          <Divider />
          <FieldArray name="diagnoses" validateOnChange={false}>
            {({ remove }) => {
              return (
                <>
                  {values.diagnoses.map((diagnosis, idx) => {
                    return (
                      <Fragment key={idx}>
                        <DiagnosisForm
                          diagnosis={diagnosis}
                          conditions={conditions}
                          bodySiteId={values.bodySiteId || ''}
                          setFieldValue={setFieldValue}
                          handleBlur={handleBlur}
                          touched={touched.diagnoses?.[idx] ?? null}
                          errors={errors.diagnoses?.[idx] ?? null}
                          index={idx}
                          onRemove={async () => {
                            if (diagnosis.id) {
                              await deleteDiagnosis.mutateAsync(diagnosis.id)
                            }
                            remove(idx)
                          }}
                        />
                        <Divider />
                      </Fragment>
                    )
                  })}
                  {/* <Link
                    component="button"
                    type="button"
                    underline="hover"
                    onClick={() =>
                      push({
                        medicalConditionId: '',
                        severity: '',
                        locations: [],
                      })
                    }
                  >
                    <Body1>Add Secondary Diagnosis</Body1>
                  </Link> */}
                </>
              )
            }}
          </FieldArray>
          <Button type="submit" fullWidth loading={isSubmitting}>
            Next
          </Button>
        </FormikProvider>
      </form>
    </>
  )
}

export default Diagnosis
