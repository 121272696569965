import { FC } from 'react'
import { Box, makeStyles } from '@perk-ui/core'

import LearnMoreBanner from './components/LearnMoreBanner'
import ThankYouContainer from './components/ThankYouContainer'

interface UnauthenticatedSigncontractDoneProps {
  type: 'no-contract' | 'thank-you'
}
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
  },
  container: {
    margin: 'auto',
  },
}))

const UnauthenticatedSigncontractDone: FC<
  UnauthenticatedSigncontractDoneProps
> = ({ type }) => {
  const classes = useStyles()
  const title =
    type == 'thank-you'
      ? 'You have successfully signed the agreement'
      : 'Contract already signed'
  const body =
    type == 'thank-you'
      ? 'Thank you for signing the contract. <br/>We also sent it to you by email.'
      : 'Thank you for signing the contract'
  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <ThankYouContainer type={type} title={title} body={body} />
        <LearnMoreBanner />
      </Box>
    </Box>
  )
}

export default UnauthenticatedSigncontractDone
