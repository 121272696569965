import { FC } from 'react'
import { Box, makeStyles } from '@perk-ui/core'

import LearnMoreBanner from './components/LearnMoreBanner'
import ThankYouContainer from './components/ThankYouContainer'

interface UnauthenticatedPaymentDoneProps {
  type: 'payment-done' | 'payment-incomplete' | 'no-subscription'
}
const useStyles = makeStyles(() => ({
  root: {
    height: '100%',
    display: 'flex',
  },
  container: {
    margin: 'auto',
  },
}))

const UnauthenticatedPaymentDone: FC<UnauthenticatedPaymentDoneProps> = ({
  type,
}) => {
  const classes = useStyles()
  const title =
    type == 'payment-done'
      ? 'Subscription already paid'
      : 'Payment not available'
  const body =
    type == 'payment-done'
      ? 'Thank you for paying the subscription.'
      : "The subscription doesn't exist."
  return (
    <Box className={classes.root}>
      <Box className={classes.container}>
        <ThankYouContainer type={type} title={title} body={body} />
        <LearnMoreBanner />
      </Box>
    </Box>
  )
}

export default UnauthenticatedPaymentDone
