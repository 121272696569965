import { FC } from 'react'
import { Body2, capitalize, H5, makeStyles } from '@perk-ui/core'

import DataPair from '../../../../components/DataPair'
import {
  AdverseEvent,
  ExpectednessLabelMap,
  RelatednessLabelMap,
  SeriousnessLabelMap,
} from '../../../../features/query-hooks/useAdverseEvents'
import useDates from '../../../../hooks/useDates'

const snakeToCamel = (snake_string: string) => snake_string.split('_').join(' ')

const useStyles = makeStyles((theme) => ({
  reportedDate: {
    fontSize: theme.typography.body1.fontSize,
  },
  spacer: {
    paddingBottom: theme.spacing(2),
  },
}))

export interface AdverseEventDetailsProps {
  adverseEvent: AdverseEvent
  withAdjudication?: boolean
}

const AdverseEventDetails: FC<AdverseEventDetailsProps> = ({
  adverseEvent,
  withAdjudication = false,
}) => {
  const classes = useStyles()
  const { localizedFormatDate } = useDates()

  const providerName = adverseEvent.provider
    ? `${adverseEvent.provider.firstName} ${adverseEvent.provider.lastName}`
    : '---'

  return (
    <>
      <Body2 className={classes.reportedDate} gutterBottom>
        Reported on: {localizedFormatDate(new Date(adverseEvent.createdAt))}
      </Body2>
      <DataPair
        label="Problem date"
        value={
          adverseEvent.dateOfProblem
            ? localizedFormatDate(new Date(adverseEvent.dateOfProblem))
            : capitalize(snakeToCamel(adverseEvent?.problemDate || ''))
        }
      />
      {!adverseEvent.dateOfProblem && (
        <DataPair
          label="Issue Type"
          value={capitalize(snakeToCamel(adverseEvent.issueType))}
        />
      )}
      <DataPair label="Issue" value={adverseEvent.issue} />
      <DataPair
        label="Seeking treatment"
        value={capitalize(snakeToCamel(adverseEvent.seekingMedicalTreatment))}
      />
      <DataPair
        label="Patient notes"
        value={`"${adverseEvent.patientNotes || '---'}"`}
      />
      <div className={classes.spacer} />
      {withAdjudication && (
        <>
          <H5 gutterBottom>Adjudication</H5>
          <DataPair
            label="Date adjudicated"
            value={localizedFormatDate(new Date(adverseEvent.updatedAt))}
          />
          <DataPair label="Provider" value={providerName} />
          <DataPair
            label="Seriousness"
            value={SeriousnessLabelMap[adverseEvent.seriousness]}
          />
          <DataPair
            label="Expectedness"
            value={ExpectednessLabelMap[adverseEvent.expectedness]}
          />
          <DataPair
            label="Relatedness"
            value={RelatednessLabelMap[adverseEvent.relatedness]}
          />
          <DataPair label="Notes" value={adverseEvent.providerNotes} />
        </>
      )}
    </>
  )
}

export default AdverseEventDetails
