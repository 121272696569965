import { useQuery } from 'react-query'

import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export interface ClinicContractResponse extends Array<ClinicContract> {}

export interface ClinicContract {
  id: string
  name: 'service-agreement' | 'baa'
  url?: string
  status: 'pending' | 'signed'
  signature?: string
  signDate?: string
  content?: string
  country: string
}

const useUnauthenticatedClinicContracts = (token: string) => {
  const key = QueryKeys.unauthenticatedClinicContract()
  const url = `/sites/contracts_unauth?token=${token}`

  return useQuery(
    key,
    () => request.get<ClinicContractResponse>(url).then((r) => r.data),
    {
      // refetchInterval: 0,
    },
  )
}

export default useUnauthenticatedClinicContracts
