import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { ProtocolDiagnosis } from './useProtocols'

interface CreateDiagnosisResponse {
  protocolDiagnosis: ProtocolDiagnosis
}

export interface CreateDiagnosisVars extends Omit<ProtocolDiagnosis, 'id'> {
  protocolId: string
}

const useCreateProtocolDiagnosis = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation(({ protocolId, ...vars }: CreateDiagnosisVars) => {
    const url = `/sites/protocol_diagnoses`

    return request
      .post<CreateDiagnosisResponse>(url, {
        siteIds: [siteId],
        ProtocolDiagnosis: {
          protocolId: protocolId,
          ...vars,
          // radiographicClassifications: vars.radiographicClassification
          //   ? vars.radiographicClassification
          //   : undefined,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.protocols({ clinicId: siteId }))
        queryClient.invalidateQueries(QueryKeys.protocol(protocolId))
        // queryClient.invalidateQueries(QueryKeys.upcomingDashboard(siteId))

        return r.data.protocolDiagnosis
      })
  })
}

export default useCreateProtocolDiagnosis
