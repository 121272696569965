import { FC } from 'react'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  H1,
  H3,
  PageTitle,
  PasswordField,
  TextField,
} from '@perk-ui/core'
import { useFormik } from 'formik'
import { KeratinError } from 'keratin-authn/dist/types'
import * as yup from 'yup'

import AppLink from '../../../components/AppLink'
import SideBannerContainer from '../../../components/SideBannerContainer'
import { useAuth } from '../../../features/auth/AuthContext'
import AuthenticationBase from '../AuthenticationBase'
import AuthenticationBanner from '../components/AuthenticationBanner'

interface LoginProps {}

interface LoginFormValues {
  email: string
  password: string
  rememberme: boolean
}

const Login: FC<LoginProps> = () => {
  const { signIn } = useAuth()
  const remembermeData = localStorage.getItem('rememberme')
    ? JSON.parse(localStorage.getItem('rememberme') || '')
    : {}

  const loginValidations = yup.object({
    email: yup
      .string()
      .required('Email is required')
      .email('Ensure the email is formatted correctly'),
    password: yup.string().required('Password is required'),
  })

  const {
    values,
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    setFieldError,
    isSubmitting,
    isValid,
    touched,
  } = useFormik<LoginFormValues>({
    enableReinitialize: true,
    initialValues: {
      email: remembermeData?.email || '',
      password: remembermeData?.password || '',
      rememberme:
        remembermeData?.email && remembermeData?.password ? true : false,
    },
    validationSchema: loginValidations,
    onSubmit: (values) => {
      const { email, password, rememberme } = values
      return signIn(email, password)
        .catch((error) => {
          if (!error || !Array.isArray(error)) {
            return
          }
          const keratinErrors = error as KeratinError[]
          const badCredentials = keratinErrors.some(
            (err) => err.field === 'credentials' && err.message === 'FAILED',
          )
          const expiredCredentials = keratinErrors.some(
            (err) => err.field === 'credentials' && err.message === 'EXPIRED',
          )
          if (badCredentials) {
            setFieldError('email', 'Invalid username or password')
          }
          if (expiredCredentials) {
            setFieldError('email', 'Account is not active.')
          }
        })
        .finally(() => {
          if (rememberme) {
            localStorage.setItem(
              'rememberme',
              JSON.stringify({ email: email, password: password }),
            )
          } else {
            localStorage.removeItem('rememberme')
          }
        })
    },
  })

  return (
    <SideBannerContainer
      bannerWidth={5}
      bannerSide="left"
      banner={<AuthenticationBanner />}
    >
      <PageTitle title="Login" />
      <AuthenticationBase>
        <Box className="form-header">
          <H1>Login</H1>
          <H3>Welcome Back</H3>
        </Box>
        <form className="form-content" onSubmit={handleSubmit}>
          <TextField
            label="Email"
            name="email"
            placeholder="Enter email"
            fullWidth
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.email && Boolean(errors.email)}
            helperText={touched.email && errors.email}
          />
          <PasswordField
            label="Password"
            name="password"
            placeholder="Enter password"
            fullWidth
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            error={touched.password && Boolean(errors.password)}
            helperText={touched.password && errors.password}
          />
          <Grid container mb={3}>
            <Grid item xs sm={6}>
              <FormControlLabel
                className="form-checkbox-control-label"
                control={
                  <Checkbox
                    className="form-checkbox"
                    name="rememberme"
                    color="primary"
                    checked={values.rememberme}
                    onChange={handleChange}
                  />
                }
                label={'Remember me'}
              />
            </Grid>
            <Grid item xs sm={6} textAlign={'right'}>
              <AppLink to="/forgot-password-new">Forgot Password</AppLink>
            </Grid>
          </Grid>

          <Button
            type="submit"
            fullWidth
            loading={isSubmitting}
            disabled={!isValid}
          >
            Log In
          </Button>
          <Box textAlign={'center'}>
            <span>
              {"Don't have an account? "}
              <AppLink to="/create-account">Create Account</AppLink>
            </span>
          </Box>
        </form>
      </AuthenticationBase>
    </SideBannerContainer>
  )
}

export default Login
