import { useQuery, UseQueryOptions } from 'react-query'

import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { StaffRole } from './types'
import { Provider } from './useProviders'

export interface ClinicStaff extends Omit<Provider, 'type' | 'npi'> {
  type: 'ClinicStaff'
}

export interface User {
  email: string | null
  id: string
  staff: Staff
  jwtToken?: string
}

export interface Staff extends Omit<Provider, 'type'> {
  type: StaffRole
}

interface CurrentUserResponse {
  user: User
}

const useCurrentUser = (extraOptions: UseQueryOptions<User> = {}) => {
  const key = QueryKeys.currentUser()
  const url = '/users/current'

  return useQuery(
    key,
    () => request.get<CurrentUserResponse>(url).then((r) => r.data.user),
    {
      // The currentUser's details rarely change, let's avoid refetching
      staleTime: Number.POSITIVE_INFINITY,
      ...extraOptions,
    },
  )
}

export default useCurrentUser
