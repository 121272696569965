import React, { Fragment, ReactNode } from 'react'
import { Box, Divider, H5, Icon } from '@perk-ui/core'
import { CheckCircle, Circle, EyeOff, Minus } from 'react-feather'
import { useParams } from 'react-router'

import { surveyPeriodsOrdered } from '../../../../../config/constants'
import useBodyPartsSurveyCompletionStatus from '../../../../../features/query-hooks/useBodyPartsSurveyCompletionStatus'
import { humanizeBodySite } from '../../../../../features/query-hooks/useBodySites'
import { SurveyStatusPeriod } from '../../../../../features/query-hooks/useSurveyCompletionCDStatus'

const SurveyCompletion: React.FC = () => {
  const { patientId } = useParams<{ patientId: string }>()
  const { data: surveyCompletionList } =
    useBodyPartsSurveyCompletionStatus(patientId)

  const surveyPeriods = surveyPeriodsOrdered
    .sort((a, b) => a.order - b.order)
    .filter((per) => per.value !== 0.5)

  const statusToComponent: Record<string, ReactNode> = {
    done: (
      <Icon size="large" color="success">
        <CheckCircle />
      </Icon>
    ),
    missed: (
      <Icon size="large" color="warning">
        <Circle />
      </Icon>
    ),
    expiring_soon: (
      <Icon size="large" color="secondaryText">
        <Circle />
      </Icon>
    ),
    open: (
      <Icon size="large" color="secondaryText">
        <Circle />
      </Icon>
    ),
    'n/a': (
      <Icon size="large" color="secondaryText">
        <Minus />
      </Icon>
    ),
    undefined: (
      <Icon size="large" color="secondaryText">
        <EyeOff />
      </Icon>
    ),
  }

  return surveyCompletionList ? (
    <Box mt={4} pr={4}>
      <H5>Survey completion</H5>
      <Box display="flex" py={2}>
        <Box flex={1}>Month</Box>
        <Box display="flex" width={360}>
          {surveyPeriods.map((period) => (
            <Box
              key={period.value}
              display="flex"
              flex={1}
              alignItems="center"
              justifyContent="center"
            >
              {period.value}
            </Box>
          ))}
        </Box>
      </Box>

      {surveyCompletionList.map((survey, index) => (
        <Fragment key={`surveyCompletionListItem-${index}`}>
          <Divider />
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            py={2}
          >
            <Box flex={1}>{humanizeBodySite(survey.name)}</Box>
            <Box display="flex" width={360}>
              {surveyPeriods.map((surveyStatusPeriod, index) => {
                const period = Object.keys(survey.surveys).find(
                  (stat) => surveyStatusPeriod.type === stat,
                )
                return (
                  <Box
                    key={`surveyCompletionListItemPeriod-${index}`}
                    display="flex"
                    flex={1}
                    alignItems="center"
                    justifyContent="center"
                  >
                    <Box
                      display="flex"
                      flex={1}
                      alignItems="center"
                      justifyContent="center"
                    >
                      {
                        statusToComponent[
                          period
                            ? survey.surveys[period as SurveyStatusPeriod]
                                .status
                            : 'undefined'
                        ]
                      }
                    </Box>
                  </Box>
                )
              })}
            </Box>
          </Box>
        </Fragment>
      ))}
    </Box>
  ) : null
}

export default SurveyCompletion
