import { useQuery, UseQueryOptions } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'

export interface MedicalCondition {
  id: string
  name: string // 'Tendinosis'
  slug: string // 'tendinosis'
  onlyForStudy: boolean
}

interface MedicalConditionResponse {
  medicalConditions: MedicalCondition[]
}

const useMedicalConditions = (
  bodySiteId?: string,
  extraOptions: UseQueryOptions<MedicalCondition[]> = {},
) => {
  const siteId = useCurrentSite().id
  const key = bodySiteId ? QueryKeys.medicalConditions(bodySiteId) : []
  const url = `/sites/medical_conditions?site_ids[]=${siteId}&body_site_id=${bodySiteId}`

  return useQuery(
    key,
    () =>
      request
        .get<MedicalConditionResponse>(url)
        .then((r) => r.data.medicalConditions),
    {
      // MedicalConditions are static. Don't refresh
      staleTime: Number.POSITIVE_INFINITY,
      enabled: Boolean(bodySiteId),
      ...extraOptions,
    },
  )
}

export default useMedicalConditions
