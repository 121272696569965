import { useMutation } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import { AdverseEvent } from './useAdverseEvents'

interface CreateAdverseEventResponse {
  adverseEvent: AdverseEvent
}

export interface CreateAdverseEventVars
  extends Omit<
    AdverseEvent,
    'id' | 'patient' | 'createdAt' | 'updatedAt' | 'status' | 'issueType'
  > {}

const useCreateAdverseEvent = () => {
  const siteId = useCurrentSite().id

  return useMutation((vars: CreateAdverseEventVars) => {
    const url = `/sites/adverse_events`

    return request
      .post<CreateAdverseEventResponse>(url, {
        siteIds: [siteId],
        adverseEvent: {
          ...vars,
          seriousness: vars.seriousness ? vars.seriousness : undefined,
          expectedness: vars.expectedness ? vars.expectedness : undefined,
          relatedness: vars.relatedness ? vars.relatedness : undefined,
          providerNotes: vars.providerNotes ? vars.providerNotes : undefined,
        },
      })
      .then((r) => {
        return r.data.adverseEvent
      })
  })
}

export default useCreateAdverseEvent
