import { useMutation, useQueryClient } from 'react-query'

import { useCurrentSite } from '../../components/SitesProvider'
import request from '../../config/axios'
import QueryKeys from './QueryKeys'
import { PatientTreatment } from './usePatientTreatment'

interface CreateTreatmentResponse {
  patientTreatment: PatientTreatment
}

export interface CreateTreatmentVars extends Omit<PatientTreatment, 'id'> {}

const useCreateTreatment = () => {
  const queryClient = useQueryClient()
  const siteId = useCurrentSite().id

  return useMutation((vars: CreateTreatmentVars) => {
    const url = `/sites/patient_treatments`

    return request
      .post<CreateTreatmentResponse>(url, {
        siteIds: [siteId],
        patientTreatment: {
          siteId: siteId,
          ...vars,
          // Remove if undefined
          researchStudyNumber: vars.researchStudyNumber
            ? vars.researchStudyNumber
            : undefined,
        },
      })
      .then((r) => {
        queryClient.invalidateQueries(QueryKeys.patientCases({}))
        queryClient.invalidateQueries(
          QueryKeys.patientTreatmentsForSite(siteId),
        )
        queryClient.invalidateQueries(QueryKeys.upcomingDashboard(siteId))

        return r.data.patientTreatment
      })
  })
}

export default useCreateTreatment
