import { FC } from 'react'
import {
  Box,
  H1,
  Icon,
  IconButton,
  makeStyles,
  PageTitle,
  Paper,
} from '@perk-ui/core'
import { ArrowLeft } from 'react-feather'
import { Route, Switch, useLocation, useRouteMatch } from 'react-router'

import usePatient from '../../features/query-hooks/usePatient'
import { useHistory } from '../../utils/useHistory'
import AddCase from './AddCase/AddCase'
import Diagnosis from './Diagnosis/Diagnosis'
import Invite from './Invite/Invite'
import PatientFlowSidebar from './PatientFlowSidebar'
import PatientFlowUrls, { PatientFlowLocationParams } from './PatientFlowUrls'
import Treatment from './Treatment/Treatment'
import TreatmentSession from './Treatment/TreatmentSession'

export interface PatientFlowProps {}

const useStyles = makeStyles((theme) => ({
  root: {
    padding: theme.spacing(3),
    margin: '0 auto',
    [theme.breakpoints.up('md')]: {
      maxWidth: '60%',
      padding: theme.spacing(3, 0),
    },
    [theme.breakpoints.up('lg')]: {
      maxWidth: '50%',
    },
  },
  content: {
    display: 'flex',
    height: '100%',
    boxShadow: theme.perk.boxShadow.card,
    maxHeight: '80vh',
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    paddingBottom: theme.spacing(2),
  },
  headerIcon: {
    marginRight: theme.spacing(1),
  },
  sidebar: {
    flex: 1,
    borderRight: `1px solid ${theme.palette.divider}`,
  },
  formContainer: {
    flex: 2,
    overflow: 'auto',

    // The below groups serve to always show the scrollbar if there is one.
    '&::-webkit-scrollbar': {
      width: 7,
      '-webkit-appearance': 'none',
      // Important for iPad Safari
      '-webkit-overflow-scrolling': 'auto',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: 4,
      backgroundColor: 'rgba(0, 0, 0, .4)',
      boxShadow: '0 0 1px rgba(255, 255, 255, .5)',
    },
  },
}))

const PatientFlow: FC<PatientFlowProps> = () => {
  const classes = useStyles()
  const { goBack, push } = useHistory()
  const location = useLocation<{ from?: string }>()

  const hasPatientMatch = useRouteMatch<PatientFlowLocationParams>(
    PatientFlowUrls.hasPatient.match,
  )
  const hasPatient = !!hasPatientMatch
  const patientId = hasPatientMatch ? hasPatientMatch.params.patientId : ''
  const { data: patient } = usePatient(patientId, {
    enabled: hasPatient,
  })

  const handleGoBack = () => {
    if (location.state?.from === '/home') {
      push('/home')
    } else if (location.state?.from === '/patients') {
      push('/patients')
    } else if (hasPatient) {
      push(`/patients/${patientId}`)
    } else {
      goBack()
    }
  }

  const title = hasPatient
    ? patient
      ? `${patient.firstName} ${patient.lastName}`
      : ''
    : 'New Patient'

  return (
    <Box className={classes.root}>
      <PageTitle title={hasPatient ? 'Edit Patient' : 'Invite Patient'} />
      <div className={classes.header}>
        <IconButton
          id="patient-flow-back-button"
          className={classes.headerIcon}
          onClick={handleGoBack}
          size="large"
        >
          <Icon size="x-large">
            <ArrowLeft />
          </Icon>
        </IconButton>
        <H1>{title}</H1>
      </div>
      <Paper className={classes.content}>
        <div className={classes.sidebar}>
          <PatientFlowSidebar />
        </div>
        <Box className={classes.formContainer} p={3}>
          <Switch>
            <Route
              path={PatientFlowUrls.invitePatient.match}
              exact
              component={Invite}
            />
            <Route
              path={PatientFlowUrls.editPatient.match}
              exact
              component={Invite}
            />
            <Route
              path={PatientFlowUrls.addCase.match}
              exact
              component={AddCase}
            />
            <Route
              path={PatientFlowUrls.diagnosis.match}
              exact
              component={Diagnosis}
            />
            <Route
              path={PatientFlowUrls.treatment.match}
              exact
              component={Treatment}
            />
            <Route
              path={PatientFlowUrls.addTreatment.match}
              exact
              component={Treatment}
            />
            <Route
              path={PatientFlowUrls.session.match}
              exact
              component={TreatmentSession}
            />
            <Route
              path={PatientFlowUrls.addSession.match}
              exact
              component={TreatmentSession}
            />
          </Switch>
        </Box>
      </Paper>
    </Box>
  )
}
export default PatientFlow
