import { Dispatch, FC, SetStateAction, useState } from 'react'
import {
  Box,
  Dialog,
  Grid,
  H2,
  H3,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  makeStyles,
} from '@perk-ui/core'
import clsx from 'clsx'
import { FileText, Lock, User as UserICon, X } from 'react-feather'

import { User } from '../features/query-hooks/useCurrentUser'
import ChangePasswordForm from './ChangePasswordForm'
import UserProfile from './UserProfile'
import UserProtocolsSettings from './UserProtocolsSettings'

interface UserSettingsModalProps {
  open: boolean
  setOpen: Dispatch<SetStateAction<boolean>>
  user: User
  setAnchorEl: Dispatch<SetStateAction<null | HTMLButtonElement>>
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .MuiDialog-container': {
      '& .MuiPaper-root:not(.MuiAlert-root)': {
        width: '100%',
        maxWidth: '982px',
        minHeight: '70vh',
        maxHeight: '75vh',
        height: '100%',
      },
    },
  },
  modalHeader: {
    borderBottom: '1px solid #F1F1F1',
    padding: theme.spacing(1, 2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    '& h3': {
      fontSize: '20px',
    },
  },
  modalContent: {
    height: 'calc(100% - 51px)',
    display: 'flex',
    overflow: 'hidden',
  },
  modalCloseButton: {},
  menuContainer: {
    height: '100%',
  },
  menuContent: {
    borderRight: '1px solid #F1F1F1',
    height: '100%',
    padding: theme.spacing(2),
  },
  gridContainer: {
    height: '100%',
  },
  contentContainer: {
    height: '100%',
    overflow: 'hidden',
  },
  content: {
    padding: theme.spacing(2),
    height: '100%',
    overflow: 'hidden',
  },
  linkContainer: {
    background: theme.palette.background.paper,
    // Somewhat arbitrary sizing, but matches design
    width: '100%',
    height: '100%',
    maxWidth: 290,
    padding: theme.spacing(0, 0, 2, 0),
  },
  marginRight: {
    marginRight: theme.spacing(1),
  },
  link: {
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(1.5, 2),
    borderRadius: 16,
    '&:hover': {
      background: 'rgba(87, 153, 173, 0.04)',
    },
    '& .ListItemText': {
      margin: 0,
      marginLeft: theme.spacing(1),
    },
    '& .MuiListItemIcon-root': {
      height: 30,
    },
    '& h5': {
      fontSize: 16,
      fontWeight: 700,
    },
    '& svg': {
      margin: 'auto',
    },
    '& span': {
      margin: 'auto',
    },
  },
  active: {
    // css specificity hack to override `a:link`
    '&&': {
      color: theme.palette.primary.main,
    },
    background: '#EAF7FD',
    '& svg': {
      color: theme.palette.primary.main,
    },
  },
  menuLinkIcon: {
    margin: 'auto',
    minWidth: 'unset',
    '& svg': {
      margin: 'auto',
    },
  },
}))

const UserSettingsModal: FC<UserSettingsModalProps> = ({
  open,
  setOpen,
  user,
  setAnchorEl,
}) => {
  const classes = useStyles()
  const [content, setContent] = useState<string>('profile')
  const isUserAdmin = user?.staff?.isAdmin || false

  const handleDialogClose = () => {
    setOpen(false)
    setAnchorEl(null)
  }

  const menuItems = [
    {
      key: 1,
      slug: 'profile',
      icon: <UserICon size={24} />,
      label: 'Personal',
      isAvailable: true,
    },
    {
      key: 2,
      slug: 'password',
      icon: <Lock size={24} />,
      label: 'Password',
      isAvailable: true,
    },
    {
      key: 3,
      slug: 'protocols',
      icon: <FileText size={24} />,
      label: 'Protocols',
      isAvailable: isUserAdmin,
    },
  ]

  const showContent = () => {
    switch (content) {
      case 'profile':
        return (
          <>
            <H2 pb={2}>Profile</H2>
            <UserProfile />
          </>
        )
      case 'password':
        return (
          <>
            <H2 pb={2}>Change Password</H2>
            <ChangePasswordForm />
          </>
        )
      case 'protocols':
        return (
          <UserProtocolsSettings
            setOpen={setOpen}
            open={open}
            setAnchorEl={setAnchorEl}
          />
        )
      default:
        return <></>
    }
  }

  return (
    <Dialog onClose={handleDialogClose} open={open} className={classes.root}>
      <Box className={classes.gridContainer}>
        <Box className={classes.modalHeader}>
          <H3>Settings</H3>
          <IconButton
            id="user-settings-modal-close-button"
            className={classes.modalCloseButton}
            aria-label="close"
            onClick={handleDialogClose}
          >
            <X size={18} />
          </IconButton>
        </Box>
        <Box className={classes.modalContent}>
          <Grid container>
            <Grid item xs={3} className={classes.menuContainer}>
              <Box className={classes.menuContent}>
                <List className={classes.linkContainer} component="nav">
                  {menuItems.map((item) => {
                    if (!item.isAvailable) return null
                    return (
                      <ListItemButton
                        id={'user-settings-menu-' + item.slug + '-button'}
                        key={item.key}
                        onClick={() => setContent(item.slug)}
                        className={clsx(classes.link, {
                          [classes.active]: content === item.slug,
                        })}
                      >
                        <ListItemIcon
                          className={clsx(classes.menuLinkIcon, {
                            [classes.active]: content === item.slug,
                          })}
                        >
                          {item.icon}
                        </ListItemIcon>
                        <ListItemText title={item.label} />
                      </ListItemButton>
                    )
                  })}
                </List>
              </Box>
            </Grid>
            <Grid item xs={9} className={classes.contentContainer}>
              <Box className={classes.content}>{showContent()}</Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Dialog>
  )
}

export default UserSettingsModal
