export var SurveyItemTypes;
(function (SurveyItemTypes) {
    SurveyItemTypes["group"] = "group";
    SurveyItemTypes["display"] = "display";
    SurveyItemTypes["question"] = "question";
})(SurveyItemTypes || (SurveyItemTypes = {}));
// These are TBD as we work through the first few Survey implementations
export var DisplayVariants;
(function (DisplayVariants) {
    DisplayVariants["title"] = "title";
    DisplayVariants["body"] = "body";
    DisplayVariants["callout"] = "callout";
    DisplayVariants["image"] = "image";
    DisplayVariants["divider"] = "divider";
})(DisplayVariants || (DisplayVariants = {}));
export var QuestionVariants;
(function (QuestionVariants) {
    QuestionVariants["choiceStandard"] = "choice-standard";
    QuestionVariants["choiceLong"] = "choice-long";
    QuestionVariants["choiceFlat"] = "choice-flat";
    QuestionVariants["choiceDropdown"] = "choice-dropdown";
    QuestionVariants["choiceIcon"] = "choice-icon";
    QuestionVariants["choiceToggle"] = "choice-toggle";
    QuestionVariants["choiceCollapse"] = "choice-collapse";
    QuestionVariants["zipCode"] = "zip-code";
    QuestionVariants["postalCode"] = "postal-code";
    QuestionVariants["slider"] = "slider";
    QuestionVariants["sliderHorizontal"] = "slider-horizontal";
    QuestionVariants["inputString"] = "input-string";
    QuestionVariants["inputTextarea"] = "input-textarea";
    QuestionVariants["inputInteger"] = "input-integer";
    QuestionVariants["inputDecimal"] = "input-decimal";
    QuestionVariants["inputText"] = "input-text";
    QuestionVariants["inputDate"] = "input-date";
})(QuestionVariants || (QuestionVariants = {}));
export var SurveyReponseStatusEnum;
(function (SurveyReponseStatusEnum) {
    SurveyReponseStatusEnum["created"] = "created";
    SurveyReponseStatusEnum["inProgress"] = "in-progress";
    SurveyReponseStatusEnum["completed"] = "completed";
    SurveyReponseStatusEnum["abandoned"] = "abandoned";
})(SurveyReponseStatusEnum || (SurveyReponseStatusEnum = {}));
