import { AggregateOutcomesParams } from './useAggregateOutcomes'
import { SurveyResponseSearchOptions } from './useSurveyResponseSearch'

const QueryKeys = {
  adverseEvent: (adverseEventId: string) => ['adverseEvent', adverseEventId],
  aggregateOutcomes: (opts: AggregateOutcomesParams) => [
    'aggregateOutcomes',
    opts,
  ],
  aggregateOutcomesFilters: () => ['aggregateOutcomesFilters'],
  surveyScoreDefinitions: () => ['surveyScoreDefinitions'],
  adverseEvents: (opts: {
    currentSiteId: string
    providerId?: string
    patientId?: string
    status?: string
    page?: number
    perPage?: number
  }) => ['adverseEvents', opts],
  anatomicalLocations: (medicalConditionId: string) => [
    'anatomicalLocations',
    medicalConditionId,
  ],
  injectionSites: (medicalConditionIds: string[]) => [
    'injectionSites',
    medicalConditionIds,
  ],
  archivalReasons: (type: string) => ['archivalReasons', type],
  bodySites: () => ['bodySites'],
  specialtyBodySites: () => ['specialtyBodySites'],
  clinicOverview: (opts: { currentSiteId: string }) => ['clinicOverview', opts],
  currentUser: () => ['currentUser'],
  medicalConditions: (bodySiteId: string) => ['medicalCondition', bodySiteId],
  medicalTreatments: () => ['medicalTreatments'],
  patient: (opts: { currentSiteId: string; patientId: string }) => [
    'patient',
    opts,
  ],
  patientCases: (opts: { patientId?: string }) => [
    'patientCases',
    'patient',
    opts,
  ],
  patientDiagnosisStats: (clinicId: string) => [
    'patientDiagnosisStats',
    clinicId,
  ],
  patientTreatmentStats: (clinicId: string) => [
    'patientTreatmentStats',
    clinicId,
  ],
  patientsSearch: (opts: {
    currentSiteId: string
    search?: string
    page?: number
    perPage?: number
  }) => ['patientsSearch', opts],
  patientsCDSearch: (opts: {
    currentSiteId: string
    search?: string
    bodySites?: string
    diagnoses?: string
    treatments?: string
    providers?: string
    studies?: string
    surveyStatuses?: string
    surveyPeriods?: string
    missingDiagnoses?: boolean
    missingTreatments?: boolean
    missingBaselineSurveys?: boolean
    filterToggle?: string
    page?: number
    perPage?: number
  }) => ['patientsSearch', opts],
  patientTreatment: (treatmentId: string) => ['patientTreatment', treatmentId],
  // TODO update key to patientTreatmentsForSite
  patientTreatmentsForSite: (siteId: string) => [
    'patientTreatmentsForSite',
    siteId,
  ],
  providers: (siteId: string) => ['providers', siteId],
  sitesAvailable: () => ['sitesAvailable'],
  survey: (slug: string) => ['survey', slug],
  surveyCount: (siteId: string, completedAfter?: Date) => [
    'surveyCount',
    { siteId, completedAfter },
  ],
  surveyResponse: (surveyResponseId: string) => [
    'surveyResponse',
    surveyResponseId,
  ],
  bodyPartsSurveyCompletionStatus: (opts: {
    currentSiteId: string
    patientId?: string
  }) => ['bodyPartsSurveyCompletionStatus', opts],
  surveyCompletionCDStatus: (opts: {
    currentSiteId: string
    patientId?: string
    patientCaseId?: string
  }) => ['surveyCompletionCDStatus', opts],
  surveyResponseGroups: (opts: {
    currentSiteId: string
    patientId?: string
  }) => ['surveyResponseGroups', opts],
  surveyResponseGroupsAlt: (opts: {
    currentSiteId: string
    patientId?: string
    patientCaseId?: string
  }) => ['surveyResponseGroupsAlt', opts],
  surveyResponseSearch: (
    options: SurveyResponseSearchOptions & { siteId: string },
  ) => ['surveyResponse', options],
  treatmentProducts: (medicalTreatmentId: string) => [
    'treatmentProducts',
    medicalTreatmentId,
  ],
  upcomingDashboard: (siteId: string) => ['upcomingDashboard', siteId],
  user: (userId: string) => ['user', userId],
  tableauEssentialToken: () => ['tableauEssentialToken'],
  tableauPerformanceToken: () => ['tableauPerformanceToken'],
  tableauAEToken: () => ['tableauAEToken'],
  tableauReportToken: () => ['tableauReportToken'],
  tableauStarterToken: () => ['tableauStarterToken'],
  studies: () => ['studies'],
  studyConsent: (patientCaseId: string) => ['studyConsent', patientCaseId],
  onboardingStep: (siteId: string) => ['onboardingStep', siteId],
  clinicContract: () => ['clinicContract'],
  unauthenticatedClinicContract: () => ['unauthenticatedClinicContract'],
  products: () => ['products'],
  providerNpi: () => ['providerNpi'],
  subscription: () => ['subscription'],
  staff: (staffId: string) => ['staff', staffId],
  unauthenticatedSubscription: () => ['unauthenticatedSubscription'],
  staffs: (clinicId: string) => ['staffs', clinicId],
  productsAvailable: () => ['productsAvailable'],
  homeMetricStats: (clinicId: string) => ['homeMetrics', clinicId],
  protocols: (opts: { clinicId: string; type?: string }) => ['protocols', opts],
  protocol: (protocolId: string) => ['protocol', protocolId],
  protocolTreatment: (treatmentId: string) => [
    'protocolTreatment',
    treatmentId,
  ],
}

export default QueryKeys
